export default async function auth({ to, next, store }){
  if (!store.getters['auth/loggedIn']){
    return next({ name: 'login' })
  }

  // Fetch Current User
  if (!store.getters['auth/currentUser']) {
    await store.dispatch('auth/fetchCurrentUser')

  } else {
    store.dispatch('auth/fetchCurrentUser')
  }

  // Could Use App
  if (to.meta.rule === 'couldUseApp') {
    const aclRoles = store.getters['auth/aclRoles']

    if (aclRoles.includes('role_1') && !aclRoles.includes('paid')) {
      return next({ name: 'account' })
    }
  }

  // Fetch Asset Data
  if (!store.getters['asset/isFetched']) {
    await store.dispatch('asset/fetchAssetData')
  }

  return next()
}