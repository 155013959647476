// import { getFirebaseBackend } from '../../authUtils.js'
import axios from '@/plugins/axios-instance'
import store from '@/state/store'
import _ from 'lodash'

const accessTokenExpiresIn = 300
const refreshTokenExpiresIn = 604800

export const state = {
  accessToken: null,
  refreshToken: null,
  refreshTimestamp: 0,
  currentUser: null,
  currentRole: null,
  adminView: false
}

export const mutations = {
  SET_ACCESS_TOKEN(state, newValue) {
    state.accessToken = newValue
  },
  SET_REFRESH_TOKEN(state, newValue) {
    state.refreshToken = newValue
  },
  SET_REFRESH_TIMESTAMP(state, newValue) {
    state.refreshTimestamp = newValue
  },
  SET_CURRENT_USER(state, newValue) {
    state.currentUser = newValue
    // saveState('auth.currentUser', newValue)
  },
  SET_CURRENT_ROLE(state, newValue) {
    state.currentRole = newValue
    // saveState('auth.currentRole', newValue)
  },
  SET_ADMIN_VIEW(state, newValue) {
    state.adminView = newValue
  },
}

export const getters = {
  refreshToken(state) {
    return state.refreshToken
  },

  accessToken(state) {
    return state.accessToken
  },

  refreshTimestamp(state) {
    return state.refreshTimestamp
  },

  loggedIn(state) {
    return !!state.refreshToken
  },

  currentUser(state) {
    return state.currentUser
  },
  currentUserRoles(state) {
    return state.currentUser ? state.currentUser.role_relations : null
  },
  currentUserCompanies(state) {
    return state.currentUser ? state.currentUser.companies : null
  },
  currentRole(state) {
    return state.currentRole
  },
  currentCompany(state, getters) {
    return state.currentRole ? _.find(getters.currentUserCompanies, { id: state.currentRole.company_id }) : null
  },
  currentAppSubscription(state, getters) {
    return _.get(getters.currentUser, 'company_app_subscription', null)
  },
  currentAppSubscriptionIsActive(state, getters) {
    return _.get(getters.currentAppSubscription, 'status') === 'active'
  },
  isAdmin(state) {
    return state.currentUser ? state.currentUser.is_admin : false
  },
  adminView(state) {
    return state.adminView
  },

  aclRoles(state, getters) {
    const roles = []

    if (getters.loggedIn) {
      if (getters.currentRole) {
        roles.push(`role_${getters.currentRole.role_id}`)

        if (getters.currentRole.role_id === 1) {
          if (getters.currentAppSubscriptionIsActive) {
            roles.push('paid')
          }
        }
      }

      if (getters.adminView) {
        roles.push('admin')
      }

    } else {
      roles.push('public')
    }

    return roles
  }
}

export const actions = {  
  // This is automatically run in `src/state/store.js` when the app
  // starts, along with any other actions named `init` in other modules.
  // eslint-disable-next-line no-unused-vars
  init({ state, dispatch }, { app }) {
    dispatch('validate', { app })
  },

  async fetchAccessToken({ dispatch, getters }) {
    await dispatch('syncTokens')
    return getters.accessToken
  },

  async syncTokens({ dispatch, state, commit, getters }) {
    // fetch Fresh Tokens from LocalStorage 
    await dispatch('fetchTokensFromLocalStorage')
    // Validate tokens
    if (state.accessToken) {
      if (this._vm.$moment() >= this._vm.$moment(state.accessToken.expires) * 1000) {
        window.console.log('syncTokens:accessToken:invalid')
        await commit('SET_ACCESS_TOKEN', null)
      }
    }

    if (state.refreshToken) {
      if (this._vm.$moment() >= this._vm.$moment(state.refreshToken.expires) * 1000) {
        window.console.log('syncTokens:refreshToken:invalid')
        await commit('SET_REFRESH_TOKEN', null)
      }
    }

    // Sync tokens with backend
    if (!getters.accessToken && getters.refreshToken) {
      // If refreshTimestamp was more that 5 seconds ago
      if (this._vm.$moment() >= this._vm.$moment((getters.refreshTimestamp + 5) * 1000)) {
        window.console.log('syncTokens start')
        await commit('SET_REFRESH_TIMESTAMP', this._vm.$moment().unix())
        const refreshToken = getters.refreshToken

        await axios.post('auth/token-refresh', { refresh_token: refreshToken.token })
          .then(async ({ data }) => {
            window.console.log('syncTokens success')
            await dispatch('setTokens', data)
          })
          .catch(async(e) => {
            window.console.log('syncTokens error', e.response)
            if (_.get(e, 'response.status') === 401 && _.isEqual(refreshToken, getters.refreshToken)) {
              await dispatch('invalidateAuth')
            }
          })

      } else {
        // Wait 20 sec for refresh token
        for (let i = 0; i < 40; i++) {
          await new Promise(resolve => setTimeout(resolve, 500))
          await dispatch('fetchTokensFromLocalStorage')
          if (getters.accessToken) {
            break
          }
        }
      }
    }
  },

  async setTokens({ commit }, { access_token, refresh_token }) {
    if (access_token && refresh_token) {
      await commit('SET_REFRESH_TOKEN', {
        token: refresh_token,
        expires: this._vm.$moment().add(refreshTokenExpiresIn, 'seconds').unix()
      })
  
      await commit('SET_ACCESS_TOKEN', {
        token: access_token,
        expires: this._vm.$moment().add(accessTokenExpiresIn, 'seconds').unix()
      })
    }
  },

  async fetchTokensFromLocalStorage({  commit }) {
    const vuex = JSON.parse(localStorage.getItem('vuex'), {})
    await commit('SET_ACCESS_TOKEN', _.get(vuex, 'auth.accessToken'))
    await commit('SET_REFRESH_TOKEN', _.get(vuex, 'auth.refreshToken'))
    await commit('SET_REFRESH_TIMESTAMP', _.get(vuex, 'auth.refreshTimestamp'))
  },

  async logOut({ dispatch, getters }) {
    if (getters.refreshToken) {
      await axios.post('auth/token-revoke', { refresh_token: getters.refreshToken.token })
        .then(() => {})
        .catch(() => {})
    }

    await dispatch('invalidateAuth')
  },

  async fetchCurrentUser({ dispatch, getters }) {
    await axios.get('users/me')
      .then(async ({ data }) => {
        await dispatch('setCurrentUser', data)

        // Update moment timezone
        this._vm.$moment.tz.setDefault(getters.currentUser.timezone)

        // Check if currentRole in roles - else set first one
        const currentRole = _.find(data.role_relations, getters.currentRole) || data.role_relations[0]

        if (!_.isEqual(getters.currentRole, currentRole)) {
          await dispatch('setCurrentRole', currentRole)
          await dispatch('fetchCurrentUser', currentRole)
        }
      })

      .catch((e) => {
        window.console.log(e)
        throw e
      })
  },

  async setCurrentUser({ commit, getters, dispatch }, value) {
    await commit('SET_CURRENT_USER', value)

    if (!getters.isAdmin) {
      await dispatch('setAdminView', getters.isAdmin)

    } else if (!getters.currentCompany) {
      await dispatch('setAdminView', getters.isAdmin)
    }
  },

  async setCurrentRole({ commit }, value) {
    await commit('SET_CURRENT_ROLE', value)
  },

  setAdminView({ commit, getters }, value) {
    return commit('SET_ADMIN_VIEW', getters.isAdmin ? value : false)
  },

  fetchCompanyData({ getters }) {
    store.dispatch('company/fetchCompanyData', { roles: getters.currentUserRoles });
  },

  fetchAssetData({ getters }) {
    store.dispatch('asset/fetchAssetData', { roles: getters.currentUserRoles });
  },

  async invalidateAuth({ commit }) {
    await commit('SET_REFRESH_TOKEN', null)
    await commit('SET_ACCESS_TOKEN', null)
    await commit('SET_CURRENT_ROLE', null)
    await commit('SET_CURRENT_USER', null)

    store.dispatch('company/clearData');
    store.dispatch('asset/clearData');
  },

  // Validates the current user's token and refreshes it
  // with new data from the API.
  // eslint-disable-next-line no-unused-vars
  validate({ getters, dispatch }, { app }) {
    //window.console.log(state);
    //window.console.log(state.currentUser);
    //window.console.log(state.userRoles);
    //window.console.log(state.currentRole);


    if (!getters.refreshToken || !getters.currentRole) {
      dispatch('invalidateAuth')
      return
    }

    // Fetch Asset Data
    dispatch('fetchAssetData')
  }
}