<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="showModal()"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'PhysicalConditionItemDeleteButton',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  methods: {
    deleteItem() {
      this.isSubmitting = true

      this.$axios
        .delete(`physical-conditions/${this.item.id}`)
        .then(() => {
          this.isSubmitting = false
          this.$emit('delete:item', this.item)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    showModal() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
        title: 'Please Confirm',
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteItem()
          }
        })
    },
  }
}
</script>