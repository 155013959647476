<template>
  <b-overlay
    :show="isBusy"
    :variant="config.overlay.variant"
    :opacity="config.overlay.opacity"
    :blur="config.overlay.blur"
    rounded="sm"
  >
    <b-card class="m-0">
      <b-form
        @submit.prevent="formSubmit"
      >
        <!-- PHOTO -->
        <b-form-group
          :invalid-feedback="getFieldInvalidFeedback($v.input.image)"
          :state="getFieldState($v.input.image)"
        >
          <div class="image-field text-center">
            <div v-if="imageUrl">
              <b-avatar
                :src="imageUrl"
                size="12rem"
              />
            </div>

            <b-form-file
              v-else
              class="add-photo"
              placeholder="+ Add Photo"
              drop-placeholder="Drop file here..."
              :state="getFieldState($v.input.image)"
              @change="input.image = $event.target.files[0]"
            />
          </div>
        </b-form-group>

        <!-- CATEGORY -->
        <b-form-group
          label="Category"
          :invalid-feedback="getFieldInvalidFeedback($v.input.category)"
          :state="getFieldState($v.input.category)"
        >
          <b-form-select
            v-model="input.category"
            :options="$store.getters['asset/getImageCategoriesOptions']"
            :state="getFieldState($v.input.category)"
          />
        </b-form-group>

        <!-- TAKEN AT -->
        <b-form-group
          label="Taken at"
          :invalid-feedback="getFieldInvalidFeedback($v.input.taken_at)"
          :state="getFieldState($v.input.taken_at)"
        >
          <b-form-datepicker
            v-model="input.taken_at"
            :state="getFieldState($v.input.taken_at)"
          />
        </b-form-group>

        <!-- SUBMIT -->
        <b-form-group class="text-right mt-4">
          <b-button
            variant="primary"
            type="submit"
            :disabled="isSubmitting"
          >
            <b-spinner v-if="isSubmitting" small />Submit
          </b-button>
        </b-form-group>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import appConfig from '@/app.config'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'ImageItemUpsert',

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    client: {
      type: Object,
      required: true
    },

    prefetch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      config: appConfig,
      isBusy: false,
      isSubmitting: false,
      newItem: {},
      input: {}
    }
  },

  computed: {
    tmpImageUrl() {
      return this.input.image ? URL.createObjectURL(this.input.image) : null
    },

    imageUrl() {
      return this.tmpImageUrl || _.get(this.newItem, 'url')
    }
  },

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        if (!_.isEqual(this.item, this.newItem)) {
          this.newItem = _.cloneDeep(this.item)

          this.$v.$reset()

          if (this.prefetch) {
            this.fetchItem()
          }
        }
      }
    },

    newItem: {
      immediate: true,
      deep: true,
      handler() {
        if (this.newItem && !_.isEqual(this.newItem, this.item)) {
          this.$emit('update:item', _.cloneDeep(this.newItem))
        }

        if (this.newItem) {
          this.setInput()
        }
      }
    }
  },

  validations() {
    return {
      input: {
        image: {
          ...!this.newItem.id ? { required } : null
        },
        category: { required },
        taken_at: { required }
      }
    }
  },

  methods: {
    setInput() {
      const fields = [
        'image',
        'category',
        'taken_at'
      ]

      const newIntput = {}
      _.each(fields, (field) => {
        newIntput[field] = this.newItem[field] || null
      })

      this.input = {
        ...newIntput,
        ...(!this.newItem.id ? { user_id: this.client.id} : null)
      }
      return this.input
    },

    getFieldState(vuelidateParam) {
      return vuelidateParam && vuelidateParam.$dirty ? !vuelidateParam.$invalid : null
    },

    getFieldInvalidFeedback(vuelidateParam) {
      let invalidFeedback = 'This field is invalid'

      if (vuelidateParam) {
        if (vuelidateParam.required === false) {
          invalidFeedback = 'This value is required.'

        } else if (vuelidateParam.minLength === false) {
          invalidFeedback = `Minimum ${vuelidateParam.$params.minLength.min} letters.`

        } else if (vuelidateParam.maxLength === false) {
          invalidFeedback = `Maximum ${vuelidateParam.$params.maxLength.max} letters.`

        } else if (vuelidateParam.between === false) {
          invalidFeedback = `Value max be between ${vuelidateParam.$params.between.min} and ${vuelidateParam.$params.between.max}.`

        } else if (vuelidateParam.email === false) {
          invalidFeedback = `Invalid email address.`
        }
      }
      return invalidFeedback
    },

    getFormData() {
      const formData = new FormData()
      _.each(this.input, (value, field) => {
        formData.append(field, value)
      })
      return formData
    },

    async formSubmit() {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      if (this.newItem.id) {
        await this.updateItem()

      } else {
        await this.createItem()
      }
    },

    fetchItem() {
      return this.$axios
        .get(`image/${this.newItem.id}`, {
          parent: this
        })
        .then(({ data }) => {
          this.newItem = data
        })
        .catch((e) => {
          throw e
        })
    },

    updateItem() {
      this.isSubmitting = true

      return this.$axios
        .patch(`image/${this.newItem.id}`, this.getFormData())
        .then(({ data }) => {
          this.newItem = data
          this.isSubmitting = false
          this.$emit('submitted', this.newItem)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    createItem() {
      this.isSubmitting = true

      return this.$axios
        .post('image', this.getFormData())
        .then(({ data }) => {
          this.newItem = data
          this.isSubmitting = false
          this.$emit('submitted', this.newItem)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },
  }
}
</script>


<style scoped>
.image-field {
  text-align: center;
}
.add-photo {
  height: auto;
}
.add-photo >>> input {
  display: none;
}
.add-photo >>> label {
  border-radius: 400px !important;
  width: 200px;
  height: 200px;
  padding-top: 80px;
  margin: 0 auto;
  vertical-align: middle;
  background: #626ed4 !important;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  position: relative;
}

.add-photo >>> label:after {
  content: none!important;
}
</style>