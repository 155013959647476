<template>
  <b-overlay :show="isFetching">
    <b-form
      v-if="isFetched || !isCreated"
      @submit.prevent="submit('submitted')"
    >
      <!-- NAME -->
      <b-form-group
        label="Name"
        label-cols-sm="2"
        :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
        :state="getFieldState($v.input.name)"
      >
        <b-form-input
          v-model="input.name"
          placeholder="Exercise group name"
          :state="getFieldState($v.input.name)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4 mb-0">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />{{ actionName }}
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { required } from "vuelidate/lib/validators"
import crudItemMixin from '@/mixins/crud-item'

export default {
  name: 'TrackerExerciseGroupItemUpsert',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'tracker-exercise-groups'
    }
  },

  validations() {
    return {
      input: {
        name: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        name: _.get(this.newItem, 'name', null)
      }

      if (!this.isCreated) {
        input.tracker_workout_id = _.get(this.newItem, 'tracker_workout_id', null)
      }

      return input
    }
  }
}
</script>