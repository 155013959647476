var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [(!_vm.newItem.cardio_sessions_per_week || !_vm.newItem.workouts_per_week) && !_vm.isFetching ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" You have not set your workout and cardio preferences in Histroy tab. ")]), _c('div', [_c('b-button', {
    attrs: {
      "variant": "primary",
      "to": "#step2"
    }
  }, [_vm._v(" Go to History ")])], 1)]) : _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('b-button', {
    staticClass: "mx-1 workout-event",
    attrs: {
      "squared": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addEvent({
          type: 'workout',
          duration: 1
        });
      }
    }
  }, [_vm._v(" Add Workout ")]), _c('b-button', {
    staticClass: "mx-1 cardio-event",
    attrs: {
      "squared": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addEvent({
          type: 'cardio',
          duration: 1
        });
      }
    }
  }, [_vm._v(" Add Cardio ")]), _c('b-button', {
    staticClass: "mx-1 work-event",
    attrs: {
      "squared": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addEvent({
          type: 'work',
          duration: 2
        });
      }
    }
  }, [_vm._v(" Add Work ")]), _c('b-button', {
    staticClass: "mx-1 sleep-event",
    attrs: {
      "squared": ""
    },
    on: {
      "click": function click($event) {
        return _vm.addEvent({
          type: 'sleep',
          duration: 2
        });
      }
    }
  }, [_vm._v(" Add Sleep ")])], 1), _c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Please add at least " + _vm._s(_vm.newItem.workouts_per_week) + " workouts and " + _vm._s(_vm.newItem.cardio_sessions_per_week) + " cardio sessions. You set these preferences in History tab. ")]), _c('b-form-group', [_c('ScheduleInput', {
    attrs: {
      "events": _vm.input.schedule,
      "events-selectable": false
    },
    on: {
      "update:events": function updateEvents($event) {
        return _vm.$set(_vm.input, "schedule", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "event-actions",
      fn: function fn(_ref) {
        var deleteEvent = _ref.deleteEvent;
        return [_c('b-button', {
          staticClass: "py-1 px-0 text-white",
          style: {
            fontSize: '8px'
          },
          attrs: {
            "variant": "link",
            "size": "sm"
          }
        }, [_c('b-icon', {
          staticClass: "no-drag",
          attrs: {
            "icon": "trash-fill"
          },
          on: {
            "click": deleteEvent
          }
        })], 1)];
      }
    }])
  })], 1), _c('b-alert', {
    attrs: {
      "show": !_vm.$v.input.schedule.minCardiosLength || !_vm.$v.input.schedule.minWorkoutsLength,
      "variant": "danger"
    }
  }, [!_vm.$v.input.schedule.minCardiosLength && !_vm.$v.input.schedule.minWorkoutsLength ? [_vm._v(" You do not have anough workouts (" + _vm._s(_vm.workoutEventCount) + "/" + _vm._s(_vm.newItem.workouts_per_week) + ") and cardio (" + _vm._s(_vm.cardioEventCount) + "/" + _vm._s(_vm.newItem.cardio_sessions_per_week) + ") sessions to match you preferences. ")] : !_vm.$v.input.schedule.minWorkoutsLength ? [_vm._v(" You do not have anough workouts (" + _vm._s(_vm.workoutEventCount) + "/" + _vm._s(_vm.newItem.workouts_per_week) + ") sessions to match you preferences. ")] : !_vm.$v.input.schedule.minCardiosLength ? [_vm._v(" You do not have anough cardio (" + _vm._s(_vm.cardioEventCount) + "/" + _vm._s(_vm.newItem.cardio_sessions_per_week) + ") sessions to match you preferences. ")] : _vm._e()], 2), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }