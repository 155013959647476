<template>
  <b-overlay :show="isFetching">
    <b-row
      class="mb-2"
      align-h="center"
    >
      <b-col lg="3">
        <ExerciseSelect
          v-model="newParams.filters.exercise_id"
          :item.sync="exercise"
          :show-create="false"
          size="sm"
        />
      </b-col>

      <b-col lg="5">
        <b-input-group>
          <b-form-datepicker
            v-model="newParams.filters.date_from"
            size="sm"
            placeholder="From Date"
          />

          <b-form-datepicker
            v-model="newParams.filters.date_to"
            size="sm"
            placeholder="To Date"
          />
        </b-input-group>
      </b-col>
    </b-row>

    <template v-if="exercise">
      <h1 class="my-4 text-center">{{ exercise.name }} Strength Gains</h1>

      <v-chart
        :option="option"
        autoresize
        :style="{
          height: '400px'
        }"
      />
    </template>

    <div v-else>
      <b-alert
        class="text-center"
        show
        variant="info"
      >
        Select an Exercise to view Strength Gains
      </b-alert>
    </div>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import ExerciseSelect from '@/components/exercises/ExerciseSelect'
import VChart from "vue-echarts"

import { use } from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([GridComponent, LineChart, CanvasRenderer])

export default {
  name: 'ClienyStrengthGains',

  mixins: [
    crudListMixin
  ],

  components: {
     VChart,
     ExerciseSelect
  },

  data() {
    return {
      baseEndpoint: 'tracker-exercises',
      exercise: null
    }
  },

  computed: {
    dates() {
      const dates = []
      const dateStart = this.$moment(this.newParams.filters.date_from)
      const dateEnd = this.$moment(this.newParams.filters.date_to)

      while (dateEnd.diff(dateStart, 'days') >= 0) {
        dates.push(dateStart.format('YYYY-MM-DD'))
        dateStart.add(1, 'days')
      }

      return dates
    },

    items() {
      return _.chain(this.dates)
        .map(date => ({
          date,
          lbs: _.chain(this.newResult.data)
            .filter(i => _.has(i, 'tracker_exercise_group.tracker_workout.log_time'))
            .filter(i => this.$moment(_.get(i, 'tracker_exercise_group.tracker_workout.log_time')).format('YYYY-MM-DD') === date)
            .flatMap('sets.items')
            .meanBy('lbs')
            .value()
        }))
        .filter(i => i.lbs)
        .value()
    },

    option() {
      return  {
        renderer: 'canvas',
        color: ['#626ed4'],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: _.map(this.items, i => i.date)
        },

        yAxis: {
          type: 'value',
          show: false
        },

        series: [
          {
            name: 'Data1',
            data: _.map(this.items, i => i.lbs),
            type: 'line',
            smooth: true,

            label: {
              show: true
            }
          }
        ]
      }
    }
  }
}
</script>