<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>
    </b-row>

    <b-card>
      <FormulaTest />
    </b-card>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import FormulaTest from '@/components/v1/formula-factors/test'

export default {
  name: 'FormulaTestPage',

  components: {
    Layout,
    PageHeader,
    FormulaTest
  },

  page() {
    return {
      title: this.title
    }
  },

  computed: {
    title() {
      return 'Projection Forecasting Tool'
    }
  }
}
</script>