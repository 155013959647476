<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>

      <b-col
        cols="4"
        class="d-flex align-items-center justify-content-end"
      >
        <UserItemUpsertButton
          v-if="$acl.check('couldAddStaff')"
          tag="b-button"
          :tag-props="{
            variant: 'primary'
          }"
          @update:item="refresh"
        >
          Add Staff
        </UserItemUpsertButton>
      </b-col>
    </b-row>

    <b-card>
      <UserList
        ref="list"
        :params="{
          filters: {
            role_ids: [2, 3, 4, 5]
          },
          order_desc: true
        }"
      />
    </b-card>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import UserList from '@/components/users/UserList'
import UserItemUpsertButton from '@/components/users/buttons/UserItemUpsertButton'
import { mapGetters } from 'vuex'

export default {
  name: 'UserPage',

  components: {
    Layout,
    PageHeader,
    UserList,
    UserItemUpsertButton
  },

  page() {
    return {
      title: this.title
    }
  },

  computed: {
    ...mapGetters({
      currentCompany: 'auth/currentCompany'
    }),

    title() {
      return 'Staff'
    }
  },

  methods: {
    refresh() {
      this.$refs.list.refresh()
    }
  }
}
</script>