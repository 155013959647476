<template>
  <b-overlay
    :show="isFetching"
  >
    <PhysicalConditionItemUpsert
      v-if="isFetched"
      :client="newItem"
      @submitted="$emit('submitted', $event)"
    />
  </b-overlay>
</template>

<script>
import crudItemMixins from '@/mixins/crud-item'
import PhysicalConditionItemUpsert from '@/components/physical-conditions/PhysicalConditionItemUpsert'

export default {
  name: 'ClientItemUpsertPhysicalCondition',

  mixins: [
    crudItemMixins
  ],

  components: {
    PhysicalConditionItemUpsert
  },

  data() {
    return {
      baseEndpoint: 'users'
    }
  }
}
</script>
