<template>
  <b-overlay :show="isFetching">
    <b-tabs
      active-nav-item-class="bg-tab text-primary"
      nav-class="border-0"
      justified
      no-fade
    >
      <b-tab
        v-for="phase in newItem.phases"
        :key="phase.id"
        lazy
        title-link-class="rounded-0 border-0"
        :title="`Phase ${phase.number}`"
      >
        <CurriculumPhaseMealsDetail
          :item="phase"
          :curriculum="item"
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import CurriculumPhaseMealsDetail from '@/components/curriculums/detail/CurriculumPhaseMealsDetail'

export default {
  name: 'CurriculumItemNutritionDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumPhaseMealsDetail
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  }
}
</script>