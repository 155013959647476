var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-5"
  }, [_c('div', {
    staticClass: "card image"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "supplement-image"
  }, [_vm.image ? _c('div', {
    staticClass: "preview"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.image,
      "alt": "Image",
      "fluid": ""
    }
  }), _c('button', {
    staticClass: "btn btn-primary delete-label",
    attrs: {
      "type": "reset"
    },
    on: {
      "click": _vm.onImageDelete
    }
  }, [_vm._v(" Remove Photo ")])], 1) : _vm._e(), _vm.form.image_url ? _c('div', {
    staticClass: "preview"
  }, [_c('b-img', {
    attrs: {
      "src": _vm.form.image_url,
      "alt": "Image",
      "fluid": ""
    }
  }), _c('button', {
    staticClass: "btn btn-primary delete-label",
    attrs: {
      "type": "reset"
    },
    on: {
      "click": _vm.onImageDelete
    }
  }, [_vm._v(" Remove Photo ")])], 1) : _vm._e(), _c('b-form-file', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.image && !_vm.form.image_url,
      expression: "!image && !form.image_url"
    }],
    staticClass: "add-photo",
    attrs: {
      "placeholder": "+ Add Photo",
      "drop-placeholder": "Drop file here..."
    },
    on: {
      "change": _vm.onImageSelect
    },
    model: {
      value: _vm.form.file,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "file", $$v);
      },
      expression: "form.file"
    }
  })], 1)])])]), _c('div', {
    staticClass: "col-lg-7"
  }, [_c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.formSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": "3",
      "label-cols-sm": "3",
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  }), _vm.formSubmitted && _vm.$v.form.name.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.name.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.form.name.minLength ? _c('span', [_vm._v(" This value is 5 letters minimum length. ")]) : _vm._e(), !_vm.$v.form.name.maxLength ? _c('span', [_vm._v(" This value is 200 letters maximum length. ")]) : _vm._e()]) : _vm._e()]), _c('b-form-group', {
    attrs: {
      "label-cols": "3",
      "label-cols-sm": "3",
      "label": "Type",
      "label-for": "type"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "mt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.type.$error
    },
    attrs: {
      "id": "type",
      "options": _vm.getSupplementTypesOptions()
    },
    model: {
      value: _vm.form.type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "type", $$v);
      },
      expression: "form.type"
    }
  }), _vm.formSubmitted && _vm.$v.form.type.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.type.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "3",
      "label-cols-sm": "3",
      "label": "Form",
      "label-for": "form"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "mt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.form.$error
    },
    attrs: {
      "id": "form",
      "options": _vm.supplementFormTypesOptions
    },
    model: {
      value: _vm.form.form,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "form", $$v);
      },
      expression: "form.form"
    }
  }), _vm.formSubmitted && _vm.$v.form.form.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.form.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "form-row form-group",
    attrs: {
      "role": "group"
    }
  }, [_c('label', {
    staticClass: "col-sm-3 col-3 col-form-label",
    attrs: {
      "for": "dose"
    }
  }, [_vm._v(" Dose ")]), _c('div', {
    staticClass: "bv-no-focus-ring col"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.dose,
      expression: "form.dose"
    }],
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.dose.$error
    },
    attrs: {
      "id": "dose",
      "type": "text",
      "placeholder": "Dose"
    },
    domProps: {
      "value": _vm.form.dose
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "dose", $event.target.value);
      }
    }
  }), _vm.formSubmitted && _vm.$v.form.dose.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.dose.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.form.dose.number ? _c('span', [_vm._v(" This value is numeric type. ")]) : _vm._e(), !_vm.$v.form.dose.minValue ? _c('span', [_vm._v(" This value minumum is 1. ")]) : _vm._e(), !_vm.$v.form.dose.maxValue ? _c('span', [_vm._v(" This value maximum is 1000. ")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "bv-no-focus-ring col"
  }, [_c('b-form-select', {
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.dose_type.$error
    },
    attrs: {
      "options": _vm.supplementDoseTypesOptionsWithEmptyValue
    },
    model: {
      value: _vm.form.dose_type,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "dose_type", $$v);
      },
      expression: "form.dose_type"
    }
  }), _vm.formSubmitted && _vm.$v.form.dose_type.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.dose_type.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1)]), _c('b-form-group', {
    attrs: {
      "label-cols": "3",
      "label-cols-sm": "3",
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.description.$error
    },
    attrs: {
      "type": "textarea",
      "rows": "5",
      "placeholder": "Description"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _vm.formSubmitted && _vm.$v.form.description.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.description.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group mb-0"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onFormCancel
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary m-l-5 ml-1",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])])])], 1)])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }