<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>
    </b-row>

    <b-card>
      <DailyActivityList
        :params="{
          limit: 400
        }"
      />
    </b-card>
  </Layout>
</template>

<script>
import appConfig from '@/app.config'
import Layout from '../../../layouts/main'
import PageHeader from '@/components/page-header'
import DailyActivityList from '@/components/v1/formula-factors/daily-activity/DailyActivityList'

export default {
  name: 'DailyActivityPage',

  components: {
    Layout,
    PageHeader,
    DailyActivityList
  },

  page: {
    title: 'Daily Activity',
    meta: [{ name: 'description', content: appConfig.description }],
  },

  data() {
    return {
      isBusy: false,
      config: appConfig
    }
  },

  computed: {
    title() {
      return 'Daily Activity'
    }
  }
}
</script>