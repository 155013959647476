var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "7"
    }
  }, [_c('b-button-group', _vm._l(_vm.steps, function (step, stepIndex) {
    return _c('b-button', {
      key: step.id,
      staticClass: "text-nowrap",
      "class": {
        'active': stepIndex === _vm.currentStepIndex,
        'completed': stepIndex < _vm.currentStepIndex
      },
      attrs: {
        "disabled": step.isDisabled,
        "to": "#".concat(step.id)
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]);
  }), 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "xl": "3"
    }
  }, [_c('CurriculumItemProfileSummaryShort', {
    staticClass: "d-block d-xl-none",
    attrs: {
      "item": _vm.newItem
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  }), _c('CurriculumItemProfileSummary', {
    staticClass: "d-none d-xl-block",
    attrs: {
      "item": _vm.newItem
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  })], 1), _c('b-col', {
    attrs: {
      "xl": "9"
    }
  }, [_c('b-card', {
    staticClass: "bg-transparent",
    attrs: {
      "body-class": "py-0 px-0"
    }
  }, [['completed', 'canceled'].includes(_vm.newItem.status) ? _c('CurriculumStatus', {
    staticClass: "mr-1",
    attrs: {
      "item": _vm.newItem
    }
  }) : _vm.currentStep.id === 'preview' ? _c('CurriculumItemPreviewUpdate', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      },
      "submitted": _vm.onNextStep
    }
  }) : _vm.currentStep.id === 'program' ? _c('CurriculumItemWorkoutsUpdate', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false,
      "mode": "program"
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      },
      "submitted": _vm.onNextStep
    }
  }) : _vm.currentStep.id === 'schedule' ? _c('CurriculumItemWorkoutsUpdate', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false,
      "mode": "data"
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      },
      "submitted": _vm.onNextStep
    }
  }) : _vm.currentStep.id === 'cardio' ? _c('CurriculumItemCardioUpdate', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false,
      "mode": "data"
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      },
      "submitted": _vm.onNextStep
    }
  }) : _vm.currentStep.id === 'supplements' ? _c('CurriculumItemSupplementsUpdate', {
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      },
      "submitted": _vm.onNextStep
    }
  }) : _vm.currentStep.id === 'nutrition' ? _c('CurriculumItemNutritionUpdate', {
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      },
      "submitted": _vm.onNextStep
    }
  }) : _vm.currentStep.id === 'review' ? _c('CurriculumItemReview', {
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      },
      "submitted": _vm.onNextStep
    }
  }) : _vm._e()], 1)], 1)], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }