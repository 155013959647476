var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? _c('PhysicalConditionItemUpsert', {
    attrs: {
      "client": _vm.newItem
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('submitted', $event);
      }
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }