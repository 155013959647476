<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-row v-if="!searchDisabled">
      <b-col
        class="mb-2"
      >
        <b-input
          :value="newParams.filters.search"
          size="sm"
          placeholder="Search workout..."
          @input="debounceSetFilterSearchParam"
        />
      </b-col>
    </b-row>

    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
      @row-clicked="!disabled ? selectedIds = [$event.id] : null"
    >
      <!-- SELECTED -->
      <template #cell(selected)="row">
        <b-icon
          variant="primary"
          font-scale="1.3"
          :icon="selectedIds.includes(row.item.id) ? 'check-circle-fill' : 'circle'"
          :style="{
            opacity: disabled ? '0.6': null,
          }"
        />
      </template>

      <!-- IS FAVORITE -->
      <template #cell(is_favorite)="row">
        <b-icon
          variant="warning"
          :icon="row.item.is_favorite ? 'star-fill' : 'star'"
        />
      </template>

      <!-- MUSCLES -->
      <template #cell(muscle_ids)="row">
        <Muscles :ids="row.item.muscle_ids || []" />
      </template>

      <!-- FITNESS LEVELS -->
      <template #cell(fitness_level_ids)="row">
        <Abilities :ids="row.item.fitness_level_ids || []" />
      </template>

      <!-- ACTIONS -->
      <template #cell(actions)="row">
        <WorkoutItemActionsButton
          :item="row.item"
          @delete:item="refresh"
          @submitted="refresh"
        >
          <b-icon-three-dots />
        </WorkoutItemActionsButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import Muscles from '@/components/v1/asset/Muscles'
import Abilities from '@/components/v1/asset/Abilities'
import WorkoutItemActionsButton from './buttons/WorkoutItemActionsButton'

export default {
  name: 'WorkoutSelect',
  
  mixins: [
    crudListMixin
  ],

  components: {
    Muscles,
    Abilities,
    WorkoutItemActionsButton
  },

  props: {
    value: {
      type: [Number, String],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    searchDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      baseEndpoint: 'workouts',

      fields: [
        { key: 'selected', sortable: false, label: '', thClass: 'w-50px' },
        { key: 'is_favorite', sortable: false, label: '', thClass: 'w-30px' },
        { key: 'name', sortable: false },
        { key: 'muscle_ids', sortable: false, label: 'Muscles' },
        { key: 'fitness_level_ids', sortable: false, label: 'Fitness Levels' },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'w-100px text-right' }
      ]
    }
  },

  computed: {
    selectedIds: {
      get() {
        return this.value ? [this.value] : [];
      },
      
      set(values) {
        const value = values.length ? values[0] : null
        this.$emit('input', value)
      }
    }
  },

  created() {
    this.debounceSetFilterSearchParam = _.debounce(async value => {
      this.newParams = {
        ...this.newParams,
        // Update filters
        filters: {
          ...this.newParams.filters,
          search: value
        },
        // Reset page to 1
        page: 1
      }
    }, 1000)
  },

  methods: {
    setSelectedItems() {
      // this.$nextTick()
    }
  }
}
</script>