<template>
  <b-overlay :show="isFetching && !newResult.data">
    <b-table-simple
      class="mb-0"
      striped
      stacked="sm"
    >
      <b-tbody>
        <b-tr
          v-for="(item, itemIndex) in newResult.data"
          :key="item.id"
        >
          <b-td
            class="w-30px align-middle text-center"
          >
            <span :style="{ cursor: 'move' }">
              <b-icon-grid3x2-gap />
            </span>
          </b-td>

          <b-td
            class="w-150px align-middle"
          >
            <ExerciseItemDetailButton
              class="text-decoration-underline-dotted"
              :item="item.exercise"
              :style="{
                cursor: 'pointer'
              }"
            >
              {{ item.exercise.name }}
            </ExerciseItemDetailButton>
          </b-td>

          <b-td class="align-middle">
            <ExerciseSetsInput
              v-model="input.items[itemIndex].sets"
            />
          </b-td>

          <b-td
            class="w-50px text-center align-middle"
          >
            <TrackerExerciseItemDeleteButton
              :item="item"
              tag="b-button"
              :tag-props="{
                variant: 'link',
                size: 'sm'
              }"
              @delete:item="refresh"
            >
              <b-icon-trash />
            </TrackerExerciseItemDeleteButton>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <div
      v-if="isSubmitting || isFetching"
      :style="{
        position: 'absolute',
        top: '15px',
        right: '15px'
      }"
    >
      <b-spinner small />
    </div>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import ExerciseItemDetailButton from '@/components/exercises/buttons/ExerciseItemDetailButton'
import TrackerExerciseItemDeleteButton from './buttons/TrackerExerciseItemDeleteButton'
import ExerciseSetsInput from './inputs/ExerciseSetsInput'

export default {
  name: 'TrackerExerciseList',

  mixins: [
    crudListMixin
  ],

  components: {
    ExerciseItemDetailButton,
    TrackerExerciseItemDeleteButton,
    ExerciseSetsInput
  },

  data() {
    return {
      baseEndpoint: 'tracker-exercises',
      autosave: true,
      debounceSubmitTime: 500
    }
  },

  methods: {
    getInput() {
      const input = {
        items: _.map(this.newResult.data, i => ({
          id: i.id,

          sets: {
            items: _.chain(i.sets)
              .get('items')
              .map(i => ({ reps: i.reps, lbs: i.lbs }))
              .value()
          }
        }))
      }

      return input
    },

    async afterSubmit() {
      // await this.refresh()
    }
  }
}
</script>