<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        v-if="!mode || mode === 'program'"
        :invalid-feedback="getFieldInvalidFeedback($v.input.workout_program_id)"
        :state="getFieldState($v.input.workout_program_id)"
      >
        <!-- SUGGESTED PROGRAMS -->
        <b-form-group label="Suggested Programs">
          <b-card
            class="bg-white"
            no-body
          >
            <WorkoutProgramSelect
              v-model="input.workout_program_id"
              is-suggested
              :params="{
                filters: {
                  workouts_per_week: availableWorkoutsPerWeek,
                  fitness_levels: [item.profile.fitness_level],
                  goal_directions: [item.profile.goal_direction],
                  genders: [item.profile.sex]
                }
              }"
              @input="unsetWorkouts"
            />
          </b-card>
        </b-form-group>

        <!-- OTHER PROGRAMS -->
        <b-form-group label="Other Programs">
          <b-card
            class="bg-white"
            no-body
          >
            <WorkoutProgramSelect
              v-model="input.workout_program_id"
              :params="{
                filters: {
                  workouts_per_week_exclude: availableWorkoutsPerWeek,
                  fitness_levels_exclude: [item.profile.fitness_level],
                  goal_directions_exclude: [item.profile.goal_direction],
                  genders_exclude: [item.profile.sex]
                }
              }"
              @input="unsetWorkouts"
            />
          </b-card>
        </b-form-group>
      </b-form-group>

      <template v-if="!mode || mode === 'data'">
        <b-tabs
          active-nav-item-class="bg-tab text-primary"
          nav-class="border-0"
          justified
          no-fade
        >
          <b-tab
            v-for="phase in newItem.phases"
            :key="phase.id"
            lazy
            title-link-class="rounded-0 border-0"
            :title="`Phase ${phase.number}`"
          >
            <CurriculumPhaseWorkouts
              :item="phase"
              :curriculum="item"
            />
          </b-tab>
        </b-tabs>
      </template>

      <!-- SUBMIT -->
      <b-form-group class="text-right my-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import WorkoutProgramSelect from "@/components/workout-programs/WorkoutProgramSelect"
import CurriculumPhaseWorkouts from '@/components/curriculums/CurriculumPhaseWorkouts'

export default {
  name: 'CurriculumItemWorkoutsUpdate',

  mixins: [
    crudItemMixin
  ],

  components: {
    WorkoutProgramSelect,
    CurriculumPhaseWorkouts
  },

  props: {
    mode: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  computed: {
    availableWorkoutsPerWeek() {
      return _.range(0, this.item.profile.workouts_per_week + 1)
    }
  },

  validations() {
    return {
      input: {
        workout_program_id: { required },
        phases: {
          ...(!this.mode || this.mode === 'data' ? { required } : null)
        }
      }
    }
  },

  methods: {
    unsetWorkouts() {
      _.each(this.input.phases, (phase) => {
        phase.workouts = []
      })
    },

    getInput() {
      const input = {
        workout_program_id: this.newItem.workout_program_id,
        phases: this.newItem.phases
      }
  
      return input
    }
  }
}
</script>