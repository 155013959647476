<template>
  <b-overlay :show="isFetching">
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- START DATE -->
      <b-form-group
        class="mb-4"
        label="Start date"
        label-class="text-uppercase font-size-24"
        :invalid-feedback="getFieldInvalidFeedback($v.input.start_date)"
        :state="getFieldState($v.input.start_date)"
      >
        <b-form-datepicker
          v-if="!isStarted"
          v-model="input.start_date"
          :min="$moment().toDate()"
          :state="getFieldState($v.input.start_date)"
        />

        <b-form-datepicker
          v-else
          :value="newItem.start_date"
          :disabled="isStarted"
        />
      </b-form-group>

      <!-- PROJECTED FAT AND ACTIVITY LEVELS -->
      <b-form-group
        class="mb-4"
        label="Projected fat % and activity level"
        label-class="text-uppercase font-size-24"
      >
        <CurriculumPhasesAbilityInput
          v-model="input.phases"
          :curriculum="item"
          mode="values"
          :disabled="isStarted"
        />

        <CurriculumPhasesBodyFatInput
          v-model="input.phases"
          :curriculum="item"
          :show-header="false"
          mode="values"
          :disabled="isStarted"
        />
      </b-form-group>

      <!-- Recommendations -->
      <b-form-group
        label="Recommendations"
        label-class="text-uppercase font-size-24"
      >
        <b-card
          no-body
          class="mb-4 bg-transparent"
        >
          <h4 class="text-primary mb-3">
            Cardio
          </h4>

          <CurriculumPhasesCardioInput
            class="mb-2"
            mode="suggestions"
            :curriculum="item"
          />
        </b-card>

        <b-card
          no-body
          class="mb-4 bg-transparent"
        >
          <h4 class="text-primary mb-3">
            Nutrients
          </h4>

          <CurriculumPhasesWaterInput
            mode="suggestions"
            :curriculum="item"
          />

          <CurriculumPhasesCaloriesInput
            mode="suggestions"
            :curriculum="item"
            :show-header="false"
          />
        </b-card>

        <b-card
          no-body
          class="mb-5 bg-transparent"
        >
          <h4 class="text-primary mb-3">
            Workout Programs
          </h4>

          <WorkoutProgramSelect
            class="bg-white"
            is-suggested
            :show-select="false"
            :params="{
              filters: {
                workouts_per_week: availableWorkoutsPerWeek,
                fitness_levels: [item.profile.fitness_level],
                goal_directions: [item.profile.goal_direction],
                genders: [item.profile.sex]
              }
            }"
          />
        </b-card>

        <b-card
          no-body
          class="mb-5 bg-transparent"
        >
          <h4 class="text-primary mb-3">
            Supplement Programs
          </h4>

          <SupplementProgramSelect
            class="bg-white"
            is-suggested
            :show-select="false"
            :params="{
              filters: {
                fitness_levels: [item.profile.fitness_level],
                goal_directions: [item.profile.goal_direction],
                genders: [item.profile.sex]
              }
            }"
          />
        </b-card>
      </b-form-group>

      <!-- PROJECTIONS -->
      <b-form-group
        class="mb-4"
        label="PROJECTIONS"
        label-class="text-uppercase font-size-24"
      >
        <CurriculumPhasesWeightInput
          class="mb-2"
          :curriculum="item"
          mode="suggestions"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small /> Submit
        </b-button>
      </b-form-group>

      <div
        v-if="isSubmitting"
        :style="{
          position: 'absolute',
          top: 0,
          right: 0
        }"
      >
        <b-spinner small/>
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import CurriculumPhasesAbilityInput from '@/components/curriculums/inputs/CurriculumPhasesAbilityInput'
import CurriculumPhasesBodyFatInput from '@/components/curriculums/inputs/CurriculumPhasesBodyFatInput'
import CurriculumPhasesWeightInput from '@/components/curriculums/inputs/CurriculumPhasesWeightInput'
import CurriculumPhasesCardioInput from '@/components/curriculums/inputs/CurriculumPhasesCardioInput'
import CurriculumPhasesWaterInput from '@/components/curriculums/inputs/CurriculumPhasesWaterInput'
import CurriculumPhasesCaloriesInput from '@/components/curriculums/inputs/CurriculumPhasesCaloriesInput'
import WorkoutProgramSelect from '@/components/workout-programs/WorkoutProgramSelect'
import SupplementProgramSelect from '@/components/supplement-programs/SupplementProgramSelect'

export default {
  name: 'CurriculumItemPreviewUpdate',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumPhasesAbilityInput,
    CurriculumPhasesBodyFatInput,
    CurriculumPhasesCardioInput,
    CurriculumPhasesWaterInput,
    CurriculumPhasesCaloriesInput,
    CurriculumPhasesWeightInput,
    WorkoutProgramSelect,
    SupplementProgramSelect
  },

  data() {
    return {
      baseEndpoint: 'curriculums',
      autosave: true,
      debounceSubmitTime: 500,
      hideSuccessMessage: true
    }
  },

  computed: {
    isStarted() {
      return (
        this.item.status !== 'designing' &&
        this.$moment().startOf('day') >= this.$moment(this.item.start_date)
      )
    },

    availableWorkoutsPerWeek() {
      return _.range(0, this.item.profile.workouts_per_week + 1)
    }
  },

  validations() {
    const input = {}

    if (!this.isStarted) {
      input.start_date = { required }
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        phases: _.map(this.newItem.phases, phase => ({
          id: phase.id,
          projected_body_fat_p: phase.projected_body_fat_p,
          projected_fitness_level_id: phase.projected_fitness_level_id
        }))
      }

      if (!this.isStarted) {
        input.start_date = this.newItem.start_date
      }

      return input
    }
  }
}
</script>