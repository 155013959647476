<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-table-simple
      class="font-size-12"
      fixed
      striped
    >
      <b-thead>
        <b-tr variant="primary">
          <b-td class="w-70px" />

          <b-td class="p-0">
            <b-row no-gutters>
              <b-col
                class="p-2"
                :style="{
                  width: 'calc((100% - 130px) / 2)',
                  maxWidth: 'calc((100% - 130px) / 2)'
                }"
              >
                Muscle
              </b-col>

              <b-col
                class="p-2"
                :style="{
                  width: 'calc((100% - 130px) / 2)',
                  maxWidth: 'calc((100% - 130px) / 2)'
                }"
              >
                Exercise
              </b-col>

              <b-col
                class="p-2"
                :style="{
                  width: '100px',
                  maxWidth: '100px'
                }"
              >
                Time per Set
              </b-col>

              <b-col
                :style="{
                  width: '30px',
                  maxWidth: '30px'
                }"
              />
            </b-row>
          </b-td>

          <b-td class="w-30px" />
        </b-tr>
      </b-thead>

      <Draggable
        v-model="input"
        tag="b-tbody"
        group="exerciseGroups"
        @update="bulkExerciseGroupUpdate(input)"
      >
        <b-tr
          v-for="exerciseGroup in input"
          :key="exerciseGroup.id"
          :style="{
            cursor: 'pointer'
          }"
        >
          <b-td
            class="text-center align-middle py-0"
            variant="success"
          >
            <ExerciseGroupItemUpsertButton
              :item="exerciseGroup"
              class="p-0 font-weight-bold font-size-12 w-100 text-truncate"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @submitted="refresh()"
            >
              {{ exerciseGroup.name }}
            </ExerciseGroupItemUpsertButton>
          </b-td>

          <b-td class="p-0">
            <b-table-simple
              class="m-0"
              fixed
              striped
              stacked="sm"
            >
              <Draggable
                v-if="exerciseGroup.exercise_group_items.length"
                v-model="exerciseGroup.exercise_group_items"
                tag="b-tbody"
                class="exercise-group-items"
                group="exerciseGroupItems"
                :move="onExerciseGroupItemMove"
                :data-id="exerciseGroup.id"
                @add="createExerciseGroupItem"
                @update="bulkExerciseGroupItemUpdate(exerciseGroup.exercise_group_items)"
              >
                <b-tr
                  v-for="exerciseGroupItem in exerciseGroup.exercise_group_items"
                  :key="exerciseGroupItem.id"
                  :style="{
                    cursor: 'pointer'
                  }"
                >
                  <b-td
                    class="align-middle p-2"
                    :style="{
                      width: 'calc((100% - 130px) / 2)'
                    }"
                  >
                    <Muscles
                      class="text-truncate d-block"
                      :ids="exerciseGroupItem.exercise.muscle_ids || []"
                    />
                  </b-td>

                  <b-td
                    class="align-middle p-2"
                    :style="{
                      width: 'calc((100% - 130px) / 2)'
                    }"
                  >
                    <div class="text-truncate">
                      {{ exerciseGroupItem.exercise.name }}
                    </div>
                  </b-td>

                  <b-td class="align-middle w-100px p-2">
                    {{ exerciseGroupItem.exercise.estimated_set_time }} secs
                  </b-td>

                  <b-td class="align-middle text-center p-0 w-30px">
                    <ExerciseGroupItemItemDeleteButton
                      class="p-0"
                      :item="exerciseGroupItem"
                      tag="b-button"
                      :tag-props="{
                        variant: 'link',
                        size: 'sm'
                      }"
                      @deleted:item="refresh()"
                    >
                      <b-icon-trash scale="0.8" />
                    </ExerciseGroupItemItemDeleteButton>
                  </b-td>
                </b-tr>
              </Draggable>

              <Draggable
                v-else
                tag="b-tbody"
                class="exercise-group-items"
                group="exerciseGroupItems"
                :data-id="exerciseGroup.id"
                @add="createExerciseGroupItem"
              >
                <b-tr>
                  <b-td
                    class="align-middle text-center"
                    colspan="4"
                  >
                    Move exercise here...
                  </b-td>
                </b-tr>
              </Draggable>
            </b-table-simple>
          </b-td>

          <b-td
            class="text-center align-middle p-0 w-30-px"
            variant="success"
          >
            <ExerciseGroupItemDeleteButton
              class="p-0"
              :item="exerciseGroup"
              tag="b-button"
              :tag-props="{
                class: 'p-0',
                variant: 'link',
                size: 'sm'
              }"
              @deleted:item="refresh()"
            >
              <b-icon-trash scale="0.8" />
            </ExerciseGroupItemDeleteButton>
          </b-td>
        </b-tr>
      </Draggable>

      <b-tbody>
        <b-tr variant="primary">
          <b-td />

          <b-td class="p-0">
            <b-row no-gutters>
              <b-col
                class="p-2"
                :style="{
                  width: 'calc((100% - 130px) / 2)',
                  maxWidth: 'calc((100% - 130px) / 2)'
                }"
              >
                Total
              </b-col>

              <b-col
                class="p-2"
                :style="{
                  width: 'calc((100% - 130px) / 2)',
                  maxWidth: 'calc((100% - 130px) / 2)'
                }"
              >
                {{ totalExerciseCount }} exercises
              </b-col>

              <b-col
                class="p-2"
                :style="{
                  width: '100px',
                  maxWidth: '100px'
                }"
              >
                {{ totalEstimatedSetTime }} secs
              </b-col>

              <b-col
                :style="{
                  width: '30px',
                  maxWidth: '30px'
                }"
              />
            </b-row>
          </b-td>

          <b-td />
        </b-tr>
      </b-tbody>

      <b-tbody>
        <b-tr>
          <b-td
            class="text-center"
            colspan="3"
          >
            <ExerciseGroupItemUpsertButton
              :item="{
                workout_id: newParams.filters.workout_id
              }"
              class="p-0"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @submitted="refresh()"
            >
              <b-icon-plus-circle class="mr-1" scale="0.8" /> Add set
            </ExerciseGroupItemUpsertButton>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import ExerciseGroupItemDeleteButton from './buttons/ExerciseGroupItemDeleteButton'
import ExerciseGroupItemItemDeleteButton from './buttons/ExerciseGroupItemItemDeleteButton'
import Muscles from '@/components/v1/asset/Muscles'
import Draggable from 'vuedraggable'
import ExerciseGroupItemUpsertButton from './buttons/ExerciseGroupItemUpsertButton'

export default {
  name: 'ExerciseGroupList',

  mixins: [
    crudListMixin
  ],

  components: {
    ExerciseGroupItemDeleteButton,
    ExerciseGroupItemItemDeleteButton,
    ExerciseGroupItemUpsertButton,
    Muscles,
    Draggable
  },

  data() {
    return {
      baseEndpoint: 'exercise-groups'
    }
  },

  computed: {
    exerciseList() {
      return _.chain(this.input)
        .flatMap('exercise_group_items')
        .flatMap('exercise')
        .value()
    },

    totalExerciseCount() {
      return _.size(this.exerciseList)
    },

    totalEstimatedSetTime() {
      return _.sumBy(this.exerciseList, 'estimated_set_time')
    }
  },

  methods: {
    getInput() {
      return this.newResult.data
    },

    onExerciseGroupItemMove({ from, to }) {
      // allow only reorder within same group
      if (from.getAttribute('data-id') !== to.getAttribute('data-id')) {
        return false
      }
    },

    createExerciseGroupItem({ clone, to }) {
      const input = {
        exercise_id: clone.getAttribute('data-id'),
        group_id: to.getAttribute('data-id')
      }

      const endpoint = `exercise-group-items`
      this.isSubmitting = true

      return this.$axios
        .post(endpoint, input)

        .then(async () => {
          this.refresh()
          this.isSubmitting = false
        })

        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    bulkExerciseGroupUpdate(items) {
      const input = {
        items: _.map(items, (i, index) => ({
          order: index + 1,
          id: i.id
        }))
      }

      const endpoint = `exercise-groups/bulk`
      this.isSubmitting = true

      return this.$axios
        .patch(endpoint, input)

        .then(async () => {
          this.refresh()
          this.isSubmitting = false
        })

        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    bulkExerciseGroupItemUpdate(items) {
      const input = {
        items: _.chain(items)
          .map((i, index) => ({ order: index + 1, id: i.id }))
          .filter(i => i.id)
          .value()
      }

      const endpoint = `exercise-group-items/bulk`
      this.isSubmitting = true

      return this.$axios
        .patch(endpoint, input)

        .then(async () => {
          this.refresh()
          this.isSubmitting = false
        })

        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>

<style lang="sass" scoped>
.exercise-group-items
  ::v-deep .sortable-ghost
    td
      overflow: hidden
      text-overflow: ellipsis
      white-space: nowrap
      opacity: 0.4
</style>