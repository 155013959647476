<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="onClick"
  >
    <b-spinner v-if="isSubmitting" small /><slot />
  </component>
</template>

<script>
export default {
  name: 'CompanyAppPlanItemCheckoutButton',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    params: {
      type: Object,
      default: () => ({})
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      isSubmitting: false
    }
  },

  methods: {
    onClick() {
      this.isSubmitting = true

      this.$axios
        .post(`company-app-plans/${this.item.id}/checkout`, this.params)
        .then(({ data }) => {
          window.location.href = data.url

          setTimeout(() => {
            this.isSubmitting = false
          }, 2000)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>