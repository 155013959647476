<template>
  <div
    class="py-1"
    :style="{
      width: '100%',
      overflow: daysCount > 1 ? 'scroll' : null,
    }"
  >
    <div
      v-if="layout"
      class="position-relative layout-wrapper"
      :style="{
        paddingTop: showDays ? '59px' : 0,
        minWidth: daysCount > 1 ? '840px' : null,
      }"
    >
      <ScheduleBackground
        :style="{
          color: '#999999'
        }"
        :events="bgEvents"
        :start-date="startDate"
        :days-count="daysCount"
        :phase="phase"
        :paused-at="pausedAt"
        :show-days="showDays"
        @bg-event-click="$emit('bg-event-click', $event)"
        @hour-click="$emit('hour-click', $event)"
      />

      <div>
        <grid-layout
          :layout.sync="layout"
          :col-num="daysCount"
          :max-rows="96"
          :row-height="10"
          :vertical-compact="false"
          :prevent-collision="true"
          :use-css-transforms="false"
          :margin="[74, 0]"
          :auto-size="false"
          :style="{
            height: '970px',
            marginRight: '-50px',
            marginLeft: '-24px',
          }"
          @layout-updated="onLayoutUpdated"
        >
          <grid-item
            v-for="(item, itemIndex) in layout"
            :key="itemIndex"
            :max-w="1"
            :i="item.i"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :is-resizable="item.isResizable"
            :static="item.isStatic"
            drag-ignore-from=".no-drag"
          >
            <ScheduleEvent
              :event="events[itemIndex]"
              @delete="deleteEventByIndex(itemIndex)"
            >
              <template v-slot:actions="{ event, toggleEventIsActive, deleteEvent }">
                <slot
                  name="event-actions"
                  :event="event"
                  :toggle-event-is-active="toggleEventIsActive"
                  :delete-event="deleteEvent"
                />
              </template>
            </ScheduleEvent>
          </grid-item>
        </grid-layout>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { GridLayout, GridItem } from 'vue-grid-layout'
import ScheduleBackground from './ScheduleBackground'
import ScheduleEvent from './ScheduleEvent'

export default {
  name: 'ScheduleInput',

  components: {
    GridLayout,
    GridItem,
    ScheduleBackground,
    ScheduleEvent
  },

  props: {
    events: {
      type: Array,
      default: null
    },

    bgEvents: {
      type: Array,
      default: null
    },

    eventProps: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    startDate: {
      type: Date,
      default: null
    },

    pausedAt: {
      type: Date,
      default: null
    },

    daysCount: {
      type: Number,
      default: 7
    },

    phase: {
      type: Object,
      default: null
    },

    showDays: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      layout: null,
      newEvents: null
    }
  },

  watch: {
    events: {
      immediate: true,
      deep: true,
      handler() {
        const newLayout = _.map(this.events, (e, i) => {
          const x = Math.floor(e.start / 24)
          const y = (e.start % 24) * 4
          const h = e.duration * 4 + y < 96 ? e.duration * 4 : 96 - y
          const w = 1
          const isResizable = ['sleep', 'work'].includes(e.type)
          const isStatic = e.isStatic || this.disabled
          return { i, x, y, h, w, isResizable, isStatic }
        })
        if (!_.isEqual(newLayout, this.layout)) {
          this.layout = newLayout
        }
      }
    }
  },

  methods: {
    onLayoutUpdated() {
      const newEvents = _.cloneDeep(this.events)
      _.each(newEvents, (e, i) => {
        e.start = this.layout[i].x * 24 + this.layout[i].y / 4
        e.duration = this.layout[i].h / 4
      })
      if (!_.isEqual(newEvents, this.events)) {
        this.$emit('update:events', newEvents)
      }
    },

    deleteEventByIndex(eventIndex) {
      const newEvents = _.filter(this.events, (event, index) => index !== eventIndex)
      this.$emit('update:events', _.cloneDeep(newEvents))
    }
  }
}
</script>

<style scoped>
.position-relative >>> .vue-grid-item.vue-grid-placeholder {
    background: #01A499 !important;
}
.position-relative >>> .vue-grid-item {
    transition: none;
}
</style>