<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Secret Key"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.stripe_secret_key)"
        :state="getFieldState($v.input.stripe_secret_key)"
      >
        <b-form-input
          v-model="input.stripe_secret_key"
          placeholder="Enter Stripe Secret Key"
          :state="getFieldState($v.input.stripe_secret_key)"
        />
      </b-form-group>

      <b-form-group
        label="Signing Secret"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.stripe_secret_key)"
        :state="getFieldState($v.input.stripe_signing_secret)"
      >
        <b-form-input
          v-model="input.stripe_signing_secret"
          placeholder="Enter Stripe Signing Secret"
          :state="getFieldState($v.input.stripe_signing_secret)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right my-4">
        <b-button
          variant="success"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'CompanyItemStripe',

  mixins: [
    crudItemMixin
  ],

  computed: {
    baseEndpoint() {
      return this.$acl.check('isAdmin') ? 'admin/companies' : 'companies'
    }
  },

  validations() {
    const input = {
      stripe_secret_key: { required },
      stripe_signing_secret: { required },
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        stripe_secret_key: null,
        stripe_signing_secret: null
      }

      return input
    }
  }
}
</script>