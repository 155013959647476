var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-button', {
    attrs: {
      "variant": "text"
    },
    on: {
      "click": function click($event) {
        return _vm.showModal();
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-1",
    attrs: {
      "variant": "primary",
      "icon": _vm.adminView ? 'check-circle-fill' : 'circle'
    }
  }), _vm._v(" Admin ")], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }