<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>

      <b-col
        cols="4"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          to="/company/add"
          variant="primary"
        >
          Add Company
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <CompanyList/>
    </b-card>
  </Layout>
</template>

<script>
import appConfig from '@/app.config'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import CompanyList from '@/components/companies/CompanyList'

export default {
  name: 'CompanyListView',
  components: {
    Layout,
    PageHeader,
    CompanyList
  },
  page: {
    title: 'Companies',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      config: appConfig,
      title: 'Companies'
    }
  },
}
</script>
