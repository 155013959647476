var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [!_vm.isCreated || _vm.isFetched ? [_vm.isEditable ? _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-row', [_c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_c('div', {
    staticClass: "image-field text-center mt-5"
  }, [_vm.tmpImageUrl || _vm.imageUrl && !_vm.input.remove_profile_image ? _c('div', [_c('b-avatar', {
    attrs: {
      "src": _vm.imageUrl,
      "size": "12rem"
    }
  }), _c('div', {
    staticClass: "image-remove"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.input.remove_profile_image = true;
        _vm.input.profile_image = null;
      }
    }
  }, [_vm._v(" Remove Photo ")])], 1)], 1) : _c('b-form-file', {
    staticClass: "add-image",
    attrs: {
      "placeholder": "+ Add Photo",
      "drop-placeholder": "Drop file here...",
      "state": _vm.getFieldState(_vm.$v.input.profile_image)
    },
    on: {
      "change": function change($event) {
        _vm.input.profile_image = $event.target.files[0];
      }
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "lg": "7"
    }
  }, [_vm.showRoles && _vm.availableRoleIds.length ? _c('b-form-group', {
    attrs: {
      "label": "Roles",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.role_ids),
      "state": _vm.getFieldState(_vm.$v.input.role_ids)
    }
  }, [_c('FormInputButtons', {
    attrs: {
      "tag": "b-button-group",
      "multiple": "",
      "disabled-values": _vm.disabledRoleIds,
      "options": _vm.$store.getters['asset/getRolesOptions']
    },
    model: {
      value: _vm.input.role_ids,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "role_ids", $$v);
      },
      expression: "input.role_ids"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-cols": "3"
    }
  }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.first_name),
      "state": _vm.getFieldState(_vm.$v.input.first_name)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "First Name",
      "squared": "",
      "state": _vm.getFieldState(_vm.$v.input.first_name)
    },
    model: {
      value: _vm.input.first_name,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "first_name", $$v);
      },
      expression: "input.first_name"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.last_name),
      "state": _vm.getFieldState(_vm.$v.input.last_name)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Last Name",
      "state": _vm.getFieldState(_vm.$v.input.last_name)
    },
    model: {
      value: _vm.input.last_name,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "last_name", $$v);
      },
      expression: "input.last_name"
    }
  })], 1)], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "D.O.B.",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.birthdate),
      "state": _vm.getFieldState(_vm.$v.input.birthdate)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "date",
      "state": _vm.getFieldState(_vm.$v.input.birthdate)
    },
    model: {
      value: _vm.input.birthdate,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "birthdate", $$v);
      },
      expression: "input.birthdate"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-cols": "3"
    }
  }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.address_line1),
      "state": _vm.getFieldState(_vm.$v.input.address_line1)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Address",
      "squared": "",
      "state": _vm.getFieldState(_vm.$v.input.address_line1)
    },
    model: {
      value: _vm.input.address_line1,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "address_line1", $$v);
      },
      expression: "input.address_line1"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.city),
      "state": _vm.getFieldState(_vm.$v.input.city)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "City",
      "state": _vm.getFieldState(_vm.$v.input.city)
    },
    model: {
      value: _vm.input.city,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "city", $$v);
      },
      expression: "input.city"
    }
  })], 1)], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "State",
      "label-cols": "3"
    }
  }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.state),
      "state": _vm.getFieldState(_vm.$v.input.state)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "State",
      "squared": "",
      "state": _vm.getFieldState(_vm.$v.input.state)
    },
    model: {
      value: _vm.input.state,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "state", $$v);
      },
      expression: "input.state"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.zip),
      "state": _vm.getFieldState(_vm.$v.input.zip)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Zip",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.zip)
    },
    model: {
      value: _vm.input.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "zip", $$v);
      },
      expression: "input.zip"
    }
  })], 1)], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Phone",
      "label-cols": "3"
    }
  }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.phone),
      "state": _vm.getFieldState(_vm.$v.input.phone)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Cell Number",
      "state": _vm.getFieldState(_vm.$v.input.phone)
    },
    model: {
      value: _vm.input.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phone", $$v);
      },
      expression: "input.phone"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.home_number),
      "state": _vm.getFieldState(_vm.$v.input.home_number)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Home Number",
      "state": _vm.getFieldState(_vm.$v.input.home_number)
    },
    model: {
      value: _vm.input.home_number,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "home_number", $$v);
      },
      expression: "input.home_number"
    }
  })], 1)], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.email),
      "state": _vm.getFieldState(_vm.$v.input.email)
    }
  }, [!_vm.newItem.id ? _c('b-form-input', {
    attrs: {
      "placeholder": "Email Address",
      "state": _vm.getFieldState(_vm.$v.input.email)
    },
    model: {
      value: _vm.input.email,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "email", $$v);
      },
      expression: "input.email"
    }
  }) : _c('b-form-input', {
    attrs: {
      "value": _vm.newItem.email,
      "placeholder": "Email Address",
      "disabled": ""
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Time zone",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.timezone),
      "state": _vm.getFieldState(_vm.$v.input.timezone)
    }
  }, [_c('TimezoneSelect', {
    model: {
      value: _vm.input.timezone,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "timezone", $$v);
      },
      expression: "input.timezone"
    }
  })], 1), _vm.isClient ? _c('b-form-group', {
    attrs: {
      "label": "Emergency contact",
      "label-cols": "3"
    }
  }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.emergency_contact),
      "state": _vm.getFieldState(_vm.$v.input.emergency_contact)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Name",
      "state": _vm.getFieldState(_vm.$v.input.emergency_contact)
    },
    model: {
      value: _vm.input.emergency_contact,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "emergency_contact", $$v);
      },
      expression: "input.emergency_contact"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.emergency_phone),
      "state": _vm.getFieldState(_vm.$v.input.emergency_phone)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Phone Number",
      "state": _vm.getFieldState(_vm.$v.input.emergency_phone)
    },
    model: {
      value: _vm.input.emergency_phone,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "emergency_phone", $$v);
      },
      expression: "input.emergency_phone"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.isClient ? _c('b-form-group', {
    attrs: {
      "label": "Occupation",
      "label-cols": "3"
    }
  }, [_c('b-form-row', [_c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.occupation),
      "state": _vm.getFieldState(_vm.$v.input.occupation)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Occupation",
      "squared": "",
      "state": _vm.getFieldState(_vm.$v.input.occupation)
    },
    model: {
      value: _vm.input.occupation,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "occupation", $$v);
      },
      expression: "input.occupation"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.employer),
      "state": _vm.getFieldState(_vm.$v.input.employer)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Employer",
      "state": _vm.getFieldState(_vm.$v.input.employer)
    },
    model: {
      value: _vm.input.employer,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "employer", $$v);
      },
      expression: "input.employer"
    }
  })], 1)], 1)], 1)], 1) : _vm._e(), _vm.isClient ? _c('b-form-group', {
    attrs: {
      "label": "Fitness Level",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.fitness_level),
      "state": _vm.getFieldState(_vm.$v.input.fitness_level)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getAbilityLevelsOptions'],
      "state": _vm.getFieldState(_vm.$v.input.fitness_level)
    },
    model: {
      value: _vm.input.fitness_level,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "fitness_level", $$v);
      },
      expression: "input.fitness_level"
    }
  })], 1) : _vm._e(), _vm.isClient ? _c('b-form-group', {
    attrs: {
      "label": "Goal Direction",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.goal_direction),
      "state": _vm.getFieldState(_vm.$v.input.goal_direction)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getGoalDirectionsOptions'],
      "state": _vm.getFieldState(_vm.$v.input.goal_direction)
    },
    model: {
      value: _vm.input.goal_direction,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "goal_direction", $$v);
      },
      expression: "input.goal_direction"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Gender",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.sex),
      "state": _vm.getFieldState(_vm.$v.input.sex)
    }
  }, [_c('b-form-radio-group', {
    attrs: {
      "options": _vm.$store.getters['asset/getSexOptions'],
      "button-variant": "outline-primary",
      "state": _vm.getFieldState(_vm.$v.input.sex)
    },
    model: {
      value: _vm.input.sex,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "sex", $$v);
      },
      expression: "input.sex"
    }
  })], 1), _vm.isStaff ? _c('b-form-group', {
    attrs: {
      "label": "Bio:",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.bio),
      "state": _vm.getFieldState(_vm.$v.input.bio)
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "5",
      "state": _vm.getFieldState(_vm.$v.input.bio)
    },
    model: {
      value: _vm.input.bio,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "bio", $$v);
      },
      expression: "input.bio"
    }
  })], 1) : _vm._e(), _vm.isClient ? _c('b-form-group', {
    attrs: {
      "label": "Primary trainer",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.primary_trainer_id),
      "state": _vm.getFieldState(_vm.$v.input.primary_trainer_id)
    }
  }, [_vm.primaryTrainerIsEditable ? _c('UserSelect', {
    attrs: {
      "item": _vm.newItem.primary_trainer,
      "show-create": false,
      "params": {
        filters: {
          role_ids: [2]
        }
      },
      "placeholder": "Type a primary trainer name"
    },
    model: {
      value: _vm.input.primary_trainer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "primary_trainer_id", $$v);
      },
      expression: "input.primary_trainer_id"
    }
  }) : _c('UserSelect', {
    attrs: {
      "value": _vm.newItem.primary_trainer ? _vm.newItem.primary_trainer.id : null,
      "item": _vm.newItem.primary_trainer,
      "disabled": "",
      "params": {
        filters: {
          role_ids: [2]
        }
      },
      "placeholder": "No primary trainer"
    }
  })], 1) : _vm._e(), _vm.isClient ? _c('b-form-group', {
    attrs: {
      "label": "Secondary trainer",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.secondary_trainer_id),
      "state": _vm.getFieldState(_vm.$v.input.secondary_trainer_id)
    }
  }, [_vm.secondaryTrainerIsEditable ? _c('UserSelect', {
    attrs: {
      "item": _vm.newItem.secondary_trainer,
      "show-create": false,
      "params": {
        filters: {
          role_ids: [2]
        }
      },
      "placeholder": "Type a secondary trainer name"
    },
    model: {
      value: _vm.input.secondary_trainer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "secondary_trainer_id", $$v);
      },
      expression: "input.secondary_trainer_id"
    }
  }) : _c('UserSelect', {
    attrs: {
      "value": _vm.newItem.secondary_trainer ? _vm.newItem.secondary_trainer.id : null,
      "item": _vm.newItem.secondary_trainer,
      "disabled": "",
      "params": {
        filters: {
          role_ids: [2]
        }
      },
      "placeholder": "No secondary trainer"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)], 1)], 1)], 1) : _vm.isCreated && !_vm.isFetching ? _c('div', [_vm._v(" You can't edit this user. ")]) : _vm._e()] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }