var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._b({
    tag: "component",
    attrs: {
      "variant": _vm.variant
    }
  }, 'component', _vm.tagProps, false), [_vm._v(" " + _vm._s(_vm.title) + " ")]);
}
var staticRenderFns = []

export { render, staticRenderFns }