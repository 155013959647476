<template>
  <Layout>
    <b-alert
      v-if="!curriculum"
      show
      variant="warning"
    >
      You have no active curriculum
    </b-alert>

    <b-alert
      v-else-if="curriculum.status === 'paused'"
      show
      variant="warning"
    >
      Your curriculum is paused
    </b-alert>

    <div v-else>
      Nutritional Tracker
    </div>
  </Layout>
</template>

<script>
import _ from 'lodash'
import Layout from '@/router/layouts/main'
import { mapGetters } from 'vuex'

export default {
  name: 'NutritionalTrackerPage',

  components: {
    Layout
  },

  page() {
    return {
      title: this.title
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    curriculum() {
      return _.get(this.currentUser, 'curriculum')
    },

    title() {
      return 'Nutritional Tracker'
    }
  }
}
</script>