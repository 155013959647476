var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-2 text-nowrap",
    attrs: {
      "fixed": "",
      "striped": "",
      "stacked": "sm"
    }
  }, [_c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "w-150px"
  }), _c('b-th', [_vm._v("Foods")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Calories")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Protein")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Carbs")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Fats")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Sodium")]), _c('b-th', {
    staticClass: "text-center w-50px"
  }), _c('b-th', {
    staticClass: "text-center overflow-hidden w-150px"
  }, [_vm._v("Supplements")])], 1)], 1), _vm.newResult.data && _vm.newResult.data.length ? _vm._l(_vm.newResult.data, function (meal) {
    return _c('b-tbody', {
      key: meal.id
    }, [_c('b-tr', {
      staticClass: "align-middle"
    }, [_c('b-th', {
      staticClass: "text-center align-middle position-relative overflow-hidden",
      attrs: {
        "variant": "success",
        "rowspan": meal.meal_foods.length ? meal.meal_foods.length + 3 : 4
      }
    }, [!_vm.disabled ? _c('CurriculumMealItemUpsertButton', {
      staticClass: "p-0 font-weight-bold",
      attrs: {
        "item": meal,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": function submitted($event) {
          _vm.$emit('submitted:item', $event);

          _vm.refresh();
        }
      }
    }, [_vm._v(" " + _vm._s(meal.name) + " ")]) : _c('div', [_vm._v(" " + _vm._s(meal.name) + " ")]), _c('div', [_c('b-badge', [_c('MealTypes', {
      attrs: {
        "ids": [meal.type]
      }
    })], 1)], 1), _c('div', [_vm._v(_vm._s(_vm.$moment(meal.taken_at, 'HH:mm:ss').format('hh:mm A')))]), !_vm.disabled ? _c('CurriculumMealItemDeleteButton', {
      attrs: {
        "item": meal,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "delete:item": function deleteItem($event) {
          _vm.$emit('delete:item', $event);

          _vm.refresh();
        }
      }
    }, [_c('b-icon-trash', {
      attrs: {
        "scale": "0.8"
      }
    })], 1) : _vm._e()], 1)], 1), meal.meal_foods.length ? _vm._l(meal.meal_foods, function (mealFood, mealFoodIndex) {
      return _c('b-tr', {
        key: mealFood.id
      }, [_c('b-td', {
        staticClass: "align-middle"
      }, [!_vm.disabled ? _c('CurriculumMealFoodItemUpsertButton', {
        staticClass: "p-0 font-weight-bold",
        attrs: {
          "item": mealFood,
          "tag": "b-button",
          "tag-props": {
            variant: 'link'
          }
        },
        on: {
          "submitted": function submitted($event) {
            _vm.$emit('submitted:food', $event);

            _vm.refresh();
          }
        }
      }, [_vm._v(" " + _vm._s(mealFood.food.name) + " ")]) : _c('div', [_vm._v(" " + _vm._s(mealFood.food.name) + " ")]), _c('div', [_vm._v(_vm._s(mealFood.amount) + " " + _vm._s(mealFood.food.serving_units))])], 1), _c('b-td', {
        staticClass: "text-center align-middle"
      }, [_vm._v(_vm._s(_vm.calcMealFoodMacro(mealFood, 'calories') || '-'))]), _c('b-td', {
        staticClass: "text-center align-middle"
      }, [_vm._v(_vm._s(_vm.calcMealFoodMacro(mealFood, 'protein') || '-') + " gm")]), _c('b-td', {
        staticClass: "text-center align-middle"
      }, [_vm._v(_vm._s(_vm.calcMealFoodMacro(mealFood, 'carbs') || '-') + " gm")]), _c('b-td', {
        staticClass: "text-center align-middle"
      }, [_vm._v(_vm._s(_vm.calcMealFoodMacro(mealFood, 'fat') || '-') + " gm")]), _c('b-td', {
        staticClass: "text-center align-middle"
      }, [_vm._v(_vm._s(_vm.calcMealFoodMacro(mealFood, 'sodium') || '-') + " mg")]), _c('b-td', {
        staticClass: "text-center align-middle"
      }, [!_vm.foodsDisabled ? _c('CurriculumMealFoodItemDeleteButton', {
        staticClass: "p-0",
        attrs: {
          "item": mealFood,
          "tag": "b-button",
          "tag-props": {
            variant: 'link'
          }
        },
        on: {
          "delete:item": function deleteItem($event) {
            _vm.$emit('delete:food', $event);

            _vm.refresh();
          }
        }
      }, [_c('b-icon-trash', {
        attrs: {
          "scale": "0.8"
        }
      })], 1) : _vm._e()], 1), mealFoodIndex === 0 ? _c('b-td', {
        attrs: {
          "rowspan": meal.meal_foods.length || 1,
          "variant": "secondary"
        }
      }, [_c('CurriculumSupplementList', {
        key: meal.id,
        attrs: {
          "params": {
            filters: {
              phase_id: _vm.newParams.filters.phase_id,
              day_number: _vm.newParams.filters.day_number,
              taken_at: meal.taken_at
            }
          },
          "prefetch": false,
          "result": {
            data: _vm.getSupplementsByTakenAt(meal.taken_at)
          },
          "show-time": false,
          "disabled": _vm.supplementsDisabled
        },
        on: {
          "submitted:item": function submittedItem($event) {
            return _vm.$emit('submitted:supplement', $event);
          },
          "delete:item": function deleteItem($event) {
            return _vm.$emit('delete:supplement', $event);
          }
        }
      })], 1) : _vm._e()], 1);
    }) : _c('b-tr', [_c('b-th', {
      staticClass: "p-0 text-center",
      attrs: {
        "colspan": "7"
      }
    }, [_c('b-alert', {
      attrs: {
        "show": "",
        "variant": "light"
      }
    }, [_vm._v(" There are no foods in this meal. ")])], 1), _c('b-td', {
      attrs: {
        "rowspan": meal.meal_foods.length || 1,
        "variant": "secondary"
      }
    }, [_c('CurriculumSupplementList', {
      key: meal.id,
      attrs: {
        "params": {
          filters: {
            phase_id: _vm.newParams.filters.phase_id,
            day_number: _vm.newParams.filters.day_number,
            taken_at: meal.taken_at
          }
        },
        "prefetch": false,
        "result": {
          data: _vm.getSupplementsByTakenAt(meal.taken_at)
        },
        "show-time": false,
        "disabled": _vm.supplementsDisabled
      },
      on: {
        "submitted:item": function submittedItem($event) {
          return _vm.$emit('submitted:supplement', $event);
        },
        "delete:item": function deleteItem($event) {
          return _vm.$emit('delete:supplement', $event);
        }
      }
    })], 1)], 1), _c('b-tr', [!_vm.foodsDisabled ? _c('b-td', {
      staticClass: "align-middle",
      attrs: {
        "colspan": "7"
      }
    }, [_c('b-row', {
      attrs: {
        "align": "between"
      }
    }, [_c('b-col', {
      staticClass: "text-center"
    }, [_c('CurriculumMealFoodItemUpsertButton', {
      staticClass: "p-0 mr-4",
      attrs: {
        "item": {
          meal_id: meal.id
        },
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": function submitted($event) {
          _vm.$emit('submitted:food', $event);

          _vm.refresh();
        }
      }
    }, [_c('b-icon-plus-circle', {
      staticClass: "mr-1",
      attrs: {
        "scale": "0.8"
      }
    }), _vm._v(" Add food ")], 1)], 1)], 1)], 1) : _vm._e(), !_vm.supplementsDisabled ? _c('b-td', {
      staticClass: "align-middle",
      attrs: {
        "variant": "secondary",
        "rowspan": "1"
      }
    }, [_c('CurriculumSupplementItemUpsertButton', {
      staticClass: "px-0",
      attrs: {
        "item": {
          phase_id: _vm.newParams.filters.phase_id,
          day_number: _vm.newParams.filters.day_number,
          taken_at: meal.taken_at
        },
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": function submitted($event) {
          return _vm.$emit('submitted:supplement', $event);
        }
      }
    }, [_c('b-icon-plus-circle', {
      attrs: {
        "scale": "0.8"
      }
    }), _vm._v(" Add supplement ")], 1)], 1) : _vm._e()], 1), _c('b-tr', {
      attrs: {
        "variant": "secondary"
      }
    }, [_c('b-td', {
      staticClass: "text-center font-weight-bolder",
      attrs: {
        "variant": "primary"
      }
    }, [_vm._v(" Meal Totals ")]), _c('b-td', {
      staticClass: "text-center font-weight-bolder",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(_vm._s(_vm.calcMealMacro(meal, 'calories') || '-'))]), _c('b-td', {
      staticClass: "text-center font-weight-bolder",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(_vm._s(_vm.calcMealMacro(meal, 'protein') || '-'))]), _c('b-td', {
      staticClass: "text-center font-weight-bolder",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(_vm._s(_vm.calcMealMacro(meal, 'carbs') || '-'))]), _c('b-td', {
      staticClass: "text-center font-weight-bolder",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(_vm._s(_vm.calcMealMacro(meal, 'fat') || '-'))]), _c('b-td', {
      staticClass: "text-center font-weight-bolder",
      attrs: {
        "variant": "secondary"
      }
    }, [_vm._v(_vm._s(_vm.calcMealMacro(meal, 'sodium') || '-'))]), _c('b-td', {
      attrs: {
        "colspan": "2"
      }
    })], 1), _c('b-tr', {
      attrs: {
        "variant": "primary"
      }
    }, [_c('b-th', {
      staticClass: "py-2",
      attrs: {
        "colspan": "9"
      }
    })], 1)], 2);
  }) : _c('b-tbody', [_c('b-tr', [_c('b-td', {
    staticClass: "text-center p-0",
    attrs: {
      "colspan": "9"
    }
  }, [_vm.isFetching ? _c('b-alert', {
    staticClass: "m-0",
    attrs: {
      "show": "",
      "variant": "light"
    }
  }, [_vm._v(" Loading... ")]) : _c('b-alert', {
    staticClass: "m-0",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" There are no meals and foods. ")])], 1)], 1)], 1), _c('b-tbody', [!_vm.disabled ? _c('b-tr', [_c('b-td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "9"
    }
  }, [_c('CurriculumMealItemUpsertButton', {
    staticClass: "px-0",
    attrs: {
      "item": {
        phase_id: _vm.newParams.filters.phase_id,
        day_number: _vm.newParams.filters.day_number
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'link',
        disabled: _vm.disabled
      }
    },
    on: {
      "submitted": function submitted($event) {
        _vm.$emit('submitted:item', $event);

        _vm.refresh();
      }
    }
  }, [_c('b-icon-plus-circle', {
    staticClass: "mr-1",
    attrs: {
      "scale": "0.8"
    }
  }), _vm._v(" Add meal ")], 1)], 1)], 1) : _vm._e(), _c('b-tr', {
    attrs: {
      "variant": "secondary"
    }
  }, [_c('b-td', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "variant": "primary",
      "colspan": "2"
    }
  }, [_vm._v(" Daily Totals ")]), _c('b-td', {
    staticClass: "text-center font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.calcMealsMacro(_vm.newResult.data, 'calories') || '-'))]), _c('b-td', {
    staticClass: "text-center font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.calcMealsMacro(_vm.newResult.data, 'protein') || '-'))]), _c('b-td', {
    staticClass: "text-center font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.calcMealsMacro(_vm.newResult.data, 'carbs') || '-'))]), _c('b-td', {
    staticClass: "text-center font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.calcMealsMacro(_vm.newResult.data, 'fat') || '-'))]), _c('b-td', {
    staticClass: "text-center font-weight-bolder"
  }, [_vm._v(_vm._s(_vm.calcMealsMacro(_vm.newResult.data, 'sodium') || '-'))]), _c('b-td', {
    attrs: {
      "colspan": "2"
    }
  })], 1)], 1)], 2), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }