export const sex = [
  { id: 'male', name: 'Male' },
  { id: 'female', name: 'Female' },
];

export const supplementFormTypes = [
  { id: '1', name: 'Liquid' },
  { id: '2', name: 'Tablet' },
  { id: '3', name: 'Powder' },
];

export const supplementDoseTypes = [
  { id: '1', name: 'Scoops' },
  { id: '2', name: 'Grams' },
  { id: '3', name: 'Ounces' },
  { id: '4', name: 'Capsule Tabs' },
];

export const workoutTypeIcons = {
  1: 'fas fa-dumbbell',
  2: 'far fa-heart',
}
