<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- WATER DATA -->
      <b-form-group
        :invalid-feedback="getFieldInvalidFeedback($v.input.phases)"
        :state="getFieldState($v.input.phases)"
      >
        <CurriculumPhasesWaterInput
          v-model="input.phases"
          :curriculum="item"
        />
      </b-form-group>

      <b-form-group
        :invalid-feedback="getFieldInvalidFeedback($v.input.supplement_program_id)"
        :state="getFieldState($v.input.supplement_program_id)"
      >
        <!-- SUGGESTED PROGRAMS -->
        <b-form-group label="Suggested Programs">
          <b-card
            class="bg-white"
            no-body
          >
            <SupplementProgramSelect
              v-model="input.supplement_program_id"
              is-suggested
              :params="{
                filters: {
                  fitness_levels: [item.profile.fitness_level],
                  goal_directions: [item.profile.goal_direction],
                  genders: [item.profile.sex]
                }
              }"
              @input="unsetSupplements"
            />
          </b-card>
        </b-form-group>

        <!-- OTHER PROGRAMS -->
        <b-form-group label="Other Programs">
          <b-card
            class="bg-white"
            no-body
          >
            <SupplementProgramSelect
              v-model="input.supplement_program_id"
              :params="{
                filters: {
                  fitness_levels_exclude: [item.profile.fitness_level],
                  goal_directions_exclude: [item.profile.goal_direction],
                  genders_exclude: [item.profile.sex]
                }
              }"
              @input="unsetSupplements"
            />
          </b-card>
        </b-form-group>
      </b-form-group>

      <template v-if="input.supplement_program_id">
        <b-button
          v-if="input.supplement_program_id !== newItem.supplement_program_id"
          variant="primary"
          :disabled="isSubmitting"
          block
          @click="submit()"
        >
          <b-spinner v-if="isSubmitting" small />Generate Supplements
        </b-button>

        <template v-else>
          <CurriculumPhaseSupplementList
            v-for="(phase, phaseIndex) in newItem.phases"
            :key="phase.id"
            :show-header="phaseIndex === 0"
            :phase="phase"
            :params="{
              filters: {
                phase_id: phase.id
              },
              limit: 100
            }"
            editable
          />
        </template>
      </template>

      <!-- SUBMIT -->
      <b-form-group class="text-right my-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import SupplementProgramSelect from "@/components/supplement-programs/SupplementProgramSelect"
import CurriculumPhasesWaterInput from './inputs/CurriculumPhasesWaterInput'
import CurriculumPhaseSupplementList from './phase-supplements/CurriculumPhaseSupplementList'

export default {
  name: 'CurriculumItemSupplementsUpdate',

  mixins: [
    crudItemMixin
  ],

  components: {
    SupplementProgramSelect,
    CurriculumPhasesWaterInput,
    CurriculumPhaseSupplementList
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  validations() {
    return {
      input: {
        phases: { required }
      }
    }
  },

  methods: {
    unsetSupplements() {
      _.each(this.input.phases, (phase) => {
        phase.supplements = []
      })
    },

    getInput() {
      const input = {
        supplement_program_id: this.newItem.supplement_program_id,

        phases: _.map(this.newItem.phases, phase => ({
          id: phase.id,
          cardio_morning: phase.cardio_morning,
          cardio_evening: phase.cardio_evening,
          cardio_mins_per_session: phase.cardio_mins_per_session,
          water_dosage_per_day: phase.water_dosage_per_day
        }))
      }

      return input
    }
  }
}
</script>