<template>
  <b-form @submit.prevent="submit">
    <b-row class="mb-2">
      <b-col
        xs="12"
        md="4"
      >
        <!-- GENDER -->
        <b-form-group
          label="Gender:"
        >
          <b-form-select
            v-model="input.profile.sex"
            :options="$store.getters['asset/getSexOptions']"
          />
        </b-form-group>
      </b-col>

      <b-col
        xs="12"
        md="4"
      >
        <!-- CURRENT WEIGHT -->
        <b-form-group
          label="Current Weight:"
        >
          <b-form-input
            v-model="input.physical_condition.weight"
            type="number"
            number
          />
        </b-form-group>
      </b-col>

      <b-col
        xs="12"
        md="4"
      >
        <!-- BODY FAT P -->
        <b-form-group
          label="Body Fat %:"
        >
          <b-form-input
            v-model="input.physical_condition.total_body_fat_p"
            type="number"
            number
            @input="input.phases[0].projected_body_fat_p = $event"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col
        xs="12"
        md="4"
      >
        <!-- GOAL DIRECTION -->
        <b-form-group
          label="Goal Direction:"
        >
          <b-form-select
            v-model="input.profile.goal_direction"
            :options="$store.getters['asset/getGoalDirectionsOptions']"
          />
        </b-form-group>
      </b-col>

      <b-col
        xs="12"
        md="4"
      >
        <!-- COMPETITION WEIGHT -->
        <b-form-group
          label="Competition Weight:"
        >
          <b-form-input
            v-model="input.profile.competition_weight"
            type="number"
            number
            :disabled="input.profile.goal_direction !== 3"
          />
        </b-form-group>
      </b-col>

      <b-col
        xs="12"
        md="4"
      >
        <!-- GOAL WEiGHT -->
        <b-form-group
          label="Goal Weight:"
        >
          <b-form-input
            v-model="input.profile.goal_weight"
            type="number"
            number
            :disabled="input.profile.goal_direction === 3"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <b-row class="mb-2">
      <b-col
        xs="12"
        md="6"
      >
        <!-- WEIGHT TO LOSS -->
        <b-form-group
          label="Weight to loss:"
        >
          <b-form-input
            :value="inputWeightToLoss"
            disabled
          />
        </b-form-group>
      </b-col>

      <b-col
        xs="12"
        md="6"
      >
        <!-- Term -->
        <b-form-group
          label="Term:"
        >
          <b-form-input
            :value="input.term"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>

    <!-- PROJECTED FAT AND ACTIVITY LEVELS -->
    <b-form-group
      class="mb-5"
      label="Projected fat % and activity level"
      label-class="text-uppercase font-size-24"
    >
      <CurriculumPhasesAbilityInput
        v-model="input.phases"
        :curriculum="curriculum"
        mode="values"
      />

      <CurriculumPhasesBodyFatInput
        v-model="input.phases"
        :curriculum="curriculum"
        :show-header="false"
        mode="values"
      />
    </b-form-group>

    <b-button
      class="my-4"
      type="submit"
      variant="primary"
      block
      @click="submit"
    >
      Refresh
    </b-button>

    <template v-if="!inputIsDirty">
      <div v-if="!calculateParamsAreValid">
        <b-alert
          show
          variant="warning"
          class="text-center"
        >
          Enter all params to get suggestions. 
        </b-alert>
      </div>

      <template v-else>
        <!-- RECOMMENDATIONS -->
        <b-form-group
          class="mb-5"
          label="Recommendations"
          label-class="text-uppercase font-size-24"
        >
          <div class="mb-4">
            <h4 class="text-primary mb-3">
              Cardio
            </h4>

            <CurriculumPhasesCardioInput
              class="mb-2"
              mode="suggestions"
              :curriculum="curriculum"
            />
          </div>

          <div>
            <h4 class="text-primary mb-3">
              Nutrients
            </h4>

            <CurriculumPhasesWaterInput
              mode="suggestions"
              :curriculum="curriculum"
            />

            <CurriculumPhasesCaloriesInput
              mode="suggestions"
              :curriculum="curriculum"
              :show-header="false"
            />
          </div>
        </b-form-group>

        <!-- PROJECTIONS -->
        <b-form-group
          class="mb-6"
          label="PROJECTIONS"
          label-class="text-uppercase font-size-24"
        >
          <CurriculumPhasesWeightInput
            class="mb-2"
            :curriculum="curriculum"
            mode="suggestions"
          />
        </b-form-group>
      </template>
    </template>
  </b-form>
</template>

<script>
import _ from 'lodash'
import CurriculumPhasesAbilityInput from '@/components/curriculums/inputs/CurriculumPhasesAbilityInput'
import CurriculumPhasesBodyFatInput from '@/components/curriculums/inputs/CurriculumPhasesBodyFatInput'
import CurriculumPhasesCardioInput from '@/components/curriculums/inputs/CurriculumPhasesCardioInput'
import CurriculumPhasesWaterInput from '@/components/curriculums/inputs/CurriculumPhasesWaterInput'
import CurriculumPhasesCaloriesInput from '@/components/curriculums/inputs/CurriculumPhasesCaloriesInput'
import CurriculumPhasesWeightInput from '@/components/curriculums/inputs/CurriculumPhasesWeightInput'

export default {
  name: 'FormulaFactorTest',

  components: {
    CurriculumPhasesAbilityInput,
    CurriculumPhasesBodyFatInput,
    CurriculumPhasesCardioInput,
    CurriculumPhasesWaterInput,
    CurriculumPhasesCaloriesInput,
    CurriculumPhasesWeightInput
  },

  data() {
    return {
      curriculum: {
        term: null,

        physical_condition: {
          weight: null,
          total_body_fat_p: null
        },

        profile: {
          sex: 'male',
          goal_weight: null,
          goal_direction: 1,
          competition_weight: null
        },

        phases: [
          {
            number: 1,
            projected_fitness_level_id: 1,
            projected_body_fat_p: null
          },

          {
            number: 2,
            projected_fitness_level_id: 1,
            projected_body_fat_p: null
          },

          {
            number: 3,
            projected_fitness_level_id: 2,
            projected_body_fat_p: null
          },

          {
            number: 4,
            projected_fitness_level_id: 2,
            projected_body_fat_p: null
          }
        ]
      },

      input: null
    }
  },

  computed: {
    inputIsDirty() {
      return !_.isEqual(this.input, this.getInput())
    },

    calculateParams() {
      return {
        current_weight: this.curriculum.physical_condition.weight,
        gender: this.curriculum.profile.sex,
        weight_loss_goal: this.curriculum.profile.goal_weight,
        weeks_count: parseInt(this.curriculum.term),
        goal_direction: this.curriculum.profile.goal_direction,
        competition_weight: this.curriculum.profile.competition_weight,
        fitness_level_ids: _.map(this.curriculum.phases, i => i.projected_fitness_level_id),
        body_fat_p_values: _.map(this.curriculum.phases, i => i.projected_body_fat_p)
      }
    },

    weightLossGoal() {
      return this.calculateParams.goal_direction === 3 ? this.calculateParams.competition_weight : this.calculateParams.weight_loss_goal
    },

    calculateParamsAreValid() {
      return (
        this.calculateParams.current_weight &&
        this.calculateParams.gender &&
        this.calculateParams.goal_direction &&
        this.calculateParams.weeks_count &&
        this.weightLossGoal &&
        !_.chain(this.calculateParams.fitness_level_ids).filter(i => _.isNil(i)).value().length &&
        !_.chain(this.calculateParams.body_fat_p_values).filter(i => _.isNil(i)).value().length
      )
    },

    inputWeightLossGoal() {
      return this.input.profile.goal_direction === 3 ? this.input.profile.competition_weight : this.input.profile.goal_weight
    },

    inputWeightToLoss() {
      return this.inputWeightLossGoal ? Math.abs(this.input.physical_condition.weight - this.inputWeightLossGoal) : null
    }
  },

  watch: {
    curriculum: {
      immediate: true,
      deep: true,

      handler() {
        if (this.inputIsDirty) {
          this.input = this.getInput()
        }
      }
    },

    inputWeightToLoss: {
      immediate: true,
      
      handler() {
        this.input.term = (this.inputWeightToLoss || 0) <= 114 ? 16 : 32
      }
    }
  },

  methods: {
    getInput() {
      return _.cloneDeep(this.curriculum)
    },

    submit() {
      this.input.profile.goal_weight = this.input.profile.goal_weight || this.inputWeightLossGoal
      this.curriculum = _.cloneDeep(this.input)
    }
  }
}
</script>