import auth from './middleware/auth'
import guest from './middleware/guest'

import Dashboard from './views/dashboard'
import LoginOld from './views/auth/login-old'
import Login from './views/auth/login'
import LoginKey from './views/auth/login-key'
import PasswordResetRequest from './views/auth/password-reset-request'
import PasswordResetConfirm from './views/auth/password-reset-confirm'

import Account from './views/account/index'
import Schedule from './views/schedule/index'

import ExerciseList from './views/exercise/list'
import ExerciseUpsert from './views/exercise/upsert'

import WorkoutList from './views/workout/list'
import WorkoutCopy from './views/workout/add'
import WorkoutUpsert from './views/workout/upsert'

import ForecastList from './views/forecast/list'
import ForecastAdd from './views/forecast/add'
import ForecastEdit from './views/forecast/add'

import SupplementList from './views/supplement/list'
import SupplementAdd from './views/supplement/add'
import SupplementEdit from './views/supplement/add'

import SupplementProgramList from './views/supplement-program/list'
import SupplementProgramAdd from './views/supplement-program/add'
import SupplementProgramEdit from './views/supplement-program/add'

import ClientList from './views/client/list'
import ClientPhotoList from './views/client/photos/list'
import ClientPhysicalConditionList from './views/client/physical-conditions/list'
import ClientCurriculumList from './views/client/curriculums/list'
import ClientStrengthGains from './views/client/strength-grains'

import CompanyUpsert from './views/company/upsert'
import CompanyList from './views/company/list'
import CompanyStore from './views/company/store'
import StaffList from './views/staff/list'

import GymUpsert from './views/gym/upsert'
import GymList from './views/gym/list'

import UserProfile from './views/users/profile'

import CurriculumBuild from './views/curriculums/index'
import CurriculumLogs from './views/curriculums/logs'
import CurriculumView from './views/curriculums/view'

import CardioTrackerPage from './views/trackers/cardio'
import WorkoutsTrackerPage from './views/trackers/workouts'
import NutritionalTrackerPage from './views/trackers/nutritional'

import Onboarding from './views/onboarding/index'
import OnboardingEdit from './views/onboarding/index'

import Error404 from './views/pages/error-404'
import Error500 from './views/pages/error-500'

import WaterIntakesListPage from './views/v1/formula-factors/water-intakes-list'
import DailyActivityListPage from './views/v1/formula-factors/daily-activity-list'
import LeanFactorListPage from './views/v1/formula-factors/lean-factor-list'
import NBCaliperListPage from './views/v1/formula-factors/nb-caliper-list'
import NBCaliperRatioListPage from './views/v1/formula-factors/nb-caliper-ratio-list'
import WeightLossListPage from './views/v1/formula-factors/weight-loss-list'
import NutrientPage from './views/v1/formula-factors/nutrient-list'
import FormulaTestPage from './views/v1/formula-factors/test'
import WorkoutsPerWeekPage from './views/v1/formula-factors/workouts-per-week'

import FoodListPage from './views/food/list'

import WorkoutProgramList from './views/workout-programs/list'
import WorkoutProgramAddStep1 from './views/workout-programs/step/step1'
import WorkoutProgramAddStep2 from './views/workout-programs/step/step2'
import WorkoutProgramEdit from './views/workout-programs/step/step2'

export default [
    {
        path: '/',
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldUseApp'
        },
        name: 'home',
        component: Dashboard,
    },
    {
        path: '/login-old',
        name: 'login-old',
        component: LoginOld,
        meta: {
            middleware: [
                guest
            ],
            rule: 'isPublic'
        },
    }, {
        path: '/login',
        name: 'login',
        component: Login,
        meta: {
            middleware: [
                guest
            ],
            rule: 'isPublic'
        },
    }, {
        path: '/login/:key',
        name: 'login-key',
        component: LoginKey,
        meta: {
            middleware: [
                guest
            ],
            rule: 'isPublic'
        },
    }, {
        path: '/password-reset',
        name: 'password-reset-request',
        component: PasswordResetRequest,
        meta: {
            middleware: [
                guest
            ],
            rule: 'isPublic'
        },
    }, {
        path: '/password-reset/:key',
        name: 'password-reset-confirm',
        component: PasswordResetConfirm,
        meta: {
            middleware: [
                guest
            ],
            rule: 'isPublic'
        },
    }, {
        path: '/profile',
        name: 'profile',
        component: UserProfile,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isLogged'
        },
    }, {
        path: '/account',
        name: 'account',
        component: Account,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isLogged'
        },
    }, {
        path: '/schedule',
        name: 'schedule',
        component: Schedule,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isPaidClient'
        },
    }, {
        path: '/exercise/list',
        name: 'exercise-list',
        component: ExerciseList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListExercise'
        },
    }, {
        path: '/exercise/add',
        name: 'exercise-add',
        component: ExerciseUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAddExercise'
        },
    }, {
        path: '/exercise/:id',
        name: 'exercise-edit',
        component: ExerciseUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAnyExercise'
        },
    }, {
        path: '/workout/list',
        name: 'workout-list',
        component: WorkoutList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListWorkout'
        },
    }, {
        path: '/workout/add',
        name: 'workout-add',
        component: WorkoutUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAddWorkout'
        },
    }, {
        path: '/workout/:id',
        name: 'workout-edit',
        component: WorkoutUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldEditWorkout'
        },
    }, {
        path: '/workout/copy/:id',
        name: 'workout-copy',
        component: WorkoutCopy,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldCopyWorkout'
        },
    }, {
        path: '/forecast/add',
        name: 'forecast-add',
        component: ForecastAdd,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAddForecast'
        },
    }, {
        path: '/forecast/list',
        name: 'forecast-list',
        component: ForecastList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListForecast'
        },
    }, {
        path: '/forecast/:id',
        name: 'forecast-edit',
        component: ForecastEdit,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldEditForecast'
        },
    }, {
        path: '/workout-programs/list',
        name: 'workout-programs-list',
        component: WorkoutProgramList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListProgram'
        },
    }, {
        path: '/workout-programs/add',
        name: 'workout-programs-add',
        component: WorkoutProgramAddStep1,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAddProgram'
        },
    }, {
        path: '/workout-programs/add/next',
        name: 'workout-programs-add-step2',
        component: WorkoutProgramAddStep2,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAddProgram'
        },
    }, {
        path: '/workout-programs/:id',
        name: 'workout-programs-edit',
        component: WorkoutProgramEdit,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldEditProgram'
        },
    }, {
        path: '/supplement/list',
        name: 'supplement-list',
        component: SupplementList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListSupplement'
        },
    }, {
        path: '/supplement/add',
        name: 'supplement-add',
        component: SupplementAdd,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAddSupplement'
        },
    }, {
        path: '/supplement/:id',
        name: 'supplement-edit',
        component: SupplementEdit,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldEditSupplement'
        },
    }, {
        path: '/supplement-program/list',
        name: 'supplement-program-list',
        component: SupplementProgramList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListSupplementProgram'
        },
    }, {
        path: '/supplement-program/add',
        name: 'supplement-program-add',
        component: SupplementProgramAdd,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAddSupplementProgram'
        },
    }, {
        path: '/supplement-program/:id',
        name: 'supplement-program-edit',
        component: SupplementProgramEdit,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldEditSupplementProgram'
        },
    }, {
        path: '/users/:id/profile',
        name: 'user-profile',
        component: UserProfile,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldUseApp'
        },
    }, {
        path: '/client/list',
        name: 'client-list',
        component: ClientList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListClient'
        },
    }, {
        path: '/staff/list',
        name: 'staff-list',
        component: StaffList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListStaff'
        },
    }, {
        path: '/company/list',
        name: 'company-list',
        component: CompanyList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/company/add',
        name: 'company-add',
        component: CompanyUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/company/:id',
        name: 'company-edit',
        component: CompanyUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAnyCompany'
        },
    }, {
        path: '/store',
        name: 'company-store',
        component: CompanyStore,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isLogged'
        },
    }, {
        path: '/gym/list',
        name: 'gym-list',
        component: GymList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldListGym'
        },
    }, {
        path: '/gym/add',
        name: 'gym-add',
        component: GymUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldAddGym'
        },
    }, {
        path: '/gym/:id',
        name: 'gym-edit',
        component: GymUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldEditGym'
        },
    }, {
        path: '/gym/:id/view',
        name: 'gym-view',
        component: GymUpsert,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldViewGym'
        },
    }, {
        path: '/client/:id/photos',
        name: 'client-photo-list',
        component: ClientPhotoList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldUseApp'
        },
    }, {
        path: '/client/:id/physical-conditions',
        name: 'client-physical-condition-list',
        component: ClientPhysicalConditionList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldUseApp'
        }
    }, {
        path: '/client/:id/curriculums',
        name: 'client-curriculum-list',
        component: ClientCurriculumList,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldUseApp'
        }
    }, {
        path: '/client/:id/strength-gains',
        name: 'client-strength-gains',
        component: ClientStrengthGains,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldUseApp'
        }
    }, {
        path: '/curriculums/:id',
        name: 'curriculums-build',
        component: CurriculumBuild,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldBuildCurriculum'
        },
    }, {
        path: '/curriculums/:id/view',
        name: 'curriculums-view',
        component: CurriculumView,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldUseApp'
        },
    }, {
        path: '/curriculums/:id/logs',
        name: 'curriculum-logs',
        component: CurriculumLogs,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldUseApp'
        },
    }, {
        path: '/trackers/cardio',
        name: 'trackers-cardio',
        component: CardioTrackerPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isPaidClient'
        }
    }, {
        path: '/trackers/workouts',
        name: 'trackers-workouts',
        component: WorkoutsTrackerPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isPaidClient'
        }
    }, {
        path: '/trackers/nutritional',
        name: 'trackers-nutritional',
        component: NutritionalTrackerPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isPaidClient'
        }
    }, {
        path: '/onboarding',
        name: 'onboarding',
        component: Onboarding,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldOnboard'
        },
    }, {
        path: '/onboarding/:id',
        name: 'onboarding-edit',
        component: OnboardingEdit,
        meta: {
            middleware: [
                auth
            ],
            rule: 'couldOnboard'
        },
    }, {
        path: '/foods',
        name: 'food-list',
        component: FoodListPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/water-intakes',
        name: 'water-intakes-list',
        component: WaterIntakesListPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/daily-activity',
        name: 'daily-activity-list',
        component: DailyActivityListPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/lean-factor',
        name: 'lean-factor-list',
        component: LeanFactorListPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/nb-caliper',
        name: 'nb-caliper-list',
        component: NBCaliperListPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/nb-caliper-ratio',
        name: 'nb-caliper-ratio-list',
        component: NBCaliperRatioListPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/weight-loss',
        name: 'weight-loss-list',
        component: WeightLossListPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/nutrient',
        name: 'nutrient-list',
        component: NutrientPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/workouts-per-week',
        name: 'workouts-per-week',
        component: WorkoutsPerWeekPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/v1/formula-test',
        name: 'formula-test',
        component: FormulaTestPage,
        meta: {
            middleware: [
                auth
            ],
            rule: 'isAdmin'
        },
    }, {
        path: '/pages/404',
        name: 'Page-404',
        component: Error404,
        meta: {
            middleware: [
                auth
            ],
        }
    },
    {
        path: '/pages/500',
        name: 'Page-500',
        component: Error500,
        meta: {
            middleware: [
                auth
            ]
        }
    }
]
