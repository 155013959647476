var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "app"
    }
  }, [_c('Loader'), _c('Alert'), _c('RouterView', {
    key: _vm.key
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }