var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "7"
    }
  }, [_c('b-button-group', _vm._l(_vm.steps, function (step, stepIndex) {
    return _c('b-button', {
      key: step.id,
      "class": {
        'active': stepIndex === _vm.currentStepIndex,
        'completed': stepIndex < _vm.currentStepIndex
      },
      attrs: {
        "disabled": step.isDisabled,
        "to": "#".concat(step.id)
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]);
  }), 1)], 1)], 1), _vm.isFetched ? _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('CurriculumItemProfileSummary', {
    attrs: {
      "item": _vm.newItem
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('b-card', {
    staticClass: "bg-transparent",
    attrs: {
      "body-class": "py-0"
    }
  }, [_vm.currentStep.id === 'preview' ? _c('CurriculumItemPreviewDetail', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  }) : _vm._e(), _vm.currentStep.id === 'cardio' ? _c('CurriculumItemCardioDetail', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  }) : _vm._e(), _vm.currentStep.id === 'supplements' ? _c('CurriculumItemSupplementsDetail', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  }) : _vm._e(), _vm.currentStep.id === 'nutrition' ? _c('CurriculumItemNutritionDetail', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  }) : _vm._e(), _vm.currentStep.id === 'schedule' ? _c('CurriculumItemScheduleDetail', {
    key: _vm.$route.fullPath,
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  }) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }