var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row mb-md-2"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-3"
  }, [_c('b-input', {
    attrs: {
      "value": _vm.filter.search,
      "size": "sm",
      "placeholder": "Search workout program..."
    },
    on: {
      "input": _vm.debounceSetFilterSearchParam
    }
  })], 1), _c('div', {
    staticClass: "col-sm-12 col-md-2"
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.abilityLevelsOptionsWithEmptyValue
    },
    model: {
      value: _vm.filter.fitness_level,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "fitness_level", $$v);
      },
      expression: "filter.fitness_level"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-12 col-md-2"
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.sexOptionsWithEmptyValue
    },
    model: {
      value: _vm.filter.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "gender", $$v);
      },
      expression: "filter.gender"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-12 col-md-2"
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.goalDirectionsOptionsWithEmptyValue
    },
    model: {
      value: _vm.filter.goal_direction,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "goal_direction", $$v);
      },
      expression: "filter.goal_direction"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-12 col-md-3"
  }, [_c('b-form-checkbox', {
    staticClass: "py-1 d-inline-flex mr-4",
    attrs: {
      "size": "sm",
      "switch": ""
    },
    model: {
      value: _vm.filter.is_private,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "is_private", $$v);
      },
      expression: "filter.is_private"
    }
  }, [_vm._v(" Private ")]), _c('b-form-checkbox', {
    staticClass: "py-1 d-inline-flex",
    attrs: {
      "size": "sm",
      "switch": ""
    },
    model: {
      value: _vm.filter.is_favorite,
      callback: function callback($$v) {
        _vm.$set(_vm.filter, "is_favorite", $$v);
      },
      expression: "filter.is_favorite"
    }
  }, [_vm._v(" Favorite ")])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "table-responsive mb-0"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.dataProvider,
      "fields": _vm.fields,
      "responsive": "sm",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "filter": _vm.afilter,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(is_favorite)",
      fn: function fn(data) {
        return [_c('Toggle', {
          key: Math.random(),
          attrs: {
            "status": !!data.item.is_favorite,
            "data-id": data.item.id
          },
          on: {
            "change": function change($event) {
              return _vm.onFavoriteToggle(data.item.id, data.item.is_favorite);
            }
          }
        })];
      }
    }, {
      key: "cell(fitness_level_ids)",
      fn: function fn(data) {
        return [_c('Abilities', {
          attrs: {
            "ids": data.item.fitness_level_ids || []
          }
        })];
      }
    }, {
      key: "cell(goal_direction_id)",
      fn: function fn(data) {
        return [_c('GoalDirections', {
          attrs: {
            "ids": [data.item.goal_direction_id]
          }
        })];
      }
    }, {
      key: "cell(gender)",
      fn: function fn(data) {
        return [_c('Genders', {
          attrs: {
            "ids": [data.item.gender]
          }
        })];
      }
    }, {
      key: "cell(published)",
      fn: function fn(data) {
        return [_c('div', [_c('switches', {
          key: data.item.id,
          staticClass: "ml-2 mb-0",
          attrs: {
            "emit-on-mount": "",
            "type-bold": "true",
            "color": data.item.published ? 'success' : 'default'
          },
          on: {
            "input": function input($event) {
              return _vm.onPublishedToggle(data.item.id, data.item.published);
            }
          },
          model: {
            value: data.item.published,
            callback: function callback($$v) {
              _vm.$set(data.item, "published", $$v);
            },
            expression: "data.item.published"
          }
        })], 1)];
      }
    }, {
      key: "cell(action_edit)",
      fn: function fn(data) {
        return [_vm.couldEdit(data.item) ? _c('b-button', {
          attrs: {
            "variant": "primary",
            "size": "sm",
            "to": "/workout-programs/".concat(data.item.id)
          }
        }, [_vm._v(" Edit ")]) : _c('b-button', {
          attrs: {
            "variant": "link",
            "size": "sm",
            "to": "/workout-programs/".concat(data.item.id)
          }
        }, [_vm._v(" Detail ")])];
      }
    }, {
      key: "cell(action_copy)",
      fn: function fn(data) {
        return [_c('WorkoutProgramItemCopyButton', {
          attrs: {
            "source": data.item,
            "tag": "b-button",
            "tag-props": {
              size: 'sm',
              variant: 'primary'
            }
          },
          on: {
            "copied": function copied($event) {
              return _vm.$router.push("/workout-programs/".concat($event.id));
            }
          }
        }, [_vm._v(" Copy ")])];
      }
    }, {
      key: "cell(action_delete)",
      fn: function fn(data) {
        return [_vm.couldDelete(data.item) ? _c('a', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(data.item.id);
            }
          }
        }, [_c('FaIcon', {
          key: data.item.id,
          attrs: {
            "icon": "far fa-trash-alt",
            "size": "17"
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6"
  }), _c('div', {
    staticClass: "col-sm-12 col-md-6"
  }, [_c('div', {
    staticClass: "dataTables_paginate paging_simple_numbers float-right"
  }, [_c('ul', {
    staticClass: "pagination pagination-rounded mb-0"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.rows,
      "per-page": _vm.perPage
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])])])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }