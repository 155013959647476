var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "busy": _vm.isBusy,
      "items": _vm.fetchItems,
      "fields": _vm.fields,
      "responsive": "sm",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "row-clicked": function rowClicked($event) {
        return _vm.$emit('input', $event.id);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(gender)",
      fn: function fn(row) {
        return [_c('Genders', {
          attrs: {
            "ids": [row.item.gender]
          }
        })];
      }
    }, {
      key: "cell(goal_direction)",
      fn: function fn(row) {
        return [_c('GoalDirections', {
          attrs: {
            "ids": [row.item.goal_direction_id]
          }
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('WeightLossItemActionsButton', {
          attrs: {
            "item": row.item
          },
          on: {
            "update:item": _vm.refresh,
            "delete:item": _vm.refresh
          }
        }, [_c('b-icon-three-dots')], 1)];
      }
    }])
  }), _vm.showPagination ? _c('div', {
    staticClass: "pagination pagination-rounded"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }