var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "animate-width-change",
    "class": !_vm.editEntityMode ? 'col-lg-8' : 'col-lg-4'
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.phases_busy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('b-tabs', {
    staticClass: "phases-tabs"
  }, [_c('b-tab', {
    attrs: {
      "title": "All Phases"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-tr', [_c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Week")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Rest")]), _c('b-th', [_vm._v("Rep. Sequence")]), _c('b-th')], 1)], 1), _vm._v(" " + _vm._s(_vm.resetCounter()) + " "), _vm._l(_vm.phases, function (phase) {
    return _c('b-tbody', {
      key: phase.id
    }, _vm._l(phase.weeks, function (week) {
      return _c('b-tr', {
        key: phase.id + '_' + week.id
      }, [_c('b-td', {
        staticClass: "text-center"
      }, [_vm._v(_vm._s(_vm.getCounter()))]), _vm._v(" " + _vm._s(_vm.incCounter()) + " "), _c('b-td', {
        staticClass: "text-center"
      }, [_vm._v(_vm._s(week.rest))]), _c('b-td', [_vm._v(_vm._s(_vm.getSetsString(week)))]), _c('b-td')], 1);
    }), 1);
  })], 2)], 1)])]), _vm._l(_vm.phases, function (phase, phase_index) {
    return _c('b-tab', {
      key: phase.id,
      attrs: {
        "title": 'Phase ' + (phase_index + 1),
        "lazy": ""
      }
    }, [_c('div', {
      staticClass: "card"
    }, [_c('div', {
      staticClass: "card-body"
    }, [_c('b-table-simple', [_c('b-thead', [_c('b-tr', [_c('b-th', {
      staticClass: "text-center"
    }, [_vm._v("Week")]), _c('b-th', {
      staticClass: "text-center"
    }, [_vm._v("Rest")]), _c('b-th', [_vm._v("Rep. Sequence")]), _c('b-th')], 1)], 1), _c('b-tbody', _vm._l(phase.weeks, function (week, week_index) {
      return _c('b-tr', {
        key: phase.id + '_' + week.id
      }, [_c('b-td', {
        staticClass: "text-center align-middle"
      }, [_vm._v(" " + _vm._s(week_index + 1) + " ")]), _c('b-td', {
        staticClass: "text-center align-middle"
      }, [_vm.edit_index !== phase.id + '_' + week.id ? _c('span', [_vm._v(" " + _vm._s(week.rest) + " ")]) : _vm._e(), _vm.edit_index === phase.id + '_' + week.id ? _c('span', [_c('b-select', {
        staticClass: "rest-select",
        attrs: {
          "options": _vm.restOptions,
          "size": "sm"
        },
        model: {
          value: week.rest,
          callback: function callback($$v) {
            _vm.$set(week, "rest", $$v);
          },
          expression: "week.rest"
        }
      })], 1) : _vm._e()]), _c('b-td', [_vm.edit_index !== phase.id + '_' + week.id ? _c('span', [_vm._v(" " + _vm._s(_vm.getSetsString(week)) + " ")]) : _vm._e(), _vm.edit_index === phase.id + '_' + week.id ? _c('span', _vm._l(week.reps, function (rep) {
        return _c('b-select', {
          key: rep + '_' + week.reps,
          staticClass: "rep-select",
          attrs: {
            "options": _vm.repOptions,
            "size": "sm"
          },
          model: {
            value: week["rep_".concat(rep)],
            callback: function callback($$v) {
              _vm.$set(week, "rep_".concat(rep), $$v);
            },
            expression: "week[`rep_${rep}`]"
          }
        });
      }), 1) : _vm._e()]), _c('b-td', {
        staticClass: "align-middle"
      }, [_vm.edit_index !== phase.id + '_' + week.id ? _c('span', {
        staticClass: "no-wrap"
      }, [_c('a', {
        key: 'edit_' + phase.id + '_' + week.id,
        staticClass: "float-right",
        attrs: {
          "title": "Edit"
        },
        on: {
          "click": function click($event) {
            return _vm.onStartEdit(phase.id + '_' + week.id, week);
          }
        }
      }, [_c('FaIcon', {
        attrs: {
          "icon": "far fa-edit",
          "size": "18",
          "color": "#626ed4"
        }
      })], 1)]) : _vm._e(), _vm.edit_index === phase.id + '_' + week.id ? _c('span', {
        staticClass: "no-wrap"
      }, [_c('a', {
        key: 'cancel_' + phase.id + '_' + week.id,
        staticClass: "float-right ml-1",
        attrs: {
          "title": "Cancel"
        },
        on: {
          "click": function click($event) {
            return _vm.onCancelEdit(week);
          }
        }
      }, [_c('FaIcon', {
        attrs: {
          "icon": "far fa-times-circle",
          "size": "18",
          "color": "#ec4561"
        }
      })], 1), _c('a', {
        key: 'apply_' + phase.id + '_' + week.id,
        staticClass: "float-right ml-1",
        attrs: {
          "title": "Apply"
        },
        on: {
          "click": function click($event) {
            return _vm.onApplyEdit(week);
          }
        }
      }, [_c('FaIcon', {
        attrs: {
          "icon": "fas fa-check-circle",
          "size": "18",
          "color": "#02a499"
        }
      })], 1), _c('a', {
        key: 'add_set_' + phase.id + '_' + week.id,
        staticClass: "float-right ml-1",
        attrs: {
          "title": "Add Set"
        },
        on: {
          "click": function click($event) {
            return _vm.onAddSet(week);
          }
        }
      }, [_c('FaIcon', {
        attrs: {
          "icon": "fas fa-plus-circle",
          "size": "18",
          "color": "#6c757d"
        }
      })], 1), _c('a', {
        key: 'remove_set_' + phase.id + '_' + week.id,
        staticClass: "float-right ml-1",
        attrs: {
          "title": "Remove Set"
        },
        on: {
          "click": function click($event) {
            return _vm.onRemoveSet(week);
          }
        }
      }, [_c('FaIcon', {
        attrs: {
          "icon": "fas fa-minus-circle",
          "size": "18",
          "color": "#6c757d"
        }
      })], 1)]) : _vm._e()])], 1);
    }), 1)], 1)], 1)])]);
  }), _vm.phases.length < _vm.max_phases && _vm.isEdit ? _c('b-tab', {
    key: _vm.phases.length,
    attrs: {
      "title": "+ New Phase"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-table-simple', [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Week")]), _c('b-th', [_vm._v("Rest")]), _c('b-th', [_vm._v("Rep. Sequence")]), _c('b-th')], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "4"
    }
  }, [_c('a', {
    attrs: {
      "title": "Add Week"
    },
    on: {
      "click": function click($event) {
        return _vm.addPhaseAndWeek();
      }
    }
  }, [_c('FaIcon', {
    attrs: {
      "icon": "fas fa-plus-circle",
      "size": "30",
      "color": "#626ed4"
    }
  })], 1)])], 1)], 1)], 1)], 1)])]) : _vm._e()], 2)], 1)], 1), _c('div', {
    staticClass: "animate-width-change",
    "class": _vm.editEntityMode ? 'col-lg-8' : 'col-lg-4'
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.editEntityMode,
      expression: "!editEntityMode"
    }]
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('h4', {
    staticClass: "mb-3 card-title"
  }, [_vm._v("Forecast - " + _vm._s(_vm.form.name))])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "edit"
    },
    on: {
      "click": _vm.onStartEditEntity
    }
  }, [_vm._v(" Edit ")])])])], 1), _c('dl', {
    staticClass: "row mt-3 mb-0"
  }, [_c('dt', {
    staticClass: "col-sm-5"
  }, [_vm._v("Gender")]), _c('dd', {
    staticClass: "col-sm-7"
  }, [_vm._v(" " + _vm._s(_vm.form.gender) + " ")]), _c('dt', {
    staticClass: "col-sm-5"
  }, [_vm._v("Goal Direction")]), _c('dd', {
    staticClass: "col-sm-7"
  }, [_vm._v(" " + _vm._s(_vm.form.goal_direction_name) + " ")]), _c('dt', {
    staticClass: "col-sm-5"
  }, [_vm._v("Fitness Level")]), _c('dd', {
    staticClass: "col-sm-7"
  }, [_vm._v(" " + _vm._s(_vm.form.fitness_level_name.join(', ')) + " ")]), _c('dt', {
    staticClass: "col-sm-5"
  }, [_vm._v("Description")]), _c('dd', {
    staticClass: "col-sm-7"
  }, [_vm._v(" " + _vm._s(_vm.form.description) + " ")])])], 1), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.editEntityMode,
      expression: "editEntityMode"
    }]
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.phases_busy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onFormSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Forecast Name",
      "label-for": "name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Forecast Name"
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  }), _vm.formSubmitted && _vm.$v.form.name.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.name.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.form.name.minLength ? _c('span', [_vm._v(" This value is 5 letters minimum length. ")]) : _vm._e(), !_vm.$v.form.name.maxLength ? _c('span', [_vm._v(" This value is 255 letters maximum length. ")]) : _vm._e()]) : _vm._e()]), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Gender",
      "label-for": "type"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "mt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.gender.$error
    },
    attrs: {
      "id": "gender",
      "options": _vm.getSexOptions()
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }), _vm.formSubmitted && _vm.$v.form.gender.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.gender.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Goal Direction",
      "label-for": "goal_direction"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "mb-1 mt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.goal_direction.$error
    },
    attrs: {
      "id": "goal_direction",
      "options": _vm.getGoalDirectionsOptions()
    },
    model: {
      value: _vm.form.goal_direction,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "goal_direction", $$v);
      },
      expression: "form.goal_direction"
    }
  }), _vm.formSubmitted && _vm.$v.form.goal_direction.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.goal_direction.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Fitness Level(s)",
      "label-for": "ability-level"
    }
  }, [_c('b-form-checkbox-group', {
    staticClass: "mt-2 mb-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.fitness_level.$error
    },
    attrs: {
      "id": "ability-level",
      "name": "ability-level"
    }
  }, _vm._l(_vm.abilityLevels, function (level) {
    return _c('b-form-checkbox', {
      key: level.id,
      attrs: {
        "value": level.id
      },
      model: {
        value: _vm.form.fitness_level,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "fitness_level", $$v);
        },
        expression: "form.fitness_level"
      }
    }, [_vm._v(" " + _vm._s(_vm.s(level.name).capitalize().value()) + " ")]);
  }), 1), _vm.formSubmitted && _vm.$v.form.fitness_level.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.fitness_level.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Term",
      "label-for": "term"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "mt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.term.$error
    },
    attrs: {
      "id": "term",
      "options": _vm.getTermOptions()
    },
    model: {
      value: _vm.form.term,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "term", $$v);
      },
      expression: "form.term"
    }
  }), _vm.formSubmitted && _vm.$v.form.term.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.term.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.onFormSubmit && _vm.$v.form.description.$error
    },
    attrs: {
      "type": "textarea",
      "rows": "5",
      "placeholder": "Description"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _vm.onFormSubmit && _vm.$v.form.description.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.description.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "form-group mb-0"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "reset"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onFormCancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary m-l-5 ml-1",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])])])], 1)])], 1)])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }