<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :class="{
        'is-suggested': isSuggested
      }"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      fixed
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
      @row-clicked="selectedIds = [$event.id]"
    >
      <!-- SELECTED -->
      <template #cell(selected)="row">
        <b-icon
          variant="primary"
          font-scale="1.3"
          :icon="selectedIds.includes(row.item.id) ? 'check-circle-fill' : 'circle'"
        />
      </template>

      <template #cell(name)="row">
        <SupplementProgramItemDetailButton
          class="text-decoration-underline-dotted"
          :item="row.item"
          prefetch
          :style="{
            cursor: 'pointer'
          }"
        >
          {{ row.item.name }}
        </SupplementProgramItemDetailButton>
      </template>

      <template #cell(fitness_level_ids)="row">
        <Abilities :ids="row.item.fitness_level_ids" />
      </template>

      <template #cell(goal_direction_id)="row">
        <GoalDirections :ids="[row.item.goal_direction_id]" />
      </template>

      <template #cell(gender)="row">
        <Genders :ids="[row.item.gender]" />
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>
<script>
import crudListMixin from '@/mixins/crud-list'
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import Genders from '@/components/v1/asset/Genders'
import SupplementProgramItemDetailButton from './buttons/SupplementProgramItemDetailButton'

export default {
  name: 'SupplementProgramSelect',

  mixins: [
    crudListMixin
  ],

  components: {
    Abilities,
    GoalDirections,
    Genders,
    SupplementProgramItemDetailButton
  },

  props: {
    value: {
      type: Number,
      default: null
    },

    isSuggested: {
      type: Boolean,
      default: false
    },

    showSelect: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      baseEndpoint: 'supplement-programs'
    }
  },

  computed: {
    fields() {
      return [
        ...(this.showSelect ? [
          { key: 'selected', sortable: false, label: '', tdClass: 'text-center', thClass: 'w-50px' }
        ] : []),
        { key: 'name', sortable: false, label: 'Program Name' },
        { key: 'gender', sortable: false, label: 'Gender' },
        { key: 'fitness_level_ids', sortable: false, label: 'Fitness Level' },
        { key: 'goal_direction_id', sortable: false, label: 'Goal Direction' }
      ]
    },

    selectedIds: {
      get() {
        return this.value ? [this.value] : [];
      },
      
      set(values) {
        const value = values.length ? values[0] : null
        this.$emit('input', value)
      }
    }
  }
}
</script>

<style scoped>
  table.table-select >>> tbody tr {
    cursor: pointer;
  }
  table.table-select.is-suggested >>> tbody tr {
    background: #d7dbf4;
  }
  table.table-select.is-suggested >>> tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
</style>