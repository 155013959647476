var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('div', {
    staticClass: "mb-3"
  }, [_c('span', {
    staticClass: "font-weight-bold font-size-20 align-middle"
  }, [_vm._v("Billing")])]), _c('b-form-group', {
    staticClass: "align-middle",
    attrs: {
      "label": "Status",
      "label-cols-sm": "3"
    }
  }, [_c('div', {
    staticClass: "py-1"
  }, [_c('BillingSubscriptionStatus', {
    staticClass: "align-middle text-uppercase px-2 py-2 mr-2",
    attrs: {
      "item": _vm.subscription || {
        status: 'unpaid'
      },
      "tag": "b-badge"
    }
  }), _vm.subscription && _vm.subscription.cancel_date ? _c('BillingSubscriptionCancel', {
    staticClass: "align-middle text-uppercase px-2 py-2",
    attrs: {
      "item": _vm.subscription,
      "tag": "b-badge"
    }
  }) : _vm._e()], 1)]), _vm.subscription ? [_c('b-form-group', {
    attrs: {
      "label": "Your Plan",
      "label-cols-sm": "3"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "value": "".concat(_vm.subscriptionPlan.name, " - $").concat(_vm.pricePerInterval * _vm.subscription.interval_count, " per ").concat(_vm.subscription.interval_count, " ").concat(_vm.subscription.interval),
      "disabled": ""
    }
  }), _c('b-input-group-append', [_c('CompanyAppPlanItemCheckoutButton', {
    attrs: {
      "item": _vm.subscriptionPlan,
      "params": {
        billing_type: 'mothly'
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'primary'
      }
    }
  }, [_vm._v(" Update ")])], 1)], 1)], 1), _vm.subscription.cancel_date ? _c('b-form-group', {
    attrs: {
      "label": "Cancel date",
      "label-cols-sm": "3"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "value": _vm.subscription.cancel_date,
      "disabled": ""
    }
  })], 1) : _vm.subscription.end_date ? _c('b-form-group', {
    attrs: {
      "label": "Next bill date",
      "label-cols-sm": "3"
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "value": _vm.subscription.end_date,
      "disabled": ""
    }
  })], 1) : _vm._e()] : _vm.companyAppPlan ? [_c('b-form-group', {
    attrs: {
      "label": "Agreement",
      "label-cols": "3",
      "clas": ""
    }
  }, [_c('div', {
    staticClass: "d-flex h-100 align-items-center"
  }, [_c('b-form-checkbox', {
    model: {
      value: _vm.agreementsCheck,
      callback: function callback($$v) {
        _vm.agreementsCheck = $$v;
      },
      expression: "agreementsCheck"
    }
  }, [_vm._v(" Check to Accept "), _c('a', {
    attrs: {
      "href": _vm.companyAppPlan.agreement_url,
      "target": "_blank"
    }
  }, [_vm._v("Agreement")])])], 1)]), _c('b-form-group', {
    attrs: {
      "label": "Choose a plan",
      "label-cols": "3"
    }
  }, [_c('CompanyAppPlanItemCheckoutButton', {
    staticClass: "mr-3",
    attrs: {
      "item": _vm.companyAppPlan,
      "params": {
        billing_type: 'monthly'
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'primary',
        disabled: !_vm.agreementsCheck
      }
    }
  }, [_vm._v(" $" + _vm._s(_vm.companyAppPlan.price_per_month) + " per month ")]), _c('CompanyAppPlanItemCheckoutButton', {
    attrs: {
      "variant": "primary",
      "item": _vm.companyAppPlan,
      "params": {
        billing_type: 'annual'
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'primary',
        disabled: !_vm.agreementsCheck
      }
    }
  }, [_vm._v(" $" + _vm._s(_vm.companyAppPlan.price_per_month * 12) + " per year ")])], 1)] : _vm._e()], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }