<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      v-if="isFetched"
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Password"
        label-class="font-weight-bold font-size-20 mb-2"
      >
        <b-form-group
          v-if="!newItem || newItem.has_password"
          :invalid-feedback="getFieldInvalidFeedback($v.input.old_password)"
          :state="getFieldState($v.input.old_password)"
        >
          <b-form-input
            v-model.trim="input.old_password"
            type="password"
            placeholder="Old password"
            squared
            :state="getFieldState($v.input.old_password)"
          />
        </b-form-group>

        <b-form-group
          :invalid-feedback="getFieldInvalidFeedback($v.input.password1)"
          :state="getFieldState($v.input.password1)"
        >
          <b-form-input
            v-model.trim="$v.input.password1.$model"
            type="password"
            placeholder="New password"
            squared
            :state="getFieldState($v.input.password1)"
          />
        </b-form-group>

        <b-form-group
          v-if="$v.input.password1.$model"
          :invalid-feedback="getFieldInvalidFeedback($v.input.password2)"
          :state="getFieldState($v.input.password2)"
        >
          <b-form-input
            v-model.trim="$v.input.password2.$model"
            type="password"
            placeholder="Confirm new password"
            squared
            :state="getFieldState($v.input.password2)"
          />
        </b-form-group>
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0 text-right">
        <b-button
          variant="success"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import { required, sameAs } from "vuelidate/lib/validators"
import { strongPassword } from '@/plugins/validation'
import crudItemMixin from '@/mixins/crud-item'

export default {
  name: 'PasswordChange',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      fetchEndpoint: 'users/me'
    }
  },

  validations() {
    return {
      input: {
        old_password: this.newItem.has_password ? { required } : {},
        password1: { required, strongPassword },
        password2: { required, sameAs: sameAs(nestedModel => nestedModel.password1)}
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        old_password: null,
        password1: null,
        password2: null
      }

      return input
    },

    async submit(submittedEventName) {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      const input = {
        old_password: this.input.old_password,
        password: this.input.password1
      }

      this.isSubmitting = true

      return this.$axios
        .post('auth/password-change', input)
  
        .then(async ({ data }) => {
          await this.$store.dispatch('auth/setTokens', data)
          this.isSubmitting = false

          this.$store.commit("layout/addAlerts", {
            type: "success",
            text: 'Password has been successfully changed'
          })

          this.setInput()
          this.fetchItem()

          if (submittedEventName) {
            this.$emit(submittedEventName, data)
          }
        })
  
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>
