var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Password"
    }
  }, [_c('b-form-group', {
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.password1),
      "state": _vm.getFieldState(_vm.$v.input.password1)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "password",
      "placeholder": "New password",
      "squared": "",
      "state": _vm.getFieldState(_vm.$v.input.password1)
    },
    model: {
      value: _vm.$v.input.password1.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.input.password1, "$model", typeof $$v === 'string' ? $$v.trim() : $$v);
      },
      expression: "$v.input.password1.$model"
    }
  })], 1), _vm.input.password1 ? _c('b-form-group', {
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.password2),
      "state": _vm.getFieldState(_vm.$v.input.password2)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "password",
      "placeholder": "Confirm new password",
      "squared": "",
      "state": _vm.getFieldState(_vm.$v.input.password2)
    },
    model: {
      value: _vm.$v.input.password2.$model,
      callback: function callback($$v) {
        _vm.$set(_vm.$v.input.password2, "$model", $$v);
      },
      expression: "$v.input.password2.$model"
    }
  })], 1) : _vm._e()], 1), _c('b-form-group', {
    staticClass: "mt-2 mb-0 text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }