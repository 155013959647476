<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { assetComputed, companyComputed } from "@/state/helpers";
import {
  required,
  minLength,
  maxLength,
  numeric,
  minValue,
  maxValue,
} from "vuelidate/lib/validators";
import s from "underscore.string";
//const image = require("@/assets/images/add_exercise.png");

/**
 * Form Advanced component
 */
export default {
  name: 'SupplementAdd',
  page: {
    title: "Add Supplement",
    meta: [{ name: "description", content: appConfig.description }],
  },
  computed: {
    ...assetComputed,
    ...companyComputed,
    title() {
      return this.isEdit ? "Edit Supplement" : "Add Supplement";
    },
    isEdit() {
      return this.$route.name === "supplement-edit";
    },
    isCopy() {
      return this.$route.name === "supplement-copy";
    },
  },
  components: { Layout, PageHeader },
  data() {
    return this.initialData();
  },
  validations: {
    form: {
      name: { required, minLength: minLength(5), maxLength: maxLength(200) },
      type: { required },
      form: { required },
      dose: {
        required,
        numeric,
        minValue: minValue(1),
        maxValue: maxValue(1000),
      },
      dose_type: { required },
      description: { required },
    },
  },
  methods: {
    s: s,
    initialData() {
      return {
        config: appConfig,
        // execrise id in edit
        id: null,
        formSubmitted: false,
        //img: image,
        image: null,
        form: {
          image: null,
          file: [],
          name: null,
          type: null,
          form: null,
          dose: null,
          dose_type: '',
          description: null,
          image_url: null,
          delete_image: false,
        },
        isBusy: false,
      };
    },
    formSubmit() {
      this.formSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      const formData = new FormData();
      formData.append("image", this.form.file);
      /*Object.keys(this.form).forEach((key) => {
        formData.append(key, this.form[key]);
      });*/

      //this.form.image = formData

      if (this.isEdit) {
        const query = new URLSearchParams(this.form).toString();

        this.$axios
          .put(`/supplement/${this.id}/?${query}`, formData, {
            parent: this,
          })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.$router.push("/supplement/list");
          })
          .catch(() => {
            this.$store.commit("layout/addAlerts", {
              type: "warning",
              text: "Could not save supplement",
            });
          });
      } else {
        this.form["company_id"] = this.currentCompany
          ? this.currentCompany.id
          : null;

        const query = new URLSearchParams(this.form).toString();

        
        this.$axios
          .post(`/supplement/?${query}`, formData, {
            parent: this,
          })
          // eslint-disable-next-line no-unused-vars
          .then((response) => {
            this.$router.push("/supplement/list");
          })
          // eslint-disable-next-line no-unused-vars
          .catch((response) => {
            this.$store.commit("layout/addAlerts", {
              type: "warning",
              text: "Could not save supplement",
            });
          });
      }
    },
    getSupplementTypesOptions() {
      return this.$store.getters["asset/getSupplementTypesOptions"];
    },
    onImageSelect(e) {
      const file = e.target.files[0];
      this.image = URL.createObjectURL(file);

      this.form.image_url = null;
      this.form.delete_image = false;
    },
    onImageDelete() {
      this.image = "";
      this.form.image = [];

      this.form.image_url = null;
      this.form.delete_image = true;
    },
    onFormCancel() {
      this.$router.push('/supplement/list');
    },
  },
  mounted() {
    this.id = this.$route.params.id;

    if (this.id) {
      this.$axios
        .get(`/supplement/${this.id}`, {
          parent: this,
        })
        .then((response) => {
          if (this.isCopy) {
            response.data.data.name += " - Copy";
          }
          this.form = response.data.data;
        });
    }
  },
  watch: {
    // watch for URL update to start over
    "$route.path": function() {
      Object.assign(this.$data, this.initialData());
      this.id = this.$route.params.id;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-5">
        <div class="card image">
          <div class="card-body">
            <div class="supplement-image">
              <div class="preview" v-if="image">
                <b-img :src="image" alt="Image" fluid></b-img>
                <button
                  type="reset"
                  class="btn btn-primary delete-label"
                  @click="onImageDelete"
                >
                  Remove Photo
                </button>
              </div>

              <div class="preview" v-if="form.image_url">
                <b-img :src="form.image_url" alt="Image" fluid></b-img>
                <button
                  type="reset"
                  class="btn btn-primary delete-label"
                  @click="onImageDelete"
                >
                  Remove Photo
                </button>
              </div>

              <b-form-file
                v-show="!image && !form.image_url"
                v-model="form.file"
                placeholder="+ Add Photo"
                drop-placeholder="Drop file here..."
                class="add-photo"
                @change="onImageSelect"
              ></b-form-file>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
      <div class="col-lg-7">
        <b-overlay
          id="overlay-background"
          :show="isBusy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <form action="#" @submit.prevent="formSubmit">
                <b-form-group
                  label-cols="3"
                  label-cols-sm="3"
                  label="Name"
                  label-for="name"
                >
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control"
                    placeholder="Name"
                    :class="{
                      'is-invalid': formSubmitted && $v.form.name.$error,
                    }"
                  />

                  <div
                    v-if="formSubmitted && $v.form.name.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.name.required">
                      This value is required.
                    </span>
                    <span v-if="!$v.form.name.minLength">
                      This value is 5 letters minimum length.
                    </span>
                    <span v-if="!$v.form.name.maxLength">
                      This value is 200 letters maximum length.
                    </span>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="3"
                  label-cols-sm="3"
                  label="Type"
                  label-for="type"
                >
                  <b-form-radio-group
                    id="type"
                    v-model="form.type"
                    class="mt-2"
                    :class="{
                      'is-invalid': formSubmitted && $v.form.type.$error,
                    }"
                    :options="getSupplementTypesOptions()"
                  >
                  </b-form-radio-group>

                  <div
                    v-if="formSubmitted && $v.form.type.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.type.required">
                      This value is required.
                    </span>
                  </div>
                </b-form-group>

                <b-form-group
                  label-cols="3"
                  label-cols-sm="3"
                  label="Form"
                  label-for="form"
                >
                  <b-form-radio-group
                    id="form"
                    v-model="form.form"
                    :class="{
                      'is-invalid': formSubmitted && $v.form.form.$error,
                    }"
                    class="mt-2"
                    :options="supplementFormTypesOptions"
                  >
                  </b-form-radio-group>

                  <div
                    v-if="formSubmitted && $v.form.form.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.form.required">
                      This value is required.
                    </span>
                  </div>
                </b-form-group>

                <div role="group" class="form-row form-group">
                  <label for="dose" class="col-sm-3 col-3 col-form-label">
                    Dose
                  </label>
                  <div class="bv-no-focus-ring col">
                    <input
                      id="dose"
                      v-model="form.dose"
                      type="text"
                      class="form-control"
                      placeholder="Dose"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.dose.$error,
                      }"
                    />
                    <div
                      v-if="formSubmitted && $v.form.dose.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.dose.required">
                        This value is required.
                      </span>
                      <span v-if="!$v.form.dose.number">
                        This value is numeric type.
                      </span>
                      <span v-if="!$v.form.dose.minValue">
                        This value minumum is 1.
                      </span>
                      <span v-if="!$v.form.dose.maxValue">
                        This value maximum is 1000.
                      </span>
                    </div>
                  </div>
                  <div class="bv-no-focus-ring col">
                    <b-form-select
                      v-model="form.dose_type"
                      :options="supplementDoseTypesOptionsWithEmptyValue"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.dose_type.$error,
                      }"
                    ></b-form-select>
                    <div
                      v-if="formSubmitted && $v.form.dose_type.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.dose_type.required">
                        This value is required.
                      </span>
                    </div>
                  </div>
                </div>

                <b-form-group
                  label-cols="3"
                  label-cols-sm="3"
                  label="Description"
                  label-for="description"
                >
                  <b-form-textarea
                    v-model="form.description"
                    type="textarea"
                    rows="5"
                    class="form-control"
                    placeholder="Description"
                    :class="{
                      'is-invalid': formSubmitted && $v.form.description.$error,
                    }"
                  />
                  <div
                    v-if="formSubmitted && $v.form.description.$error"
                    class="invalid-feedback"
                  >
                    <span v-if="!$v.form.description.required">
                      This value is required.
                    </span>
                  </div>
                </b-form-group>

                <div class="form-group mb-0">
                  <div class="float-right">
                    <button type="button" class="btn btn-secondary" @click="onFormCancel">
                      Cancel
                    </button>
                    <button type="submit" class="btn btn-primary m-l-5 ml-1">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </b-overlay>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
  .card.image {
    background: #ececf1;
  }
  .supplement-image {
    position: relative;
    text-align: center;
  }
  .supplement-image .preview {
    text-align: center;
  }
  .supplement-image .preview .delete-label {
    position: absolute;
    top: calc(50% - 15px);
    z-index: 10;
    display: none;
    left: 0;
    right: 0;
    margin: auto;
  }
  .supplement-image .preview:hover .delete-label {
    display: block;
  }
</style>
