var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "items": _vm.newResult.data,
      "fields": _vm.fields,
      "stacked": "sm",
      "no-local-sorting": "",
      "sort-by": _vm.newParams.order_by,
      "sort-desc": _vm.newParams.order_desc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(gender)",
      fn: function fn(row) {
        return [_c('Genders', {
          attrs: {
            "ids": [row.item.gender]
          }
        })];
      }
    }, {
      key: "cell(goal_direction_id)",
      fn: function fn(row) {
        return [_c('GoalDirections', {
          attrs: {
            "ids": [row.item.goal_direction_id]
          }
        })];
      }
    }, {
      key: "cell(fitness_level_id)",
      fn: function fn(row) {
        return [_c('Abilities', {
          attrs: {
            "ids": [row.item.fitness_level_id]
          }
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('WorkoutsPerWeekItemUpsertButton', {
          staticClass: "p-0 mr-3",
          attrs: {
            "item": row.item,
            "tag": "b-button",
            "tag-props": {
              variant: 'link',
              size: 'sm'
            }
          },
          on: {
            "submitted": _vm.refresh
          }
        }, [_c('b-icon-gear')], 1), _c('WorkoutsPerWeekItemDeleteButton', {
          staticClass: "p-0",
          attrs: {
            "item": row.item,
            "tag": "b-button",
            "tag-props": {
              variant: 'link',
              size: 'sm'
            }
          },
          on: {
            "delete:item": _vm.refresh
          }
        }, [_c('b-icon-trash')], 1)];
      }
    }])
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }