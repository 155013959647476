var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "table-responsive mb-0"
  }, [_c('b-table', {
    staticClass: "supplement-table",
    attrs: {
      "items": _vm.dataProvider,
      "fields": _vm.fields,
      "responsive": "sm",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "filter": _vm.afilter
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(image_url)",
      fn: function fn(data) {
        return [data.item.image_url ? _c('b-img', {
          attrs: {
            "rounded": "circle",
            "alt": data.item.name,
            "src": data.item.image_url
          }
        }) : _c('span', [_vm._v(" no image ")])];
      }
    }, {
      key: "cell(form)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.supplementFormTypeName(data.item.form)) + " ")];
      }
    }, {
      key: "cell(dose)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.dose + " " + _vm.supplementDoseTypeName(data.item.dose_type)) + " ")];
      }
    }, {
      key: "cell(action_edit)",
      fn: function fn(data) {
        return [_vm.couldEdit(data.item) ? _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.onEdit(data.item.id);
            }
          }
        }, [_vm._v("Edit")]) : _vm._e()];
      }
    }, {
      key: "cell(action_delete)",
      fn: function fn(data) {
        return [_c('div', [_vm.couldDelete(data.item) ? _c('a', {
          on: {
            "click": function click($event) {
              return _vm.onDelete(data.item.id);
            }
          }
        }, [_c('FaIcon', {
          key: data.item.id,
          attrs: {
            "icon": "far fa-trash-alt",
            "size": "17"
          }
        })], 1) : _vm._e()])];
      }
    }])
  })], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6"
  }), _c('div', {
    staticClass: "col-sm-12 col-md-6"
  }, [_c('div', {
    staticClass: "dataTables_paginate paging_simple_numbers float-right"
  }, [_c('ul', {
    staticClass: "pagination pagination-rounded mb-0"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.rows,
      "per-page": _vm.perPage
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])])])])])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }