<template>
  <b-overlay
    :show="isFetching"
    :style="{
      overflow: 'scroll'
    }"
  >
    <b-table-simple
      v-if="newResult.data && newResult.data.length"
      class="mb-2 text-nowrap"
      fixed
      striped
      stacked="sm"
      :style="{
        minWidth: '900px'
      }"
    >
      <b-thead>
        <b-tr class="text-center font-size-12">
          <b-th
            class="w-70px align-middle"
            variant="primary"
          >
            <b-spinner
              v-if="isSubmitting"
              small
            />
          </b-th>

          <b-th
            v-for="item in newResult.data"
            :key="item.id"
            class="px-1"
            variant="primary"
          >
            <div>
              {{ item.name }}
            </div>

            <b-row
              v-if="showSuggestions && showValues"
              no-gutters
              class="mt-2 text-nowrap"
            >
              <b-col class="text-truncate">Rec</b-col> 
              <b-col class="text-truncate">Actual</b-col>
            </b-row>
          </b-th>

          <b-th
            class="w-150px"
            variant="success"
          >
            <div>Totals</div>

            <b-row
              v-if="showSuggestions && showValues"
              no-gutters
              class="mt-2 text-nowrap"
            >
              <b-col class="text-truncate">Rec</b-col>
              <b-col class="text-truncate">Actual</b-col>
            </b-row>
          </b-th>
        </b-tr>

        <b-tr>
          <b-td
            class="align-middle font-weight-bold text-center py-1"
            :colspan="(newResult.data || []).length + 2"
            variant="info"
          >
            Protein
          </b-td>
        </b-tr>

        <b-tr class="text-center font-size-12">
          <b-td
            class="align-middle font-weight-bold"
          >
            Grams
          </b-td>

          <b-td
            v-for="(item, itemIndex) in newResult.data"
            :key="item.id"
            class="px-1 align-middle"
          >
            <b-row
              no-gutters
              class="text-nowrap"
              align-v="center"
            >
              <b-col v-if="showSuggestions">
                <div class="py-2">{{ suggestedMeals[itemIndex] ? suggestedMeals[itemIndex].grams.protein : '-' }}</div>
              </b-col>

              <b-col v-if="showValues">
                <b-form-input
                  v-if="!disabled"
                  v-model="input.items[itemIndex].protein"
                  class="hide-arrows text-center px-0"
                  type="number"
                  size="sm"
                  step="0.1"
                  number
                  :disabled="disabled"
                  @update="input.items[itemIndex].protein = $event !== '' ? $event : null"
                />

                <div
                  v-else
                  class="py-2"
                >
                  {{ item.protein || '-' }}
                </div>
              </b-col>
            </b-row>
          </b-td>

          <b-td
            class="align-middle"
            variant="success"
          >
            <b-row
              align-v="center"
              no-gutters
            >
              <b-col v-if="showSuggestions">
                {{ suggestedMacros ? suggestedMacros.grams.protein : '-' }}
              </b-col>

              <b-col  v-if="showValues">
                {{ input ? calcMealsInputMacro('protein') : '-' }}
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr class="text-center font-size-12">
          <b-td
            class="align-middle font-weight-bold"
            variant="secondary"
          >
            Calories
          </b-td>

          <b-td
            v-for="(item, itemIndex) in newResult.data"
            :key="item.id"
            class="px-1"
            variant="secondary"
          >
            <b-row
              no-gutters
              class="text-nowrap"
            >
              <b-col v-if="showSuggestions">
                <div class="py-2">{{ suggestedMeals[itemIndex] ? suggestedMeals[itemIndex].calories.protein : '-' }}</div>
              </b-col>

              <b-col
               v-if="showValues"
               class="font-weight-bolder"
              >
                <div class="py-2">{{ input.items[itemIndex] ? getCaloriesByGrams(input.items[itemIndex].protein, 'protein') : '-' }}</div>
              </b-col>
            </b-row>
          </b-td>

          <b-td
            class="align-middle"
            variant="success"
          >
            <b-row
              align-v="center"
              no-gutters
            >
              <b-col v-if="showSuggestions">
                {{ suggestedMacros ? suggestedMacros.calories.protein : '-' }}
              </b-col>

              <b-col v-if="showValues">
                {{ input ? getCaloriesByGrams(calcMealsInputMacro('protein'), 'protein') : '-' }}
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            class="align-middle font-weight-bold text-center py-1"
            :colspan="(newResult.data || []).length + 2"
            variant="info"
          >
            Carbs
          </b-td>
        </b-tr>

        <b-tr class="text-center font-size-12">
          <b-td
            class="align-middle font-weight-bold"
          >
            Grams
          </b-td>

          <b-td
            v-for="(item, itemIndex) in newResult.data"
            :key="item.id"
            class="align-middle px-1"
          >
            <b-row
              no-gutters
              class="text-nowrap"
              align-v="center"
            >
              <b-col v-if="showSuggestions">
                <div class="py-2">{{ suggestedMeals[itemIndex] ? suggestedMeals[itemIndex].grams.carbs : '-' }}</div>
              </b-col>

              <b-col v-if="showValues">
                <b-form-input
                  v-if="!disabled"
                  v-model="input.items[itemIndex].carbs"
                  class="hide-arrows text-center px-0"
                  type="number"
                  size="sm"
                  step="0.1"
                  number
                  :disabled="disabled"
                  @update="input.items[itemIndex].carbs = $event !== '' ? $event : null"
                />

                <div
                  v-else
                  class="py-2"
                >
                  {{ item.carbs || '-' }}
                </div>
              </b-col>
            </b-row>
          </b-td>

          <b-td
            class="align-middle"
            variant="success"
          >
            <b-row
              align-v="center"
              no-gutters
            >
              <b-col v-if="showSuggestions">
                {{ suggestedMacros ? suggestedMacros.grams.carbs : '-' }}
              </b-col>

              <b-col v-if="showValues">
                {{ input ? calcMealsInputMacro('carbs') : '-' }}
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr class="text-center font-size-12">
          <b-td
            class="align-middle font-weight-bold"
            variant="secondary"
          >
            Calories
          </b-td>

          <b-td
            v-for="(item, itemIndex) in newResult.data"
            :key="item.id"
            class="align-middle px-1"
            variant="secondary"
          >
            <b-row
              no-gutters
              class="text-nowrap"
            >
              <b-col v-if="showSuggestions">
                <div class="py-2">{{ suggestedMeals[itemIndex] ? suggestedMeals[itemIndex].calories.carbs : '-' }}</div>
              </b-col>

              <b-col
                v-if="showValues"
                class="font-weight-bolder"
              >
                <div class="py-2">{{ input.items[itemIndex] ? getCaloriesByGrams(input.items[itemIndex].carbs, 'carbs') : '-' }}</div>
              </b-col>
            </b-row>
          </b-td>

          <b-td
            class="align-middle"
            variant="success"
          >
            <b-row
              align-v="center"
              no-gutters
            >
              <b-col v-if="showSuggestions">
                {{ suggestedMacros ? suggestedMacros.calories.carbs : '-' }}
              </b-col>

              <b-col v-if="showValues">
                {{ input ? getCaloriesByGrams(calcMealsInputMacro('carbs'), 'carbs') : '-' }}
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            class="align-middle font-weight-bold text-center py-1"
            :colspan="(newResult.data || []).length + 2"
            variant="info"
          >
            Fats
          </b-td>
        </b-tr>

        <b-tr class="text-center font-size-12">
          <b-td
            class="align-middle font-weight-bold"
          >
            Grams
          </b-td>

          <b-td
            v-for="(item, itemIndex) in newResult.data"
            :key="item.id"
            class="align-middle px-1"
          >
            <b-row
              no-gutters
              class="text-nowrap"
              align-v="center"
            >
              <b-col v-if="showSuggestions">
                <div class="py-2">{{ suggestedMeals[itemIndex] ? suggestedMeals[itemIndex].grams.fat : '-' }}</div>
              </b-col>

              <b-col v-if="showValues">
                <b-form-input
                  v-if="!disabled"
                  v-model="input.items[itemIndex].fat"
                  class="hide-arrows text-center px-0"
                  type="number"
                  size="sm"
                  step="0.1"
                  number
                  :disabled="disabled"
                  @update="input.items[itemIndex].fat = $event !== '' ? $event : null"
                />

                <div
                  v-else
                  class="py-2"
                >
                  {{ item.fat || '-' }}
                </div>
              </b-col>
            </b-row>
          </b-td>

          <b-td
            class="align-middle"
            variant="success"
          >
            <b-row
              align-v="center"
              no-gutters
            >
              <b-col v-if="showSuggestions">
                {{ suggestedMacros ? suggestedMacros.grams.fat : '-' }}
              </b-col>

              <b-col v-if="showValues">
                {{ input ? calcMealsInputMacro('fat') : '-' }}
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr class="text-center font-size-12">
          <b-td
            class="align-middle font-weight-bold"
            variant="secondary"
          >
            Calories
          </b-td>

          <b-td
            v-for="(item, itemIndex) in newResult.data"
            :key="item.id"
            class="px-1"
            variant="secondary"
          >
            <b-row
              no-gutters
              class="text-nowrap"
              align-v="center"
            >
              <b-col v-if="showSuggestions">
                <div class="py-2">{{ suggestedMeals[itemIndex] ? suggestedMeals[itemIndex].calories.fat : '-' }}</div>
              </b-col>

              <b-col
                v-if="showValues"
                class="font-weight-bolder"
              >
                <div class="py-2">{{ input.items[itemIndex] ? getCaloriesByGrams(input.items[itemIndex].fat, 'fat') : '-' }}</div>
              </b-col>
            </b-row>
          </b-td>

          <b-td
            class="align-middle"
            variant="success"
          >
            <b-row
              align-v="center"
              no-gutters
            >
              <b-col v-if="showSuggestions">
                {{ suggestedMacros ? suggestedMacros.calories.fat : '-' }}
              </b-col>

              <b-col v-if="showValues">
                {{ input ? getCaloriesByGrams(calcMealsInputMacro('fat'), 'fat') : '-' }}
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            :colspan="(newResult.data || []).length + 1"
          />

          <b-td
            variant="success"
          >
            <b-row
              class="font-weight-bolder text-center"
              align-v="center"
              no-gutters
            >
              <b-col>
                Cal/Day
              </b-col>

              <b-col>
                {{ calcMealsInputCalories() }}
              </b-col>
            </b-row>
          </b-td>
        </b-tr>
      </b-thead>
    </b-table-simple>

    <div v-else-if="isFetching">
      <b-alert
        show
        class="text-center"
        variant="secondary"
      >
        Loading...
      </b-alert>
    </div>


    <div v-else>
      <b-alert
        show
        class="text-center"
        variant="warning"
      >
        There are no meals.
      </b-alert>
    </div>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'

export default {
  name: 'CurriculumMealListTable',

  mixins: [
    crudListMixin
  ],

  props: {
    curriculum: {
      type: Object,
      required: true
    },

    phase: {
      type: Object,
      required: true
    },

    mode: {
      type: String,
      default: null,
      validator: (i) => ['suggestions', 'values'].includes(i)
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      autosave: !this.disabled,
      debounceSubmitTime: 500,
      suggestions: null
    }
  },

  computed: {
    baseEndpoint() {
      return 'curriculum-meals'
    },

    showValues() {
      return !this.mode || this.mode === 'values'
    },

    showSuggestions() {
      return !this.mode || this.mode === 'suggestions'
    },

    calculateParams() {
      return {
        current_weight: this.curriculum.physical_condition.weight,
        gender: this.curriculum.profile.sex,
        weight_loss_goal: this.curriculum.profile.goal_weight,
        weeks_count: parseInt(this.curriculum.term),
        goal_direction: this.curriculum.profile.goal_direction,
        competition_weight: this.curriculum.profile.competition_weight,
        fitness_level_ids: _.map(this.curriculum.phases, i => i.projected_fitness_level_id),
        body_fat_p_values: _.map(this.curriculum.phases, i => i.projected_body_fat_p)
      }
    },

    calculateParamsAreValid() {
      return (
        !_.chain(this.calculateParams.fitness_level_ids).filter(i => _.isNil(i)).value().length &&
        !_.chain(this.calculateParams.body_fat_p_values).filter(i => _.isNil(i)).value().length
      )
    },

    suggestedNutrients() {
      return _.get(this.suggestions, `${this.phase.number - 1}.nutrients`)
    },

    suggestedMacros() {
      return this.suggestedNutrients ? {
        grams: {
          protein: Math.round(this.suggestedNutrients.grams.protein * 10) / 10,
          carbs: Math.round(this.suggestedNutrients.grams.carbs * 10) / 10,
          fat: Math.round(this.suggestedNutrients.grams.fats * 10) / 10,
          total: Math.round(this.suggestedNutrients.grams.total * 10) / 10
        },
        calories: {
          protein: Math.round(this.suggestedNutrients.calories.protein * 10) / 10,
          carbs: Math.round(this.suggestedNutrients.calories.carbs * 10) / 10,
          fat: Math.round(this.suggestedNutrients.calories.fats * 10) / 10,
          total: Math.round(this.suggestedNutrients.calories.total * 10) / 10
        }
      } : null
    },

    suggestedMeals() {
      return _.chain(this.suggestedNutrients)
        .get('meals')
        .map(i => ({
          grams: {
            protein: Math.round(i.grams.protein * 10) / 10,
            carbs: Math.round(i.grams.carbs * 10) / 10,
            fat: Math.round(i.grams.fats * 10) / 10,
            total: Math.round(i.grams.total * 10) / 10
          },
          calories: {
            protein: Math.round(i.calories.protein * 10) / 10,
            carbs: Math.round(i.calories.carbs * 10) / 10,
            fat: Math.round(i.calories.fats * 10) / 10,
            total: Math.round(i.calories.total * 10) / 10
          }
        }))
        .value()
    }
  },

  watch: {
    calculateParams: {
      immediate: true,
      deep: true,

      handler() {
        if ((!this.mode || this.mode === 'suggestions') && this.calculateParamsAreValid) {
          this.fetchSuggestions()
        }
      }
    },

    suggestedMeals: {
      immediate: true,
      deep: true,

      handler() {
        if (this.isFetched) {
          this.fillInput()
        }
      }
    },

    newResult: {
      immediate: true,
      deep: true,

      handler() {
        if (this.isFetched) {
          this.fillInput()
        }
      }
    }
  },

  methods: {
    getInput() {
      const newInput = {
        items: _.map(this.newResult.data, i => ({
          id: i.id,
          protein: i.protein,
          carbs: i.carbs,
          fat: i.fat
        })),

        bulk_mode: true
      }

      return newInput
    },

    fillInput() {
      this.input = {
        ...this.input,

        items: _.map(this.input.items, (i, index) => ({
          id: i.id,
          protein: !_.isNil(i.protein) || this.disabled ? i.protein : _.get(this.suggestedMeals, `${index}.grams.protein`, null),
          carbs: !_.isNil(i.carbs) || this.disabled ? i.carbs : _.get(this.suggestedMeals, `${index}.grams.carbs`, null),
          fat: !_.isNil(i.fat) || this.disabled ? i.fat : _.get(this.suggestedMeals, `${index}.grams.fat`, null),
        }))
      }
    },

    fetchSuggestions() {
      this.isFetching = true

      return this.$axios
        .post('/forecast/phase/side/calculate', this.calculateParams)
        .then(({ data }) => {
          this.isFetching = false
          this.suggestions = data.data
        })
        .catch((error) => {
          this.isFetching = false
          throw error
        })
    },

    calcMealMacro(meal, type) {
      return _.chain(meal)
        .get('meal_foods')
        .map(i => this.calcMealFoodMacro(i, type))
        .sum()
        .round(2)
        .value()
    },

    calcMealsMacro(meals, type) {
      return _.chain(meals)
        .map(i => this.calcMealMacro(i, type))
        .sum()
        .round(2)
        .value()
    },

    calcMealsInputMacro(type) {
      return _.chain(this.input.items)
        .map(i => i[type] || 0)
        .sum()
        .round(2)
        .value()
    },

    calcMealsInputCalories() {
      return _.chain(['protein', 'carbs', 'fat'])
        .map(i => this.getCaloriesByGrams(this.calcMealsInputMacro(i), i))
        .sum()
        .round(2)
        .value()
    },

    getCaloriesByGrams(value, type) {
      const caloriesPerGram = type === 'fat' ? 9 : 4
      const calories = Math.round(parseFloat(value || '0') * caloriesPerGram * 10) / 10

      return calories
    }
  }
}
</script>