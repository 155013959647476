<template>
  <div v-if="isStarted">
    <!-- Weight  -->
    <div>
      <template v-if="physicalCondition && physicalCondition.weight">
        Weight: {{ physicalCondition.weight }} lbs
      </template>

      <template v-else>
        Weight: -
      </template>
    </div>

    <!-- Body fat  -->
    <div>
      <template v-if="physicalCondition && physicalCondition.weight">
        Body Fat: {{ physicalCondition.body_fat_p }}%
      </template>

      <template v-else>
        Body Fat: -
      </template>
    </div>

    <!-- Workouts  -->
    <div>
      <template v-if="workouts && workouts.weekly_average_mins">
        Workouts: {{ workouts.completed }} / {{ workouts.total }} completed, {{ workouts.weekly_average_mins }} mins weekly average
      </template>
      
      <template v-else-if="workouts">
        Workouts: {{ workouts.completed }} / {{ workouts.total }} completed
      </template>

      <template v-else>
        Workouts: -
      </template>
    </div>

    <!-- Cardio  -->
    <div>
      <template v-if="cardios && cardios.weekly_average_mins">
        Cardio: {{ cardios.weekly_average_mins }} mins weekly average
      </template>

      <template v-else-if="cardios">
        Cardio: {{ cardios.completed }}/{{ cardios.total }} completed
      </template>

      <template v-else>
        Cardio: -
      </template>
    </div>

    <div>
      <template v-if="fluids && fluids.daily_average">
        Fluids: {{ fluids.daily_average }} oz daily average
      </template>
      
      <template v-else>
        Fluids: -
      </template>
    </div>

    <div>
      <template v-if="calories && calories.daily_average">
        Calories: {{ calories.daily_average }} daily average
      </template>
      
      <template v-else>
        Calories: -
      </template>
    </div>

    <div>
      <template v-if="fats && fats.daily_average">
        Fat: {{ fats.daily_average }} gm daily average
      </template>
      
      <template v-else>
        Fat: -
      </template>
    </div>

    <div>
      <template v-if="carbs && carbs.daily_average">
        Carbs: {{ carbs.daily_average }} gm daily average
      </template>
      
      <template v-else>
        Carbs: -
      </template>
    </div>

    <div>
      <template v-if="proteins && proteins.daily_average">
        Protein: {{ proteins.daily_average }} gm daily average
      </template>
      
      <template v-else>
        Protein: -
      </template>
    </div>
  </div>

  <div v-else>
    Not started
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CurriculumPhaseStats',

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  computed: {
    isStarted() {
      return this.$moment().startOf('day') >= this.$moment(this.item.start_date)
    },

    physicalCondition() {
      return _.get(this.item.stats, 'physical_condition')
    },

    cardios() {
      return _.get(this.item.stats, 'cardios')
    },

    workouts() {
      return _.get(this.item.stats, 'workouts')
    },

    proteins() {
      return _.get(this.item.stats, 'proteins')
    },

    fats() {
      return _.get(this.item.stats, 'fats')
    },

    carbs() {
      return _.get(this.item.stats, 'carbs')
    },

    calories() {
      return _.get(this.item.stats, 'calories')
    },

    fluids() {
      return _.get(this.item.stats, 'fluids')
    }
  }
}
</script>