<template>
  <b-input-group>
    <b-form-input
      v-model="unitValue"
      type="number"
      :step="1 / Math.pow(10, decimalPlaces)"
      :formatter="i => i ? parseInt(parseFloat(i) * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces) : i"
      v-bind="inputProps"
    />

    <template #append>
      <b-dropdown
        v-if="!unitDisabled"
        :text="suffix"
        v-bind="inputProps"
      >
        <b-dropdown-item
          v-for="unitOption in unitOptions"
          :key="unitOption.value"
          size="sm"
          @click="newUnit = unitOption.value"
        >
          {{ getSuffix(unitOption.value) }}
        </b-dropdown-item>
      </b-dropdown>

      <b-input-group-text v-else>
        {{ suffix }}
      </b-input-group-text>
    </template>
  </b-input-group>
</template>

<script>
import _ from 'lodash'
import formMixin from '@/mixins/form'

export default {
  name: 'PerHundredGramsInput',

  mixins: [
    formMixin
  ],

  props: {
    inputProps: {
      type: Object,
      default: null
    },

    unit: {
      type: String,
      default: 'grams'
    },

    unitDisabled: {
      type: Boolean,
      default: false
    },

    decimalPlaces: {
      type: Number,
      default: 2
    }
  },

  data() {
    return {
      autosave: true,
      newUnit: null
    }
  },

  computed: {
    unitOptions() {
      return [
        {
          value: 'miligrams',
          single: 'miligram',
          amount: 100,
          multiplier: 0.01
        },
        {
          value: 'grams',
          single: 'gram',
          amount: 100,
          multiplier: 1
        },
        {
          value: 'ounces',
          single: 'ounce',
          amount: 1,
          multiplier: 0.283495
        }
      ]
    },

    currentUnit: {
      get() {
        return _.find(this.unitOptions, i => i.value === this.newUnit) 
      },

      set(value) {
        this.$emit('update:unit', value)
      }
    },
    
    suffix() {
      return this.getSuffix(this.currentUnit.value)
    },

    unitValue: {
      get() {
        return this.newValue ? Math.round(this.newValue * this.currentUnit.multiplier * Math.pow(10, this.decimalPlaces)) / Math.pow(10, this.decimalPlaces) : this.newValue
      },

      set(value) {
        this.newValue = value ? Math.round(value / this.currentUnit.multiplier * Math.pow(10, this.decimalPlaces)) / Math.pow(10, this.decimalPlaces) : value
      }
    }
  },

  watch: {
    unit: {
      immediate: true,

      handler() {
        this.newUnit = this.unit
      }
    }
  },

  methods: {
    getSuffix(unitValue) {
      const unit = _.find(this.unitOptions, i => i.value === unitValue) 
      return `per ${unit.amount} ${unit.amount === 1 ? unit.single : unit.value }`
    }
  }
}
</script>