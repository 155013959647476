<template>
  <Layout>
    <b-overlay :show="isFetching">
      <template v-if="isFetched">
        <b-row>
          <b-col cols="5">
            <PageHeader :title="title" />
          </b-col>

          <b-col cols="7" class="d-flex align-items-center justify-content-end">
            <b-button-group>
              <b-button
                v-for="(step, stepIndex) in steps"
                :key="step.id"
                :variant="stepIndex === currentStepIndex ? 'primary' : null"
                :disabled="step.isDisabled"
                :to="`#${step.id}`"
              >
                {{ step.title }}
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <div
          v-if="newItem.status === 'designing'"
        >
          <b-alert
            show
            variant="warning"
          >
            You can't view logs for Curriculum with status {{ newItem.status }}.
          </b-alert>
        </div>

        <b-card
          v-else
          class="bg-transparent"
          body-class="py-0"
        >
          <TrackerWorkoutList
            v-if="currentStep.id === 'workouts'"
            :params="{
              filters: {
                curriculum_id: newItem.id,
                is_performed: true
              }
            }"
          />

          <TrackerCardioList
            v-else-if="currentStep.id === 'cardios'"
            :params="{
              filters: {
                curriculum_id: newItem.id,
                is_performed: true
              }
            }"
          />

          <TrackerNutritionList
            v-else-if="currentStep.id === 'nutritions'"
            :params="{
              filters: {
                curriculum_id: newItem.id
              }
            }"
          />
        </b-card>
      </template>
    </b-overlay>
  </Layout>
</template>

<script>
import _ from 'lodash'
import crudItem from '@/mixins/crud-item'
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import TrackerWorkoutList from '@/components/trackers/workouts/TrackerWorkoutList'
import TrackerCardioList from '@/components/trackers/cardios/TrackerCardioList'
import TrackerNutritionList from '@/components/trackers/nutritions/TrackerNutritionList'

export default {
  name: 'CurriculumLogs',

  mixins: [
    crudItem
  ],

  components: {
    Layout,
    PageHeader,
    TrackerWorkoutList,
    TrackerCardioList,
    TrackerNutritionList
  },

  page() {
    return {
      title: this.title
    }
  },

  props: {
    item: {
      type: Object,

      default: function() {
        const id = parseInt(this.$route.params.id)
        return { id }
      }
    },

    prefetch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  computed: {
    title() {
      const client = _.get(this.newItem, 'client')
      return client ? `${client.first_name || client.email}'s Curriculum Logs` : null
    },

    steps() {
      const steps = [
        { id: 'workouts', title: 'Workouts', isVisible: true },
        { id: 'cardios', title: 'Cardio', isVisible: true },
        { id: 'nutritions', title: 'Nutrition', isVisible: true }
      ]

      return _.filter(steps, { isVisible: true })
    },

    currentStepIndex: {
      get() {
        const index = _.findIndex(this.steps, { id: this.$route.hash.replace('#', '') })
        return index >= 0 ? index : 0
      },

      set(index) {
        const path = `/curriculums/${this.itemId}/logs`
        const step = _.get(this.steps, `${index}.id`) || 'workouts'
        this.$router.push({ path: path, hash: `#${step}` })
      }
    },
  
    currentStep() {
      return this.steps[this.currentStepIndex]
    }
  },

  methods: {
    onPrevStep() {
      this.currentStepIndex -= 1
    },

    onNextStep() {
      this.currentStepIndex += 1
    }
  },

  watch: {
    '$route.hash': {
      immediate: true,
      deep: true,

      handler() {
        this.refresh()
      }
    }
  }
};
</script>
