var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.layoutType === 'vertical' ? _c('vertical', {
    attrs: {
      "layout": _vm.layoutType
    }
  }, [_vm._t("default")], 2) : _vm._e(), _vm.layoutType === 'horizontal' ? _c('Horizontal', {
    attrs: {
      "layout": _vm.layoutType
    }
  }, [_vm._t("default")], 2) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }