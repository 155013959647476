<template>
  <b-overlay
    :show="isFetching"
  >
    <b-card
      class="m-0"
      :style="{
        overflow: 'scroll',
        width: '100%'
      }"
    >
      <b-form
        @submit.prevent="submit('submitted')"
        :style="{
          'background-image':
            'url(' + require('@/assets/images/body/MusclesMale.png') + ')'
        }"
        class="image-background"
      >
        <b-row>
          <b-col>
            <!-- HEIGHT -->
            <b-form-group
              label="Height"
              :invalid-feedback="getFieldInvalidFeedback($v.input.height)"
              :state="getFieldState($v.input.height)"
            >
              <HeightInput
                v-model="input.height"
                :state="getFieldState($v.input.height)"
              />
            </b-form-group>
          </b-col>

          <b-col>
            <!-- Weight -->
            <b-form-group
              label="Weight"
              :invalid-feedback="getFieldInvalidFeedback($v.input.weight)"
              :state="getFieldState($v.input.weight)"
            >
              <b-input-group append="lb">
                <b-form-input
                  v-model="input.weight"
                  type="number"
                  step="0.25"
                  number
                  :state="getFieldState($v.input.weight)"
                />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="main-content-measurements">
              <div>
                <div class="container">
                  <div class="wrap-left-inputs">
                    <div class="wrap-form-body">
                      <b-form-group
                        class="row-reversed"
                        label="Neck (inch)"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.neck)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.neck"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.neck)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        class="row-reversed"
                        label="Subscapular (mms)"
                        label-class="font-weight-bolder"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.subscapular)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.subscapular"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.subscapular)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        class="row-reversed"
                        label="Chest (mms)"
                        label-class="font-weight-bolder"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.chest)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.chest"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.chest)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        class="row-reversed"
                        label="Right tricep (mms)"
                        label-class="font-weight-bolder"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.tricep)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.tricep"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.tricep)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        class="row-reversed"
                        label="Waist (inch)"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.waist)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.waist"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.waist)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        class="row-reversed"
                        label="Suprailiac (mms)"
                        label-class="font-weight-bolder"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.suprailiac)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.suprailiac"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.suprailiac)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        class="row-reversed"
                        label="Thigh (mms)"
                        label-class="font-weight-bolder"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.thigh)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.thigh"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.thigh)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        class="row-reversed"
                        label="Left calf (inch)"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.calf_l)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.calf_l"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.calf_l)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        class="row-reversed"
                        label="Right calf (inch)"
                        label-cols="8"
                        label-align="left"
                        content-cols="4"
                        :state="getFieldState($v.input.calf_r)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.calf_r"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.calf_r)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>

                  <div class="wrap-right-inputs">
                    <div class="wrap-form-body">
                      <b-form-group
                        label="Chest (inch)"
                        label-cols="8"
                        label-align="right"
                        content-cols="4"
                        :state="getFieldState($v.input.chest_inches)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.chest_inches"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.chest_inches)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        label="Right bicep (inch)"
                        label-cols="8"
                        label-align="right"
                        content-cols="4"
                        :state="getFieldState($v.input.arm_r)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.arm_r"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.arm_r)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        label="Left bicep (inch)"
                        label-cols="8"
                        label-align="right"
                        content-cols="4"
                        :state="getFieldState($v.input.arm_l)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.arm_l"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.arm_l)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        label="Midaxillary (mms)"
                        label-class="font-weight-bolder"
                        label-cols="8"
                        label-align="right"
                        content-cols="4"
                        :state="getFieldState($v.input.midaxillary)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.midaxillary"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.midaxillary)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        label="Hips (inch)"
                        label-cols="8"
                        label-align="right"
                        content-cols="4"
                        :state="getFieldState($v.input.hips)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.hips"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.hips)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        label="Abdominal (mms)"
                        label-class="font-weight-bolder"
                        label-cols="8"
                        label-align="right"
                        content-cols="4"
                        :state="getFieldState($v.input.abdominal)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.abdominal"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.abdominal)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        label="Left thigh (inch)"
                        label-cols="8"
                        label-align="right"
                        content-cols="4"
                        :state="getFieldState($v.input.thigh_l)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.thigh_l"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.thigh_l)"
                          />
                        </b-input-group>
                      </b-form-group>

                      <b-form-group
                        label="Right thigh (inch)"
                        label-cols="8"
                        label-align="right"
                        content-cols="4"
                        :state="getFieldState($v.input.thigh_r)"
                      >
                        <b-input-group>
                          <b-form-input
                            v-model="input.thigh_r"
                            type="number"
                            step="0.25"
                            number
                            :state="getFieldState($v.input.thigh_r)"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>
              </div>

              <div class="wrap-bottom-texts">
                <div class="empty-block"></div>
                <div class="wrap-mass-fat-headers">
                  <div class="wrap-anfas-text">
                    <div class="local-header">
                      Lean Mass
                    </div>
                    <div>
                      {{ input.lean_mass }}
                    </div>
                  </div>
                  <div class="wrap-profile-text">
                    <div class="local-header">
                      Body Fat %
                    </div>
                    <div>
                      {{ input.total_body_fat_p }}
                    </div>
                  </div>
                </div>

                <div class="wrap-footer-text-measurement">
                  <div class="font-weight-bolder">
                    Caliper Measurement
                  </div>
                  <div>
                    Tape Measurement
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <!-- SUBMIT -->
        <b-form-group class="text-right mt-4">
          <b-button
            class="mr-2"
            :disabled="isCalculating"
            @click="calculateSubmit"
          >
            <b-spinner v-if="isCalculating" small />Calculate            
          </b-button>

          <b-button
            variant="primary"
            type="submit"
            :disabled="isSubmitting"
          >
            <b-spinner v-if="isSubmitting" small />Submit
          </b-button>
        </b-form-group>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required, between, minValue } from "vuelidate/lib/validators"
import HeightInput from '@/components/inputs/height-input'

export default {
  name: 'PhysicalConditionItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    HeightInput
  },

  props: {
    client: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      baseEndpoint: 'physical-conditions',
      isCalculating: false
    }
  },

  computed: {
    calculateInput() {
      return this.getCalculateInput(this.input)
    }
  },

  watch: {
    calculateInput: {
      immediate: false,
      deep: true,
      handler() {
        if (!_.isEqual(this.calculateInput, this.getCalculateInput(this.newItem))) {
          this.input.total_body_fat_p = null
          this.input.lean_mass = null
        }
      }
    }
  },

  validations() {
    const calculateInput = {
      weight: { required, between: between(80, 400)},
      tricep: { required, minValue: minValue(0) },
      chest: { required, minValue: minValue(0) },
      subscapular: { required, minValue: minValue(0) },
      abdominal: { required, minValue: minValue(0) },
      midaxillary: { required, minValue: minValue(0) },
      thigh: { required, minValue: minValue(0) }
    }

    const input = {
      ...calculateInput,
      suprailiac: { required, minValue: minValue(0) },
      arm_l: { required, minValue: minValue(0) },
      arm_r: { required, minValue: minValue(0) },
      thigh_l: { required, minValue: minValue(0) },
      thigh_r: { required, minValue: minValue(0) },
      calf_l: { required, minValue: minValue(0) },
      calf_r: { required, minValue: minValue(0) },
      height: { required, minValue: minValue(0) },
      neck: { required, minValue: minValue(0) },
      waist: { required, minValue: minValue(0) },
      hips: { required, minValue: minValue(0) },
      chest_inches: { required, minValue: minValue(0) }
    }

    return {
      input,
      calculateInput
    }
  },

  methods: {
    getInput() {
      const input = {
        weight: this.newItem.weight,
        tricep: this.newItem.tricep,
        chest: this.newItem.chest,
        subscapular: this.newItem.subscapular,
        suprailiac: this.newItem.suprailiac,
        abdominal: this.newItem.abdominal,
        midaxillary: this.newItem.midaxillary,
        thigh: this.newItem.thigh,
        arm_l: this.newItem.arm_l,
        arm_r: this.newItem.arm_r,
        thigh_l: this.newItem.thigh_l,
        thigh_r: this.newItem.thigh_r,
        calf_l: this.newItem.calf_l,
        calf_r: this.newItem.calf_r,
        height: this.newItem.height,
        neck: this.newItem.neck,
        waist: this.newItem.waist,
        hips: this.newItem.hips,
        chest_inches: this.newItem.chest_inches,
        total_body_fat_p: this.newItem.total_body_fat_p,
        lean_mass: this.newItem.lean_mass
      }

      if (!this.isCreated) {
        input.user_id = this.client.id
      }

      return input
    },

    getCalculateInput(value) {
      return {
        ..._.pick(value, [
          'weight',
          'tricep',
          'chest',
          'subscapular',
          'suprailiac',
          'abdominal',
          'midaxillary',
          'thigh'
        ]),
        age: this.$moment().diff(_.get(this.client, 'birthdate'), 'years'),
        gender: _.get(this.client, 'sex')
      }
    },

    calculateSubmit() {
      this.$v.$touch()

      if (this.$v.calculateInput.$error) {
        return
      }

      this.isCalculating = true

      return this.$axios
        .post('physical-conditions/calculate', this.calculateInput)
        .then(({ data }) => {
          this.isCalculating = false
          this.input.total_body_fat_p = Math.round(data.total_body_fat_p * 100) / 100
          this.input.lean_mass = Math.round(data.lean_mass * 100) / 100
        })
        .catch((e) => {
          this.isCalculating = false
          throw e
        })
    }
  }
}
</script>

<style scoped>
.card {
  background: #ECEFF1;
}
.medical-questions >>> label {
  margin-bottom: 16px;
}
.wrap-form-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.wrap-form-body >>> .input-group-prepend .input-group-text,
.wrap-form-body >>> .input-group-append .input-group-text {
  font-size: 10px;
  width: 47px;
  text-align: center;
}
.wrap-anfas-text {
  width: 155px;
  align-items: center;
}
.wrap-bottom-buttons {
  position: absolute;
  bottom: 1px;
  right: 1px;
}
.bold-header-body-text {
  font-weight: 600;
}
.wrap-profile-text {
  width: 195px;
  align-items: center;
}
.empty-block {
  width: 41%;
}
.wrap-bottom-texts {
  display: flex;
  padding: 12px;
  width: 100%;
  justify-content: space-around;
  padding-right: 1px;
}
.wrap-footer-text-measurement {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 33%;
}
.wrap-mass-fat-headers {
  display: flex;
  width: 33%;
}
.wrap-mass-fat-headers div {
  display: flex;
  flex-direction: column;
}
.main-content-measurements {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}
.image-background {
  width: 800px;
  height: 800px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  bottom: 0;
}
.container {
  display: flex;
  justify-content: space-between;
  height: auto;
  padding: 80px 0 0 0;
}
.wrap-left-inputs {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}
.wrap-body-images {
  display: flex;
  justify-content: space-between;
  width: 33%;
}
.wrap-right-inputs {
  width: 38%;
  display: flex;
}
.wrap-image-body {
  height: 600px;
}
.image-body {
  width: 100%;
  height: 100%;
}
.body-profile {
  width: 245px;
}
.body-anfas {
  width: 145px;
}
.local-header-addiction {
  color: #5b626b;
  padding-left: 3px;
}
.local-header {
  color: #626ed4;
  padding-bottom: 10px;
  font-size: 16px;
}
.medical-questions >>> .vue-switcher span {
  float: left;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 400;
}
.number-of-meals >>> select {
  max-width: 120px;
}
</style>
