<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="isValidParams">
      <ScheduleInput
        :events="events"
        :bg-events="daySchedule"
        :start-date="$moment(startDate).toDate()"
        :phase="phase"
        :paused-at="curriculum.paused_at ? $moment(curriculum.paused_at).toDate() : null"
        @update:events="onUpdateEvents"
      >
        <template v-slot:event-actions="{ event, toggleEventIsActive }">
          <template v-if="curriculum.status === 'active' || event.is_performed">
            <b-dropdown
              v-if="event.type === 'workout'"
              toggle-class="p-0 text-white"
              variant="link"
              size="sm"
              @shown="toggleEventIsActive(true)"
              @hidden="toggleEventIsActive(false)"
            >
              <template #button-content>
                <b-icon
                  class="no-drag"
                  icon="three-dots"
                />
              </template>
              <TrackerWorkoutItemUpsertButton
                :item="{ id: event.id }"
                tag="b-dropdown-item-button"
                @submitted="fetchEvents"
              >
                Workout Tracker
              </TrackerWorkoutItemUpsertButton>
            </b-dropdown>

            <b-dropdown
              v-if="event.type === 'cardio'"
              toggle-class="p-0 text-white"
              variant="link"
              size="sm"
              @shown="toggleEventIsActive(true)"
              @hidden="toggleEventIsActive(false)"
            >
              <template #button-content>
                <b-icon
                  class="no-drag"
                  icon="three-dots"
                />
              </template>

              <TrackerCardioItemUpsertButton
                :item="{ id: event.id }"
                tag="b-dropdown-item-button"
                @submitted="fetchEvents"
              >
                Cardio Tracker
              </TrackerCardioItemUpsertButton>
            </b-dropdown>
          </template>
        </template>
      </ScheduleInput>
    </template>

    <b-alert
      v-else
      show
      variant="warning"
    >
      CurriculumPhaseScheduleWeekDetail only with filters between one week
    </b-alert>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import ScheduleInput from '@/components/schedule/ScheduleInput'
import TrackerWorkoutItemUpsertButton from '@/components/trackers/workouts/buttons/TrackerWorkoutItemUpsertButton'
import TrackerCardioItemUpsertButton from '@/components/trackers/cardios/buttons/TrackerCardioItemUpsertButton'

export default {
  name: 'CurriculumPhaseScheduleWeekDetail',

  mixins: [
    crudListMixin
  ],

  components: {
    ScheduleInput,
    TrackerWorkoutItemUpsertButton,
    TrackerCardioItemUpsertButton
  },

  props: {
    curriculum: {
      type: Object,
      required: true
    },

    phase: {
      type: Object,
      required: true
    },

    prefetch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      workoutsResult: {
        data: null,
        total: null
      },

      cardiosResult: {
        data: null,
        total: null
      },

      autosave: true,
      debounceSubmitTime: 0
    }
  },

  computed: {
    daySchedule() {
      return _.chain(this.curriculum.client.schedule)
        .filter(i => Math.floor(i.start / 24) + 1 === this.$moment(this.date).day())
        .filter(i => ['work', 'sleep'].includes(i.type))
        .map(i => ({ ...i, start: i.start % 24 }))
        .value()
    },

    startDate() {
      const start_time_from = this.$moment(this.params.filters.start_time_from)
      return start_time_from.subtract(start_time_from.isoWeekday() - 1, 'day').format('YYYY-MM-DD HH:mm:ss')
    },

    isValidParams() {
      const start_time_from = this.$moment(this.params.filters.start_time_from)
      const start_time_to = this.$moment(this.params.filters.start_time_to)
      return this.params.filters.curriculum_phase_id && start_time_to.isoWeekday() - start_time_from.isoWeekday() <= 7
    },

    workoutEvents() {
      return _.map(this.workoutsResult.data, i => {
        const start = (this.$moment(i.start_time).isoWeekday() - 1) * 24 + this.$moment(i.start_time).hour() + this.$moment(i.start_time).minute() / 60
        const end = (this.$moment(i.end_time).isoWeekday() - 1) * 24 + this.$moment(i.end_time).hour() + this.$moment(i.start_time).minute() / 60
        return {
          ...i,
          start,
          duration: end - start,
          type: 'workout'
        }
      })
    },

    cardioEvents() {
      return _.map(this.cardiosResult.data, i => {
        const start = (this.$moment(i.start_time).isoWeekday() - 1) * 24 + this.$moment(i.start_time).hour() + this.$moment(i.start_time).minute() / 60
        const end = (this.$moment(i.end_time).isoWeekday() - 1) * 24 + this.$moment(i.end_time).hour() + this.$moment(i.start_time).minute() / 60
        return {
          ...i,
          start,
          duration: end - start,
          type: 'cardio'
        }
      })
    },

    events() {
      return [...this.workoutEvents, ...this.cardioEvents]
    }
  },

  created() {
    this.fetchEvents()
  },

  methods: {
    async fetchEvents() {
      const limit = this.newParams.limit
      const page = this.newParams.page
      const filters = this.newParams.filters
      const order_by = this.newParams.order_by
      const order_desc = this.newParams.order_desc

      const workoutsResult = { data: null, total: null }
      const cardiosResult = { data: null, total: null }

      this.isFetching = true

      await this.$axios
        .get('tracker-cardios', {
          params: { limit, page, order_by, order_desc, ...filters }
        })

        .then(({ data }) => {
          this.isFetching = false
          cardiosResult.data = data.data
          cardiosResult.total = data.total
        })

        .catch((e) => {
          this.isFetching = false
          throw e
        })

      await this.$axios
        .get('tracker-workouts', {
          params: { limit, page, order_by, order_desc, ...filters }
        })

        .then(({ data }) => {
          this.isFetching = false
          workoutsResult.data = data.data
          workoutsResult.total = data.total
        })

        .catch((e) => {
          this.isFetching = false
          throw e
        })

      this.workoutsResult = workoutsResult
      this.cardiosResult = cardiosResult

      this.isFetching = false
    },

    getInput() {
      return {
        workouts: {
          items: _.map(this.workoutsResult.data, i => ({
            id: i.id,
            start_time: i.start_time,
            end_time: i.end_time
          }))
        },

        cardios: {
          items: _.map(this.cardiosResult.data, i => ({
            id: i.id,
            start_time: i.start_time,
            end_time: i.end_time
          }))
        }
      }
    },

    async onUpdateEvents(events) {
      const workoutEvents = _.filter(events, { type: 'workout' })
      const cardioEvents = _.filter(events, { type: 'cardio' })

      this.input = {
        workouts: {
          items: _.map(workoutEvents, i => ({
            id: i.id,
            start_time: this.$moment(this.startDate).add(i.start * 60, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            end_time: this.$moment(this.startDate).add(i.start * 60 + i.duration * 60, 'minute').format('YYYY-MM-DD HH:mm:ss')
          }))
        },

        cardios: {
          items: _.map(cardioEvents, i => ({
            id: i.id,
            start_time: this.$moment(this.startDate).add(i.start * 60, 'minute').format('YYYY-MM-DD HH:mm:ss'),
            end_time: this.$moment(this.startDate).add(i.start * 60 + i.duration * 60, 'minute').format('YYYY-MM-DD HH:mm:ss')
          }))
        }
      }
    },

    async bulkUpdate() {
      this.isSubmitting = true

      if (this.input.workouts.items.length) {
        await this.$axios
          .patch('tracker-workouts/bulk', this.input.workouts)
          .catch((e) => {
            this.isSubmitting = false
            throw e
          })
      }

      if (this.input.cardios.items.length) {
        await this.$axios
          .patch('tracker-cardios/bulk', this.input.cardios)
          .catch((e) => {
            this.isSubmitting = false
            throw e
          })
      }

      this.fetchEvents()

      this.isSubmitting = false
    }
  }
}
</script>