<template>
  <div>
    <b-row
      v-for="(set, setIndex) in newValue.items"
      :key="setIndex"
      align-h="center"
      align-v="center"
      class="mb-2"
    >
      <b-col
        class="text-secondary"
        :style="{
          maxWidth: '70px'
        }"
      >
        Set {{ setIndex + 1 }}:
      </b-col>

      <b-col>
        <b-row
          no-gutters
          align-h="center"
          align-v="center"
        >
          <b-col
            :style="{
              maxWidth: '33px',
              fontSize: '12px'
            }"
          >
            Lbs
          </b-col>

          <b-col>
            <b-form-group
              class="mb-0"
              :invalid-feedback="getFieldInvalidFeedback($v.newValue.items.$each[setIndex].lbs)"
              :state="getFieldState($v.newValue.items.$each[setIndex].lbs)"
            >
              <b-form-input
                v-model="set.lbs"
                size="sm"
                type="number"
                number
                :state="getFieldState($v.newValue.items.$each[setIndex].lbs)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col>
        <b-row
          no-gutters
          align-h="center"
          align-v="center"
        >
          <b-col
            :style="{
              maxWidth: '33px',
              fontSize: '12px'
            }"
          >
            Reps
          </b-col>

          <b-col>
            <b-form-group
              class="mb-0"
              :invalid-feedback="getFieldInvalidFeedback($v.newValue.items.$each[setIndex].reps)"
              :state="getFieldState($v.newValue.items.$each[setIndex].reps)"
            >
              <b-form-input
                v-model="set.reps"
                size="sm"
                type="number"
                number
                :state="getFieldState($v.newValue.items.$each[setIndex].reps)"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        class="text-secondary p-0"
        :style="{
          maxWidth: '25px'
        }"
      >
        <b-button
          class="p-0"
          size="sm"
          variant="text"
          @click="showDeleteConfirmationModal(setIndex)"
        >
          <b-icon-x />
        </b-button>
      </b-col>
    </b-row>

    <div>
      <b-button
        variant="link"
        class="p-0 m-0"
        @click="addItem"
      >
        + Add Set
      </b-button>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import formMixin from '@/mixins/form'

export default {
  name: 'ExerciseSetsInput',

  mixins: [
    formMixin
  ],

  data() {
    return {
      autosave: true
    }
  },

  validations: {
    newValue: {
      items: {
        $each: {
          lbs: {},
          reps: {}
        }
      }
    }
  },

  methods: {
    addItem() {
      this.newValue.items.push({ lbs: null, reps: null })
    },

    deleteItem(itemIndex) {
      this.newValue.items = _.filter(this.newValue.items, (i, index) => index !== itemIndex)
    },

    showDeleteConfirmationModal(itemIndex) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
        title: 'Please Confirm',
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteItem(itemIndex)
          }
        })
    }
  }
}
</script>