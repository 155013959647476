<template>
  <span v-if="disabled">
    {{ time.mm }}:{{ time.ss }}
  </span>

  <vue-timepicker
    v-else
    v-model="time"
    format="mm:ss"
  />
</template>

<script>
import _ from 'lodash'
import VueTimepicker from 'vue2-timepicker'

export default {
  name: 'TimeInput',

  components: {
    VueTimepicker
  },

  props: {
    value: {
      type: Number,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    time: {
      get() {
        if (this.value) {
          // const H = _.toString(parseInt(this.value / 3600))
          const mm = _.toString(parseInt(this.value % 3600 / 60))
          const ss = _.toString(parseInt(this.value % 3600 % 60))
          return {
            // H,
            mm: mm.length === 1 ? `0${mm}`: mm,
            ss: ss.length === 1 ? `0${ss}`: ss,
          }
        } else {
          return {
            // H: '0',
            mm: '00',
            ss: '00'
          }
        }
      },

      set(value) {
        let seconds = 0
        /*
        if (value.H) {
          seconds += value.H ? parseInt(value.H) * 3600 : 0
        }
        */
        if (value.mm) {
          seconds += value.mm ? parseInt(value.mm) * 60 : 0
        }
        if (value.ss) {
          seconds += parseInt(value.ss)
        }
        this.$emit('input', seconds)
      }
    }
  }
}
</script>