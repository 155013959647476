<template>
  <b-overlay :show="isFetching">
    <b-table-simple
      class="mb-0"
      fixed
    >
      <b-thead v-if="showHeader">
        <b-tr variant="primary">
          <b-th class="w-130px bg-transparent" />

          <b-th
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center"
           colspan="2"
          >
            Phase {{ phase.number }}
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr>
          <b-th
            variant="success"
            class="w-130px"
          >
            <div class="py-1 text-nowrap text-center">
              Water
            </div>
          </b-th>

          <b-td
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center bg-white align-middle"
           colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col v-if="showSuggestions">
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>

                <template v-else-if="typeof suggestedValue[phase.number - 1].water_dosage_per_day === 'number'">
                  {{ suggestedValue[phase.number - 1].water_dosage_per_day }}oz per day
                </template>

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues">
                <b-form-input
                  v-if="!disabled && newValue"
                  v-model="newValue[phase.number - 1].water_dosage_per_day"
                  class="text-center hide-arrows"
                  type="number"
                  number
                  size="sm"
                />

                <template v-else>
                  <template v-if="newValue">
                    {{ newValue[phase.number - 1].water_dosage_per_day }}
                  </template>

                  <template v-else>
                    {{ phase.water_dosage_per_day }}
                  </template>
                </template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import formMixin from '@/mixins/form'

export default {
  name: 'CurriculumPhasesWaterInput',

  mixins: [
    formMixin
  ],

  props: {
    curriculum: {
      type: Object,
      required: true
    },

    mode: {
      type: String,
      default: null
    },

    showHeader: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFetching: false,
      suggestions: null,
      autosave: true
    }
  },

  computed: {
    suggestedValue() {
      return _.map(this.suggestions, phase => ({
        water_dosage_per_day: _.isNumber(phase.water_intake) ? Math.round(phase.water_intake) : phase.water_intake
      }))
    },

    showValues() {
      return !this.mode || this.mode === 'values'
    },

    showSuggestions() {
      return !this.mode || this.mode === 'suggestions'
    },

    calculateParams() {
      const calculateTerm = Math.max(parseInt(this.curriculum.term), 16)

      return {
        current_weight: this.curriculum.physical_condition.weight,
        gender: this.curriculum.profile.sex,
        weight_loss_goal: this.curriculum.profile.goal_weight,
        weeks_count: calculateTerm,
        goal_direction: this.curriculum.profile.goal_direction,
        competition_weight: this.curriculum.profile.competition_weight,
        fitness_level_ids: _.map(this.curriculum.phases, i => i.projected_fitness_level_id),
        body_fat_p_values: _.map(this.curriculum.phases, i => i.projected_body_fat_p)
      }
    },

    weightLossGoal() {
      return this.calculateParams.goal_direction === 3 ? this.calculateParams.competition_weight : this.calculateParams.weight_loss_goal
    },

    calculateParamsAreValid() {
      return (
        this.calculateParams.current_weight &&
        this.calculateParams.gender &&
        this.calculateParams.goal_direction &&
        this.calculateParams.weeks_count &&
        this.weightLossGoal &&
        !_.chain(this.calculateParams.fitness_level_ids).filter(i => _.isNil(i)).value().length &&
        !_.chain(this.calculateParams.body_fat_p_values).filter(i => _.isNil(i)).value().length
      )
    }
  },

  watch: {
    calculateParams: {
      immediate: true,
      deep: true,

      handler() {
        if ((!this.mode || this.mode === 'suggestions') && this.calculateParamsAreValid) {
          this.fetchSuggestions()
        }
      }
    },

    suggestedValue: {
      immediate: true,
      deep: true,

      handler() {
        if (this.suggestedValue.length) {
          this.newValue = _.map(this.newValue, (phase, phaseIndex) => ({
            ...phase,
            water_dosage_per_day: !_.isNil(phase.water_dosage_per_day) ? phase.water_dosage_per_day : (Math.round(this.suggestedValue[phaseIndex].water_dosage_per_day) || 0)
          }))
        }
      }
    },

    calculateParamsAreValid: {
      immediate: true,
      
      handler(newValue, oldValue) {
        if (!newValue && newValue !== oldValue) {
          this.suggestions = null
        }
      }
    }
  },

  methods: {
    fetchSuggestions() {
      this.isFetching = true

      return this.$axios
        .post('/forecast/phase/side/calculate', this.calculateParams)
        .then(({ data }) => {
          this.suggestions = data.data
          this.isFetching = false
        })
        .catch((error) => {
          this.isFetching = false
          throw error
        })
    }
  }
}
</script>