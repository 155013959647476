var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "vertical-menu"
  }, [_c('simplebar', {
    staticClass: "h-100"
  }, [_c('div', {
    attrs: {
      "id": "sidebar-menu"
    }
  }, [!_vm.$acl.check('couldUseApp') ? _c('ul', {
    staticClass: "metismenu list-unstyled",
    attrs: {
      "id": "side-menu"
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/account"
    }
  }, [_c('b-icon-gear', {
    staticClass: "mr-3"
  }), _c('BillingSubscriptionStatus', {
    staticClass: "float-right text-uppercase",
    attrs: {
      "item": _vm.currentUser.company_app_subscription || {
        status: 'unpaid'
      },
      "tag": "b-badge"
    }
  }), _c('span', [_vm._v("Account")])], 1)], 1)]) : _vm.$acl.check('isClient') ? _c('ul', {
    staticClass: "metismenu list-unstyled",
    attrs: {
      "id": "side-menu"
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/"
    }
  }, [_c('i', {
    staticClass: "ti-home"
  }), _vm.curriculum ? _c('b-badge', {
    staticClass: "float-right text-uppercase",
    attrs: {
      "variant": _vm.curriculum.status === 'paused' ? 'warning' : 'primary'
    }
  }, [_vm._v(" " + _vm._s(_vm.curriculum.status) + " ")]) : _vm._e(), _c('span', [_vm._v("Dashboard")])], 1)], 1), _vm.curriculum && _vm.curriculum.status === 'active' ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/schedule"
    }
  }, [_c('i', {
    staticClass: "ti-home"
  }), _c('span', [_vm._v("Schedule")])])], 1) : _vm._e(), _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-home"
  }), _c('span', [_vm._v("Curriculums")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_vm.curriculum && _vm.curriculum.status === 'active' ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/curriculums/".concat(_vm.curriculum.id, "/view")
    }
  }, [_vm._v(" Current Curriculum ")])], 1) : _vm._e(), _vm.curriculum && _vm.curriculum.status === 'active' ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/curriculums/".concat(_vm.curriculum.id, "/logs")
    }
  }, [_vm._v(" Curriculum Logs ")])], 1) : _vm._e(), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/client/".concat(_vm.currentUser.id, "/curriculums")
    }
  }, [_vm._v(" Curriculum Histories ")])], 1)])]), _vm.curriculum && _vm.curriculum.status === 'active' ? _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-home"
  }), _c('span', [_vm._v("Trackers")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', [_c('CurriculumWorkoutTrackerButton', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "tag-props": {
        href: 'javascript: void(0);'
      },
      "params": {
        filters: {
          curriculum_id: _vm.curriculum.id,
          is_performed: false
        },
        order_by: 'start_time',
        order_desc: false
      }
    }
  }, [_vm._v(" Workout Tracker ")])], 1), _c('li', [_c('CurriculumCardioTrackerButton', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "tag-props": {
        href: 'javascript: void(0);'
      },
      "params": {
        filters: {
          curriculum_id: _vm.curriculum.id,
          is_performed: false
        },
        order_by: 'start_time',
        order_desc: false
      }
    }
  }, [_vm._v(" Cardio Tracker ")])], 1), _c('li', [_c('TrackerNutritionButton', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "tag-props": {
        href: 'javascript: void(0);'
      },
      "params": {
        filters: {
          user_id: _vm.currentUser.id,
          date: _vm.$moment().format('YYYY-MM-DD')
        }
      },
      "curriculum": _vm.curriculum
    }
  }, [_vm._v(" Nutrition Tracker ")])], 1)])]) : _vm._e(), _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-home"
  }), _c('span', [_vm._v("Results & Reports")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/client/".concat(_vm.currentUser.id, "/physical-conditions")
    }
  }, [_vm._v(" Measurements ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/client/".concat(_vm.currentUser.id, "/photos")
    }
  }, [_vm._v(" Photos ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/client/".concat(_vm.currentUser.id, "/strength-gains")
    }
  }, [_vm._v(" Strength Gains ")])], 1)])]), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "to": "/store"
    }
  }, [_c('i', {
    staticClass: "ti-email"
  }), _c('span', [_vm._v("Store")])])], 1), _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-home"
  }), _c('span', [_vm._v("Workout Essentials")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/exercise/list"
    }
  }, [_vm._v(" Exercises ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/workout/list"
    }
  }, [_vm._v(" Workouts ")])], 1)])])]) : _c('ul', {
    staticClass: "metismenu list-unstyled",
    attrs: {
      "id": "side-menu"
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "to": "/"
    }
  }, [_c('i', {
    staticClass: "ti-home"
  }), _c('span', [_vm._v("Dashboard")])])], 1), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldOnboard'),
      expression: "$acl.check('couldOnboard')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "to": "/onboarding"
    }
  }, [_c('i', {
    staticClass: "ti-home"
  }), _c('span', [_vm._v("Onboarding")])])], 1), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAnyCurriculumEssentials'),
      expression: "$acl.check('couldAnyCurriculumEssentials')"
    }]
  }, [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-email"
  }), _c('span', [_vm._v("Curriculum Essentials")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAnyExercise'),
      expression: "$acl.check('couldAnyExercise')"
    }]
  }, [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-email"
  }), _c('span', [_vm._v("Exercises")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAddExercise'),
      expression: "$acl.check('couldAddExercise')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/exercise/add"
    }
  }, [_vm._v("Add")])], 1), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldListExercise'),
      expression: "$acl.check('couldListExercise')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/exercise/list"
    }
  }, [_vm._v("List")])], 1)])]), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAnyWorkout'),
      expression: "$acl.check('couldAnyWorkout')"
    }]
  }, [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-email"
  }), _c('span', [_vm._v("Workouts")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAddWorkout'),
      expression: "$acl.check('couldAddWorkout')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/workout/add"
    }
  }, [_vm._v("Add")])], 1), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldListWorkout'),
      expression: "$acl.check('couldListWorkout')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/workout/list"
    }
  }, [_vm._v("List")])], 1)])]), _vm.$acl.check('couldAnyForecast') ? _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-email"
  }), _c('span', [_vm._v("Forecasts")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_vm.$acl.check('couldAddForecast') ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/forecast/add"
    }
  }, [_vm._v("Add")])], 1) : _vm._e(), _vm.$acl.check('couldListForecast') ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/forecast/list"
    }
  }, [_vm._v("List")])], 1) : _vm._e()])]) : _vm._e(), _vm.$acl.check('couldAnyProgram') ? _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-package"
  }), _c('span', [_vm._v("Workout Programs")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_vm.$acl.check('couldAddProgram') ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/workout-programs/add"
    }
  }, [_vm._v("Add")])], 1) : _vm._e(), _vm.$acl.check('couldListProgram') ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/workout-programs/list"
    }
  }, [_vm._v("List")])], 1) : _vm._e()])]) : _vm._e(), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAnySupplement'),
      expression: "$acl.check('couldAnySupplement')"
    }]
  }, [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-shortcode"
  }), _c('span', [_vm._v("Supplements")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAddSupplement'),
      expression: "$acl.check('couldAddSupplement')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/supplement/add"
    }
  }, [_vm._v("Add")])], 1), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldListSupplement'),
      expression: "$acl.check('couldListSupplement')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/supplement/list"
    }
  }, [_vm._v("List")])], 1)])]), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAnySupplementProgram'),
      expression: "$acl.check('couldAnySupplementProgram')"
    }]
  }, [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-shortcode"
  }), _c('span', [_vm._v("Supplement Programs")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAddSupplementProgram'),
      expression: "$acl.check('couldAddSupplementProgram')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/supplement-program/add"
    }
  }, [_vm._v("Add")])], 1), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldListSupplementProgram'),
      expression: "$acl.check('couldListSupplementProgram')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/supplement-program/list"
    }
  }, [_vm._v("List")])], 1)])])])]), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldAnyClient'),
      expression: "$acl.check('couldAnyClient')"
    }]
  }, [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-user"
  }), _c('span', [_vm._v("Clients")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldListClient'),
      expression: "$acl.check('couldListClient')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/client/list"
    }
  }, [_vm._v(" List ")])], 1)])]), _vm.$acl.check('couldListGym') ? _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-location-pin"
  }), _c('span', [_vm._v("Gyms")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_vm.$acl.check('couldAddGym') ? _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/gym/add"
    }
  }, [_vm._v(" Add ")])], 1) : _vm._e(), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/gym/list"
    }
  }, [_vm._v(" List ")])], 1)])]) : _vm._e(), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('isOwner'),
      expression: "$acl.check('isOwner')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "to": "/company/".concat(_vm.currentCompany.id)
    }
  }, [_c('i', {
    staticClass: "ti-email"
  }), _c('span', [_vm._v("Company")])])], 1), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('couldListStaff'),
      expression: "$acl.check('couldListStaff')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "to": "/staff/list"
    }
  }, [_c('i', {
    staticClass: "ti-email"
  }), _c('span', [_vm._v("Staff")])])], 1), _c('li', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('isOwner'),
      expression: "$acl.check('isOwner')"
    }]
  }, [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "tag": "a",
      "to": "/store"
    }
  }, [_c('i', {
    staticClass: "ti-email"
  }), _c('span', [_vm._v("Store")])])], 1), _vm.$acl.check('isAdmin') ? _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-settings"
  }), _c('span', [_vm._v("Admin Tools")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-agenda"
  }), _c('span', [_vm._v("Companies")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/company/add"
    }
  }, [_vm._v(" Add ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/company/list"
    }
  }, [_vm._v(" List ")])], 1)])]), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/foods"
    }
  }, [_c('i', {
    staticClass: "ti-plug"
  }), _c('span', [_vm._v("Foods")])])], 1), _c('li', [_c('a', {
    staticClass: "has-arrow",
    attrs: {
      "href": "javascript: void(0);"
    }
  }, [_c('i', {
    staticClass: "ti-plug"
  }), _c('span', [_vm._v("Formula Factors")])]), _c('ul', {
    staticClass: "sub-menu",
    attrs: {
      "aria-expanded": "false"
    }
  }, [_c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/nutrient"
    }
  }, [_vm._v(" Nutrient ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/daily-activity"
    }
  }, [_vm._v(" Daily Activity ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/lean-factor"
    }
  }, [_vm._v(" Lean Factor ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/nb-caliper"
    }
  }, [_vm._v(" NB Caliper ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/nb-caliper-ratio"
    }
  }, [_vm._v(" NB Cardio Caliper Ratio ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/water-intakes"
    }
  }, [_vm._v(" Water Intakes ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/weight-loss"
    }
  }, [_vm._v(" Weight Loss ")])], 1), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/workouts-per-week"
    }
  }, [_vm._v(" Workouts Per Week ")])], 1)])]), _c('li', [_c('router-link', {
    staticClass: "side-nav-link",
    attrs: {
      "to": "/v1/formula-test"
    }
  }, [_c('i', {
    staticClass: "ti-plug"
  }), _c('span', [_vm._v("Forecasting Tool")])])], 1)])]) : _vm._e()])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }