var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "to": "/onboarding",
      "variant": "primary"
    }
  }, [_vm._v(" Add Client ")])], 1)], 1), _c('b-card', [_c('ClientList', {
    attrs: {
      "params": {
        filters: {
          trainer_ids: _vm.trainers,
          role_ids: [1]
        },
        order_desc: true
      }
    },
    on: {
      "update:params": function updateParams($event) {
        _vm.trainers = $event.filters.trainer_ids;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }