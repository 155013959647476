<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="updateItem"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'ExerciseItemFavoriteButton',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  computed: {
    endpoint() {
      return `exercises/${this.item.id}/${this.item.is_favorite ? 'unfavorite' : 'favorite'}`
    },

    input() {
      return {
        is_favorite: !this.item.is_favorite
      }
    }
  },

  methods: {
    updateItem() {
      this.isSubmitting = true

      return this.$axios
        .patch(this.endpoint, this.input)
        .then(({ data }) => {
          this.isSubmitting = false
          this.$emit('update:item', data)
          this.$emit('submitted', data)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>