<template>
  <b-overlay :show="isFetching">
    <b-table-simple
      class="mb-0"
      fixed
      striped
    >
      <b-thead v-show="showHeader">
        <b-tr
          variant="primary"
        >
          <b-th class="w-130px bg-transparent" />

          <b-th
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center"
           colspan="2"
          >
            Phase {{ phase.number }}
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr>
          <b-th
            variant="success"
            class="w-130px"
          >
            <div class="py-1 text-nowrap text-center">
              Calories
            </div>
          </b-th>

          <b-td
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center align-middle bg-white w-100"
           colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col v-if="showSuggestions">
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>
                
                <template v-else-if="suggestedValue[phase.number - 1].nutrients.calories.total">
                  {{ suggestedValue[phase.number - 1].nutrients.calories.total }} per day
                </template>

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues">
                <template v-if="phasesMacros[phase.number - 1].calories.total">
                  {{ phasesMacros[phase.number - 1].calories.total }} per day
                </template>

                <template v-else>-</template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            class="align-middle font-weight-bold text-center py-1"
            colspan="9"
            variant="info"
          >
            Protein
          </b-td>
        </b-tr>
        
        <b-tr>
          <b-td
            variant="success"
            class="w-130px"
          >
            <div class="py-1 text-nowrap text-center">
              Calories
            </div>
          </b-td>

          <b-td
            v-for="phase in curriculum.phases"
            :key="phase.number"
            class="text-center align-middle bg-white w-100"
            colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col v-if="showSuggestions">
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>
                
                <template v-else-if="suggestedValue[phase.number - 1].nutrients.calories.protein">
                  {{ suggestedValue[phase.number - 1].nutrients.calories.protein }} per day
                </template>

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues">
                <template v-if="phasesMacros[phase.number - 1].calories.protein">
                  {{ phasesMacros[phase.number - 1].calories.protein }} per day
                </template>

                <template v-else>-</template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            variant="success"
            class="w-130px"
          >
            <div class="py-1 text-nowrap text-center">
              Grams
            </div>
          </b-td>

          <b-td
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center align-middle bg-white w-100"
           colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col v-if="showSuggestions">
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>
                
                <template v-else-if="suggestedValue[phase.number - 1].nutrients.grams.protein">
                  {{ suggestedValue[phase.number - 1].nutrients.grams.protein }} per day
                </template>

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues">
                <template v-if="phasesMacros[phase.number - 1].grams.protein">
                  {{ phasesMacros[phase.number - 1].grams.protein }} per day
                </template>

                <template v-else>-</template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            class="align-middle font-weight-bold text-center py-1"
            colspan="9"
            variant="info"
          >
            Carbs
          </b-td>
        </b-tr>
        
        <b-tr>
          <b-td
            variant="success"
            class="w-130px"
          >
            <div class="py-1 text-nowrap text-center">
              Calories
            </div>
          </b-td>

          <b-td
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center align-middle bg-white w-100"
           colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col v-if="showSuggestions">
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>
                
                <template v-else-if="suggestedValue[phase.number - 1].nutrients.calories.carbs">
                  {{ suggestedValue[phase.number - 1].nutrients.calories.carbs }} per day
                </template>

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues">
                <template v-if="phasesMacros[phase.number - 1].calories.carbs">
                  {{ phasesMacros[phase.number - 1].calories.carbs }} per day
                </template>

                <template v-else>-</template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            variant="success"
            class="w-130px"
          >
            <div class="py-1 text-nowrap text-center">
              Grams
            </div>
          </b-td>

          <b-td
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center align-middle bg-white w-100"
           colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col v-if="showSuggestions">
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>
                
                <template v-else-if="suggestedValue[phase.number - 1].nutrients.grams.carbs">
                  {{ suggestedValue[phase.number - 1].nutrients.grams.carbs }} per day
                </template>

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues">
                <template v-if="phasesMacros[phase.number - 1].grams.carbs">
                  {{ phasesMacros[phase.number - 1].grams.carbs }} per day
                </template>

                <template v-else>-</template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            class="align-middle font-weight-bold text-center py-1"
            colspan="9"
            variant="info"
          >
            Fat
          </b-td>
        </b-tr>
        
        <b-tr>
          <b-td
            variant="success"
            class="w-130px"
          >
            <div class="py-1 text-nowrap text-center">
              Calories
            </div>
          </b-td>

          <b-td
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center align-middle bg-white w-100"
           colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col v-if="showSuggestions">
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>
                
                <template v-else-if="suggestedValue[phase.number - 1].nutrients.calories.fat">
                  {{ suggestedValue[phase.number - 1].nutrients.calories.fat }} per day
                </template>

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues">
                <template v-if="phasesMacros[phase.number - 1].calories.fat">
                  {{ phasesMacros[phase.number - 1].calories.fat }} per day
                </template>

                <template v-else>-</template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            variant="success"
            class="w-130px"
          >
            <div class="py-1 text-nowrap text-center">
              Grams
            </div>
          </b-td>

          <b-td
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center align-middle bg-white w-100"
           colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col v-if="showSuggestions">
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>
                
                <template v-else-if="suggestedValue[phase.number - 1].nutrients.grams.fat">
                  {{ suggestedValue[phase.number - 1].nutrients.grams.fat }} per day
                </template>

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues">
                <template v-if="phasesMacros[phase.number - 1].grams.fat">
                  {{ phasesMacros[phase.number - 1].grams.fat }} per day
                </template>

                <template v-else>-</template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import formMixin from '@/mixins/form'

export default {
  name: 'CurriculumPhasesCaloriesInput',

  mixins: [
    formMixin
  ],

  props: {
    curriculum: {
      type: Object,
      required: true
    },

    mode: {
      type: String,
      default: null
    },

    showHeader: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFetching: false,
      suggestions: null,
      mealsResult: null,
      autosave: true
    }
  },

  computed: {
    suggestedValue() {
      return _.map(this.suggestions, phase => ({
        nutrients: {
          grams: {
            protein: Math.round(phase.nutrients.grams.protein * 10) / 10,
            carbs: Math.round(phase.nutrients.grams.carbs * 10) / 10,
            fat: Math.round(phase.nutrients.grams.fats * 10) / 10,
            total: Math.round(phase.nutrients.grams.total * 10) / 10
          },
          calories: {
            protein: Math.round(phase.nutrients.calories.protein * 10) / 10,
            carbs: Math.round(phase.nutrients.calories.carbs * 10) / 10,
            fat: Math.round(phase.nutrients.calories.fats * 10) / 10,
            total: Math.round(phase.nutrients.calories.total * 10) / 10
          }
        }
      }))
    },

    showValues() {
      return !this.mode || this.mode === 'values'
    },

    showSuggestions() {
      return !this.mode || this.mode === 'suggestions'
    },

    calculateParams() {
      const calculateTerm = Math.max(parseInt(this.curriculum.term), 16)

      return {
        current_weight: this.curriculum.physical_condition.weight,
        gender: this.curriculum.profile.sex,
        weight_loss_goal: this.curriculum.profile.goal_weight,
        weeks_count: calculateTerm,
        goal_direction: this.curriculum.profile.goal_direction,
        competition_weight: this.curriculum.profile.competition_weight,
        fitness_level_ids: _.map(this.curriculum.phases, i => i.projected_fitness_level_id),
        body_fat_p_values: _.map(this.curriculum.phases, i => i.projected_body_fat_p)
      }
    },

    weightLossGoal() {
      return this.calculateParams.goal_direction === 3 ? this.calculateParams.competition_weight : this.calculateParams.weight_loss_goal
    },

    calculateParamsAreValid() {
      return (
        this.calculateParams.current_weight &&
        this.calculateParams.gender &&
        this.calculateParams.goal_direction &&
        this.calculateParams.weeks_count &&
        this.weightLossGoal &&
        !_.chain(this.calculateParams.fitness_level_ids).filter(i => _.isNil(i)).value().length &&
        !_.chain(this.calculateParams.body_fat_p_values).filter(i => _.isNil(i)).value().length
      )
    },

    phasesMacros() {
      return _.map(this.curriculum.phases, (phase) => {
        const meals = _.chain(this.mealsResult)
          .get('data')
          .filter({ phase_id: phase.id })
          .value()

        const proteinsGrams = this.calcMealsMacro(meals, 'protein')
        const carbsGrams = this.calcMealsMacro(meals, 'carbs')
        const fatsGrams = this.calcMealsMacro(meals, 'fat')
        const totalGrams = proteinsGrams + carbsGrams + fatsGrams

        const proteinsCalories = this.getCaloriesByGrams(proteinsGrams, 'protein')
        const carbsCalories = this.getCaloriesByGrams(carbsGrams, 'carbs')
        const fatsCalories = this.getCaloriesByGrams(fatsGrams, 'fat')
        const totalCaloreis = proteinsCalories + carbsCalories + fatsCalories

        return {
          grams: {
            protein: Math.round(proteinsGrams * 10) / 10,
            carbs: Math.round(carbsGrams * 10) / 10,
            fat: Math.round(fatsGrams * 10) / 10,
            total: Math.round(totalGrams * 10) / 10
          },
          calories: {
            protein: Math.round(proteinsCalories * 10) / 10,
            carbs: Math.round(carbsCalories * 10) / 10,
            fat: Math.round(fatsCalories * 10) / 10,
            total: Math.round(totalCaloreis * 10) / 10,
          }
        }
      })
    }
  },

  watch: {
    'curriculum.id': {
      immediate: true,
      deep: true,

      handler(newValue, oldValue) {
        if (this.showValues && newValue !== oldValue) {
          this.fetchMeals()
        }
      }
    },

    calculateParams: {
      immediate: true,
      deep: true,

      handler() {
        if (this.showSuggestions && this.calculateParamsAreValid) {
          this.fetchSuggestions()
        }
      }
    },

    calculateParamsAreValid: {
      immediate: true,
      
      handler(newValue, oldValue) {
        if (!newValue && newValue !== oldValue) {
          this.suggestions = null
        }
      }
    }
  },

  methods: {
    fetchSuggestions() {
      this.isFetching = true

      return this.$axios
        .post('/forecast/phase/side/calculate', this.calculateParams)
        .then(({ data }) => {
          this.suggestions = data.data
          this.isFetching = false
        })
        .catch((error) => {
          this.isFetching = false
          throw error
        })
    },

    fetchMeals() {
      this.isFetching = true

      return this.$axios
        .get('/curriculum-meals', {
          params: {
            curriculum_id: this.curriculum.id,
            day_number: 1,
            limit: 200
          }
        })
        .then(({ data }) => {
          this.mealsResult = data
          this.isFetching = false
        })
        .catch((error) => {
          this.isFetching = false
          throw error
        })
    },

    calcMealsMacro(meals, type) {
      return _.chain(meals)
        .map(i => i[type] || 0)
        .sum()
        .round(2)
        .value()
    },

    calcMealsMacroCalories(meals) {
      return _.chain(['protein', 'carbs', 'fat'])
        .map(i => this.getCaloriesByGrams(this.calcMealsMacro(meals, i), i))
        .sum()
        .round(2)
        .value()
    },

    getCaloriesByGrams(value, type) {
      const caloriesPerGram = type === 'fat' ? 9 : 4
      const calories = Math.round(parseFloat(value || '0') * caloriesPerGram * 100) / 100
      return calories
    }
  }
}
</script>