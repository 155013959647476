var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "fixed": "",
      "striped": "",
      "stacked": "sm"
    }
  }, [_c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "w-120px"
  }), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Calories")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Protein")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Carbs")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Fats")]), _c('b-th', {
    staticClass: "w-50px"
  })], 1)], 1), _c('b-tbody', [_vm._l(_vm.newResult.data, function (mealFood) {
    return _c('b-tr', {
      key: mealFood.id
    }, [_c('b-th', {
      staticClass: "font-weight-bold text-center align-middle",
      attrs: {
        "variant": "success"
      }
    }, [_c('TrackerMealFoodItemUpsertButton', {
      staticClass: "p-0 font-weight-bolder",
      attrs: {
        "item": mealFood,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": function submitted($event) {
          return _vm.$emit('submitted:item', $event);
        }
      }
    }, [_vm._v(" " + _vm._s(mealFood.food.name) + " ")]), _c('div', [_vm._v(" " + _vm._s(mealFood.amount) + " " + _vm._s(mealFood.food.serving_units) + " ")])], 1), _c('b-td', {
      staticClass: "text-center align-middle",
      attrs: {
        "stacked-heading": "Calories"
      }
    }, [_vm._v(" " + _vm._s(_vm.calcMealFoodMacro(mealFood, 'calories')) + " ")]), _c('b-td', {
      staticClass: "text-center align-middle",
      attrs: {
        "stacked-heading": "Protein"
      }
    }, [_vm._v(" " + _vm._s(_vm.calcMealFoodMacro(mealFood, 'protein')) + " ")]), _c('b-td', {
      staticClass: "text-center align-middle",
      attrs: {
        "stacked-heading": "Carbs"
      }
    }, [_vm._v(" " + _vm._s(_vm.calcMealFoodMacro(mealFood, 'carbs')) + " ")]), _c('b-td', {
      staticClass: "text-center align-middle",
      attrs: {
        "stacked-heading": "Fats"
      }
    }, [_vm._v(" " + _vm._s(_vm.calcMealFoodMacro(mealFood, 'fat')) + " ")]), _c('b-td', {
      staticClass: "text-center align-middle"
    }, [_c('TrackerMealFoodItemDeleteButton', {
      staticClass: "font-size-12",
      attrs: {
        "item": mealFood,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "delete:item": function deleteItem($event) {
          return _vm.$emit('delete:item', $event);
        }
      }
    }, [_c('b-icon-trash')], 1)], 1)], 1);
  }), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "colspan": "6"
    }
  }, [_c('b-row', {
    attrs: {
      "align": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('TrackerMealFoodItemUpsertButton', {
    staticClass: "p-0 mr-4",
    attrs: {
      "item": {
        meal_id: _vm.newParams.filters.meal_id
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('submitted:item', $event);
      }
    }
  }, [_c('b-icon-plus-circle', {
    staticClass: "mr-1",
    attrs: {
      "scale": "0.8"
    }
  }), _vm._v(" Add food ")], 1)], 1)], 1)], 1)], 1), _vm.macroRecommended ? _c('b-tr', {
    attrs: {
      "variant": "secondary"
    }
  }, [_c('b-th', {
    staticClass: "text-right font-weight-bolder"
  }, [_vm._v(" Recommended ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Calories"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroRecommended.calories) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Protein"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroRecommended.protein) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Carbs"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroRecommended.carbs) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Fats"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroRecommended.fat) + " ")]), _c('b-th')], 1) : _vm._e(), _c('b-tr', {
    attrs: {
      "variant": "secondary"
    }
  }, [_c('b-th', {
    staticClass: "text-right font-weight-bolder"
  }, [_vm._v(" Total ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Calories"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroTotal.calories) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Protein"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroTotal.protein) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Carbs"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroTotal.carbs) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Fats"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroTotal.fat) + " ")]), _c('b-th')], 1), _vm.meal.curriculum_meal ? _c('b-tr', {
    attrs: {
      "variant": "secondary"
    }
  }, [_c('b-th', {
    staticClass: "text-right font-weight-bolder"
  }, [_vm._v(" +/- ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Calories"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroDifference.calories) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Protein"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroDifference.protein) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Carbs"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroDifference.carbs) + " ")]), _c('b-th', {
    staticClass: "text-center font-weight-bolder",
    attrs: {
      "stacked-heading": "Fats"
    }
  }, [_vm._v(" " + _vm._s(_vm.macroDifference.fat) + " ")]), _c('b-th')], 1) : _vm._e()], 2)], 1), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }