var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _vm.$acl.check('isClient') ? _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "6"
    }
  }, [_c('b-button-group', _vm._l(_vm.steps, function (step, stepIndex) {
    return _c('b-button', {
      key: step.id,
      staticClass: "text-nowrap",
      attrs: {
        "variant": stepIndex === _vm.currentStepIndex ? 'primary' : stepIndex < _vm.currentStepIndex ? 'success' : null,
        "disabled": step.isDisabled,
        "to": "#".concat(step.id)
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]);
  }), 1)], 1) : _vm._e()], 1), _c('b-card', [_vm.currentStep.id === 'general' ? _c('UserProfileUpsert', {
    attrs: {
      "prefetch": false,
      "item": _vm.newItem
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  }) : _vm.currentStep.id === 'history' ? _c('ClientHistoryUpsert', {
    attrs: {
      "prefetch": false,
      "item": _vm.newItem
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  }) : _vm._e()], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }