<template>
  <b-overlay :show="isFetching">
    <b-table-simple
      class="mb-0"
      fixed
    >
      <b-thead>
        <b-tr variant="primary">
          <b-th class="text-center bg-transparent w-130px" />

          <b-th class="text-center">
            AM Cardio
          </b-th>

          <b-th class="text-center">
            PM Cardio
          </b-th>

          <b-th class="text-center">
            Totals
          </b-th>

          <b-th class="text-center">
            Minutes Per Session
          </b-th>
        </b-tr>
      </b-thead>

      <template>
        <b-tbody
          v-for="phase in curriculum.phases"
          :key="phase.number"
        >
          <b-tr>
            <b-th
              class="text-center w-130px"
              variant="success"
            >
              <div class="py-1">
                Phase {{ phase.number }}
              </div>
            </b-th>

            <b-td class="bg-white">
              <b-row
                align-v="center"
                class="text-center"
              >
                <b-col v-if="showSuggestions">
                  <template v-if='!suggestedValue[phase.number - 1]'>-</template>

                  <template v-else>
                    {{ suggestedValue[phase.number - 1].cardio_morning }}
                  </template>
                </b-col>

                <b-col v-if="showValues">
                  <b-form-input
                    v-if="!disabled && newValue"
                    v-model="newValue[phase.number - 1].cardio_morning"
                    class="text-center hide-arrows"
                    type="number"
                    step="1"
                    min="0"
                    number
                    size="sm"
                  />

                  <template v-else>
                    <template v-if="newValue">
                      {{ newValue[phase.number - 1].cardio_morning }}
                    </template>

                    <template v-else>
                      {{ phase.cardio_morning }}
                    </template>
                  </template>
                </b-col>
              </b-row>
            </b-td>

            <b-td class="bg-white">
              <b-row
                align-v="center"
                class="text-center"
              >
                <b-col v-if="showSuggestions">
                  <template v-if="!suggestedValue[phase.number - 1]">-</template>

                  <template v-else>
                    {{ suggestedValue[phase.number - 1].cardio_evening }}
                  </template>
                </b-col>

                <b-col v-if="showValues">
                  <b-form-input
                    v-if="!disabled && newValue"
                    v-model="newValue[phase.number - 1].cardio_evening"
                    class="text-center hide-arrows"
                    type="number"
                    step="1"
                    min="0"
                    number
                    size="sm"
                  />

                  <template v-else>
                    <template v-if="newValue">
                      {{ newValue[phase.number - 1].cardio_evening }}
                    </template>

                    <template v-else>
                      {{ phase.cardio_evening }}
                    </template>
                  </template>
                </b-col>
              </b-row>
            </b-td>

            <b-td class="bg-white">
              <b-row
                align-v="center"
                class="text-center"
              >
                <b-col v-if="showSuggestions">
                  <template v-if="!suggestedValue[phase.number - 1]">-</template>

                  <template v-else>
                    {{ suggestedValue[phase.number - 1].cardio_morning +
                       suggestedValue[phase.number - 1].cardio_evening }}
                  </template>
                </b-col>

                <b-col v-if="showValues">
                  <b-form-input
                    v-if="!disabled && newValue"
                    :value="newValue[phase.number - 1].cardio_morning +
                            newValue[phase.number - 1].cardio_evening"
                    class="text-center hide-arrows"
                    disabled
                    type="number"
                    step="1"
                    min="0"
                    number
                    size="sm"
                  />

                  <template v-else>
                    <template v-if="newValue">
                      {{ newValue[phase.number - 1].cardio_morning + newValue[phase.number - 1].cardio_evening }}
                    </template>

                    <template v-else>
                      {{ phase.cardio_morning + phase.cardio_evening }}
                    </template>
                  </template>
                </b-col>
              </b-row>
            </b-td>

            <b-td class="bg-white">
              <b-row
                align-v="center"
                class="text-center"
              >
                <b-col v-if="showSuggestions">
                  <template v-if="!suggestedValue[phase.number - 1]">-</template>

                  <template v-else>
                    {{ suggestedValue[phase.number - 1].cardio_mins_per_session }}
                  </template>
                </b-col>

                <b-col v-if="showValues">
                  <b-form-input
                    v-if="!disabled && newValue"
                    v-model="newValue[phase.number - 1].cardio_mins_per_session"
                    class="text-center hide-arrows"
                    type="number"
                    step="1"
                    min="0"
                    number
                    size="sm"
                  />

                  <template v-else>
                    <template v-if="newValue">
                      {{ newValue[phase.number - 1].cardio_mins_per_session }}
                    </template>

                    <template v-else>
                      {{ phase.cardio_mins_per_session }}
                    </template>
                  </template>
                </b-col>
              </b-row>
            </b-td>
          </b-tr>
        </b-tbody>
      </template>
    </b-table-simple>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import formMixin from '@/mixins/form'

export default {
  name: 'CurriculumPhasesCardioInput',

  mixins: [
    formMixin
  ],

  props: {
    curriculum: {
      type: Object,
      required: true
    },

    mode: {
      type: String,
      default: null
    },

    showHeader: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFetching: false,
      suggestions: null,
      autosave: true
    }
  },

  computed: {
    showValues() {
      return !this.mode || this.mode === 'values'
    },

    showSuggestions() {
      return !this.mode || this.mode === 'suggestions'
    },

    calculateParams() {
      const calculateTerm = Math.max(parseInt(this.curriculum.term), 16)

      return {
        current_weight: this.curriculum.physical_condition.weight,
        gender: this.curriculum.profile.sex,
        weight_loss_goal: this.curriculum.profile.goal_weight,
        weeks_count: calculateTerm,
        goal_direction: this.curriculum.profile.goal_direction,
        competition_weight: this.curriculum.profile.competition_weight,
        fitness_level_ids: _.map(this.curriculum.phases, i => i.projected_fitness_level_id),
        body_fat_p_values: _.map(this.curriculum.phases, i => i.projected_body_fat_p)
      }
    },

    weightLossGoal() {
      return this.calculateParams.goal_direction === 3 ? this.calculateParams.competition_weight : this.calculateParams.weight_loss_goal
    },

    calculateParamsAreValid() {
      return (
        this.calculateParams.current_weight &&
        this.calculateParams.gender &&
        this.calculateParams.goal_direction &&
        this.calculateParams.weeks_count &&
        this.weightLossGoal &&
        !_.chain(this.calculateParams.fitness_level_ids).filter(i => _.isNil(i)).value().length &&
        !_.chain(this.calculateParams.body_fat_p_values).filter(i => _.isNil(i)).value().length
      )
    },

    suggestedValue() {
      return _.map(this.suggestions, phase => ({
        number: phase.phase_number,
        cardio_morning: Math.round(phase.workout_suggestions.number_of_workouts.morning),
        cardio_evening: Math.round(phase.workout_suggestions.number_of_workouts.evening),
        cardio_mins_per_session: Math.round(phase.cardio_mins_per_workout)
      })) 
    }
  },

  watch: {
    calculateParams: {
      immediate: true,
      deep: true,

      handler() {
        if ((!this.mode || this.mode === 'suggestions') && this.calculateParamsAreValid) {
          this.fetchSuggestions()
        }
      }
    },

    suggestedValue: {
      immediate: true,
      deep: true,

      handler() {
        if (this.suggestedValue.length) {
          this.newValue = _.map(this.newValue, (phase, phaseIndex) => ({
            ...phase,
            cardio_morning: !_.isNil(phase.cardio_morning) ? phase.cardio_morning : this.suggestedValue[phaseIndex].cardio_morning,
            cardio_evening: !_.isNil(phase.cardio_evening) ? phase.cardio_evening : this.suggestedValue[phaseIndex].cardio_evening,
            cardio_mins_per_session: !_.isNil(phase.cardio_mins_per_session) ? phase.cardio_mins_per_session : this.suggestedValue[phaseIndex].cardio_mins_per_session
          }))
        }
      }
    },

    calculateParamsAreValid: {
      immediate: true,
      
      handler(newValue, oldValue) {
        if (!newValue && newValue !== oldValue) {
          this.suggestions = null
        }
      }
    }
  },

  methods: {
    fetchSuggestions() {
      this.isFetching = true

      return this.$axios
        .post('/forecast/phase/side/calculate', this.calculateParams)
        .then(({ data }) => {
          this.suggestions = data.data
          this.isFetching = false
        })
        .catch((error) => {
          this.isFetching = false
          throw error
        })
    }
  }
}
</script>