<template>
  <b-overlay
    :show="isFetching"
  >
    <!-- WATER -->
    <b-form-group
      class="mb-4"
      label="Water"
      label-class="font-size-20 text-primary font-weight-bold"
    >
      <CurriculumPhasesWaterInput
        class="mb-2"
        mode="values"
        disabled
        :curriculum="newItem"
      />
    </b-form-group>

    <!-- SUPPLEMENTS -->
    <b-form-group
      class="mb-4"
      label="Supplements"
      label-class="font-size-20 text-primary font-weight-bold"
    >
      <CurriculumPhaseSupplementList
        v-for="(phase, phaseIndex) in newItem.phases"
        :key="phase.id"
        :show-header="phaseIndex === 0"
        :phase="phase"
        :params="{
          filters: {
            phase_id: phase.id
          },
          limit: 100
        }"
      />
    </b-form-group>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import CurriculumPhasesWaterInput from '@/components/curriculums/inputs/CurriculumPhasesWaterInput'
import CurriculumPhaseSupplementList from '@/components/curriculums/phase-supplements/CurriculumPhaseSupplementList'

export default {
  name: 'CurriculumItemSupplementsDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumPhasesWaterInput,
    CurriculumPhaseSupplementList
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  }
}
</script>