var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    "class": _vm.sizeClasses
  }, [_vm.$slots.prepend || _vm.prepend ? _c('div', {
    ref: "prependDiv",
    staticClass: "input-group-prepend"
  }, [_vm._t("prepend", function () {
    return [_c('span', {
      staticClass: "input-group-text"
    }, [_vm._v(_vm._s(_vm.prepend))])];
  })], 2) : _vm._e(), _c('b-input', {
    ref: "input",
    "class": "form-control ".concat(_vm.inputClass),
    attrs: {
      "type": "search",
      "placeholder": _vm.placeholder,
      "aria-label": _vm.placeholder,
      "value": _vm.inputValue,
      "state": _vm.state,
      "size": _vm.size,
      "autocomplete": "off"
    },
    on: {
      "focus": function focus($event) {
        _vm.isFocused = true;
      },
      "blur": _vm.handleBlur,
      "input": function input($event) {
        return _vm.handleInput($event);
      }
    }
  }), _vm.$slots.append || _vm.append ? _c('div', {
    staticClass: "input-group-append"
  }, [_vm._t("append", function () {
    return [_c('span', {
      staticClass: "input-group-text"
    }, [_vm._v(_vm._s(_vm.append))])];
  })], 2) : _vm._e()], 1), _c('vue-bootstrap-typeahead-list', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isFocused && _vm.data.length > 0,
      expression: "isFocused && data.length > 0"
    }],
    ref: "list",
    staticClass: "vbt-autcomplete-list",
    attrs: {
      "query": _vm.inputValue,
      "data": _vm.formattedData,
      "background-variant": _vm.backgroundVariant,
      "text-variant": _vm.textVariant,
      "maxMatches": _vm.maxMatches,
      "minMatchingChars": _vm.minMatchingChars,
      "doNotFilter": _vm.doNotFilter
    },
    on: {
      "hit": _vm.handleHit
    },
    scopedSlots: _vm._u([_vm._l(_vm.$scopedSlots, function (slot, slotName) {
      return {
        key: slotName,
        fn: function fn(_ref) {
          var data = _ref.data,
              htmlText = _ref.htmlText;
          return [_vm._t(slotName, null, null, {
            data: data,
            htmlText: htmlText
          })];
        }
      };
    })], null, true)
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }