<template>
  <span>
    {{ macroTotal[macro] }}
  </span>
</template>

<script>
import _ from 'lodash'

export default {
  props: {
    item: {
      type: Object,
      required: true
    },

    macro: {
      type: String,
      required: true
    }
  },

  computed: {
    macroTotal() {
      return {
        calories: this.calcMealsMacro(this.item.meals, 'calories'),
        protein: this.calcMealsMacro(this.item.meals, 'protein'),
        carbs: this.calcMealsMacro(this.item.meals, 'carbs'),
        fat: this.calcMealsMacro(this.item.meals, 'fat')
      }
    }
  },

  methods: {
    calcMealFoodMacro(mealFood, type) {
      const mealFoodMacro = Math.round(mealFood.amount * (mealFood.food.serving_size || 1) * mealFood.food[type]) / 100
      const decimalPlaces = type === 'calories' ? 0 : 1
      return Math.round(mealFoodMacro * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
    },

    calcMealsMacro(meals, type) {
      return _.chain(meals)
        .flatMap('meal_foods')
        .map(i => this.calcMealFoodMacro(i, type))
        .sum()
        .round(2)
        .value()
    }
  }
}
</script>