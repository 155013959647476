<template>
  <Layout>
    <b-overlay :show="isFetching">
      <template v-if="isFetched">
        <b-row>
          <b-col cols="6">
            <PageHeader :title="title" />
          </b-col>

          <b-col
            v-if="$acl.check('isClient')"
            cols="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button-group>
              <b-button
                v-for="(step, stepIndex) in steps"
                :key="step.id"
                class="text-nowrap"
                :variant="stepIndex === currentStepIndex ? 'primary' : (stepIndex < currentStepIndex ? 'success' : null)"
                :disabled="step.isDisabled"
                :to="`#${step.id}`"
              >
                {{ step.title }}
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <b-card>
          <UserProfileUpsert
            v-if="currentStep.id === 'general'"
            :prefetch="false"
            :item.sync="newItem"
          />

          <ClientHistoryUpsert
            v-else-if="currentStep.id === 'history'"
            :prefetch="false"
            :item.sync="newItem"
          />
        </b-card>
      </template>
    </b-overlay>
  </Layout>
</template>

<script>
import _ from 'lodash'
import crudItem from '@/mixins/crud-item'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import UserProfileUpsert from '@/components/users/UserProfileUpsert'
import ClientHistoryUpsert from '@/components/clients/ClientHistoryUpsert'

export default {
  name: 'ProfilePage',

  mixins: [
    crudItem
  ],

  components: {
    Layout,
    PageHeader,
    UserProfileUpsert,
    ClientHistoryUpsert
  },

  page: {
    title: 'Profile'
  },

  props: {
    item: {
      type: Object,

      default: function() {
        const id = parseInt(this.$route.params.id) || this.$store.getters['auth/currentUser'].id
        return { id }
      }
    },

    prefetch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  computed: {
    title() {
      return 'Profile'
    },

    steps() {
      const steps = [
        { id: 'general', title: 'General Info', isVisible: true },
        { id: 'history', title: 'History', isVisible: this.$acl.check('isPaidClient') }
      ]

      return _.filter(steps, { isVisible: true })
    },

    currentStepIndex: {
      get() {
        const index = _.findIndex(this.steps, { id: this.$route.hash.replace('#', '') })
        return index >= 0 ? index : 0
      },

      set(index) {
        const step = _.get(this.steps, `${index}.id`) || 'general'
        this.$router.push({ ...this.$route, hash: `#${step}` })
      }
    },

    currentStep() {
      return this.steps[this.currentStepIndex]
    }
  },

  watch: {
    '$route.hash': {
      immediate: true,
      deep: true,

      handler() {
        this.refresh()
      }
    }
  }
}
</script>