var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1)], 1), _vm.$acl.check('isClient') ? [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', [_c('BillingDetail', {
    attrs: {
      "item": _vm.currentUser,
      "prefetch": false
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card', [_c('PasswordChange', {
    attrs: {
      "item": _vm.currentUser,
      "prefetch": false
    }
  })], 1)], 1)], 1), _c('h2', {
    staticClass: "font-size-18 mb-3"
  }, [_vm._v("Purchases")]), _c('b-row', [_c('b-col', [_c('b-card', [_c('CompanyPaymentList')], 1)], 1)], 1)] : _c('b-row', [_c('b-col', [_c('b-card', [_c('PasswordChange', {
    attrs: {
      "item": _vm.currentUser,
      "prefetch": false
    }
  })], 1)], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }