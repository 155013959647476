var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1)], 1), !_vm.isCreated || _vm.isFetched ? _c('b-card', [_c('ExerciseItemUpsert', {
    attrs: {
      "item": _vm.newItem
    },
    on: {
      "update:item": [function ($event) {
        _vm.newItem = $event;
      }, _vm.onUpdateItem],
      "submitted": function submitted($event) {
        return _vm.$router.push('/exercise/list');
      },
      "cancel": function cancel($event) {
        return _vm.$router.push('/exercise/list');
      }
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }