<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>
    </b-row>

    <b-card>
      <CompanyItemUpsert
        :item.sync="item"
      />
    </b-card>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import CompanyItemUpsert from '@/components/companies/CompanyItemUpsert'

export default {
  name: 'CompanyPage',

  components: {
    Layout,
    PageHeader,
    CompanyItemUpsert
  },

  page() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      item: null
    }
  },

  computed: {
    title() {
      return this.item.id ? this.item.name || 'Company' : 'Add company'
    },

    itemId() {
      return this.$route.params.id
    }
  },

  watch: {
    itemId: {
      immediate: true,
      
      handler() {
        if (this.itemId) {
          this.item = { id: this.itemId }
        } else {
          this.item = {}
        }
      }
    }
  }
}
</script>