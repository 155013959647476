<template>
  <vue-bootstrap-typeahead
    ref="typeahead"
    v-model="query"
    :placeholder="placeholder"
    :data="options"
    :min-matching-chars="0"
    :serializer="serializer"
    @input="onInput"
    @hit="onHit"
    @blur="onBlur"
  />
</template>

<script>
import VueBootstrapTypeahead from '@/components/typeahead/VueBootstrapTypeahead'

export default {
  name: 'Autocomplete',

  components: {
    VueBootstrapTypeahead
  },

  props: {
    value: {
      type: String,
      default: null
    },

    options: {
      type: Array,
      required: true
    },

    serializer: {
      type: Function,
      default: (i) => i
    },

    placeholder: {
      type: String,
      default: 'Type to select...'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      query: null
    }
  },

  watch: {
    value: {
      immediate: true,
      
      handler() {
        this.$nextTick(() => {
          this.$refs.typeahead.handleInput(this.value || '')
        })
      }
    }
  },

  methods: {
    onInput($event) {
      if (!$event) {
        this.$emit('input', null)
      }
    },

    onHit($event) {
      this.$emit('input', $event)
    },

    onBlur() {
      this.$refs.typeahead.handleInput(this.value || '')
    }
  }
}
</script>