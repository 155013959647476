var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0",
      "justified": ""
    }
  }, _vm._l(_vm.value, function (phase, phaseIndex) {
    return _c('b-tab', {
      key: phase.id,
      attrs: {
        "title-link-class": "rounded-0 border-0",
        "title": "Phase ".concat(phaseIndex + 1)
      }
    }, [_c('b-card', {
      staticClass: "my-0",
      attrs: {
        "no-body": ""
      }
    }, _vm._l(phase.weeks, function (week, weekIndex) {
      return _c('b-table-simple', {
        key: week.id,
        staticClass: "m-0 input-table"
      }, [_c('b-thead', [_c('b-tr', [_c('b-th', {
        attrs: {
          "colspan": "4"
        }
      }, [_vm._v(" Week " + _vm._s(weekIndex + 1) + " ")])], 1)], 1), _c('b-tbody', _vm._l(week.workouts, function (workout, workoutIndex) {
        return _c('b-tr', {
          key: workoutIndex,
          on: {
            "click": function click($event) {
              return _vm.$emit('select-workout', workout);
            }
          }
        }, [_c('b-td', [_vm._v(" " + _vm._s(workoutIndex) + " ")]), _c('b-td', {
          staticClass: "w-20"
        }, [_vm._v(" " + _vm._s(workout.name) + " ")]), _c('b-td', {
          staticClass: "w-33-33"
        }, [_c('Muscles', {
          attrs: {
            "ids": workout.muscle_ids
          }
        })], 1), _c('b-td', {
          staticClass: "w-33-33"
        }, [_c('Abilities', {
          attrs: {
            "ids": workout.fitness_level_ids
          }
        })], 1)], 1);
      }), 1)], 1);
    }), 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }