<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="isFetched">
      <b-card
        bg-variant="dark"
        text-variant="white"
        class="rounded-0 pt-4 m-0"
        body-class="pt-5 mt-5"
      >
        <div class="font-size-24 font-weight-bold">
          {{ newItem.name }}
        </div>

        {{ newItem.description }}
      </b-card>

      <b-card
        class="py-3 m-0"
        bg-variant="light"
      >
        <b-row>
          <b-col>
            <span class="font-weight-bold">
              Fitness Level(s)
            </span>:
            
            <Abilities
              :ids="newItem.fitness_level_ids || []"
            />
          </b-col>

          <b-col>
            <span class="font-weight-bold">
              Muscles:
            </span>
            
            <Muscles
              :ids="newItem.muscle_ids || []"
            />
          </b-col>
        </b-row>
      </b-card>

      <b-card class="m-0">
        <WorkoutExerciseGroupsInput
          :value="newItem.exercise_groups"
        />
      </b-card>
    </template>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import Abilities from '@/components/v1/asset/Abilities'
import Muscles from '@/components/v1/asset/Muscles'
import WorkoutExerciseGroupsInput from '@/components/workouts/inputs/WorkoutExerciseGroupsInput'

export default {
  name: 'WorkoutItemDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    Abilities,
    Muscles,
    WorkoutExerciseGroupsInput
  },

  data() {
    return {
      baseEndpoint: 'workouts'
    }
  }
}
</script>