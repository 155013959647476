var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "fixed": ""
    }
  }, [_vm.showHeader ? _c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "w-130px bg-transparent"
  }), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-th', {
      key: phase.number,
      staticClass: "text-center",
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v(" Phase " + _vm._s(phase.number) + " ")]);
  })], 2)], 1) : _vm._e(), _c('b-tbody', [_c('b-tr', {
    staticClass: "bg-white"
  }, [_c('b-th', {
    staticClass: "w-130px align-middle",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "py-1 text-nowrap text-center"
  }, [_vm._v(" Activity Level ")])]), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-td', {
      key: phase.number,
      staticClass: "text-center",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', {
      staticClass: "mb-2",
      attrs: {
        "cols": "12"
      }
    }, [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("...")] : _vm.suggestedValue[phase.number - 1].projected_fitness_level_id ? _c('Abilities', {
      staticClass: "text-secondary font-size-12",
      attrs: {
        "ids": [_vm.suggestedValue[phase.number - 1].projected_fitness_level_id]
      }
    }) : [_vm._v("-")]], 2) : _vm._e(), _vm.showValues && _vm.newValue ? _c('b-col', [!_vm.disabled ? _c('b-form-select', {
      attrs: {
        "options": _vm.$store.getters['asset/getAbilityLevelsOptions']
      },
      model: {
        value: _vm.newValue[phase.number - 1].projected_fitness_level_id,
        callback: function callback($$v) {
          _vm.$set(_vm.newValue[phase.number - 1], "projected_fitness_level_id", $$v);
        },
        expression: "newValue[phase.number - 1].projected_fitness_level_id"
      }
    }) : [_c('Abilities', {
      attrs: {
        "ids": [_vm.newValue[phase.number - 1].projected_fitness_level_id || '-']
      }
    })]], 2) : _vm._e()], 1)], 1);
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }