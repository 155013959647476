var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h6', [_vm._v("Select Forecast:")])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card card-forecast"
  }, [_c('div', {
    staticClass: "table-forecasts mb-0"
  }, [_c('b-table', {
    attrs: {
      "items": _vm.dataProvider,
      "fields": _vm.fields,
      "responsive": "sm",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "filter-included-fields": _vm.filterOn
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "row-clicked": _vm.onForecastSelect
    },
    scopedSlots: _vm._u([{
      key: "cell(select)",
      fn: function fn(data) {
        return [_c('b-form-radio', {
          attrs: {
            "name": "selected_forecast",
            "value": data.item.id
          },
          model: {
            value: _vm.selected_forecast,
            callback: function callback($$v) {
              _vm.selected_forecast = $$v;
            },
            expression: "selected_forecast"
          }
        })];
      }
    }, {
      key: "cell(fitness_level_ids)",
      fn: function fn(data) {
        return [_c('Abilities', {
          attrs: {
            "ids": data.item.fitness_level_ids || []
          }
        })];
      }
    }, {
      key: "cell(goal_direction)",
      fn: function fn(data) {
        return [_c('GoalDirections', {
          attrs: {
            "ids": [data.item.goal_direction]
          }
        })];
      }
    }])
  })], 1), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6"
  }), _c('div', {
    staticClass: "col-sm-12 col-md-6"
  }, [_c('div', {
    staticClass: "dataTables_paginate paging_simple_numbers float-right"
  }, [_c('ul', {
    staticClass: "pagination pagination-rounded mb-0"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.rows,
      "per-page": _vm.perPage
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('h6', [_vm._v("Select the maximum number of workouts per any one phase:")]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-2"
  }), _c('div', {
    staticClass: "col-8"
  }, [_c('vue-slide-bar', {
    attrs: {
      "min": 1,
      "max": 14
    },
    model: {
      value: _vm.number_of_workouts,
      callback: function callback($$v) {
        _vm.number_of_workouts = $$v;
      },
      expression: "number_of_workouts"
    }
  })], 1)])])])])])])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12"
  }, [_c('div', {
    staticClass: "mt-3"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "disabled": ""
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary ml-1",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": _vm.onNextClick
    }
  }, [_vm._v(" Next ")])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 text-right"
  }, [_vm.next_clicked && !_vm.selected_forecast ? _c('div', {
    "class": {
      'is-invalid': _vm.next_clicked && !_vm.selected_forecast
    }
  }) : _vm._e(), _vm.next_clicked && !_vm.selected_forecast ? _c('div', {
    staticClass: "invalid-feedback"
  }, [_c('span', [_vm._v("Select a forecast.")])]) : _vm._e()])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }