var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? [_c('div', {
    staticClass: "m-3"
  }, [_c('b-table-simple', {
    staticClass: "text-center"
  }, [_c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "w-100px"
  }), _c('b-th', [_vm._v("Calories")]), _c('b-th', [_vm._v("Proteins")]), _c('b-th', [_vm._v("Carbs")]), _c('b-th', [_vm._v("Fats")])], 1)], 1), _c('b-tbody', [_vm.macroRecommended ? _c('b-tr', [_c('b-th', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Total ")]), _c('b-td', [_vm._v(_vm._s(_vm.macroRecommended.calories))]), _c('b-td', [_vm._v(_vm._s(_vm.macroRecommended.protein))]), _c('b-td', [_vm._v(_vm._s(_vm.macroRecommended.carbs))]), _c('b-td', [_vm._v(_vm._s(_vm.macroRecommended.fat))])], 1) : _vm._e(), _c('b-tr', [_c('b-th', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Taken ")]), _c('b-td', [_vm._v(_vm._s(_vm.macroTotal.calories))]), _c('b-td', [_vm._v(_vm._s(_vm.macroTotal.protein))]), _c('b-td', [_vm._v(_vm._s(_vm.macroTotal.carbs))]), _c('b-td', [_vm._v(_vm._s(_vm.macroTotal.fat))])], 1), _vm.macroRecommended ? _c('b-tr', [_c('b-th', {
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" +/- ")]), _c('b-td', [_vm._v(_vm._s(_vm.macroDifference.calories))]), _c('b-td', [_vm._v(_vm._s(_vm.macroDifference.protein))]), _c('b-td', [_vm._v(_vm._s(_vm.macroDifference.carbs))]), _c('b-td', [_vm._v(_vm._s(_vm.macroDifference.fat))])], 1) : _vm._e()], 1)], 1)], 1), _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "9"
    }
  }, [_c('b-tabs', {
    attrs: {
      "card": "",
      "no-fade": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Meals"
    }
  }, [_c('TrackerMealList', {
    attrs: {
      "params": {
        filters: {
          nutrition_id: _vm.newItem.id
        }
      },
      "result": {
        data: _vm.newItem.meals
      },
      "prefetch": false
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.refresh();
      },
      "delete:item": function deleteItem($event) {
        return _vm.refresh();
      },
      "submitted:meal-food": function submittedMealFood($event) {
        return _vm.refresh();
      },
      "delete:meal-food": function deleteMealFood($event) {
        return _vm.refresh();
      }
    }
  })], 1), _c('b-tab', {
    attrs: {
      "title": "Supplements"
    }
  }, [_c('TrackerSupplementList', {
    attrs: {
      "params": {
        filters: {
          nutrition_id: _vm.newItem.id
        }
      },
      "result": {
        data: _vm.newItem.supplements
      },
      "prefetch": false
    },
    on: {
      "submitted:item": function submittedItem($event) {
        return _vm.refresh();
      },
      "delete:item": function deleteItem($event) {
        return _vm.refresh();
      }
    }
  })], 1)], 1)], 1), _c('b-col', [_c('b-tabs', {
    attrs: {
      "card": "",
      "no-fade": "",
      "justified": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Water"
    }
  }, [_c('div', {
    staticClass: "text-center font-size-24 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.input.water || 0)), _vm.waterRecommended ? [_vm._v(" / " + _vm._s(_vm.waterRecommended))] : _vm._e()], 2), _c('div', {
    staticClass: "text-center font-weight-bolder"
  }, [_vm._v("Ounces")]), _c('div', {
    staticClass: "text-center"
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "list"
    },
    on: {
      "click": function click($event) {
        _vm.input.water += 1;
      }
    }
  }, [_c('b-icon-plus-circle')], 1), _c('b-button', {
    attrs: {
      "variant": "list"
    },
    on: {
      "click": function click($event) {
        _vm.input.water ? _vm.input.water -= 1 : null;
      }
    }
  }, [_c('b-icon-dash-circle')], 1)], 1)], 1), _c('div', {
    staticClass: "text-center my-3"
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e()], 1)])], 1)], 1)], 1)], 1)], 1)] : _vm.isFetching ? _c('div', [_c('b-alert', {
    staticClass: "m-0 text-center",
    attrs: {
      "show": "",
      "variant": "light"
    }
  }, [_vm._v(" Loading... ")])], 1) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }