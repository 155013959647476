<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(name)="row">
        <b-avatar
          class="mr-2"
          :src="row.item.profile_img_url"
        />
        {{ row.item.first_name }} {{ row.item.last_name }}
      </template>

      <template #cell(roles)="row">
        <b-badge
          v-for="(roleId, roleIndex) in row.item.role_ids"
          :key="roleIndex"
          class="mr-1"
          variant="info"
        >
          <Roles :ids="[roleId]"/>
        </b-badge>
      </template>

      <template #cell(actions)="row">
        <UserItemActionButtons
          v-if="getCouldActionItem(row.item)"
          :item="row.item"
          @delete:item="refresh"
          @submitted="refresh"
        >
          <b-icon-three-dots />
        </UserItemActionButtons>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudList from '@/mixins/crud-list'
import UserItemActionButtons from './buttons/UserItemActionsButton'
import Roles from '@/components/v1/asset/Roles'
import { mapGetters } from 'vuex'

export default {
  name: 'UserList',

  mixins: [
    crudList
  ],

  components: {
    UserItemActionButtons,
    Roles
  },

  data() {
    return {
      baseEndpoint: 'users',

      fields: [
        { key: 'name', sortable: false, label: 'Name' },
        { key: 'roles', sortable: false, label: 'Roles' },
        { key: 'email', sortable: false, label: 'Email Address' },
        { key: 'phone', sortable: false, label: 'Phone Number' },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },

  methods: {
    getCouldActionItem(item) {
      if (item.id === this.currentUser.id) {
        return true
      }

      if (item.role_ids.includes(2) && this.$acl.check('couldActionTrainer')) {
        return true
      }

      if (item.role_ids.includes(4) && this.$acl.check('couldActionManager')) {
        return true
      }

      if (item.role_ids.includes(5) && this.$acl.check('couldActionSales')) {
        return true
      }

      return false
    }
  }
}
</script>