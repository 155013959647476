<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(created_at)="row">
        {{ $moment(row.item.created_at).format('YYYY-MM-DD HH:mm') }}
      </template>

      <template #cell(actions)="row">
        <PhysicalConditionItemActionsButton
          :item="row.item"
          :client="client"
          @delete:item="refresh"
          @update:client="$emit('update:client', $event)"
          @submitted="refresh"
        >
          <b-icon-three-dots />
        </PhysicalConditionItemActionsButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import PhysicalConditionItemActionsButton from './buttons/PhysicalConditionItemActionsButton'

export default {
  name: 'PhysicalConditionList',

  mixins: [
    crudListMixin
  ],

  components: {
    PhysicalConditionItemActionsButton
  },

  props: {
    client: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      baseEndpoint: 'physical-conditions',

      fields: [
        { key: 'created_at', sortable: false, label: 'Date' },
        { key: 'weight', sortable: false },
        { key: 'total_body_fat_p', sortable: false },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  }
}
</script>

<style scoped>
  .table-select >>> table tbody tr {
    cursor: pointer;
  }
  .table-select.is-suggested >>> table tbody tr {
    background: #d7dbf4;
  }
  .table-select.is-suggested >>> table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
</style>