var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.disabled ? _c('span', [_vm._v(" " + _vm._s(_vm.time.mm) + ":" + _vm._s(_vm.time.ss) + " ")]) : _c('vue-timepicker', {
    attrs: {
      "format": "mm:ss"
    },
    model: {
      value: _vm.time,
      callback: function callback($$v) {
        _vm.time = $$v;
      },
      expression: "time"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }