var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.item ? _c('TrackerWorkoutItemUpsert', {
    key: _vm.item.id,
    staticClass: "p-3",
    attrs: {
      "item": {
        id: _vm.item.id
      }
    },
    on: {
      "completed": function completed($event) {
        _vm.$emit('submitted', $event);

        _vm.refresh();
      },
      "submitted": function submitted($event) {
        return _vm.$emit('submitted', $event);
      }
    }
  }) : _vm.isFetching ? _c('b-alert', {
    staticClass: "m-0 text-center",
    attrs: {
      "show": "",
      "variant": "light"
    }
  }, [_vm._v(" Loading... ")]) : _c('b-alert', {
    staticClass: "m-0 text-center",
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_vm._v(" You have no workouts to work. ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }