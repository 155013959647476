var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "overflow-hidden"
  }, [_c('b-row', {
    staticClass: "min-vh-100"
  }, [_c('b-col', {
    attrs: {
      "cols": "12",
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "py-5 px-4"
  }, [_c('b-img', {
    staticClass: "mb-5",
    attrs: {
      "center": "",
      "src": require('@/assets/images/logo.png'),
      "width": "175",
      "fluid": ""
    }
  }), _c('h1', {
    staticClass: "font-size-18 text-center"
  }, [_vm._v(" Login... ")]), _c('LoginKey', {
    staticClass: "mx-auto",
    style: {
      maxWidth: '400px'
    },
    on: {
      "submitted": _vm.onSubmitted
    }
  }), _c('b-button', {
    staticClass: "mt-1",
    attrs: {
      "to": "/login",
      "variant": "link",
      "block": ""
    }
  }, [_vm._v(" Return to login ")])], 1)]), _c('b-col', {
    style: {
      background: "url(".concat(require('@/assets/images/bg.jpg'), ")"),
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }