<template>
  <b-overlay
    :show="isBusy"
    :variant="config.overlay.variant"
    :opacity="config.overlay.opacity"
    :blur="config.overlay.blur"
    rounded="sm"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :busy="isBusy"
      :items="fetchItems"
      :fields="fields"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter-included-fields="filterOn"
      @row-clicked="$emit('input', $event.id)"
    >
      <template #cell(actions)="row">
        <NBCaliperItemActionsButton
          :item="row.item"
          @update:item="refresh"
          @delete:item="refresh"
        >
          <b-icon-three-dots />
        </NBCaliperItemActionsButton>
      </template>
    </b-table>

    <div
      v-if="showPagination"
      class="pagination pagination-rounded"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      />
    </div>
  </b-overlay>
</template>
<script>
import appConfig from '@/app.config'
import NBCaliperItemActionsButton from './buttons/NBCaliperItemActionsButton'

export default {
  name: 'NBCaliperList',

  components: {
    NBCaliperItemActionsButton
  },

  data() {
    return {
      isBusy: false,
      config: appConfig,
      totalRows: 0,
      currentPage: 1,
      perPage: 400,
      sortBy: 'name',
      sortDesc: false,
      filterOn: [],
      fields: [
        { key: 'weight_from', sortable: false },
        { key: 'weight_to', sortable: false },
        { key: 'weeks_count', sortable: false },
        { key: 'nb_caliper', sortable: false },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  },

  computed: {
    showPagination() {
      return this.totalRows > this.currentPage + this.perPage
    }
  },

  methods: {
    async fetchItems() {
      const limit = this.perPage
      const page = this.currentPage

      return this.$axios
        .get(`admin/nb-caliper`, {
          parent: this,
          params: { limit, page }
        })
        .then(({ data }) => {
          this.totalRows = data.total
          return data.data
        })
    },

    refresh() {
      return this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
  .table-select >>> table tbody tr {
    cursor: pointer;
  }
  .table-select.is-suggested >>> table tbody tr {
    background: #d7dbf4;
  }
  .table-select.is-suggested >>> table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
</style>