<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import FaIcon from '@/components/widgets/fa-icon';
import { assetComputed, companyComputed } from '@/state/helpers';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex'
import _ from 'lodash';
import s from 'underscore.string';
import Swal from 'sweetalert2';
import Draggable from 'vuedraggable';
import FormInputButtons from '@/components/FormInputButtons'
//import RawDisplayer from '@/components/raw-displayer';

/**
 * Form Advanced component
 */
export default {
  name: 'WorkoutAdd',
  page: {
    title: 'Workout Creator',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  computed: {
    ...assetComputed,
    ...companyComputed,
    ...mapGetters({
      currentCompany: 'auth/currentCompany'
    }),
    exercises_total_time() {
      let total = 0;

      this.groupsData.forEach((group) => {
        group.exercises.forEach((exercise) => {
          total += exercise.estimated_set_time;
        });
      });

      return total;
    },
    exercises_total() {
      let total = 0;

      this.groupsData.forEach((group) => {
        total += group.exercises.length;
      });

      return total;
    },
    title() {
      return this.isEdit
        ? 'Edit Workout'
        : this.isCopy
        ? 'Copy Workout'
        : 'Workout Creator';
    },
    isEdit() {
      return this.$route.name === 'workout-edit';
    },
    isCopy() {
      return this.$route.name === 'workout-copy';
    },
  },
  components: { Layout, PageHeader, FaIcon, Draggable, FormInputButtons },
  data() {
    return this.initialData();
  },
  validations: {
    workoutForm: {
      name: { required, minLength: minLength(5), maxLength: maxLength(255) },
      description: {
        required,
        maxLength: maxLength(255),
      },
      fitness_level_ids: {
        required,
      }
    },
  },
  methods: {
    s: s,
    initialData() {
      return {
        config: appConfig,
        // entity id in edit
        id: null,
        // edit workout entity
        editEntityMode: true,
        // when go to the next step on Workout save - use this flag in order not to reload form data
        keepData: false,
        workoutSubmit: false,
        workoutFormClone: {},
        workoutOverlay: false,
        workoutForm: {
          name: '',
          description: '',
          fitness_level_ids: [],
          is_private: false,
          // type: null, // Remove type as we need only weights
          company_id: null,
        },
        exercises: {
          isBusy: false,
          items: [],
          totalRows: 0,
          currentPage: 1,
          perPage: 10,
          filter: {
            primary_muscle: '',
            ability_level: '',
            type: '',
            favorite: false,
          },
          //sortBy: 'name',
          //sortDesc: false,
        },
        groupsOverlay: false,
        groups: [],
        groupsData: [],
        exercises_list: [],
      };
    },
    workoutFormSubmit() {
      this.workoutSubmit = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.workoutForm.company_id = this.currentCompany ?
                                    this.currentCompany.id :
                                    null;

      this.workoutOverlay = true;
      if (this.isEdit) {
        this.$axios
          .patch(`/workouts/${this.id}`, this.workoutForm)
          .then(({ data }) => {
            this.workoutOverlay = false;
            this.editEntityMode = false;

            this.workoutForm = {
              name: data.name,
              description: data.description,
              fitness_level_ids: data.fitness_level_ids,
              is_private: data.is_private
            };
            //this.$router.push('/exercises/list');
          })
          .catch(() => {
            this.workoutOverlay = false;
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Could not save workout',
            });
          });
      } else {
        this.$axios
          .post('/workouts', this.workoutForm)
          .then(({ data }) => {
            this.workoutOverlay = false;
            this.editEntityMode = false;

            this.keepData = true;
            this.$router.push(`/workout/${data.id}`);
          })
          .catch(() => {
            this.workoutOverlay = false;
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Could not save workout',
            });
          });
      }
    },
    loadExercises() {
      this.exercisesDataProvider();
    },
    exercisesDataProvider() {
      const filters = [];

      for (const i in this.exercises.filter) {
        let filter = this.exercises.filter[i];

        if (filter) {
          filters.push({
            field: i,
            value: filter,
          });
        }
      }

      this.exercises.sortBy = this.exercises.sortBy ?
                              this.exercises.sortBy :
                              'name';

      const sort = {
        field: this.exercises.sortBy,
        order: this.exercises.sortDesc ? 'DESC' : 'ASC',
      };

      const limit = this.exercises.perPage;
      const page = this.exercises.currentPage;

      this.exercises.isBusy = true;
      return this.$axios
        .post(`/exercise/list/filter?limit=${limit}&page=${page}`, {
          filters: filters,
          sort: sort,
        })
        .then(({ data }) => {
          this.exercises.isBusy = false;
          this.exercises.totalRows = data.pagination.total;
          this.exercises.items = data.data;
          this.exercises_list = data.data;
        })
        .catch(() => {
          this.exercises.isBusy = false;
        });
    },
    getWorkoutTypeIcon(type) {
      const asset = this.workoutTypes.filter((workout) => workout.id === type);

      if (asset.length > 0) {
        return asset[0].faicon;
      }

      return false;
    },
    onFiltered(filteredItems) {
      filteredItems;
    },
    onEditWorkoutEntity() {
      this.editEntityMode = true;
    },
    onFormCancel() {
      if (this.isEdit) {
        this.editEntityMode = false;
        this.workoutForm = _.cloneDeep(this.workoutFormClone);
      }
    },
    async loadGroups() {
      let groupsData = [];

      this.groupsOverlay = true;

      for (const i in this.groups) {
        const id = this.groups[i].id;

        await this.$axios
          .get(`/exercise/group/${id}`)
          .then(({ data }) => {
            groupsData.push(data.data);
          });
      }

      this.groupsOverlay = false;
      this.groupsData = groupsData;
    },
    getAddSetForm(name = '') {
      //return `<input type="text" name="name" value="${name}"/>`;
      return (
        `<input class="swal2-input" placeholder="Pick or enter set name" id="exercise-set-name" type="text" style="display: flex;" value="${name}" />` +
        '<button type="button" onclick="document.getElementById(\'exercise-set-name\').value = \'Single\'" class="btn btn-secondary rounded-pill mr-2 mb-2">Single</button>' +
        '<button type="button" onclick="document.getElementById(\'exercise-set-name\').value = \'Super 2\'" class="btn btn-secondary rounded-pill mr-2 mb-2">Super 2</button>' +
        '<button type="button" onclick="document.getElementById(\'exercise-set-name\').value = \'Super 3\'" class="btn btn-secondary rounded-pill mb-2">Super 3</button>'
      );
    },
    onAddSet() {
      Swal.fire({
        title: 'Add New Exercises Set',
        //input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#556ee6',
        cancelButtonColor: '#f46a6a',
        html: this.getAddSetForm(),
        preConfirm: () => {
          const name = document.getElementById('exercise-set-name').value;
          if (!name.length) {
            Swal.showValidationMessage('Please pick or enter set name');
          }
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          const name = document.getElementById('exercise-set-name').value;
          return this.$axios
            .post('exercise/group', { workout_id: this.id, name: name })
            .then(() => {
              this.loadWorkout();
            });
        }
      });
    },
    loadWorkout() {
      this.id = this.$route.params.id;
      if (this.id) {
        this.workoutOverlay = true;
        return this.$axios
          .get(`/workouts/${this.id}`, {
            params: {
              company_id: this.currentCompany.id
            }
          })
          .then(({ data }) => {
            this.workoutOverlay = false;
            this.workoutForm = {
              name: data.name,
              description: data.description,
              fitness_level_ids: data.fitness_level_ids,
              is_private: data.is_private
            };
            this.workoutFormClone = _.cloneDeep(this.workoutForm);

            this.groups = data.exercise_groups;
            this.loadGroups();
          })
          .catch(() => {
            this.workoutOverlay = false;
          });
      }
    },
    onExerciseAdd({ clone, to }) {
      const exercise_id = clone.getAttribute('data-id');
      const group_id = to.getAttribute('data-id');
      this.$axios
        .post('exercise/group/item', {
          exercise_id: exercise_id,
          group_id: group_id,
        })
        .then(() => {
          this.loadWorkout();
        })
        .catch(() => {
          this.loadWorkout();
        });
    },
    applyExerciseOrder(group_id) {
      const groupData = this.groupsData.filter(
        (group) => group.group.id === group_id
      );

      if (!groupData.length) {
        return;
      }

      const exercise_ids = groupData[0].exercises.map(
        (exercise) => exercise.exercise_groups_items_id
      );
      //
      this.$axios
        .put(`exercise/group/${group_id}/order`, {
          exercise_ids: exercise_ids,
        })
        .then(() => {
          this.loadGroups();
        })
        .catch(() => {
          this.loadGroups();
        });
    },
    applyGroupOrder() {
      const group_ids = this.groupsData.map((group) => group.group.id);

      this.$axios
        .put(`workout/${this.id}/group/order`, {
          group_ids: group_ids,
        })
        .then(() => {
          this.loadWorkout();
        })
        .catch(() => {
          this.loadWorkout();
        });
    },
    /*onRemove() {
      window.console.log("onRemove");
      window.console.log(arguments);
    },
    onUpdate({ from }) {
      window.console.log("onUpdate");
      window.console.log(from);

      const groupId = from.getAttribute("data-id");
      this.applyExerciseOrder(groupId);
    },
    onEnd() {
      window.console.log("onEnd");
      window.console.log(arguments);
      window.console.log(this.exercises_list);
    },
    onChoose() {
      window.console.log("onChoose");
      window.console.log(arguments);
    },
    onUnchoose() {
      window.console.log("onUnchoose");
      window.console.log(arguments);
    },
    onSort() {
      window.console.log("onSort");
      window.console.log(arguments);
    },
    onFilter() {
      window.console.log("onFilter");
      window.console.log(arguments);
    },
    onClone() {
      window.console.log("onClone");
      window.console.log(arguments);
    },
    onDrop() {
      window.console.log("onDrop");
      window.console.log(arguments);
    },*/
    onMove({ to }) {
      // prevent sorting feature in exercises table
      if (!to.getAttribute('data-exercise-drop-target')) return false;
    },
    onExerciseMove({ from, to }) {
      // allow only reorder within same group
      if (from.getAttribute('data-id') !== to.getAttribute('data-id')) {
        return false;
      }
    },
    onExerciseOrder({ from }) {
      const group_id = from.getAttribute('data-id');

      let group = this.groupsData.filter((group) => group.group.id === group_id);
      group = group[0];

      const exercise_ids = group.exercises.map((exercise) => {
        return exercise.exercise_groups_items_id;
      });

      this.$axios
        .put(`exercise/group/${group_id}/order`, {
          exercise_ids: exercise_ids,
        })
        .then(() => {
          this.loadWorkout();
        })
        .catch(() => {
          this.loadWorkout();
        });
      //this.applyExerciseOrder();
    },
    /*applyExerciseOrder() {
      const group_ids = this.groupsData.map((group) => group.group.id);

      this.$axios
        .put(`workout/${this.id}/group/order`, {
          group_ids: group_ids,
        })
        .then(() => {
          this.loadWorkout();
        })
        .catch(() => {
          this.loadWorkout();
        });
    },
    onGroupMove({ to }) {
      window.console.log("onGroupMove");
      window.console.log(to);
    },*/
    onGroupOrder() {
      this.applyGroupOrder();
    },
    onGroupRename(id, name) {
      Swal.fire({
        title: 'Rename Set',
        //input: 'text',
        showCancelButton: true,
        confirmButtonText: 'Submit',
        showLoaderOnConfirm: true,
        confirmButtonColor: '#556ee6',
        cancelButtonColor: '#f46a6a',
        html: this.getAddSetForm(name),
        preConfirm: () => {
          const name = document.getElementById('exercise-set-name').value;
          if (!name.length) {
            Swal.showValidationMessage('Please pick or enter set name');
          }
        },
        allowOutsideClick: false,
      }).then((result) => {
        if (result.value) {
          const name = document.getElementById('exercise-set-name').value;
          return this.$axios
            .put(`exercise/group/${id}`, { name: name })
            .then(() => {
              this.loadWorkout();
            });
        }
      });
    },
    onGroupDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(`exercise/group/${id}`)
            .then(() => {
              Swal.fire('Deleted!', 'Set has been deleted.', 'success');
              this.loadWorkout();
            })
            .catch(() => {
              Swal.fire('Error Happened!', 'Set is not deleted.', 'error');
              this.loadWorkout();
            });
        }
      });
    },
    onExerciseDelete(exercise_id, group_id) {
      group_id;
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(`exercise/group/delete/item/${exercise_id}`)
            .then(() => {
              Swal.fire(
                'Deleted!',
                'Exercise has been deleted from the set.',
                'success'
              );
              this.loadWorkout();
            })
            .catch(() => {
              Swal.fire('Error Happened!', 'Exercise is not deleted.', 'error');
              this.loadWorkout();
            });
        }
      });
    },
    copy() {
      this.workoutForm.name += ' Copy' 
    },
    getPrimaryMuscleNames(item) {
      return item.primary_muscle_names.join(', ');
    },
  },
  mounted() {
    this.loadWorkout();
    this.loadExercises();

    if (this.isCopy) {
      this.copy();
    }
  },
  watch: {
    // watch for URL update to start over
    '$route.path'() {
      if (!this.keepData) {
        Object.assign(this.$data, this.initialData());
      }
      this.keepData = false;
      this.id = this.$route.params.id;
    },
    'exercises.filter.primary_muscle'() {
      this.exercises.currentPage = 1;
      this.loadExercises();
    },
    'exercises.filter.ability_level'() {
      this.exercises.currentPage = 1;
      this.loadExercises();
    },
    'exercises.filter.type'() {
      this.exercises.currentPage = 1;
      this.loadExercises();
    },
    'exercises.currentPage'() {
      this.loadExercises();
    },
    /*groupsData: {
      handler(val) {
        // do stuff
        window.console.log(val);
      },
      deep: true,
    },*/
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-6">
        <b-overlay
          :show="exercises.isBusy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <div class="card exercise-table-card">
            <div class="card-body">
              <div class="row mb-md-2">
                <!-- Filters -->
                <div class="col-sm-12 col-md-4">
                  <b-form-select
                    v-model="exercises.filter.primary_muscle"
                    size="sm"
                    :options="primaryMusclesOptionsWithEmptyValue"
                  ></b-form-select>
                </div>
                <div class="col-sm-12 col-md-4">
                  <b-form-select
                    v-model="exercises.filter.ability_level"
                    size="sm"
                    :options="abilityLevelsOptionsWithEmptyValue"
                  ></b-form-select>
                </div>
                <div class="col-sm-12 col-md-4">
                  <b-form-select
                    v-model="exercises.filter.type"
                    size="sm"
                    :options="workoutTypesOptionsWithEmptyValue"
                  ></b-form-select>
                </div>
              </div>
              <div class="table-responsive mb-0">
                <b-table-simple class="exercise-table" :busy="exercises.isBusy">
                  <b-thead>
                    <div>
                      <b-tr>
                        <b-th>Name</b-th>
                        <b-th>Muscle</b-th>
                        <b-th>Type</b-th>
                        <b-th>Fitness Level</b-th>
                      </b-tr>
                    </div>
                  </b-thead>
                  <b-tbody>
                    <Draggable
                      :move="onMove"
                      :disabled="editEntityMode"
                      :group="{ name: 'exercises', pull: 'clone', put: false }"
                      :list="exercises.items"
                    >
                      <b-tr
                        v-for="exercise in this.exercises.items"
                        :key="exercise.id"
                        :data-id="exercise.id"
                      >
                        <b-td>{{ exercise.name }}</b-td>
                        <b-td>{{ getPrimaryMuscleNames(exercise) }}</b-td>
                        <b-td>
                          <div
                            class=""
                            v-if="getWorkoutTypeIcon(exercise.type)"
                          >
                            <FaIcon
                              :icon="getWorkoutTypeIcon(exercise.type)"
                              :key="exercise.id"
                              size="14"
                            />
                          </div>
                          <span v-if="!getWorkoutTypeIcon(exercise.type)">
                            {{ exercise.type }}
                          </span>
                        </b-td>
                        <b-td>
                          {{ exercise.ability_level_names.join(', ') }}
                        </b-td>
                      </b-tr>
                    </Draggable>
                  </b-tbody>
                  <template #cell(type)="data">
                    <div class="" v-if="getWorkoutTypeIcon(data.item.type)">
                      <FaIcon
                        :icon="getWorkoutTypeIcon(data.item.type)"
                        :key="data.item.id"
                        size="14"
                      />
                    </div>
                    <span v-if="!getWorkoutTypeIcon(data.item.type)">
                      {{ data.item.type }}
                    </span>
                  </template>
                </b-table-simple>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="exercises.currentPage"
                        :total-rows="exercises.totalRows"
                        :per-page="exercises.perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
      <!-- end col -->
      <div class="col-lg-6">
        <b-collapse :visible="!editEntityMode">
          <div class="card">
            <div class="card-body">
              <b-row>
                <b-col cols="8">
                  <h4 class="mb-3 card-title">
                    Workout - {{ workoutForm.name }}
                  </h4>
                </b-col>
                <b-col cols="4">
                  <div class="float-right">
                    <button
                      type="edit"
                      @click="onEditWorkoutEntity"
                      class="btn btn-secondary"
                    >
                      Edit
                    </button>
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-collapse>

        <b-collapse :visible="editEntityMode">
          <div class="card">
            <b-overlay
              :show="workoutOverlay"
              variant="white"
              opacity="0.5"
              blur="2px"
              rounded="sm"
            >
              <div class="card-body">
                <form action="#" @submit.prevent="workoutFormSubmit">
                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Name"
                    label-for="name"
                  >
                    <input
                      v-model="workoutForm.name"
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      :class="{
                        'is-invalid': workoutFormSubmit &&
                                      $v.workoutForm.name.$error
                      }"
                    />
                    <div
                      v-if="workoutFormSubmit && $v.workoutForm.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.workoutForm.name.required">
                        This value is required.
                      </span>
                      <span v-if="!$v.workoutForm.name.minLength">
                        This value is 5 letters minimum length.
                      </span>
                      <span v-if="!$v.workoutForm.name.maxLength">
                        This value is 200 letters maximum length.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Fitness Level(s)"
                    label-for="ability-level"
                  >
                    <b-form-checkbox-group
                      id="ability-level"
                      name="ability-level"
                      :class="{
                        'is-invalid': workoutFormSubmit &&
                                      $v.workoutForm.fitness_level_ids.$error
                      }"
                      class="mt-2"
                    >
                      <b-form-checkbox
                        v-model="workoutForm.fitness_level_ids"
                        v-for="level in abilityLevels"
                        :key="level.id"
                        :value="level.id"
                      >
                        {{ s(level.name).capitalize().value() }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <div
                      v-if="workoutFormSubmit && $v.workoutForm.fitness_level_ids.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.workoutForm.fitness_level_ids.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Description"
                    label-for="description"
                  >
                    <b-form-textarea
                      v-model="workoutForm.description"
                      rows="5"
                      class="form-control"
                      placeholder="Description"
                      :class="{
                        'is-invalid': workoutFormSubmit &&
                                      $v.workoutForm.description.$error
                      }"
                    />
                    <div
                      v-if="
                        workoutFormSubmit && $v.workoutForm.description.$error
                      "
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.workoutForm.description.required">
                        This value is required.
                      </span>
                      <span v-if="!$v.workoutForm.description.maxLength">
                        This value is 200 letters maximum length.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Publish"
                    label-for="is_private"
                  >
                    <FormInputButtons
                      v-model="workoutForm.is_private"
                      tag="b-button-group"
                      :options="[
                        { value: false, text: 'Public' },
                        { value: true, text: 'Private' }
                      ]"
                    />
                  </b-form-group>

                  <div class="mt-3 bottom-button-container">
                    <div class="float-right">
                      <button
                        type="button"
                        @click.stop.prevent="onFormCancel"
                        class="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-primary m-l-5 ml-1">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </b-overlay>
          </div>
        </b-collapse>

        <b-collapse :visible="!editEntityMode">
          <b-overlay
            id="overlay-background"
            :show="groupsOverlay"
            variant="white"
            opacity="0.5"
            blur="2px"
            rounded="sm"
          >
            <div class="groups-table">
              <div class="header">
                <div class="cell cell-group-name group-name"></div>
                <div class="cell cell-muscle">Muscle</div>
                <div class="cell cell-name">Exercise</div>
                <div class="cell cell-time">Time per Set</div>
                <div class="cell cell-delete-exercise"></div>
                <div class="cell cell-delete-set"></div>
              </div>
              <template v-if="groups.length > 0">
                <Draggable
                  group="groups"
                  :list="groupsData"
                  @update="onGroupOrder"
                >
                  <div
                    class="group-wrapper"
                    v-for="(groupData, groupIndex) in groupsData"
                    :key="groupData.group.id"
                  >
                    <div
                      class="extra-row"
                      v-if="groupIndex > 0"
                      :data-group-id="groupData.group.id"
                    >
                      <div class="cell cell-group-name group-name"></div>
                      <div class="cell cell-muscle"></div>
                      <div class="cell cell-name"></div>
                      <div class="cell cell-time"></div>
                      <div class="cell cell-delete-exercise"></div>
                      <div class="cell cell-delete-set"></div>
                    </div>
                    <template v-if="groupData.exercises.length > 0">
                      <div class="group-exercises">
                        <div
                          class="align-middle group-name cell cell-group-name"
                        >
                          {{ groupData.group.name }}
                        </div>
                        <div class="exercise-rows">
                          <Draggable
                            group="exercises"
                            :move="onExerciseMove"
                            :data-id="groupData.group.id"
                            :data-exercise-drop-target="true"
                            :list="groupData.exercises"
                            @add="onExerciseAdd"
                            @update="onExerciseOrder"
                          >
                            <div
                              class="exercise-row"
                              v-for="exercise in groupData.exercises"
                              :key="exercise.exercise_groups_items_id"
                            >
                              <div class="align-middle cell cell-muscle">
                                {{ getPrimaryMuscleNames(exercise) }}
                              </div>
                              <div class="align-middle cell cell-name">
                                {{ exercise.name }}
                              </div>
                              <div class="align-middle cell cell-time">
                                {{ exercise.estimated_set_time }} secs
                              </div>
                              <div
                                class="align-middle cell cell-delete-exercise"
                              >
                                <a
                                  @click="
                                    onExerciseDelete(
                                      exercise.exercise_groups_items_id,
                                      groupData.group.id
                                    )
                                  "
                                  title="Delete exercise from set"
                                >
                                  <FaIcon
                                    icon="far fa-trash-alt"
                                    :key="exercise.exercise_groups_items_id"
                                    size="12"
                                  />
                                </a>
                              </div>
                            </div>
                          </Draggable>
                        </div>
                        <div class="cell cell-delete-set">
                          <a
                            @click="
                              onGroupRename(
                                groupData.group.id,
                                groupData.group.name
                              )
                            "
                            title="Rename group"
                          >
                            <FaIcon
                              icon="fas fa-edit"
                              :key="'group_rename_' + groupData.group.id"
                              size="14"
                            />
                          </a>
                          <a
                            @click="onGroupDelete(groupData.group.id)"
                            title="Delete Set"
                          >
                            <FaIcon
                              icon="far fa-trash-alt"
                              :key="'group_delete_' + groupData.group.id"
                              size="14"
                            />
                          </a>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="group-exercises">
                        <div
                          class="align-middle group-name cell cell-group-name"
                        >
                          {{ groupData.group.name }}
                        </div>
                        <div class="exercise-rows">
                          <div class="exercise-row">
                            <div class="text-center cell cell-add-exercise">
                              <Draggable
                                group="exercises"
                                :data-id="groupData.group.id"
                                :data-exercise-drop-target="true"
                                @add="onExerciseAdd"
                              >
                                + Add exercise
                              </Draggable>
                            </div>
                          </div>
                        </div>
                        <div class="cell cell-delete-set">
                          <a
                            @click="
                              onGroupRename(
                                groupData.group.id,
                                groupData.group.name
                              )
                            "
                            title="Rename group"
                          >
                            <FaIcon
                              icon="fas fa-edit"
                              :key="'group_rename_' + groupData.group.id"
                              size="14"
                          /></a>
                          <a
                            @click="onGroupDelete(groupData.group.id)"
                            title="Delete Set"
                          >
                            <FaIcon
                              icon="far fa-trash-alt"
                              :key="groupData.group.id"
                              size="14"
                          /></a>
                        </div>
                      </div>
                    </template>
                  </div>
                </Draggable>
                <div class="exercise-row">
                  <div
                    class="align-middle group-name cell cell-group-name"
                  ></div>
                  <div class="align-middle cell cell-muscle">Total</div>
                  <div class="align-middle cell cell-name">
                    {{ exercises_total }} exercises
                  </div>
                  <div class="align-middle cell cell-time">
                    {{ exercises_total_time }} secs
                  </div>
                  <div class="align-middle cell cell-delete-exercise"></div>
                  <div class="cell cell-delete-set"></div>
                </div>
              </template>
              <template v-else>
                <div class="group-wrapper">
                  <div class="group-exercises">
                    <div
                      class="align-middle group-name cell cell-group-name"
                    ></div>
                    <div class="exercise-rows">
                      <div class="exercise-row">
                        <div class="align-middle cell cell-no-groups">
                          No sets yet
                        </div>
                      </div>
                    </div>
                    <div class="cell cell-delete-set" />
                  </div>
                </div>
              </template>
            </div>

            <div class="mb-3 mt-3 bottom-button-container">
              <div class="float-right">
                <b-button
                  variant="primary"
                  @click="onAddSet"
                >
                  + Add Set
                </b-button>

                <b-button
                  class="ml-1"
                  to="/workout/list"
                  variant="success"
                >
                  Finish
                </b-button>
              </div>
            </div>
          </b-overlay>
        </b-collapse>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
  .exercise-table tr {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  .exercise-table tbody + tbody,
  .exercise-table tr th {
    border-bottom-width: 1px;
  }
  .exercise-table tr th,
  .exercise-table tr td {
    width: 500px;
  }
  .exercise-table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
  .groups-table tr th,
  .groups-table tr td {
    width: 500px;
  }
  .groups-table th {
    background: #b2dada;
  }
  .groups-table tr {
    background: #edecf2;
  }
  .groups-table tr:nth-child(odd) {
    background: #dad9de;
  }
  .groups-table tr th:nth-child(4),
  .groups-table tr th:first-child,
  .groups-table tr td:nth-child(4),
  .groups-table tr td:first-child {
    width: 100px;
  }
  .groups-table tr th:nth-child(4),
  .groups-table tr th:last-child,
  .groups-table tr td:nth-child(4),
  .groups-table tr td:last-child {
    width: 50px;
  }
  .groups-table td.group-name {
    background: #b2dada;
  }
  .groups-table th.group-name {
    background: #87cdcb;
  }
  .groups-table .remove-set {
    background: #b2dada;
  }
  .groups-table .add-exercise {
    background: #d7dbf4;
  }
  .exercise-table-card {
    background: #d7dbf4;
  }
  .bottom-button-container {
    overflow: hidden;
  }
  .groups-table .exercise-row {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  div.groups-table .header {
    border-bottom: 1px solid #fff;
  }
  div.groups-table .group-exercises,
  div.groups-table .exercise-row,
  div.groups-table .extra-row,
  div.groups-table .header {
    display: flex;
    min-height: 40px;
    align-items: center;
  }
  div.groups-table .header div {
    min-height: 40px;
    background: #b2dada;
    line-height: 40px;
  }
  div.groups-table .extra-row {
    border-bottom: 1px solid #fff;
  }
  div.groups-table .extra-row div {
    min-height: 40px;
    background: #b2dada;
  }
  div.groups-table .group-wrapper {
    border-bottom: 1px solid #fff;
  }
  div.groups-table .group-exercises {
    background: #b2dada;
  }
  div.groups-table .exercise-rows {
    flex-grow: 4;
  }
  div.groups-table .exercise-row {
    background: #edecf2;
    align-items: center;
  }
  div.groups-table .exercise-row:nth-child(odd) {
    background: #dad9de;
  }
  div.groups-table .group-name {
    width: 80px;
  }
  div.groups-table .cell {
    padding-left: 10px;
    overflow: hidden;
  }
  div.groups-table .cell-group-name {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    width: 80px;
    align-self: center;
    min-width: 80px;
  }
  div.groups-table .extra-row .cell-group-name,
  div.groups-table .header .cell-group-name {
    background: #87cdcb;
    min-width: 80px;
  }
  div.groups-table .cell-muscle {
    width: 80px;
  }
  div.groups-table .cell-name {
    width: 80px;
    flex-grow: 4;
  }
  div.groups-table .cell-time {
    width: 100px;
  }
  div.groups-table .cell-delete-exercise {
    width: 30px;
  }
  div.groups-table .cell-delete-set {
    padding-left: 0;
    width: 30px;
    min-width: 50px;
    text-align: center;
  }
  div.groups-table .cell-no-groups,
  div.groups-table .cell-add-exercise {
    flex-grow: 4;
  }
</style>
