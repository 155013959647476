<template>
  <component
    :is="tag"
    :variant="variant"
    v-bind="tagProps"
  >
    {{ title }}
  </component>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'BillingSubscriptionStatus',

  props: {
    item: {
      type: Object,
      default: null
    },

    tag: {
      type: String,
      default: 'b-button'
    },

    tagProps: {
      type: Object,
      default: () => ({
        size: 'sm'
      })
    }
  },

  computed: {
    status() {
      return _.get(this.item, 'status', 'unpaid')
    },

    title() {
      const statuses = {
        incomplete: 'Incomplete',
        incomplete_expired: 'Incomplete expired',
        trialing: 'Trialing',
        active: 'Active',
        past_due: 'Past due',
        canceled: 'Canceled',
        unpaid: 'Unpaid'
      }

      return statuses[this.status]
    },

    variant() {
      const variants = {
        incomplete: 'warning',
        incomplete_expired: 'warning',
        trialing: 'warning',
        active: 'success',
        past_due: 'danger',
        canceled: 'danger',
        unpaid: 'danger'
      }

      return variants[this.status]
    }
  }
}
</script>