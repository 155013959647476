<template>
  <b-overlay
    :show="isBusy"
    :variant="config.overlay.variant"
    :opacity="config.overlay.opacity"
    :blur="config.overlay.blur"
    rounded="sm"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :busy="isBusy"
      :items="fetchItems"
      :fields="fields"
      responsive="sm"
      :per-page="perPage"
      :current-page="currentPage"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :filter-included-fields="filterOn"
      @row-clicked="$emit('input', $event.id)"
    >
      <template #cell(gender)="row">
        <Genders :ids="[row.item.gender]" />
      </template>

      <template #cell(goal_direction)="row">
        <GoalDirections :ids="[row.item.goal_direction_id]" />
      </template>

      <template #cell(actions)="row">
        <WeightLossItemActionsButton
          :item="row.item"
          @update:item="refresh"
          @delete:item="refresh"
        >
          <b-icon-three-dots />
        </WeightLossItemActionsButton>
      </template>
    </b-table>

    <div
      v-if="showPagination"
      class="pagination pagination-rounded"
    >
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      />
    </div>
  </b-overlay>
</template>
<script>
import appConfig from '@/app.config'
import WeightLossItemActionsButton from './buttons/WeightLossItemActionsButton'
import Genders from '@/components/v1/asset/Genders'
import GoalDirections from '@/components/v1/asset/GoalDirections'

export default {
  name: 'WeightLossList',

  components: {
    WeightLossItemActionsButton,
    Genders,
    GoalDirections
  },

  data() {
    return {
      isBusy: false,
      config: appConfig,
      totalRows: 0,
      currentPage: 1,
      perPage: 400,
      sortBy: 'name',
      sortDesc: false,
      filterOn: [],
      fields: [
        { key: 'phase_number', sortable: false },
        { key: 'weight', sortable: false },
        { key: 'weight_loss_ratio', sortable: false },
        { key: 'increment', sortable: false },
        { key: 'gender', sortable: false },
        { key: 'goal_direction', sortable: false },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  },

  computed: {
    showPagination() {
      return this.totalRows > this.currentPage + this.perPage
    }
  },

  methods: {
    async fetchItems() {
      const limit = this.perPage
      const page = this.currentPage

      return this.$axios
        .get(`admin/weight-loss`, {
          parent: this,
          params: { limit, page }
        })
        .then(({ data }) => {
          this.totalRows = data.total
          return data.data
        })
    },

    refresh() {
      return this.$refs.table.refresh()
    }
  }
}
</script>

<style scoped>
  .table-select >>> table tbody tr {
    cursor: pointer;
  }
  .table-select.is-suggested >>> table tbody tr {
    background: #d7dbf4;
  }
  .table-select.is-suggested >>> table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
</style>