var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Water",
      "label-class": "font-size-20 text-primary font-weight-bold"
    }
  }, [_c('CurriculumPhasesWaterInput', {
    staticClass: "mb-2",
    attrs: {
      "mode": "values",
      "disabled": "",
      "curriculum": _vm.newItem
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Supplements",
      "label-class": "font-size-20 text-primary font-weight-bold"
    }
  }, _vm._l(_vm.newItem.phases, function (phase, phaseIndex) {
    return _c('CurriculumPhaseSupplementList', {
      key: phase.id,
      attrs: {
        "show-header": phaseIndex === 0,
        "phase": phase,
        "params": {
          filters: {
            phase_id: phase.id
          },
          limit: 100
        }
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }