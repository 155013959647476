<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(date)="row">
        {{ row.item.date ? $moment(row.item.date).format('YYYY-MM-DD') : '-' }}
      </template>

      <template #cell(calories)="row">
        <TrackerNutritionMacro
          :item="row.item"
          macro="calories"
        />
      </template>

      <template #cell(actions)="row">
        <TrackerNutritionItemUpdateButton
          :item="{ id: row.item.id }"
          tag="b-button"
          :tag-props="{
            variant: 'link',
            size: 'sm'
          }"
          @submitted="refresh"
          @update:item="row.item = $event"
          @close="refresh"
        >
          <b-icon-pencil />
        </TrackerNutritionItemUpdateButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import TrackerNutritionItemUpdateButton from './buttons/TrackerNutritionItemUpdateButton'
import TrackerNutritionMacro from './TrackerNutritionMacro'

export default {
  name: 'TrackerNutritionList',

  mixins: [
    crudListMixin
  ],

  components: {
    TrackerNutritionItemUpdateButton,
    TrackerNutritionMacro
  },

  data() {
    return {
      baseEndpoint: 'tracker-nutritions',

      fields: [
        { key: 'date', sortable: false, label: 'Date' },
        { key: 'calories', sortable: false, label: 'Total Calories' },
        { key: 'water', sortable: false, label: 'Total Water' },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  }
}
</script>