var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    "class": _vm.textClasses,
    attrs: {
      "tabindex": "0",
      "href": "#"
    },
    on: {
      "mouseover": function mouseover($event) {
        _vm.active = true;
      },
      "mouseout": function mouseout($event) {
        _vm.active = false;
      }
    }
  }, [_vm._t("suggestion", function () {
    return [_c('span', {
      domProps: {
        "innerHTML": _vm._s(_vm.htmlText)
      }
    })];
  }, null, {
    data: _vm.data,
    htmlText: _vm.htmlText
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }