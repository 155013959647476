var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching,
      "rounded": "sm"
    }
  }, [!_vm.isFetched ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.isFetching ? 'Loading...' : 'You can not view this item.') + " ")]) : _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Owners",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.owner_ids),
      "state": _vm.getFieldState(_vm.$v.input.owner_ids)
    }
  }, [_vm.ownersIsEditable ? _c('UserSelect', {
    attrs: {
      "list-endpoint": "admin/users",
      "items": _vm.newItem.owners,
      "show-create": _vm.isCreated,
      "item-to-create": _vm.isCreated ? {
        company_id: _vm.newItem.id,
        role_ids: [3]
      } : null,
      "company-to-create": _vm.isCreated ? _vm.newItem : null,
      "multiple": "",
      "placeholder": "Select an owner..."
    },
    model: {
      value: _vm.input.owner_ids,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "owner_ids", $$v);
      },
      expression: "input.owner_ids"
    }
  }) : _c('UserSelect', {
    attrs: {
      "list-endpoint": "admin/users",
      "value": _vm.newItem.owners ? _vm.newItem.owners.map(function (i) {
        return i.id;
      }) : null,
      "items": _vm.newItem.owners,
      "disabled": "",
      "multiple": ""
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right my-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting || !_vm.inputIsDirty
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }