<template>
  <b-overlay
    :show="isFetching"
  >
    <!-- START DATE -->
    <b-form-group
      class="mb-4"
      label="Start date"
      label-class="font-size-20 text-primary font-weight-bold"
    >
      <b-form-datepicker
        v-if="newItem.start_date"
        :value="newItem.start_date"
        disabled
      />

      <b-alert
        v-else
        show
      >
        Start date has not been selected yet.
      </b-alert>
    </b-form-group>

    <!-- CARDIO -->
    <b-form-group
      class="mb-4"
      label="Cardio"
      label-class="font-size-20 text-primary font-weight-bold"
    >
      <CurriculumPhasesCardioInput
        class="mb-2"
        mode="values"
        disabled
        :curriculum="newItem"
      />
    </b-form-group>

    <!-- NUTRIENTS -->
    <b-form-group
      class="mb-4"
      label="Nutrients"
      label-class="font-size-20 text-primary font-weight-bold"
    >
      <CurriculumPhasesWaterInput
        class="mb-0"
        mode="values"
        disabled
        :curriculum="item"
      />

      <CurriculumPhasesCaloriesInput
        class="mb-0"
        mode="values"
        disabled
        :show-header="false"
        :curriculum="item"
      />
    </b-form-group>

    <!-- WORKOUT PROGRAM -->
    <b-form-group
      class="mb-4"
      label="Workout program"
      label-class="font-size-20 text-primary font-weight-bold"
    >
      <b-table-simple
        v-if="newItem.workout_program_id"
        class="bg-white"
        fixed
      >
        <b-thead>
          <b-tr>
            <b-th>Program Name</b-th>
            <b-th>Gender</b-th>
            <b-th>Fitness Level</b-th>
            <b-th>Goal Direction</b-th>
            <b-th>Workouts per Week</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr variant="primary">
            <b-td>
              <WorkoutProgramItemButton
                :item="newItem.workout_program"
                class="text-decoration-underline-dotted"
                :style="{
                  cursor: 'pointer'
                }"
              >
                {{ newItem.workout_program.name }}
              </WorkoutProgramItemButton>
            </b-td>

            <b-td>
              <Genders :ids="[newItem.workout_program.gender]" />
            </b-td>

            <b-td>
              <Abilities :ids="newItem.workout_program.fitness_level_ids" />
            </b-td>

            <b-td>
              <GoalDirections :ids="[newItem.workout_program.goal_direction_id]" />
            </b-td>

            <b-td>{{ newItem.workout_program.workouts_per_week }}</b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-alert
        v-else
        show
        class="m-0"
      >
        Workout program has not been selected yet.
      </b-alert>
    </b-form-group>

    <!-- SUPPLEMENT PROGRAM -->
    <b-form-group
      class="mb-4"
      label="Supplement program"
      label-class="font-size-20 text-primary font-weight-bold"
    >
      <b-table-simple
        v-if="newItem.supplement_program_id"
        class="bg-white"
        fixed
      >
        <b-thead>
          <b-tr>
            <b-th>Program Name</b-th>
            <b-th>Gender</b-th>
            <b-th>Fitness Level</b-th>
            <b-th>Goal Direction</b-th>
          </b-tr>
        </b-thead>

        <b-tbody>
          <b-tr
            variant="primary"
          >
            <b-td>{{ newItem.supplement_program.name }}</b-td>

            <b-td>
              <Genders :ids="[newItem.supplement_program.gender]" />
            </b-td>

            <b-td>
              <Abilities :ids="newItem.supplement_program.fitness_level_ids" />
            </b-td>

            <b-td>
              <GoalDirections :ids="[newItem.workout_program.goal_direction_id]" />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>

      <b-alert
        v-else
        show
        class="m-0"
      >
        Supplement program has not been selected yet.
      </b-alert>
    </b-form-group>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import CurriculumPhasesCardioInput from '@/components/curriculums/inputs/CurriculumPhasesCardioInput'
import CurriculumPhasesWaterInput from '@/components/curriculums/inputs/CurriculumPhasesWaterInput'
import CurriculumPhasesCaloriesInput from '@/components/curriculums/inputs/CurriculumPhasesCaloriesInput'
import Genders from '@/components/v1/asset/Genders'
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import WorkoutProgramItemButton from '@/components/workout-programs/buttons/WorkoutProgramItemButton'

export default {
  name: 'CurriculumItemPreviewDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumPhasesCardioInput,
    CurriculumPhasesWaterInput,
    CurriculumPhasesCaloriesInput,
    WorkoutProgramItemButton,
    Genders,
    Abilities,
    GoalDirections
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  }
}
</script>