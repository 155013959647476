<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-row>
      <b-col
        lg="3"
        class="mb-2"
      >
        <b-input
          :value="newParams.filters.search"
          size="sm"
          placeholder="Search workout..."
          @input="debounceSetFilterSearchParam"
        />
      </b-col>

      <b-col
        lg="3"
        class="mb-2"
      >
        <b-form-select
          v-model="filterFitnessLevelId"
          size="sm"
          :options="$store.getters['asset/getAbilityLevelsOptions']"
        >
          <template #first>
            <b-form-select-option :value="null">
              Fitness Level
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        lg="2"
        class="mb-2"
      >
        <b-form-select
          v-model="filterMuscleId"
          size="sm"
          :options="$store.getters['asset/getPrimaryMusclesOptions']"
        >
          <template #first>
            <b-form-select-option :value="null">
              Primary Muscle
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        lg="3"
        class="mb-2"
      >
        <b-form-checkbox
          v-if="$acl.check('couldFilterWorkoutsByPrivate')"
          class="py-1 d-inline-flex mr-4"
          v-model="filterIsPrivate"
          size="sm"
          switch
        >
          Private
        </b-form-checkbox>

        <b-form-checkbox
          class="py-1 d-inline-flex"
          v-model="filterIsFavorite"
          size="sm"
          switch
        >
          Favorite
        </b-form-checkbox>
      </b-col>  
    </b-row>

    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <!-- IS FAVORITE -->
      <template #cell(is_favorite)="row">
        <WorkoutItemFavoriteButton
          :item="row.item"
          tag="b-icon"
          :tag-props="{
            variant: 'warning',
            icon: row.item.is_favorite ? 'star-fill' : 'star'
          }"
          :style="{
            cursor: 'pointer'
          }"
          @submitted="refresh"
        />
      </template>

      <!-- NAME -->
      <template #cell(name)="row">
        <WorkoutItemDetailButton
          class="text-decoration-underline-dotted"
          :item="row.item"
          prefetch
          :style="{
            cursor: 'pointer'
          }"
        >
          {{ row.item.name }}
        </WorkoutItemDetailButton>
      </template>

      <!-- MUSCLES -->
      <template #cell(muscle_ids)="row">
        <Muscles :ids="row.item.muscle_ids || []" />
      </template>

      <!-- FITNESS LEVELS -->
      <template #cell(fitness_level_ids)="row">
        <Abilities :ids="row.item.fitness_level_ids || []" />
      </template>

      <!-- ACTIONS -->
      <template #cell(actions)="row">
        <WorkoutItemActionsButton
          :item="row.item"
          @submitted="refresh"
          @copied="$router.push(`/workout/${$event.id}`)"
          @deleted:item="refresh"
        >
          <b-icon-three-dots />
        </WorkoutItemActionsButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import Muscles from '@/components/v1/asset/Muscles'
import Abilities from '@/components/v1/asset/Abilities'
import WorkoutItemActionsButton from './buttons/WorkoutItemActionsButton'
import WorkoutItemFavoriteButton from './buttons/WorkoutItemFavoriteButton'
import WorkoutItemDetailButton from './buttons/WorkoutItemDetailButton'

export default {
  name: 'WorkoutList',

  mixins: [
    crudListMixin
  ],

  components: {
    Muscles,
    Abilities,
    WorkoutItemActionsButton,
    WorkoutItemFavoriteButton,
    WorkoutItemDetailButton
  },

  data() {
    return {
      baseEndpoint: 'workouts',

      fields: [
        { key: 'is_favorite', sortable: false, label: '', thClass: 'w-30px' },
        { key: 'name', sortable: false },
        { key: 'muscle_ids', sortable: false, label: 'Muscles' },
        { key: 'fitness_level_ids', sortable: false, label: 'Fitness Levels' },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'w-100px text-right' }
      ]
    }
  },

  computed: {
    filterMuscleId: {
      get() {
        return _.get(this.newParams.filters, 'muscle_ids.0', null)
      },

      set(value) {
        this.newParams.filters.muscle_ids = value ? [value] : []
      }
    },

    filterFitnessLevelId: {
      get() {
        return _.get(this.newParams.filters, 'fitness_level_ids.0', null)
      },

      set(value) {
        this.newParams.filters.fitness_level_ids = value ? [value] : []
      }
    },

    filterIsPrivate: {
      get() {
        return _.get(this.newParams.filters, 'is_private', false)
      },

      set(value) {
        this.newParams.filters.is_private = value || null
      }
    },

    filterIsFavorite: {
      get() {
        return _.get(this.newParams.filters, 'is_favorite', false)
      },

      set(value) {
        this.newParams.filters.is_favorite = value || null
      }
    }
  },

  created() {
    this.debounceSetFilterSearchParam = _.debounce(async value => {
      this.newParams = {
        ...this.newParams,
        // Update filters
        filters: {
          ...this.newParams.filters,
          search: value
        },
        // Reset page to 1
        page: 1
      }
    }, 1000)
  }
}
</script>