var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._b({
    tag: "component",
    on: {
      "click": function click($event) {
        return _vm.showModal();
      }
    }
  }, 'component', _vm.tagProps, false), [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._t("default")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }