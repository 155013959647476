<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="showModal()"
  >
    <slot />
  </component>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ExerciseGroupItemItemDeleteButton',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    couldDelete() {
      return true
    }
  },

  methods: {
    deleteItem() {
      this.isSubmitting = true

      this.$axios
        .delete(`exercise-group-items/${this.item.id}`)
        .then(() => {
          this.isSubmitting = false
          this.$emit('deleted:item', this.item)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    showModal() {
      if (this.couldDelete) {
        this.showConfirmModal()

      } else {
        this.showNotOwnModal()
      }
    },

    showConfirmModal() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
        title: 'Please Confirm',
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true
      })
        .then(async (value) => {
          if (value) {
            this.deleteItem()
          }
        })
    },

    showNotOwnModal() {
      this.$bvModal.msgBoxOk(`You can't delete a item you do not own.`, {
        title: 'Warning',
        okTitle: 'Close',
        okVariant: 'warning',
        centered: true
      })
    }
  }
}
</script>