<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-row>
      <b-col
        lg="3"
        class="mb-2"
      >
        <b-input
          :value="newParams.filters.search"
          size="sm"
          placeholder="Search exercise..."
          @input="debounceSetFilterSearchParam"
        />
      </b-col>

      <b-col
        lg="3"
        class="mb-2"
      >
        <b-form-select
          v-model="filterFitnessLevelId"
          size="sm"
          :options="$store.getters['asset/getAbilityLevelsOptions']"
        >
          <template #first>
            <b-form-select-option :value="null">
              Fitness Level
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        lg="2"
        class="mb-2"
      >
        <b-form-select
          v-model="filterMuscleId"
          size="sm"
          :options="$store.getters['asset/getPrimaryMusclesOptions']"
        >
          <template #first>
            <b-form-select-option :value="null">
              Primary Muscle
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        lg="2"
        class="mb-2"
      >
        <b-form-select
          v-model="filterWorkoutType"
          size="sm"
          :options="$store.getters['asset/getWorkoutTypesOptions']"
        >
          <template #first>
            <b-form-select-option :value="null">
              Workout Type
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        lg="1"
        class="mb-2"
      >
        <b-form-checkbox
          class="py-1 d-inline-flex"
          v-model="filterIsFavorite"
          size="sm"
          switch
        >
          Favorite
        </b-form-checkbox>
      </b-col>  
    </b-row>

    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-th>Name</b-th>
          <b-th>Muscle</b-th>
          <b-th>Type</b-th>
          <b-th>Fitness Level</b-th>
        </b-tr>
      </b-thead>

      <Draggable
        tag="b-tbody"
        :disabled="disabled"
        :group="{
          name: draggableGroupName,
          pull: 'clone',
          put: false
        }"
        :clone="clone"
        :sort="false"
        :list="newResult.data"
      >
        <b-tr
          v-for="item in newResult.data"
          :key="item.id"
          :data-id="item.id"
          :style="{
            cursor: 'pointer'
          }"
        >
          <b-td>
            {{ item.name }}
          </b-td>

          <b-td>
            <Muscles :ids="item.muscle_ids || []" />
          </b-td>

          <b-td>
            <WorkoutTypes :ids="item.type_ids" />
          </b-td>

          <b-td>
            <Abilities :ids="item.fitness_level_ids || []" />
          </b-td>
        </b-tr>
      </Draggable>
    </b-table-simple>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import Muscles from '@/components/v1/asset/Muscles'
import Abilities from '@/components/v1/asset/Abilities'
import WorkoutTypes from '@/components/v1/asset/WorkoutTypes'
import Draggable from 'vuedraggable';

export default {
  name: 'ExerciseListSelect',

  mixins: [
    crudListMixin
  ],

  components: {
    Muscles,
    Abilities,
    WorkoutTypes,
    Draggable
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },

    draggableGroupName: {
      type: String,
      default: null
    },

    clone: {
      type: Function,
      defualt: null
    }
  },

  data() {
    return {
      baseEndpoint: 'exercises'
    }
  },

  computed: {
    filterMuscleId: {
      get() {
        return _.get(this.newParams.filters, 'muscle_ids.0', null)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            muscle_ids: value ? [value] : []
          },
          // Reset page to 1
          page: 1
        }
      }
    },

    filterFitnessLevelId: {
      get() {
        return _.get(this.newParams.filters, 'fitness_level_ids.0', null)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            fitness_level_ids: value ? [value] : []
          },
          // Reset page to 1
          page: 1
        }
      }
    },

    filterWorkoutType: {
      get() {
        return _.get(this.newParams.filters, 'type', null)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
          ...this.newParams.filters,
          type: value
          },
          // Reset page to 1
          page: 1
        }
      }
    },

    filterIsPrivate: {
      get() {
        return _.get(this.newParams.filters, 'is_private', false)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            is_private: value || null
          },
          // Reset page to 1
          page: 1
        }
      }
    },

    filterIsFavorite: {
      get() {
        return _.get(this.newParams.filters, 'is_favorite', false)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            is_favorite: value || null
          },
          // Reset page to 1
          page: 1
        }
      }
    }
  },

  created() {
    this.debounceSetFilterSearchParam = _.debounce(async value => {
      this.newParams = {
        ...this.newParams,
        // Update filters
        filters: {
          ...this.newParams.filters,
          search: value
        },
        // Reset page to 1
        page: 1
      }
    }, 1000)
  }
}
</script>