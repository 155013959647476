<template>
  <b-overlay
    :show="isFetching"
  >
    <div v-if="!item.start_date">
      <b-alert
        show
        class="text-center"
        variant="warning"
      >
        You should set curriculum's start date first on <router-link
          :to="{
            name: 'curriculums-build',
            hash: '#preview'
          }"
        >
          Preview
        </router-link>.
      </b-alert>
    </div>

    <div
      v-else
      class="text-right mt-4"
    >
      <div class="d-inline-flex">
        <CurriculumItemActivateButton
          v-if="!newItem.designed_at"
          :item="item"
          tag="b-button"
          :tag-props="{
            variant: 'primary'
          }"
          @submitted="$router.push('/client/list')"
        >
          Activate
        </CurriculumItemActivateButton>

        <CurriculumPhaseNumber
          v-else-if="newItem.status === 'active'"
          v-slot:default="{ activePhase }"
          tag="b-button"
          class="ml-3"
          :tag-props="{
            variant: 'link'
          }"
          :item="item"
          disabled
        >
          Phase {{ activePhase.number }} Active
        </CurriculumPhaseNumber>

        <CurriculumStatus
          v-else
          :item="newItem"
          tag="b-button"
          class="ml-3"
          :tag-props="{
            variant: 'success'
          }"
          disabled
        />
      </div>

      <div
        v-if="newItem.designed_at && unreleasedPhase"
        class="d-inline-flex ml-1"
      >
        <CurriculumPhaseItemReleaseButton
          :item="unreleasedPhase"
          tag="b-button"
          class="ml-2"
          :tag-props="{
            variant: 'primary'
          }"
          :disabled="!unreleasedPhase.start_date || !releaseEnabled"
          @submitted="refresh"
        >
          Release Phase {{ unreleasedPhase.number }}
        </CurriculumPhaseItemReleaseButton>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import CurriculumPhaseNumber from '@/components/curriculums/CurriculumPhaseNumber'
import CurriculumItemActivateButton from '@/components/curriculums/buttons/CurriculumItemActivateButton'
import CurriculumPhaseItemReleaseButton from '@/components/curriculums/phases/buttons/CurriculumPhaseItemReleaseButton'
import CurriculumStatus from '@/components/curriculums/CurriculumStatus'

export default {
  name: 'CurriculumItemReview',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumItemActivateButton,
    CurriculumPhaseNumber,
    CurriculumPhaseItemReleaseButton,
    CurriculumStatus
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  computed: {
    unreleasedPhase() {
      return _.find(this.item.phases, i => !i.released_at)
    },

    releaseEnabled() {
      return this.$moment(this.unreleasedPhase.start_date) <= this.$moment().add(7, 'd')
    }
  }
}
</script>