<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import FaIcon from "@/components/widgets/fa-icon";
import { assetComputed, authComputed } from "@/state/helpers";
import _ from "lodash";
import Swal from "sweetalert2";

/**
 * Advanced table component
 */
export default {
  name: 'SupplementListPage',
  page: {
    title: "Supplements",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, FaIcon },
  data() {
    return {
      config: appConfig,
      title: "Supplements",
      totalRows: 0,
      currentPage: 1,
      perPage: appConfig.table.perPage,
      pageOptions: appConfig.table.pageOptions,
      isBusy: false,
      sortBy: "name",
      sortDesc: false,
      afilter: "",
      fields: [
        { key: "image_url", label: "", tdClass: "text-center align-middle" },
        { key: "name", sortable: true, tdClass: "align-middle" },
        {
          key: "type_name",
          sortable: true,
          label: "Type",
          tdClass: "align-middle",
        },
        { key: "form", sortable: true, tdClass: "align-middle" },
        { key: "dose", tdClass: "align-middle" },
        {
          key: "action_edit",
          sortable: false,
          tdClass: "text-center align-middle",
          label: "",
        },
        {
          key: "action_delete",
          sortable: false,
          tdClass: "text-center align-middle",
          label: "",
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...assetComputed,
    ...authComputed,
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    dataProvider() {
      this.sortBy = this.sortBy ? this.sortBy : "name";

      const sort = {
        field: this.sortBy,
        order: this.sortDesc ? "DESC" : "asc",
      };

      const limit = this.perPage;
      const page = this.currentPage;

      return this.$axios
        .get(`/supplement/?limit=${limit}&page=${page}`, {
          params: {
            //filters: filters,
            sort: sort,
          },
          parent: this,
        })
        .then((response) => {
          this.totalRows = response.data.pagination.total;

          return response.data.data;
        })
        .catch((response) => {
          window.console.log(response);
        });
    },
    onEdit(id) {
      this.$router.push(`/supplement/${id}`);
    },
    async onDelete(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.value) {
          await this.$axios
            .delete(`supplement/${id}`)
            .then(() => {
              Swal.fire("Deleted!", "Supplement has been deleted.", "success");
              this.triggerReload();
            })
            .catch(() => {
              Swal.fire(
                "Error Happened!",
                "Supplement is not deleted.",
                "error"
              );
              this.triggerReload();
            });
        }
      });
    },
    couldEdit(item) {
      return (
        this.$acl.check('couldEditPublicSupplement') && !item.is_private ||
        this.$acl.check('couldEditOwnSupplement') && this.currentUser.id === item.created_by
      )
    },
    couldDelete(item) {
      return (
        this.$acl.check('couldDeletePublicSupplement') && !item.is_private ||
        this.$acl.check('couldDeleteOwnSupplement') && this.currentUser.id === item.created_by
      )
    },
    supplementDoseTypeName(id) {
      const doseType = _.find(this.supplementDoseTypes, { id: id });
      if (doseType) return doseType.name;
      return id;
    },
    supplementFormTypeName(id) {
      const formType = _.find(this.supplementFormTypes, { id: id });
      if (formType) return formType.name;
      return id;
    },
    triggerReload() {
      this.afilter = {
        value: !this.afilter.value,
      };
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="isBusy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="dataProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="afilter"
                  class="supplement-table"
                >
                  <template #cell(image_url)="data">
                    <b-img
                      v-if="data.item.image_url"
                      rounded="circle"
                      :alt="data.item.name"
                      :src="data.item.image_url"
                    ></b-img>
                    <span v-else>
                      no image
                    </span>
                  </template>
                  <template #cell(form)="data">
                    {{ supplementFormTypeName(data.item.form) }}
                  </template>
                  <template #cell(dose)="data">
                    {{
                      data.item.dose + " " + supplementDoseTypeName(data.item.dose_type)
                    }}
                  </template>
                  <template #cell(action_edit)="data">
                    <b-button
                      variant="primary"
                      v-if="couldEdit(data.item)"
                      @click="onEdit(data.item.id)"
                      >Edit</b-button
                    >
                  </template>
                  <template #cell(action_delete)="data">
                    <div>
                      <a
                        v-if="couldDelete(data.item)"
                        @click="onDelete(data.item.id)"
                      >
                        <FaIcon
                          icon="far fa-trash-alt"
                          :key="data.item.id"
                          size="17"
                      /></a>
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.vue-switcher {
  top: 8px !important;
}
.supplement-table img {
  width: 40px;
  height: 40px;
}
</style>
