<script>
import { layoutComputed } from "@/state/helpers";

import Vertical from "@/router/layouts/vertical";
import Horizontal from "@/router/layouts/horizontal";

export default {
  name: 'LayoutMain',
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1, user-scalable=no' }
  ],
  components: { Vertical, Horizontal },
  computed: {
    ...layoutComputed
  },
  mounted() {
    window.addEventListener('beforeunload', this.onBeforeUnload)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.onBeforeUnload)
  },
  methods: {
    onBeforeUnload(e) {
      if (this.$store.getters['layout/dirtyComponents'].length) {
        if (confirm('Do you really want to leave? You have unsaved changes!')) {
          this.$store.dispatch('layout/clearDirtyComponents')

        } else {
          e.preventDefault()
          e.returnValue = '' // Chrome requires returnValue to be set
        }
      }
    },
    confirmLeave() {
      return window.confirm('Do you really want to leave? You have unsaved changes!')
    }
  }
};
</script>

<template>
  <div>
    <vertical v-if="layoutType === 'vertical'" :layout="layoutType">
      <slot />
    </vertical>

    <Horizontal v-if="layoutType === 'horizontal'" :layout="layoutType">
      <slot />
    </Horizontal>
  </div>
</template>