<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="isFetched">
      <b-card
        bg-variant="dark"
        text-variant="white"
        class="shadow-none rounded-0 pt-4 m-0"
        body-class="pt-5 mt-5"
      >
        <div class="font-size-24 font-weight-bold">
          {{ newItem.name }}
        </div>

        {{ newItem.description }}
      </b-card>

      <b-card
        class="py-3 m-0"
        bg-variant="light"
      >
        <b-row>
          <b-col>
            <span class="font-weight-bold">
              Fitness Level(s)
            </span>:
            
            <Abilities :ids="newItem.fitness_level_ids || []"/>
          </b-col>

          <b-col>
            <span class="font-weight-bold">
              Gender:
            </span>
            
            <Genders :ids="newItem.gender ? [newItem.gender] : []" />
          </b-col>

          <b-col>
            <span class="font-weight-bold">
              Goal Direction:
            </span>
            
            <GoalDirections :ids="newItem.goal_direction_id ? [newItem.goal_direction_id] : []"/>
          </b-col>
        </b-row>
      </b-card>

      <b-card class="m-0">
        <SupplementProgramPhasesInput
          :value="newItem.phases"
        />
      </b-card>
    </template>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import Genders from '@/components/v1/asset/Genders'
import SupplementProgramPhasesInput from './inputs/SupplementProgramPhasesInput'

export default {
  name: 'SupplementProgramItemDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    Abilities,
    GoalDirections,
    Genders,
    SupplementProgramPhasesInput
  },

  data() {
    return {
      baseEndpoint: 'supplement-programs'
    }
  }
}
</script>

<style scoped lang="scss">
  .input-table {
    th {
      background: #BAD9D9;
      vertical-align: middle;
      border-bottom: none;
    }
    td {
      vertical-align: middle;
      border-bottom: none;
    }

    thead {
      th {
        background: #c0c5ed;

        &:first-child {
          background: #97CBCA;
          vertical-align: middle;
        }
      }
    }

    tbody {
      background: #FFFFFF;
    }
  }
</style>