var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', _vm._b({
    attrs: {
      "right": "",
      "text": "Actions"
    },
    scopedSlots: _vm._u([_vm.$slots["default"] ? {
      key: "button-content",
      fn: function fn() {
        return [_vm._t("default")];
      },
      proxy: true
    } : null], null, true)
  }, 'b-dropdown', _vm.dropdownProps, false), [_c('b-dropdown-item', {
    attrs: {
      "to": "/users/".concat(_vm.item.id, "/profile")
    }
  }, [_vm._v(" Profile ")]), _vm.isTrainer ? _c('b-dropdown-item', {
    attrs: {
      "to": "/client/list?trainers=".concat(_vm.item.id)
    }
  }, [_vm._v(" Clients ")]) : _vm._e(), _vm.showResetPasswordButton ? _c('UserItemPasswordUpdateButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('submitted');
      }
    }
  }, [_vm._v(" Reset Password ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }