<template>
  <b-overlay :show="isFetching">
    <b-form @submit.prevent="submit('submitted')">
      <!-- PHASE NUMBER -->
      <b-form-group
        label="Phase number"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.phase_number)"
        :state="getFieldState($v.input.phase_number)"
      >
        <b-form-select
          v-model="input.phase_number"
          :options="[1, 2, 3, 4]"
          :disabled="isCreated"
          :state="getFieldState($v.input.phase_number)"
        />
      </b-form-group>

      <!-- GOAL DIRECTION -->
      <b-form-group
        label="Goal direction"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.goal_direction_id)"
        :state="getFieldState($v.input.goal_direction_id)"
      >
        <b-form-select
          v-model="input.goal_direction_id"
          :options="$store.getters['asset/getGoalDirectionsOptions']"
          :disabled="isCreated"
          :state="getFieldState($v.input.goal_direction_id)"
        />
      </b-form-group>

      <!-- GOAL DIRECTION -->
      <b-form-group
        label="Fitness level"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.fitness_level_id)"
        :state="getFieldState($v.input.fitness_level_id)"
      >
        <b-form-select
          v-model="input.fitness_level_id"
          :options="$store.getters['asset/getAbilityLevelsOptions']"
          :disabled="isCreated"
          :state="getFieldState($v.input.fitness_level_id)"
        />
      </b-form-group>

      <!-- GENDER -->
      <b-form-group
        label="Gender"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.gender)"
        :state="getFieldState($v.input.gender)"
      >
        <b-form-select
          v-model="input.gender"
          :options="$store.getters['asset/getSexOptions']"
          :disabled="isCreated"
          :state="getFieldState($v.input.gender)"
        />
      </b-form-group>

      <!-- NUMBER -->
      <b-form-group
        label="Number"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.number)"
        :state="getFieldState($v.input.number)"
      >
        <b-form-input
          v-model="input.number"
          type="number"
          number
          :state="getFieldState($v.input.number)"
        />
      </b-form-group>

      <!-- NUMBER -->
      <b-form-group
        label="Morning number"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.morning_number)"
        :state="getFieldState($v.input.morning_number)"
      >
        <b-form-input
          v-model="input.morning_number"
          type="number"
          number
          :state="getFieldState($v.input.morning_number)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'WorkoutsPerWeekItemUpsert',

  mixins: [
    crudItemMixin
  ],

  computed: {
    baseEndpoint() {
      return 'admin/workouts-per-week'
    }
  },

  validations() {
    return {
      input: {
        phase_number: { required },
        goal_direction_id: { required },
        gender: { required },
        fitness_level_id: { required },
        number: { required },
        morning_number: { required },
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        phase_number: _.get(this.newItem, 'phase_number', null),
        goal_direction_id: _.get(this.newItem, 'goal_direction_id', null),
        gender: _.get(this.newItem, 'gender', null),
        fitness_level_id: _.get(this.newItem, 'fitness_level_id', null),
        number: _.get(this.newItem, 'number', null),
        morning_number: _.get(this.newItem, 'morning_number', null)
      }

      return input
    }
  }
}
</script>
