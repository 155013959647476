var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0"
    },
    scopedSlots: _vm._u([_vm.newResult.data.length ? {
      key: "tabs-end",
      fn: function fn() {
        return [_c('TrackerMealItemUpsertButton', {
          staticClass: "px-0",
          attrs: {
            "tag": "b-nav-item",
            "tag-props": {
              role: 'link',
              linkClasses: 'border-0'
            },
            "role": "presentation",
            "item": {
              nutrition_id: _vm.newParams.filters.nutrition_id
            }
          },
          on: {
            "submitted": function submitted($event) {
              return _vm.$emit('submitted', $event);
            }
          }
        }, [_vm._v(" + Add ")])];
      },
      proxy: true
    } : {
      key: "empty",
      fn: function fn() {
        return [_c('b-alert', {
          staticClass: "text-center",
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_c('TrackerMealItemUpsertButton', {
          staticClass: "px-0",
          attrs: {
            "tag": "b-button",
            "tag-props": {
              variant: 'link'
            },
            "item": {
              nutrition_id: _vm.newParams.filters.nutrition_id
            }
          },
          on: {
            "submitted": function submitted($event) {
              return _vm.$emit('submitted', $event);
            }
          }
        }, [_vm._v(" + Add meal ")])], 1)];
      },
      proxy: true
    }], null, true)
  }, _vm._l(_vm.newResult.data, function (meal) {
    return _c('b-tab', {
      key: meal.id,
      staticClass: "p-2 bg-tab",
      attrs: {
        "title-link-class": "rounded-0 border-0",
        "title": meal.name
      }
    }, [_c('b-row', {
      staticClass: "mb-2",
      attrs: {
        "align-v": "center"
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('TrackerMealItemUpsertButton', {
      style: {
        cursor: 'pointer'
      },
      attrs: {
        "item": meal
      },
      on: {
        "submitted": function submitted($event) {
          return _vm.$emit('submitted', $event);
        }
      }
    }, [_c('b-form-timepicker', {
      staticClass: "w-130px",
      attrs: {
        "value": meal.taken_at,
        "locale": "en",
        "disabled": ""
      }
    })], 1), _c('div', {
      staticClass: "mr-2 d-flex align-items-center ml-2"
    }, [_c('b-badge', {
      staticClass: "p-1",
      attrs: {
        "variant": "success"
      }
    }, [_c('MealTypes', {
      attrs: {
        "ids": [meal.type]
      }
    })], 1)], 1)], 1)]), _c('b-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "6"
      }
    }, [_c('TrackerMealItemUpsertButton', {
      attrs: {
        "item": meal,
        "tag": "b-button",
        "tag-props": {
          variant: 'primary',
          squared: true,
          size: 'sm'
        }
      },
      on: {
        "submitted": function submitted($event) {
          return _vm.$emit('submitted', $event);
        }
      }
    }, [_c('b-icon-pencil')], 1), !meal.curriculum_meal ? _c('TrackerMealItemDeleteButton', {
      staticClass: "ml-2",
      attrs: {
        "item": meal,
        "tag": "b-button",
        "tag-props": {
          variant: 'outline-danger',
          squared: true,
          size: 'sm'
        }
      },
      on: {
        "delete:item": function deleteItem($event) {
          return _vm.$emit('delete:item', $event);
        }
      }
    }, [_c('b-icon-trash')], 1) : _vm._e()], 1)], 1), _c('TrackerMealFoodList', {
      attrs: {
        "meal": meal,
        "params": {
          filters: {
            meal_id: meal.id
          }
        },
        "result": {
          data: meal.meal_foods
        },
        "prefetch": false
      },
      on: {
        "submitted:item": function submittedItem($event) {
          return _vm.$emit('submitted:meal-food', $event);
        },
        "delete:item": function deleteItem($event) {
          return _vm.$emit('delete:meal-food', $event);
        }
      }
    })], 1);
  }), 1), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }