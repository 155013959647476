var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();

        _vm.$refs.totalTimeTimer.stop();

        _vm.submit('submitted');
      }
    }
  }, [_c('b-row', {
    staticClass: "mb-3",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "sm": "6"
    }
  }, [_c('div', {
    staticClass: "font-size-16 font-weight-bold text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.newItem.workout.name) + " ")]), _vm.newItem.start_time ? _c('div', {
    staticClass: "text-secondary text-truncate"
  }, [_vm._v(" " + _vm._s(_vm.$moment(_vm.newItem.start_time).format('lll')) + " - "), _vm.newItem.curriculum_phase ? [_vm._v(" Phase " + _vm._s(_vm.newItem.curriculum_phase.number) + ", Week " + _vm._s(_vm.weekIndex + 1) + " ")] : _vm._e()], 2) : _vm._e(), _vm.rest ? _c('div', {
    staticClass: "text-secondary text-truncate"
  }, [_vm._v(" Rest between sets: " + _vm._s(_vm.rest) + " secs ")]) : _vm._e()]), _c('b-col', {
    staticClass: "d-flex justify-content-end",
    attrs: {
      "sm": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "m-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.total_time),
      "state": _vm.getFieldState(_vm.$v.input.total_time)
    }
  }, [!_vm.$acl.check('isClient') ? _c('TimeInput', {
    staticClass: "font-weight-bolder font-size-22",
    attrs: {
      "value": _vm.input.total_time,
      "disabled": ""
    }
  }) : _vm._e(), _c('TimerInput', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$acl.check('isClient'),
      expression: "$acl.check('isClient')"
    }],
    ref: "totalTimeTimer",
    model: {
      value: _vm.input.total_time,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "total_time", $$v);
      },
      expression: "input.total_time"
    }
  })], 1)], 1)], 1), _c('TrackerExerciseGroupList', {
    attrs: {
      "params": {
        filters: {
          tracker_workout_id: _vm.newItem.id
        }
      },
      "result": {
        data: _vm.newItem.tracker_exercise_groups
      },
      "prefetch": false
    },
    on: {
      "update:result": function updateResult($event) {
        return _vm.onUpdateExerciseGroupsResult($event);
      }
    }
  }), _c('b-form-group', {
    staticClass: "m-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.notes),
      "state": _vm.getFieldState(_vm.$v.input.notes)
    }
  }, [_c('NotesInput', {
    model: {
      value: _vm.input.notes,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "notes", $$v);
      },
      expression: "input.notes"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4 mb-0"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting && !_vm.isCompleting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(_vm._s(_vm.actionName) + " ")], 1), _vm.$acl.check('isClient') && !_vm.newItem.is_performed ? _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "variant": "success",
      "disabled": _vm.isCompleting || !!_vm.emptyExerciseSets.length
    },
    on: {
      "click": _vm.complete
    }
  }, [_vm.isCompleting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Complete ")], 1) : _vm._e()], 1), _vm.$acl.check('isClient') && !_vm.newItem.is_performed && _vm.emptyExerciseSets.length ? _c('div', {
    staticClass: "mt-4"
  }, [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Please fill or remove sets to complete the workout. ")])], 1) : _vm._e()], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }