var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? [_c('b-card', {
    staticClass: "rounded-0 pt-4 m-0",
    attrs: {
      "bg-variant": "dark",
      "text-variant": "white",
      "body-class": "pt-5 mt-5"
    }
  }, [_c('div', {
    staticClass: "font-size-24 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.newItem.name) + " ")]), _vm._v(" " + _vm._s(_vm.newItem.description) + " ")]), _c('b-card', {
    staticClass: "py-3 m-0",
    attrs: {
      "bg-variant": "light"
    }
  }, [_c('b-row', [_c('b-col', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Fitness Level(s) ")]), _vm._v(": "), _c('Abilities', {
    attrs: {
      "ids": _vm.newItem.fitness_level_ids || []
    }
  })], 1), _c('b-col', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Muscles: ")]), _c('Muscles', {
    attrs: {
      "ids": _vm.newItem.muscle_ids || []
    }
  })], 1)], 1)], 1), _c('b-card', {
    staticClass: "m-0"
  }, [_c('WorkoutExerciseGroupsInput', {
    attrs: {
      "value": _vm.newItem.exercise_groups
    }
  })], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }