<template>
  <div>
    <b-card
      v-for="(week, weekIndex) in weeks"
      :key="weekIndex"
      class="bg-tab"
    >
      <TrackerWorkoutListSchedule
        :params="{
          filters: {
            curriculum_phase_id: item.id,
            start_time_from: week.start_date_from,
            start_time_to: week.start_date_to
          }
        }"
        :phase="item"
        :curriculum="curriculum"
      />
    </b-card>
  </div>
</template>

<script>
import TrackerWorkoutListSchedule from '@/components/trackers/workouts/TrackerWorkoutListSchedule'

export default {
  name: 'CurriculumPhaseWorkouts',

  components: {
    TrackerWorkoutListSchedule
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    curriculum: {
      type: Object,
      required: true
    }
  },

  computed: {
    weeks() {
      const weeks = []

      const phaseEndDay = this.item.end_date
      const start_date = this.item.start_date
      let start_date_from = this.$moment(start_date).subtract(this.$moment(start_date).isoWeekday() - 1, 'day').format('YYYY-MM-DD HH:mm:ss')

      while (this.$moment(start_date_from) <= this.$moment(phaseEndDay)) {
        const start_date_to = this.$moment(start_date_from).add(7 - (this.$moment(start_date_from).isoWeekday() - 1), 'day').format('YYYY-MM-DD HH:mm:ss')
        weeks.push({ start_date_from, start_date_to })
        start_date_from = start_date_to
      }

      return weeks
    }
  }
}
</script>