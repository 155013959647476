<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="!isCreated || isFetched">
      <b-form
        v-if="isEditable"
        @submit.prevent="submit('submitted')"
      >
        <b-form-row>
          <b-col lg="5">
            <div class="image-field text-center mt-5">
              <div v-if="tmpImageUrl || imageUrl && !input.remove_profile_image">
                <b-avatar
                  :src="imageUrl"
                  size="12rem"
                />

                <div class="image-remove">
                  <b-button
                    variant="primary"
                    @click="input.remove_profile_image = true; input.profile_image = null"
                  >
                    Remove Photo
                  </b-button>
                </div>
              </div>

              <b-form-file
                v-else
                class="add-image"
                placeholder="+ Add Photo"
                drop-placeholder="Drop file here..."
                :state="getFieldState($v.input.profile_image)"
                @change="input.profile_image = $event.target.files[0]"
              />
            </div>
          </b-col>

          <b-col lg="7">
            <!-- ROLES -->
            <b-form-group
              v-if="showRoles && availableRoleIds.length"
              label="Roles"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.role_ids)"
              :state="getFieldState($v.input.role_ids)"
            >
              <FormInputButtons
                v-model="input.role_ids"
                tag="b-button-group"
                multiple
                :disabled-values="disabledRoleIds"
                :options="$store.getters['asset/getRolesOptions']"
              />
            </b-form-group>

            <!-- NAME -->
            <b-form-group
              label="Name"
              label-cols="3"
            >
              <b-form-row>
                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.first_name)"
                    :state="getFieldState($v.input.first_name)"
                  >
                    <b-form-input
                      v-model="input.first_name"
                      placeholder="First Name"
                      squared
                      :state="getFieldState($v.input.first_name)"
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.last_name)"
                    :state="getFieldState($v.input.last_name)"
                  >
                    <b-form-input
                      v-model="input.last_name"
                      placeholder="Last Name"
                      :state="getFieldState($v.input.last_name)"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>

            <!-- BIRTHDATE -->
            <b-form-group
              label="D.O.B."
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.birthdate)"
              :state="getFieldState($v.input.birthdate)"
            >
              <b-form-input
                v-model="input.birthdate"
                type="date"
                :state="getFieldState($v.input.birthdate)"
              />
            </b-form-group>

            <!-- Address -->
            <b-form-group
              label="Address"
              label-cols="3"
            >
              <b-form-row>
                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.address_line1)"
                    :state="getFieldState($v.input.address_line1)"
                  >
                    <b-form-input
                      v-model="input.address_line1"    
                      placeholder="Address"
                      squared
                      :state="getFieldState($v.input.address_line1)"      
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.city)"
                    :state="getFieldState($v.input.city)"
                  >
                    <b-form-input
                      v-model="input.city"
                      placeholder="City"
                      :state="getFieldState($v.input.city)"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>

            <!-- STATE -->
            <b-form-group
              label="State"
              label-cols="3"
            >
              <b-form-row>
                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.state)"
                    :state="getFieldState($v.input.state)"
                  >
                    <b-form-input
                      v-model="input.state"    
                      placeholder="State"
                      squared
                      :state="getFieldState($v.input.state)"      
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.zip)"
                    :state="getFieldState($v.input.zip)"
                  >
                    <b-form-input
                      v-model="input.zip"
                      placeholder="Zip"
                      number
                      :state="getFieldState($v.input.zip)"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>

            <!-- PHONE -->
            <b-form-group
              label="Phone"
              label-cols="3"
            >
              <b-form-row>
                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.phone)"
                    :state="getFieldState($v.input.phone)"
                  >
                    <b-form-input
                      v-model="input.phone"
                      placeholder="Cell Number" 
                      :state="getFieldState($v.input.phone)"      
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.home_number)"
                    :state="getFieldState($v.input.home_number)"
                  >
                    <b-form-input
                      v-model="input.home_number"
                      placeholder="Home Number"
                      :state="getFieldState($v.input.home_number)"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>

            <!-- EMAIL -->
            <b-form-group
              label="Email"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.email)"
              :state="getFieldState($v.input.email)"
            >
              <b-form-input
                v-if="!newItem.id"
                v-model="input.email"
                placeholder="Email Address"
                :state="getFieldState($v.input.email)"
              />

              <b-form-input
                v-else
                :value="newItem.email"
                placeholder="Email Address"
                disabled
              />
            </b-form-group>

            <!-- TIMEZONE -->
            <b-form-group
              label="Time zone"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.timezone)"
              :state="getFieldState($v.input.timezone)"
            >
              <TimezoneSelect
                v-model="input.timezone"
              />
            </b-form-group>

            <!-- EMERGENCY CONTACT -->
            <b-form-group
              v-if="isClient"
              label="Emergency contact"
              label-cols="3"
            >
              <b-form-row>
                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.emergency_contact)"
                    :state="getFieldState($v.input.emergency_contact)"
                  >
                    <b-form-input
                      v-model="input.emergency_contact"
                      placeholder="Name" 
                      :state="getFieldState($v.input.emergency_contact)"      
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.emergency_phone)"
                    :state="getFieldState($v.input.emergency_phone)"
                  >
                    <b-form-input
                      v-model="input.emergency_phone"
                      placeholder="Phone Number"
                      :state="getFieldState($v.input.emergency_phone)"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>

            <!-- OCCUPATION -->
            <b-form-group
              v-if="isClient"
              label="Occupation"
              label-cols="3"
            >
              <b-form-row>
                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.occupation)"
                    :state="getFieldState($v.input.occupation)"
                  >
                    <b-form-input
                      v-model="input.occupation"    
                      placeholder="Occupation"
                      squared
                      :state="getFieldState($v.input.occupation)"      
                    />
                  </b-form-group>
                </b-col>

                <b-col>
                  <b-form-group
                    class="mb-0"
                    :invalid-feedback="getFieldInvalidFeedback($v.input.employer)"
                    :state="getFieldState($v.input.employer)"
                  >
                    <b-form-input
                      v-model="input.employer"
                      placeholder="Employer"
                      :state="getFieldState($v.input.employer)"
                    />
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form-group>

            <!-- FITNESS LEVEL -->
            <b-form-group
              v-if="isClient"
              label="Fitness Level"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.fitness_level)"
              :state="getFieldState($v.input.fitness_level)"
            >
              <b-form-select
                v-model="input.fitness_level"
                :options="$store.getters['asset/getAbilityLevelsOptions']"
                :state="getFieldState($v.input.fitness_level)"
              />
            </b-form-group>

            <!-- GOAL DIRECTION -->
            <b-form-group
              v-if="isClient"
              label="Goal Direction"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.goal_direction)"
              :state="getFieldState($v.input.goal_direction)"
            >
              <b-form-select
                v-model="input.goal_direction"
                :options="$store.getters['asset/getGoalDirectionsOptions']"
                :state="getFieldState($v.input.goal_direction)"
              />
            </b-form-group>

            <!-- GENDER -->
            <b-form-group
              label="Gender"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.sex)"
              :state="getFieldState($v.input.sex)"
            >
              <b-form-radio-group
                v-model="input.sex"
                :options="$store.getters['asset/getSexOptions']"
                button-variant="outline-primary"
                :state="getFieldState($v.input.sex)"
              />
            </b-form-group>

            <!-- BIO -->
            <b-form-group
              v-if="isStaff"
              label="Bio:"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.bio)"
              :state="getFieldState($v.input.bio)"
            >
              <b-form-textarea
                v-model="input.bio"
                rows="5"
                :state="getFieldState($v.input.bio)"
              />
            </b-form-group>

            <!-- PRIMARY TRAINER -->
            <b-form-group
              v-if="isClient"
              label="Primary trainer"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.primary_trainer_id)"
              :state="getFieldState($v.input.primary_trainer_id)"
            >
              <UserSelect
                v-if="primaryTrainerIsEditable"
                v-model="input.primary_trainer_id"
                :item="newItem.primary_trainer"
                :show-create="false"
                :params="{
                  filters: {
                    role_ids: [2]
                  }
                }"
                placeholder="Type a primary trainer name"
              />

              <UserSelect
                v-else
                :value="newItem.primary_trainer ? newItem.primary_trainer.id : null"
                :item="newItem.primary_trainer"
                disabled
                :params="{
                  filters: {
                    role_ids: [2]
                  }
                }"
                placeholder="No primary trainer"
              />
            </b-form-group>

            <!-- SECONDARY TRAINER -->
            <b-form-group
              v-if="isClient"
              label="Secondary trainer"
              label-cols="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.secondary_trainer_id)"
              :state="getFieldState($v.input.secondary_trainer_id)"
            >
              <UserSelect
                v-if="secondaryTrainerIsEditable"
                v-model="input.secondary_trainer_id"
                :item="newItem.secondary_trainer"
                :show-create="false"
                :params="{
                  filters: {
                    role_ids: [2]
                  }
                }"
                placeholder="Type a secondary trainer name"
              />

              <UserSelect
                v-else
                :value="newItem.secondary_trainer ? newItem.secondary_trainer.id : null"
                :item="newItem.secondary_trainer"
                disabled
                :params="{
                  filters: {
                    role_ids: [2]
                  }
                }"
                placeholder="No secondary trainer"
              />
            </b-form-group>

            <!-- SUBMIT -->
            <b-form-group class="text-right mt-4">
              <b-button
                variant="primary"
                type="submit"
                :disabled="isSubmitting"
              >
                <b-spinner v-if="isSubmitting" small /> Submit
              </b-button>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
      
      <div v-else-if="isCreated && !isFetching">
        You can't edit this user.
      </div>
    </template>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { required, minLength, maxLength, email } from "vuelidate/lib/validators"
import crudItemMixin from '@/mixins/crud-item'
import FormInputButtons from '@/components/FormInputButtons'
import TimezoneSelect from '@/components/inputs/timezone-select'
import { mapGetters } from 'vuex'

export default {
  name: 'UserProfileUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    FormInputButtons,
    TimezoneSelect,
    UserSelect: () => import('@/components/users/UserSelect')
  },

  props: {
    company: {
      type: Object,
      default: null
    },

    showRoles: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    baseEndpoint() {
      return this.company ? 'admin/users' : 'users'
    },

    isEditable() {
      if (
        // 1. is current user
        this.isCurrentUser ||
        // 2. no user
        !this.isCreated && this.$acl.check('couldAddUser') ||
        // 3. user is client
        this.newItem.role_ids.includes(1) && this.$acl.check('couldEditClient') ||
        // 4. user is trainer
        this.newItem.role_ids.includes(2) && this.$acl.check('couldEditTrainer') ||
        // 5. user is owner
        this.newItem.role_ids.includes(3) && this.$acl.check('couldEditOwner') ||
        // 6. user is manager
        this.newItem.role_ids.includes(4) && this.$acl.check('couldEditManager') ||
        // 7. user is sales agent
        this.newItem.role_ids.includes(5) && this.$acl.check('couldEditSalesAgent')
      ) {
        return true
      }
      return false
    },

    availableRoleIds() {
      let availableRoleIds = []

      if (this.$acl.check('couldEditClient')) {
        availableRoleIds.push(1)
      }

      if (this.$acl.check('couldEditTrainer')) {
        availableRoleIds.push(2)
      }
      
      if (this.$acl.check('couldEditOwner')) {
        availableRoleIds.push(3)
      }
      
      if (this.$acl.check('couldEditManager')) {
        availableRoleIds.push(4)
      }
      
      if (this.$acl.check('couldEditSalesAgent')) {
        availableRoleIds.push(5)
      }

      return availableRoleIds
    },

    disabledRoleIds() {
      return _.filter([1, 2, 3, 4, 5], i => !this.availableRoleIds.includes(i))
    },

    isCurrentUser() {
      return this.newItem.id === this.currentUser.id
    },

    isClient() {
      return this.input && _.find(this.input.role_ids, i => i === 1)
    },

    isStaff() {
      return this.input && _.find(this.input.role_ids, i => i >= 2)
    },

    rolesAreEditable() {
      return this.availableRoleIds.length > 0
    },

    primaryTrainerIsEditable() {
      return this.isClient && (
        this.$acl.check('couldEditClientsPrimaryTrainer') ||
        !this.isCreated && this.$acl.check('couldEditClientsPrimaryTrainerOnCreate') ||
        _.get(this.newItem.primary_trainer, 'id') === this.currentUser.id && this.$acl.check('couldEditClientsPrimaryTrainerIfIsPrimaryTrainer')
      )
    },

    secondaryTrainerIsEditable() {
      return this.isClient && (
        this.$acl.check('couldEditClientsSecondaryTrainer') ||
        !this.isCreated && this.$acl.check('couldEditClientsSecondaryTrainerOnCreate') ||
        _.get(this.newItem.primary_trainer, 'id') === this.currentUser.id && this.$acl.check('couldEditClientsSecondaryTrainerIfIsPrimaryTrainer')
      )
    },

    emailIsEditable() {
      return !this.isCreated
    },

    tmpImageUrl() {
      return this.input && this.input.profile_image ? URL.createObjectURL(this.input.profile_image) : null
    },

    imageUrl() {
      return this.tmpImageUrl || _.get(this.newItem, 'profile_img_url')
    }
  },

  validations() {
    const input = {
      first_name: { required, minLength: minLength(2), maxLength: maxLength(120) },
      last_name: { required, minLength: minLength(2), maxLength: maxLength(180) },
      birthdate: { required },
      profile_image: {},
      address_line1: { required, minLength: minLength(5), maxLength: maxLength(200) },
      state: { required, minLength: minLength(2), maxLength: maxLength(50) },
      city: { required, minLength: minLength(2), maxLength: maxLength(255) },
      zip: { required, minLength: minLength(5), maxLength: maxLength(5) },
      phone: { required, minLength: minLength(5), maxLength: maxLength(30) },
      home_number: { minLength: minLength(5), maxLength: maxLength(30) },
      sex: { required },
      timezone: { required }
    }

    if (this.emailIsEditable) {
      input.email = { required, email }
    }

    if (this.isClient) {
      input.emergency_contact = { required, minLength: minLength(2), maxLength: maxLength(255) }
      input.emergency_phone = { required, minLength: minLength(5), maxLength: maxLength(30) }
      input.occupation = { required, maxLength: maxLength(150) }
      input.employer = { required, maxLength: maxLength(150) }
      input.fitness_level = { required }
      input.goal_direction = { required }
    }

    if (this.primaryTrainerIsEditable) {
      input.primary_trainer_id = { required }
    }

    if (this.rolesAreEditable) {
      input.role_ids = { required }
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        first_name: this.newItem.first_name,
        last_name: this.newItem.last_name,
        birthdate: this.newItem.birthdate,
        address_line1: this.newItem.address_line1,
        state: this.newItem.state,
        city: this.newItem.city,
        zip: this.newItem.zip,
        phone: this.newItem.phone,
        home_number: this.newItem.home_number,
        sex: this.newItem.sex,
        profile_image: this.newItem.profile_image,
        remove_profile_image: false,
        // Client only
        fitness_level: this.newItem.fitness_level,
        goal_direction: this.newItem.goal_direction,
        emergency_contact: this.newItem.emergency_contact,
        emergency_phone: this.newItem.emergency_phone,
        occupation: this.newItem.occupation,
        employer: this.newItem.employer,
        primary_trainer_id: _.get(this.newItem.primary_trainer, 'id'),
        secondary_trainer_id: _.get(this.newItem.secondary_trainer, 'id'),
        timezone: this.newItem.timezone,
        // Staff only
        bio: this.newItem.bio,
        // Role ids
        role_ids: this.newItem.role_ids,
        // Email
        email: this.newItem.email
      }

      if (!this.isCreated && !input.timezone) {
        input.timezone = this.$moment.tz.guess()
      }

      if (this.company) {
        input.company_id = this.company.id
      }

      return input
    },

    prepareInput() {
      const input = {
        first_name: this.input.first_name,
        last_name: this.input.last_name,
        birthdate: this.input.birthdate,
        address_line1: this.input.address_line1,
        state: this.input.state,
        city: this.input.city,
        zip: this.input.zip,
        phone: this.input.phone,
        home_number: this.input.home_number,
        sex: this.input.sex,
        profile_image: this.input.profile_image,
        remove_profile_image: this.input.remove_profile_image,
        timezone: this.input.timezone
      }

      if (this.emailIsEditable) {
        input.email = this.input.email
      }

      if (this.isClient) {
        input.fitness_level = this.input.fitness_level
        input.goal_direction = this.input.goal_direction
        input.emergency_contact = this.input.emergency_contact
        input.emergency_phone = this.input.emergency_phone
        input.occupation = this.input.occupation
        input.employer = this.input.employer
      }

      if (this.isStaff) {
        input.bio = this.input.bio
      }

      if (this.primaryTrainerIsEditable) {
        input.primary_trainer_id = this.input.primary_trainer_id
      }

      if (this.secondaryTrainerIsEditable) {
        input.secondary_trainer_id = this.input.secondary_trainer_id
      }

      if (this.rolesAreEditable) {
        input.role_ids = this.input.role_ids
      }

      if (this.company) {
        input.company_id = this.input.company_id
      }
      window.console.log('prepareInput', input)
      return input
    },

    async afterSubmit(data) {
      const endpoint = this.updateEndpoint || `${this.baseEndpoint}/${data.id}`

      if (this.isCurrentUser) {
        await this.$store.dispatch('auth/fetchCurrentUser')
      }

      if (this.input.profile_image) {
        const formData = new FormData()
        formData.append('profile_image', this.input.profile_image)

        return this.$axios
          .patch(endpoint, formData)

          .then(async ({ data }) => {  
            if (this.isCurrentUser) {
              await this.$store.dispatch('auth/fetchCurrentUser')
            }
            return data
          })

          .catch((e) => {
            throw e
          })
      }
    }
  }
}
</script>