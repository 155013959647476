var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-row', {
    staticClass: "mb-2",
    attrs: {
      "align-h": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('ExerciseSelect', {
    attrs: {
      "item": _vm.exercise,
      "show-create": false,
      "size": "sm"
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.exercise = $event;
      }
    },
    model: {
      value: _vm.newParams.filters.exercise_id,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams.filters, "exercise_id", $$v);
      },
      expression: "newParams.filters.exercise_id"
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "5"
    }
  }, [_c('b-input-group', [_c('b-form-datepicker', {
    attrs: {
      "size": "sm",
      "placeholder": "From Date"
    },
    model: {
      value: _vm.newParams.filters.date_from,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams.filters, "date_from", $$v);
      },
      expression: "newParams.filters.date_from"
    }
  }), _c('b-form-datepicker', {
    attrs: {
      "size": "sm",
      "placeholder": "To Date"
    },
    model: {
      value: _vm.newParams.filters.date_to,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams.filters, "date_to", $$v);
      },
      expression: "newParams.filters.date_to"
    }
  })], 1)], 1)], 1), _vm.exercise ? [_c('h1', {
    staticClass: "my-4 text-center"
  }, [_vm._v(_vm._s(_vm.exercise.name) + " Strength Gains")]), _c('v-chart', {
    style: {
      height: '400px'
    },
    attrs: {
      "option": _vm.option,
      "autoresize": ""
    }
  })] : _c('div', [_c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" Select an Exercise to view Strength Gains ")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }