<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table-simple
      v-if="newParams.filters.phase_id"
      class="input-table mb-2"
      fixed
    >
      <b-thead v-if="showHeader">
        <b-tr>
          <b-th class="bg-transparent w-100px" />
          <b-th class="text-center">Name</b-th>
          <b-th class="text-center">Type</b-th>
          <b-th class="text-center">Rec. Dosage</b-th>
          <b-th class="text-center">Actual Dosage</b-th>
          <b-th class="text-center">Dosages/Day</b-th>

          <b-th
            v-if="editable"
            class="text-right w-50px"
          />
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr v-if="!input || !input.items || !input.items.length">
          <b-th
            class="text-center w-100px"
            :rowspan="editable ? 2 : 1"
          >
            Phase {{ phase.number }}
          </b-th>

          <b-td class="text-center" />
          <b-td class="text-center" />
          <b-td class="text-center" />
          <b-td class="text-center" />
          <b-td class="text-center" />
        </b-tr>

        <template v-else>
          <b-tr
            v-for="(item, itemIndex) in input.items"
            :key="item.id"
          >
            <b-th
              v-if="itemIndex === 0"
              class="text-center w-100px"
              :rowspan="input.items.length + (editable ? 1 : 0)"
            >
              Phase {{ phase.number }}
            </b-th>

            <b-td class="text-center">
              {{ getItemById(item.id).supplement.name }}
            </b-td>

            <b-td class="text-center">
              <SupplementTypes
                :ids="[getItemById(item.id).supplement.type]"
              />
            </b-td>

            <b-td class="text-center">
              {{ getItemById(item.id).supplement.dose }}
            </b-td>

            <b-td class="text-center">
              <b-form-input
                v-if="editable"
                v-model="item.dose"
                type="number"
                number
              />

              <template v-else>
                {{ item.dose }}
              </template>
            </b-td>

            <b-td class="text-center">
              <b-form-input
                v-if="editable"
                v-model="item.doses_per_day"
                type="number"
                number
              />

              <template v-else>
                {{ item.doses_per_day }}
              </template>
            </b-td>

            <b-td
              v-if="editable"
              class="text-right w-50px"
            >
              <CurriculumPhaseSupplementItemDeleteButton
                :item="item"
                class="p-0"
                tag="b-button"
                :tag-props="{
                  variant: 'link'
                }"
                @delete:item="$emit('delete:item', $event); refresh()"
              >
                <b-icon-trash scale="0.8" />
              </CurriculumPhaseSupplementItemDeleteButton>
            </b-td>
          </b-tr>
        </template>

        <b-tr v-if="editable">
          <b-td
            class="align-middle"
            colspan="6"
          >
            <b-row align="between">
              <b-col class="text-center">
                <CurriculumPhaseSupplementItemUpsertButton
                  :item="{ phase_id: newParams.filters.phase_id }"
                  class="p-0 mr-4"
                  tag="b-button"
                  :tag-props="{
                    variant: 'link'
                  }"
                  @submitted="refresh()"
                >
                  <b-icon-plus-circle class="mr-1" scale="0.8" /> Add supplement
                </CurriculumPhaseSupplementItemUpsertButton>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-alert
      v-else
      show
      variant="warning"
    >
      CurriculumPhaseSupplementList working only with filters: phase_id
    </b-alert>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import SupplementTypes from '@/components/v1/asset/SupplementTypes'
import CurriculumPhaseSupplementItemDeleteButton from './buttons/CurriculumPhaseSupplementItemDeleteButton'
import CurriculumPhaseSupplementItemUpsertButton from './buttons/CurriculumPhaseSupplementItemUpsertButton'

export default {
  name: 'CurriculumPhaseSupplementList',

  mixins: [
    crudListMixin
  ],

  components: {
    SupplementTypes,
    CurriculumPhaseSupplementItemDeleteButton,
    CurriculumPhaseSupplementItemUpsertButton,
  },

  props: {
    showHeader: {
      type: Boolean,
      default: true
    },

    editable: {
      type: Boolean,
      default: false
    },

    phase: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      baseEndpoint: 'curriculum-phase-supplements',
      autosave: true
    }
  },

  methods: {
    getItemById(id) {
      return _.find(this.newResult.data, { id })
    },

    getInput() {
      const input = {
        items: _.map(this.newResult.data, item => ({
          id: item.id,
          dose: item.dose,
          doses_per_day: item.doses_per_day
        }))
      }

      return input
    },

    async afterSubmit() {
      await this.refresh()
    }
  }
}
</script>

<style scoped lang="scss">
  .input-table {
    th {
      background: #BAD9D9;
      vertical-align: middle;
      border-bottom: none;
    }
    td {
      vertical-align: middle;
      border-bottom: none;
    }

    thead {
      th {
        background: #c0c5ed;

        &:first-child {
          background: #97CBCA;
          vertical-align: middle;
        }
      }
    }

    tbody {
      background: #FFFFFF;
    }
  }
</style>