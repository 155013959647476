var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "4"
    }
  }, [_c('WaterIntakesItemUpsertButton', {
    attrs: {
      "tag": "b-button",
      "tag-props": {
        variant: 'primary'
      }
    },
    on: {
      "update:item": _vm.refresh
    }
  }, [_vm._v(" Add Water Intakes ")])], 1)], 1), _c('b-card', [_c('WaterIntakesList', {
    ref: "list",
    attrs: {
      "params": {
        limit: 400
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }