<template>
  <div class="alerts-wrap">
    <b-alert
      v-for="item in list"
      :key="item.id"
      :variant="item.type"
      show
      v-html="item.text"
    >
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'Alerts',
  data () {
    return {
      interval: null
    }
  },
  computed: {
    list () {
      if (this.$store.getters['layout/getAlerts'].length) {
        this.startDelete()
      } else {
        this.stopDelete()
      }
      return this.$store.getters['layout/getAlerts']
    }
  },
  methods: {
    startDelete () {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.$store.commit('layout/removeAlert')
        }, 5000)
      }
    },
    stopDelete () {
      clearInterval(this.interval)
      this.interval = null
    }
  }
}
</script>

<style scoped>
  .alerts-wrap {
    position: fixed;
    top: 30px;
    left: 30px;
    max-height: 185px;
    min-width: 300px;
    overflow: hidden;
    z-index: 1051;
  }
</style>