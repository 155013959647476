<template>
  <div>
    <b-form-group
      v-for="(note, noteIndex) in newValue.items"
      :key="noteIndex"
      class="my-2"
      :invalid-feedback="getFieldInvalidFeedback($v.newValue.items.$each[noteIndex])"
      :state="getFieldState($v.newValue.items.$each[noteIndex])"
    >
      <b-collapse
        :visible="noteIndex !== activeNoteIndex"
      >
        <b-alert
          class="mb-0"
          show
          dismissing
        >
          <div>
            {{ note.text }}
          </div>

          <div class="text-right">
            <b-button
              variant="link"
              size="sm"
              @click="activeNoteIndex = noteIndex"
            >
              Edit
            </b-button>

            <b-button
              variant="link"
              size="sm"
              @click="showDeleteConfirmationModal(noteIndex)"
            >
              Delete
            </b-button>
          </div>
        </b-alert>
      </b-collapse>

      <b-collapse
        :visible="noteIndex === activeNoteIndex"
      >
        <b-form-textarea
          v-if="noteIndex === activeNoteIndex"
          v-click-outside="() => onClose(noteIndex)"
          v-model="note.text"
          rows="6"
          max-rows="6"
        />

        <div class="text-right">
          <b-button
            variant="link"
            size="sm"
            @click="onClose(noteIndex)"
          >
            Close
          </b-button>
        </div>
      </b-collapse>
    </b-form-group>

    <div>
      <b-alert
        show
        class="text-center"
      >
        <b-button
          variant="link"
          class="p-0 m-0"
          @click="addItem(); activeNoteIndex = newValue.items.length - 1"
        >
          + Add Note
        </b-button>
      </b-alert>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import formMixin from '@/mixins/form'

export default {
  name: 'NotesInput',

  mixins: [
    formMixin
  ],

  data() {
    return {
      autosave: true,
      activeNoteIndex: null
    }
  },

  validations: {
    newValue: {
      items: {
        $each: {
          text: {},
          create_at: {}
        }
      }
    }
  },

  methods: {
    addItem() {
      this.newValue.items.push({
        text: null,
        created_at: this.$moment().format('YYYY-MM-DD HH:mm:ii')
      })
    },

    deleteItem(itemIndex) {
      this.newValue.items = _.filter(this.newValue.items, (i, index) => index !== itemIndex)
    },

    showDeleteConfirmationModal(itemIndex) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
        title: 'Please Confirm',
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteItem(itemIndex)
          }
        })
    },

    onClose() {
      this.activeNoteIndex = null
    }
  }
}
</script>