<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>
    </b-row>

    <template v-if="$acl.check('isStaff')">
      <b-card>
        <b-row>
          <b-col class="mb-3 font-size-20 font-weight-bold">
            Stripe
          </b-col>

          <b-col
            v-if="$acl.check('isOwner')"
            class="text-right"
          >
            <CompanyItemStripeUpdateButton
              :item="currentCompany"
              tag="b-button"
              :tag-props="{
                size: 'sm'
              }"
            >
              Edit
            </CompanyItemStripeUpdateButton>
          </b-col>
        </b-row>

        <b-alert
          show
          :variant="currentCompany.has_stripe_secret_key ? 'success' : 'warning'"
        >
          {{ currentCompany.has_stripe_secret_key ? 'Company has stripe secret key.' : 'Company has no stripe secret key.' }}
        </b-alert>

        <b-alert
          show
          :variant="currentCompany.has_stripe_signing_secret ? 'success' : 'warning'"
        >
          {{ currentCompany.has_stripe_signing_secret ? 'Company has stripe signing secret.' : 'Company has no stripe signing secret.' }}
        </b-alert>
      </b-card>

      <b-card>
        <div class="mb-3 font-size-20 font-weight-bold">
          Company App Plans
        </div>

        <CompanyAppPlanList />
      </b-card>

      <b-card>
        <div class="mb-3 font-size-20 font-weight-bold">
          Curriculum Products
        </div>

        <CurriculumProductList />
      </b-card>

      <b-card>
        <div class="mb-3 font-size-20 font-weight-bold">
          Other Company Products
        </div>

        <CompanyProductList />
      </b-card>
    </template>

    <template v-if="$acl.check('isClient')">
      <b-card>
        <div class="mb-3 font-size-20 font-weight-bold">
          Curriculum Products
        </div>

        <CurriculumProductList
          :params="{
            filters: {
              type: 'new'
            }
          }"
        />
      </b-card>

      <b-card>
        <div class="mb-3 font-size-20 font-weight-bold">
          Other Company Products
        </div>

        <CompanyProductList />
      </b-card>
    </template>
  </Layout>
</template>

<script>
import { mapGetters } from 'vuex'
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import CompanyAppPlanList from '@/components/companies/store/CompanyAppPlanList'
import CurriculumProductList from '@/components/companies/store/CurriculumProductList'
import CompanyProductList from '@/components/companies/store/CompanyProductList'
import CompanyItemStripeUpdateButton from '@/components/companies/buttons/CompanyItemStripeUpdateButton'

export default {
  name: 'CompanyStorePage',

  components: {
    Layout,
    PageHeader,
    CurriculumProductList,
    CompanyProductList,
    CompanyAppPlanList,
    CompanyItemStripeUpdateButton
  },

  page() {
    return {
      title: this.title
    }
  },

  computed: {
    ...mapGetters({
      currentCompany: 'auth/currentCompany'
    }),

    title() {
      return `${this.currentCompany.name}'s Store`
    }
  }
}
</script>