var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "py-1",
    style: {
      width: '100%',
      overflow: _vm.daysCount > 1 ? 'scroll' : null
    }
  }, [_vm.layout ? _c('div', {
    staticClass: "position-relative layout-wrapper",
    style: {
      paddingTop: _vm.showDays ? '59px' : 0,
      minWidth: _vm.daysCount > 1 ? '840px' : null
    }
  }, [_c('ScheduleBackground', {
    style: {
      color: '#999999'
    },
    attrs: {
      "events": _vm.bgEvents,
      "start-date": _vm.startDate,
      "days-count": _vm.daysCount,
      "phase": _vm.phase,
      "paused-at": _vm.pausedAt,
      "show-days": _vm.showDays
    },
    on: {
      "bg-event-click": function bgEventClick($event) {
        return _vm.$emit('bg-event-click', $event);
      },
      "hour-click": function hourClick($event) {
        return _vm.$emit('hour-click', $event);
      }
    }
  }), _c('div', [_c('grid-layout', {
    style: {
      height: '970px',
      marginRight: '-50px',
      marginLeft: '-24px'
    },
    attrs: {
      "layout": _vm.layout,
      "col-num": _vm.daysCount,
      "max-rows": 96,
      "row-height": 10,
      "vertical-compact": false,
      "prevent-collision": true,
      "use-css-transforms": false,
      "margin": [74, 0],
      "auto-size": false
    },
    on: {
      "update:layout": function updateLayout($event) {
        _vm.layout = $event;
      },
      "layout-updated": _vm.onLayoutUpdated
    }
  }, _vm._l(_vm.layout, function (item, itemIndex) {
    return _c('grid-item', {
      key: itemIndex,
      attrs: {
        "max-w": 1,
        "i": item.i,
        "x": item.x,
        "y": item.y,
        "w": item.w,
        "h": item.h,
        "is-resizable": item.isResizable,
        "static": item.isStatic,
        "drag-ignore-from": ".no-drag"
      }
    }, [_c('ScheduleEvent', {
      attrs: {
        "event": _vm.events[itemIndex]
      },
      on: {
        "delete": function _delete($event) {
          return _vm.deleteEventByIndex(itemIndex);
        }
      },
      scopedSlots: _vm._u([{
        key: "actions",
        fn: function fn(_ref) {
          var event = _ref.event,
              toggleEventIsActive = _ref.toggleEventIsActive,
              deleteEvent = _ref.deleteEvent;
          return [_vm._t("event-actions", null, {
            "event": event,
            "toggleEventIsActive": toggleEventIsActive,
            "deleteEvent": deleteEvent
          })];
        }
      }], null, true)
    })], 1);
  }), 1)], 1)], 1) : _vm._e()]);
}
var staticRenderFns = []

export { render, staticRenderFns }