<script>
/**
 * Toggle component
 */
export default {
  props: {
    icon: {
      type: String,
      default: "far fa-question",
    },
    size: {
      type: String,
      default: '10',
    },
    color: {
      type: String,
      default: '#000000',
    }
  },
  created() {
    this.internalIcon = this.icon;
    this.internalSize = this.size;
  },
  computed: {
    style() {
      return {
        fontSize: this.internalSize + "px",
        color: this.color
      };
    },
  },
  data() {
    return {
      internalIcon: '',
      internalSize: '',
    };
  },
};
</script>

<template>
  <div class="fa-icon-component">
    <i v-bind:style="style" :class="internalIcon"></i>
  </div>
</template>
