<script>
import Layout from '../../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import { assetComputed, authComputed } from '@/state/helpers';
import VueSlideBar from 'vue-slide-bar';
import Abilities from '@/components/v1/asset/Abilities';
import GoalDirections from '@/components/v1/asset/GoalDirections';

/**
 * Advanced table component
 */
export default {
  name: 'WorkoutProgramStep1',
  page: {
    title: 'Workout Program Creator',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueSlideBar, Abilities, GoalDirections },
  data() {
    return {
      config: appConfig,
      title: 'Workout Program Creator',
      totalRows: 0,
      currentPage: 1,
      perPage: appConfig.table.perPage,
      pageOptions: appConfig.table.pageOptions,
      //afilter: '',
      //searchQuery: '',
      isBusy: false,
      filter: {
        primary_muscle: '',
        ability_level: '',
        type: '',
        favorite: false,
      },
      filterOn: [],
      sortBy: 'name',
      sortDesc: false,
      fields: [
        { key: 'select', sortable: false, label: '', tdClass: 'text-center' },
        { key: 'name', sortable: false },
        { key: 'fitness_level_ids', sortable: false, label: 'Fitness Level' },
        { key: 'gender', sortable: false },
        { key: 'term', sortable: false },
        {
          key: 'goal_direction',
          sortable: false,
          label: 'Goal direction',
        },
      ],
      selected_forecast: null,
      number_of_workouts: 1,
      next_clicked: false,
    };
  },
  computed: {
    ...assetComputed,
    ...authComputed,
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    dataProvider() {
      const limit = this.perPage;
      const page = this.currentPage;

      return this.$axios
        .get(`/forecast?limit=${limit}&page=${page}`, { parent: this })
        .then((response) => {
          this.totalRows = response.data.total;
          return response.data.data;
        });
    },
    /*triggerReload() {
      this.afilter = {
        value: !this.afilter.value,
      };
    },*/
    /**
     * Search the table data with search input
     */
    /*onFiltered(filteredItems) {
      filteredItems;
    },*/
    /**
     * Select forecast handler
     */
    onForecastSelect(row) {
      this.selected_forecast = row.id;
    },
    onNextClick() {
      this.next_clicked = true;

      if (this.selected_forecast > 0) {
        window.localStorage.setItem('add_program_selected_forecast', this.selected_forecast)
        window.localStorage.setItem('add_program_number_of_workouts', this.number_of_workouts)
        this.$router.push(`/workout-programs/add/next`);
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-12">
        <div class="card-body">
          <h6>Select Forecast:</h6>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="isBusy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <div class="card card-forecast">
            <!-- Table -->
            <div class="table-forecasts mb-0">
              <b-table
                :items="dataProvider"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @row-clicked="onForecastSelect"
              >
                <template #cell(select)="data">
                  <b-form-radio
                    v-model="selected_forecast"
                    name="selected_forecast"
                    :value="data.item.id"
                  ></b-form-radio>
                </template>
                <template #cell(fitness_level_ids)="data">
                  <Abilities :ids="data.item.fitness_level_ids || []" />
                </template>
                <template #cell(goal_direction)="data">
                  <GoalDirections :ids="[data.item.goal_direction]" />
                </template>
              </b-table>
            </div>
            <div class="card-body">
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <h6>Select the maximum number of workouts per any one phase:</h6>
                  <div class="row">
                    <div class="col-2"></div>
                    <div class="col-8">
                      <vue-slide-bar
                        v-model="number_of_workouts"
                        :min="1"
                        :max="14"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="mt-3">
          <div class="float-right">
            <button disabled class="btn btn-secondary">
              Cancel
            </button>
            <button
              @click="onNextClick"
              type="submit"
              class="btn btn-primary ml-1"
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-right">
        <div
          v-if="next_clicked && !selected_forecast"
          :class="{
            'is-invalid': next_clicked && !selected_forecast,
          }"
        ></div>
        <div v-if="next_clicked && !selected_forecast" class="invalid-feedback">
          <span>Select a forecast.</span>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
  .card.card-forecast >>> table tr {
    background: #d7dbf4;
  }
  .card.card-forecast >>> table tbody tr {
    cursor: pointer;
  }
  .card.card-forecast >>> table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
</style>
