var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-row', [_c('b-col', [_c('b-card', {
    attrs: {
      "bg-variant": "primary",
      "body-class": "pb-2"
    }
  }, [_c('h5', {
    staticClass: "font-size-16 text-uppercase mt-0 text-white-50"
  }, [_vm._v("Fluids")]), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col'), _c('b-col', {
    staticClass: "text-white font-size-22 font-weight-bold text-center",
    style: {
      lineHeight: 1.2
    }
  }, [_vm.stats ? _c('div', [_vm._v(" " + _vm._s(_vm.stats.fluids.taken) + " / " + _vm._s(_vm.stats.fluids.total) + " ")]) : _c('div', [_vm._v("...")]), _c('div', [_vm._v("ounces")])])], 1), _c('TrackerWaterButton', {
    staticClass: "p-0 text-white",
    attrs: {
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      },
      "params": {
        filters: {
          user_id: _vm.newItem.client ? _vm.newItem.client.id : null,
          date: _vm.$moment(_vm.date).format('YYYY-MM-DD')
        }
      },
      "curriculum": _vm.newItem,
      "water-recommended": _vm.stats ? _vm.stats.fluids.total : null
    },
    on: {
      "closed": _vm.fetchStats
    }
  }, [_vm._v(" + Add Ounces ")])], 1)], 1), _c('b-col', [_c('b-card', {
    attrs: {
      "bg-variant": "primary",
      "body-class": "pb-2"
    }
  }, [_c('h5', {
    staticClass: "font-size-16 text-uppercase mt-0 text-white-50"
  }, [_vm._v("Nutrients")]), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col'), _c('b-col', {
    staticClass: "text-white font-size-22 font-weight-bold text-center",
    style: {
      lineHeight: 1.2
    }
  }, [_vm.stats ? _c('div', [_vm._v(" " + _vm._s(Math.round(_vm.stats.calories.taken)) + " / " + _vm._s(Math.round(_vm.stats.calories.total)) + " ")]) : _c('div', [_vm._v("...")]), _c('div', [_vm._v("calories")])])], 1), _c('TrackerNutritionButton', {
    staticClass: "p-0 text-white",
    attrs: {
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      },
      "params": {
        filters: {
          user_id: _vm.newItem.client ? _vm.newItem.client.id : null,
          date: _vm.$moment(_vm.date).format('YYYY-MM-DD')
        }
      },
      "curriculum": _vm.newItem,
      "water-recommended": _vm.stats ? _vm.stats.fluids.total : null
    },
    on: {
      "closed": _vm.fetchStats
    }
  }, [_vm._v(" + Add Meals & Supplements ")])], 1)], 1), _c('b-col', [_c('b-card', {
    attrs: {
      "bg-variant": "primary",
      "body-class": "pb-2"
    }
  }, [_c('h5', {
    staticClass: "font-size-16 text-uppercase mt-0 text-white-50"
  }, [_vm._v("Cardio")]), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col'), _c('b-col', {
    staticClass: "text-white font-size-22 font-weight-bold text-center",
    style: {
      lineHeight: 1.2
    }
  }, [_vm.stats ? _c('div', [_vm._v(" " + _vm._s(_vm.stats.cardios.completed_mins) + " / " + _vm._s(_vm.stats.cardios.total_mins) + " ")]) : _c('div', [_vm._v("...")]), _c('div', [_vm._v("minutes")])])], 1), _c('CurriculumCardioTrackerButton', {
    staticClass: "p-0 text-white",
    attrs: {
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      },
      "params": {
        filters: {
          curriculum_id: _vm.newItem.id,
          start_time_to: _vm.$moment(_vm.date).add(1, 'day').format('YYYY-MM-DD'),
          is_performed: false
        },
        order_by: 'start_time',
        order_desc: false
      }
    },
    on: {
      "submitted": _vm.fetchStats
    }
  }, [_vm._v(" + Add Cardio ")])], 1)], 1), _c('b-col', [_c('b-card', {
    attrs: {
      "bg-variant": "primary",
      "body-class": "pb-2"
    }
  }, [_c('h5', {
    staticClass: "font-size-16 text-uppercase mt-0 text-white-50"
  }, [_vm._v("Workouts")]), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col'), _c('b-col', {
    staticClass: "text-white font-size-22 font-weight-bold text-center",
    style: {
      lineHeight: 1.2
    }
  }, [_vm.stats ? _c('div', [_vm._v(" " + _vm._s(_vm.stats.workouts.completed) + " / " + _vm._s(_vm.stats.workouts.total) + " ")]) : _c('div', [_vm._v("...")]), _c('div', [_vm._v(" workouts ")])])], 1), _c('CurriculumWorkoutTrackerButton', {
    staticClass: "p-0 text-white",
    attrs: {
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      },
      "params": {
        filters: {
          curriculum_id: _vm.newItem.id,
          start_time_to: _vm.$moment().add(1, 'day').format('YYYY-MM-DD'),
          is_performed: false
        },
        order_by: 'start_time',
        order_desc: false
      }
    },
    on: {
      "submitted": _vm.fetchStats
    }
  }, [_vm._v(" + Add Workout ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }