var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-input-group', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [!_vm.unitDisabled ? _c('b-dropdown', _vm._b({
          attrs: {
            "text": _vm.suffix
          }
        }, 'b-dropdown', _vm.inputProps, false), _vm._l(_vm.unitOptions, function (unitOption) {
          return _c('b-dropdown-item', {
            key: unitOption.value,
            attrs: {
              "size": "sm"
            },
            on: {
              "click": function click($event) {
                _vm.newUnit = unitOption.value;
              }
            }
          }, [_vm._v(" " + _vm._s(_vm.getSuffix(unitOption.value)) + " ")]);
        }), 1) : _c('b-input-group-text', [_vm._v(" " + _vm._s(_vm.suffix) + " ")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', _vm._b({
    attrs: {
      "type": "number",
      "step": 1 / Math.pow(10, _vm.decimalPlaces),
      "formatter": function formatter(i) {
        return i ? parseInt(parseFloat(i) * Math.pow(10, _vm.decimalPlaces)) / Math.pow(10, _vm.decimalPlaces) : i;
      }
    },
    model: {
      value: _vm.unitValue,
      callback: function callback($$v) {
        _vm.unitValue = $$v;
      },
      expression: "unitValue"
    }
  }, 'b-form-input', _vm.inputProps, false))], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }