<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- PROFILE UPTODATE -->
      <b-form-group
        :invalid-feedback="getFieldInvalidFeedback($v.input.profile_uptodate)"
        :state="getFieldState($v.input.profile_uptodate)"
      >
        <b-form-checkbox
          v-model="input.profile_uptodate"
          switch
          size="lg"
          :state="getFieldState($v.input.profile_uptodate)"
        >
          Profile is updated
        </b-form-checkbox>
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixins from '@/mixins/crud-item'

export default {
  name: 'ClientProfileUptodateUpsert',

  mixins: [
    crudItemMixins
  ],

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  validations() {
    return {
      input: {
        profile_uptodate: {}
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        profile_uptodate: this.newItem.profile_uptodate
      }

      return input
    }
  }
}
</script>
