<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(gender)="row">
        <Genders :ids="[row.item.gender]" />
      </template>

      <template #cell(goal_direction)="row">
        <GoalDirections :ids="[row.item.goal_direction_id]" />
      </template>

      <template #cell(actions)="row">
        <NutrientItemUpsertButton
          :item="row.item"
          :style="{ cursor: 'pointer' }"
          @submitted="refresh"
        >
          <b-icon-gear />
        </NutrientItemUpsertButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import NutrientItemUpsertButton from './buttons/NutrientItemUpsertButton'
import Genders from '@/components/v1/asset/Genders'
import GoalDirections from '@/components/v1/asset/GoalDirections'

export default {
  name: 'NutrientList',

  mixins: [
    crudListMixin
  ],

  components: {
    NutrientItemUpsertButton,
    Genders,
    GoalDirections
  },

  data() {
    return {
      fields: [
        { key: 'phase_number', sortable: false },
        { key: 'goal_direction', sortable: false },
        { key: 'gender', sortable: false },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  },

  computed: {
    baseEndpoint() {
      return 'admin/nutrients'
    }
  }
}
</script>