<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <b-dropdown-item
      :to="`/company/${item.id}`"
    >
      Details
    </b-dropdown-item>

    <CompanyItemOwnersUpdateButton
      :item="item"
      tag="b-dropdown-item-button"
      @update:item="$emit('update:item', $event)"
      @submitted="$emit('submitted', $event)"
    >
      {{ item.owners.length ? 'Edit owners' : 'Add owners' }}
    </CompanyItemOwnersUpdateButton>
  </b-dropdown>
</template>

<script>
import CompanyItemOwnersUpdateButton from './CompanyItemOwnersUpdateButton'

export default {
  name: 'CompanyItemOwnersActionsButton',

  components: {
    CompanyItemOwnersUpdateButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  }
}
</script>