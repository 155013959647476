<template>
  <b-card class="client-summary-card">
    <template #header>
      <b-avatar
        class="mb-3"
        :src="item.client.profile_img_url"
        size="10rem"
      />

      <div class="client-summary-name">
        {{ item.client.first_name }} {{ item.client.last_name }}
      </div>

      <CurriculumItemClientNotesUpdateButton
        :item="item"
        tag="b-button"
        class="mt-3"
        prefetch
        :tag-props="{
          variant: 'primary',
          size: 'sm'
        }"
        @update:item="$emit('update:item', $event)"
      >
        Client Notes
      </CurriculumItemClientNotesUpdateButton>
      
      <CurriculumStatus
        :item="item"
        :style="{
          position: 'absolute',
          top: '10px',
          right: '10px'
        }"
      />
    </template>

    <div class="mb-4">
      <div class="mb-2 text-uppercase">
        Client info
      </div>

      <div><strong>Age</strong>: {{ age }} </div>
      <div><strong>Gender</strong>: <Genders :ids="[item.profile.sex]" /></div>
      <div><strong>Height</strong>: {{ item.physical_condition.height || '-' }}</div>
      <div><strong>Current Weight</strong>: {{ item.physical_condition.weight || '-' }}</div>
      <div><strong>Level</strong>: <Abilities :ids="[item.profile.fitness_level]" /></div>
    </div>

    <div class="mb-4">
      <div class="mb-2 text-uppercase">
        Client goals
      </div>

      <div><strong>Primary Goal</strong>: <GoalDirections :ids="[item.profile.goal_direction]" /></div>
      <div><strong>Goal Weight</strong>: {{ item.profile.goal_weight || '-' }}</div>
      <div><strong>Competition Weight</strong>: {{ item.profile.competition_weight || '-' }}</div>
      <div><strong>Term</strong>: {{ item.term }} weeks</div>
      <div>
        <strong>Major Working Points</strong>:
      </div>

      <div
        v-for="working_point in item.profile.working_points"
        :key="'working_point-' + working_point"
      >
        - <WorkingPoints :ids="[working_point]" />
      </div>

      <div><strong>Notes</strong>: {{ item.profile.fitness_notes || '-' }}</div>
    </div>

    <div
      v-if="isStarted"
      class="mb-4"
    >
      <div class="mb-2 text-uppercase">
        Progress Notes
      </div>

      <div
        v-for="phase in item.phases"
        :key="phase.number"
      >
        <div>
          <strong>Phase {{ phase.number }}</strong>
        </div>

        <div class="mb-2">
          <CurriculumPhaseStats
            v-if="$moment().startOf('day') >= $moment(phase.start_date)"
            :item="phase"
          />

          <div v-else>
            Not started
          </div>
        </div>
      </div>
    </div>

    <div class="mb-4">
      <div class="mb-2 text-uppercase">
        History
      </div>

      <div>
        <strong>History or currently experiencing</strong>:
      </div>

      <div
        v-for="history1_item in item.profile.history1"
        :key="'history1_item-' + history1_item"
      >
        - <History1 :ids="[history1_item]" />
      </div>

      <div v-if="item.profile.history1_notes">
        {{ item.profile.history1_notes }}
      </div>

      <div>
        <strong>History of pulls, strains, fractures, surgery, discomfort</strong>:
      </div>

      <div
        v-for="history2_item in item.profile.history2"
        :key="'history2_item-' + history2_item"
      >
        - <History2 :ids="[history2_item]" />
      </div>

      <div v-if="item.profile.history2_notes">
        {{ item.profile.history2_notes }}
      </div>

      <div><strong>Current Medication</strong>: {{ item.profile.medication_notes || '-' }}</div>

      <div><strong>Workout History</strong>: {{ item.profile.workout_history || '-' }}</div>
    </div>

    <div class="mb-4">
      <div class="mb-2 text-uppercase">
        Dietary restrictions/preferences
      </div>

      <div>
        <strong>Diets:</strong>
      </div>

      <div
        v-for="dietary_restriction in item.profile.dietary_restrictions"
        :key="'dietary_restriction-' + dietary_restriction"
      >
        - <DietaryRestrictions :ids="[dietary_restriction]" />
      </div>

      <div v-if="item.profile.dietary_restriction_notes">
        {{ item.profile.dietary_restriction_notes }}
      </div>

      <div v-if="item.profile.dietary_notes">
        {{ item.profile.dietary_notes }}
      </div>
    </div>

    <div class="mb-4">
      <div class="mb-2 text-uppercase">
        Contact info
      </div>

      <div v-if="item.client.address_line1">{{ item.client.address_line1 }}</div>
      <div v-if="item.client.address_line2">{{ item.client.address_line2 }}</div>
      <div>{{ item.client.city }}, {{ item.client.state }}, {{ item.client.zip }}</div>
      <div v-if="item.client.email">{{ item.client.email }}</div>
      <div v-if="item.client.phone">{{ item.client.phone }}</div>
      <div>Emergency Contact: {{ item.client.emergency_phone ? `${item.client.emergency_contact}, ${item.client.emergency_phone}` : '-' }}</div>
    </div>
  </b-card>
</template>

<script>
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import WorkingPoints from '@/components/v1/asset/WorkingPoints'
import History1 from '@/components/v1/asset/History1'
import History2 from '@/components/v1/asset/History2'
import DietaryRestrictions from '@/components/v1/asset/DietaryRestrictions'
import Genders from '@/components/v1/asset/Genders'
import CurriculumPhaseStats from './CurriculumPhaseStats'
import CurriculumItemClientNotesUpdateButton from './buttons/CurriculumItemClientNotesUpdateButton'
import CurriculumStatus from './CurriculumStatus'

export default {
  name: 'CurriculumItemProfileSummary',

  components: {
    Abilities,
    GoalDirections,
    WorkingPoints,
    History1,
    History2,
    DietaryRestrictions,
    Genders,
    CurriculumPhaseStats,
    CurriculumItemClientNotesUpdateButton,
    CurriculumStatus
  },

  props: {
    item: {
      type: Object,
      default: null
    }
  },

  computed: {
    age() {
      return this.$moment().diff(this.item.client.birthdate, 'years');
    },

    isStarted() {
      return this.$moment().startOf('day') >= this.$moment(this.item.start_date)
    }
  }
}
</script>

<style lang="scss" scoped>
  .client-summary-card {
    background: #d7dbf4;
    color: #000;

    .client-summary-edit-button {
      position: absolute;
      top: 0.75rem;
      right: 1.25rem;
    }

    .card-header {
      background: inherit;
      text-align: center;
      padding: 1.5rem;
    }

    .client-summary-name {
      font-weight: bold;
      font-size: 1.75rem;
    }
  }
</style>