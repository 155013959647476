<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-row
      v-for="(item, itemIndex) in newResult.data"
      :key="item.id"
      :class="{
        'mt-3': itemIndex !== 0
      }"
      align-v="center"
    >
      <b-col
        v-if="showTime"
        :style="{
          maxWidth: '90px'
        }"
      >
        <b-badge
          class="p-2"
          variant="success"
        >
          {{ $moment(item.taken_at, 'HH:mm:ss').format('hh:mm A') }}
        </b-badge>
      </b-col>

      <b-col>
        <CurriculumSupplementItemUpsertButton
          v-if="!disabled"
          :item="item"
          class="font-weight-bold p-0"
          tag="b-button"
          :tag-props="{
            variant: 'link'
          }"
          @submitted="$emit('submitted:item', $event); refresh()"
        >
          {{ item.supplement.name }}
        </CurriculumSupplementItemUpsertButton>

        <div v-else>
          {{ item.supplement.name }}
        </div>

        <div>{{ item.amount }} <SupplementDoseTypes :ids="[item.supplement.dose_type]" lower /></div>
      </b-col>

      <b-col
        v-if="!disabled"
        class="text-right w-50px"
      >
        <CurriculumSupplementItemDeleteButton
          :item="item"
          class="p-0"
          tag="b-button"
          :tag-props="{
            variant: 'link'
          }"
          @delete:item="$emit('delete:item', $event); refresh()"
        >
          <b-icon-trash scale="0.8" />
        </CurriculumSupplementItemDeleteButton>
      </b-col>
    </b-row>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import CurriculumSupplementItemUpsertButton from './buttons/CurriculumSupplementItemUpsertButton'
import CurriculumSupplementItemDeleteButton from './buttons/CurriculumSupplementItemDeleteButton'
import SupplementDoseTypes from '@/components/v1/asset/SupplementDoseTypes'

export default {
  name: 'CurriculumSupplementList',

  mixins: [
    crudListMixin
  ],

  components: {
    CurriculumSupplementItemUpsertButton,
    CurriculumSupplementItemDeleteButton,
    SupplementDoseTypes
  },

  props: {
    showTime: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    baseEndpoint() {
      return 'curriculum-supplements'
    }
  }
}
</script>