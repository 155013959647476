var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gender:"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getSexOptions']
    },
    model: {
      value: _vm.input.profile.sex,
      callback: function callback($$v) {
        _vm.$set(_vm.input.profile, "sex", $$v);
      },
      expression: "input.profile.sex"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Current Weight:"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": ""
    },
    model: {
      value: _vm.input.physical_condition.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.input.physical_condition, "weight", $$v);
      },
      expression: "input.physical_condition.weight"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Body Fat %:"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": ""
    },
    on: {
      "input": function input($event) {
        _vm.input.phases[0].projected_body_fat_p = $event;
      }
    },
    model: {
      value: _vm.input.physical_condition.total_body_fat_p,
      callback: function callback($$v) {
        _vm.$set(_vm.input.physical_condition, "total_body_fat_p", $$v);
      },
      expression: "input.physical_condition.total_body_fat_p"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Goal Direction:"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getGoalDirectionsOptions']
    },
    model: {
      value: _vm.input.profile.goal_direction,
      callback: function callback($$v) {
        _vm.$set(_vm.input.profile, "goal_direction", $$v);
      },
      expression: "input.profile.goal_direction"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Competition Weight:"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "disabled": _vm.input.profile.goal_direction !== 3
    },
    model: {
      value: _vm.input.profile.competition_weight,
      callback: function callback($$v) {
        _vm.$set(_vm.input.profile, "competition_weight", $$v);
      },
      expression: "input.profile.competition_weight"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Goal Weight:"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "disabled": _vm.input.profile.goal_direction === 3
    },
    model: {
      value: _vm.input.profile.goal_weight,
      callback: function callback($$v) {
        _vm.$set(_vm.input.profile, "goal_weight", $$v);
      },
      expression: "input.profile.goal_weight"
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "xs": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Weight to loss:"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.inputWeightToLoss,
      "disabled": ""
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "xs": "12",
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Term:"
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.input.term,
      "disabled": ""
    }
  })], 1)], 1)], 1), _c('b-form-group', {
    staticClass: "mb-5",
    attrs: {
      "label": "Projected fat % and activity level",
      "label-class": "text-uppercase font-size-24"
    }
  }, [_c('CurriculumPhasesAbilityInput', {
    attrs: {
      "curriculum": _vm.curriculum,
      "mode": "values"
    },
    model: {
      value: _vm.input.phases,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phases", $$v);
      },
      expression: "input.phases"
    }
  }), _c('CurriculumPhasesBodyFatInput', {
    attrs: {
      "curriculum": _vm.curriculum,
      "show-header": false,
      "mode": "values"
    },
    model: {
      value: _vm.input.phases,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phases", $$v);
      },
      expression: "input.phases"
    }
  })], 1), _c('b-button', {
    staticClass: "my-4",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "block": ""
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v(" Refresh ")]), !_vm.inputIsDirty ? [!_vm.calculateParamsAreValid ? _c('div', [_c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Enter all params to get suggestions. ")])], 1) : [_c('b-form-group', {
    staticClass: "mb-5",
    attrs: {
      "label": "Recommendations",
      "label-class": "text-uppercase font-size-24"
    }
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('h4', {
    staticClass: "text-primary mb-3"
  }, [_vm._v(" Cardio ")]), _c('CurriculumPhasesCardioInput', {
    staticClass: "mb-2",
    attrs: {
      "mode": "suggestions",
      "curriculum": _vm.curriculum
    }
  })], 1), _c('div', [_c('h4', {
    staticClass: "text-primary mb-3"
  }, [_vm._v(" Nutrients ")]), _c('CurriculumPhasesWaterInput', {
    attrs: {
      "mode": "suggestions",
      "curriculum": _vm.curriculum
    }
  }), _c('CurriculumPhasesCaloriesInput', {
    attrs: {
      "mode": "suggestions",
      "curriculum": _vm.curriculum,
      "show-header": false
    }
  })], 1)]), _c('b-form-group', {
    staticClass: "mb-6",
    attrs: {
      "label": "PROJECTIONS",
      "label-class": "text-uppercase font-size-24"
    }
  }, [_c('CurriculumPhasesWeightInput', {
    staticClass: "mb-2",
    attrs: {
      "curriculum": _vm.curriculum,
      "mode": "suggestions"
    }
  })], 1)]] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }