<template>
  <component
    :is="tag"
    :variant="variant"
    v-bind="tagProps"
  >
    {{ title }}
  </component>
</template>

<script>
export default {
  name: 'ClientStatus',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    status() {
      if (this.item.profile_uptodate) {
        return this.item.curriculum_count ? 'profiling_completed' : 'onboarding_completed'
      } else {
        return this.item.curriculum_count ? 'profiling' : 'onboarding'
      } 
    },

    title() {
      const statuses = {
        onboarding: 'Onboarding',
        onboarding_completed: 'Onboarding Completed',
        profiling: 'Profiling',
        profiling_completed: 'Profile Completed',
      }
      return statuses[this.status]
    },

    variant() {
      const variants = {
        profiling: 'warning',
        onboarding: 'warning',
        profiling_completed: 'success',
        onboarding_completed: 'success'
      }
      return variants[this.status]
    }
  }
}
</script>