<template>
  <b-overlay :show="isFetching">
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- PHASE NUMBER -->
      <b-form-group
        label="Phase number"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.phase_number)"
        :state="getFieldState($v.input.phase_number)"
      >
        <b-form-select
          v-model="input.phase_number"
          :options="[1, 2, 3, 4]"
          :disabled="isCreated"
          :state="getFieldState($v.input.phase_number)"
        />
      </b-form-group>

      <!-- GOAL DIRECTION -->
      <b-form-group
        label="Goal direction"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.goal_direction_id)"
        :state="getFieldState($v.input.goal_direction_id)"
      >
        <b-form-select
          v-model="input.goal_direction_id"
          :options="$store.getters['asset/getGoalDirectionsOptions']"
          :disabled="isCreated"
          :state="getFieldState($v.input.goal_direction_id)"
        />
      </b-form-group>

      <!-- GENDER -->
      <b-form-group
        label="Gender"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.gender)"
        :state="getFieldState($v.input.gender)"
      >
        <b-form-select
          v-model="input.gender"
          :options="$store.getters['asset/getSexOptions']"
          :disabled="isCreated"
          :state="getFieldState($v.input.gender)"
        />
      </b-form-group>

      <!-- CALORIES RATIO -->
      <b-form-group
        label="Calories ratio"
        :invalid-feedback="getFieldInvalidFeedback($v.input.calories_ratio)"
        :state="getFieldState($v.input.calories_ratio)"
      >
        <b-table-simple
          class="mb-0"
          fixed
          striped
          stacked="sm"
        >
          <b-thead>
            <b-tr variant="primary">
              <b-th class="w-120px" />
              <b-th class="text-center">Carbs</b-th>
              <b-th class="text-center">Fats</b-th>
              <b-th class="text-center">Protein</b-th>
              <b-th class="text-center">Totals</b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr>
              <b-td
                class="align-middle font-weight-bolder"
                variant="success"
              >
                Multiplier (cal)
              </b-td>

              <b-td class="text-center align-middle">
                <b-form-group
                  class="mb-0"
                  :invalid-feedback="getFieldInvalidFeedback($v.input.calories_ratio.carbs.multiplier)"
                  :state="getFieldState($v.input.calories_ratio.carbs.multiplier)"
                >
                  <b-form-input
                    v-model="input.calories_ratio.carbs.multiplier"
                    class="text-center hide-arrows"
                    type="number"
                    number
                    step="0.0001"
                    size="sm"
                    :state="getFieldState($v.input.calories_ratio.carbs.multiplier)"
                  />
                </b-form-group>
              </b-td>

              <b-td class="text-center align-middle">
                <b-form-group
                  class="mb-0"
                  :invalid-feedback="getFieldInvalidFeedback($v.input.calories_ratio.fats.multiplier)"
                  :state="getFieldState($v.input.calories_ratio.fats.multiplier)"
                >
                  <b-form-input
                    v-model="input.calories_ratio.fats.multiplier"
                    class="text-center hide-arrows"
                    type="number"
                    number
                    step="0.0001"
                    size="sm"
                    :state="getFieldState($v.input.calories_ratio.fats.multiplier)"
                  />
                </b-form-group>
              </b-td>

              <b-td class="text-center align-middle">
                <b-form-group
                  class="mb-0"
                  :invalid-feedback="getFieldInvalidFeedback($v.input.calories_ratio.protein.multiplier)"
                  :state="getFieldState($v.input.calories_ratio.protein.multiplier)"
                >
                  <b-form-input
                    v-model="input.calories_ratio.protein.multiplier"
                    class="text-center hide-arrows"
                    type="number"
                    number
                    step="0.0001"
                    size="sm"
                    :state="getFieldState($v.input.calories_ratio.protein.multiplier)"
                  />
                </b-form-group>
              </b-td>

              <b-td class="text-center align-middle">
                <b-form-group
                  class="mb-0"
                  :invalid-feedback="getFieldInvalidFeedback($v.caloriesRatioTotals.multiplier)"
                  :state="getFieldState($v.caloriesRatioTotals.multiplier)"
                >
                  <b-form-input
                    :value="caloriesRatioTotals.multiplier"
                    class="text-center"
                    disabled
                    size="sm"
                    :state="getFieldState($v.caloriesRatioTotals.multiplier)"
                  />
                </b-form-group>
              </b-td>
            </b-tr>

            <b-tr>
              <b-td
                class="align-middle font-weight-bolder"
                variant="success"
              >
                Multiplier (gm)
              </b-td>

              <b-td class="text-center align-middle">
                <b-form-group
                  class="mb-0"
                  :invalid-feedback="getFieldInvalidFeedback($v.input.calories_ratio.carbs.grams_multiplier)"
                  :state="getFieldState($v.input.calories_ratio.carbs.grams_multiplier)"
                >
                  <b-form-input
                    v-model="input.calories_ratio.carbs.grams_multiplier"
                    class="text-center hide-arrows"
                    type="number"
                    number
                    step="0.0001"
                    size="sm"
                    :state="getFieldState($v.input.calories_ratio.carbs.grams_multiplier)"
                  />
                </b-form-group>
              </b-td>

              <b-td class="text-center align-middle">
                <b-form-group
                  class="mb-0"
                  :invalid-feedback="getFieldInvalidFeedback($v.input.calories_ratio.fats.grams_multiplier)"
                  :state="getFieldState($v.input.calories_ratio.fats.grams_multiplier)"
                >
                  <b-form-input
                    v-model="input.calories_ratio.fats.grams_multiplier"
                    class="text-center hide-arrows"
                    type="number"
                    number
                    step="0.0001"
                    size="sm"
                    :state="getFieldState($v.input.calories_ratio.fats.grams_multiplier)"
                  />
                </b-form-group>
              </b-td>

              <b-td class="text-center align-middle">
                <b-form-group
                  class="mb-0"
                  :invalid-feedback="getFieldInvalidFeedback($v.input.calories_ratio.protein.grams_multiplier)"
                  :state="getFieldState($v.input.calories_ratio.protein.grams_multiplier)"
                >
                  <b-form-input
                    v-model="input.calories_ratio.protein.grams_multiplier"
                    class="text-center hide-arrows"
                    type="number"
                    number
                    step="0.0001"
                    size="sm"
                    :state="getFieldState($v.input.calories_ratio.protein.grams_multiplier)"
                  />
                </b-form-group>
              </b-td>

              <b-td class="text-center align-middle" />
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form-group>

      <!-- MEALS RATIO -->
      <b-form-group
        label="Meals ratio"
        :invalid-feedback="getFieldInvalidFeedback($v.input.meals_ratio)"
        :state="getFieldState($v.input.meals_ratio)"
      >
        <b-table-simple
          class="mb-0"
          fixed
          striped
          stacked="sm"
        >
          <b-thead>
            <b-tr variant="primary">
              <b-th class="w-70px" />

              <b-th class="text-center">
                <div class="mb-2">Carbs</div>

                <b-row no-gutters>
                  <b-col cols="6">7/d</b-col>
                  <b-col cols="3">6/d</b-col>
                  <b-col cols="3">5/d</b-col>
                </b-row>
              </b-th>

              <b-th class="text-center">
                <div class="mb-2">Fats</div>

                <b-row no-gutters>
                  <b-col cols="6">7/d</b-col>
                  <b-col cols="3">6/d</b-col>
                  <b-col cols="3">5/d</b-col>
                </b-row>
              </b-th>

              <b-th class="text-center">
                <div class="mb-2">Protein</div>

                <b-row no-gutters>
                  <b-col cols="6">7/d</b-col>
                  <b-col cols="3">6/d</b-col>
                  <b-col cols="3">5/d</b-col>
                </b-row>
              </b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr
              v-for="(meal, mealIndex) in input.meals_ratio"
              :key="mealIndex"
            >
              <b-td
                class="align-middle font-weight-bolder"
                variant="success"
              >
                Meal {{ mealIndex + 1 }}
              </b-td>

              <b-td class="text-center">
                <b-row align-v="center" no-gutters>
                  <b-col cols="6">
                    <b-form-input
                      v-model="meal.carbs"
                      class="text-center hide-arrows"
                      type="number"
                      number
                      step="0.0001"
                      size="sm"
                      :state="getFieldState($v.input.meals_ratio.$each[mealIndex].carbs)"
                    />
                  </b-col>

                  <b-col
                    cols="3"
                  >
                    {{ sixMealsRatio[mealIndex] ? Math.round(sixMealsRatio[mealIndex].carbs * 100) / 100 : '-' }}
                  </b-col>

                  <b-col
                    cols="3"
                  >
                    {{ fiveMealsRatio[mealIndex] ? Math.round(fiveMealsRatio[mealIndex].carbs * 100) / 100 : '-' }}
                  </b-col>
                </b-row>
              </b-td>

              <b-td class="text-center">
                <b-row align-v="center" no-gutters>
                  <b-col cols="6">
                    <b-form-input
                      v-model="meal.fats"
                      class="text-center hide-arrows"
                      type="number"
                      number
                      step="0.0001"
                      size="sm"
                      :state="getFieldState($v.input.meals_ratio.$each[mealIndex].fats)"
                    />
                  </b-col>

                  <b-col
                    cols="3"
                  >
                    {{ sixMealsRatio[mealIndex] ? Math.round(sixMealsRatio[mealIndex].fats * 100) / 100 : '-' }}
                  </b-col>

                  <b-col
                    cols="3"
                  >
                    {{ fiveMealsRatio[mealIndex] ? Math.round(fiveMealsRatio[mealIndex].fats * 100) / 100 : '-' }}
                  </b-col>
                </b-row>
              </b-td>

              <b-td class="text-center">
                <b-row
                  align-v="center"
                  no-gutters
                >
                  <b-col cols="6">
                    <b-form-input
                      v-model="meal.protein"
                      class="text-center hide-arrows"
                      type="number"
                      number
                      step="0.0001"
                      size="sm"
                      :state="getFieldState($v.input.meals_ratio.$each[mealIndex].protein)"
                    />
                  </b-col>

                  <b-col cols="3">
                    {{ sixMealsRatio[mealIndex] ? Math.round(sixMealsRatio[mealIndex].protein * 100) / 100 : '-' }}
                  </b-col>

                  <b-col cols="3">
                    {{ fiveMealsRatio[mealIndex] ? Math.round(fiveMealsRatio[mealIndex].protein * 100) / 100 : '-' }}
                  </b-col>
                </b-row>
              </b-td>
            </b-tr>

            <b-tr variant="secondary">
              <b-td class="align-middle font-weight-bolder">
                Totals
              </b-td>

              <b-th class="text-center align-middle">
                <b-row align-v="center" no-gutters>
                  <b-col cols="6">
                    <b-form-group
                      class="mb-0"
                      :invalid-feedback="getFieldInvalidFeedback($v.mealsRatioTotals.carbs)"
                      :state="getFieldState($v.mealsRatioTotals.carbs)"
                    >
                      <b-form-input
                        :value="mealsRatioTotals.carbs"
                        class="text-center"
                        disabled
                        size="sm"
                        :state="getFieldState($v.mealsRatioTotals.carbs)"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="3" />
                  <b-col cols="3" />
                </b-row>
              </b-th>

              <b-th class="text-center align-middle">
                <b-row align-v="center" no-gutters>
                  <b-col cols="6">
                    <b-form-group
                      class="mb-0"
                      :invalid-feedback="getFieldInvalidFeedback($v.mealsRatioTotals.fats)"
                      :state="getFieldState($v.mealsRatioTotals.fats)"
                    >
                      <b-form-input
                        :value="mealsRatioTotals.fats"
                        class="text-center"
                        disabled
                        size="sm"
                        :state="getFieldState($v.mealsRatioTotals.fats)"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="3" />
                  <b-col cols="3" />
                </b-row>
              </b-th>

              <b-th class="text-center align-middle">
                <b-row align-v="center" no-gutters>
                  <b-col cols="6">
                    <b-form-group
                      class="mb-0"
                      :invalid-feedback="getFieldInvalidFeedback($v.mealsRatioTotals.protein)"
                      :state="getFieldState($v.mealsRatioTotals.protein)"
                    >
                      <b-form-input
                        :value="mealsRatioTotals.protein"
                        class="text-center"
                        disabled
                        size="sm"
                        :state="getFieldState($v.mealsRatioTotals.protein)"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col cols="3" />
                  <b-col cols="3" />
                </b-row>
              </b-th>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required, minLength, maxLength, between } from "vuelidate/lib/validators"

export default {
  name: 'NutrientItemUpsert',

  mixins: [
    crudItemMixin
  ],

  computed: {
    baseEndpoint() {
      return 'admin/nutrients'
    },

    sixMealsRatio() {
      return this.getMealsRatio(this.input.meals_ratio, 6)
    },

    fiveMealsRatio() {
      return this.getMealsRatio(this.input.meals_ratio, 5)
    },

    mealsRatioTotals() {
      return this.getMealsRatioTotal(this.input.meals_ratio)
    },

    caloriesRatioTotals() {
      return {
        multiplier: _.chain(this.input.calories_ratio).map(i => i.multiplier).sum().round(4).value(),
        grams_multiplier: _.chain(this.input.calories_ratio).map(i => i.grams_multiplier).sum().round(4).value()
      }
    }
  },

  validations() {
    return {
      input: {
        phase_number: { required },
        goal_direction_id: { required },
        gender: { required },

        calories_ratio: {
          required,

          carbs: {
            multiplier: { required },
            grams_multiplier: { required }
          },

          fats: {
            multiplier: { required },
            grams_multiplier: { required }
          },

          protein: {
            multiplier: { required },
            grams_multiplier: { required }
          }
        },

        meals_ratio: {
          minLength: minLength(7),
          maxLength: maxLength(7),

          $each: {
            carbs: { required },
            fats: { required },
            protein: { required }
          }
        }
      },

      mealsRatioTotals: {
        carbs: { between: between(1, 1) },
        fats: { between: between(1, 1) },
        protein: { between: between(1, 1) }
      },

      caloriesRatioTotals: {
        multiplier: { between: between(1, 1) }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        phase_number: _.get(this.newItem, 'phase_number', null),
        goal_direction_id: _.get(this.newItem, 'goal_direction_id', null),
        gender: _.get(this.newItem, 'gender', null),
        meals_ratio: _.get(this.newItem, 'meals_ratio', []),
        calories_ratio: _.get(this.newItem, 'calories_ratio', {})
      }

      return input
    },

    getMealsRatioTotal(mealsRatio) {
      return {
        carbs: _.chain(mealsRatio).map(i => i.carbs).sum().round(4).value(),
        fats: _.chain(mealsRatio).map(i => i.fats).sum().round(4).value(),
        protein: _.chain(mealsRatio).map(i => i.protein).sum().round(4).value()
      }
    },

    getMealsRatio(mealsRatio, mealCount) {
      mealsRatio = _.slice(mealsRatio, 0, mealCount)
      const mealsRatioTotal = this.getMealsRatioTotal(mealsRatio)

      return _.map(mealsRatio, i => ({
        carbs: _.round(i.carbs / mealsRatioTotal.carbs, 4),
        fats: _.round(i.fats / mealsRatioTotal.fats, 4),
        protein: _.round(i.protein / mealsRatioTotal.protein, 4)
      }))
    }
  }
}
</script>
