<script>

import _ from 'lodash';
import moment from 'moment';
import Swal from 'sweetalert2';
import appConfig from '@/app.config';
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
import PerHundredGramsInput from '@/components/inputs/per-hundred-grams-input'
import GramsInput from '@/components/inputs/grams-input'
import ServingUnitInput from '@/components/inputs/serving-unit-input'
import {
  required,
  minLength,
  maxLength
} from 'vuelidate/lib/validators';

export default {
  name: 'OnboardingNutritionPage',
  components: { VueBootstrapTypeahead, PerHundredGramsInput, GramsInput, ServingUnitInput },
  computed: {
    clientId() {
      return parseInt(this.$route.params.id);
    },
    servingUnitOptions() {
      const servingUnitsOptions = [
        { value: null, text: 'Select units' },
        ...this.$store.getters['asset/getFoodServingUnitsOptions']
      ]

      if (!this.selectedFood && this.mealItemType !== 'supplement') {
        return servingUnitsOptions.filter(i => ['ounces', 'cups'].includes(i.value))
      }

      return servingUnitsOptions
    }
  },
  props: {
    step: {
      require: true
    },
    client: {
      type: Object,
      require: true
    }
  },
  data() {
    return {
      config: appConfig,
      moment: moment,
      isSaving: false,
      isLoading: false,
      mealItemType: 'food',
      selectedMeal: {},
      selectedMealFood: {},
      selectedMealSupplement: {},
      foods: [],
      selectedFood: null,
      selectedFoodSearch: '',
      typical_meals: [],
      foodSearch: null,
    };
  },
  validations() {
    const selectedMeal = {
      name: { required },
      taken_at: { required },
      type: { required }
    }

    const selectedMealFood = {
      typical_meal_id: { required },
      taken_amount: { required }
    }

    if (!this.selectedMealFood.food_id) {
      selectedMealFood.name = { required }
      selectedMealFood.serving_units = { required }

      if (
        !['ounces', 'grams'].includes(this.selectedMealFood.serving_units) && (
          this.selectedMealFood.calories ||
          this.selectedMealFood.protein ||
          this.selectedMealFood.carbs ||
          this.selectedMealFood.fat ||
          this.selectedMealFood.sodium
        )
      ) {
        selectedMealFood.serving_size = { required }
      }
    }

    const selectedMealSupplement = {
      taken_amount: { required },
      serving_units: { required },
      serving_size: { required },
      name: { required }
    }

    const client = {
      dietary_restriction_notes: {
        minLength: minLength(5),
        maxLength: maxLength(255),
      },
      dietary_notes: {
        minLength: minLength(5),
        maxLength: maxLength(255),
      }
    }

    return { selectedMeal, selectedMealFood, selectedMealSupplement, client }
  },
  methods: {
    loadStep() {
      if (!this.clientId) return;
      this.isLoading = true;
      this.$axios
        .get(`/users/${this.clientId}/typical_meals`)
        .then(({ data }) => {
          this.typical_meals = data.data;
        })
        .catch(() => {
          this.$store.commit('layout/addAlerts', {
            type: 'warning',
            text: 'Cannot load typical meals',
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    calcCalories(mealFood) {
      const calories = Math.round(mealFood.taken_amount * (mealFood.serving_size || 1) * mealFood.calories) / 100
      return Math.round(calories * 1) / 1
    },
    calcProtein(mealFood) {
      const protein = Math.round(mealFood.taken_amount * (mealFood.serving_size || 1) * mealFood.protein) / 100
      return Math.round(protein * 10) / 10
    },
    calcCarbs(mealFood) {
      const carbs = Math.round(mealFood.taken_amount * (mealFood.serving_size || 1) * mealFood.carbs) / 100;
      return Math.round(carbs * 10) / 10
    },
    calcFat(mealFood) {
      const fat = Math.round(mealFood.taken_amount * (mealFood.serving_size || 1) * mealFood.fat) / 100;
      return Math.round(fat * 10) / 10
    },
    calcSodium(mealFood) {
      const sodium = Math.round(mealFood.taken_amount * (mealFood.serving_size || 1) * mealFood.sodium) / 100;
      return Math.round(sodium * 10) / 10
    },
    mealTotalCalories(m) {
      return Math.round(m.foods.reduce((res, v) => res + this.calcCalories(v), 0) * 1) / 1
    },
    mealTotalProtein(m) {
      return Math.round(m.foods.reduce((res, v) => res + this.calcProtein(v), 0) * 10) / 10
    },
    mealTotalCarbs(m) {
      return Math.round(m.foods.reduce((res, v) => res + this.calcCarbs(v), 0) * 10) / 10
    },
    mealTotalFat(m) {
      return Math.round(m.foods.reduce((res, v) => res + this.calcFat(v), 0) * 10) / 10
    },
    mealTotalSodium(m) {
      return Math.round(m.foods.reduce((res, v) => res + this.calcSodium(v), 0) * 10) / 10
    },
    totalCalories() {
      return Math.round(this.typical_meals.reduce((res, v) => res + this.mealTotalCalories(v), 0) * 1) / 1
    },
    totalProtein() {
      return Math.round(this.typical_meals.reduce((res, v) => res + this.mealTotalProtein(v), 0) * 10) / 10
    },
    totalCarbs() {
      return Math.round(this.typical_meals.reduce((res, v) => res + this.mealTotalCarbs(v), 0) * 10) / 10
    },
    totalFat() {
      return Math.round(this.typical_meals.reduce((res, v) => res + this.mealTotalFat(v), 0) * 10) / 10
    },
    totalSodium() {
      return Math.round(this.typical_meals.reduce((res, v) => res + this.mealTotalSodium(v), 0) * 10) / 10
    },
    showMealModal(meal) {
      this.selectedMeal = meal ? _.cloneDeep(meal) : { type: null };
      if (this.selectedMeal.taken_at) {
        this.selectedMeal.taken_at = moment(this.selectedMeal.taken_at, 'HH:mm:ss').format('HH:mm');
      }
      this.$bvModal.show('meal-modal');
    },
    showMealItemModal(item, meal) {
      this.mealItemType = 'food';
      this.selectedMeal = _.cloneDeep(meal);
      this.selectedMeal.taken_at = moment(this.selectedMeal.taken_at, 'HH:mm:ss').format('HH:mm');
      // this.selectedMealFood = { typical_meal_id: meal.id, serving_units: 'ounces' };
      this.resetFood()
      this.setServingSize(this.selectedMealFood)
      this.selectedMealSupplement = { serving_units: null };
      this.selectedFood = null;
      if (item) {
        this.mealItemType = !item.food_id ? 'supplement' : 'food';
        if (this.mealItemType === 'food') {
          this.selectedMealFood = _.cloneDeep(item);
          this.selectedFood = Object.assign(
            { id: item.food_id },
            _.pick(item, [
              'name',
              'calories',
              'protein',
              'carbs',
              'fat',
              'sodium',
              'serving_units',
              'serving_size'
            ])
          );
        } else {
          this.selectedMealSupplement = item;
        }
      }
      this.$bvModal.show('meal-food-modal');
    },
    hideMealModal() {
      this.$v.selectedMeal.$reset();
      this.$bvModal.hide('meal-modal');
    },
    hideMealItemModal() {
      this.$v.selectedMealFood.$reset();
      this.$v.selectedMealSupplement.$reset();
      this.$bvModal.hide('meal-food-modal');
    },
    saveMeal() {
      this.$v.selectedMeal.$reset();
      this.$v.selectedMeal.$touch();

      if (this.$v.selectedMeal.$error) return;

      if (this.isSaving) return;

      this.isSaving = true;

      if (this.selectedMeal.id) {
        this.$axios
          .put(
            `/users/${this.clientId}/typical_meals/${this.selectedMeal.id}`,
            this.selectedMeal
          )
          .then(() => {
            this.loadStep();
            this.hideMealModal();
          })
          .catch(() => {
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Cannot save meal',
            });
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.$axios
          .post(
            `/users/${this.clientId}/typical_meals`,
            this.selectedMeal
          )
          .then(() => {
            this.loadStep();
            this.hideMealModal();
          })
          .catch(() => {
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Cannot save meal',
            });
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
    deleteMeal(meal) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(`users/${this.clientId}/typical_meals/${meal.id}`)
            .then(() => Swal.fire('Deleted!', 'Meal has been deleted.', 'success'))
            .catch(() => Swal.fire('Error Happened!', 'Meal was not deleted.', 'error'))
            .finally(() => this.loadStep());
        }
      });
    },
    deleteMealFood(food) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(`users/${this.clientId}/typical_meal_foods/${food.id}`)
            .then(() => Swal.fire('Deleted!', 'Item has been deleted.', 'success'))
            .catch(() => Swal.fire('Error Happened!', 'Item was not deleted.', 'error'))
            .finally(() => this.loadStep());
        }
      });
    },
    deleteMealSupplement(supplement, meal) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        const index = meal.supplements.findIndex((s) => s === supplement);
        if (index === -1) return;
        meal.supplements.splice(index, 1);
        // Update ids/indexes
        meal.supplements.forEach((s, i) => s.id = `S:${i + 1}`);
        const data = _.cloneDeep(meal);
        data.taken_at = moment(data.taken_at, 'HH:mm:ss').format('HH:mm');
        if (result.value) {
          this.$axios
            .put(`users/${this.clientId}/typical_meals/${meal.id}`, data)
            .then(() => Swal.fire('Deleted!', 'Item has been deleted.', 'success'))
            .catch(() => Swal.fire('Error Happened!', 'Item was not deleted.', 'error'))
            .finally(() => this.loadStep());
        }
      });
    },
    async createFood() {
      const data = _.pick(this.selectedMealFood, [
        'name',
        'calories',
        'protein',
        'carbs',
        'fat',
        'sodium',
        'serving_units',
        'serving_size'
      ]);
      await this.$axios
        .post('foods', data)
        .then(({ data }) => {
          this.selectedFood = data;
        })
        .catch(() => {
          this.$store.commit('layout/addAlerts', {
            type: 'warning',
            text: 'Cannot create food',
          });
        });
    },
    getServingUnit(value) {
      return _.chain(this.servingUnitOptions)
        .find({ value })
        .get('text')
        .value()
    },
    saveMealSupplement() {
      this.$v.selectedMealSupplement.$reset();
      this.$v.selectedMealSupplement.$touch();

      if (this.$v.selectedMealSupplement.$error) return;

      if (this.isSaving) return;

      this.isSaving = true;

      // Add supplement
      let removeOnFail = false;
      if (!this.selectedMealSupplement.id) {
        // Simulate ID to detect further updates
        this.selectedMealSupplement.id = `S:${this.selectedMeal.supplements.length + 1}`;
        this.selectedMeal.supplements = this.selectedMeal.supplements || [];
        this.selectedMeal.supplements.push(this.selectedMealSupplement);
        removeOnFail = true;
      } else {
        Object.assign(
          _.find(this.selectedMeal.supplements, { id: this.selectedMealSupplement.id }) || {},
          this.selectedMealSupplement
        );
      }

      this.$axios
        .put(
          `/users/${this.clientId}/typical_meals/${this.selectedMeal.id}`,
          this.selectedMeal
        )
        .then(() => {
          this.loadStep();
          this.hideMealItemModal();
        })
        .catch(() => {
          this.$store.commit('layout/addAlerts', {
            type: 'warning',
            text: 'Cannot save supplement',
          });
          // Remove supplement
          if (removeOnFail) {
            this.selectedMeal.supplements = this.selectedMeal.supplements.slice(0, -1);
          }
        })
        .finally(() => {
          this.isSaving = false;
        });
    },
    async saveMealFood() {
      if (this.mealItemType === 'supplement') {
        this.saveMealSupplement();
        return;
      }

      this.$v.selectedMealFood.$reset();
      this.$v.selectedMealFood.$touch();

      if (this.$v.selectedMealFood.$error) return;

      if (this.isSaving) return;

      this.isSaving = true;

      if (!this.selectedFood) {
        await this.createFood();
      }

      const data = Object.assign(
        { food_id: this.selectedFood.id },
        _.pick(this.selectedMealFood, ['typical_meal_id', 'taken_amount'])
      );

      if (this.selectedMealFood.id) {
        this.$axios
          .put(
            `/users/${this.clientId}/typical_meal_foods/${this.selectedMealFood.id}`,
            data
          )
          .then(() => {
            this.loadStep();
            this.hideMealItemModal();
          })
          .catch(() => {
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Cannot save food',
            });
          })
          .finally(() => {
            this.isSaving = false;
          });
      } else {
        this.$axios
          .post(
            `/users/${this.clientId}/typical_meal_foods`,
            data
          )
          .then(() => {
            this.loadStep();
            this.hideMealItemModal();
          })
          .catch(() => {
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Cannot save meal',
            });
          })
          .finally(() => {
            this.isSaving = false;
          });
      }
    },
    setSelectedFood(food) {
      this.selectedFood = food
      if (!food) return
      this.selectedMealFood = {
        ...this.selectedMealFood,
        ..._.pick(food, [
          'name',
          'calories',
          'protein',
          'carbs',
          'fat',
          'sodium',
          'serving_units',
          'serving_size']),
        food_id: food.id
      }
    },
    mealTypeOptions() {
      return [
        { value: null, text: 'Select meal type' },
        { value: 'main', text: 'Main' },
        { value: 'pre-workout', text: 'Pre-workout' },
        { value: 'post-workout', text: 'Post-workout' },
      ];
    },
    servingUnitsRequiresSize(serving_units) {
      return ['cups', 'scoops', 'tablets'].indexOf(serving_units) > -1;
    },
    setServingSize(item) {
      switch (item.serving_units) {
        case 'grams':
          item.serving_size = 1;
          break;
        case 'ounces':
          item.serving_size = 28.3495;
          break;
        default:
          item.serving_size = null;
          this.$forceUpdate();
      }
    },
    async loadFoods(search) {
      if (!search) {
        this.foods = [];
        return;
      }
      await this.$axios
        .get(`/foods?limit=10&page=1&search=${search}`)
        .then(({ data }) => {
          this.foods = data.data;
        });
    },
    resetFood() {
      // if (this.selectedFood) this.selectedFood = null;
      // if (this.selectedMealFood) {
      //   this.selectedMealFood = {
      //     typical_meal_id: this.selectedMealFood.typical_meal_id,
      //     serving_units: null
      //   }
      // }
      this.selectedFood = null

      this.selectedMealFood = {
        typical_meal_id: this.selectedMeal.id,
        name: null,
        food_id: null,
        calories: null,
        carbs: null,
        fat: null,
        protein: null,
        serving_size: null,
        serving_units: null,
        sodium: null,
        taken_amount: null
      }
    },
    dietaryOptions() {
      return [
        { value: 1, text: 'Vegan' },
        { value: 2, text: 'Vegetarian' },
        { value: 3, text: 'Celiac' },
        { value: 4, text: 'Diabetic' },
        { value: 5, text: 'Pescatarian' },
        { value: 6, text: 'Kosher' },
        { value: 7, text: 'Lactose Intolerant' },
        { value: 8, text: 'Food Allergies' },
        { value: 9, text: 'Halal' },
        { value: 10, text: 'Keto' },
      ];
    },
    toggleDietaryRestriction(value) {
      if (this.client.dietary_restrictions.indexOf(value) !== -1) {
        this.client.dietary_restrictions.splice(this.client.dietary_restrictions.indexOf(value), 1);
      } else {
        this.client.dietary_restrictions.push(value);
      }
    },
    prevStep() {
      this.$router.push(`/onboarding/${this.clientId}#step2`);
    },
    nextStep() {
      this.$v.client.$reset();
      this.$v.client.$touch();

      if (this.$v.client.$error) return;

      this.isLoading = true;
      this.$axios
        .put(`/users/${this.clientId}`, _.pick(this.client, [
          'dietary_restrictions',
          'dietary_restriction_notes',
          'dietary_notes'
        ]))
        .then(() => {
          this.$router.push(`/onboarding/${this.clientId}#step4`);
        })
        .catch(() => {
          this.$store.commit('layout/addAlerts', {
            type: 'warning',
            text: 'Cannot save data',
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
  watch: {
    selectedFoodSearch: _.debounce(function (search) {
      if (search === this.selectedMealFood.name) return;
      this.selectedMealFood.name = search;
      this.loadFoods(search);
    }, 500),
    selectedFood() {
      if (!this.selectedFood || this.selectedFoodSearch === this.selectedFood.name) return;
      this.selectedFoodSearch = this.selectedFood.name;
    },
    step: {
      immediate: true,
      handler() {
        if (this.step === 3) this.loadStep();
      }
    },
    client: {
      immediate: true,
      handler() {
        if (!this.client) return;
        this.client.dietary_restrictions = this.client.dietary_restrictions || [];
      }
    }
  }
}
</script>

<template>
  <div>
    <b-overlay
      :show="isLoading"
      :variant="config.overlay.variant"
      :opacity="config.overlay.opacity"
      :blur="config.overlay.blur"
      rounded="sm"
    >
      <div class="row mb-3">
        <div class="col">
          <h4 class="card-title">Client Meal Log</h4>
        </div>
        <div class="col text-right">
          <b-button variant="primary" @click.prevent="showMealModal()">
            <i class="fa fa-plus"></i> Add meal
          </b-button>
        </div>
      </div>

      <!-- Meal log start -->
      <div v-if="!typical_meals.length" class="mb-3">
        No meals added yet.
      </div>
      <div v-if="typical_meals.length">
        <div class="row no-gutters">
          <div class="col-lg-2 cell">&nbsp;</div>
          <div class="col row no-gutters">
            <div class="col-lg-4 cell cell-header">Item</div>
            <div class="col-lg-2 cell cell-header">Serving Size</div>
            <div class="col cell cell-header">Calories</div>
            <div class="col cell cell-header">Protein</div>
            <div class="col cell cell-header">Carbs</div>
            <div class="col cell cell-header">Fats</div>
            <div class="col cell cell-header">Sodium</div>
            <div class="col cell cell-header">&nbsp;</div>
          </div>
          <div class="col-lg-1 cell cell-header">&nbsp;</div>
        </div>
        <div class="row row-meal mb-1 no-gutters" v-for="m in typical_meals" :key="m.id">
          <div class="col-lg-2 cell col-header">
            <div>
              <p class="font-weight-bold mb-0">
                <a href="#" @click.prevent="showMealModal(m)">
                  {{ m.name }}
                  <b-badge class="align-middle" v-if="m.type === 'pre-workout'">
                    Pre Workout
                  </b-badge>
                  <b-badge class="align-middle" v-if="m.type === 'post-workout'">
                    Post Workout
                  </b-badge>
                </a>
              </p>
              {{ moment(m.taken_at, 'HH:mm:ss').format('hh:mm A') }}
            </div>
          </div>
          <div class="col d-flex flex-column">
            <div
              v-for="f in m.foods"
              class="row no-gutters row-food"
              :key="f.id"
            >
              <div class="col-lg-4 cell cell-food-name">
                <a href="#" @click.prevent="showMealItemModal(f, m)">{{ f.name }}</a>
              </div>
              <div class="col-lg-2 cell cell-base">
                {{ f.taken_amount }} {{ f.serving_units }}
              </div>
              <div class="col cell cell-base">
                {{ calcCalories(f) || '-' }}
              </div>
              <div class="col cell cell-base">
                {{ calcProtein(f) || '-' }} gm
              </div>
              <div class="col cell cell-base">
                {{ calcCarbs(f) || '-' }} gm
              </div>
              <div class="col cell cell-base">
                {{ calcFat(f) || '-' }} gm
              </div>
              <div class="col cell cell-base">
                {{ calcSodium(f) || '-' }} mg
              </div>
              <div class="col cell cell-base">
                <a href="#" @click.prevent="deleteMealFood(f)">
                  <i class="fa fa-trash-alt"></i>
                </a>
              </div>
            </div>
            <div
              v-for="s in m.supplements"
              class="row no-gutters row-food"
              :key="s.id"
            >
              <div class="col-lg-4 cell cell-food-name">
                <a href="#" @click.prevent="showMealItemModal(s, m)">{{ s.name }}</a>
              </div>
              <div class="col-lg-2 cell cell-base">
                {{ s.taken_amount }} {{ s.serving_units }}
              </div>
              <div class="col cell cell-base">-</div>
              <div class="col cell cell-base">-</div>
              <div class="col cell cell-base">-</div>
              <div class="col cell cell-base">-</div>
              <div class="col cell cell-base">-</div>
              <div class="col cell cell-base">
                <a href="#" @click.prevent="deleteMealSupplement(s, m)">
                  <i class="fa fa-trash-alt"></i>
                </a>
              </div>
            </div>
            <div
              class="row no-gutters row-food h-100"
            >
              <div class="col-lg-4 cell cell-food-button">
                <b-button
                  variant="primary"
                  size="sm"
                  @click="showMealItemModal(null, m)"
                >
                  <i class="fa fa-plus"></i> Add item
                </b-button>
              </div>
              <div class="col cell cell-base"></div>
            </div>
          </div>
          <div class="col-lg-1 cell cell-base d-flex justify-content-center align-items-center">
            <a href="#" @click.prevent= "deleteMeal(m)"><i class="fa fa-trash-alt fa-lg"></i></a>
          </div>
        </div>
        <div class="row no-gutters mb-3">
          <div class="col-lg-2 cell">&nbsp;</div>
          <div class="col row row-totals no-gutters">
            <div class="col-lg-4 cell cell-footer">Daily Totals</div>
            <div class="col-lg-2 cell cell-footer">&nbsp;</div>
            <div class="col cell cell-footer">{{ totalCalories() }}</div>
            <div class="col cell cell-footer">{{ totalProtein() }}</div>
            <div class="col cell cell-footer">{{ totalCarbs() }}</div>
            <div class="col cell cell-footer">{{ totalFat() }}</div>
            <div class="col cell cell-footer">{{ totalSodium() }}</div>
            <div class="col cell cell-footer">&nbsp;</div>
          </div>
          <div class="col-lg-1 cell cell-footer">&nbsp;</div>
        </div>
      </div>
      <!-- Meal log end -->

      <!-- Dietary form -->
      <b-form v-if="client">
        <b-form-group
          label="Diet selector (select all that apply):"
          label-for="dietary_restrictions"
        >
          <b-button
            class="mr-2 mb-1"
            v-for="item in dietaryOptions()"
            :key="item.value"
            :pressed="client.dietary_restrictions.indexOf(item.value) !== -1"
            :variant="client.dietary_restrictions.indexOf(item.value) !== -1 ? 'primary' : ''"
            @click="toggleDietaryRestriction(item.value)"
          >
            {{ item.text }}
          </b-button>
        </b-form-group>

        <b-form-group
          label="If dietary restriction applies, please provide more detail:"
          label-for="dietary_restriction_notes"
          v-if="client.dietary_restrictions.length"
        >
          <b-form-textarea
            v-model="client.dietary_restriction_notes"
            rows="5"
            class="form-control"
            :class="{
              'is-invalid': $v.client.dietary_restriction_notes.$error
            }"
          />
          <div
            v-if="$v.client.dietary_restriction_notes.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.client.dietary_restriction_notes.minLength">
              Minimum 5 letters.
            </span>
            <span v-if="!$v.client.dietary_restriction_notes.maxLength">
              Maximum 255 letters.
            </span>
          </div>
        </b-form-group>

        <b-form-group
          label="Notes:"
          label-for="dietary_notes"
        >
          <b-form-textarea
            v-model="client.dietary_notes"
            rows="5"
            class="form-control"
            :class="{
              'is-invalid': $v.client.dietary_notes.$error
            }"
          />
          <div
            v-if="$v.client.dietary_notes.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.client.dietary_notes.minLength">
              Minimum 5 letters.
            </span>
            <span v-if="!$v.client.dietary_notes.maxLength">
              Maximum 255 letters.
            </span>
          </div>
        </b-form-group>
      </b-form>

      <div class="row">
        <div class="col text-right">
          <b-button variant="secondary" @click="prevStep">Cancel</b-button>
          <b-button variant="primary" class="ml-1" @click="nextStep">Submit</b-button>
        </div>
      </div>
    </b-overlay>

    <!-- Add meal modal -->
    <b-modal
      id="meal-modal"
      content-class="rounded-0 border-0"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-3"
      @hidden="hideMealModal()"
    >
      <template #modal-title>
        {{ selectedMeal.id ? 'Edit' : 'Add' }} Meal
      </template>
      <form>
        <b-form-group label="Meal name" label-for="name" label-cols="3">
          <b-form-input
            v-model="selectedMeal.name"
            placeholder="Enter meal name"
            :class="{ 'is-invalid': $v.selectedMeal.name.$error }"
          ></b-form-input>
          <div v-if="$v.selectedMeal.name.$error" class="invalid-feedback">
            <span v-if="!$v.selectedMeal.name.required">
              This value is required.
            </span>
          </div>
        </b-form-group>
        <b-form-group label="Meal type" label-for="type" label-cols="3">
          <b-form-select
            v-model="selectedMeal.type"
            :options="mealTypeOptions()"
            :class="{ 'is-invalid': $v.selectedMeal.type.$error }"
          />
          <div v-if="$v.selectedMeal.type.$error" class="invalid-feedback">
            <span v-if="!$v.selectedMeal.type.required">
              This value is required.
            </span>
          </div>
        </b-form-group>
        <b-form-group
          label="Taken at"
          label-for="taken_at"
          label-cols="3"
          class="mb-0"
        >
          <b-form-input
            type="time"
            v-model="selectedMeal.taken_at"
            :class="{ 'is-invalid': $v.selectedMeal.taken_at.$error }"
          ></b-form-input>
          <div v-if="$v.selectedMeal.taken_at.$error" class="invalid-feedback">
            <span v-if="!$v.selectedMeal.taken_at.required">
              This value is required.
            </span>
          </div>
        </b-form-group>
      </form>
      <template #modal-footer>
        <b-button variant="secondary" @click="hideMealModal()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="saveMeal()">
          <b-spinner v-if="isSaving" small/> Save
        </b-button>
      </template>
    </b-modal>

    <!-- Add food modal -->
    <b-modal
      id="meal-food-modal"
      content-class="rounded-0 border-0"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-3"
      @hidden="hideMealItemModal()"
    >
      <template #modal-title>
        {{ selectedMealFood.id || selectedMealSupplement.id ? 'Edit' : 'Add' }} item
      </template>
      <b-tabs pills>
        <!-- Food form -->
        <b-tab
          title="Food"
          :active="mealItemType === 'food'"
          :disabled="!!selectedMealSupplement.id"
          @click="mealItemType = 'food'"
        >
          <form class="mt-3">
            <b-form-group label="Food" label-for="food_search" label-cols="3">
              <template #label>
                Food <span class="text-danger">*</span>
              </template>

              <vue-bootstrap-typeahead
                v-if="!selectedFood"
                v-model="selectedFoodSearch"
                :data="foods"
                :serializer="f => `${f.name} (${getServingUnit(f.serving_units)})`"
                placeholder="Type food name..."
                @hit="setSelectedFood($event)"
                :inputClass="$v.selectedMealFood.name?.$error ? 'is-invalid' : ''"
              />
              <b-input-group v-if="selectedFood">
                <template #append>
                  <b-input-group-text>
                    <a href="#" @click.prevent="resetFood()">
                      <i class="fa fa-times"></i>
                    </a>
                  </b-input-group-text>
                </template>
                <b-form-input
                  :value="`${selectedMealFood.name} (${getServingUnit(selectedMealFood.serving_units)})`"
                  disabled
                />
              </b-input-group>
              <b-form-input
                class="input-hidden"
                v-model="selectedMealFood.name"
                :class="{ 'is-invalid': $v.selectedMealFood.name?.$error }"
              />
              <div v-if="$v.selectedMealFood.name?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.name.required">
                  This value is required.
                </span>
              </div>
            </b-form-group>
            <b-form-group label="Calories" label-for="calories" label-cols="3">
              <PerHundredGramsInput
                v-model="selectedMealFood.calories"
                :input-props="{
                  placeholder: 'Enter Calories',
                  disabled: !!selectedFood
                }"
                unit="ounces"
                :decimal-places="0"
                unit-disabled
              />
              <div v-if="$v.selectedMealFood.calories?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.calories.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealFood.calories.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
            <b-form-group label="Protein (gm)" label-for="protein" label-cols="3">
              <PerHundredGramsInput
                v-model="selectedMealFood.protein"
                :input-props="{
                  placeholder: 'Enter Grams',
                  disabled: !!selectedFood
                }"
                unit="ounces"
                :decimal-places="1"
                unit-disabled
              />
              <div v-if="$v.selectedMealFood.protein?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.protein.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealFood.protein.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
            <b-form-group label="Carbs (gm)" label-for="carbs" label-cols="3">
              <PerHundredGramsInput
                v-model="selectedMealFood.carbs"
                :input-props="{
                  placeholder: 'Enter Grams',
                  disabled: !!selectedFood
                }"
                unit="ounces"
                :decimal-places="1"
                unit-disabled
              />
              <div v-if="$v.selectedMealFood.carbs?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.carbs.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealFood.carbs.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
            <b-form-group label="Fat (gm)" label-for="fat" label-cols="3">
              <PerHundredGramsInput
                v-model="selectedMealFood.fat"
                :input-props="{
                  placeholder: 'Enter Grams',
                  disabled: !!selectedFood
                }"
                unit="ounces"
                :decimal-places="1"
                unit-disabled
              />
              <div v-if="$v.selectedMealFood.fat?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.fat.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealFood.fat.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
            <b-form-group label="Sodium (mg)" label-for="sodium" label-cols="3">
              <PerHundredGramsInput
                v-model="selectedMealFood.sodium"
                :input-props="{
                  placeholder: 'Enter Milligrams',
                  disabled: !!selectedFood
                }"
                unit="ounces"
                :decimal-places="1"
                unit-disabled
              />
              <div v-if="$v.selectedMealFood.sodium?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.sodium.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealFood.sodium.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
            <b-form-group
              label="Serving units"
              label-for="serving_units"
              label-cols="3"
            >
              <template #label>
                Serving units <span class="text-danger">*</span>
              </template>

              <b-form-select
                v-model="selectedMealFood.serving_units"
                :options="servingUnitOptions"
                :class="{ 'is-invalid': $v.selectedMealFood.serving_units?.$error }"
                :disabled="!!selectedFood"
                @change="setServingSize(selectedMealFood)"
              />
              <div v-if="$v.selectedMealFood.serving_units?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.serving_units.required">
                  This value is required.
                </span>
              </div>
            </b-form-group>

            <b-form-group
              :label="selectedMealFood.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit'"
              label-for="serving_size"
              v-if="servingUnitsRequiresSize(selectedMealFood.serving_units)"
              label-cols="3"
            >
              <template v-if="$v.selectedMealFood.serving_size?.$params?.required" #label>
                {{ selectedMealFood.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit' }} <span class="text-danger">*</span>
              </template>

              <GramsInput
                v-model="selectedMealFood.serving_size"
                :input-props="{
                  disabled: !!selectedFood,
                  placeholder: selectedMealFood.serving_units === 'cups' ? 'Enter ounces per 1 Cup' : 'Enter ounces per 1 Unit',
                  class: { 'is-invalid': $v.selectedMealFood.serving_size?.$error }
                }"
                :decimal-places="1"
                unit="ounces"
              />

              <div v-if="$v.selectedMealFood.serving_size?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.serving_size.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealFood.serving_size.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
            <b-form-group
              class="mb-0"
              label="Taken amount"
              label-for="taken_amount"
              label-cols="3"
            >
              <template #label>
                Taken amount <span class="text-danger">*</span>
              </template>

              <b-form-input
                v-if="['grams', 'ounces', undefined, null].includes(selectedMealFood?.serving_units)"
                v-model="selectedMealFood.taken_amount"
                type="number"
                number
                :placeholder="`Enter taken amount in ${selectedMealFood?.serving_units || 'serving units'}`"
                :class="{ 'is-invalid': $v.selectedMealFood.taken_amount.$error }"
              />

              <ServingUnitInput
                v-else
                v-model="selectedMealFood.taken_amount"
                :unit-label="selectedMealFood?.serving_units"
              />

              <div v-if="$v.selectedMealFood.taken_amount.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealFood.taken_amount.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealFood.taken_amount.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
          </form>
        </b-tab>
        <!-- Supplement form -->
        <b-tab
          title="Supplement"
          :active="mealItemType === 'supplement'"
          :disabled="!!selectedMealFood.id"
          @click="mealItemType = 'supplement'"
        >
          <form class="mt-3">
            <b-form-group
              label="Name"
              label-for="name"
              label-cols="3"
            >
              <template #label>
                Name <span class="text-danger">*</span>
              </template>

              <b-form-input
                v-model="selectedMealSupplement.name"
                placeholder="Enter supplement name"
                :class="{ 'is-invalid': $v.selectedMealSupplement.name.$error }"
              />
              <div v-if="$v.selectedMealSupplement.name.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealSupplement.name.required">
                  This value is required.
                </span>
              </div>
            </b-form-group>
            <b-form-group
              label="Serving units"
              label-for="serving_units"
              label-cols="3"
            >
              <template #label>
                Serving units <span class="text-danger">*</span>
              </template>
              <b-form-select
                v-model="selectedMealSupplement.serving_units"
                :options="servingUnitOptions"
                :class="{ 'is-invalid': $v.selectedMealSupplement.serving_units.$error }"
                @change="setServingSize(selectedMealSupplement)"
              />
              <div v-if="$v.selectedMealSupplement.serving_units.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealSupplement.serving_units.required">
                  This value is required.
                </span>
              </div>
            </b-form-group>

            <b-form-group
              :label="selectedMealSupplement.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit'"
              label-for="serving_size"
              v-if="servingUnitsRequiresSize(selectedMealSupplement.serving_units)"
              label-cols="3"
            >
              <template v-if="$v.selectedMealSupplement.serving_size?.$params?.required" #label>
                {{ selectedMealSupplement.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit' }} <span class="text-danger">*</span>
              </template>

              <GramsInput
                v-model="selectedMealSupplement.serving_size"
                :input-props="{
                  placeholder: selectedMealSupplement.serving_units === 'cups' ? 'Enter ounces per 1 Cup' : 'Enter ounces per 1 Unit',
                  class: { 'is-invalid': $v.selectedMealSupplement.serving_size?.$error }
                }"
                :decimal-places="1"
                unit="ounces"
              />

              <div v-if="$v.selectedMealSupplement.serving_size?.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealSupplement.serving_size.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealSupplement.serving_size.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
            <b-form-group
              class="mb-0"
              label="Taken amount"
              label-for="taken_amount"
              label-cols="3"
            >
              <template #label>
                Taken amount <span class="text-danger">*</span>
              </template>

              <b-form-input
                v-if="['grams', 'ounces', undefined, null].includes(selectedMealSupplement?.serving_units)"
                v-model="selectedMealSupplement.taken_amount"
                type="number"
                number
                :placeholder="`Enter taken amount in ${selectedMealSupplement?.serving_units || 'serving units'}`"
                :class="{ 'is-invalid': $v.selectedMealSupplement.taken_amount.$error }"
              />

              <ServingUnitInput
                v-else
                v-model="selectedMealSupplement.taken_amount"
                :unit-label="selectedMealSupplement?.serving_units"
              />

              <div v-if="$v.selectedMealSupplement.taken_amount.$error" class="invalid-feedback">
                <span v-if="!$v.selectedMealSupplement.taken_amount.required">
                  This value is required.
                </span>
                <span v-if="!$v.selectedMealSupplement.taken_amount.decimal">
                  This value should be numeric.
                </span>
              </div>
            </b-form-group>
          </form>
        </b-tab>
      </b-tabs>
      <template #modal-footer>
        <b-button variant="secondary" @click="hideMealItemModal()">
          Cancel
        </b-button>
        <b-button variant="primary" @click="saveMealFood()">
          <b-spinner v-if="isSaving" small/> Save
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<style scoped>
  .col-header {
    background-color: #b2dada;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cell-header {
    background-color: #d7dbf4;
    text-align: center;
    font-weight: bold;
  }
  .cell-footer {
    background-color: #ccc;
    text-align: center;
    font-weight: bold;
  }
  .cell-footer:first-child {
    background-color: #d7dbf4;
  }
  .cell {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
  .cell-base {
    text-align: center;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cell-food-name {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    display: flex;
    align-items: center;
  }
  .cell-food-button {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
    display: flex;
    align-items: center;
  }
  .row-meal:nth-child(odd) .cell-food-name,
  .row-meal:nth-child(odd) .cell-food-button {
    background-color: #ccc;
  }
  .input-hidden {
    display: none !important;
  }
  .form-control:disabled {
    color: #6c757d;
    background-color: #e9ecef;
  }
  .input-group >>> .form-control:disabled  {
    border-right: none;
  }
</style>
