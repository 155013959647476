<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="showModal()"
  >
    <b-spinner v-if="isSubmitting" small /><slot />
  </component>
</template>

<script>
export default {
  name: 'CurriculumPhaseItemReleaseButton',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      isSubmitting: false
    }
  },

  methods: {
    activateItem() {
      const input = {}

      this.isSubmitting = true

      this.$axios
        .patch(`curriculum-phases/${this.item.id}/release`, input)
        .then(() => {
          this.isSubmitting = false
          this.$emit('update:item', this.item)
          this.$emit('submitted', this.item)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    showModal() {
      this.$bvModal.msgBoxConfirm(`Are you sure you want to release Phase ${this.item.number}?`, {
        title: 'Please Confirm',
        okTitle: 'Release',
        okVariant: 'success',
        centered: true
      })
        .then(value => {
          if (value) {
            this.activateItem()
          }
        })
    },
  }
}
</script>