<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="!isCreated || isFetched">
      <b-form @submit.prevent="submit('submitted')">
        <!-- PRIMARY TRAINER -->
        <b-form-group
          label="Primary trainer"
          label-cols="3"
          :invalid-feedback="getFieldInvalidFeedback($v.input.primary_trainer_id)"
          :state="getFieldState($v.input.primary_trainer_id)"
        >
          <UserSelect
            v-if="primaryTrainerIsEditable"
            v-model="input.primary_trainer_id"
            :item="newItem.primary_trainer"
            :show-create="false"
            :params="{
              filters: {
                role_ids: [2]
              }
            }"
            placeholder="Type a primary trainer name"
          />

          <UserSelect
            v-else
            :value="newItem.primary_trainer ? newItem.primary_trainer.id : null"
            :item="newItem.primary_trainer"
            disabled
            :params="{
              filters: {
                role_ids: [2]
              }
            }"
            placeholder="No primary trainer"
          />
        </b-form-group>

        <!-- SECONDARY TRAINER -->
        <b-form-group
          label="Secondary trainer"
          label-cols="3"
          :invalid-feedback="getFieldInvalidFeedback($v.input.secondary_trainer_id)"
          :state="getFieldState($v.input.secondary_trainer_id)"
        >
          <UserSelect
            v-if="secondaryTrainerIsEditable"
            v-model="input.secondary_trainer_id"
            :item="newItem.secondary_trainer"
            :show-create="false"
            :params="{
              filters: {
                role_ids: [2]
              }
            }"
            placeholder="Type a secondary trainer name"
          />

          <UserSelect
            v-else
            :value="newItem.secondary_trainer ? newItem.secondary_trainer.id : null"
            :item="newItem.secondary_trainer"
            disabled
            :params="{
              filters: {
                role_ids: [2]
              }
            }"
            placeholder="No secondary trainer"
          />
        </b-form-group>

        <!-- SUBMIT -->
        <b-form-group class="text-right mt-4">
          <b-button
            variant="primary"
            type="submit"
            :disabled="isSubmitting"
          >
            <b-spinner v-if="isSubmitting" small /> Submit
          </b-button>
        </b-form-group>
      </b-form>
    </template>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { required  } from "vuelidate/lib/validators"
import crudItemMixin from '@/mixins/crud-item'
import { mapGetters } from 'vuex'

export default {
  name: 'ClientTrainersUpdate',

  mixins: [
    crudItemMixin
  ],

  components: {
    UserSelect: () => import('@/components/users/UserSelect')
  },

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    primaryTrainerIsEditable() {
      return (
        this.$acl.check('couldEditClientsPrimaryTrainer') ||
        !this.isCreated && this.$acl.check('couldEditClientsPrimaryTrainerOnCreate') ||
        _.get(this.newItem.primary_trainer, 'id') === this.currentUser.id && this.$acl.check('couldEditClientsPrimaryTrainerIfIsPrimaryTrainer')
      )
    },

    secondaryTrainerIsEditable() {
      return (
        this.$acl.check('couldEditClientsSecondaryTrainer') ||
        !this.isCreated && this.$acl.check('couldEditClientsSecondaryTrainerOnCreate') ||
        _.get(this.newItem.primary_trainer, 'id') === this.currentUser.id && this.$acl.check('couldEditClientsSecondaryTrainerIfIsPrimaryTrainer')
      )
    }
  },

  validations() {
    const input = {
      primary_trainer_id: { required },
      secondary_trainer_id: {}
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        primary_trainer_id: _.get(this.newItem.primary_trainer, 'id'),
        secondary_trainer_id: _.get(this.newItem.secondary_trainer, 'id')
      }

      return input
    }
  }
}
</script>