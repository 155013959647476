<template>
  <component
    :is="tag"
    v-bind="buttonProps"
    @click="popupIsShown = true"
  >
    <slot />

    <b-modal
      v-model="popupIsShown"
      content-class="rounded-0 border-0"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-4"
      body-class="p-0"
      size="lg"
      hide-footer
    >
      <template #modal-title>
        <div
          v-if="workoutProgram"
          class="pt-5"
        >
          <h2 class="pt-5 mt-5">
            {{ workoutProgram.name }}
          </h2>

          <div>
            {{ workoutProgram.description }}
          </div>
        </div>
      </template>

      <b-overlay
        :show="isBusy"
        :variant="config.overlay.variant"
        :opacity="config.overlay.opacity"
        :blur="config.overlay.blur"
        rounded="sm"
      >
        <template v-if="workoutProgram">
          <b-card
            class="py-3 m-0"
            bg-variant="light"
          >
            <b-row>
              <b-col>
                <span class="font-weight-bold">
                  Fitness Level
                </span>: <Abilities :ids="workoutProgram.fitness_level_ids" />
              </b-col>

              <b-col>
                <span class="font-weight-bold">
                  Gender
                </span>: <Genders :ids="[workoutProgram.gender]" />
              </b-col>

              <b-col>
                <span class="font-weight-bold">
                  Goal Direction
                </span>: <GoalDirections :ids="[workoutProgram.goal_direction_id]" />
              </b-col>
            </b-row>
          </b-card>

          <b-card class="m-0">
            <h4 class="mb-4">
              {{ weeksCount }} Week Program - {{ phasesCount }} Phases
            </h4>

            <WorkoutProgramPhasesInput
              :value="workoutProgram.phases"
            />
          </b-card>
        </template>
      </b-overlay>
    </b-modal>
  </component>
</template>

<script>
import _ from 'lodash'
import appConfig from '@/app.config'
import WorkoutProgramPhasesInput from '@/components/inputs/workout-program-phases-input'
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import Genders from '@/components/v1/asset/Genders'

export default {
  name: 'WorkoutProgramDetailPopupButton',

  components: {
    WorkoutProgramPhasesInput,
    Abilities,
    GoalDirections,
    Genders
  },

  props: {
    id: {
      type: Number,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    buttonProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      config: appConfig,
      isBusy: false,
      popupIsShown: false,
      workoutProgram: null
    }
  },

  computed: {
    phasesCount() {
      return _.chain(this.workoutProgram)
        .get('phases')
        .size()
        .value()
    },

    weeksCount() {
      return _.chain(this.workoutProgram)
        .get('phases')
        .flatMap('weeks')
        .size()
        .value()
    }
  },

  watch: {
    popupIsShown(newValue) {
      if (newValue) {
        this.fetchWorkoutProgram()
      }
    }
  },

  methods: {
    fetchWorkoutProgram() {
      return this.$axios
        .get(`/workout-programs/${this.id}`, {
          parent: this,
        })
        .then(({ data }) => {
          this.workoutProgram = data
        })
    }
  }
}
</script>

<style lang="scss" scope>
.bg-tab {
  background: #ECECF1 !important;
}
</style>