<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>

      <b-col
        cols="4"
        class="d-flex align-items-center justify-content-end"
      >
        <b-button
          to="/onboarding"
          variant="primary"
        >
          Add Client
        </b-button>
      </b-col>
    </b-row>

    <b-card>
      <ClientList
        :params="{
          filters: {
            trainer_ids: trainers,
            role_ids: [1]
          },
          order_desc: true
        }"
        @update:params="trainers = $event.filters.trainer_ids"
      />
    </b-card>
  </Layout>
</template>

<script>
import _ from 'lodash'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import ClientList from '@/components/clients/ClientList'

export default {
  name: 'UserPage',

  components: {
    Layout,
    PageHeader,
    ClientList
  },

  page: {
    title: 'Clients'
  },

  computed: {
    title() {
      return 'Clients'
    },

    trainers: {
      get() {
        return this.$route.query.trainers ? (
          _.chain(this.$route.query.trainers)
            .split('.')
            .map(i => parseInt(i))
            .value()
        ) : null
      },

      set(value) {
        const query = _.cloneDeep(this.$route.query)

        if (value) {
          query.trainers = value.join('.')
        } else {
          _.unset(query, 'trainers')
        }

        if (!_.isEqual(query, this.$route.query)) {
          this.$router.push({ ...this.$route, query })
        }
      }
    }
  }
}
</script>