import { mapState, mapGetters, mapActions } from 'vuex'

export const authComputed = {
  ...mapGetters('auth', [
    'loggedIn',
    'currentUser',
    'currentRole',
    'currentCompany'
  ])
}

export const layoutComputed = {
  ...mapState('layout', {
    layoutType: (state) => state.layoutType,
    layoutWidth: (state) => state.layoutWidth,
  }),
}

export const companyComputed = {
  ...mapGetters('auth', [
    'currentCompany'
  ])
}

export const assetComputed = {
  ...mapState('asset', {
    primaryMuscles: (state) => state.primaryMuscles ? state.primaryMuscles : [],
    abilityLevels: (state) => state.abilityLevels ? state.abilityLevels : [],
    workoutTypes: (state) => state.workoutTypes ? state.workoutTypes : [],
    //fitnessLevels: (state) => state.fitnessLevels ? state.fitnessLevels : [],
    goalDirections: (state) => state.goalDirections ? state.goalDirections : [],
    sex: (state) => state.sex ? state.sex : [],
    primaryMusclesOptionsWithEmptyValue: (state) => {
      let options = state.primaryMuscles ? state.primaryMuscles.map((muscle) => {
        return {
          value: muscle.id,
          text: muscle.name
        }
      }) : []

      options.unshift({
        value: "",
        text: 'Primary Muscle',
      });

      return options;
    },
    abilityLevelsOptionsWithEmptyValue: (state) => {
      let options = state.abilityLevels ? state.abilityLevels.map((item) => {
        return {
          value: item.id,
          text: item.name
        }
      }) : [];

      options.unshift({
        value: "",
        text: 'Fitness Level',
      });

      return options;
    },
    workoutTypesOptionsWithEmptyValue: (state) => {
      let options = state.workoutTypes ? state.workoutTypes.map((item) => {
        return {
          value: item.id,
          text: item.name
        }
      }) : []

      options.unshift({
        value: "",
        text: 'Workout Type',
      });

      return options;
    },
    goalDirectionsOptionsWithEmptyValue: (state) => {
      let options = state.goalDirections ? state.goalDirections.map((item) => {
        return {
          value: item.id,
          text: item.name
        }
      }) : []

      options.unshift({
        value: "",
        text: 'Goal Direction',
      });

      return options;
    },
    sexOptionsWithEmptyValue: (state) => {
      let options = state.sex ? state.sex.map((item) => {
        return {
          value: item.id,
          text: item.name
        }
      }) : []

      options.unshift({
        value: "",
        text: 'Gender',
      });

      return options;
    },
    supplementTypesOptionsWithEmptyValue: (state) => {
      let options = state.supplementTypes ? state.supplementTypes.map((type) => {
        return {
          value: type.id,
          text: type.name
        }
      }) : []

      options.unshift({
        value: "",
        text: 'Type',
      });

      return options;
    },
    exerciseSubcategoriesOptions: (state) => {
      if (!state.exerciseSubcategories) return [];

      return state.exerciseSubcategories.map((item) => {
        return { value: item.id, text: item.name };
      });
    },
    supplementFormTypes: (state) => {
      return state.supplementFormTypes || [];
    },
    supplementFormTypesOptions: (state) => {
      if (!state.supplementFormTypes) return [];

      return state.supplementFormTypes.map((item) => {
        return { value: item.id, text: item.name };
      });
    },
    supplementFormTypesOptionsWithEmptyValue: (state) => {
      let options = [];
      if (!state.supplementFormTypes) return options;

      options = state.supplementFormTypes.map((item) => {
        return { value: item.id, text: item.name };
      });
      options.unshift({ value: '', text: 'Form' });
      return options;
    },
    supplementDoseTypes: (state) => {
      return state.supplementDoseTypes || [];
    },
    supplementDoseTypesOptions: (state) => {
      if (!state.supplementDoseTypes) return [];

      return state.supplementDoseTypes.map((item) => {
        return { value: item.id, text: item.name };
      });
    },
    supplementDoseTypesOptionsWithEmptyValue: (state) => {
      let options = [];
      if (!state.supplementDoseTypes) return options;

      options = state.supplementDoseTypes.map((item) => {
        return { value: item.id, text: item.name };
      });
      options.unshift({ value: '', text: 'Select' });
      return options;
    },
  }),
}

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword', 'setCurrentRole'])

export const layoutMethods = mapActions('layout', ['changeLayoutType', 'changeLayoutWidth'])

export const companyMethods = mapActions('company', ['setCurrentCompany'])
