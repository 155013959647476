<template>
  <div
    class="schedule-event-wrapper w-100 h-100 d-flex"
    :class="{
      'is-active': isActive
    }"
  >
    <div
      class="w-100 h-100 d-flex justify-content-center align-items-center text-white"
      :class="{
        'workout-event': event.type === 'workout',
        'cardio-event': event.type === 'cardio',
        'work-event': event.type === 'work',
        'sleep-event': event.type === 'sleep',
        'is-completed': event.is_performed,
        'is-missed': !event.is_performed && isMissed
      }"

    >
      <b-img
        v-if="event.type === 'workout'"
        :height="20"
        src="https://image.flaticon.com/icons/png/128/206/206305.png"
      />

      <b-img
        v-else-if="event.type === 'cardio'"
        :height="20"
        src="https://image.flaticon.com/icons/png/128/763/763965.png"
      />

      <b-img
        v-else-if="event.type === 'sleep'"
        :height="20"
        src="https://image.flaticon.com/icons/png/512/632/632339.png"
      />

      <b-img
        v-else-if="event.type === 'work'"
        :height="20"
        src="https://image.flaticon.com/icons/png/512/1063/1063299.png"
      />

      <template v-else>
        {{ title }}
      </template>

      <div class="actions-buttons">
        <slot
          name="actions"
          :event="event"
          :toggle-event-is-active="toggleEventIsActive"
          :delete-event="deleteEvent"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScheduleEvent',

  props: {
    event: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isActive: false
    }
  },

  computed: {
    title() {
      return this.event.workout ? this.event.workout.name.substring(0, 10) || `Workout ${this.event.workout.id}` : this.event.type
    },

    isMissed() {
      return this.$moment() > this.$moment(this.event.end_time)
    }
  },

  methods: {
    toggleEventIsActive(value) {
      this.isActive = value
    },

    deleteEvent() {
      this.$emit('delete')
    }
  }
}
</script>

<style lang="scss" scoped>
.schedule-event-wrapper {
  font-size: 8px;
  padding: 1.5px 0;
  z-index: 10;
  position: relative;

  &.is-active {
    z-index: 11;
  }

  .workout-event {
    background-color: #02a499 !important;
    text-transform: capitalize;

    &.is-completed {
      background-color: #CAF5F0 !important;
    }

    &.is-missed {
      background-color: #F9F8C9 !important;
      color: #000;
    }
  }
  .cardio-event {
    background-color: #626ed4 !important;
    text-transform: capitalize;

    &.is-completed {
      background-color: #D1D5F0 !important;
    }

    &.is-missed {
      background-color: #F9F8C9 !important;
      color: #000;
    }
  }
  .work-event {
    background-color: #6c757d !important;
    text-transform: capitalize;
  }
  .sleep-event {
    background-color: #333547 !important;
    text-transform: capitalize;
  }
  .actions-buttons {
    position: absolute;
    top: 0;
    right: 2px;
    z-index: 10;
    cursor: pointer;
  }
}
</style>