var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? [_c('b-card', {
    staticClass: "bg-transparent mt-3",
    attrs: {
      "no-body": ""
    }
  }, [_c('h4', {
    staticClass: "mb-1 text-weight-bold"
  }, [_vm._v("TODAY'S LOG")]), _c('div', {
    staticClass: "font-size-20 mb-3"
  }, [_vm._v(" " + _vm._s(_vm.$moment().format('M/D/Y')) + " ")]), _c('CurriculumItemStats', {
    attrs: {
      "item": _vm.item,
      "date": _vm.$moment().startOf('day').format('YYYY-MM-DD')
    }
  })], 1), _c('b-card', [_c('h4', {
    staticClass: "mb-3 text-secondary"
  }, [_vm._v("Curriculum Notes")]), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "bg-variant": "light"
    }
  }, [_c('CurriculumItemClientNotesUpdate', {
    attrs: {
      "item": _vm.newItem,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newItem = $event;
      }
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', [_c('b-row', {
    staticClass: "mb-3"
  }, [_c('b-col', [_c('h4', {
    staticClass: "mb-3 text-secondary"
  }, [_vm._v("Today's Schedule")])]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "to": "/schedule",
      "variant": "primary"
    }
  }, [_vm._v(" Full Schedule ")])], 1)], 1), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "bg-variant": "light"
    }
  }, [_c('CurriculumItemScheduleDayDetail', {
    attrs: {
      "date": _vm.$moment().toDate(),
      "curriculum": _vm.newItem
    }
  })], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-card', [_c('h4', {
    staticClass: "mb-3 text-secondary"
  }, [_vm._v("Progress")]), _c('b-card', {
    staticClass: "mb-0",
    attrs: {
      "bg-variant": "light"
    }
  }, [_c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0"
    }
  }, [_c('b-tab', {
    staticClass: "p-2 bg-tab",
    attrs: {
      "title-link-class": "rounded-0 border-0",
      "title": "Hide"
    }
  }, [_c('b-alert', {
    staticClass: "m-0",
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Progress is hidden. ")])], 1), _c('b-tab', {
    staticClass: "p-2 bg-tab",
    attrs: {
      "title-link-class": "rounded-0 border-0",
      "title": "Weight"
    }
  }, [_c('PhysicalConditionListChart', {
    attrs: {
      "mode": "weight",
      "params": {
        filters: {
          curriculum_id: _vm.newItem.id
        },
        order_by: 'taken_at'
      },
      "curriculum": _vm.newItem
    }
  })], 1), _c('b-tab', {
    staticClass: "p-2 bg-tab",
    attrs: {
      "title-link-class": "rounded-0 border-0",
      "title": "Body Mass %"
    }
  }, [_c('PhysicalConditionListChart', {
    attrs: {
      "mode": "total_body_fat_p",
      "params": {
        filters: {
          curriculum_id: _vm.newItem.id
        },
        order_by: 'taken_at'
      },
      "curriculum": _vm.newItem
    }
  })], 1)], 1)], 1)], 1)], 1)], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }