var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isValidParams ? [_c('ScheduleInput', {
    attrs: {
      "events": _vm.events,
      "bg-events": _vm.curriculum.client.schedule,
      "start-date": _vm.$moment(_vm.startDate).toDate(),
      "paused-at": _vm.curriculum.paused_at ? _vm.$moment(_vm.curriculum.paused_at).toDate() : null,
      "phase": _vm.phase
    },
    on: {
      "update:events": _vm.onUpdateEvents,
      "bg-event-click": function bgEventClick($event) {
        _vm.selectedSlot = $event;
      },
      "hour-click": function hourClick($event) {
        _vm.selectedSlot = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "event-actions",
      fn: function fn(_ref) {
        var event = _ref.event,
            toggleEventIsActive = _ref.toggleEventIsActive;
        return [event.type === 'cardio' ? _c('b-dropdown', {
          attrs: {
            "toggle-class": "p-0 text-white",
            "variant": "link",
            "size": "sm"
          },
          on: {
            "shown": function shown($event) {
              return toggleEventIsActive(true);
            },
            "hidden": function hidden($event) {
              return toggleEventIsActive(false);
            }
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('b-icon', {
                staticClass: "no-drag",
                attrs: {
                  "icon": "three-dots"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('CurriculumTrackerCardioItemUpsertButton', {
          attrs: {
            "item": event,
            "tag": "b-dropdown-item-button"
          },
          on: {
            "submitted": _vm.refresh
          }
        }, [_vm._v(" Cardio Details ")]), _vm.curriculum.status === 'active' || event.is_performed ? _c('TrackerCardioItemUpsertButton', {
          attrs: {
            "item": event,
            "tag": "b-dropdown-item-button"
          },
          on: {
            "submitted": _vm.refresh
          }
        }, [_vm._v(" Cardio Tracker ")]) : _vm._e(), _c('TrackerCardioItemDeleteButton', {
          attrs: {
            "item": event,
            "tag": "b-dropdown-item-button"
          },
          on: {
            "delete:item": _vm.refresh
          }
        }, [_vm._v(" Delete ")])], 1) : _vm._e()];
      }
    }], null, false, 3500758300)
  }), _c('b-modal', {
    attrs: {
      "content-class": "rounded-0 border-0",
      "header-bg-variant": "dark",
      "header-text-variant": "white",
      "header-class": "rounded-0 p-4",
      "body-class": "p-0",
      "hide-footer": "",
      "size": "lg"
    },
    model: {
      value: _vm.newItemModalIsShown,
      callback: function callback($$v) {
        _vm.newItemModalIsShown = $$v;
      },
      expression: "newItemModalIsShown"
    }
  }, [_c('b-card', [_vm.selectedSlot ? _c('CurriculumTrackerCardioItemUpsert', {
    attrs: {
      "item": _vm.itemToCreate
    },
    on: {
      "submitted": function submitted($event) {
        _vm.selectedSlot = null;

        _vm.refresh();
      }
    }
  }) : _vm._e()], 1)], 1)] : _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" CurriculumCardioListUpdate working only with filters between one week ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }