var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.formSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Gender",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.gender)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getSexOptions'],
      "disabled": !!_vm.input.id,
      "state": _vm.getFieldState(_vm.$v.input.gender)
    },
    model: {
      value: _vm.input.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "gender", $$v);
      },
      expression: "input.gender"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Activity Level",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.activity_level)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "disabled": !!_vm.input.id,
      "state": _vm.getFieldState(_vm.$v.input.activity_level)
    },
    model: {
      value: _vm.input.activity_level,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "activity_level", $$v);
      },
      expression: "input.activity_level"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Multiplier",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.multiplier)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.01",
      "state": _vm.getFieldState(_vm.$v.input.multiplier)
    },
    model: {
      value: _vm.input.multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "multiplier", $$v);
      },
      expression: "input.multiplier"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }