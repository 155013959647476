var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form-input', _vm._b({
    attrs: {
      "type": "number",
      "step": 1 / Math.pow(10, _vm.decimalPlaces),
      "formatter": function formatter(i) {
        return i ? parseInt(parseFloat(i) * Math.pow(10, 1)) / Math.pow(10, 1) : i;
      }
    },
    model: {
      value: _vm.unitValue,
      callback: function callback($$v) {
        _vm.unitValue = $$v;
      },
      expression: "unitValue"
    }
  }, 'b-form-input', _vm.inputProps, false));
}
var staticRenderFns = []

export { render, staticRenderFns }