import { render, staticRenderFns } from "./ExerciseGroupList.vue?vue&type=template&id=35e1766a&scoped=true&"
import script from "./ExerciseGroupList.vue?vue&type=script&lang=js&"
export * from "./ExerciseGroupList.vue?vue&type=script&lang=js&"
import style0 from "./ExerciseGroupList.vue?vue&type=style&index=0&id=35e1766a&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35e1766a",
  null
  
)

export default component.exports