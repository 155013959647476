var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "authentication-bg d-flex align-items-center pb-0 vh-100"
  }, [_c('div', {
    staticClass: "content-center w-100"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "card mo-mt-2"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row align-items-center"
  }, [_c('div', {
    staticClass: "col-lg-4 ml-auto"
  }, [_c('div', {
    staticClass: "ex-page-content"
  }, [_c('h1', {
    staticClass: "text-dark display-1 mt-4"
  }, [_vm._v("500")]), _c('h4', {
    staticClass: "mb-4"
  }, [_vm._v("Internal Server Error")]), _c('p', {
    staticClass: "mb-5"
  }, [_vm._v("It will be as simple as Occidental in fact, it will be Occidental to an English person")]), _c('router-link', {
    staticClass: "btn btn-primary mb-5",
    attrs: {
      "to": "/"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-home"
  }), _vm._v(" Back to Dashboard ")])], 1)]), _vm._m(0)])])])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-5 mx-auto"
  }, [_c('img', {
    staticClass: "img-fluid mx-auto d-block",
    attrs: {
      "src": require("@/assets/images/error.png"),
      "alt": ""
    }
  })]);
}]

export { render, staticRenderFns }