var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    style: {
      width: '250px'
    },
    attrs: {
      "align-v": "center",
      "align-h": "center"
    }
  }, [_c('b-col', [_c('TimeInput', {
    style: {
      fontSize: '43px'
    },
    attrs: {
      "value": _vm.value,
      "disabled": ""
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  })], 1), _c('b-col', [_vm.state === 'started' || _vm.state === 'paused' ? [_vm.state === 'started' ? _c('b-button', {
    staticClass: "py-0 font-size-12",
    attrs: {
      "variant": "warning",
      "block": "",
      "squared": "",
      "size": "sm",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.pause
    }
  }, [_vm._v(" Pause ")]) : _c('b-button', {
    staticClass: "py-0 font-size-12",
    attrs: {
      "variant": "success",
      "block": "",
      "squared": "",
      "size": "sm",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.start
    }
  }, [_vm._v(" Resume ")]), _c('b-button', {
    staticClass: "py-0 font-size-12",
    attrs: {
      "variant": "danger",
      "block": "",
      "squared": "",
      "size": "sm",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.stop
    }
  }, [_vm._v(" Stop ")])] : _vm.value ? _c('b-button', {
    staticClass: "font-size-14",
    style: {
      paddingTop: '13px',
      paddingBottom: '13px'
    },
    attrs: {
      "block": "",
      "squared": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.reset
    }
  }, [_vm._v(" Reset ")]) : _c('b-button', {
    staticClass: "font-size-14",
    style: {
      paddingTop: '13px',
      paddingBottom: '13px'
    },
    attrs: {
      "variant": "success",
      "block": "",
      "squared": "",
      "disabled": _vm.disabled
    },
    on: {
      "click": _vm.start
    }
  }, [_vm._v(" Start ")])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }