var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', [_c('CurriculumMealListTable', {
    ref: "meal-table",
    attrs: {
      "params": {
        filters: {
          phase_id: _vm.item.id,
          day_number: _vm.dayNumber
        },
        order_by: 'taken_at'
      },
      "result": _vm.mealResult,
      "curriculum": _vm.curriculum,
      "phase": _vm.item,
      "mode": "values",
      "disabled": ""
    },
    on: {
      "update:result": [function ($event) {
        _vm.mealResult = $event;
      }, function ($event) {
        return _vm.$emit('update:result-meals', $event);
      }]
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', [_c('CurriculumMealList', {
    ref: "meal-list",
    attrs: {
      "params": {
        filters: {
          phase_id: _vm.item.id,
          day_number: _vm.dayNumber
        },
        order_by: 'taken_at'
      },
      "result": _vm.mealResult,
      "supplement-result": _vm.supplementResult,
      "disabled": "",
      "foods-disabled": "",
      "supplements-disabled": ""
    },
    on: {
      "update:result": function updateResult($event) {
        _vm.mealResult = $event;
      }
    }
  })], 1)], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mb-4"
  }, [_c('b-col', [_c('b-card', [_c('b-row', {
    staticClass: "mb-3",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "font-size-16 font-weight-bold"
  }, [_vm._v(" Supplements ")])], 1), _c('CurriculumSupplementList', {
    ref: "supplement-list",
    attrs: {
      "params": {
        filters: {
          phase_id: _vm.item.id,
          day_number: _vm.dayNumber
        },
        order_by: 'taken_at'
      },
      "disabled": "",
      "result": _vm.supplementResult
    },
    on: {
      "update:result": function updateResult($event) {
        _vm.supplementResult = $event;
      }
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }