<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <ImageItemUpsertButton
      :item="item"
      :client="client"
      prefetch
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @update:item="$emit('update:item', $event)"
      @submitted="$emit('submitted', $event)"
    >
      Details
    </ImageItemUpsertButton>

    <ImageItemDeleteButton
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @delete:item="$emit('delete:item', $event)"
    >
      Delete
    </ImageItemDeleteButton>
  </b-dropdown>
</template>

<script>
import ImageItemUpsertButton from './ImageItemUpsertButton'
import ImageItemDeleteButton from './ImageItemDeleteButton'

export default {
  name: 'ImageItemActionsButton',

  components: {
    ImageItemUpsertButton,
    ImageItemDeleteButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    client: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  }
}
</script>