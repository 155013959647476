<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-row class="mb-2">
      <b-col
        xs="12"
        md="3"
      >
        <UserSelect
          v-model="newParams.filters.trainer_ids"
          placeholder="Select a trainer..."
          :show-create="false"
          multiple
          :multiple-limit="1"
          :params="{
            filters: {
              role_ids: [2]
            }
          }"
        />
      </b-col>
    </b-row>

    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(status)="row">
        <CurriculumStatus
          v-if="row.item.curriculum"
          :item="row.item.curriculum"
          tag="b-badge"
          class="px-2 py-2"
        />

        <ClientStatus
          v-else
          :item="row.item"
          tag="b-badge"
          class="px-2 py-2"
        />
      </template>

      <template #cell(name)="row">
        <b-avatar
          class="mr-2"
          :src="row.item.profile_img_url"
        />
        {{ row.item.first_name }} {{ row.item.last_name }}
      </template>

      <template #cell(phase)="row">
        <CurriculumPhaseNumber
          v-if="row.item.curriculum"
          :item="row.item.curriculum"
        />

        <template v-else>
          -
        </template>
      </template>

      <template #cell(days_remaining)="row">
        <CurriculumPhaseDaysRemaining
          v-if="row.item.curriculum"
          :item="row.item.curriculum"
        />

        <template v-else>
          -
        </template>
      </template>

      <template #cell(actions)="row">
        <ClientItemActionsButton
          v-if="getCouldActionItem(row.item)"
          :item="row.item"
          @delete:item="refresh"
          @submitted="refresh"
        >
          <b-icon-three-dots />
        </ClientItemActionsButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import ClientItemActionsButton from '@/components/clients/buttons/ClientItemActionsButton'
import CurriculumStatus from '@/components/curriculums/CurriculumStatus'
import CurriculumPhaseNumber from '@/components/curriculums/CurriculumPhaseNumber'
import CurriculumPhaseDaysRemaining from '@/components/curriculums/CurriculumPhaseDaysRemaining'
import ClientStatus from '@/components/clients/ClientStatus'
import UserSelect from '@/components/users/UserSelect'
import { mapGetters } from 'vuex'

export default {
  name: 'ClientList',

  mixins: [
    crudListMixin
  ],

  components: {
    ClientItemActionsButton,
    CurriculumStatus,
    CurriculumPhaseNumber,
    CurriculumPhaseDaysRemaining,
    ClientStatus,
    UserSelect
  },

  data() {
    return {
      baseEndpoint: 'users',

      fields: [
        { key: 'name', sortable: false },
        { key: 'status', sortable: false },
        { key: 'phase', sortable: false },
        { key: 'days_remaining', sortable: false },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },

  methods: {
    getCouldActionItem(item) {
      if (this.$acl.check('couldActionClient')) {
        return true
      }

      const isPrimaryTrainer = _.get(item, 'primary_trainer.id') === this.currentUser.id
      const isSecondaryTrainer = _.get(item, 'secondary_trainer.id') === this.currentUser.id
      const isTrainer = isPrimaryTrainer || isSecondaryTrainer

      if (this.$acl.check('couldActionClientIsTrainer') && isTrainer) {
        return true
      }

      return false
    }
  }
}
</script>