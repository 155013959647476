<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Name"
        label-cols="2"
        :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
        :state="getFieldState($v.input.name)"
      >
        <b-form-input
          v-model="input.name"
          :state="getFieldState($v.input.name)"
          placeholder="Enter Workout Program Name"
        />
      </b-form-group>

      <b-form-group
        v-if="isExpanded"
        label="Description"
        label-cols="2"
        :invalid-feedback="getFieldInvalidFeedback($v.input.description)"
        :state="getFieldState($v.input.description)"
      >
        <b-form-textarea
          v-model="input.description"
          :state="getFieldState($v.input.description)"
          placeholder="Enter Workout Program Description"
        />
      </b-form-group>

      <b-button
        class="px-0"
        variant="link"
        @click="isExpanded = !isExpanded"
      >
        {{ isExpanded ? 'Less' : 'More' }}
      </b-button>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />{{ actionName }}
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { requiredUnless } from "vuelidate/lib/validators"

export default {
  name: 'WorkoutProgramItemUpsert',

  mixins: [
    crudItemMixin
  ],

  props: {
    source: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'workout-programs',
      isExpanded: false
    }
  },

  computed: {
    actionName() {
      return this.isCreated ? 'Update' : this.source ? 'Copy' : 'Create'
    }
  },

  validations() {
    return {
      input: {
        name: { requiredUnless: requiredUnless('input.source_id') },
        description: { requiredUnless: requiredUnless('input.source_id') },
        source_id: {}
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        name: this.newItem.name,
        description: this.newItem.description,
        fitness_level_ids: this.newItem.fitness_level_ids,
      }

      if (!this.isCreated) {
        if (this.source) {
          input.source_id = this.source.id
          input.name = `${this.source.name} -- Copy`
          input.description = this.source.description
          input.fitness_level_ids = this.source.fitness_level_ids
        }
      }

      return input
    }
  }
}
</script>