<template>
  <b-overlay
    :show="isFetching"
  >
    <b-row>
      <b-col>
        <b-card
          bg-variant="primary"
          body-class="pb-2"
        >
          <h5 class="font-size-16 text-uppercase mt-0 text-white-50">Fluids</h5>

          <b-row class="mb-2">
            <b-col />

            <b-col
              class="text-white font-size-22 font-weight-bold text-center"
              :style="{
                lineHeight: 1.2
              }"
            >
              <div v-if="stats">
                {{ stats.fluids.taken }} / {{ stats.fluids.total }}
              </div>

              <div v-else>...</div>
              <div>ounces</div>
            </b-col>
          </b-row>

          <TrackerWaterButton
            class="p-0 text-white"
            tag="b-button"
            :tag-props="{
              variant: 'link'
            }"
            :params="{
              filters: {
                user_id: newItem.client ? newItem.client.id : null,
                date: $moment(date).format('YYYY-MM-DD')
              }
            }"
            :curriculum="newItem"
            :water-recommended="stats ? stats.fluids.total : null"
            @closed="fetchStats"
          >
            + Add Ounces
          </TrackerWaterButton>
        </b-card>
      </b-col>

      <b-col>
        <b-card
          bg-variant="primary"
          body-class="pb-2"
        >
          <h5 class="font-size-16 text-uppercase mt-0 text-white-50">Nutrients</h5>

          <b-row class="mb-2">
            <b-col />

            <b-col
              class="text-white font-size-22 font-weight-bold text-center"
              :style="{
                lineHeight: 1.2
              }"
            >
              <div v-if="stats">
                {{ Math.round(stats.calories.taken) }} / {{ Math.round(stats.calories.total) }}
              </div>

              <div v-else>...</div>
              <div>calories</div>
            </b-col>
          </b-row>

          <TrackerNutritionButton
            class="p-0 text-white"
            tag="b-button"
            :tag-props="{
              variant: 'link'
            }"
            :params="{
              filters: {
                user_id: newItem.client ? newItem.client.id : null,
                date: $moment(date).format('YYYY-MM-DD')
              }
            }"
            :curriculum="newItem"
            :water-recommended="stats ? stats.fluids.total : null"
            @closed="fetchStats"
          >
            + Add Meals & Supplements
          </TrackerNutritionButton>
        </b-card>
      </b-col>

      <b-col>
        <b-card
          bg-variant="primary"
          body-class="pb-2"
        >
          <h5 class="font-size-16 text-uppercase mt-0 text-white-50">Cardio</h5>

          <b-row class="mb-2">
            <b-col />

            <b-col
              class="text-white font-size-22 font-weight-bold text-center"
              :style="{
                lineHeight: 1.2
              }"
            >
              <div v-if="stats">
                {{ stats.cardios.completed_mins }} / {{ stats.cardios.total_mins }}
              </div>

              <div v-else>...</div>
              <div>minutes</div>
            </b-col>
          </b-row>

          <CurriculumCardioTrackerButton
            class="p-0 text-white"
            tag="b-button"
            :tag-props="{
              variant: 'link'
            }"
            :params="{
              filters: {
                curriculum_id: newItem.id,
                start_time_to: $moment(date).add(1, 'day').format('YYYY-MM-DD'),
                is_performed: false
              },
              order_by: 'start_time',
              order_desc: false
            }"
            @submitted="fetchStats"
          >
            + Add Cardio
          </CurriculumCardioTrackerButton>
        </b-card>
      </b-col>

      <b-col>
        <b-card
          bg-variant="primary"
          body-class="pb-2"
        >
          <h5 class="font-size-16 text-uppercase mt-0 text-white-50">Workouts</h5>

          <b-row class="mb-2">
            <b-col />

            <b-col
              class="text-white font-size-22 font-weight-bold text-center"
              :style="{
                lineHeight: 1.2
              }"
            >
              <div v-if="stats">
                {{ stats.workouts.completed }} / {{ stats.workouts.total }}
              </div>

              <div v-else>...</div>

              <div>
                workouts
              </div>
            </b-col>
          </b-row>

          <CurriculumWorkoutTrackerButton
            class="p-0 text-white"
            tag="b-button"
            :tag-props="{
              variant: 'link'
            }"
            :params="{
              filters: {
                curriculum_id: newItem.id,
                start_time_to: $moment().add(1, 'day').format('YYYY-MM-DD'),
                is_performed: false
              },
              order_by: 'start_time',
              order_desc: false
            }"
            @submitted="fetchStats"
          >
            + Add Workout
          </CurriculumWorkoutTrackerButton>
        </b-card>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import CurriculumWorkoutTrackerButton from '@/components/curriculums/workouts/buttons/CurriculumWorkoutTrackerButton'
import CurriculumCardioTrackerButton from '@/components/curriculums/cardios/buttons/CurriculumCardioTrackerButton'
import TrackerNutritionButton from '@/components/trackers/nutritions/buttons/TrackerNutritionButton'
import TrackerWaterButton from '@/components/trackers/nutritions/buttons/TrackerWaterButton'

export default {
  name: 'CurriculumItemStats',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumWorkoutTrackerButton,
    CurriculumCardioTrackerButton,
    TrackerNutritionButton,
    TrackerWaterButton
  },

  props: {
    date: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'curriculums',
      stats: null
    }
  },

  mounted() {
    this.fetchStats()
  },

  methods: {
    fetchStats() {
      const endpoint = `${this.baseEndpoint}/${this.newItem.id}/stats`
      this.isFetching = true

      return this.$axios
        .get(endpoint, {
          parent: this,
          params: {
            date: this.date
          }
        })
        .then(({ data }) => {
          this.isFetching = false
          this.stats = data
        })
        .catch((e) => {
          this.isFetching = false
          throw e
        })
    }
  }
}
</script>