var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [!_vm.couldEdit && _vm.isEdit ? _c('b-overlay', {
    staticClass: "py-4",
    attrs: {
      "show": _vm.programOverlay
    }
  }, [_vm.program.id ? [_c('WorkoutProgramItemDetail', {
    attrs: {
      "item": _vm.program,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.program = $event;
      }
    }
  }), _c('div', {
    staticClass: "text-right m-4"
  }, [_c('WorkoutProgramItemCopyButton', {
    attrs: {
      "source": _vm.program,
      "tag": "b-button",
      "tag-props": {
        variant: 'success'
      }
    },
    on: {
      "copied": function copied($event) {
        return _vm.$router.push("/workout-programs/".concat($event.id));
      }
    }
  }, [_vm._v(" Copy ")]), _c('b-button', {
    staticClass: "ml-2",
    attrs: {
      "to": "/workout-programs/list"
    }
  }, [_vm._v(" Back ")])], 1)] : _vm._e()], 2) : [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.gridOverlay,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card workout-table-card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "4"
    }
  }, [_c('b-input', {
    attrs: {
      "value": _vm.workouts.filter.search,
      "size": "sm",
      "placeholder": "Search workout..."
    },
    on: {
      "input": _vm.debounceSetFilterSearchParam
    }
  })], 1)], 1), _c('div', {
    staticClass: "table-responsive mb-0"
  }, [_c('b-table-simple', {
    staticClass: "workout-table",
    attrs: {
      "busy": _vm.gridOverlay
    }
  }, [_c('b-thead', [_c('div', [_c('b-tr', [_c('b-th', [_vm._v("Name")]), _c('b-th', [_vm._v("Fitness Level")]), _c('b-th', [_vm._v("Type")])], 1)], 1)]), _c('b-tbody', [_c('Draggable', {
    attrs: {
      "move": _vm.onMove,
      "group": {
        name: 'workouts',
        pull: 'clone',
        put: false
      },
      "list": _vm.workouts.items
    }
  }, _vm._l(this.workouts.items, function (workout) {
    return _c('b-tr', {
      key: workout.id,
      attrs: {
        "data-id": workout.id
      }
    }, [_c('b-td', [_vm._v(" " + _vm._s(workout.name) + " ")]), _c('b-td', [_c('Abilities', {
      attrs: {
        "ids": workout.fitness_level_ids || []
      }
    })], 1), _c('b-td', [_vm.getWorkoutTypeIcon(workout.type) ? _c('FaIcon', {
      key: workout.id,
      attrs: {
        "icon": _vm.getWorkoutTypeIcon(workout.type),
        "size": "17"
      }
    }) : _vm._e(), !_vm.getWorkoutTypeIcon(workout.type) ? _c('span', [_vm._v(" " + _vm._s(workout.type) + " ")]) : _vm._e()], 1)], 1);
  }), 1)], 1)], 1)], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6"
  }), _c('div', {
    staticClass: "col-sm-12 col-md-6"
  }, [_c('div', {
    staticClass: "dataTables_paginate paging_simple_numbers float-right"
  }, [_c('ul', {
    staticClass: "pagination pagination-rounded mt-3 mb-0"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.workouts.totalRows,
      "per-page": _vm.workouts.perPage
    },
    model: {
      value: _vm.workouts.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.workouts, "currentPage", $$v);
      },
      expression: "workouts.currentPage"
    }
  })], 1)])])])], 1)])])], 1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-collapse', {
    attrs: {
      "visible": !_vm.editEntityMode
    }
  }, [_c('b-row', {
    staticClass: "align-items-center"
  }, [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('h4', {
    staticClass: "mb-0 card-title"
  }, [_vm._v("Program - " + _vm._s(_vm.program.name))])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "edit"
    },
    on: {
      "click": _vm.onEditProgramEntity
    }
  }, [_vm._v(" Edit ")])])])], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": _vm.editEntityMode
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.programOverlay,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.programFormSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.program.name,
      expression: "program.name"
    }],
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.program.name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.program.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.program, "name", $event.target.value);
      }
    }
  }), _vm.formSubmitted && _vm.$v.program.name.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.program.name.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.program.name.minLength ? _c('span', [_vm._v(" This value is 5 letters minimum length. ")]) : _vm._e(), !_vm.$v.program.name.maxLength ? _c('span', [_vm._v(" This value is 200 letters maximum length. ")]) : _vm._e()]) : _vm._e()]), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Gender",
      "label-for": "gender"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "pt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.program.gender.$error
    },
    attrs: {
      "id": "gender",
      "disabled": "",
      "options": _vm.getSexOptions()
    },
    model: {
      value: _vm.program.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.program, "gender", $$v);
      },
      expression: "program.gender"
    }
  }), _vm.formSubmitted && _vm.$v.program.gender.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.program.gender.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Goal Direction",
      "label-for": "goal_direction_id"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "mt-2 mb-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.program.goal_direction_id.$error
    },
    attrs: {
      "id": "goal_direction",
      "disabled": "",
      "options": _vm.getGoalDirectionsOptions()
    },
    model: {
      value: _vm.program.goal_direction_id,
      callback: function callback($$v) {
        _vm.$set(_vm.program, "goal_direction_id", $$v);
      },
      expression: "program.goal_direction_id"
    }
  }), _vm.formSubmitted && _vm.$v.program.goal_direction_id.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.program.goal_direction_id.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Fitness Level(s)",
      "label-for": "ability-level"
    }
  }, [_c('b-form-checkbox-group', {
    staticClass: "mt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.program.fitness_level_ids.$error
    },
    attrs: {
      "id": "ability-level",
      "name": "ability-level",
      "disabled": ""
    }
  }, _vm._l(_vm.abilityLevels, function (level) {
    return _c('b-form-checkbox', {
      key: level.id,
      attrs: {
        "value": level.id
      },
      model: {
        value: _vm.program.fitness_level_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.program, "fitness_level_ids", $$v);
        },
        expression: "program.fitness_level_ids"
      }
    }, [_vm._v(" " + _vm._s(_vm.s(level.name).capitalize().value()) + " ")]);
  }), 1), _vm.formSubmitted && _vm.$v.program.fitness_level_ids.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.program.fitness_level_ids.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Workouts per Week",
      "label-for": "workouts_per_week"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "placeholder": "Workouts per Week",
      "disabled": ""
    },
    model: {
      value: _vm.program.workouts_per_week,
      callback: function callback($$v) {
        _vm.$set(_vm.program, "workouts_per_week", $$v);
      },
      expression: "program.workouts_per_week"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.program.description.$error
    },
    attrs: {
      "rows": "5",
      "placeholder": "Description"
    },
    model: {
      value: _vm.program.description,
      callback: function callback($$v) {
        _vm.$set(_vm.program, "description", $$v);
      },
      expression: "program.description"
    }
  }), _vm.formSubmitted && _vm.$v.program.description.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.program.description.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.program.description.maxLength ? _c('span', [_vm._v(" This value is 200 letters maximum length. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Publish",
      "label-for": "is_private"
    }
  }, [_c('FormInputButtons', {
    attrs: {
      "tag": "b-button-group",
      "options": [{
        value: false,
        text: 'Public'
      }, {
        value: true,
        text: 'Private'
      }]
    },
    model: {
      value: _vm.program.is_private,
      callback: function callback($$v) {
        _vm.$set(_vm.program, "is_private", $$v);
      },
      expression: "program.is_private"
    }
  })], 1), _c('div', {
    staticClass: "mt-3 bottom-button-container form-group"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_vm.isEdit ? _c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onFormCancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" Cancel ")]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary m-l-5 ml-1",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])])])], 1)])], 1)], 1)]), _c('b-collapse', {
    attrs: {
      "visible": !_vm.editEntityMode
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.phasesOverlay,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('b-tabs', {
    staticClass: "phases-tabs"
  }, _vm._l(_vm.program.phases, function (phase, phase_index) {
    return _c('b-tab', {
      key: phase.id,
      attrs: {
        "title": 'Phase ' + (phase_index + 1)
      }
    }, [_c('div', {
      staticClass: "card"
    }, [_c('div', {
      staticClass: "card-body"
    }, [_c('div', {
      staticClass: "phase-wrapper"
    }, _vm._l(phase.weeks, function (week, week_index) {
      return _c('div', {
        key: week.id,
        staticClass: "week-wrapper"
      }, [_c('div', {
        staticClass: "week-row title"
      }, [_c('div', {
        staticClass: "cell cell-name"
      }, [_vm._v(" Week " + _vm._s(week_index + 1) + " ")]), _vm.forecast.phases ? _c('div', {
        staticClass: "cell cell-name text-right"
      }, [_c('span', [_vm._v(" Rest: " + _vm._s(_vm.forecast.phases[phase_index].weeks[week_index].rest) + " ")]), _c('span', [_vm._v(" Reps: " + _vm._s(_vm.getWeekReps(_vm.forecast.phases[phase_index].weeks[week_index]).join(', ')) + " ")])]) : _vm._e()]), _c('Draggable', {
        attrs: {
          "group": "workouts",
          "list": week.workouts
        },
        on: {
          "update": _vm.onWorkoutOrder
        }
      }, _vm._l(week.workouts, function (workout, workout_index) {
        return _c('div', {
          key: workout_index,
          staticClass: "week-row week-content"
        }, [_c('div', {
          staticClass: "cell cell-number align-self-center"
        }, [_vm._v(" " + _vm._s(workout_index + 1) + " ")]), _c('div', {
          staticClass: "cell cell-name align-self-center"
        }, [_vm._v(" " + _vm._s(workout.name) + " ")]), _c('div', {
          staticClass: "cell cell-muscles align-self-center"
        }, [_vm._v(" where muscles come from? ")]), _c('div', {
          staticClass: "cell cell-ability-level align-self-center"
        }, [_c('Abilities', {
          attrs: {
            "ids": workout.fitness_level_ids || []
          }
        })], 1), _c('div', {
          staticClass: "cell cell-delete align-self-center"
        }, [_c('a', {
          attrs: {
            "title": "Delete workout"
          },
          on: {
            "click": function click($event) {
              return _vm.onWorkoutDelete(workout, week);
            }
          }
        }, [_c('FaIcon', {
          attrs: {
            "icon": "far fa-trash-alt",
            "size": "17"
          }
        })], 1)])]);
      }), 0), _c('div', {
        directives: [{
          name: "show",
          rawName: "v-show",
          value: week.workouts.length < _vm.program.workouts_per_week,
          expression: "\n                            week.workouts.length < program.workouts_per_week\n                          "
        }],
        staticClass: "week-row week-content"
      }, [_c('Draggable', {
        attrs: {
          "group": "workouts",
          "data-phase-id": phase.id,
          "data-week-id": week.id,
          "list": week.workouts
        },
        on: {
          "add": _vm.onWorkoutAdd
        }
      }, [_c('div', {
        staticClass: "cell cell-add-workout text-center"
      }, [_vm._v(" + Drop workout to add ")])])], 1)], 1);
    }), 0)])])]);
  }), 1)], 1), _c('div', {
    staticClass: "mb-3 mt-3 bottom-button-container"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "to": "/workout-programs/list",
      "variant": "success"
    }
  }, [_vm._v(" Finish ")])], 1)])], 1)], 1)])]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }