var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Fitness goals and general notes:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.fitness_notes),
      "state": _vm.getFieldState(_vm.$v.input.fitness_notes)
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "5",
      "state": _vm.getFieldState(_vm.$v.input.fitness_notes)
    },
    model: {
      value: _vm.input.fitness_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "fitness_notes", $$v);
      },
      expression: "input.fitness_notes"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Do you have a history or currently experiencing (select all that apply):",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.history1),
      "state": _vm.getFieldState(_vm.$v.input.history1)
    }
  }, [_c('FormInputButtons', {
    attrs: {
      "multiple": "",
      "button-class": "mr-2 mb-1",
      "options": _vm.$store.getters['asset/getHistory1Options']
    },
    model: {
      value: _vm.input.history1,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "history1", $$v);
      },
      expression: "input.history1"
    }
  })], 1), _vm.input.history1 && _vm.input.history1.length ? _c('b-form-group', {
    attrs: {
      "label": "If yes to any of the above, please provide details:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.history1_notes),
      "state": _vm.getFieldState(_vm.$v.input.history1_notes)
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "5",
      "state": _vm.getFieldState(_vm.$v.input.history1_notes)
    },
    model: {
      value: _vm.input.history1_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "history1_notes", $$v);
      },
      expression: "input.history1_notes"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "History of pulls, strains, fractures, surgery, discomfort (select all that apply):",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.history2),
      "state": _vm.getFieldState(_vm.$v.input.history2)
    }
  }, [_c('FormInputButtons', {
    attrs: {
      "multiple": "",
      "button-class": "mr-2 mb-1",
      "options": _vm.$store.getters['asset/getHistory2Options']
    },
    model: {
      value: _vm.input.history2,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "history2", $$v);
      },
      expression: "input.history2"
    }
  })], 1), _vm.input.history2 && _vm.input.history2.length ? _c('b-form-group', {
    attrs: {
      "label": "Pulls, strains, fractures, surgery, and discomfort details:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.history2_notes),
      "state": _vm.getFieldState(_vm.$v.input.history2_notes)
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "5",
      "state": _vm.getFieldState(_vm.$v.input.history2_notes)
    },
    model: {
      value: _vm.input.history2_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "history2_notes", $$v);
      },
      expression: "input.history2_notes"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Current medications (over-the-counter and prescribed, dosages, times administered, and experienced side effects):",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.medication_notes),
      "state": _vm.getFieldState(_vm.$v.input.medication_notes)
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "5",
      "state": _vm.getFieldState(_vm.$v.input.medication_notes)
    },
    model: {
      value: _vm.input.medication_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "medication_notes", $$v);
      },
      expression: "input.medication_notes"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Major working points (select all that apply):",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.working_points),
      "state": _vm.getFieldState(_vm.$v.input.working_points)
    }
  }, [_c('FormInputButtons', {
    attrs: {
      "multiple": "",
      "button-class": "mr-2 mb-1",
      "options": _vm.$store.getters['asset/getWorkingPointsOptions']
    },
    model: {
      value: _vm.input.working_points,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "working_points", $$v);
      },
      expression: "input.working_points"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "What is your Somatotype?",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.somatotype),
      "state": _vm.getFieldState(_vm.$v.input.somatotype)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getSomatotypesOptions'],
      "state": _vm.getFieldState(_vm.$v.input.somatotype)
    },
    model: {
      value: _vm.input.somatotype,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "somatotype", $$v);
      },
      expression: "input.somatotype"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Fitness goal:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.goal_direction),
      "state": _vm.getFieldState(_vm.$v.input.goal_direction)
    }
  }, [_c('FormInputButtons', {
    attrs: {
      "tag": "b-button-group",
      "options": _vm.$store.getters['asset/getGoalDirectionsOptions']
    },
    model: {
      value: _vm.input.goal_direction,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "goal_direction", $$v);
      },
      expression: "input.goal_direction"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Goal Body Mass:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.goal_body_mass),
      "state": _vm.getFieldState(_vm.$v.input.goal_body_mass)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.goal_body_mass)
    },
    model: {
      value: _vm.input.goal_body_mass,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "goal_body_mass", $$v);
      },
      expression: "input.goal_body_mass"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Goal Body Fat (%):",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.goal_body_fat_p),
      "state": _vm.getFieldState(_vm.$v.input.goal_body_fat_p)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.goal_body_fat_p)
    },
    model: {
      value: _vm.input.goal_body_fat_p,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "goal_body_fat_p", $$v);
      },
      expression: "input.goal_body_fat_p"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Goal Weight:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.goal_weight),
      "state": _vm.getFieldState(_vm.$v.input.goal_weight)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.goal_weight)
    },
    model: {
      value: _vm.input.goal_weight,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "goal_weight", $$v);
      },
      expression: "input.goal_weight"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Competition Weight:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.competition_weight),
      "state": _vm.getFieldState(_vm.$v.input.competition_weight)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.competition_weight)
    },
    model: {
      value: _vm.input.competition_weight,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "competition_weight", $$v);
      },
      expression: "input.competition_weight"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Workout history:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.workout_history),
      "state": _vm.getFieldState(_vm.$v.input.workout_history)
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "rows": "5",
      "state": _vm.getFieldState(_vm.$v.input.workout_history)
    },
    model: {
      value: _vm.input.workout_history,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "workout_history", $$v);
      },
      expression: "input.workout_history"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Fitness level:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.fitness_level),
      "state": _vm.getFieldState(_vm.$v.input.fitness_level)
    }
  }, [_c('FormInputButtons', {
    attrs: {
      "tag": "b-button-group",
      "options": _vm.$store.getters['asset/getAbilityLevelsOptions']
    },
    model: {
      value: _vm.input.fitness_level,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "fitness_level", $$v);
      },
      expression: "input.fitness_level"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Workouts per Week:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.workouts_per_week),
      "state": _vm.getFieldState(_vm.$v.input.workouts_per_week)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.workouts_per_week)
    },
    model: {
      value: _vm.input.workouts_per_week,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "workouts_per_week", $$v);
      },
      expression: "input.workouts_per_week"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Cardio Sessions per Week:",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.cardio_sessions_per_week),
      "state": _vm.getFieldState(_vm.$v.input.cardio_sessions_per_week)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.cardio_sessions_per_week)
    },
    model: {
      value: _vm.input.cardio_sessions_per_week,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "cardio_sessions_per_week", $$v);
      },
      expression: "input.cardio_sessions_per_week"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }