<template>
  <div>
    <div
      class="accountbg"
      :style="{
        background: 'url(' + require('@/assets/images/bg.jpg') + ')',
        'background-size': 'cover',
        'background-position': 'center',
      }"
    ></div>
    <div
      class="wrapper-page account-page-full"
      style="width: 100%; max-width: 420px"
    >
      <div class="card shadow-none">
        <div class="card-block">
          <div class="account-box">
            <div class="card-box shadow-none p-4">
              <div class="p-2">
                <div class="text-center mt-4">
                  <router-link tag="a" to="/">
                    <img
                      src="@/assets/images/logo.png"
                      height="175"
                      alt="logo"
                    />
                  </router-link>
                </div>

                <h4 class="font-size-18 mt-5 text-center">Welcome Back !</h4>
                <p class="text-muted text-center">
                  Sign in to continue to USA Elite.
                </p>

                <form
                  class="mt-4"
                  @submit="
                    (e) => {
                      e.preventDefault();
                    }
                  "
                >
                  <div class="form-group">
                    <label for="username">Email</label>
                    <input
                      v-model="email"
                      type="text"
                      class="form-control"
                      id="email"
                      placeholder="Enter email"
                    />
                    <p
                      v-if="errors.email && touch.password"
                      class="input-error"
                    >
                      {{ errors.email }}
                    </p>
                  </div>

                  <div class="form-group">
                    <label for="userpassword">Password</label>
                    <input
                      v-model="password"
                      type="password"
                      class="form-control"
                      id="userpassword"
                      placeholder="Enter password"
                    />
                    <p
                      v-if="errors.password && touch.password"
                      class="input-error"
                    >
                      {{ errors.password }}
                    </p>
                  </div>

                  <div class="form-group row">
                    <div class="col" style="display: flex; align-items: center">
                      <div class="custom-control custom-checkbox">
                        <input
                          v-model="remember"
                          type="checkbox"
                          class="custom-control-input"
                          id="customControlInline"
                        />
                        <label
                          class="custom-control-label"
                          for="customControlInline"
                          >Remember me</label
                        >
                      </div>
                    </div>
                    <div class="col text-right">
                      <button
                        class="btn btn-primary w-md"
                        :disabled="send"
                        @click="tryToLogIn"
                      >
                        Log In
                      </button>
                    </div>
                  </div>

                  <div class="form-group mt-2 mb-0 row">
                    <div class="col-12 mt-3">
                      <router-link tag="a" to="/password-reset">
                        <i class="mdi mdi-lock"></i> Forgot your password?
                      </router-link>
                    </div>
                  </div>
                </form>

                <!-- <div class="mt-5 pt-4 text-center">
                  <p>
                    Don't have an account ?
                    <router-link
                      tag="a"
                      to="/pages/register-2"
                      class="font-weight-medium text-primary"
                    >Signup now</router-link>
                  </p>
                  <p>
                    ©
                    {{new Date().getFullYear()}}
                    Veltrix. Crafted with
                    <i
                      class="mdi mdi-heart text-danger"
                    ></i> by Themesbrand
                  </p>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { validationEmail, validationPassword } from "@/plugins/validation";
import { authMethods } from "@/state/helpers";

export default {
  page: {
    title: "Login",
    meta: [{ name: "description", content: appConfig.description }],
  },
  mounted() {
    const log = window.localStorage.getItem("emailUSAelit");
    const pass = window.localStorage.getItem("passwordUSAelit");
    if (log && pass) {
      this.email = log;
      this.password = pass;
    }
  },
  data() {
    return {
      email: "",
      password: "",
      errors: {
        email: "",
        password: "",
      },
      touch: {
        email: false,
        password: false,
      },
      remember: true,
      send: false,
    };
  },
  watch: {
    email() {
      this.checkValid();
    },
    password() {
      this.checkValid();
    },
  },
  computed: {},
  methods: {
    ...authMethods,
    checkValid() {
      this.errors = {
        email: validationEmail(this.email),
        password: validationPassword(this.password),
      };
      return Object.values(this.errors).findIndex((item) => !!item) === -1;
    },
    tryToLogIn() {
      this.touch = {
        email: true,
        password: true,
      };

      if (this.checkValid()) {
        this.$store.commit("layout/setLoader", true);
        this.send = true;

        this.logIn({
          email: this.email,
          password: this.password,
          remember: this.remember,
          app: this
        })
          // eslint-disable-next-line no-unused-vars
          .then((user) => {
            this.$store.commit("layout/setLoader", false);
            this.send = false;
            this.$router.push({ path: "/" });
            this.afterLogin();
          })
          // eslint-disable-next-line no-unused-vars
          .catch(( response ) => {
            this.$store.commit("layout/setLoader", false);
            this.send = false;

            response &&
              response.data &&
              response.data.message &&
              this.$store.commit("layout/addAlerts", {
                type: "warning",
                text: response.data.message,
              });

            response &&
              response.data &&
              response.data.errors &&
              response.data.errors.map((item) => {
                this.errors = {
                  ...this.errors,
                  [item.source.pointer]: item.detail,
                };
              });
          });
      }
    },
  },
};
</script>

<style scoped>
.input-error {
  color: #fd397a;
  font-size: 12px;
  font-weight: 500;
  margin: 5px;
}
</style>
