<template>
  <b-overlay :show="isFetching">
    <b-table-simple
      class="mb-0"
      fixed
    >
      <b-thead v-if="showHeader">
        <b-tr variant="primary">
          <b-th
            class="w-130px bg-transparent"
          />

          <b-th
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center"
           colspan="2"
          >
            Phase {{ phase.number }}
          </b-th>
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr class="bg-white">
          <b-th
            class="w-130px align-middle"
            variant="success"
          >
            <div class="py-1 text-nowrap text-center">
              Activity Level
            </div>
          </b-th>

          <b-td
           v-for="phase in curriculum.phases"
           :key="phase.number"
           class="text-center"
           colspan="2"
          >
            <b-row
              align-v="center"
              class="text-center"
            >
              <b-col
                v-if="showSuggestions"
                class="mb-2"
                cols="12"
              >
                <template v-if='!suggestedValue[phase.number - 1]'>...</template>
                
                <Abilities
                  v-else-if="suggestedValue[phase.number - 1].projected_fitness_level_id"
                  :ids="[suggestedValue[phase.number - 1].projected_fitness_level_id]"
                  class="text-secondary font-size-12"
                />

                <template v-else>-</template>
              </b-col>

              <b-col v-if="showValues && newValue">
                <b-form-select
                  v-if="!disabled"
                  v-model="newValue[phase.number - 1].projected_fitness_level_id"
                  :options="$store.getters['asset/getAbilityLevelsOptions']"
                />

                <template v-else>
                  <Abilities
                    :ids="[newValue[phase.number - 1].projected_fitness_level_id || '-']"
                  />
                </template>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import formMixin from '@/mixins/form'
import Abilities from '@/components/v1/asset/Abilities'

export default {
  name: 'CurriculumPhasesAbilityInput',

  mixins: [
    formMixin
  ],

  components: {
    Abilities
  },

  props: {
    curriculum: {
      type: Object,
      required: true
    },

    mode: {
      type: String,
      default: null
    },

    showHeader: {
      type: Boolean,
      default: true
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFetching: false,
      suggestions: null,
      autosave: true
    }
  },

  computed: {
    suggestedValue() {
      return _.map(this.suggestions, i => ({
        projected_fitness_level_id: i.activity_level
      }))
    },

    showValues() {
      return !this.mode || this.mode === 'values'
    },

    showSuggestions() {
      return !this.mode || this.mode === 'suggestions'
    },

    calculateParams() {
      const calculateTerm = Math.max(parseInt(this.curriculum.term), 16)

      return {
        current_weight: this.curriculum.physical_condition.weight,
        gender: this.curriculum.profile.sex,
        weight_loss_goal: this.curriculum.profile.goal_weight,
        weeks_count: calculateTerm,
        goal_direction: this.curriculum.profile.goal_direction,
        competition_weight: this.curriculum.profile.competition_weight,
        fitness_level_ids: _.map(this.curriculum.phases, i => i.projected_fitness_level_id),
        body_fat_p_values: _.map(this.curriculum.phases, i => i.projected_body_fat_p)
      }
    },

    weightLossGoal() {
      return this.calculateParams.goal_direction === 3 ? this.calculateParams.competition_weight : this.calculateParams.weight_loss_goal
    },

    calculateParamsAreValid() {
      return (
        this.calculateParams.current_weight &&
        this.calculateParams.gender &&
        this.calculateParams.goal_direction &&
        this.calculateParams.weeks_count &&
        this.weightLossGoal &&
        !_.chain(this.calculateParams.fitness_level_ids).filter(i => _.isNil(i)).value().length &&
        !_.chain(this.calculateParams.body_fat_p_values).filter(i => _.isNil(i)).value().length
      )
    }
  },

  watch: {
    calculateParams: {
      immediate: true,
      deep: true,

      handler() {
        if ((!this.mode || this.mode === 'suggestions') && this.calculateParamsAreValid) {
          this.fetchSuggestions()
        }
      }
    },

    suggestedValue: {
      immediate: true,
      deep: true,

      handler() {
        this.newValue = _.map(this.newValue, (phase, phaseIndex) => ({
          ...phase,
          projected_fitness_level_id: _.get(phase, 'projected_fitness_level_id', null) || _.get(this.suggestedValue, `${phaseIndex}.projected_fitness_level_id`, null)
        }))
      }
    },

    calculateParamsAreValid: {
      immediate: true,
      
      handler(newValue, oldValue) {
        if (!newValue && newValue !== oldValue) {
          this.suggestions = null
        }
      }
    }
  },

  methods: {
    fetchSuggestions() {
      this.isFetching = true

      return this.$axios
        .post('/forecast/phase/side/calculate', this.calculateParams)
        .then(({ data }) => {
          this.isFetching = false
          this.suggestions = data.data
        })
        .catch((error) => {
          this.isFetching = false
          throw error
        })
    }
  }
}
</script>