var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.newValue.items, function (note, noteIndex) {
    return _c('b-form-group', {
      key: noteIndex,
      staticClass: "my-2",
      attrs: {
        "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.newValue.items.$each[noteIndex]),
        "state": _vm.getFieldState(_vm.$v.newValue.items.$each[noteIndex])
      }
    }, [_c('b-collapse', {
      attrs: {
        "visible": noteIndex !== _vm.activeNoteIndex
      }
    }, [_c('b-alert', {
      staticClass: "mb-0",
      attrs: {
        "show": "",
        "dismissing": ""
      }
    }, [_c('div', [_vm._v(" " + _vm._s(note.text) + " ")]), _c('div', {
      staticClass: "text-right"
    }, [_c('b-button', {
      attrs: {
        "variant": "link",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          _vm.activeNoteIndex = noteIndex;
        }
      }
    }, [_vm._v(" Edit ")]), _c('b-button', {
      attrs: {
        "variant": "link",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.showDeleteConfirmationModal(noteIndex);
        }
      }
    }, [_vm._v(" Delete ")])], 1)])], 1), _c('b-collapse', {
      attrs: {
        "visible": noteIndex === _vm.activeNoteIndex
      }
    }, [noteIndex === _vm.activeNoteIndex ? _c('b-form-textarea', {
      directives: [{
        name: "click-outside",
        rawName: "v-click-outside",
        value: function value() {
          return _vm.onClose(noteIndex);
        },
        expression: "() => onClose(noteIndex)"
      }],
      attrs: {
        "rows": "6",
        "max-rows": "6"
      },
      model: {
        value: note.text,
        callback: function callback($$v) {
          _vm.$set(note, "text", $$v);
        },
        expression: "note.text"
      }
    }) : _vm._e(), _c('div', {
      staticClass: "text-right"
    }, [_c('b-button', {
      attrs: {
        "variant": "link",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.onClose(noteIndex);
        }
      }
    }, [_vm._v(" Close ")])], 1)], 1)], 1);
  }), _c('div', [_c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": ""
    }
  }, [_c('b-button', {
    staticClass: "p-0 m-0",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function click($event) {
        _vm.addItem();

        _vm.activeNoteIndex = _vm.newValue.items.length - 1;
      }
    }
  }, [_vm._v(" + Add Note ")])], 1)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }