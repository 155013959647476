var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', [_c('b-input-group', [_c('b-form-datepicker', {
    attrs: {
      "size": "sm",
      "placeholder": "From Date",
      "reset-button": ""
    },
    model: {
      value: _vm.newFilters.taken_at_from,
      callback: function callback($$v) {
        _vm.$set(_vm.newFilters, "taken_at_from", $$v);
      },
      expression: "newFilters.taken_at_from"
    }
  }), _c('b-form-datepicker', {
    attrs: {
      "size": "sm",
      "placeholder": "To Date",
      "reset-button": ""
    },
    model: {
      value: _vm.newFilters.taken_at_to,
      callback: function callback($$v) {
        _vm.$set(_vm.newFilters, "taken_at_to", $$v);
      },
      expression: "newFilters.taken_at_to"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "text-right"
  }, [_c('b-form-group', [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "md"
    },
    model: {
      value: _vm.newFilters.is_favorite,
      callback: function callback($$v) {
        _vm.$set(_vm.newFilters, "is_favorite", $$v);
      },
      expression: "newFilters.is_favorite"
    }
  }, [_vm._v(" Favorite ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }