var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching,
      "rounded": "sm"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_c('div', {
    staticClass: "image-field text-center"
  }, [_vm.tmpImageUrl || _vm.imageUrl && !_vm.input.remove_image ? _c('div', [_c('b-img', {
    attrs: {
      "src": _vm.imageUrl,
      "fluid": ""
    }
  }), _c('div', {
    staticClass: "image-remove"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.input.remove_image = true;
        _vm.input.image = null;
      }
    }
  }, [_vm._v(" Remove Photo ")])], 1)], 1) : _c('b-form-file', {
    staticClass: "add-image mt-4",
    attrs: {
      "placeholder": "+ Add Photo",
      "drop-placeholder": "Drop file here...",
      "state": _vm.getFieldState(_vm.$v.input.image)
    },
    on: {
      "change": function change($event) {
        _vm.input.image = $event.target.files[0];
      }
    }
  })], 1)]), _c('b-col', {
    attrs: {
      "lg": "8"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.name),
      "state": _vm.getFieldState(_vm.$v.input.name)
    }
  }, [_c('b-form-input', {
    attrs: {
      "state": _vm.getFieldState(_vm.$v.input.name),
      "placeholder": "Enter Exercise Name"
    },
    model: {
      value: _vm.input.name,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "name", $$v);
      },
      expression: "input.name"
    }
  })], 1), !_vm.source ? [_c('b-form-group', {
    attrs: {
      "label": "Primary Muscle(s):",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.muscle_ids),
      "state": _vm.getFieldState(_vm.$v.input.muscle_ids)
    }
  }, [_c('b-form-checkbox-group', _vm._l(_vm.$store.getters['asset/getPrimaryMusclesOptions'], function (option) {
    return _c('b-form-checkbox', {
      key: option.value,
      attrs: {
        "value": option.value
      },
      model: {
        value: _vm.input.muscle_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.input, "muscle_ids", $$v);
        },
        expression: "input.muscle_ids"
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  }), 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Subcategory",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.subcategory_ids),
      "state": _vm.getFieldState(_vm.$v.input.subcategory_ids)
    }
  }, [_c('b-form-checkbox-group', [_c('div', {
    staticClass: "custom-control custom-control-inline custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "subcategory-none",
      "disabled": _vm.input.subcategory_ids && !_vm.input.subcategory_ids.length,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.input.subcategory_ids && !_vm.input.subcategory_ids.length
    },
    on: {
      "input": function input($event) {
        _vm.input.subcategory_ids = [];
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "subcategory-none"
    }
  }, [_vm._v(" None ")])]), _vm._l(_vm.$store.getters['asset/getExerciseSubcategoriesOptions'], function (option) {
    return _c('b-form-checkbox', {
      key: option.value,
      attrs: {
        "value": option.value
      },
      model: {
        value: _vm.input.subcategory_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.input, "subcategory_ids", $$v);
        },
        expression: "input.subcategory_ids"
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    attrs: {
      "label": "Position",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.position_ids),
      "state": _vm.getFieldState(_vm.$v.input.position_ids)
    }
  }, [_c('b-form-checkbox-group', [_c('div', {
    staticClass: "custom-control custom-control-inline custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "position-none",
      "disabled": _vm.input.position_ids && !_vm.input.position_ids.length,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.input.position_ids && !_vm.input.position_ids.length
    },
    on: {
      "input": function input($event) {
        _vm.input.position_ids = [];
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "position-none"
    }
  }, [_vm._v(" None ")])]), _vm._l(_vm.$store.getters['asset/getExercisePositionsOptions'], function (option) {
    return _c('b-form-checkbox', {
      key: option.value,
      attrs: {
        "value": option.value
      },
      model: {
        value: _vm.input.position_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.input, "position_ids", $$v);
        },
        expression: "input.position_ids"
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    attrs: {
      "label": "Bench position",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.bench_position_ids),
      "state": _vm.getFieldState(_vm.$v.input.bench_position_ids)
    }
  }, [_c('b-form-checkbox-group', [_c('div', {
    staticClass: "custom-control custom-control-inline custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "bench-position-none",
      "disabled": _vm.input.bench_position_ids && !_vm.input.bench_position_ids.length,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.input.bench_position_ids && !_vm.input.bench_position_ids.length
    },
    on: {
      "input": function input($event) {
        _vm.input.bench_position_ids = [];
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "bench-position-none"
    }
  }, [_vm._v(" None ")])]), _vm._l(_vm.$store.getters['asset/getExerciseBenchPositionsOptions'], function (option) {
    return _c('b-form-checkbox', {
      key: option.value,
      attrs: {
        "value": option.value
      },
      model: {
        value: _vm.input.bench_position_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.input, "bench_position_ids", $$v);
        },
        expression: "input.bench_position_ids"
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    attrs: {
      "label": "Hand/Feet placement",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.hand_feet_placement_ids),
      "state": _vm.getFieldState(_vm.$v.input.hand_feet_placement_ids)
    }
  }, [_c('b-form-checkbox-group', [_c('div', {
    staticClass: "custom-control custom-control-inline custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "hand-feet-placement-none",
      "disabled": _vm.input.hand_feet_placement_ids && !_vm.input.hand_feet_placement_ids.length,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.input.hand_feet_placement_ids && !_vm.input.hand_feet_placement_ids.length
    },
    on: {
      "input": function input($event) {
        _vm.input.hand_feet_placement_ids = [];
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "hand-feet-placement-none"
    }
  }, [_vm._v(" None ")])]), _vm._l(_vm.$store.getters['asset/getExerciseHandFeetPlacementsOptions'], function (option) {
    return _c('b-form-checkbox', {
      key: option.value,
      attrs: {
        "value": option.value
      },
      model: {
        value: _vm.input.hand_feet_placement_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.input, "hand_feet_placement_ids", $$v);
        },
        expression: "input.hand_feet_placement_ids"
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    attrs: {
      "label": "Fitness Level(s):",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.fitness_level_ids),
      "state": _vm.getFieldState(_vm.$v.input.fitness_level_ids)
    }
  }, [_c('b-form-checkbox-group', [_c('div', {
    staticClass: "custom-control custom-control-inline custom-checkbox"
  }, [_c('input', {
    staticClass: "custom-control-input",
    attrs: {
      "id": "fitness-level-none",
      "disabled": _vm.input.fitness_level_ids && !_vm.input.fitness_level_ids.length,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.input.fitness_level_ids && !_vm.input.fitness_level_ids.length
    },
    on: {
      "input": function input($event) {
        _vm.input.fitness_level_ids = [];
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "fitness-level-none"
    }
  }, [_vm._v(" None ")])]), _vm._l(_vm.$store.getters['asset/getAbilityLevelsOptions'], function (option) {
    return _c('b-form-checkbox', {
      key: option.value,
      attrs: {
        "value": option.value
      },
      model: {
        value: _vm.input.fitness_level_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.input, "fitness_level_ids", $$v);
        },
        expression: "input.fitness_level_ids"
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  })], 2)], 1), _c('b-form-group', {
    attrs: {
      "label": "Workout Type",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.type_ids),
      "state": _vm.getFieldState(_vm.$v.input.type_ids)
    }
  }, [_c('b-form-checkbox-group', _vm._l(_vm.$store.getters['asset/getWorkoutTypesOptions'], function (option) {
    return _c('b-form-checkbox', {
      key: option.value,
      attrs: {
        "value": option.value
      },
      model: {
        value: _vm.input.type_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.input, "type_ids", $$v);
        },
        expression: "input.type_ids"
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  }), 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Estimated Set Time",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.estimated_set_time),
      "state": _vm.getFieldState(_vm.$v.input.estimated_set_time)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "placeholder": "Seconds to complete one set of ten repetitions",
      "state": _vm.getFieldState(_vm.$v.input.estimated_set_time)
    },
    model: {
      value: _vm.input.estimated_set_time,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "estimated_set_time", $$v);
      },
      expression: "input.estimated_set_time"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.description),
      "state": _vm.getFieldState(_vm.$v.input.description)
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "state": _vm.getFieldState(_vm.$v.input.description),
      "placeholder": "Enter Exercise Description"
    },
    model: {
      value: _vm.input.description,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "description", $$v);
      },
      expression: "input.description"
    }
  })], 1)] : _vm._e(), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    staticClass: "mr-2",
    on: {
      "click": function click($event) {
        return _vm.$emit('cancel');
      }
    }
  }, [_vm._v(" Cancel ")]), _c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(_vm._s(_vm.actionName) + " ")], 1)], 1)], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }