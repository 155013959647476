var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching,
      "rounded": "sm"
    }
  }, [_vm.isFetched ? _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('rebooted');
      }
    }
  }, [!_vm.isOwner ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You can't reboot this curriculum. ")])], 1) : _vm._e(), _vm.currentUserCurriculum ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You already have an active curriculum. ")])], 1) : _vm._e(), !_vm.currentUserProfileUptodate ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Your profile is not uptodate. ")])], 1) : _vm._e(), !_vm.statusIsRebootable ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You can't reboot curriculum with status " + _vm._s(_vm.newItem.status) + ". ")])], 1) : _vm._e(), _vm.scheduleCardioEventCount < _vm.newItem.profile.cardio_sessions_per_week && _vm.scheduleWorkoutEventCount < _vm.newItem.profile.workouts_per_week ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You do not have anough workouts (" + _vm._s(_vm.scheduleWorkoutEventCount) + "/" + _vm._s(_vm.newItem.profile.workouts_per_week) + ") and cardio (" + _vm._s(_vm.scheduleCardioEventCount) + "/" + _vm._s(_vm.newItem.profile.cardio_sessions_per_week) + ") sessions to match this curriculum. ")])], 1) : _vm.scheduleCardioEventCount < _vm.newItem.profile.cardio_sessions_per_week ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You do not have anough cardio (" + _vm._s(_vm.scheduleCardioEventCount) + "/" + _vm._s(_vm.newItem.profile.cardio_sessions_per_week) + ") sessions in your schedule to match this curriculum. ")])], 1) : _vm.scheduleWorkoutEventCount < _vm.newItem.profile.workouts_per_week ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You do not have anough workouts (" + _vm._s(_vm.scheduleWorkoutEventCount) + "/" + _vm._s(_vm.newItem.profile.workouts_per_week) + ") in your schedule to match this curriculum. ")])], 1) : _vm._e(), _c('b-form-group', {
    staticClass: "text-left mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "block": "",
      "size": "lg",
      "disabled": !_vm.isRebootable || _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm.curriculumProduct ? [_vm._v(" $" + _vm._s(_vm.curriculumProduct.price_per_week * _vm.newItem.term) + " | Reboot ")] : _vm._e()], 2)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }