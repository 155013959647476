<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-row
      v-if="$acl.check('isAdmin')"
      class="mb-2"
    >
      <b-col
        xs="12"
        md="3"
      >
        <CompanySelect
          v-model="newParams.filters.company_ids"
          placeholder="Select a company..."
          list-endpoint="admin/companies"
          :show-create="false"
          multiple
          :multiple-limit="1"
        />
      </b-col>
    </b-row>

    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <!-- ACTIONS -->
      <template #cell(actions)="row">
        <GymItemActionsButton
          v-if="$acl.check('couldActionGym')"
          :item="row.item"
          @delete:item="refresh"
          @submitted="refresh"
        >
          <b-icon-three-dots />
        </GymItemActionsButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import GymItemActionsButton from './buttons/GymItemActionsButton'
import CompanySelect from '@/components/companies/CompanySelect'

export default {
  name: 'GymList',

  mixins: [
    crudListMixin
  ],

  components: {
    GymItemActionsButton,
    CompanySelect
  },

  data() {
    return {
      fields: [
        { key: 'name', sortable: false },
        { key: 'phone', sortable: false },
        { key: 'email', sortable: false },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'w-100px text-right' }
      ]
    }
  },

  computed: {
    baseEndpoint() {
      return this.$acl.check('isAdmin') ? 'admin/gyms' : 'gyms'
    },

    filterCompanyIds: {
      get() {
        return _.get(this.newParams.filters, 'company_ids', null)
      },

      set(value) {
        this.newParams.filters.company_ids = value
      }
    }
  }
}
</script>