<template>
  <b-overlay
    :show="isBusy"
    :variant="config.overlay.variant"
    :opacity="config.overlay.opacity"
    :blur="config.overlay.blur"
    rounded="sm"
  >
    <div v-if="!newResult.data && isBusy">
      Loading...
    </div>

    <div v-else-if="!newResult.data">
      Not loaded
    </div>

    <div v-else-if="!newResult.data.length">
      No results
    </div>
    
    <b-row v-else>
      <b-col
        v-for="(item, itemIndex) in newResult.data"
        :key="itemIndex"
        :cols="parseInt(12 / cols)"
      >
        <b-row align-v="center">
          <b-col cols="8">
            <b-badge
              class="p-0"
              variant="light"
            >
              {{ $moment(item.taken_at).format('l') }}
            </b-badge>

            <b-badge
              variant="light"
            >
              <ImageCategories
                :ids="[item.category]"
              />
            </b-badge>
          </b-col>

          <b-col
            class="text-right"
            cols="4"
          >
            <ImageItemActionsButton
              :item="item"
              :client="client"
              :dropdown-props="{
                toggleClass: 'bg-transparent text-primary p-0 border-0'
              }"
              @update:item="refresh(); $emit('update:item', $event)"
              @delete:item="refresh(); $emit('delete:item', $event)"
            >
              <b-icon-three-dots />
            </ImageItemActionsButton>
          </b-col>
        </b-row>

        <div class="position-relative">
          <b-img
            class="bg-light object-fit-cover w-100"
            :src="item.url"
            :style="{
              height: cols === 1 ? '300px' : '175px'
            }"
          />

          <div class="favorite-button p-2">
            <ImageItemFavoriteButton
              tag="b-icon-heart-fill"
              class="font-size-20"
              :style="{ cursor: 'pointer' }"
              :tag-props="{
                variant: item.is_favorite ? 'danger' : 'white'
              }"
              :item="item"
              @update:item="refresh(); $emit('update:item', $event)"
            />
          </div>
        </div>
      </b-col>
    </b-row>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import appConfig from '@/app.config'
import ImageItemActionsButton from './buttons/ImageItemActionsButton'
import ImageItemFavoriteButton from './buttons/ImageItemFavoriteButton'
import ImageCategories from '@/components/v1/asset/ImageCategories'

export default {
  name: 'ImageList',

  components: {
    ImageItemActionsButton,
    ImageItemFavoriteButton,
    ImageCategories
  },

  props: {
    client: {
      type: Object,
      required: true
    },

    result: {
      type: Object,
      default: null
    },

    params: {
      type: Object,
      default: null
    },

    prefetch: {
      type: Boolean,
      default: true
    },

    pagination: {
      type: Boolean,
      default: true
    },

    cols: {
      type: Number,
      default: 4
    }
  },

  data() {
    return {
      isBusy: false,
      config: appConfig,
      newResult: {
        data: null,
        total: null
      },
      newParams: {
        filters: {},
        page: 1,
        limit: 4,
        order_by: null,
        order_desc: null
      }
    }
  },

  computed: {
    showPagination() {
      return this.pagination && this.newResult.total > this.newParams.limit
    }
  },

  watch: {
    result: {
      immediate: true,
      deep: true,
      handler() {
        if (!_.isEqual(this.result, this.newResult)) {
          this.setNewResult()
        }
      }
    },

    params: {
      immediate: true,
      deep: true,
      handler() {
        if (!_.isEqual(this.params, this.newParams)) {
          this.setNewParams()

          if (this.prefetch) {
            this.fetchList()
          }
        }
      }
    },

    newParams: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.newParams && !_.isEqual(this.newParams, this.params)) {
          this.$emit('update:params', _.cloneDeep(this.newParams))

          this.fetchList()
        }
      }
    },

    newResult: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.newResult && !_.isEqual(this.newResult, this.result)) {
          this.$emit('update:result', _.cloneDeep(this.newResult))
        }
      }
    }
  },

  methods: {
    setNewResult() {
      this.newResult = {
        ...this.newResult,
        ..._.pick(this.result, [
          'data',
          'total'
        ])
      }
    },

    setNewParams() {
      this.newParams = {
        ...this.newParams,
        ..._.pick(this.params, [
          'filters',
          'page',
          'limit',
          'order_by',
          'order_desc'
        ])
      }
    },

    async fetchList() {
      const limit = this.newParams.limit
      const page = this.newParams.page
      const filters = this.newParams.filters
      const order_by = this.newParams.order_by
      const order_desc = this.newParams.order_desc

      return this.$axios
        .get(`/images/${this.client.id}`, {
          parent: this,
          params: { limit, page, order_by, order_desc, ...filters }
        })
        .then(({ data }) => {
          this.newResult.data = data.data
          this.newResult.total = data.total
          return data.data
        })
    },

    refresh() {
      this.fetchList()
    }
  }
}
</script>

<style lang="scss" scoped>
.favorite-button {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.8;
}
</style>