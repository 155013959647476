var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('autocomplete', {
    attrs: {
      "value": _vm.value,
      "placeholder": _vm.placeholder,
      "options": _vm.options,
      "serializer": function serializer(i) {
        return i;
      }
    },
    on: {
      "input": function input($event) {
        return _vm.$emit('input', $event);
      }
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }