<template>
  <component
    :is="tag"
    v-bind="tagProps"
  >
    <b-button
      size="sm"
      class="my-1"
      v-for="option in options"
      :key="option.value"
      :class="buttonClass"
      :disabled="disabledValues.includes(option.value)"
      :pressed="isActive(option.value)"
      :variant="isActive(option.value) ? 'primary' : ''"
      @click="toggleItem(option.value)"
    >
      {{ option.text }}
    </b-button>
  </component>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'FormInputButtons',

  props: {
    value: {
      type: [Array, Number, String, Boolean],
      default: null
    },

    options: {
      type: Array,
      default: () => ([])
    },

    disabledValues: {
      type: Array,
      default: () => ([])
    },

    multiple: {
      type: Boolean,
      default: false
    },

    tag: {
      type: String,
      default: 'div'
    },

    tagProps: {
      type: Object,
      default: null
    },

    buttonClass: {
      type: String,
      default: null
    }
  },

  methods: {
    toggleItem(id) {
      let newValue

      if (this.multiple) {
        newValue = _.cloneDeep(this.value) || []

        if (newValue.includes(id)) {
          newValue.splice(newValue.indexOf(id), 1)
        } else {
          newValue.push(id)
        }

      } else {
        newValue = id
      }

      this.$emit('input', newValue)
    },

    isActive(id) {
      if (this.multiple) {
        return this.value && this.value.includes(id)
      } else {
        return this.value === id
      }
    }
  }
}
</script>