var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_vm.$acl.check('isAdmin') ? _c('b-row', {
    staticClass: "mb-2"
  }, [_c('b-col', {
    attrs: {
      "xs": "12",
      "md": "3"
    }
  }, [_c('CompanySelect', {
    attrs: {
      "placeholder": "Select a company...",
      "list-endpoint": "admin/companies",
      "show-create": false,
      "multiple": "",
      "multiple-limit": 1
    },
    model: {
      value: _vm.newParams.filters.company_ids,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams.filters, "company_ids", $$v);
      },
      expression: "newParams.filters.company_ids"
    }
  })], 1)], 1) : _vm._e(), _c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "items": _vm.newResult.data,
      "fields": _vm.fields,
      "stacked": "sm",
      "no-local-sorting": "",
      "sort-by": _vm.newParams.order_by,
      "sort-desc": _vm.newParams.order_desc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(actions)",
      fn: function fn(row) {
        return [_vm.$acl.check('couldActionGym') ? _c('GymItemActionsButton', {
          attrs: {
            "item": row.item
          },
          on: {
            "delete:item": _vm.refresh,
            "submitted": _vm.refresh
          }
        }, [_c('b-icon-three-dots')], 1) : _vm._e()];
      }
    }])
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }