<template>
  <component
    :is="tag"
    :variant="variant"
    v-bind="tagProps"
  >
    <template v-if="item.product_installment && !item.product_installment.is_paid">
      Unpaid
    </template>

    <template v-else-if="restartDate">
      Restart {{ $moment(restartDate).fromNow() }}
    </template>

    <template v-else>
      <template v-if="item.parent_id">Reboot </template>{{ title }}
    </template>
  </component>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CurriculumStatus',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'b-button'
    },

    tagProps: {
      type: Object,
      default: () => ({
        size: 'sm'
      })
    }
  },

  computed: {
    title() {
      const statuses = {
        completed: 'Completed',
        canceled: 'Cancelled',
        paused: 'Paused',
        active: 'Active',
        designing: 'Designing'
      }
      return statuses[this.item.status]
    },

    variant() {
      const variants = {
        completed: 'secondary',
        canceled: 'secondary',
        paused: 'warning',
        active: 'success',
        designing: 'danger'
      }
      return variants[this.item.status]
    },

    restartDate() {
      return _.chain(this.item)
        .get('pauses.items')
        .find(i => this.$moment() < this.$moment(i.to).add(1, 'day'))
        .get('to')
        .value()
    }
  }
}
</script>