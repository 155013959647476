var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "layout-wrapper"
    }
  }, [_c('HorizontalTopbar'), _c('HorizontalNav'), _c('div', {
    staticClass: "main-content"
  }, [_c('div', {
    staticClass: "page-content"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_vm._t("default")], 2)]), _c('Footer')], 1), _c('RightBar')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }