var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._b({
    tag: "component",
    on: {
      "click": function click($event) {
        _vm.popupIsShown = true;
      }
    }
  }, 'component', _vm.buttonProps, false), [_vm._t("default"), _c('b-modal', {
    attrs: {
      "content-class": "rounded-0 border-0",
      "header-bg-variant": "dark",
      "header-text-variant": "white",
      "header-class": "rounded-0 p-4",
      "body-class": "p-0",
      "size": "lg",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm.workoutProgram ? _c('div', {
          staticClass: "pt-5"
        }, [_c('h2', {
          staticClass: "pt-5 mt-5"
        }, [_vm._v(" " + _vm._s(_vm.workoutProgram.name) + " ")]), _c('div', [_vm._v(" " + _vm._s(_vm.workoutProgram.description) + " ")])]) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.popupIsShown,
      callback: function callback($$v) {
        _vm.popupIsShown = $$v;
      },
      expression: "popupIsShown"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_vm.workoutProgram ? [_c('b-card', {
    staticClass: "py-3 m-0",
    attrs: {
      "bg-variant": "light"
    }
  }, [_c('b-row', [_c('b-col', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Fitness Level ")]), _vm._v(": "), _c('Abilities', {
    attrs: {
      "ids": _vm.workoutProgram.fitness_level_ids
    }
  })], 1), _c('b-col', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Gender ")]), _vm._v(": "), _c('Genders', {
    attrs: {
      "ids": [_vm.workoutProgram.gender]
    }
  })], 1), _c('b-col', [_c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Goal Direction ")]), _vm._v(": "), _c('GoalDirections', {
    attrs: {
      "ids": [_vm.workoutProgram.goal_direction_id]
    }
  })], 1)], 1)], 1), _c('b-card', {
    staticClass: "m-0"
  }, [_c('h4', {
    staticClass: "mb-4"
  }, [_vm._v(" " + _vm._s(_vm.weeksCount) + " Week Program - " + _vm._s(_vm.phasesCount) + " Phases ")]), _c('WorkoutProgramPhasesInput', {
    attrs: {
      "value": _vm.workoutProgram.phases
    }
  })], 1)] : _vm._e()], 2)], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }