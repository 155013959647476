<template>
  <b-tabs
    active-nav-item-class="bg-tab text-primary"
    nav-class="border-0"
    justified
    no-fade
  >
    <b-tab
      v-for="(phase, phaseIndex) in value"
      :key="phase.id"
      title-link-class="rounded-0 border-0"
      :title="`Phase ${phaseIndex + 1}`"
    >
      <b-card class="my-0" no-body>
        <b-table-simple
          class="m-0 input-table"
          fixed
        >
          <b-thead>
            <b-tr>
              <b-th>Name</b-th>
              <b-th>Type</b-th>
              <b-th>Recommended Dosage</b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr
              v-for="(supplement, supplementIndex) in phase.supplements"
              :key="supplementIndex"
              @click="$emit('select-supplement', supplement)"
            >
              <b-td class="w-20">
                {{ supplement.name }}
              </b-td>

              <b-td class="w-20">
                <SupplementTypes :ids="supplement.type  ? [supplement.type ] : []" />
              </b-td>

              <b-td class="w-33-33">
                {{ supplement.dose }} <SupplementDoseTypes :ids="supplement.dose_type  ? [supplement.dose_type ] : []" />

              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import SupplementTypes from '@/components/v1/asset/SupplementTypes'
import SupplementDoseTypes from '@/components/v1/asset/SupplementDoseTypes'

export default {
  name: 'SupplementProgramPhasesInput',

  components: {
    SupplementTypes,
    SupplementDoseTypes
  },

  props: {
    value: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.input-table {
  thead {
    th {
      background: #BAD9D9;
    }
  }

  tbody {
    tr {
      background: #DADADE;

      &:nth-child(odd) {
        background: #ECECF1;
      } 
    }
  }
}
</style>