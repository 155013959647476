<template>
  <b-card
    :style="{
      backgroundColor: '#d7dbf4'
    }"
    body-class="p-3"
  >
    <div class="text-center">
      <b-avatar
        :src="item.client.profile_img_url"
        size="4rem"
      />
    </div>

    <div class="text-center mt-2 font-weight-bolder">
      {{ item.client.first_name }} {{ item.client.last_name }}
    </div>

    <div class="text-center">
      <b-button
        class="p-0 pt-2"
        variant="link"
        size="sm"
        @click="popupIsShown = true"
      >
        Show summary
      </b-button>
    </div>

    <CurriculumStatus
      :item="item"
      :style="{
        position: 'absolute',
        top: '10px',
        right: '10px'
      }"
    />

    <b-modal
      v-model="popupIsShown"
      content-class="rounded-0 border-0"
      body-class="p-0"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-4"
      hide-footer
    >
      <template #modal-title>
        {{ item.client.first_name }} {{ item.client.last_name }}
      </template>
      
      <CurriculumItemProfileSummary
        class="mb-0"
        :item="item"
        @update:item="$emit('update:item', $event)"
      />
    </b-modal>
  </b-card>
</template>

<script>
import CurriculumStatus from './CurriculumStatus'
import CurriculumItemProfileSummary from './CurriculumItemProfileSummary'

export default {
  name: 'CurriculumItemProfileSummaryShort',

  components: {
    CurriculumStatus,
    CurriculumItemProfileSummary
  },

  props: {
    item: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      popupIsShown: false
    }
  },

  computed: {
    age() {
      return this.$moment().diff(this.item.client.birthdate, 'years');
    },

    isStarted() {
      return this.$moment().startOf('day') >= this.$moment(this.item.start_date)
    }
  }
}
</script>