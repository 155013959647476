var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1)], 1), _vm.isFetched ? _c('b-card', [_c('ClientStrengthGains', {
    attrs: {
      "params": {
        filters: {
          user_id: _vm.item.id,
          exercise_id: null,
          date_from: _vm.$moment().subtract(90, 'd').format('YYYY-MM-DD'),
          date_to: _vm.$moment().format('YYYY-MM-DD')
        }
      },
      "prefetch": false
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }