<script>
import _ from 'lodash'
import simplebar from "simplebar-vue";
import MetisMenu from "metismenujs/dist/metismenujs";
import CurriculumWorkoutTrackerButton from '@/components/curriculums/workouts/buttons/CurriculumWorkoutTrackerButton'
import CurriculumCardioTrackerButton from '@/components/curriculums/cardios/buttons/CurriculumCardioTrackerButton'
import TrackerNutritionButton from '@/components/trackers/nutritions/buttons/TrackerNutritionButton'
import BillingSubscriptionStatus from '@/components/account/BillingSubscriptionStatus'

import { layoutMethods, authComputed } from "@/state/helpers";
import { mapGetters } from 'vuex'

/**
 * Sidebar component
 */
export default {
  components: {
    simplebar,
    CurriculumWorkoutTrackerButton,
    CurriculumCardioTrackerButton,
    TrackerNutritionButton,
    BillingSubscriptionStatus
  },
  mounted: function() {
    // eslint-disable-next-line no-unused-vars
    var menuRef = new MetisMenu("#side-menu");
    var links = document.getElementsByClassName("side-nav-link");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;
      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("mm-active");
        const parent2 = parent.parentElement.closest("ul");
        if (parent2 && parent2.id !== "side-menu") {
          parent2.classList.add("mm-show");
          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.add("mm-active");
            var childAnchor = parent3.querySelector(".has-arrow");
            var childDropdown = parent3.querySelector(".has-dropdown");
            if (childAnchor) childAnchor.classList.add("mm-active");
            if (childDropdown) childDropdown.classList.add("mm-active");
            const parent4 = parent3.parentElement;
            if (parent4) parent4.classList.add("mm-show");
            const parent5 = parent4.parentElement;
            if (parent5) parent5.classList.add("active");
          }
        } else {
          parent.classList.add("mm-active");
        }
      }
    }
  },
  methods: {
    ...layoutMethods,
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout });
    },
    compactSidebar() {
      document.body.setAttribute("data-sidebar-size", "small");
      document.body.classList.remove("vertical-collpsed");
      document.body.removeAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    iconSidebar() {
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
      document.body.removeAttribute("data-sidebar-size", "small");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedLayout() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.classList.add("vertical-collpsed");
      document.body.setAttribute("data-keep-enlarged", "true");
    },
  },
  computed: {
    ...authComputed,

    ...mapGetters({
      currentCompany: 'auth/currentCompany',
      currentUser: 'auth/currentUser'
    }),

    curriculum() {
      return _.get(this.currentUser, 'curriculum')
    }
  }
};
</script>

<template>
  <!-- ========== Left Sidebar Start ========== -->
  <div class="vertical-menu">
    <simplebar class="h-100">
      <!--- Sidemenu -->
      <div id="sidebar-menu">
        <!-- Left Menu for Unpaid Client  -->
        <ul
          v-if="!$acl.check('couldUseApp')"
          class="metismenu list-unstyled"
          id="side-menu"
        >
          <li>
            <router-link
              class="side-nav-link"
              to="/account"
            >
              <b-icon-gear class="mr-3" />

              <BillingSubscriptionStatus
                class="float-right text-uppercase"
                :item="currentUser.company_app_subscription || { status: 'unpaid' }"
                tag="b-badge"
              />

              <span>Account</span>
            </router-link>
          </li>
        </ul>

        <!-- Left Menu for a Client  -->
        <ul
          v-else-if="$acl.check('isClient')"
          class="metismenu list-unstyled"
          id="side-menu"
        >
          <li>
            <router-link
              class="side-nav-link"
              to="/"
            >
              <i class="ti-home" />

              <b-badge
                v-if="curriculum"
                class="float-right text-uppercase"
                :variant="curriculum.status === 'paused' ? 'warning' : 'primary'"
              >
                {{ curriculum.status }}
              </b-badge>

              <span>Dashboard</span>
            </router-link>
          </li>

          <li v-if="curriculum && curriculum.status === 'active'">
            <router-link
              :to="`/schedule`"
              class="side-nav-link"
            >
              <i class="ti-home" />
              <span>Schedule</span>
            </router-link>
          </li>

          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-home" />
              <span>Curriculums</span>
            </a>

            <ul class="sub-menu" aria-expanded="false">
              <li v-if="curriculum && curriculum.status === 'active'">
                <router-link
                  :to="`/curriculums/${curriculum.id}/view`"
                  class="side-nav-link"
                >
                  Current Curriculum
                </router-link>
              </li>

              <li v-if="curriculum && curriculum.status === 'active'">
                <router-link
                  :to="`/curriculums/${curriculum.id}/logs`"
                  class="side-nav-link"
                >
                  Curriculum Logs
                </router-link>
              </li>

              <li>
                <router-link
                  :to="`/client/${currentUser.id}/curriculums`"
                  class="side-nav-link"
                >
                  Curriculum Histories
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="curriculum && curriculum.status === 'active'">
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-home" />
              <span>Trackers</span>
            </a>

            <ul class="sub-menu" aria-expanded="false">
              <li>
                <CurriculumWorkoutTrackerButton
                  class="side-nav-link"
                  tag="a"
                  :tag-props="{
                    href: 'javascript: void(0);'
                  }"
                  :params="{
                    filters: {
                      curriculum_id: curriculum.id,
                      is_performed: false
                    },
                    order_by: 'start_time',
                    order_desc: false
                  }"
                >
                  Workout Tracker
                </CurriculumWorkoutTrackerButton>
              </li>

              <li>
                <CurriculumCardioTrackerButton
                  class="side-nav-link"
                  tag="a"
                  :tag-props="{
                    href: 'javascript: void(0);'
                  }"
                  :params="{
                    filters: {
                      curriculum_id: curriculum.id,
                      is_performed: false
                    },
                    order_by: 'start_time',
                    order_desc: false
                  }"
                >
                  Cardio Tracker
                </CurriculumCardioTrackerButton>
              </li>

              <li>
                <TrackerNutritionButton
                  class="side-nav-link"
                  tag="a"
                  :tag-props="{
                    href: 'javascript: void(0);'
                  }"
                  :params="{
                    filters: {
                      user_id: currentUser.id,
                      date: $moment().format('YYYY-MM-DD')
                    }
                  }"
                  :curriculum="curriculum"
                >
                  Nutrition Tracker
                </TrackerNutritionButton>
              </li>
            </ul>
          </li>

          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-home" />
              <span>Results & Reports</span>
            </a>

            <ul class="sub-menu" aria-expanded="false">
              <li>
                <router-link
                  :to="`/client/${currentUser.id}/physical-conditions`"
                  class="side-nav-link"
                >
                  Measurements
                </router-link>
              </li>

              <li>
                <router-link
                  :to="`/client/${currentUser.id}/photos`"
                  class="side-nav-link"
                >
                  Photos
                </router-link>
              </li>

              <li>
                <router-link
                  :to="`/client/${currentUser.id}/strength-gains`"
                  class="side-nav-link"
                >
                  Strength Gains
                </router-link>
              </li>
            </ul>
          </li>

          <li>
            <router-link
              tag="a"
              :to="`/store`"
              class="side-nav-link"
            >
              <i class="ti-email"></i>
              <span>Store</span>
            </router-link>
          </li>

          <li>
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-home" />
              <span>Workout Essentials</span>
            </a>

            <ul class="sub-menu" aria-expanded="false">
              <li>
                <router-link
                  :to="`/exercise/list`"
                  class="side-nav-link"
                >
                  Exercises
                </router-link>
              </li>

              <li>
                <router-link
                  :to="`/workout/list`"
                  class="side-nav-link"
                >
                  Workouts
                </router-link>
              </li>
            </ul>
          </li>
        </ul>

        <!-- Left Menu Start -->
        <ul
          v-else
          class="metismenu list-unstyled"
          id="side-menu"
        >
          <li>
            <router-link tag="a" to="/" class="side-nav-link">
              <i class="ti-home"></i>
              <span>Dashboard</span>
            </router-link>
          </li>

          <li v-show="$acl.check('couldOnboard')">
            <router-link tag="a" to="/onboarding" class="side-nav-link">
              <i class="ti-home"></i>
              <span>Onboarding</span>
            </router-link>
          </li>

          <li v-show="$acl.check('couldAnyCurriculumEssentials')">
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-email"></i>
              <span>Curriculum Essentials</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li v-show="$acl.check('couldAnyExercise')">
                <a href="javascript: void(0);" class="has-arrow">
                  <i class="ti-email"></i>
                  <span>Exercises</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <li v-show="$acl.check('couldAddExercise')">
                    <router-link to="/exercise/add" class="side-nav-link"
                      >Add</router-link
                    >
                  </li>
                  <li v-show="$acl.check('couldListExercise')">
                    <router-link to="/exercise/list" class="side-nav-link"
                      >List</router-link
                    >
                  </li>
                </ul>
              </li>

              <li v-show="$acl.check('couldAnyWorkout')">
                <a href="javascript: void(0);" class="has-arrow">
                  <i class="ti-email"></i>
                  <span>Workouts</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <li v-show="$acl.check('couldAddWorkout')">
                    <router-link to="/workout/add" class="side-nav-link"
                      >Add</router-link
                    >
                  </li>
                  <li v-show="$acl.check('couldListWorkout')">
                    <router-link to="/workout/list" class="side-nav-link"
                      >List</router-link
                    >
                  </li>
                </ul>
              </li>

              <li v-if="$acl.check('couldAnyForecast')">
                <a href="javascript: void(0);" class="has-arrow">
                  <i class="ti-email"></i>
                  <span>Forecasts</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <li v-if="$acl.check('couldAddForecast')">
                    <router-link to="/forecast/add" class="side-nav-link"
                      >Add</router-link
                    >
                  </li>
                  <li v-if="$acl.check('couldListForecast')">
                    <router-link to="/forecast/list" class="side-nav-link"
                      >List</router-link
                    >
                  </li>
                </ul>
              </li>

              <li v-if="$acl.check('couldAnyProgram')">
                <a href="javascript: void(0);" class="has-arrow">
                  <i class="ti-package"></i>
                  <span>Workout Programs</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <li v-if="$acl.check('couldAddProgram')">
                    <router-link to="/workout-programs/add" class="side-nav-link"
                      >Add</router-link
                    >
                  </li>
                  <li v-if="$acl.check('couldListProgram')">
                    <router-link to="/workout-programs/list" class="side-nav-link"
                      >List</router-link
                    >
                  </li>
                </ul>
              </li>

              <li v-show="$acl.check('couldAnySupplement')">
                <a href="javascript: void(0);" class="has-arrow">
                  <i class="ti-shortcode"></i>
                  <span>Supplements</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <li v-show="$acl.check('couldAddSupplement')">
                    <router-link to="/supplement/add" class="side-nav-link"
                      >Add</router-link
                    >
                  </li>
                  <li v-show="$acl.check('couldListSupplement')">
                    <router-link to="/supplement/list" class="side-nav-link"
                      >List</router-link
                    >
                  </li>
                </ul>
              </li>

              <li v-show="$acl.check('couldAnySupplementProgram')">
                <a href="javascript: void(0);" class="has-arrow">
                  <i class="ti-shortcode"></i>
                  <span>Supplement Programs</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <li v-show="$acl.check('couldAddSupplementProgram')">
                    <router-link to="/supplement-program/add" class="side-nav-link"
                      >Add</router-link
                    >
                  </li>
                  <li v-show="$acl.check('couldListSupplementProgram')">
                    <router-link to="/supplement-program/list" class="side-nav-link"
                      >List</router-link
                    >
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li v-show="$acl.check('couldAnyClient')">
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-user"></i>
              <span>Clients</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li v-show="$acl.check('couldListClient')">
                <router-link to="/client/list" class="side-nav-link">
                  List
                </router-link>
              </li>
            </ul>
          </li>

          <li v-if="$acl.check('couldListGym')">
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-location-pin"></i>
              <span>Gyms</span>
            </a>
            <ul class="sub-menu" aria-expanded="false">
              <li v-if="$acl.check('couldAddGym')">
                <router-link to="/gym/add" class="side-nav-link">
                  Add
                </router-link>
              </li>
              <li>
                <router-link to="/gym/list" class="side-nav-link">
                  List
                </router-link>
              </li>
            </ul>
          </li>

          <li v-show="$acl.check('isOwner')">
            <router-link
              tag="a"
              :to="`/company/${currentCompany.id}`"
              class="side-nav-link"
            >
              <i class="ti-email"></i>
              <span>Company</span>
            </router-link>
          </li>

          <li v-show="$acl.check('couldListStaff')">
            <router-link tag="a" to="/staff/list" class="side-nav-link">
              <i class="ti-email"></i>
              <span>Staff</span>
            </router-link>
          </li>

          <li v-show="$acl.check('isOwner')">
            <router-link
              tag="a"
              :to="`/store`"
              class="side-nav-link"
            >
              <i class="ti-email"></i>
              <span>Store</span>
            </router-link>
          </li>

          <li v-if="$acl.check('isAdmin')">
            <a href="javascript: void(0);" class="has-arrow">
              <i class="ti-settings"></i>
              <span>Admin Tools</span>
            </a>

            <ul class="sub-menu" aria-expanded="false">
              <li>
                <a href="javascript: void(0);" class="has-arrow">
                  <i class="ti-agenda"></i>
                  <span>Companies</span>
                </a>
                <ul class="sub-menu" aria-expanded="false">
                  <li>
                    <router-link to="/company/add" class="side-nav-link">
                      Add
                    </router-link>
                  </li>
                  <li>
                    <router-link to="/company/list" class="side-nav-link">
                      List
                    </router-link>
                  </li>
                </ul>
              </li>

              <li>
                <router-link
                  to="/foods"
                  class="side-nav-link"
                >
                  <i class="ti-plug" />
                  <span>Foods</span>
                </router-link>
              </li>

              <li>
                <a href="javascript: void(0);" class="has-arrow">
                  <i class="ti-plug"></i>
                  <span>Formula Factors</span>
                </a>

                <ul class="sub-menu" aria-expanded="false">
                  <li>
                    <router-link
                      to="/v1/nutrient"
                      class="side-nav-link"
                    >
                      Nutrient
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      to="/v1/daily-activity"
                      class="side-nav-link"
                    >
                      Daily Activity
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      to="/v1/lean-factor"
                      class="side-nav-link"
                    >
                      Lean Factor
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      to="/v1/nb-caliper"
                      class="side-nav-link"
                    >
                      NB Caliper
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      to="/v1/nb-caliper-ratio"
                      class="side-nav-link"
                    >
                      NB Cardio Caliper Ratio
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      to="/v1/water-intakes"
                      class="side-nav-link"
                    >
                      Water Intakes
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      to="/v1/weight-loss"
                      class="side-nav-link"
                    >
                      Weight Loss
                    </router-link>
                  </li>

                  <li>
                    <router-link
                      to="/v1/workouts-per-week"
                      class="side-nav-link"
                    >
                      Workouts Per Week
                    </router-link>
                  </li>
                </ul>
              </li>

              <li>
                <router-link
                  to="/v1/formula-test"
                  class="side-nav-link"
                >
                  <i class="ti-plug" />
                  <span>Forecasting Tool</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- Sidebar -->
    </simplebar>
  </div>
  <!-- Left Sidebar End -->
</template>

<style scoped>
.vertical-menu {
  padding-top: 70px;
}
.vertical-collpsed .vertical-menu {
  padding-top: 10px;
}
</style>
