var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', _vm._b({
    attrs: {
      "right": "",
      "text": "Actions"
    },
    scopedSlots: _vm._u([_vm.$slots["default"] ? {
      key: "button-content",
      fn: function fn() {
        return [_vm._t("default")];
      },
      proxy: true
    } : null], null, true)
  }, 'b-dropdown', _vm.dropdownProps, false), [_c('WaterIntakesItemUpsertButton', {
    attrs: {
      "item": _vm.item,
      "prefetch": "",
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-12'
      }
    },
    on: {
      "update:item": function updateItem($event) {
        return _vm.$emit('update:item', $event);
      }
    }
  }, [_vm._v(" Edit ")]), _c('WaterIntakesItemDeleteButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-12'
      }
    },
    on: {
      "delete:item": function deleteItem($event) {
        return _vm.$emit('delete:item', $event);
      }
    }
  }, [_vm._v(" Delete ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }