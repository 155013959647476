var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "star-toggle toggle text-warning"
  }, [_c('a', {
    on: {
      "click": _vm.toggle
    }
  }, [_c('i', {
    staticClass: "typcn",
    "class": _vm.currentStatus ? 'typcn-star-full-outline' : 'typcn-star-outline'
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }