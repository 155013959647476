import _ from 'lodash'

export default {
  props: {
    value: {
      type: [Object, Array, Number, String],
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      newValue: null,
      changedAt: null,
      savedAt: null
    }
  },

  computed: {
    isDirty() {
      return !_.isEqual(this.value, this.newValue)
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,

      handler() {
        this.setNewValue()
      }
    },

    newValue: {
      immediate: true,
      deep: true,

      handler() {
        this.changedAt = +new Date()

        if (this.autosave) {
          if (this.debounce) {
            this.$nextTick(() => {
              if (this.isDirty) {
                this.debounceSubmit('autosave')
              }
            })

          } else {
            this.submit('autosave')
          }
        }
      }
    }
  },

  created() {
    const debounce = _.isNumber(this.debounce) ? this.debounce : 2000

    this.debounceSubmit = _.debounce(async function(submitEventName) {
      if (this.isDirty) {
        this.savedAt = +new Date()
        await this.submit(submitEventName)
      }
    }, debounce)
  },

  validations: {},

  methods: {
    setNewValue() {
      const newValue = this.getNewValue()

      if (!_.isEqual(newValue, this.newValue)) {
        this.newValue = newValue
      }
    },

    getNewValue() {
      return _.cloneDeep(this.value)
    },

    submit(submitEventName) {
      if (submitEventName !== 'autosave') {
        this.$v.$touch()

        if (this.$v.$error) {
          return
        }
      }
      
      this.$emit('input', _.cloneDeep(this.newValue))

      // Emit submit event
      if (submitEventName !== 'input') {
        this.$emit(submitEventName,  _.cloneDeep(this.newValue))
      }
    },

    getFieldState(vuelidateParam) {
      return vuelidateParam && vuelidateParam.$dirty ? !vuelidateParam.$invalid : null
    },

    getFieldInvalidFeedback(vuelidateParam) {
      let invalidFeedback = 'This field is invalid'

      if (vuelidateParam) {
        if (vuelidateParam.required === false) {
          invalidFeedback = 'This value is required.'

        } else if (vuelidateParam.minLength === false) {
          invalidFeedback = `Minimum ${vuelidateParam.$params.minLength.min} length.`

        } else if (vuelidateParam.maxLength === false) {
          invalidFeedback = `Maximum ${vuelidateParam.$params.maxLength.max} length.`

        } else if (vuelidateParam.between === false) {
          invalidFeedback = `Value max be between ${vuelidateParam.$params.between.min} and ${vuelidateParam.$params.between.max}.`

        } else if (vuelidateParam.email === false) {
          invalidFeedback = `Invalid email address.`

        } else if (vuelidateParam.strongPassword === false) {
          invalidFeedback = `Password must be 8-60 chars length, contain at least one uppercase, one lowercase, one special character and one digit.`

        } else if (vuelidateParam.sameAs === false) {
          invalidFeedback = `Two inputs don't match.`
        }
      }
      return invalidFeedback
    }
  }
}