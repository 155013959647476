<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table-simple
      class="mb-0"
      fixed
      striped
      stacked="sm"
    >
      <b-thead>
        <b-tr variant="primary">
          <b-th class="text-left">Supplement</b-th>
          <b-th class="text-center">Amount</b-th>
          <b-th class="w-50px" />
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr
          v-for="item in newResult.data"
          :key="item.id"
        >
          <b-th>
            <TrackerSupplementItemUpsertButton
              :item="item"
              class="p-0 font-weight-bolder"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @submitted="$emit('submitted:item', $event)"
            >
              <b-badge
                class="mr-2"
                variant="success"
              >
                {{ $moment(item.taken_at, 'HH:mm:ss').format('hh:mm A') }}
              </b-badge>

              {{ item.supplement.name }}
            </TrackerSupplementItemUpsertButton>
          </b-th>

          <b-td
            class="text-center align-middle"
            stacked-heading="Calories"
          >
            <TrackerSupplementItemUpsertButton
              :item="item"
              class="p-0 font-weight-bolder"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @submitted="$emit('submitted:item', $event)"
            >
              {{ item.amount }} <SupplementDoseTypes :ids="[item.supplement.dose_type]" lower />
            </TrackerSupplementItemUpsertButton>
          </b-td>

          <b-td class="text-center align-middle">
            <TrackerSupplementItemDeleteButton
              :item="item"
              class="font-size-12"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @delete:item="$emit('delete:item', $event)"
            >
              <b-icon-trash />
            </TrackerSupplementItemDeleteButton>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            class="align-middle"
            colspan="3"
          >
            <b-row align="between">
              <b-col class="text-center">
                <TrackerSupplementItemUpsertButton
                  :item="{ nutrition_id: newParams.filters.nutrition_id }"
                  class="p-0 mr-4"
                  tag="b-button"
                  :tag-props="{ variant: 'link' }"
                  @submitted="$emit('submitted:item', $event)"
                >
                  <b-icon-plus-circle class="mr-1" scale="0.8" /> Add Supplement
                </TrackerSupplementItemUpsertButton>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import TrackerSupplementItemUpsertButton from './buttons/TrackerSupplementItemUpsertButton'
import TrackerSupplementItemDeleteButton from './buttons/TrackerSupplementItemDeleteButton'
import SupplementDoseTypes from '@/components/v1/asset/SupplementDoseTypes'

export default {
  name: 'TrackerSupplementList',

  mixins: [
    crudListMixin
  ],

  components: {
    TrackerSupplementItemUpsertButton,
    TrackerSupplementItemDeleteButton,
    SupplementDoseTypes
  },

  computed: {
    baseEndpoint() {
      return 'tracker-supplements'
    }
  }
}
</script>