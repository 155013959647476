var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phase Number",
      "label-cols": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "value": _vm.newItem.phase_number,
      "options": [1, 2, 3, 4],
      "disabled": _vm.isCreated
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Goal direction",
      "label-cols-sm": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "value": _vm.newItem.goal_direction,
      "options": _vm.$store.getters['asset/getGoalDirectionsOptions'],
      "disabled": _vm.isCreated
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Fitness level",
      "label-cols-sm": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "value": _vm.newItem.fitness_level,
      "options": _vm.$store.getters['asset/getAbilityLevelsOptions'],
      "disabled": _vm.isCreated
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Multiplier",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.multiplier)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.01",
      "state": _vm.getFieldState(_vm.$v.input.multiplier)
    },
    model: {
      value: _vm.input.multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "multiplier", $$v);
      },
      expression: "input.multiplier"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }