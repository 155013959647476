var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('b-card', {
    staticClass: "m-0"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.formSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.image),
      "state": _vm.getFieldState(_vm.$v.input.image)
    }
  }, [_c('div', {
    staticClass: "image-field text-center"
  }, [_vm.imageUrl ? _c('div', [_c('b-avatar', {
    attrs: {
      "src": _vm.imageUrl,
      "size": "12rem"
    }
  })], 1) : _c('b-form-file', {
    staticClass: "add-photo",
    attrs: {
      "placeholder": "+ Add Photo",
      "drop-placeholder": "Drop file here...",
      "state": _vm.getFieldState(_vm.$v.input.image)
    },
    on: {
      "change": function change($event) {
        _vm.input.image = $event.target.files[0];
      }
    }
  })], 1)]), _c('b-form-group', {
    attrs: {
      "label": "Category",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.category),
      "state": _vm.getFieldState(_vm.$v.input.category)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getImageCategoriesOptions'],
      "state": _vm.getFieldState(_vm.$v.input.category)
    },
    model: {
      value: _vm.input.category,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "category", $$v);
      },
      expression: "input.category"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Taken at",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.taken_at),
      "state": _vm.getFieldState(_vm.$v.input.taken_at)
    }
  }, [_c('b-form-datepicker', {
    attrs: {
      "state": _vm.getFieldState(_vm.$v.input.taken_at)
    },
    model: {
      value: _vm.input.taken_at,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "taken_at", $$v);
      },
      expression: "input.taken_at"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }