var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "3"
    }
  }, [_c('b-input', {
    attrs: {
      "value": _vm.newParams.filters.search,
      "size": "sm",
      "placeholder": "Search exercise..."
    },
    on: {
      "input": _vm.debounceSetFilterSearchParam
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.$store.getters['asset/getAbilityLevelsOptions']
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v(" Fitness Level ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterFitnessLevelId,
      callback: function callback($$v) {
        _vm.filterFitnessLevelId = $$v;
      },
      expression: "filterFitnessLevelId"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.$store.getters['asset/getPrimaryMusclesOptions']
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v(" Primary Muscle ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterMuscleId,
      callback: function callback($$v) {
        _vm.filterMuscleId = $$v;
      },
      expression: "filterMuscleId"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.$store.getters['asset/getWorkoutTypesOptions']
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v(" Workout Type ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterWorkoutType,
      callback: function callback($$v) {
        _vm.filterWorkoutType = $$v;
      },
      expression: "filterWorkoutType"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "1"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "py-1 d-inline-flex",
    attrs: {
      "size": "sm",
      "switch": ""
    },
    model: {
      value: _vm.filterIsFavorite,
      callback: function callback($$v) {
        _vm.filterIsFavorite = $$v;
      },
      expression: "filterIsFavorite"
    }
  }, [_vm._v(" Favorite ")])], 1)], 1), _c('b-table-simple', [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Name")]), _c('b-th', [_vm._v("Muscle")]), _c('b-th', [_vm._v("Type")]), _c('b-th', [_vm._v("Fitness Level")])], 1)], 1), _c('Draggable', {
    attrs: {
      "tag": "b-tbody",
      "disabled": _vm.disabled,
      "group": {
        name: _vm.draggableGroupName,
        pull: 'clone',
        put: false
      },
      "clone": _vm.clone,
      "sort": false,
      "list": _vm.newResult.data
    }
  }, _vm._l(_vm.newResult.data, function (item) {
    return _c('b-tr', {
      key: item.id,
      style: {
        cursor: 'pointer'
      },
      attrs: {
        "data-id": item.id
      }
    }, [_c('b-td', [_vm._v(" " + _vm._s(item.name) + " ")]), _c('b-td', [_c('Muscles', {
      attrs: {
        "ids": item.muscle_ids || []
      }
    })], 1), _c('b-td', [_c('WorkoutTypes', {
      attrs: {
        "ids": item.type_ids
      }
    })], 1), _c('b-td', [_c('Abilities', {
      attrs: {
        "ids": item.fitness_level_ids || []
      }
    })], 1)], 1);
  }), 1)], 1), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }