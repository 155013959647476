<template>
  <span>
    {{ title }}
  </span>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'History2',

  props: {
    ids: {
      type: Array,
      required: true
    }
  },

  computed: {
    title() {
      return _.chain(this.$store.getters['asset/getHistory2Options'])
        .filter((i) => this.ids.includes(i.value))
        .map('text')
        .join(', ')
        .value()
    }
  }
}
</script>