var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "alerts-wrap"
  }, _vm._l(_vm.list, function (item) {
    return _c('b-alert', {
      key: item.id,
      attrs: {
        "variant": item.type,
        "show": ""
      },
      domProps: {
        "innerHTML": _vm._s(item.text)
      }
    });
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }