var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.newValue.items, function (set, setIndex) {
    return _c('b-row', {
      key: setIndex,
      staticClass: "mb-2",
      attrs: {
        "align-h": "center",
        "align-v": "center"
      }
    }, [_c('b-col', {
      staticClass: "text-secondary",
      style: {
        maxWidth: '70px'
      }
    }, [_vm._v(" Set " + _vm._s(setIndex + 1) + ": ")]), _c('b-col', [_c('b-row', {
      attrs: {
        "no-gutters": "",
        "align-h": "center",
        "align-v": "center"
      }
    }, [_c('b-col', {
      style: {
        maxWidth: '33px',
        fontSize: '12px'
      }
    }, [_vm._v(" Lbs ")]), _c('b-col', [_c('b-form-group', {
      staticClass: "mb-0",
      attrs: {
        "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.newValue.items.$each[setIndex].lbs),
        "state": _vm.getFieldState(_vm.$v.newValue.items.$each[setIndex].lbs)
      }
    }, [_c('b-form-input', {
      attrs: {
        "size": "sm",
        "type": "number",
        "number": "",
        "state": _vm.getFieldState(_vm.$v.newValue.items.$each[setIndex].lbs)
      },
      model: {
        value: set.lbs,
        callback: function callback($$v) {
          _vm.$set(set, "lbs", $$v);
        },
        expression: "set.lbs"
      }
    })], 1)], 1)], 1)], 1), _c('b-col', [_c('b-row', {
      attrs: {
        "no-gutters": "",
        "align-h": "center",
        "align-v": "center"
      }
    }, [_c('b-col', {
      style: {
        maxWidth: '33px',
        fontSize: '12px'
      }
    }, [_vm._v(" Reps ")]), _c('b-col', [_c('b-form-group', {
      staticClass: "mb-0",
      attrs: {
        "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.newValue.items.$each[setIndex].reps),
        "state": _vm.getFieldState(_vm.$v.newValue.items.$each[setIndex].reps)
      }
    }, [_c('b-form-input', {
      attrs: {
        "size": "sm",
        "type": "number",
        "number": "",
        "state": _vm.getFieldState(_vm.$v.newValue.items.$each[setIndex].reps)
      },
      model: {
        value: set.reps,
        callback: function callback($$v) {
          _vm.$set(set, "reps", $$v);
        },
        expression: "set.reps"
      }
    })], 1)], 1)], 1)], 1), _c('b-col', {
      staticClass: "text-secondary p-0",
      style: {
        maxWidth: '25px'
      }
    }, [_c('b-button', {
      staticClass: "p-0",
      attrs: {
        "size": "sm",
        "variant": "text"
      },
      on: {
        "click": function click($event) {
          return _vm.showDeleteConfirmationModal(setIndex);
        }
      }
    }, [_c('b-icon-x')], 1)], 1)], 1);
  }), _c('div', [_c('b-button', {
    staticClass: "p-0 m-0",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.addItem
    }
  }, [_vm._v(" + Add Set ")])], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }