var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.vis ? _c('div', {
    staticClass: "spiner-wrap"
  }, [_c('b-spinner', {
    staticClass: "m-1",
    attrs: {
      "label": "Spinning",
      "variant": "primary"
    }
  })], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }