<template>
  <div>
    <div class="text-right">
      <b-button
        size="sm"
        :variant="showValues ? 'primary': 'outline-primary'"
        @click="showValues = !showValues"
      >
        {{ showValues ? 'Hide numbers' : 'Show numbers' }}
      </b-button>
    </div>

    <v-chart
      :option="option"
      autoresize
      :style="{
        height: '400px'
      }"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import VChart from "vue-echarts"

import { use } from 'echarts/core'
import { GridComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'

use([GridComponent, LineChart, CanvasRenderer])

export default {
  name: 'PhysicalConditionListChart',

  mixins: [
    crudListMixin
  ],

  components: {
     VChart
  },

  props: {
    mode: {
      type: String,
      default: 'weight'
    },

    showLabels: {
      type: Boolean,
      default: true
    },

    curriculum: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'physical-conditions',
      showValues: false
    }
  },

  computed: {
    phaseLength() {
      return this.curriculum.phases.length
    },

    lastPhaseIsEnded() {
      return this.curriculum.phases[3] && this.$moment() >= this.$moment(this.curriculum.phases[3].end_date).add(1, 'day')
    },

    itemsByPhases() {
      return [
        _.find(this.newResult.data, i => i.id === this.curriculum.physical_condition.id),

        this.curriculum.phases[1] ? _.find(this.newResult.data, i => (
          this.$moment(i.taken_at) >= this.$moment(this.curriculum.phases[1].start_date) &&
          this.$moment(i.taken_at) < this.$moment(this.curriculum.phases[1].end_date).add(1, 'day')
        )) : null,

        this.curriculum.phases[2]? _.find(this.newResult.data, i => (
          this.$moment(i.taken_at) >= this.$moment(this.curriculum.phases[2].start_date) &&
          this.$moment(i.taken_at) < this.$moment(this.curriculum.phases[2].end_date).add(1, 'day')
        )) : null,

        this.curriculum.phases[3] ? _.find(this.newResult.data, i => (
          this.$moment(i.taken_at) >= this.$moment(this.curriculum.phases[3].start_date) &&
          this.$moment(i.taken_at) < this.$moment(this.curriculum.phases[3].end_date).add(1, 'day')
        )) : null,

        this.lastPhaseIsEnded ? _.findLast(this.newResult.data, i => (
          this.$moment(i.taken_at) >= this.$moment(this.curriculum.phases[3].start_date) &&
          this.$moment(i.taken_at) < this.$moment(this.curriculum.phases[3].end_date).add(1, 'day')
        )) : null
      ]
    },

    option() {
      return  {
        renderer: 'canvas',
        color: ['#626ed4'],
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['Start', 'Phase 2', 'Phase 3', 'Phase 4', 'Finish']
        },

        yAxis: {
          type: 'value',
          show: false
        },

        series: [
          {
            name: 'Data1',
            data: _.map(this.itemsByPhases, i => _.get(i, this.mode)),
            type: 'line',
            smooth: true,

            label: {
              show: this.showValues
            }
          }
        ]
      }
    }
  }
}
</script>