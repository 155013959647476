import axios from '@/plugins/axios-instance';
import store from '@/state/store';
import {
  sex,
  supplementFormTypes,
  supplementDoseTypes,
  workoutTypeIcons
} from './asset-data';

export const state = {
  isFetched: null,
  primaryMuscles: null,
  exerciseSubcategories: null,
  exercisePositions: null,
  exerciseBenchPositions: null,
  exerciseHandFeetPlacements: null,
  abilityLevels: null,
  workoutTypes: null,
  goalDirections: null,
  sex: null,
  supplementTypes: null,
  supplementFormTypes: null,
  supplementDoseTypes: null,
  somatotypes: null,
};

export const getters = {
  isFetched(state) {
    return state.isFetched
  },

  getPrimaryMuscles(state) {
    return state.primaryMuscles;
  },

  getPrimaryMusclesOptions(state) {
    let options = state.primaryMuscles
      ? state.primaryMuscles.map((muscle) => {
          return {
            value: muscle.id,
            text: muscle.name,
          };
        })
      : [];

    return options;
  },

  getAbilityLevelsOptions(state) {
    let options = state.abilityLevels
      ? state.abilityLevels.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];

    return options;
  },

  getWorkoutTypesOptions(state) {
    let options = state.workoutTypes
      ? state.workoutTypes.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];

    return options;
  },

  getGoalDirectionsOptions(state) {
    return state.goalDirections
      ? state.goalDirections.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];
  },

  getSexOptions(state) {
    return state.sex
      ? state.sex.map((item) => {
          return {
            value: item.name.toLowerCase(),
            text: item.name,
          };
        })
      : [];
  },

  getSupplementTypesOptions(state) {
    let options = state.supplementTypes
      ? state.supplementTypes.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];

    return options;
  },

  getSupplementDoseTypesOptions(state) {
    let options = state.supplementDoseTypes
      ? state.supplementDoseTypes.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];

    return options;
  },

  getExerciseSubcategoriesOptions(state) {
    let options = state.exerciseSubcategories
      ? state.exerciseSubcategories.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];

    return options;
  },

  getExercisePositionsOptions(state) {
    let options = state.exercisePositions
      ? state.exercisePositions.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];

    return options;
  },

  getExerciseBenchPositionsOptions(state) {
    let options = state.exerciseBenchPositions
      ? state.exerciseBenchPositions.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];

    return options;
  },

  getExerciseHandFeetPlacementsOptions(state) {
    let options = state.exerciseHandFeetPlacements
      ? state.exerciseHandFeetPlacements.map((item) => {
          return {
            value: item.id,
            text: item.name,
          };
        })
      : [];

    return options;
  },

  getSomatotypesOptions(state) {
    let options = state.somatotypes
      ? state.somatotypes.map((somatotype) => {
          return {
            value: somatotype.id,
            text: somatotype.name,
          };
        })
      : [];

    return options;
  },

  getHistory1Options() {
    let options = [
      { value: 1, text: 'High Blood Pressure' },
      { value: 2, text: 'Bone/Joint Disorder' },
      { value: 3, text: 'Asthma/Respiratory' },
      { value: 4, text: 'Allergies' },
      { value: 5, text: 'High Cholesterol' },
      { value: 6, text: 'Heart Disease' },
      { value: 7, text: 'Heart Conditions' },
      { value: 8, text: 'Smoking' },
      { value: 9, text: 'Diabetes' },
      { value: 10, text: 'Balance Problems' },
      { value: 11, text: 'Under the Care of Medical Professional' },
    ]
    return options
  },

  getHistory2Options() {
    let options = [
      { value: 1, text: 'Head' },
      { value: 2, text: 'Neck' },
      { value: 3, text: 'Wrists' },
      { value: 4, text: 'Hands' },
      { value: 5, text: 'Lower Leg' },
      { value: 6, text: 'Arm' },
      { value: 7, text: 'Elbow' },
      { value: 8, text: 'Thigh' },
      { value: 9, text: 'Knee' },
      { value: 10, text: 'Shoulders or Clavicle' },
      { value: 11, text: 'Hips or Pelvis' },
      { value: 12, text: 'Upper Back' },
      { value: 13, text: 'Lower Back' },
      { value: 14, text: 'Ankle or Feet' },
    ]
    return options
  },

  getWorkingPointsOptions() {
    let options = [
      { value: 1, text: 'Speed' },
      { value: 2, text: 'Power' },
      { value: 3, text: 'Conditioning' },
      { value: 4, text: 'Athlete' },
      { value: 5, text: 'Strengthening Cardiovascular Systems' },
      { value: 6, text: 'Lose Weight' },
      { value: 7, text: 'Tone' },
      { value: 8, text: 'Build Muscle' },
      { value: 9, text: 'Lower Cholesterol' },
      { value: 10, text: 'Sleep Better' },
      { value: 11, text: 'Improve Nutrition' },
      { value: 12, text: 'Lower Blood Pressure' },
      { value: 13, text: 'Feel Better' },
      { value: 14, text: 'Improve Flexibility' },
      { value: 15, text: 'Gain Strength' },
      { value: 16, text: 'Rehabilitate' },
    ]
    return options
  },

  getBillingIntervalOptions() {
    let options = [
      { value: 'day', text: 'Day' },
      { value: 'week', text: 'Week' },
      { value: 'month', text: 'Month' },
      { value: 'year', text: 'Year' },
    ]
    return options
  },

  getCurriculumTermOptions() {
    let options = [
      { value: 8, text: '8 Weeks' },
      { value: 16, text: '16 Weeks' },
      { value: 32, text: '32 Weeks' }
    ]
    return options
  },

  getImageCategoriesOptions() {
    return [
      { value: 'front', text: "Front" },
      { value: 'side_1', text: "Side 1" },
      { value: 'back', text: "Back" },
      { value: 'side_2', text: "Side 2" },
      { value: 'front_double_bicep', text: "Front Double Bicep" },
      { value: 'front_lat_spread', text: "Front Lat Spread" },
      { value: 'side_chest', text: "Side Chest" },
      { value: 'side_tricep', text: "Side Tricep" },
      { value: 'rear_double_bicep', text: "Rear Double Bicep" },
      { value: 'rear_lat_spread', text: "Rear Lat Spread" },
      { value: 'hands_on_hips_most_muscular', text: "Hands On Hips Most Muscular" },
      { value: 'hands_on_head_abdominal', text: "Hands on Head Abdominal" },
      { value: 'most_muscular', text: "Most Muscular" }
    ]
  },

  getMealTypesOptions() {
    return [
      { value: 'main', text: 'Main' },
      { value: 'pre-workout', text: 'Pre-workout' },
      { value: 'post-workout', text: 'Post-workout' }
    ]
  },

  getCardioMachinesOptions() {
    return [
      { value: 1, text: "Treadmill" },
      { value: 2, text: "Elliptical" },
      { value: 3, text: "Other" }
    ]
  },

  getDietaryRestrictionsOptions() {
    return [
      { value: 1, text: "Vegan" },
      { value: 2, text: "Vegetarian" },
      { value: 3, text: "Gluten Free" },
      { value: 4, text: "Diabetic" },
      { value: 5, text: "Keto" },
      { value: 6, text: "Paleo" },
      { value: 7, text: "Low Carb" }
    ]
  },

  getFoodServingUnitsOptions() {
    return [
      { value: 'grams', text: 'Grams' },
      { value: 'ounces', text: 'Ounces' },
      { value: 'cups', text: 'Cups' },
      { value: 'scoops', text: 'Scoops' },
      { value: 'tablets', text: 'Tablets' }
    ]
  },

  getRolesOptions() {
    let options = [
      { value: 1, text: 'Client' },
      { value: 2, text: 'Trainer' },
      { value: 3, text: 'Owner' },
      { value: 4, text: 'Manager' },
      { value: 5, text: 'Sales agent' }
    ]
    return options
  }
};

export const mutations = {
  SET_SOMATOTYPES(state, value) {
    state.somatotypes = value
  },

  SET_PRIMARY_MUSCLES(state, value) {
    state.primaryMuscles = value;
    // saveState('asset.primaryMuscles', value);
  },

  SET_ABILITY_LEVELS(state, value) {
    state.abilityLevels = value;
    // saveState('asset.abilityLevels', value);
  },

  SET_WORKOUT_TYPES(state, value) {
    state.workoutTypes = value;
    // saveState('asset.workoutTypes', value);
  },

  SET_GOAL_DIRECTIONS(state, value) {
    state.goalDirections = value;
    // saveState('asset.goalDirections', value);
  },

  SET_EXERCISE_SUBCATEGORIES(state, value) {
    state.exerciseSubcategories = value;
    // saveState('asset.exerciseSubcategories', value);
  },

  SET_EXERCISE_POSITIONS(state, value) {
    state.exercisePositions = value;
  },

  SET_EXERCISE_BENCH_POSITIONS(state, value) {
    state.exerciseBenchPositions = value;
  },

  SET_EXERCISE_HAND_FEET_PLACEMENTS(state, value) {
    state.exerciseHandFeetPlacements = value;
  },

  SET_SEX(state, value) {
    state.sex = value;
    // saveState('asset.sex', value);
  },

  SET_SUPPLEMENT_TYPES(state, value) {
    state.supplementTypes = value;
    // saveState('asset.supplementTypes', value);
  },

  SET_SUPPLEMENT_FORM_TYPES(state, value) {
    state.supplementFormTypes = value;
    // saveState('asset.supplementFormTypes', value);
  },

  SET_SUPPLEMENT_DOSE_TYPES(state, value) {
    state.supplementDoseTypes = value;
    // saveState('asset.supplementDoseTypes', value);
  },

  SET_IS_FETCHED(state, value) {
    state.isFetched = value;
    // saveState('asset.isFetched', value);
  }
};

export const actions = {
  // eslint-disable-next-line no-unused-vars
  setPrimaryMuscles({ commit, state, rootState }, { data }) {
    commit('SET_PRIMARY_MUSCLES', data);
  },

  // eslint-disable-next-line no-unused-vars
  async fetchAssetData({ commit, state, dispatch }) {
    axios
      .get('asset')
      .then(async ({ data }) => {
        const modelData = data.data;
        if (!modelData) return;

        if (modelData.abilities) {
          commit('SET_ABILITY_LEVELS', modelData.abilities);
        }
        if (modelData.goal_direction) {
          commit('SET_GOAL_DIRECTIONS', modelData.goal_direction);
        }
        if (modelData.muscles) {
          commit('SET_PRIMARY_MUSCLES', modelData.muscles);
        }
        if (modelData.exercise_subcategories) {
          commit('SET_EXERCISE_SUBCATEGORIES', modelData.exercise_subcategories);
        }
        if (modelData.exercise_positions) {
          commit('SET_EXERCISE_POSITIONS', modelData.exercise_positions);
        }
        if (modelData.exercise_bench_positions) {
          commit('SET_EXERCISE_BENCH_POSITIONS', modelData.exercise_bench_positions);
        }
        if (modelData.exercise_hand_feet_placements) {
          commit('SET_EXERCISE_HAND_FEET_PLACEMENTS', modelData.exercise_hand_feet_placements);
        }
        if (modelData.supplement_types) {
          commit('SET_SUPPLEMENT_TYPES', modelData.supplement_types);
        }
        if (modelData.somatotypes) {
          commit('SET_SOMATOTYPES', modelData.somatotypes);
        }
        if (modelData.workout_types) {
          const workout_types = modelData.workout_types.map((type) => {
            return Object.assign({}, type, {
              faicon: workoutTypeIcons[type.id] ?
                      workoutTypeIcons[type.id] :
                      ''
            });
          });
          commit('SET_WORKOUT_TYPES', workout_types);
        }
        commit('SET_IS_FETCHED', true)
      })
      .catch(() => {
        store.commit('layout/addAlerts', {
          type: 'warning',
          text: 'Could not load asset data',
        });
      });

    commit('SET_SEX', sex);
    commit('SET_SUPPLEMENT_FORM_TYPES', supplementFormTypes);
    commit('SET_SUPPLEMENT_DOSE_TYPES', supplementDoseTypes);
  },
  clearData({ commit }) {
    commit('SET_SOMATOTYPES', null);
    commit('SET_PRIMARY_MUSCLES', null);
    commit('SET_ABILITY_LEVELS', null);
    commit('SET_WORKOUT_TYPES', null);
    commit('SET_GOAL_DIRECTIONS', null);
    commit('SET_SUPPLEMENT_TYPES', null);
    commit('SET_EXERCISE_SUBCATEGORIES', null);
    commit('SET_SEX', null);
    commit('SET_SUPPLEMENT_FORM_TYPES', null);
    commit('SET_SUPPLEMENT_DOSE_TYPES', null);
    commit('SET_IS_FETCHED', false);
  },
};

// ===
// Private helpers
// ===
/*
function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
*/