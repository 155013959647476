<template>
  <Layout>
    <template v-if="$acl.check('isClient')">
      <b-alert
        v-if="!currentUser.curriculum"
        show
        class="my-3"
        variant="warning"
      >
        You have no current curriculum
      </b-alert>

      <b-alert
        v-else-if="!curriculumIsActive"
        show
        class="my-3"
        variant="warning"
      >
        Your curriculum is {{ currentUser.curriculum.status }}
      </b-alert>

      <b-alert
        v-else-if="!curriculumIsStarted"
        show
        class="my-3"
        variant="warning"
      >
        Your curriculum is not started yet
      </b-alert>

      <CurriculumItemDashboard
        v-else
        :item="{ id: currentUser.curriculum.id }"
      />
    </template>

    <b-alert
      v-else-if="$acl.check('isTrainer')"
      show
      class="my-3"
      variant="info"
    >
      Trainers's Dashboard
    </b-alert>

    <b-alert
      v-else
      show
      class="my-3"
      variant="info"
    >
      User's Dashboard
    </b-alert>
  </Layout>
</template>

<script>
import Layout from '@/router/layouts/main'
import CurriculumItemDashboard from '@/components/curriculums/detail/CurriculumItemDashboard'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardPage',

  components: {
    Layout,
    CurriculumItemDashboard
  },

  page() {
    return {
      title: this.title
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    title() {
      return 'Dashboard'
    },


    curriculumIsActive() {
      return this.currentUser.curriculum.status === 'active'
    },

    curriculumIsStarted() {
      return this.currentUser.curriculum ? (
        this.currentUser.curriculum.status !== 'designing' &&
        this.$moment().startOf('day') >= this.$moment(this.currentUser.curriculum.start_date)
      ) : null
    }
  }
}
</script>