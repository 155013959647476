<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <b-dropdown-item
      :to="isEditable && $acl.check('couldBuildCurriculum') ? `/curriculums/${item.id}` : `/curriculums/${item.id}/view`"
    >
      Details
    </b-dropdown-item>

    <b-dropdown-item
      v-if="item.status !== 'designing'"
      :to="`/curriculums/${item.id}/logs`"
    >
      Logs
    </b-dropdown-item>

    <CurriculumItemRebootButton
      v-if="isRebootable && isOwner"
      :item="{ id: item.id }"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @rebooted:item="$emit('rebooted:item', $event)"
    >
      Reboot
    </CurriculumItemRebootButton>

    <template v-if="isCompletable">
      <CurriculumItemCompleteButton
        v-if="!item.parent_id && $acl.check('couldBuildCurriculum')"
        :item="item"
        tag="b-dropdown-item-button"
        :tag-props="{
          buttonClass: 'font-size-14'
        }"
        @completed:item="$emit('completed:item', $event)"
      >
        Complete
      </CurriculumItemCompleteButton>

      <CurriculumItemCompleteButton
        v-if="item.parent_id && isOwner"
        :item="item"
        tag="b-dropdown-item-button"
        :tag-props="{
          buttonClass: 'font-size-14'
        }"
        @completed:item="$emit('completed:item', $event)"
      >
        Complete
      </CurriculumItemCompleteButton>
    </template>

    <template v-if="isCancelable">
      <CurriculumItemCancelButton
        v-if="!item.parent_id && $acl.check('couldBuildCurriculum')"
        :item="item"
        tag="b-dropdown-item-button"
        :tag-props="{
          buttonClass: 'font-size-14'
        }"
        @canceled:item="$emit('canceled:item', $event)"
      >
        Cancel
      </CurriculumItemCancelButton>

      <CurriculumItemCancelButton
        v-if="item.parent_id && isOwner"
        :item="item"
        tag="b-dropdown-item-button"
        :tag-props="{
          buttonClass: 'font-size-14'
        }"
        @canceled:item="$emit('canceled:item', $event)"
      >
        Deactivate
      </CurriculumItemCancelButton>
    </template>

    <CurriculumItemDeleteButton
      v-if="isDeletable && $acl.check('couldBuildCurriculum')"
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @deleted:item="$emit('deleted:item', $event)"
    >
      Delete
    </CurriculumItemDeleteButton>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import CurriculumItemDeleteButton from './CurriculumItemDeleteButton'
import CurriculumItemRebootButton from './CurriculumItemRebootButton'
import CurriculumItemCancelButton from './CurriculumItemCancelButton'
import CurriculumItemCompleteButton from './CurriculumItemCompleteButton'

export default {
  name: 'CurriculumItemActionsButton',

  components: {
    CurriculumItemDeleteButton,
    CurriculumItemRebootButton,
    CurriculumItemCancelButton,
    CurriculumItemCompleteButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    isOwner() {
      return (this.item.client_id || this.item.client?.id) === this.currentUser.id
    },

    isRebootable() {
      return ['completed', 'canceled'].includes(this.item.status)
    },

    isEditable() {
      return !['completed', 'canceled'].includes(this.item.status)
    },

    isCancelable() {
      return !['completed', 'canceled', 'designing'].includes(this.item.status)
    },

    isCompletable() {
      return !['completed', 'canceled', 'designing'].includes(this.item.status)
    },

    isDeletable() {
      return ['designing'].includes(this.item.status)
    }
  }
}
</script>