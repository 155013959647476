var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    style: {
      backgroundColor: '#d7dbf4'
    },
    attrs: {
      "body-class": "p-3"
    }
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('b-avatar', {
    attrs: {
      "src": _vm.item.client.profile_img_url,
      "size": "4rem"
    }
  })], 1), _c('div', {
    staticClass: "text-center mt-2 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.item.client.first_name) + " " + _vm._s(_vm.item.client.last_name) + " ")]), _c('div', {
    staticClass: "text-center"
  }, [_c('b-button', {
    staticClass: "p-0 pt-2",
    attrs: {
      "variant": "link",
      "size": "sm"
    },
    on: {
      "click": function click($event) {
        _vm.popupIsShown = true;
      }
    }
  }, [_vm._v(" Show summary ")])], 1), _c('CurriculumStatus', {
    style: {
      position: 'absolute',
      top: '10px',
      right: '10px'
    },
    attrs: {
      "item": _vm.item
    }
  }), _c('b-modal', {
    attrs: {
      "content-class": "rounded-0 border-0",
      "body-class": "p-0",
      "header-bg-variant": "dark",
      "header-text-variant": "white",
      "header-class": "rounded-0 p-4",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.item.client.first_name) + " " + _vm._s(_vm.item.client.last_name) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.popupIsShown,
      callback: function callback($$v) {
        _vm.popupIsShown = $$v;
      },
      expression: "popupIsShown"
    }
  }, [_c('CurriculumItemProfileSummary', {
    staticClass: "mb-0",
    attrs: {
      "item": _vm.item
    },
    on: {
      "update:item": function updateItem($event) {
        return _vm.$emit('update:item', $event);
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }