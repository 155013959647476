var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "fixed": "",
      "striped": "",
      "stacked": "sm"
    }
  }, [_c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "text-left"
  }, [_vm._v("Supplement")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Amount")]), _c('b-th', {
    staticClass: "w-50px"
  })], 1)], 1), _c('b-tbody', [_vm._l(_vm.newResult.data, function (item) {
    return _c('b-tr', {
      key: item.id
    }, [_c('b-th', [_c('TrackerSupplementItemUpsertButton', {
      staticClass: "p-0 font-weight-bolder",
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": function submitted($event) {
          return _vm.$emit('submitted:item', $event);
        }
      }
    }, [_c('b-badge', {
      staticClass: "mr-2",
      attrs: {
        "variant": "success"
      }
    }, [_vm._v(" " + _vm._s(_vm.$moment(item.taken_at, 'HH:mm:ss').format('hh:mm A')) + " ")]), _vm._v(" " + _vm._s(item.supplement.name) + " ")], 1)], 1), _c('b-td', {
      staticClass: "text-center align-middle",
      attrs: {
        "stacked-heading": "Calories"
      }
    }, [_c('TrackerSupplementItemUpsertButton', {
      staticClass: "p-0 font-weight-bolder",
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": function submitted($event) {
          return _vm.$emit('submitted:item', $event);
        }
      }
    }, [_vm._v(" " + _vm._s(item.amount) + " "), _c('SupplementDoseTypes', {
      attrs: {
        "ids": [item.supplement.dose_type],
        "lower": ""
      }
    })], 1)], 1), _c('b-td', {
      staticClass: "text-center align-middle"
    }, [_c('TrackerSupplementItemDeleteButton', {
      staticClass: "font-size-12",
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "delete:item": function deleteItem($event) {
          return _vm.$emit('delete:item', $event);
        }
      }
    }, [_c('b-icon-trash')], 1)], 1)], 1);
  }), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "colspan": "3"
    }
  }, [_c('b-row', {
    attrs: {
      "align": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('TrackerSupplementItemUpsertButton', {
    staticClass: "p-0 mr-4",
    attrs: {
      "item": {
        nutrition_id: _vm.newParams.filters.nutrition_id
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('submitted:item', $event);
      }
    }
  }, [_c('b-icon-plus-circle', {
    staticClass: "mr-1",
    attrs: {
      "scale": "0.8"
    }
  }), _vm._v(" Add Supplement ")], 1)], 1)], 1)], 1)], 1)], 2)], 1), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }