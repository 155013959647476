<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      fixed
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <!-- NAME -->
      <template #cell(name)="row">
        <div class="font-weight-bold font-size-16 mb-1">
          <b-badge
            v-if="row.item.type === 'reboot'"
            class="text-uppercase"
          >
            {{ row.item.type }}
          </b-badge> {{ row.item.name }}
        </div>

        <div>
          {{ row.item.description }}
        </div>
      </template>

      <!-- GOAL DIRECTION -->
      <template #cell(goal_direction_id)="row">
        <GoalDirections :ids="[row.item.goal_direction_id]" />
      </template>

      <!-- PRICE PER WEEK -->
      <template #cell(price_per_week)="row">
        <div>
          ${{ row.item.price_per_week }} per week
        </div>
      </template>

      <!-- AGREEMENT -->
      <template #cell(agreement_url)="row">
        <b-button
          v-if="$acl.check('isOwner')"
          variant="success"
          size="sm"
          :href="row.item.agreement_url"
          target="_blank"
        >
          View Agreement
        </b-button>

        <b-form-checkbox
          v-else-if="
            $acl.check('isClient') &&
            currentUser.goal_direction === row.item.goal_direction_id &&
            !currentUser.curriculum
          "
          v-model="agreementsCheck[row.item.id]"
        >
          Check to Accept <a
            :href="row.item.agreement_url"
            target="_blank"
          >Agreement</a>
        </b-form-checkbox>
      </template>

      <!-- ACTIONS -->
      <template #cell(actions)="row">
        <CurriculumProductItemUpsertButton
          v-if="$acl.check('isOwner')"
          :item="row.item"
          :style="{
            cursor: 'pointer'
          }"
          @update:item="refresh"
        >
          <b-icon-gear />
        </CurriculumProductItemUpsertButton>

        <CurriculumProductItemCheckoutButton
          v-else-if="
            $acl.check('isClient') &&
            currentUser.goal_direction === row.item.goal_direction_id &&
            !currentUser.curriculum
          "
          :item="row.item"
          tag="b-button"
          :tag-props="{
            size: 'sm',
            variant: 'success'
          }"
          :disabled="!agreementsCheck[row.item.id]"
          @update:item="refresh"
        >
          <b-icon-cart /> Buy
        </CurriculumProductItemCheckoutButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import crudListMixin from '@/mixins/crud-list'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import CurriculumProductItemUpsertButton from './buttons/CurriculumProductItemUpsertButton'
import CurriculumProductItemCheckoutButton from './buttons/CurriculumProductItemCheckoutButton'

export default {
  name: 'CurriculumProductList',

  mixins: [
    crudListMixin
  ],

  components: {
    GoalDirections,
    CurriculumProductItemUpsertButton,
    CurriculumProductItemCheckoutButton
  },

  props: {
    columns: {
      type: Array,

      default: () => ([
        'name',
        'goal_direction_id',
        'price_per_week',
        'agreement_url',
        'actions'
      ])
    }
  },

  data() {
    return {
      baseEndpoint: 'curriculum-products',
      agreementsCheck: {}
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    fields() {
      const fields = [
        { key: 'name', sortable: false, label: 'Product' },
        { key: 'description', sortable: false },
        { key: 'goal_direction_id', sortable: false, label: 'Goal Direction' },
        { key: 'price_per_week', sortable: false, label: 'Price' },
        { key: 'stripe_product_id', sortable: false, label: 'Stripe Product' },
        { key: 'reboot_stripe_product_id', sortable: false, label: 'Reboot Stripe Product' },
        { key: 'agreement_url', sortable: false, label: 'Agreement' },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'w-100px text-right' }
      ]

      return _.filter(fields, i => this.columns.includes(i.key))
    }
  },

  watch: {
    newResult: {
      immediate: true,
      deep: true,
      handler() {
        // Reset agreementsCheck
        const agreementsCheck = {}

        if (this.newResult.data) {
          for (let i = 0; i < this.newResult.data.length; i++) {
            const item = this.newResult.data[i]
            agreementsCheck[item.id] = false
          }
        }

        this.agreementsCheck = agreementsCheck
      }
    }
  }
}
</script>