<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Name"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.food_id)"
        :state="getFieldState($v.input.food_id)"
      >
        <template v-if="$v.input.food_id?.$params?.required" #label>
          Name <span class="text-danger">*</span>
        </template>

        <FoodSelect
          v-model="input.food_id"
          :item.sync="newFood"
          placeholder="Select a food or add new..."
          :state="getFieldState($v.input.food_id)"
        />
      </b-form-group>

      <!-- CALORIES -->
      <b-form-group
        label="Calories"
        label-cols-sm="4"
      >
        <PerHundredGramsInput
          :value="newFood?.calories"
          unit="ounces"
          unit-disabled
          :decimal-places="1"
          :input-props="{ disabled: true }"
        />
      </b-form-group>

      <!-- CALORIES -->
      <b-form-group
        label="Protein (gm)"
        label-cols-sm="4"
      >
        <PerHundredGramsInput
          :value="newFood?.protein"
          unit="ounces"
          unit-disabled
          :decimal-places="1"
          :input-props="{ disabled: true }"
        />
      </b-form-group>

      <!-- CARBS -->
      <b-form-group
        label="Carbs (gm)"
        label-cols-sm="4"
      >
        <PerHundredGramsInput
          :value="newFood?.carbs"
          unit="ounces"
          unit-disabled
          :decimal-places="1"
          :input-props="{ disabled: true }"
        />
      </b-form-group>

      <!-- CARBS -->
      <b-form-group
        label="Fat (gm)"
        label-cols-sm="4"
      >
        <PerHundredGramsInput
          :value="newFood?.fat"
          unit="ounces"
          unit-disabled
          :decimal-places="1"
          :input-props="{ disabled: true }"
        />
      </b-form-group>

      <!-- SODIUM -->
      <b-form-group
        label="Sodium (mg)"
        label-cols-sm="4"
      >
        <PerHundredGramsInput
          :value="newFood?.sodium"
          unit="ounces"
          unit-disabled
          :decimal-places="1"
          :input-props="{ disabled: true }"
        />
      </b-form-group>

      <!-- SERVING UNITS -->
      <b-form-group
        label="Serving units"
        label-cols-sm="4"
      >
        <b-form-select
          :value="newFood?.serving_units"
          :options="$store.getters['asset/getFoodServingUnitsOptions']"
          disabled
        />
      </b-form-group>

      <!-- SERVING SIZE -->
      <b-form-group
        v-if="newFood && !['ounces', 'gramms'].includes(newFood?.serving_units)"
        :label="newFood.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit'"
        label-cols-sm="4"
      >
        <GramsInput
          :value="newFood?.serving_size"
          :input-props="{ disabled: true }"
          :decimal-places="1"
          unit="ounces"
        />
      </b-form-group>

      <b-form-group
        label="Amount"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.amount)"
        :state="getFieldState($v.input.amount)"
      >
        <template v-if="$v.input.amount?.$params?.required" #label>
          Amount <span class="text-danger">*</span>
        </template>

        <b-form-input
          v-if="['grams', 'ounces', undefined].includes(newFood?.serving_units)"
          v-model="input.amount"
          type="number"
          number
          :placeholder="`Enter taken amount in ${newFood?.serving_units || 'serving units'}`"
          :state="getFieldState($v.input.amount)"
        />

        <ServingUnitInput
          v-else
          v-model="input.amount"
          :state="getFieldState($v.input.amount)"
          :unit-label="newFood?.serving_units"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import PerHundredGramsInput from '@/components/inputs/per-hundred-grams-input'
import GramsInput from '@/components/inputs/grams-input'
import { required } from "vuelidate/lib/validators"
import FoodSelect from '@/components/nutritions/FoodSelect'
import ServingUnitInput from '@/components/inputs/serving-unit-input'

export default {
  name: 'CurriculumMealFoodItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    FoodSelect,
    PerHundredGramsInput,
    GramsInput,
    ServingUnitInput
  },

  data() {
    return {
      baseEndpoint: 'curriculum-meal-foods',
      newFood: null
    }
  },

  validations() {
    return {
      input: {
        food_id: { required },
        amount: { required }
      }
    }
  },

  watch: {
    'newItem.food': {
      immediate: true,
      deep: true,

      handler() {
        this.newFood = this.newItem.food
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        amount: _.get(this.newItem, 'amount', null),
        food_id: _.get(this.newItem, 'food.id', null)
      }

      if (!this.isCreated) {
        input.meal_id = _.get(this.newItem, 'meal_id', null)
      }

      return input
    }
  }
}
</script>
