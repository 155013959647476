var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "5"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "7"
    }
  }, [_c('b-button-group', _vm._l(_vm.steps, function (step, stepIndex) {
    return _c('b-button', {
      key: step.id,
      attrs: {
        "variant": stepIndex === _vm.currentStepIndex ? 'primary' : null,
        "disabled": step.isDisabled,
        "to": "#".concat(step.id)
      }
    }, [_vm._v(" " + _vm._s(step.title) + " ")]);
  }), 1)], 1)], 1), _vm.newItem.status === 'designing' ? _c('div', [_c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You can't view logs for Curriculum with status " + _vm._s(_vm.newItem.status) + ". ")])], 1) : _c('b-card', {
    staticClass: "bg-transparent",
    attrs: {
      "body-class": "py-0"
    }
  }, [_vm.currentStep.id === 'workouts' ? _c('TrackerWorkoutList', {
    attrs: {
      "params": {
        filters: {
          curriculum_id: _vm.newItem.id,
          is_performed: true
        }
      }
    }
  }) : _vm.currentStep.id === 'cardios' ? _c('TrackerCardioList', {
    attrs: {
      "params": {
        filters: {
          curriculum_id: _vm.newItem.id,
          is_performed: true
        }
      }
    }
  }) : _vm.currentStep.id === 'nutritions' ? _c('TrackerNutritionList', {
    attrs: {
      "params": {
        filters: {
          curriculum_id: _vm.newItem.id
        }
      }
    }
  }) : _vm._e()], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }