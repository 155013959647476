<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="isValidParams">
      <ScheduleInput
        :events="events"
        :bg-events="curriculum.client.schedule"
        :start-date="$moment(startDate).toDate()"
        :phase="phase"
        :paused-at="curriculum.paused_at ? $moment(curriculum.paused_at).toDate() : null"
        @update:events="onUpdateEvents"
        @bg-event-click="selectedSlot = $event"
        @hour-click="selectedSlot = $event"
      >
        <template v-slot:event-actions="{ event, toggleEventIsActive }">
          <b-dropdown
            v-if="event.type === 'workout'"
            toggle-class="p-0 text-white"
            variant="link"
            size="sm"
            @shown="toggleEventIsActive(true)"
            @hidden="toggleEventIsActive(false)"
          >
            <template #button-content>
              <b-icon
                class="no-drag"
                icon="three-dots"
              />
            </template>

            <CurriculumTrackerWorkoutItemUpsertButton
              :item="{ id: event.id }"
              tag="b-dropdown-item-button"
              @submitted="refresh"
            >
              Workout Details
            </CurriculumTrackerWorkoutItemUpsertButton>

            <TrackerWorkoutItemUpsertButton
              :item="{ id: event.id }"
              tag="b-dropdown-item-button"
              @submitted="refresh"
            >
              Workout Tracker
            </TrackerWorkoutItemUpsertButton>

            <TrackerWorkoutItemDeleteButton
              :item="event"
              tag="b-dropdown-item-button"
              @delete:item="refresh"
            >
              Delete
            </TrackerWorkoutItemDeleteButton>
          </b-dropdown>
        </template>
      </ScheduleInput>

      <b-modal
        v-model="newItemModalIsShown"
        content-class="rounded-0 border-0"
        header-bg-variant="dark"
        header-text-variant="white"
        header-class="rounded-0 p-4"
        body-class="p-0"
        hide-footer
        size="lg"
      >
        <b-card>
          <CurriculumTrackerWorkoutItemUpsert
            v-if="selectedSlot"
            :item="itemToCreate"
            @submitted="selectedSlot = null; refresh()"
          />
        </b-card>
      </b-modal>
    </template>

    <b-alert
      v-else
      show
      variant="warning"
    >
      TrackerWorkoutListSchedule working only with filters between one week
    </b-alert>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import ScheduleInput from '@/components/schedule/ScheduleInput'
import TrackerWorkoutItemDeleteButton from './buttons/TrackerWorkoutItemDeleteButton'
import TrackerWorkoutItemUpsertButton from './buttons/TrackerWorkoutItemUpsertButton'
import CurriculumTrackerWorkoutItemUpsert from '@/components/curriculums/workouts/CurriculumTrackerWorkoutItemUpsert'
import CurriculumTrackerWorkoutItemUpsertButton from '@/components/curriculums/workouts/buttons/CurriculumTrackerWorkoutItemUpsertButton'

export default {
  name: 'TrackerWorkoutListSchedule',

  mixins: [
    crudListMixin
  ],

  components: {
    ScheduleInput,
    TrackerWorkoutItemDeleteButton,
    TrackerWorkoutItemUpsertButton,
    CurriculumTrackerWorkoutItemUpsertButton,
    CurriculumTrackerWorkoutItemUpsert
  },

  props: {
    curriculum: {
      type: Object,
      required: true
    },

    phase: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      baseEndpoint: 'tracker-workouts',
      selectedSlot: null,
      autosave: true,
      debounceSubmitTime: 0,

      cardiosResult: {
        data: null,
        total: null
      }
    }
  },

  computed: {
    startDate() {
      const start_time_from = this.$moment(this.params.filters.start_time_from)
      return start_time_from.subtract(start_time_from.isoWeekday() - 1, 'day').format('YYYY-MM-DD HH:mm:ss')
    },

    isValidParams() {
      const start_time_from = this.$moment(this.params.filters.start_time_from)
      const start_time_to = this.$moment(this.params.filters.start_time_to)
      return start_time_to.isoWeekday() - start_time_from.isoWeekday() <= 7
    },

    newItemModalIsShown: {
      get() {
        return !!this.selectedSlot
      },

      set(value) {
        if (!value) {
          this.selectedSlot = null
        }
      }
    },

    itemToCreate() {
      return this.selectedSlot ? {
        curriculum_phase_id: this.newParams.filters.curriculum_phase_id,
        user_id: this.curriculum.client.id,
        start_time: this.$moment(this.startDate).add(this.selectedSlot.start * 60, 'minute').format('YYYY-MM-DD HH:mm:ss'),
        end_time: this.$moment(this.startDate).add(this.selectedSlot.start * 60 + 60, 'minute').format('YYYY-MM-DD HH:mm:ss')
      } : null
    },

    workoutEvents() {
      return _.map(this.newResult.data, i => {
        const start = (this.$moment(i.start_time).isoWeekday() - 1) * 24 + this.$moment(i.start_time).hour() + this.$moment(i.start_time).minute() / 60
        const end = (this.$moment(i.end_time).isoWeekday() - 1) * 24 + this.$moment(i.end_time).hour() + this.$moment(i.start_time).minute() / 60
        return {
          ...i,
          start,
          duration: end - start,
          type: 'workout'
        }
      })
    },

    cardioEvents() {
      return _.map(this.cardiosResult.data, i => {
        const start = (this.$moment(i.start_time).isoWeekday() - 1) * 24 + this.$moment(i.start_time).hour() + this.$moment(i.start_time).minute() / 60
        const end = (this.$moment(i.end_time).isoWeekday() - 1) * 24 + this.$moment(i.end_time).hour() + this.$moment(i.start_time).minute() / 60
        return {
          ...i,
          start,
          duration: end - start,
          type: 'cardio',
          isStatic: true
        }
      })
    },

    events() {
      return [...this.workoutEvents, ...this.cardioEvents]
    }
  },

  created() {
    this.fetchCardios()
  },

  methods: {
    getInput() {
      return {
        items: _.map(this.workoutEvents, i => ({
          id: i.id,
          start_time: i.start_time,
          end_time: i.end_time
        }))
      }
    },

    async onUpdateEvents(events) {
      const workoutEvents = _.filter(events, { type: 'workout' })

      this.input = {
        items: _.map(workoutEvents, i => ({
          id: i.id,
          start_time: this.$moment(this.startDate).add(i.start * 60, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          end_time: this.$moment(this.startDate).add(i.start * 60 + i.duration * 60, 'minute').format('YYYY-MM-DD HH:mm:ss')
        }))
      }
    },

    afterSubmit() {
      this.refresh()
    },

    async fetchCardios() {
      const endpoint = 'tracker-cardios'
      const limit = this.newParams.limit
      const page = this.newParams.page
      const filters = this.newParams.filters
      const order_by = this.newParams.order_by
      const order_desc = this.newParams.order_desc
      this.isFetching = true

      await this.$axios
        .get(endpoint, {
          params: { limit, page, order_by, order_desc, ...filters }
        })

        .then(({ data }) => {
          this.isFetching = false
          this.cardiosResult.data = data.data
          this.cardiosResult.total = data.total
        })

        .catch((e) => {
          this.isFetching = false
          throw e
        })
    }
  }
}
</script>