var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "4"
    }
  }, [_vm.$acl.check('couldAddWorkout') ? _c('WorkoutItemUpsertButton', {
    attrs: {
      "tag": "b-button",
      "tag-props": {
        variant: 'primary'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$router.push("/workout/".concat($event.id));
      }
    }
  }, [_vm._v(" Add Workout ")]) : _vm._e()], 1)], 1), _c('b-card', [_c('WorkoutList', {
    ref: "list",
    attrs: {
      "params": {
        filters: {
          muscle_ids: [],
          fitness_level_ids: [],
          is_private: null,
          is_favorite: null
        },
        order_desc: true
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }