var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', _vm._b({
    attrs: {
      "right": "",
      "text": "Actions"
    },
    scopedSlots: _vm._u([_vm.$slots["default"] ? {
      key: "button-content",
      fn: function fn() {
        return [_vm._t("default")];
      },
      proxy: true
    } : null], null, true)
  }, 'b-dropdown', _vm.dropdownProps, false), [_c('WorkoutItemDetailButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      },
      "prefetch": ""
    }
  }, [_vm._v(" Detail ")]), _vm.showEditButton ? _c('b-dropdown-item', {
    attrs: {
      "to": "/workout/".concat(_vm.item.id)
    }
  }, [_vm._v(" Edit ")]) : _vm._e(), _vm.$acl.check('couldCopyWorkout') ? _c('WorkoutItemUpsertButton', {
    attrs: {
      "source": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('copied', $event);
      }
    }
  }, [_vm._v(" Copy ")]) : _vm._e(), _vm.showDeleteButton ? _c('WorkoutItemDeleteButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "deleted:item": function deletedItem($event) {
        return _vm.$emit('deleted:item', $event);
      },
      "submitted": function submitted($event) {
        return _vm.$emit('submitted', $event);
      }
    }
  }, [_vm._v(" Delete ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }