<template>
  <b-overlay
    :show="isFetching"
  >
    <ScheduleInput
      :events="events"
      :bg-events="daySchedule"
      :start-date="$moment(date).toDate()"
      :days-count="1"
      :show-days="false"
      :paused-at="curriculum.paused_at ? $moment(curriculum.paused_at).toDate() : null"
      disabled
    >
      <template v-slot:event-actions="{ event, toggleEventIsActive }">
        <template v-if="curriculum.status === 'active' || event.is_performed">
          <b-dropdown
            v-if="event.type === 'workout'"
            toggle-class="p-0 text-white"
            variant="link"
            size="sm"
            @shown="toggleEventIsActive(true)"
            @hidden="toggleEventIsActive(false)"
          >
            <template #button-content>
              <b-icon
                class="no-drag"
                icon="three-dots"
              />
            </template>
            <TrackerWorkoutItemUpsertButton
              :item="{ id: event.id }"
              tag="b-dropdown-item-button"
              @submitted="fetchEvents"
            >
              Workout Tracker
            </TrackerWorkoutItemUpsertButton>
          </b-dropdown>

          <b-dropdown
            v-if="event.type === 'cardio'"
            toggle-class="p-0 text-white"
            variant="link"
            size="sm"
            @shown="toggleEventIsActive(true)"
            @hidden="toggleEventIsActive(false)"
          >
            <template #button-content>
              <b-icon
                class="no-drag"
                icon="three-dots"
              />
            </template>

            <TrackerCardioItemUpsertButton
              :item="{ id: event.id }"
              tag="b-dropdown-item-button"
              @submitted="fetchEvents"
            >
              Cardio Tracker
            </TrackerCardioItemUpsertButton>
          </b-dropdown>
        </template>
      </template>
    </ScheduleInput>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import ScheduleInput from '@/components/schedule/ScheduleInput'
import TrackerWorkoutItemUpsertButton from '@/components/trackers/workouts/buttons/TrackerWorkoutItemUpsertButton'
import TrackerCardioItemUpsertButton from '@/components/trackers/cardios/buttons/TrackerCardioItemUpsertButton'

export default {
  name: 'CurriculumItemScheduleDayDetail',

  mixins: [
    crudListMixin
  ],

  components: {
    ScheduleInput,
    TrackerWorkoutItemUpsertButton,
    TrackerCardioItemUpsertButton
  },

  props: {
    date: {
      type: Date,
      required: true
    },

    curriculum: {
      type: Object,
      required: true
    },

    prefetch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      workoutsResult: {
        data: null,
        total: null
      },

      cardiosResult: {
        data: null,
        total: null
      }
    }
  },

  computed: {
    daySchedule() {
      return _.chain(this.curriculum.client.schedule)
        .filter(i => Math.floor(i.start / 24) + 1 === this.$moment(this.date).day())
        .filter(i => ['work', 'sleep'].includes(i.type))
        .map(i => ({ ...i, start: i.start % 24 }))
        .value()
    },

    workoutEvents() {
      return _.map(this.workoutsResult.data, i => {
        const start = this.$moment(i.start_time).hour() + this.$moment(i.start_time).minute() / 60
        const end = this.$moment(i.end_time).hour() + this.$moment(i.start_time).minute() / 60
        return {
          ...i,
          start,
          duration: end - start,
          type: 'workout'
        }
      })
    },

    cardioEvents() {
      return _.map(this.cardiosResult.data, i => {
        const start = this.$moment(i.start_time).hour() + this.$moment(i.start_time).minute() / 60
        const end = this.$moment(i.end_time).hour() + this.$moment(i.start_time).minute() / 60
        return {
          ...i,
          start,
          duration: end - start,
          type: 'cardio'
        }
      })
    },

    events() {
      return [...this.workoutEvents, ...this.cardioEvents]
    }
  },

  created() {
    this.fetchEvents()
  },

  methods: {
    async fetchEvents() {
      const limit = this.newParams.limit
      const page = this.newParams.page
      const order_by = this.newParams.order_by
      const order_desc = this.newParams.order_desc

      const filters = {
        ...this.newParams.filters,
        curriculum_id: this.curriculum.id,
        start_time_from: this.$moment(this.date).format('YYYY-MM-DD'),
        start_time_to: this.$moment(this.date).add(1, 'day').format('YYYY-MM-DD')
      }

      this.isFetching = true

      await this.$axios
        .get('tracker-cardios', {
          params: { limit, page, order_by, order_desc, ...filters }
        })

        .then(({ data }) => {
          this.isFetching = false
          this.cardiosResult.data = data.data
          this.cardiosResult.total = data.total
        })

        .catch((e) => {
          this.isFetching = false
          throw e
        })

      await this.$axios
        .get('tracker-workouts', {
          params: { limit, page, order_by, order_desc, ...filters }
        })

        .then(({ data }) => {
          this.isFetching = false
          this.workoutsResult.data = data.data
          this.workoutsResult.total = data.total
        })

        .catch((e) => {
          this.isFetching = false
          throw e
        })

      this.isFetching = false
    }
  }
}
</script>