var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.weeks, function (week, weekIndex) {
    return _c('b-card', {
      key: weekIndex,
      staticClass: "bg-tab"
    }, [_c('TrackerCardioListSchedule', {
      attrs: {
        "params": {
          filters: {
            curriculum_phase_id: _vm.item.id,
            start_time_from: week.start_date_from,
            start_time_to: week.start_date_to
          }
        },
        "phase": _vm.item,
        "curriculum": _vm.curriculum
      }
    })], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }