<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- WORKOUT -->
      <b-form-group label="Workout">
        <b-card
          class="bg-white"
          no-body
        >
          <template v-if="isFetched || !isCreated">
            <WorkoutSelect
              v-if="isCreated && !newItem.created_by_id"
              v-model="input.workout_id"
              search-disabled
              :params="{
                filters: {},
              }"
              :result="{
                data: phaseWorkouts
              }"
              :prefetch="false"
            />

            <WorkoutSelect
              v-else
              v-model="input.workout_id"
              :params="{
                filters: {}
              }"
            />
          </template>
        </b-card>
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting || !inputIsDirty"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import WorkoutSelect from '@/components/workouts/WorkoutSelect'

export default {
  name: 'CurriculumTrackerWorkoutItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    WorkoutSelect
  },

  data() {
    return {
      baseEndpoint: 'tracker-workouts'
    }
  },

  computed: {
    phaseWorkouts() {
      const phaseNumber = this.newItem.curriculum_phase?.number;

      if (phaseNumber) {
        const weeks = this.newItem.curriculum_phase?.workout_program.phases[phaseNumber - 1]?.weeks;
        return weeks.map(i => i.workouts)
          .flat()
          .reduce(({ ids, items }, item, index, array) => {
            if (!ids.includes(item.id)) {
              ids.push(item.id)
              items.push(item)
            }
            return (index + 1) === array.length ? items : { ids, items }
          }, { ids: [], items: [] });
      }

      return [];
    }
  },

  validations() {
    return {
      input: {
        workout_id: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        workout_id: this.newItem.workout_id || this.newItem.workout?.id,
      }

      if (!this.isCreated) {
        input.user_id = this.newItem.user_id;
        input.curriculum_phase_id = this.newItem.curriculum_phase_id;
        input.start_time = this.newItem.start_time;
        input.end_time = this.newItem.end_time;
      }

      return input;
    }
  }
}
</script>
