<template>
  <b-overlay :show="isFetching">
    <b-form @submit.prevent="submit('submitted')">
      <b-tabs
        v-model="activeInputPhaseIndex"
        active-nav-item-class="bg-tab text-primary"
        nav-class="border-0"
      >
        <b-tab
          v-for="(phase, phaseIndex) in input.phases"
          :key="phase.id"
          class="p-2 bg-tab"
          title-link-class="rounded-0 border-0"
          :title="`Phase ${getPhaseNumber(phase.id)}`"
        >
          <b-alert
            :show="!phase.client_notes.length"
            class="my-0"
            variant="light"
          >
            There are no notes yet.
          </b-alert>
          
          <b-form-group
            v-for="(note, noteIndex) in phase.client_notes"
            :key="noteIndex"
            class="my-2"
            :invalid-feedback="getFieldInvalidFeedback($v.input.phases.$each[phaseIndex].client_notes.$each[noteIndex])"
            :state="getFieldState($v.input.phases.$each[phaseIndex].client_notes.$each[noteIndex])"
          >
            <b-collapse
              :visible="noteIndex !== activeInputNoteIndex"
            >
              <b-alert
                class="mb-0"
                show
                dismissing
              >
                <div>
                  {{ note.text }}
                </div>

                <div class="text-right">
                  <b-button
                    variant="link"
                    size="sm"
                    @click="activeInputNoteIndex = noteIndex"
                  >
                    Edit
                  </b-button>

                  <b-button
                    variant="link"
                    size="sm"
                    @click="showDeleteConfirmationModal(noteIndex)"
                  >
                    Delete
                  </b-button>
                </div>
              </b-alert>
            </b-collapse>

            <b-collapse
              :visible="noteIndex === activeInputNoteIndex"
            >
              <b-form-textarea
                v-model="note.text"
                rows="6"
                max-rows="6"
              />

              <div class="text-right">
                <b-button
                  variant="link"
                  size="sm"
                  @click="activeInputNoteIndex = null"
                >
                  Close
                </b-button>
              </div>
            </b-collapse>
          </b-form-group>
        </b-tab>
      </b-tabs>

      <!-- SUBMIT -->
      <b-form-group class="text-right ml-2 mr-2 mt-2">
        <b-button
          variant="success"
          :disabled="isSubmitting"
          @click="addNote"
        >
          New Note
        </b-button>

        <b-button
          class="ml-1"
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'CurriculumItemClientNotesUpdate',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'curriculums',
      activeInputPhaseIndex: 0,
      activeInputNoteIndex: null
    }
  },

  computed: {
    updateEndpoint() {
      return `curriculums/${this.newItem.id}/client-notes`
    },

    activeInputPhase() {
      return this.input.phases[this.activeInputPhaseIndex]
    }
  },

  validations() {
    return {
      input: {
        phases: {
          $each: {
            client_notes: {
              $each: {
                text: { required },
                created_at: { required }
              }
            }
          }
        }
      }
    }
  },

  methods: {
    getPhaseNumber(id) {
      const phase = _.find(this.newItem.phases, { id })
      return phase ? phase.number : null
    },

    addNote() {
      this.activeInputPhase.client_notes.push({
        text: null,
        created_at: this.$moment()
      })
      this.activeInputNoteIndex = this.activeInputPhase.client_notes.length - 1
    },

    deleteNote(noteIndex) {
      this.activeInputPhase.client_notes = _.filter(this.activeInputPhase.client_notes, (note, i) => (
        i !== noteIndex
      ))
    },

    showDeleteConfirmationModal(noteIndex) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this item?', {
        title: 'Please Confirm',
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true
      })
        .then(value => {
          if (value) {
            this.deleteNote(noteIndex)
          }
        })
    },

    getInput() {
      const input = {
        phases: _.map(this.newItem.phases, phase => ({
          id: phase.id,

          client_notes: _.map(phase.client_notes, note => ({
            text: note.text,
            created_at: note.created_at
          }))
        }))
      }

      return input
    }
  }
}
</script>