var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-card', {
    staticClass: "bg-tab"
  }, [_vm.currentPhaseMeals ? [_vm.showCopyButton ? [_c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v(" There are no meals in Phase " + _vm._s(_vm.item.number) + ". ")]), _vm.prevPhaseHasMeals ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.isCopying
    },
    on: {
      "click": function click($event) {
        return _vm.copyCurriculumMeals();
      }
    }
  }, [_vm.isCopying ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Copy Previous Phase ")], 1) : _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.isCopying
    },
    on: {
      "click": function click($event) {
        return _vm.copyCurriculumMeals('typical');
      }
    }
  }, [_vm.isCopying ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Copy Client Meals ")], 1)], 1)] : _c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0",
      "justified": "",
      "no-fade": ""
    }
  }, _vm._l(_vm.days, function (day) {
    return _c('b-tab', {
      key: day.number,
      attrs: {
        "lazy": "",
        "title-link-class": "rounded-0 border-0 font-size-16",
        "title": day.text
      }
    }, [_c('CurriculumPhaseDayMeals', {
      attrs: {
        "item": _vm.item,
        "day-number": day.number,
        "curriculum": _vm.curriculum
      },
      on: {
        "update:result-meals": function updateResultMeals($event) {
          $event.data && !$event.data.length && _vm.currentPhaseHasMeals ? _vm.fetchCurrentPhaseMeals() : null;
        }
      }
    })], 1);
  }), 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }