import { helpers } from "vuelidate/lib/validators";

export const validationEmail = (value) => {
    if (!value) {
        return 'Please enter a valid email address'
    } else if (!/^[^]+@[^]+\.[^]{2,}$/i.test(value)) {
        return 'Email address not in correct format'
    } else if (!/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(value)) {
        return 'Email address should be alphanumeric and following special characters'
    } else {
        return ''
    }
}

export const validationPassword = (value) => {
    if (!value) {
      return 'The Password field is required'
    } else if (value.length < 8) {
      return 'Your password must be at least 8 characters'
    } else if (value.length > 25) {
      return 'Your Password should not be more than 25 characters'
    } else if (/[^A-Za-z0-9!@#$%^&*()_{}[\]/~<>`]/g.test(value)) {
      return 'The Password field allowed alphanumeric and following special characters'
    } else {
      return ''
    }
}

export const strongPassword = helpers.regex('password', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/)