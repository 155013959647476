<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(owners)="row">
        <template v-if="row.item.owners.length">
          {{ row.item.owners.map(i => `${i.first_name} ${i.last_name}`).join(', ') }}
        </template>

        <CompanyItemOwnersUpdateButton
          v-else
          :item="row.item"
          tag="b-button"
          :tag-props="{
            variant: 'danger',
            size: 'sm'
          }"
          @update:item="refresh"
        >
          Add owner
        </CompanyItemOwnersUpdateButton>
      </template>

      <template #cell(actions)="row">
        <CompanyItemActionsButton
          :item="row.item"
          @delete:item="refresh"
          @submitted="refresh"
        >
          <b-icon-three-dots />
        </CompanyItemActionsButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import CompanyItemActionsButton from './buttons/CompanyItemActionsButton'
import CompanyItemOwnersUpdateButton from './buttons/CompanyItemOwnersUpdateButton'

export default {
  name: 'CompanyList',

  mixins: [
    crudListMixin
  ],

  components: {
    CompanyItemActionsButton,
    CompanyItemOwnersUpdateButton
  },

  data() {
    return {
      fields: [
        { key: 'name', sortable: true },
        { key: 'owners', sortable: false },
        { key: 'phone', sortable: false },
        { key: 'email', sortable: true },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' },
      ]
    }
  },

  computed: {
    baseEndpoint() {
      return this.$acl.check('isAdmin') ? 'admin/companies' : 'companies'
    }
  }
}
</script>