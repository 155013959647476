<template>
  <b-form-input
    v-model="unitValue"
    type="number"
    :step="1 / Math.pow(10, decimalPlaces)"
    :formatter="i => i ? parseInt(parseFloat(i) * Math.pow(10, 1)) / Math.pow(10, 1) : i"
    v-bind="inputProps"
  />
</template>

<script>
import _ from 'lodash'
import formMixin from '@/mixins/form'

export default {
  name: 'GramsInput',

  mixins: [
    formMixin
  ],

  props: {
    inputProps: {
      type: Object,
      default: null
    },

    unit: {
      type: String,
      default: 'grams'
    },

    decimalPlaces: {
      type: Number,
      default: 2
    }
  },

  data() {
    return {
      autosave: true
    }
  },

  computed: {    
    unitOptions() {
      return [
        {
          value: 'miligrams',
          single: 'miligram',
          amount: 1,
          multiplier: 0.01
        },
        {
          value: 'grams',
          single: 'gram',
          amount: 1,
          multiplier: 1
        },
        {
          value: 'ounces',
          single: 'ounce',
          amount: 1,
          multiplier: 0.03527
        }
      ]
    },

    currentUnit: {
      get() {
        return _.find(this.unitOptions, i => i.value === this.newUnit) 
      },

      set(value) {
        this.$emit('update:unit', value)
      }
    },

    unitValue: {
      get() {
        return this.newValue ? Math.round(this.newValue * this.currentUnit.multiplier * 10 * this.decimalPlaces) / (10 * this.decimalPlaces) : this.newValue
      },

      set(value) {
        this.newValue = value ? Math.round(value / this.currentUnit.multiplier * 10 * this.decimalPlaces) / (10 * this.decimalPlaces) : value
      }
    }
  },

  watch: {
    unit: {
      immediate: true,

      handler() {
        this.newUnit = this.unit
      }
    }
  }
}
</script>