<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <!-- TERM -->
    <b-form-group
      label="Term"
      label-cols-sm="2"
    >
      <b-form-select
        v-model="input.term"
        size="lg"
        :options="$store.getters['asset/getCurriculumTermOptions']"
      />
    </b-form-group>

    <b-form-group>
      <b-button
        block
        variant="success"
        size="lg"
        :disabled="isSubmitting"
        @click="input.billing_type = 'one_time'; submit()"
      >
        <b-spinner
          v-if="isSubmitting && input.billing_type == 'one_time'"
          small
        /> Pay in Full - ${{ newItem.price_per_week * input.term }}
      </b-button>

      <b-button
        v-if="nodeEnv === 'development'"
        block
        variant="success"
        size="lg"
        :disabled="isSubmitting"
        @click="input.billing_type = 'daily_twice'; submit()"
      >
        <b-spinner
          v-if="isSubmitting && input.billing_type == 'daily_twice'"
          small
        /> Daily Two Payments - 2 x ${{ newItem.price_per_week * input.term /2 }}
      </b-button>

      <b-button
        block
        variant="success"
        size="lg"
        :disabled="isSubmitting"
        @click="input.billing_type = 'monthly_until_paid'; submit()"
      >
        <b-spinner
          v-if="isSubmitting && input.billing_type == 'monthly_until_paid'"
          small
        /> Monthly - {{ input.term / 4 }} x ${{ newItem.price_per_week * input.term / 4 }}
      </b-button>

      <b-button
        block
        variant="success"
        size="lg"
        :disabled="isSubmitting"
        @click="input.billing_type = 'every_two_weeks_until_paid'; submit()"
      >
        <b-spinner
          v-if="isSubmitting && input.billing_type == 'every_two_weeks_until_paid'"
          small
        /> Every Two Weeks - {{ input.term / 2 }} x ${{ newItem.price_per_week * 2 }}
      </b-button>
    </b-form-group>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'CurriculumProductItemCheckout',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'curriculum-products'
    }
  },

  computed: {
    nodeEnv() {
      return process.env.NODE_ENV
    }
  },

  validations() {
    const input = {
      term: { required },
      billing_type: { required }
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        term: 16,
        billing_type: null
      }

      return input
    },

    submit() {
      const endpoint = `${this.baseEndpoint}/${this.newItem.id}/checkout`
      this.isSubmitting = true
      
      return this.$axios
        .post(endpoint, this.input)
  
        .then(async ({ data }) => {
          window.location.href = data.url

          setTimeout(() => {
            this.isSubmitting = false
          }, 2000)

          return data
        })
  
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>