var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_vm.client ? [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "4"
    }
  })], 1), _c('b-card', [_c('CurriculumList', {
    ref: "list",
    attrs: {
      "params": {
        filters: {
          client_id: _vm.client.id
        }
      }
    }
  })], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }