<template>
  <div>
    <b-card>
      <ImageListFilters
        :filters.sync="params.filters"
      />
    </b-card>

    <b-row>
      <b-col>
        <b-card>
          <h3>Starting Image</h3>

          <ImageList
            :ref="`list-starting`"
            :client="client"
            :params="{
              ...params,
              filters: { ...params.filters, categories: ['front'], is_favorite: null },
              sort_desc: false,
              limit: 1
            }"
            :cols="1"
            :pagination="false"
            @delete:item="refreshExcept(`list-starting`)"
            @update:item="refreshExcept(`list-starting`)"
          />
        </b-card>
      </b-col>

      <b-col>
        <b-card>
          <h3>Now</h3>

          <ImageList
            :ref="`list-now`"
            :client="client"
            :params="{
              ...params,
              filters: { ...params.filters, categories: ['front'], is_favorite: null },
              sort_desc: true,
              limit: 1
            }"
            :cols="1"
            :pagination="false"
            @update:item="refreshExcept(`list-now`)"
            @delete:item="refreshExcept(`list-now`)"
          />
        </b-card>
      </b-col>
    </b-row>

    <b-card
      v-for="category in $store.getters['asset/getImageCategoriesOptions']"
      :key="category.value"
    >
      <h3>{{ category.text }}</h3>

      <ImageList
        :ref="`list-${category.value}`"
        :client="client"
        :params="{ ...params, filters: { ...params.filters, categories: [category.value] }}"
        @update:item="refreshExcept(`list-${category.value}`)"
        @delete:item="refreshExcept(`list-${category.value}`)"
      />
    </b-card>

    <b-card>
      <h3>Other</h3>

      <ImageList
        :ref="`list-other`"
        :client="client"
        :params="{ ...params, filters: { ...params.filters, categories: ['other'] }}"
        @update:item="refreshExcept(`list-other`)"
        @delete:item="refreshExcept(`list-other`)"
      />
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'
import ImageList from '@/components/images/ImageList'
import ImageListFilters from './ImageListFilters'

export default {
  name: 'ImageByCategoryList',

  components: {
    ImageList,
    ImageListFilters
  },

  props: {
    client: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      params: {
        filters: {
          is_favorite: false
        },
        limit: 4,
        order_by: 'taken_at',
        sort_desc: true
      }
    }
  },

  methods: {
    refresh(refName) {
      const ref = this.$refs[refName][0] || this.$refs[refName]
      if (ref) {
        ref.refresh()
      }
    },

    refreshExcept(refNameExcept) {
      const lists = [
        ...this.$store.getters['asset/getImageCategoriesOptions'],
        { value: 'other', text: 'Other' },
        { value: 'starting', text: 'Starting Image' },
        { value: 'now', text: 'Now' }
      ]

      _.each(lists, (category) => {
        const refName = `list-${category.value}`
        if (refName !== refNameExcept) {
          this.refresh(refName)
        }
      })
    }
  }
}
</script>