<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Date"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.restart_date)"
        :state="getFieldState($v.input.restart_date)"
      >
        <b-form-datepicker
          v-model="input.restart_date"
          :min="$moment().toDate()"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-left my-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'CurriculumItemRestart',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  computed: {
    updateEndpoint() {
      return `${this.baseEndpoint}/${this.newItem.id}/restart`
    }
  },

  validations() {
    return {
      input: {
        restart_date: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        restart_date: this.newItem.restart_date
      }

      return input
    }
  }
}
</script>