var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vue-bootstrap-typeahead', {
    ref: "typeahead",
    attrs: {
      "placeholder": _vm.placeholder,
      "data": _vm.options,
      "min-matching-chars": 0,
      "serializer": _vm.serializer
    },
    on: {
      "input": _vm.onInput,
      "hit": _vm.onHit,
      "blur": _vm.onBlur
    },
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }