var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', [_c('b-card', {
    staticClass: "bg-tab"
  }, [_c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0",
      "justified": "",
      "no-fade": ""
    }
  }, _vm._l(_vm.days, function (day) {
    return _c('b-tab', {
      key: day.number,
      attrs: {
        "lazy": "",
        "title-link-class": "rounded-0 border-0 font-size-16",
        "title": day.text
      }
    }, [_c('CurriculumPhaseDayMealsDetail', {
      attrs: {
        "item": _vm.item,
        "day-number": day.number,
        "curriculum": _vm.curriculum
      }
    })], 1);
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }