<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="isValidParams">
      <ScheduleInput
        :events="events"
        :bg-events="curriculum.client.schedule"
        :start-date="$moment(startDate).toDate()"
        :paused-at="curriculum.paused_at ? $moment(curriculum.paused_at).toDate() : null"
        :phase="phase"
        @update:events="onUpdateEvents"
        @bg-event-click="selectedSlot = $event"
        @hour-click="selectedSlot = $event"
      >
        <template v-slot:event-actions="{ event, toggleEventIsActive }">
          <b-dropdown
            v-if="event.type === 'cardio'"
            toggle-class="p-0 text-white"
            variant="link"
            size="sm"
            @shown="toggleEventIsActive(true)"
            @hidden="toggleEventIsActive(false)"
          >
            <template #button-content>
              <b-icon
                class="no-drag"
                icon="three-dots"
              />
            </template>

            <CurriculumTrackerCardioItemUpsertButton
              :item="event"
              tag="b-dropdown-item-button"
              @submitted="refresh"
            >
              Cardio Details
            </CurriculumTrackerCardioItemUpsertButton>

            <TrackerCardioItemUpsertButton
              v-if="curriculum.status === 'active' || event.is_performed"
              :item="event"
              tag="b-dropdown-item-button"
              @submitted="refresh"
            >
              Cardio Tracker
            </TrackerCardioItemUpsertButton>

            <TrackerCardioItemDeleteButton
              :item="event"
              tag="b-dropdown-item-button"
              @delete:item="refresh"
            >
              Delete
            </TrackerCardioItemDeleteButton>
          </b-dropdown>
        </template>
      </ScheduleInput>

      <b-modal
        v-model="newItemModalIsShown"
        content-class="rounded-0 border-0"
        header-bg-variant="dark"
        header-text-variant="white"
        header-class="rounded-0 p-4"
        body-class="p-0"
        hide-footer
        size="lg"
      >
        <b-card>
          <CurriculumTrackerCardioItemUpsert
            v-if="selectedSlot"
            :item="itemToCreate"
            @submitted="selectedSlot = null; refresh()"
          />
        </b-card>
      </b-modal>
    </template>

    <b-alert
      v-else
      show
      variant="warning"
    >
      CurriculumCardioListUpdate working only with filters between one week
    </b-alert>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import ScheduleInput from '@/components/schedule/ScheduleInput'
import TrackerCardioItemDeleteButton from './buttons/TrackerCardioItemDeleteButton'
import TrackerCardioItemUpsertButton from './buttons/TrackerCardioItemUpsertButton'
import CurriculumTrackerCardioItemUpsertButton from '@/components/curriculums/cardios/buttons/CurriculumTrackerCardioItemUpsertButton'
import CurriculumTrackerCardioItemUpsert from '@/components/curriculums/cardios/CurriculumTrackerCardioItemUpsert'

export default {
  name: 'TrackerCardioListSchedule',

  mixins: [
    crudListMixin
  ],

  components: {
    ScheduleInput,
    TrackerCardioItemDeleteButton,
    TrackerCardioItemUpsertButton,
    CurriculumTrackerCardioItemUpsertButton,
    CurriculumTrackerCardioItemUpsert
  },

  props: {
    curriculum: {
      type: Object,
      required: true
    },

    phase: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      baseEndpoint: 'tracker-cardios',
      selectedSlot: null,
      autosave: true,
      debounceSubmitTime: 0,

      workoutsResult: {
        data: null,
        total: null
      }
    }
  },

  computed: {
    startDate() {
      const start_time_from = this.$moment(this.params.filters.start_time_from)
      return start_time_from.subtract(start_time_from.isoWeekday() - 1, 'day').format('YYYY-MM-DD HH:mm:ss')
    },

    isValidParams() {
      const start_time_from = this.$moment(this.params.filters.start_time_from)
      const start_time_to = this.$moment(this.params.filters.start_time_to)
      return this.params.filters.curriculum_phase_id && start_time_to.isoWeekday() - start_time_from.isoWeekday() <= 7
    },

    newItemModalIsShown: {
      get() {
        return !!this.selectedSlot
      },

      set(value) {
        if (!value) {
          this.selectedSlot = null
        }
      }
    },

    itemToCreate() {
      return this.selectedSlot ? {
        curriculum_phase_id: this.newParams.filters.curriculum_phase_id,
        user_id: this.curriculum.client.id,
        start_time: this.$moment(this.startDate).add(this.selectedSlot.start * 60, 'minute').format('YYYY-MM-DD HH:mm:ss'),
        end_time: this.$moment(this.startDate).add(this.selectedSlot.start * 60 + 60, 'minute').format('YYYY-MM-DD HH:mm:ss')
      } : null
    },

    cardioEvents() {
      return _.map(this.newResult.data, i => {
        const start = (this.$moment(i.start_time).isoWeekday() - 1) * 24 + this.$moment(i.start_time).hour() + this.$moment(i.start_time).minute() / 60
        const end = (this.$moment(i.end_time).isoWeekday() - 1) * 24 + this.$moment(i.end_time).hour() + this.$moment(i.start_time).minute() / 60
        return {
          ...i,
          start,
          duration: end - start,
          type: 'cardio'
        }
      })
    },

    workoutEvents() {
      return _.map(this.workoutsResult.data, i => {
        const start = (this.$moment(i.start_time).isoWeekday() - 1) * 24 + this.$moment(i.start_time).hour() + this.$moment(i.start_time).minute() / 60
        const end = (this.$moment(i.end_time).isoWeekday() - 1) * 24 + this.$moment(i.end_time).hour() + this.$moment(i.start_time).minute() / 60

        return {
          ...i,
          start,
          duration: end - start,
          type: 'workout',
          isStatic: true
        }
      })
    },

    events() {
      return [...this.cardioEvents, ...this.workoutEvents]
    }
  },

  created() {
    this.fetchWorkouts()
  },

  methods: {
    getInput() {
      return {
        items: _.map(this.cardioEvents, i => ({
          id: i.id,
          start_time: i.start_time,
          end_time: i.end_time
        }))
      }
    },

    async onUpdateEvents(events) {
      const cardioEvents = _.filter(events, { type: 'cardio' })

      this.input = {
        items: _.map(cardioEvents, i => ({
          id: i.id,
          start_time: this.$moment(this.startDate).add(i.start * 60, 'minute').format('YYYY-MM-DD HH:mm:ss'),
          end_time: this.$moment(this.startDate).add(i.start * 60 + i.duration * 60, 'minute').format('YYYY-MM-DD HH:mm:ss')
        }))
      }
    },

    afterSubmit() {
      this.refresh()
    },

    async fetchWorkouts() {
      const endpoint = 'tracker-workouts'
      const limit = this.newParams.limit
      const page = this.newParams.page
      const filters = this.newParams.filters
      const order_by = this.newParams.order_by
      const order_desc = this.newParams.order_desc
      this.isFetching = true

      await this.$axios
        .get(endpoint, {
          params: { limit, page, order_by, order_desc, ...filters }
        })

        .then(({ data }) => {
          this.isFetching = false
          this.workoutsResult.data = data.data
          this.workoutsResult.total = data.total
        })

        .catch((e) => {
          this.isFetching = false
          throw e
        })
    }
  }
}
</script>