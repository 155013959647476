<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-form
      v-if="isFetched"
      @submit.prevent="submit('rebooted')"
    >
      <!-- OWNER MISMATCH -->
      <div v-if="!isOwner">
        <b-alert show variant="warning">
          You can't reboot this curriculum.
        </b-alert>
      </div>

      <!-- ALREADY HAS CURRICULUM -->
      <div v-if="currentUserCurriculum">
        <b-alert show variant="warning">
          You already have an active curriculum.
        </b-alert>
      </div>

      <!-- PROFILEI IS NOT UPTODATE -->
      <div v-if="!currentUserProfileUptodate">
        <b-alert show variant="warning">
          Your profile is not uptodate.
        </b-alert>
      </div>

      <!-- STATUS MISMATCH -->
      <div v-if="!statusIsRebootable">
        <b-alert show variant="warning">
          You can't reboot curriculum with status {{ newItem.status }}.
        </b-alert>
      </div>

      <!-- SCHEDULE MISMATCH -->
      <div v-if="scheduleCardioEventCount < newItem.profile.cardio_sessions_per_week && scheduleWorkoutEventCount < newItem.profile.workouts_per_week">
        <b-alert
          show
          variant="warning"
        >
          You do not have anough workouts ({{ scheduleWorkoutEventCount}}/{{ newItem.profile.workouts_per_week }}) and cardio ({{ scheduleCardioEventCount }}/{{ newItem.profile.cardio_sessions_per_week }}) sessions to match this curriculum.
        </b-alert>
      </div>

      <div v-else-if="scheduleCardioEventCount < newItem.profile.cardio_sessions_per_week">
        <b-alert
          show
          variant="warning"
        >
          You do not have anough cardio ({{ scheduleCardioEventCount }}/{{ newItem.profile.cardio_sessions_per_week }}) sessions in your schedule to match this curriculum.
        </b-alert>
      </div>

      <div v-else-if="scheduleWorkoutEventCount < newItem.profile.workouts_per_week">
        <b-alert
          show
          variant="warning"
        >
          You do not have anough workouts ({{ scheduleWorkoutEventCount}}/{{ newItem.profile.workouts_per_week }}) in your schedule to match this curriculum.
        </b-alert>
      </div>

      <!-- SUBMIT -->
      <b-form-group class="text-left mt-4">
        <b-button
          variant="success"
          type="submit"
          block
          size="lg"
          :disabled="!isRebootable || isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small /><template v-if="curriculumProduct">
            ${{ curriculumProduct.price_per_week * newItem.term }} | Reboot
          </template>
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import crudItemMixin from '@/mixins/crud-item'
export default {
  name: 'CurriculumItemReboot',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'curriculums',
      curriculumProductsResult: {
        data: [],
        total: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    isOwner() {
      return (this.newItem.client_id || this.newItem.client?.id) === this.currentUser.id
    },

    currentUserCurriculum() {
      return this.currentUser.curriculum
    },

    currentUserProfileUptodate() {
      return this.currentUser.profile_uptodate
    },

    statusIsRebootable() {
      return ['completed', 'canceled'].includes(this.newItem.status)
    },

    scheduleCardioEventCount() {
      return _.filter(this.currentUser.schedule, { type: 'cardio' }).length
    },

    scheduleWorkoutEventCount() {
      return _.filter(this.currentUser.schedule, { type: 'workout' }).length
    },

    curriculumProduct() {
      return _.get(this.curriculumProductsResult.data, '0')
    },

    isRebootable() {
      return (
        this.isOwner &&
        !this.currentUserCurriculum &&
        this.currentUserProfileUptodate &&
        this.statusIsRebootable &&
        this.scheduleCardioEventCount >= this.newItem.profile.cardio_sessions_per_week &&
        this.scheduleWorkoutEventCount >= this.newItem.profile.workouts_per_week &&
        this.curriculumProduct
      )
    }
  },

  methods: {
    async afterFetch() {
      const limit = 1
      const goal_direction_ids = [this.newItem.profile.goal_direction]
      const terms = [this.newItem.term]
      const type = 'reboot'

      await this.$axios
        .get(`curriculum-products`, { type, terms, goal_direction_ids, limit })
        .then(({ data }) => {
          this.curriculumProductsResult.data = data.data
          this.curriculumProductsResult.total = data.total
        })
    },

    submit() {
      this.isSubmitting = true

      this.$axios
        .post(`curriculums/${this.newItem.id}/reboot`, this.params)
        .then(({ data }) => {
          window.location.href = data.url

          setTimeout(() => {
            this.isSubmitting = false
          }, 2000)

          return data
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>