<template>
  <b-overlay :show="isFetching || isSubmitting">
    <div
      v-for="(item, itemIndex) in newResult.data"
      :key="item.id"
    >
      <div class="bg-secondary text-light py-1 px-0">
        <b-row
          align-v="center"
          no-gutters
        >
          <b-col
            cols="6"
          >
            <TrackerExerciseGroupItemUpsertButton
              :item="item"
              class="font-weight-bold text-white"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @submitted="refresh"
            >
              {{ item.name }}
            </TrackerExerciseGroupItemUpsertButton>
          </b-col>

          <b-col
            class="text-right"
            cols="6"
          >
            <TrackerExerciseItemUpsertButton
              :item="{ tracker_exercise_group_id: item.id }"
              class="mr-1 text-white"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @submitted="refresh"
            >
              <b-icon-plus-circle scale="0.8" />
            </TrackerExerciseItemUpsertButton>

            <TrackerExerciseGroupItemDeleteButton
              :item="item"
              tag="b-button"
              class="text-white"
              :tag-props="{
                variant: 'link'
              }"
              @delete:item="refresh"
            >
              <b-icon-trash scale="0.8" />
            </TrackerExerciseGroupItemDeleteButton>
          </b-col>
        </b-row>
      </div>

      <TrackerExerciseList
        :params="{
          filters: {
            tracker_exercise_group_id: item.id
          }
        }"
        :result="{
          data: item.tracker_exercises
        }"
        :prefetch="false"
        @update:result="onUpdateExercises(itemIndex, $event)"
      />
    </div>

    <div class="text-center m-2">
      <TrackerExerciseGroupItemUpsertButton
        :item="{ tracker_workout_id: newParams.filters.tracker_workout_id }"
        tag="b-button"
        :tag-props="{
          variant: 'link'
        }"
        @submitted="refresh"
      >
        <b-icon-plus-circle class="mr-1" scale="0.8" /> Add exercise group
      </TrackerExerciseGroupItemUpsertButton>
    </div>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import TrackerExerciseList from './TrackerExerciseList'
import TrackerExerciseItemUpsertButton from './buttons/TrackerExerciseItemUpsertButton'
import TrackerExerciseGroupItemUpsertButton from './buttons/TrackerExerciseGroupItemUpsertButton'
import TrackerExerciseGroupItemDeleteButton from './buttons/TrackerExerciseGroupItemDeleteButton'

export default {
  name: 'TrackerExerciseGroupList',

  mixins: [
    crudListMixin
  ],

  components: {
    TrackerExerciseList,
    TrackerExerciseItemUpsertButton,
    TrackerExerciseGroupItemUpsertButton,
    TrackerExerciseGroupItemDeleteButton
  },

  data() {
    return {
      baseEndpoint: 'tracker-exercise-groups'
    }
  },

  methods: {
    onUpdateExercises(itemIndex, exercisesResult) {
      if (exercisesResult) {
        const newResult = _.cloneDeep(this.newResult)
        newResult.data[itemIndex] = { ...newResult.data[itemIndex], tracker_exercises: exercisesResult.data }

        if (!_.isEqual(this.newResult, newResult)) {
          this.$emit('update:result', newResult)
        }
      }
    }
  }
}
</script>