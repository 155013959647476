<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="isFetched">
      <div class="m-3">
        <b-table-simple
          class="text-center"
        >
          <b-thead>
            <b-tr variant="primary">
              <b-th class="w-100px" />
              <b-th>Calories</b-th>
              <b-th>Proteins</b-th>
              <b-th>Carbs</b-th>
              <b-th>Fats</b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr v-if="macroRecommended">
              <b-th variant="success">
                Total
              </b-th>

              <b-td>{{ macroRecommended.calories }}</b-td>
              <b-td>{{ macroRecommended.protein }}</b-td>
              <b-td>{{ macroRecommended.carbs }}</b-td>
              <b-td>{{ macroRecommended.fat }}</b-td>
            </b-tr>

            <b-tr>
              <b-th variant="success">
                Taken
              </b-th>

              <b-td>{{ macroTotal.calories }}</b-td>
              <b-td>{{ macroTotal.protein }}</b-td>
              <b-td>{{ macroTotal.carbs }}</b-td>
              <b-td>{{ macroTotal.fat }}</b-td>
            </b-tr>

            <b-tr v-if="macroRecommended">
              <b-th variant="success">
                +/-
              </b-th>

              <b-td>{{ macroDifference.calories }}</b-td>
              <b-td>{{ macroDifference.protein }}</b-td>
              <b-td>{{ macroDifference.carbs }}</b-td>
              <b-td>{{ macroDifference.fat }}</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>

      <b-form
        @submit.prevent="submit('submitted')"
      >
        <b-card no-body>
          <b-row no-gutters>
            <b-col lg="9">
              <b-tabs
                card
                no-fade
              >
                <b-tab title="Meals">
                  <TrackerMealList
                    :params="{
                      filters: {
                        nutrition_id: newItem.id
                      }
                    }"
                    :result="{
                      data: newItem.meals
                    }"
                    :prefetch="false"
                    @submitted="refresh()"
                    @delete:item="refresh()"
                    @submitted:meal-food="refresh()"
                    @delete:meal-food="refresh()"
                  />
                </b-tab>

                <b-tab title="Supplements">
                  <TrackerSupplementList
                    :params="{
                      filters: {
                        nutrition_id: newItem.id
                      }
                    }"
                    :result="{
                      data: newItem.supplements
                    }"
                    :prefetch="false"
                    @submitted:item="refresh()"
                    @delete:item="refresh()"
                  />
                </b-tab>
              </b-tabs>
            </b-col>

            <b-col>
              <b-tabs
                card
                no-fade
                justified
              >
                <b-tab title="Water">
                  <div class="text-center font-size-24 font-weight-bolder">
                    {{ input.water || 0 }}<template v-if="waterRecommended"> / {{ waterRecommended }}</template>
                  </div>

                  <div class="text-center font-weight-bolder">Ounces</div>

                  <div class="text-center">
                    <b-button-group>
                      <b-button
                        variant="list"
                        @click="input.water += 1"
                      >
                        <b-icon-plus-circle />
                      </b-button>

                      <b-button
                        variant="list"
                        @click="input.water ? input.water -= 1 : null"
                      >
                        <b-icon-dash-circle />
                      </b-button>
                    </b-button-group>
                  </div>

                  <div class="text-center my-3">
                    <b-spinner v-if="isSubmitting" small /> 
                  </div>
                </b-tab>
              </b-tabs>
            </b-col>
          </b-row>
        </b-card>
      </b-form>
    </template>

    <div v-else-if="isFetching">
      <b-alert
        show
        class="m-0 text-center"
        variant="light"
      >
        Loading...
      </b-alert>
    </div>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import TrackerMealList from './meals/TrackerMealList'
import TrackerSupplementList from './supplements/TrackerSupplementList'

export default {
  name: 'TrackerNutritionItemUpdate',

  mixins: [
    crudItemMixin
  ],

  components: {
    TrackerMealList,
    TrackerSupplementList
  },

  props: {
    waterRecommended: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'tracker-nutritions',
      activeMealIndex: null
    }
  },

  computed: {
    autosave() {
      return true
    },

    debounceSubmitTime() {
      return 100
    },

    curriculumMeals() {
      return _.chain(this.newItem.meals)
       .flatMap('curriculum_meal')
       .filter(i => i)
       .value()
    },

    macroRecommended() {
      return this.curriculumMeals.length ? {
        calories: Math.round(_.sum(_.map(this.curriculumMeals, i => this.calcMealCalories(i))) * 100) / 100,
        protein: Math.round(_.sum(_.map(this.curriculumMeals, i => i.protein || 0)) * 100) / 100,
        carbs: Math.round(_.sum(_.map(this.curriculumMeals, i => i.carbs || 0)) * 100) / 100,
        fat: Math.round(_.sum(_.map(this.curriculumMeals, i => i.fat || 0)) * 100) / 100
      } : null
    },

    macroTotal() {
      return {
        calories: this.calcMealsMacro(this.newItem.meals, 'calories'),
        protein: this.calcMealsMacro(this.newItem.meals, 'protein'),
        carbs: this.calcMealsMacro(this.newItem.meals, 'carbs'),
        fat: this.calcMealsMacro(this.newItem.meals, 'fat')
      }
    },

    macroDifference() {
      return this.macroRecommended ? {
        calories: Math.round((this.macroTotal.calories - this.macroRecommended.calories) * 100) / 100,
        protein: Math.round((this.macroTotal.protein - this.macroRecommended.protein) * 100) / 100,
        carbs: Math.round((this.macroTotal.carbs - this.macroRecommended.carbs) * 100) / 100,
        fat: Math.round((this.macroTotal.fat - this.macroRecommended.fat) * 100) / 100,
      } : null
    }
  },

  methods: {
    getInput() {
      const input = {
        water: _.get(this.newItem, 'water', null)
      }

      return input
    },

    calcMealFoodMacro(mealFood, type) {
      return Math.round(mealFood.amount * (mealFood.food.serving_size || 1) * mealFood.food[type]) / 100
    },

    calcMealFoodsMacro(mealFoods, type) {
      return _.chain(mealFoods)
        .map(i => this.calcMealFoodMacro(i, type))
        .sum()
        .round(2)
        .value()
    },

    calcMealsMacro(meals, type) {
      return _.chain(meals)
        .flatMap('meal_foods')
        .map(i => this.calcMealFoodMacro(i, type))
        .sum()
        .round(2)
        .value()
    },

    calcMealCalories(meal) {
      return _.chain(['protein', 'carbs', 'fat'])
        .map(i => this.getCaloriesByGrams(meal[i], i))
        .sum()
        .round(2)
        .value()
    },

    getCaloriesByGrams(value, type) {
      const caloriesPerGram = type === 'fat' ? 9 : 4
      const calories = Math.round(parseFloat(value || '0') * caloriesPerGram * 100) / 100

      return calories
    }
  }
}
</script>