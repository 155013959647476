var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1)], 1), _vm.$acl.check('isStaff') ? [_c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "mb-3 font-size-20 font-weight-bold"
  }, [_vm._v(" Stripe ")]), _vm.$acl.check('isOwner') ? _c('b-col', {
    staticClass: "text-right"
  }, [_c('CompanyItemStripeUpdateButton', {
    attrs: {
      "item": _vm.currentCompany,
      "tag": "b-button",
      "tag-props": {
        size: 'sm'
      }
    }
  }, [_vm._v(" Edit ")])], 1) : _vm._e()], 1), _c('b-alert', {
    attrs: {
      "show": "",
      "variant": _vm.currentCompany.has_stripe_secret_key ? 'success' : 'warning'
    }
  }, [_vm._v(" " + _vm._s(_vm.currentCompany.has_stripe_secret_key ? 'Company has stripe secret key.' : 'Company has no stripe secret key.') + " ")]), _c('b-alert', {
    attrs: {
      "show": "",
      "variant": _vm.currentCompany.has_stripe_signing_secret ? 'success' : 'warning'
    }
  }, [_vm._v(" " + _vm._s(_vm.currentCompany.has_stripe_signing_secret ? 'Company has stripe signing secret.' : 'Company has no stripe signing secret.') + " ")])], 1), _c('b-card', [_c('div', {
    staticClass: "mb-3 font-size-20 font-weight-bold"
  }, [_vm._v(" Company App Plans ")]), _c('CompanyAppPlanList')], 1), _c('b-card', [_c('div', {
    staticClass: "mb-3 font-size-20 font-weight-bold"
  }, [_vm._v(" Curriculum Products ")]), _c('CurriculumProductList')], 1), _c('b-card', [_c('div', {
    staticClass: "mb-3 font-size-20 font-weight-bold"
  }, [_vm._v(" Other Company Products ")]), _c('CompanyProductList')], 1)] : _vm._e(), _vm.$acl.check('isClient') ? [_c('b-card', [_c('div', {
    staticClass: "mb-3 font-size-20 font-weight-bold"
  }, [_vm._v(" Curriculum Products ")]), _c('CurriculumProductList', {
    attrs: {
      "params": {
        filters: {
          type: 'new'
        }
      }
    }
  })], 1), _c('b-card', [_c('div', {
    staticClass: "mb-3 font-size-20 font-weight-bold"
  }, [_vm._v(" Other Company Products ")]), _c('CompanyProductList')], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }