var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', [_c('CurriculumMealListTable', {
    ref: "meal-table",
    attrs: {
      "params": {
        filters: {
          phase_id: _vm.item.id,
          day_number: _vm.dayNumber
        },
        order_by: 'taken_at'
      },
      "result": _vm.mealResult,
      "curriculum": _vm.curriculum,
      "phase": _vm.item,
      "disabled": _vm.dayNumber !== 1
    },
    on: {
      "update:result": [function ($event) {
        _vm.mealResult = $event;
      }, function ($event) {
        return _vm.$emit('update:result-meals', $event);
      }]
    }
  })], 1)], 1), _vm.dayNumber !== 1 ? _c('div', {
    staticClass: "my-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "warning",
      "block": "",
      "disabled": _vm.isReseting
    },
    on: {
      "click": function click($event) {
        return _vm.showResetModal();
      }
    }
  }, [_vm.isReseting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Use Template Meals ")], 1)], 1) : _vm._e(), _c('b-row', {
    staticClass: "mb-4"
  }, [_c('b-col', [_c('CurriculumMealList', {
    ref: "meal-list",
    attrs: {
      "params": {
        filters: {
          phase_id: _vm.item.id,
          day_number: _vm.dayNumber
        },
        order_by: 'taken_at'
      },
      "result": _vm.mealResult,
      "supplement-result": _vm.supplementResult,
      "disabled": _vm.dayNumber !== 1
    },
    on: {
      "update:result": function updateResult($event) {
        _vm.mealResult = $event;
      },
      "submitted:supplement": function submittedSupplement(i) {
        return _vm.$refs['supplement-list'].refresh();
      },
      "delete:supplement": function deleteSupplement(i) {
        return _vm.$refs['supplement-list'].refresh();
      },
      "submitted:item": function submittedItem(i) {
        return _vm.$refs['meal-table'].refresh();
      },
      "delete:item": function deleteItem(i) {
        return _vm.$refs['meal-table'].refresh();
      }
    }
  })], 1)], 1), _c('b-row', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "mb-4"
  }, [_c('b-col', [_c('b-card', [_c('b-row', {
    staticClass: "mb-3",
    attrs: {
      "align-v": "center"
    }
  }, [_c('b-col', {
    staticClass: "font-size-16 font-weight-bold"
  }, [_vm._v(" Supplements ")]), _c('b-col', {
    staticClass: "text-right"
  }, [_c('CurriculumSupplementItemUpsertButton', {
    staticClass: "p-0",
    attrs: {
      "item": {
        phase_id: _vm.item.id,
        day_number: _vm.dayNumber
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'link',
        disabled: _vm.dayNumber !== 1
      }
    },
    on: {
      "submitted": function submitted(i) {
        return _vm.$refs['supplement-list'].refresh();
      }
    }
  }, [_vm._v(" + Add supplement ")])], 1)], 1), _c('CurriculumSupplementList', {
    ref: "supplement-list",
    attrs: {
      "params": {
        filters: {
          phase_id: _vm.item.id,
          day_number: _vm.dayNumber
        },
        order_by: 'taken_at'
      },
      "disabled": _vm.dayNumber !== 1,
      "result": _vm.supplementResult
    },
    on: {
      "update:result": function updateResult($event) {
        _vm.supplementResult = $event;
      }
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }