var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-card', {
    staticClass: "mb-0"
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "text-center font-size-24 font-weight-bolder"
  }, [_vm._v(" " + _vm._s(_vm.input.water || 0)), _vm.waterRecommended ? [_vm._v(" / " + _vm._s(_vm.waterRecommended))] : _vm._e()], 2), _c('div', {
    staticClass: "text-center font-weight-bolder"
  }, [_vm._v("Ounces")]), _c('div', {
    staticClass: "text-center"
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "list"
    },
    on: {
      "click": function click($event) {
        _vm.input.water += 1;
      }
    }
  }, [_c('b-icon-plus-circle')], 1), _c('b-button', {
    attrs: {
      "variant": "list"
    },
    on: {
      "click": function click($event) {
        _vm.input.water ? _vm.input.water -= 1 : null;
      }
    }
  }, [_c('b-icon-dash-circle')], 1)], 1)], 1)]), _c('b-form-group', {
    staticClass: "text-right mb-0"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Log Water ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }