var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.profile_uptodate),
      "state": _vm.getFieldState(_vm.$v.input.profile_uptodate)
    }
  }, [_c('b-form-checkbox', {
    attrs: {
      "switch": "",
      "size": "lg",
      "state": _vm.getFieldState(_vm.$v.input.profile_uptodate)
    },
    model: {
      value: _vm.input.profile_uptodate,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "profile_uptodate", $$v);
      },
      expression: "input.profile_uptodate"
    }
  }, [_vm._v(" Profile is updated ")])], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }