<script>
import Layout from '../../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import { assetComputed, authComputed, companyComputed } from '@/state/helpers';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import Draggable from 'vuedraggable';
import FaIcon from '@/components/widgets/fa-icon';
import s from 'underscore.string';
import Swal from 'sweetalert2';
import _ from 'lodash';
import Abilities from '@/components/v1/asset/Abilities'
import FormInputButtons from '@/components/FormInputButtons'
import WorkoutProgramItemDetail from '@/components/workout-programs/WorkoutProgramItemDetail'
import WorkoutProgramItemCopyButton from '@/components/workout-programs/buttons/WorkoutProgramItemCopyButton'

let counter = 1;

/**
 * Advanced table component
 */
export default {
  name: 'WorkoutProgramStep3',
  page: {
    title: 'Workout Program Creator',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
    Draggable,
    FaIcon,
    Abilities,
    FormInputButtons,
    WorkoutProgramItemDetail,
    WorkoutProgramItemCopyButton
  },
  data() {
    return {
      config: appConfig,
      editEntityMode: this.$route.name !== 'workout-programs-edit',
      forecast: {},
      //next_clicked: false,
      workouts: {
        items: [],
        totalRows: 0,
        currentPage: 1,
        perPage: 10,
        filter: {
          primary_muscle: '',
          ability_level: '',
          type: '',
          favorite: false,
          search: '',
        },
        //sortBy: "name",
        //sortDesc: false,
      },
      phases: {
        busy: false,
      },
      program: {
        name: '',
        gender: '',
        goal_direction_id: null,
        fitness_level_ids: [],
        description: '',
        program: null,
        phases: [],
        forecast_id: null,
        workouts_per_week: null,
        workouts_per_day: null,
        is_private: false
      },
      formSubmitted: false,
      // overlays
      gridOverlay: false,
      programOverlay: false,
      phasesOverlay: false,
      //formBusy: false,
    };
  },
  validations: {
    program: {
      name: { required, minLength: minLength(5), maxLength: maxLength(255) },
      gender: { required },
      goal_direction_id: { required },
      fitness_level_ids: { required },
      description: { required, maxLength: maxLength(255) },
      is_private: { required },
    },
  },
  computed: {
    ...assetComputed,
    ...authComputed,
    ...companyComputed,
    title() {
      return typeof this.forecast.term !== 'undefined'
        ? `Workout Program Creator - <small class='text-muted'>${this.forecast.term} Weeks</small>`
        : 'Workout Program Creator';
    },
    isEdit() {
      return this.$route.name === 'workout-programs-edit';
    },
    couldEdit() {
      return (
        this.$acl.check('couldEditPublicWorkoutProgram') && !this.program.is_private ||
        this.$acl.check('couldEditOwnWorkoutProgram') && this.currentUser.id === this.program.user_id
      )
    }
  },
  created() {
    this.debounceSetFilterSearchParam = _.debounce(async value => {
      this.workouts.filter.search = value
      this.loadWorkouts()
    }, 1000)
  },
  methods: {
    s: s,
    loadProgram() {
      this.id = this.$route.params.id;

      if (this.id) {
        this.programOverlay = true;
        this.$axios
          .get(`/workout-programs/${this.id}`)
          .then(({ data }) => {
            this.program = data;
            this.formClone = _.cloneDeep(this.program);

            this.loadForecast();
          })
          .finally(() => {
            this.programOverlay = false;
          });
      }
    },
    loadForecast() {
      if (this.program.forecast_id) {
        this.programOverlay = true;

        this.$axios
          .get(`/forecast/${this.program.forecast_id}`)
          .then(({ data }) => {
            this.forecast = data.data;

            this.forecast.phases.forEach((phase) => {
              phase.weeks.forEach((week) => {
                this.$set(week, 'workouts', []);
              });
            });

            if (this.program && !this.program.phases.length) {
              this.program.phases = this.forecast.phases;
            }
          })
          .finally(() => {
            this.programOverlay = false;
          });
      }
    },
    onFormCancel() {
      if (this.isEdit) {
        this.editEntityMode = false;
        this.program = _.cloneDeep(this.formClone);
      }
    },
    onNextClick() {},
    loadWorkouts() {
      this.workoutsDataProvider();
    },
    workoutsDataProvider() {
      const limit = this.workouts.perPage;
      const page = this.workouts.currentPage;
      const filters = {
        company_id: this.currentCompany.id,
      }

      if (this.workouts.filter.search) {
        filters.search = this.workouts.filter.search
      }

      this.gridOverlay = true;
      return this.$axios
        .get(`/workouts`, {
          params: { limit, page, ...filters },
          parent: this
        })
        .then(({ data }) => {
          this.workouts.totalRows = data.total;
          this.workouts.items = data.data;
        })
        .finally(() => {
          this.gridOverlay = false;
        });
    },
    onMove({ dragged, to }) {
      const workoutId = parseFloat(dragged.getAttribute('data-id'));
      const phaseId = parseFloat(to.getAttribute('data-phase-id'));
      const weekId = parseFloat(to.getAttribute('data-week-id'));

      let phase = this.program.phases.filter((p) => p.id === phaseId);

      if (phase.length !== 1) {
        return false;
      }

      phase = phase[0];

      let week = phase.weeks.filter((w) => w.id === weekId);
      if (week.length !== 1) {
        return false;
      }

      week = week[0];
      workoutId;

      // Prevent same workout to be used more than once
      // let workout = week.workouts.filter((w) => w.id === workoutId);
      // if (workout.length !== 0) {
      //   return false;
      // }
    },
    onWorkoutAdd({ clone, to }) {
      const workoutId = parseFloat(clone.getAttribute('data-id'));
      const phaseId = parseFloat(to.getAttribute('data-phase-id'));
      const weekId = parseFloat(to.getAttribute('data-week-id'));
      const phase = this.program.phases.filter((p) => p.id === phaseId)[0];
      const week = phase && phase.weeks.filter((w) => w.id === weekId)[0];
      // Move first item to the bottom of the list
      if (week) {
        const workoutIndex = week.workouts.findIndex((w) => w.id === workoutId);
        const workout = week.workouts.splice(workoutIndex, 1)[0];
        week.workouts.push(workout);
      }
      this.updateProgram();
    },
    onWorkoutDelete(workout, week) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          const workoutIndex = week.workouts.findIndex((w) => w === workout);
          week.workouts.splice(workoutIndex, 1);
          this.updateProgram();
        }
      });
    },
    onWorkoutOrder() {
      this.updateProgram();
    },
    getWorkoutTypeIcon(type) {
      const asset = this.workoutTypes.filter((workout) => workout.id === type);
      if (asset.length > 0) {
        return asset[0].faicon;
      }
      return false;
    },
    resetCounter() {
      counter = 1;
    },
    incCounter() {
      counter++;
    },
    getCounter() {
      return counter;
    },
    onEditProgramEntity() {
      this.editEntityMode = true;
    },
    updateProgram() {
      this.formClone = _.cloneDeep(this.program);

      this.$axios
        .patch(`/workout-programs/${this.id}`, this.program)
        .catch(() => {
          this.$store.commit('layout/addAlerts', {
            type: 'warning',
            text: 'Could not save program',
          });
        })
        .finally(() => {
          this.programOverlay = false;
          this.editEntityMode = false;
        });
    },
    programFormSubmit() {
      this.formSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.program.company_id = this.currentCompany ?
                                this.currentCompany.id :
                                null;

      this.programOverlay = true;
      if (this.isEdit) {
        this.updateProgram();
      } else {
        this.$axios
          .post('/workout-programs', this.program)
          .then(({ data }) => {
            this.editEntityMode = false;
            this.keepData = true;
            this.id = data.id;
            this.program = data
            this.$router.push(`/workout-programs/${data.id}`);
          })
          .catch(() => {
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Could not save workout',
            });
          })
          .finally(() => {
            this.programOverlay = false;
          });
      }
    },
    getSexOptions() {
      return this.$store.getters['asset/getSexOptions'];
    },
    getGoalDirectionsOptions() {
      return this.$store.getters['asset/getGoalDirectionsOptions'];
    },
    getWeekReps(week) {
      return _.chain(0)
        .range(_.get(week, 'reps'))
        .map(i => _.get(week, `rep_${i + 1}`))
        .value()
    }
  },
  async mounted() {
    if (!this.isEdit) {
      this.program.forecast_id = window.localStorage.getItem(
        'add_program_selected_forecast'
      );
      this.program.workouts_per_week = window.localStorage.getItem(
        'add_program_number_of_workouts'
      );
      this.program.workouts_per_day = window.localStorage.getItem(
        'add_program_number_of_workouts'
      );

      if (!this.program.forecast_id) {
        await this.$router.push(`/workout-programs/add`);
      }

      this.loadForecast();
    }

    this.loadWorkouts();
  },
  watch: {
    'workouts.currentPage'() {
      this.loadWorkouts();
    },
    'forecast'() {
      this.program.gender = this.forecast.gender;
      this.program.fitness_level_ids = this.forecast.fitness_level;
      this.program.goal_direction_id = this.forecast.goal_direction;
    },
    '$route.params.id': {
      immediate: true,

      handler(newValue, oldValue) {
        window.console.log('$route.params.id', newValue, oldValue)
        if (newValue && newValue !== oldValue) {
          this.loadProgram()
        }
      }
    }
  },
};
</script>

<template>
  <Layout>
    <b-overlay
      v-if="!couldEdit && isEdit"
      :show="programOverlay"
      class="py-4"
    >
      <template v-if="program.id">
        <WorkoutProgramItemDetail
          :item.sync="program"
          :prefetch="false"
        />

        <div class="text-right m-4">
          <WorkoutProgramItemCopyButton
            :source="program"
            tag="b-button"
            :tag-props="{
              variant: 'success'
            }"
            @copied="$router.push(`/workout-programs/${$event.id}`)"
          >
            Copy
          </WorkoutProgramItemCopyButton>

          <b-button
            class="ml-2"
            to="/workout-programs/list"
          >
            Back
          </b-button>
        </div>
      </template>
    </b-overlay>

    <template v-else>
      <PageHeader :title="title" />

      <div class="row">
        <div class="col-lg-6">
          <b-overlay
            :show="gridOverlay"
            :variant="config.overlay.variant"
            :opacity="config.overlay.opacity"
            :blur="config.overlay.blur"
            rounded="sm"
          >
            <div class="card workout-table-card">
              <div class="card-body">
                <b-row>
                  <b-col
                    lg="4"
                    class="mb-2"
                  >
                    <b-input
                      :value="workouts.filter.search"
                      size="sm"
                      placeholder="Search workout..."
                      @input="debounceSetFilterSearchParam"
                    />
                  </b-col>
                </b-row>
                <div class="table-responsive mb-0">
                  <b-table-simple class="workout-table" :busy="gridOverlay">
                    <b-thead>
                      <div>
                        <b-tr>
                          <b-th>Name</b-th>
                          <b-th>Fitness Level</b-th>
                          <b-th>Type</b-th>
                        </b-tr>
                      </div>
                    </b-thead>
                    <b-tbody>
                      <Draggable
                        :move="onMove"
                        :group="{ name: 'workouts', pull: 'clone', put: false }"
                        :list="workouts.items"
                      >
                        <b-tr
                          v-for="workout in this.workouts.items"
                          :key="workout.id"
                          :data-id="workout.id"
                        >
                          <b-td>
                            {{ workout.name }}
                          </b-td>
                          <b-td>
                            <Abilities :ids="workout.fitness_level_ids || []" />
                          </b-td>
                          <b-td>
                            <FaIcon v-if="getWorkoutTypeIcon(workout.type)"
                              :icon="getWorkoutTypeIcon(workout.type)"
                              :key="workout.id"
                              size="17"
                            />
                            <span v-if="!getWorkoutTypeIcon(workout.type)">
                              {{ workout.type }}
                            </span>
                          </b-td>
                        </b-tr>
                      </Draggable>
                    </b-tbody>
                  </b-table-simple>
                </div>
                <div class="row">
                  <div class="col-sm-12 col-md-6"></div>
                  <div class="col-sm-12 col-md-6">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mt-3 mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="workouts.currentPage"
                          :total-rows="workouts.totalRows"
                          :per-page="workouts.perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-overlay>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <div class="card-body">
              <b-collapse :visible="!editEntityMode">
                <b-row class="align-items-center">
                  <b-col cols="8">
                    <h4 class="mb-0 card-title">Program - {{ program.name }}</h4>
                  </b-col>
                  <b-col cols="4">
                    <div class="float-right">
                      <button
                        type="edit"
                        @click="onEditProgramEntity"
                        class="btn btn-secondary"
                      >
                        Edit
                      </button>
                    </div>
                  </b-col>
                </b-row>
              </b-collapse>

              <b-collapse :visible="editEntityMode">
                <b-overlay
                  :show="programOverlay"
                  :variant="config.overlay.variant"
                  :opacity="config.overlay.opacity"
                  :blur="config.overlay.blur"
                  rounded="sm"
                >
                  <form action="#" @submit.prevent="programFormSubmit">
                    <b-form-group
                      label-cols="9"
                      label-cols-sm="3"
                      label="Name"
                      label-for="name"
                    >
                      <input
                        v-model="program.name"
                        type="text"
                        class="form-control"
                        placeholder="Name"
                        :class="{
                          'is-invalid': formSubmitted && $v.program.name.$error,
                        }"
                      />
                      <div
                        v-if="formSubmitted && $v.program.name.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.program.name.required">
                          This value is required.
                        </span>
                        <span v-if="!$v.program.name.minLength">
                          This value is 5 letters minimum length.
                        </span>
                        <span v-if="!$v.program.name.maxLength">
                          This value is 200 letters maximum length.
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-cols="9"
                      label-cols-sm="3"
                      label="Gender"
                      label-for="gender"
                    >
                      <b-form-radio-group
                        id="gender"
                        v-model="program.gender"
                        :class="{
                          'is-invalid': formSubmitted && $v.program.gender.$error,
                        }"
                        class="pt-2"
                        disabled
                        :options="getSexOptions()"
                      >
                      </b-form-radio-group>

                      <div
                        v-if="formSubmitted && $v.program.gender.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.program.gender.required">
                          This value is required.
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-cols="9"
                      label-cols-sm="3"
                      label="Goal Direction"
                      label-for="goal_direction_id"
                    >
                      <b-form-radio-group
                        id="goal_direction"
                        v-model="program.goal_direction_id"
                        class="mt-2 mb-2"
                        disabled
                        :class="{
                          'is-invalid': formSubmitted &&
                                        $v.program.goal_direction_id.$error,
                        }"
                        :options="getGoalDirectionsOptions()"
                      >
                      </b-form-radio-group>

                      <div
                        v-if="formSubmitted && $v.program.goal_direction_id.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.program.goal_direction_id.required">
                          This value is required.
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-cols="9"
                      label-cols-sm="3"
                      label="Fitness Level(s)"
                      label-for="ability-level"
                    >
                      <b-form-checkbox-group
                        id="ability-level"
                        name="ability-level"
                        :class="{
                          'is-invalid': formSubmitted &&
                                        $v.program.fitness_level_ids.$error,
                        }"
                        class="mt-2"
                        disabled
                      >
                        <b-form-checkbox
                          v-model="program.fitness_level_ids"
                          v-for="level in abilityLevels"
                          :key="level.id"
                          :value="level.id"
                        >
                          {{ s(level.name).capitalize().value() }}
                        </b-form-checkbox>
                      </b-form-checkbox-group>

                      <div
                        v-if="formSubmitted && $v.program.fitness_level_ids.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.program.fitness_level_ids.required">
                          This value is required.
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-cols="9"
                      label-cols-sm="3"
                      label="Workouts per Week"
                      label-for="workouts_per_week"
                    >
                      <b-form-input
                        v-model="program.workouts_per_week"
                        type="number"
                        number
                        placeholder="Workouts per Week"
                        disabled
                      />
                    </b-form-group>

                    <b-form-group
                      label-cols="9"
                      label-cols-sm="3"
                      label="Description"
                      label-for="description"
                    >
                      <b-form-textarea
                        v-model="program.description"
                        rows="5"
                        class="form-control"
                        placeholder="Description"
                        :class="{
                          'is-invalid': formSubmitted &&
                                        $v.program.description.$error,
                        }"
                      />
                      <div
                        v-if="formSubmitted && $v.program.description.$error"
                        class="invalid-feedback"
                      >
                        <span v-if="!$v.program.description.required">
                          This value is required.
                        </span>
                        <span v-if="!$v.program.description.maxLength">
                          This value is 200 letters maximum length.
                        </span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      label-cols="9"
                      label-cols-sm="3"
                      label="Publish"
                      label-for="is_private"
                    >
                      <FormInputButtons
                        v-model="program.is_private"
                        tag="b-button-group"
                        :options="[
                          { value: false, text: 'Public' },
                          { value: true, text: 'Private' }
                        ]"
                      />
                    </b-form-group>

                    <div class="mt-3 bottom-button-container form-group">
                      <div class="float-right">
                        <button
                          v-if="isEdit"
                          type="button"
                          @click.stop.prevent="onFormCancel"
                          class="btn btn-secondary"
                        >
                          Cancel
                        </button>
                        <button type="submit" class="btn btn-primary m-l-5 ml-1">
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </b-overlay>
              </b-collapse>
            </div>
          </div>

          <b-collapse :visible="!editEntityMode">
            <b-overlay
              :show="phasesOverlay"
              :variant="config.overlay.variant"
              :opacity="config.overlay.opacity"
              :blur="config.overlay.blur"
              rounded="sm"
            >
              <b-tabs class="phases-tabs">
                <b-tab
                  v-for="(phase, phase_index) in program.phases"
                  :key="phase.id"
                  :title="'Phase ' + (phase_index + 1)"
                >
                  <div class="card">
                    <div class="card-body">
                      <div class="phase-wrapper">
                        <div
                          v-for="(week, week_index) in phase.weeks"
                          :key="week.id"
                          class="week-wrapper"
                        >
                          <div class="week-row title">
                            <div class="cell cell-name">
                              Week {{ week_index + 1 }}
                            </div>

                            <div
                              v-if="forecast.phases"
                              class="cell cell-name text-right"
                            >
                              <span>
                                Rest: {{ forecast.phases[phase_index].weeks[week_index].rest }}
                              </span>

                              <span>
                                Reps: {{ getWeekReps(forecast.phases[phase_index].weeks[week_index]).join(', ') }}
                              </span>                            
                            </div>
                          </div>

                          <Draggable
                            group="workouts"
                            :list="week.workouts"
                            @update="onWorkoutOrder"
                          >
                            <div
                              v-for="(workout, workout_index) in week.workouts"
                              :key="workout_index"
                              class="week-row week-content"
                            >
                              <div class="cell cell-number align-self-center">
                                {{ workout_index + 1 }}
                              </div>
                              <div class="cell cell-name align-self-center">
                                {{ workout.name }}
                              </div>
                              <div class="cell cell-muscles align-self-center">
                                where muscles come from?
                              </div>
                              <div class="cell cell-ability-level align-self-center">
                                <Abilities :ids="workout.fitness_level_ids || []" />
                              </div>
                              <div class="cell cell-delete align-self-center">
                                <a
                                  @click="onWorkoutDelete(workout, week)"
                                  title="Delete workout"
                                >
                                  <FaIcon icon="far fa-trash-alt" size="17"/>
                                </a>
                              </div>
                            </div>
                          </Draggable>

                          <div
                            v-show="
                              week.workouts.length < program.workouts_per_week
                            "
                            class="week-row week-content"
                          >
                            <Draggable
                              group="workouts"
                              :data-phase-id="phase.id"
                              :data-week-id="week.id"
                              :list="week.workouts"
                              @add="onWorkoutAdd"
                            >
                              <div class="cell cell-add-workout text-center">
                                + Drop workout to add
                              </div>
                            </Draggable>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </b-tab>
              </b-tabs>
            </b-overlay>

            <div class="mb-3 mt-3 bottom-button-container">
              <div class="float-right">
                <b-button
                  class="ml-1"
                  to="/workout-programs/list"
                  variant="success"
                >
                  Finish
                </b-button>
              </div>
            </div>
          </b-collapse>
        </div>
      </div>
    </template>
  </Layout>
</template>

<style scoped>
  .card.card-forecast >>> table tr {
    background: #d7dbf4;
  }
  .card.card-forecast >>> table tbody tr {
    cursor: pointer;
  }
  .card.card-forecast >>> table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
  .workout-table-card {
    background: #d7dbf4;
  }
  .workout-table tr th,
  .workout-table tr td {
    width: 500px;
  }
  .workout-table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
  .phases-tabs >>> .nav-tabs .nav-link.active {
    border-bottom: 1px solid #edecf2;
    background: #edecf2;
  }
  .phases-tabs .tab-content .tab-pane.active {
    background: #edecf2;
  }
  .phases-tabs .tab-content .card {
    background: none;
  }
  .phase-wrapper .week-wrapper .week-row {
    display: flex;
    border-bottom: 1px solid #fff;
  }
  .phase-wrapper .week-wrapper .week-row .cell {
    padding: 10px;
    width: 1000px;
    display: table-cell;
  }
  .phase-wrapper .week-wrapper .week-row.title {
    background: #b2dada;
  }
  .phase-wrapper .week-wrapper .week-content {
    background: #dad9de;
  }
  .phase-wrapper .week-wrapper .week-content:nth-child(odd) {
    background: #edecf2;
  }
  .phase-wrapper .week-wrapper .week-content .cell-number,
  .phase-wrapper .week-wrapper .week-content .cell-delete {
    width: 50px;
  }
</style>
