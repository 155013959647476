<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-tabs
        active-nav-item-class="bg-tab text-primary"
        nav-class="border-0"
        justified
        no-fade
      >
        <b-tab
          v-for="phase in newItem.phases"
          :key="phase.id"
          lazy
          title-link-class="rounded-0 border-0"
          :title="`Phase ${phase.number}`"
        >
          <CurriculumPhaseMeals
            :item="phase"
            :curriculum="item"
          />
        </b-tab>
      </b-tabs>

      <!-- SUBMIT -->
      <b-form-group class="text-right my-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import CurriculumPhaseMeals from './CurriculumPhaseMeals'

export default {
  name: 'CurriculumItemNutritionUpdate',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumPhaseMeals
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  methods: {
    getInput() {
      return {}
    }
  }
}
</script>