<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="popupIsShown = true"
  >
    <slot />

    <b-modal
      v-model="popupIsShown"
      content-class="rounded-0 border-0"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-4"
      hide-footer
    >
      <template #modal-title>
        {{ title }}
      </template>
      
      <CurriculumItemReboot
        :item="item"
        @rebooted:item="$emit('rebooted:item', $event); popupIsShown = false"
      />
    </b-modal>
  </component>
</template>

<script>
import CurriculumItemReboot from '../CurriculumItemReboot'

export default {
  name: 'CurriculumItemRebootButton',

  components: {
    CurriculumItemReboot
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      popupIsShown: false
    }
  },

  computed: {
    title() {
      return 'Reboot Curriculum'
    }
  }
}
</script>
