<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- NAME -->
      <b-form-group
        label="Name"
        label-cols="2"
        :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
        :state="getFieldState($v.input.name)"
      >
        <b-form-input
          v-model="input.name"
          :state="getFieldState($v.input.name)"
          placeholder="Pick or Enter Set Name"
        />

        <div class="mt-2">
          <b-button
            v-for="nameOption in nameOptions"
            :key="nameOption"
            class="mx-1"
            size="sm"
            @click="input.name = nameOption"
          >
            {{ nameOption }}
          </b-button>
        </div>
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />{{ actionName }}
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'ExerciseGroupItemUpsert',

  mixins: [
    crudItemMixin
  ],


  data() {
    return {
      baseEndpoint: 'exercise-groups',
      isExpanded: false
    }
  },

  computed: {
    actionName() {
      return this.isCreated ? 'Update' : this.source ? 'Copy' : 'Create'
    },

    nameOptions() {
      return ['Single', 'Super 2', 'Super 3']
    }
  },

  validations() {
    return {
      input: {
        name: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        name: this.newItem.name
      }

      if (!this.isCreated) {
        input.workout_id = this.item.workout_id
      }

      return input
    }
  }
}
</script>