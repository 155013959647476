<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import FaIcon from '@/components/widgets/fa-icon';
import { assetComputed, companyComputed } from '@/state/helpers';
import { required, minLength, maxLength } from 'vuelidate/lib/validators';
import _ from 'lodash';
import s from 'underscore.string';
import Swal from 'sweetalert2';
import Draggable from 'vuedraggable';
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
//import RawDisplayer from "@/components/raw-displayer";

/**
 * Form Advanced component
 */
export default {
  name: 'SupplementProgramAdd',
  page: {
    title: 'Supplement Program Creator',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  computed: {
    ...assetComputed,
    ...companyComputed,
    title() {
      return this.isEdit ?
               'Edit Supplement Program' :
                this.isCopy ?
                  'Copy Supplement Program' :
                  'Supplement Program Creator';
    },
    isEdit() {
      return this.$route.name === 'supplement-program-edit';
    },
    isCopy() {
      return this.$route.name === 'supplement-program-copy';
    },
  },
  components: { Layout, PageHeader, FaIcon, Draggable, Abilities, GoalDirections },
  data() {
    return this.initialData();
  },
  validations: {
    form: {
      name: { required, minLength: minLength(5), maxLength: maxLength(255) },
      description: {
        required,
        maxLength: maxLength(255),
      },
      fitness_level_ids: { required },
      goal_direction_id: { required },
      gender: { required },
    },
  },
  methods: {
    s: s,
    initialData() {
      return {
        config: appConfig,
        // entity id in edit
        id: null,
        // edit program entity
        editEntityMode: true,
        // when go to the next step on Workout save - use this flag in order not to reload form data
        keepData: false,
        formSubmitted: false,
        formClone: {},
        formOverlay: false,
        form: {
          name: '',
          description: '',
          fitness_level_ids: [],
          goal_direction_id: null,
          gender: null,
          company_id: null,
          phases: [],
        },
        supplements: {
          isBusy: false,
          items: [],
          totalRows: 0,
          currentPage: 1,
          perPage: 10,
          filter: {
            type: '',
            form: '',
          },
          sortBy: 'name',
          sortDesc: false,
        },
        phasesOverlay: false,
      };
    },
    onFormSubmit() {
      this.formSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      if (this.currentCompany) {
        this.form.company_id = this.currentCompany.id;
      }

      this.editEntityMode = false;

      this.formOverlay = true;

      if (this.isEdit) {
        this.$axios
          .patch(`/supplement-programs/${this.id}`, this.form)
          .then(({ data }) => {
            this.formOverlay = false;
            this.editEntityMode = false;
            this.keepData = true;
            this.$router.push(`/supplement-program/${data.id}`);
          })
          .catch(() => {
            this.formOverlay = false;
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Could not save supplement program',
            });
          });
      } else {
        this.$axios
          .post('/supplement-programs', this.form)
          .then(({ data }) => {
            this.formOverlay = false;
            this.editEntityMode = false;
            this.keepData = true;
            this.$router.push(`/supplement-program/${data.id}`);
          })
          .catch(() => {
            this.formOverlay = false;
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Could not save supplement program',
            });
          });
      }
    },
    loadSupplements() {
      this.supplementsDataProvider();
    },
    supplementsDataProvider() {
      const filters = [];

      for (const i in this.supplements.filter) {
        let filter = this.supplements.filter[i];

        if (filter) {
          filters.push({
            field: i,
            value: filter,
          });
        }
      }

      const limit = this.supplements.perPage;
      const page = this.supplements.currentPage;

      this.supplements.isBusy = true;
      return this.$axios
        .get(`/supplement/?limit=${limit}&page=${page}`, {
          params: {
            filters: JSON.stringify(filters),
          },
        })
        .then(({ data }) => {
          this.supplements.isBusy = false;
          this.supplements.totalRows = data.pagination.total;
          this.supplements.items = data.data;
        })
        .catch(() => {
          this.supplements.isBusy = false;
        });
    },
    onEditSupplementProgramEntity() {
      this.editEntityMode = true;
    },
    onFormCancel() {
      if (this.isEdit) {
        this.editEntityMode = false;
        this.form = _.cloneDeep(this.formClone);
      }
    },
    loadSupplementProgram() {
      this.id = this.$route.params.id;

      if (this.id) {
        this.programOverlay = true;
        this.$axios
          .get(`/supplement-programs/${this.id}`)
          .then(({ data }) => {
            this.form = data;
            this.formClone = _.cloneDeep(this.form);
          })
          .finally(() => {
            this.formOverlay = false;
          });
      }
    },
    onMove({ dragged, to }) {
      // filter draggable target
      if (!to.getAttribute('data-supplement-drop-target')) return false;

      // prevent supplements duplication
      const phase_index = to.getAttribute('data-phase-index');
      const supplement_id = dragged.getAttribute('data-id');
      let phase = this.form.phases.slice(phase_index, phase_index + 1)[0];

      let supplement = phase.supplements.filter((s) => s.id === supplement_id);
      if (supplement.length !== 0) {
        return false;
      }
    },
    onSupplementMove({ from, to }) {
      // allow only reorder within same phase
      if (
        from.getAttribute('data-phase-index') !==
        to.getAttribute('data-phase-index')
      ) {
        return false;
      }
    },
    onAddPhase() {
      let phase = { supplements: [] };
      this.form.phases.push(phase);
      this.savePhases();
    },
    onSupplementAdd() {
      this.savePhases();
    },
    onSupplementOrder() {
      this.savePhases();
    },
    onPhaseOrder() {
      this.savePhases();
    },
    onPhaseDelete(phase) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.form.phases = this.form.phases.filter((p) => p !== phase);
          this.savePhases();
        }
      });
    },
    onSupplementDelete(phase, supplement) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          phase.supplements = phase.supplements.filter((s) => s !== supplement);
          this.savePhases();
        }
      });
    },
    savePhases() {
      // save program phases
      this.phasesOverlay = true;

      this.$axios
        .patch(`/supplement-programs/${this.id}`, this.form)
        .catch(() => {
          this.$store.commit('layout/addAlerts', {
            type: 'warning',
            text: 'Could not save supplement program',
          });
        })
        .finally(() => {
          this.phasesOverlay = false;
        });
    },
    getSexOptions() {
      return this.$store.getters['asset/getSexOptions'];
    },
    getGoalDirectionsOptions() {
      return this.$store.getters['asset/getGoalDirectionsOptions'];
    },
    supplementDoseTypeName(id) {
      const doseType = _.find(this.supplementDoseTypes, { id: id });
      if (doseType) return doseType.name;
      return id;
    },
    supplementFormTypeName(id) {
      const formType = _.find(this.supplementFormTypes, { id: id });
      if (formType) return formType.name;
      return id;
    },
  },
  mounted() {
    this.loadSupplementProgram();
    this.loadSupplements();
  },
  watch: {
    // watch for URL update to start over
    '$route.path'() {
      if (!this.keepData) {
        Object.assign(this.$data, this.initialData());
      }
      this.keepData = false;
      this.id = this.$route.params.id;
    },
    'supplements.filter.type'() {
      this.supplements.currentPage = 1;
      this.loadSupplements();
    },
    'supplements.filter.form'() {
      this.supplements.currentPage = 1;
      this.loadSupplements();
    },
    'supplements.currentPage'() {
      this.loadSupplements();
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-lg-6">
        <b-overlay
          :show="supplements.isBusy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <div class="card supplements-table-card">
            <div class="card-body">
              <div class="row mb-md-2">
                <!-- Filters -->
                <div class="col-sm-12 col-md-6">
                  <b-form-select
                    v-model="supplements.filter.type"
                    size="sm"
                    :options="supplementTypesOptionsWithEmptyValue"
                  ></b-form-select>
                </div>
                <div class="col-sm-12 col-md-6">
                  <b-form-select
                    v-model="supplements.filter.form"
                    size="sm"
                    :options="supplementFormTypesOptionsWithEmptyValue"
                  ></b-form-select>
                </div>
              </div>
              <div class="table-responsive mb-0">
                <b-table-simple
                  class="supplements-table"
                  :busy="supplements.isBusy"
                >
                  <b-thead>
                    <div>
                      <b-tr>
                        <b-th>Name</b-th>
                        <b-th>Type</b-th>
                        <b-th>Dose</b-th>
                        <b-th>Form</b-th>
                      </b-tr>
                    </div>
                  </b-thead>
                  <b-tbody>
                    <Draggable
                      :move="onMove"
                      :disabled="editEntityMode"
                      :group="{
                        name: 'phase.supplements',
                        pull: 'clone',
                        put: false,
                      }"
                      :list="supplements.items"
                    >
                      <b-tr
                        v-for="supplement in this.supplements.items"
                        :key="supplement.id"
                        :data-id="supplement.id"
                      >
                        <b-td>{{ supplement.name }}</b-td>
                        <b-td>{{ supplement.type_name }}</b-td>
                        <b-td>
                          {{
                            `${supplement.dose} ${supplementDoseTypeName(supplement.dose_type)}`
                          }}
                        </b-td>
                        <b-td>{{ supplementFormTypeName(supplement.form) }}</b-td>
                      </b-tr>
                    </Draggable>
                  </b-tbody>
                </b-table-simple>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0 mt-4">
                      <!-- pagination -->
                      <b-pagination
                        v-model="supplements.currentPage"
                        :total-rows="supplements.totalRows"
                        :per-page="supplements.perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
      <!-- end col -->
      <div class="col-lg-6">
        <b-collapse :visible="!editEntityMode">
          <div class="card">
            <div class="card-body">
              <b-row>
                <b-col cols="8">
                  <h4 class="mb-3 card-title">
                    Supplement Program - {{ form.name }}
                  </h4>
                </b-col>
                <b-col cols="4">
                  <div class="float-right">
                    <button
                      type="edit"
                      @click="onEditSupplementProgramEntity"
                      class="btn btn-secondary"
                    >
                      Edit
                    </button>
                  </div>
                </b-col>
              </b-row>
              <dl class="row mt-3 mb-0">
                <dt class="col-sm-5">Gender</dt>
                <dd class="col-sm-7">
                  {{ form.gender }}
                </dd>
                <dt class="col-sm-5">Goal Direction</dt>
                <dd class="col-sm-7">
                  <GoalDirections :ids="[form.goal_direction_id]" />
                </dd>
                <dt class="col-sm-5">Fitness Level</dt>
                <dd class="col-sm-7">
                  <Abilities :ids="form.fitness_level_ids" />
                </dd>
                <dt class="col-sm-5">Description</dt>
                <dd class="col-sm-7">
                  {{ form.description }}
                </dd>
              </dl>
            </div>
          </div>
        </b-collapse>

        <b-collapse :visible="editEntityMode">
          <div class="card">
            <b-overlay
              :show="formOverlay"
              variant="white"
              opacity="0.5"
              blur="2px"
              rounded="sm"
            >
              <div class="card-body">
                <form action="#" @submit.prevent="onFormSubmit">
                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Name"
                    label-for="name"
                  >
                    <input
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Name"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.name.$error
                      }"
                    />
                    <div
                      v-if="formSubmitted && $v.form.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.name.required">
                        This value is required.
                      </span>
                      <span v-if="!$v.form.name.minLength">
                        This value is 5 letters minimum length.
                      </span>
                      <span v-if="!$v.form.name.maxLength">
                        This value is 200 letters maximum length.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Gender"
                    label-for="gender"
                  >
                    <b-form-radio-group
                      id="gender"
                      v-model="form.gender"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.gender.$error
                      }"
                      class="pt-2"
                      :options="getSexOptions()"
                    >
                    </b-form-radio-group>

                    <div
                      v-if="formSubmitted && $v.form.gender.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.gender.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Goal Direction"
                    label-for="goal_direction_id"
                  >
                    <b-form-radio-group
                      id="goal_direction_id"
                      v-model="form.goal_direction_id"
                      class="mt-2 mb-2"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.goal_direction_id.$error
                      }"
                      :options="getGoalDirectionsOptions()"
                    >
                    </b-form-radio-group>

                    <div
                      v-if="formSubmitted && $v.form.goal_direction_id.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.goal_direction_id.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Fitness Level(s)"
                    label-for="ability-level"
                  >
                    <b-form-checkbox-group
                      id="ability-level"
                      name="ability-level"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.fitness_level_ids.$error
                      }"
                      class="mt-2"
                    >
                      <b-form-checkbox
                        v-model="form.fitness_level_ids"
                        v-for="level in abilityLevels"
                        :key="level.id"
                        :value="level.id"
                      >
                        {{ s(level.name).capitalize().value() }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <div
                      v-if="formSubmitted && $v.form.fitness_level_ids.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.fitness_level_ids.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Description"
                    label-for="description"
                  >
                    <b-form-textarea
                      v-model="form.description"
                      rows="5"
                      class="form-control"
                      placeholder="Description"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.description.$error
                      }"
                    />
                    <div
                      v-if="formSubmitted && $v.form.description.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.description.required">
                        This value is required.
                      </span>
                      <span v-if="!$v.form.description.maxLength">
                        This value is 200 letters maximum length.
                      </span>
                    </div>
                  </b-form-group>

                  <div class="mt-3 bottom-button-container">
                    <div class="float-right">
                      <button
                        type="button"
                        @click.stop.prevent="onFormCancel"
                        class="btn btn-secondary"
                      >
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-primary m-l-5 ml-1">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </b-overlay>
          </div>
        </b-collapse>

        <b-collapse :visible="!editEntityMode">
          <b-overlay
            :show="phasesOverlay"
            variant="white"
            opacity="0.5"
            blur="2px"
            rounded="sm"
          >
            <div class="phases-table">
              <div class="header">
                <div class="cell cell-phase-name phase-name col-lg-2"></div>
                <div class="cell cell-supplement-name col-lg-3">Name</div>
                <div class="cell cell-supplement-type col-lg-2">Type</div>
                <div class="cell cell-supplement-dose col-lg-3">Dose</div>
                <div class="cell cell-delete-supplement col-lg-1"></div>
                <div class="cell cell-delete-phase col-lg-1"></div>
              </div>
              <template v-if="form.phases.length > 0">
                <Draggable
                  group="phases"
                  :list="form.phases"
                  @update="onPhaseOrder"
                >
                  <div
                    class="phase-wrapper"
                    v-for="(phase, phaseIndex) in form.phases"
                    :key="'phase-' + phaseIndex"
                  >
                    <div class="extra-row" v-if="phaseIndex > 0">
                      <div class="cell cell-phase-name phase-name col-lg-2"></div>
                      <div class="cell cell-supplement-name col-lg-3"></div>
                      <div class="cell cell-supplement-type col-lg-2"></div>
                      <div class="cell cell-supplement-dose col-lg-3"></div>
                      <div class="cell cell-delete-supplement col-lg-1"></div>
                      <div class="cell cell-delete-phase col-lg-1"></div>
                    </div>
                    <template v-if="phase.supplements.length > 0">
                      <div class="group-supplements">
                        <div
                          class="align-middle phase-name cell cell-phase-name col-lg-2"
                        >
                          Phase {{ phaseIndex + 1 }}
                        </div>
                        <div class="supplement-rows col-lg-9">
                          <Draggable
                            group="phase.supplements"
                            :data-supplement-drop-target="true"
                            :data-phase-index="phaseIndex"
                            :move="onSupplementMove"
                            :list="phase.supplements"
                            @add="onSupplementAdd"
                            @update="onSupplementOrder"
                          >
                            <div
                              class="supplement-row row"
                              v-for="(supplement,
                              supplementIndex) in phase.supplements"
                              :key="'supplement-' + supplementIndex"
                            >
                              <div
                                class="align-middle cell cell-supplement-name col-lg-4"
                              >
                                {{ supplement.name }}
                              </div>
                              <div
                                class="align-middle cell cell-supplement-type col-lg-3"
                              >
                                {{ supplement.type_name }}
                              </div>
                              <div
                                class="align-middle cell cell-supplement-dose col-lg-4"
                              >
                                {{ `${supplement.dose} ${supplementDoseTypeName(supplement.dose_type)}` }}
                              </div>
                              <div
                                class="align-middle cell cell-delete-supplement col-lg-1"
                              >
                                <a
                                  @click="onSupplementDelete(phase, supplement)"
                                  title="Delete supplement from phase"
                                >
                                  <FaIcon
                                    icon="far fa-trash-alt"
                                    :key="'supplement_delete_' + supplementIndex"
                                    size="17"
                                /></a>
                              </div>
                            </div>
                          </Draggable>
                        </div>
                        <div class="cell cell-delete-phase col-lg-1">
                          <a @click="onPhaseDelete(phase)" title="Delete Set">
                            <FaIcon
                              icon="far fa-trash-alt"
                              :key="'phase_delete_' + phaseIndex"
                              size="17"
                          /></a>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="group-supplements">
                        <div
                          class="align-middle phase-name cell cell-phase-name col-lg-2"
                        >
                          Phase {{ phaseIndex + 1 }}
                        </div>
                        <div class="supplement-rows col-lg-9">
                          <Draggable
                            class="supplement-row"
                            group="phase.supplements"
                            :data-supplement-drop-target="true"
                            :data-phase-index="phaseIndex"
                            :list="phase.supplements"
                            @add="onSupplementAdd"
                          >
                            <div class="text-center cell cell-add-supplement">
                              + Add Supplement
                            </div>
                          </Draggable>
                        </div>
                        <div class="cell cell-delete-phase col-lg-1">
                          <a @click="onPhaseDelete(phase)" title="Delete Phase">
                            <FaIcon
                              icon="far fa-trash-alt"
                              :key="'phase_delete_' + phaseIndex"
                              size="17"
                            />
                          </a>
                        </div>
                      </div>
                    </template>
                  </div>
                </Draggable>
              </template>
              <template v-else>
                <div class="phases-wrapper">
                  <div class="group-supplements">
                    <div
                      class="align-middle phase-name cell cell-phase-name col-lg-2"
                    ></div>
                    <div class="supplement-rows col-lg-9">
                      <div class="supplement-row">
                        <div class="align-middle cell cell-no-phases">
                          No phases yet
                        </div>
                      </div>
                    </div>
                    <div class="cell cell-delete-phase col-lg-1" />
                  </div>
                </div>
              </template>
            </div>
            <div class="mb-3 mt-3 bottom-button-container">
              <div class="float-right">
                <button type="button" @click="onAddPhase" class="btn btn-primary">
                  + Add Phase
                </button>
              </div>
            </div>
          </b-overlay>
        </b-collapse>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
  .supplements-table tr {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  .supplements-table tbody + tbody,
  .supplements-table tr th {
    border-bottom-width: 1px;
  }
  .supplements-table tr th,
  .supplements-table tr td {
    width: 500px;
  }
  .supplements-table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
  .supplements-table-card {
    background: #d7dbf4;
  }
  .bottom-button-container {
    overflow: hidden;
  }

  div.phases-table .supplement-row {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
  }
  div.phases-table .header {
    border-bottom: 1px solid #fff;
  }
  div.phases-table .group-supplements,
  div.phases-table .supplement-row,
  div.phases-table .extra-row,
  div.phases-table .header {
    display: flex;
    min-height: 40px;
    align-items: center;
  }
  div.phases-table .header div {
    min-height: 40px;
    background: #b2dada;
    line-height: 40px;
  }
  div.phases-table .extra-row {
    border-bottom: 1px solid #fff;
  }
  div.phases-table .extra-row div {
    min-height: 40px;
    background: #b2dada;
  }
  div.phases-table .phase-wrapper {
    border-bottom: 1px solid #fff;
  }
  div.phases-table .group-supplements {
    background: #b2dada;
  }
  div.phases-table .supplement-rows {
    background: #dad9de;
  }
  div.phases-table .cell-phase-name {
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    align-self: center;
  }
  div.phases-table .extra-row .cell-phase-name,
  div.phases-table .header .cell-phase-name {
    background: #87cdcb;
  }
  div.phases-table .cell-delete-supplement,
  div.phases-table .cell-delete-phase {
    text-align: center;
  }
</style>
