import ax from 'axios'
import store from '@/state/store'
import _ from 'lodash'
//import router from '@/router/routes'

const tokensEndpoints = [
  'auth/token-refresh',
  'auth/token-revoke'
]

const $axios = ax.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
  }
})

$axios.interceptors.response.use((response) => {
  store.commit('layout/setLoader', false)
  //window.console.log(response);

  // clear busy state on parent if set
  if (response.config && response.config.parent && response.config.parent.isBusy) {
    response.config.parent.isBusy = false;
  }

  return response
}, async (error) => {
  // clear busy state on parent if set
  if (_.has(error, 'response.config.parent.isBusy')) {
    error.response.config.parent.isBusy = false
  }

  let errors;

  if (_.has(error, 'response.data.errors')) {
    errors = error.response.data.errors.map(e => e.detail)

  } else if (_.has(error, 'response.data.error.message')) {
    errors = [error.response.data.error.message]

  } else if (_.has(error, 'response.data.message')) {
    errors = [error.response.data.message]

  } else if (_.isArray(error.response && error.response.data)) {
    errors = error.response.data.map(e => e.message)
  }

  if (_.has(error, 'config.error_message')) {
    errors.unshift(error.config.error_message)
  }

  const status  = _.get(error, 'response.status')
  const url = _.get(error, 'response.config.url')
  const isInvalidToken = status === 401 && errors.includes('Token is invalid or expired') 
  const isTokenEndpoint = tokensEndpoints.includes(url)

  if (isInvalidToken && !isTokenEndpoint && !error.config.__isRetry) {
    await store.commit('auth/SET_ACCESS_TOKEN')
    const accessToken = await store.dispatch('auth/fetchAccessToken')

    if (accessToken) {
      window.console.log('Retry')
      error.config.headers.Authorization = `Bearer ${accessToken.token}`
      return $axios(error.config)
    }
  }

  if (!(isInvalidToken && isTokenEndpoint)) {
    store.commit("layout/addAlerts", {
      type: "danger",
      text: errors ? errors.join("<br>") : 'Data is not loaded. Error happened',
    })
  }

  store.commit('layout/setLoader', false)
  return Promise.reject(error)
})


$axios.interceptors.request.use(async (config) => {
  if (!tokensEndpoints.includes(config.url)) {
    const accessToken = await store.dispatch('auth/fetchAccessToken')

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken.token}`
    }
  }

  // Current Company
  const currentRole = store.getters['auth/currentRole']

  if (currentRole) {
    config.headers['X-Company-ID'] = currentRole.company_id
    config.headers['X-Role-ID'] = currentRole.role_id
  }

  if (config.parent && typeof config.parent.isBusy !== 'undefined') {
    config.parent.isBusy = true;
  }

  return config;
});

export default $axios
