<script>
/**
 * Topbar component
 */
import _ from 'lodash'
import {
  authMethods,
  authComputed,
} from "@/state/helpers";
import Swal from "sweetalert2";
import CompanySelect from '@/components/app/CompanySelect'
import AdminViewCheckbox from '@/components/app/AdminViewCheckbox'

export default {
  components: {
    CompanySelect,
    AdminViewCheckbox
  },
  data() {
    return {};
  },
  computed: {
    ...authComputed,

    currentUser() {
      return this.$store.getters['auth/currentUser']
    },

    currentCompany() {
      return this.$store.getters['auth/currentCompany']
    },

    currentUserCompanies() {
      return this.$store.getters['auth/currentUserCompanies']
    },

    currentUserAvatarText() {
      return `${_.get(this.currentUser.first_name, '0') || ''}${_.get(this.currentUser.last_name, '0') || ''}` 
    }
  },
  methods: {
    ...authMethods,
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    },
    async logout() {
      await this.logOut();
      // this.$acl.change("public");
      // this.$router.push({ path: "/login" });
    },
    changeCompany(event) {
      const company = this.userCompanies.filter(
        (company) => company.id == event.target.dataset.id
      );
      this.setCurrentCompany({ company: company[0] });

      const role = this.userRoles.filter(
        (role) => role.company_id == event.target.dataset.id
      );
      this.setCurrentRole({ role: role[0] });

      this.$acl.change(`role_${role[0].role_id}`);

      this.$router.push("/").catch((error) => window.console.log(error));
    },
    applyCompany() {
      var ipAPI = "https://api.ipify.org?format=json";
      Swal.queue([
        {
          title: "Apply for a new Company",
          confirmButtonText: "Apply Now",
          text: "",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(ipAPI)
              .then((response) => response.json())
              .then((data) => {
                let message =
                  '<div class="apply-token" style="font-size:16px;">';
                message +=
                  '<div class="token-value" style="font-weight:bold;font-size:24px;">';
                message += data.ip;
                message += "</div>";
                message +=
                  "Please pass token to company manager<br>Token expires in 60 minutes";
                message += "</div>";
                Swal.insertQueueStep(message);
              })
              .catch(() => {
                Swal.insertQueueStep({
                  type: "error",
                  title: "Unable to get your public IP",
                });
              });
          },
        },
      ]);
    },
  },
};
</script>
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo_gold.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo_gold.png" alt height="110" />
            </span>
          </router-link>

          <router-link tag="a" to="/" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo_gold.png" alt height="22" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo_gold.png" alt height="110" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item"
          id="vertical-menu-btn"
          @click="toggleMenu()"
        >
          <i class="mdi mdi-menu"></i>
        </button>

        <!--<div v-if="userCompanies.length === 0" class="d-none d-sm-block">
          <button type="button" class="btn btn-secondary">No company</button>
        </div>-->

        <div class="d-none d-sm-block">
          <CompanySelect class="pt-3 d-inline-block" />
        </div>

        <div
          v-if="$store.getters['auth/isAdmin']"
          class="d-none d-sm-block ml-1"
        >
          <AdminViewCheckbox class="pt-3 d-inline-block" />
        </div>
      </div>

      <div class="d-flex">
        <b-dropdown
          class="d-inline-block"
          right
          toggle-class="header-item"
          variant="white"
        >
          <template v-slot:button-content>
            <b-avatar
              :src="currentUser.profile_img_url"
              :text="currentUserAvatarText"
              :badge="`${currentUser.id}`"
            />
          </template>

          <router-link
            v-if="$store.getters['auth/loggedIn']"
            class="dropdown-item"
            :to="`/profile`"
          >
            <i
              class="mdi mdi-account-circle font-size-17 align-middle mr-1"
            />
            Profile
          </router-link>

          <router-link
            class="dropdown-item"
            to="/account"
          >
            <i
              class="mdi mdi-settings font-size-17 align-middle mr-1"
            />
            Account
          </router-link>

          <div class="dropdown-divider"></div>
          <a
            class="dropdown-item text-danger"
            href="/"
            @click="
              (e) => {
                e.preventDefault();
                logout();
              }
            "
          >
            <i
              class="bx bx-power-off font-size-17 align-middle mr-1 text-danger"
            ></i>
            Logout
          </a>
        </b-dropdown>
      </div>
    </div>
  </header>
</template>

<style scoped>
.apply-token .token-value {
  font-weight: bold;
}
.navbar-brand-box {
  position: absolute;
  width: 240px;
  top: 0;
  padding-top: 20px;
}
#vertical-menu-btn {
  margin-left: 240px;
}
.vertical-collpsed #vertical-menu-btn {
  margin-left: 70px;
}

@media (max-width: 1024px) {
  .navbar-brand-box {
    display: none;
  }

  #vertical-menu-btn {
    margin-left: 0;
  }
}
</style>
