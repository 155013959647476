import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2'
import router from './router/index'
import store from '@/state/store'
import vco from "v-click-outside"
import "@/design/index.scss";
import VueAxios from './plugins/axios'
import acl from '@/plugins/acl'
import moment from 'moment'
import momentTz from 'moment-timezone'
import VueSlideBar from 'vue-slide-bar'
// import VueCompositionAPI from '@vue/composition-api'

//import { initFirebaseBackend } from './authUtils'

/*const firebaseConfig = {
  apiKey: process.env.VUE_APP_apiKey,
  authDomain: process.env.VUE_APP_authDomain,
  databaseURL: process.env.VUE_APP_databaseURL,
  projectId: process.env.VUE_APP_projectId,
  storageBucket: process.env.VUE_APP_storageBucket,
  messagingSenderId: process.env.VUE_APP_messagingSenderId,
  appId: process.env.VUE_APP_appId,
  measurementId: process.env.VUE_APP_measurementId
};*/

//initFirebaseBackend(firebaseConfig);

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

Vue.use(Vuelidate);
Vue.use(VueMask)
Vue.use(VueSweetalert2)
Vue.use(vco)
Vue.use(VueAxios)
// Vue.use(VueCompositionAPI)
Vue.component('VueSlideBar', VueSlideBar)
Vue.prototype.$moment = moment
Vue.prototype.$moment.prototype.tz = momentTz

const app = new Vue({
  router,
  store,
  acl,
  render: h => h(App),
}).$mount('#app')

store.dispatch('auth/init', {app: app});
