<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>

      <b-col
        cols="4"
        class="d-flex align-items-center justify-content-end"
      >
        <FoodItemUpsertButton
          tag="b-button"
          :tag-props="{
            variant: 'primary'
          }"
          @update:item="refresh"
        >
          Add Food
        </FoodItemUpsertButton>
      </b-col>
    </b-row>

    <b-card>
      <FoodList
        ref="list"
      />
    </b-card>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import FoodList from '@/components/nutritions/FoodList'
import FoodItemUpsertButton from '@/components/nutritions/buttons/FoodItemUpsertButton'

export default {
  name: 'FoodsPage',

  components: {
    Layout,
    PageHeader,
    FoodList,
    FoodItemUpsertButton
  },

  page() {
    return {
      title: this.title
    }
  },

  computed: {
    title() {
      return 'Foods'
    }
  },

  methods: {
    refresh() {
      this.$refs.list.refresh()
    }
  }
}
</script>