var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', _vm._b({
    attrs: {
      "right": "",
      "text": "Actions"
    },
    scopedSlots: _vm._u([_vm.$slots["default"] ? {
      key: "button-content",
      fn: function fn() {
        return [_vm._t("default")];
      },
      proxy: true
    } : null], null, true)
  }, 'b-dropdown', _vm.dropdownProps, false), [_c('b-dropdown-item', {
    attrs: {
      "to": "/onboarding/".concat(_vm.item.id)
    }
  }, [_vm._v(" Onboarding ")]), _c('b-dropdown-item', {
    attrs: {
      "to": "/client/".concat(_vm.item.id, "/physical-conditions")
    }
  }, [_vm._v(" Measurements ")]), _c('b-dropdown-item', {
    attrs: {
      "to": "/client/".concat(_vm.item.id, "/photos")
    }
  }, [_vm._v(" Photos ")]), _c('ClientItemTrainersUpdateButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('submitted');
      }
    }
  }, [_vm._v(" Trainers ")]), _vm.$acl.check('couldEditUserPassword') ? _c('UserItemPasswordUpdateButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('submitted');
      }
    }
  }, [_vm._v(" Reset Password ")]) : _vm._e(), _c('b-dropdown-divider'), _c('b-dropdown-group', [_vm.item.curriculum ? [_c('b-dropdown-item', {
    attrs: {
      "to": "/curriculums/".concat(_vm.item.curriculum.id)
    }
  }, [_vm._v(" " + _vm._s(_vm.item.curriculum.status === 'designing' ? 'Build Curriculum' : 'Curriculum Assessment') + " ")]), _vm.item.curriculum.status !== 'designing' ? _c('b-dropdown-item', {
    attrs: {
      "to": "/curriculums/".concat(_vm.item.curriculum.id, "/logs")
    }
  }, [_vm._v(" Curriculum Logs ")]) : _vm._e(), _vm.curriculumIsCompletable && !_vm.item.curriculum.parent_id && _vm.$acl.check('couldBuildCurriculum') ? _c('CurriculumItemCompleteButton', {
    attrs: {
      "item": _vm.item.curriculum,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "completed:item": function completedItem($event) {
        return _vm.$emit('submitted');
      }
    }
  }, [_vm._v(" Complete Curriculum ")]) : _vm._e(), _vm.curriculumIsCancelable && !_vm.item.curriculum.parent_id && _vm.$acl.check('couldBuildCurriculum') ? _c('CurriculumItemCancelButton', {
    attrs: {
      "item": _vm.item.curriculum,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "canceled:item": function canceledItem($event) {
        return _vm.$emit('submitted');
      }
    }
  }, [_vm._v(" Cancel Curriculum ")]) : _vm._e(), _vm.curriculumIsRestartable && _vm.$acl.check('couldBuildCurriculum') ? _c('CurriculumItemRestartButton', {
    attrs: {
      "item": _vm.item.curriculum,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('submitted');
      }
    }
  }, [_vm._v(" Restart Curriculum ")]) : _vm._e(), _vm.curriculumIsPausable && _vm.$acl.check('couldBuildCurriculum') ? _c('CurriculumItemPauseButton', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "item": _vm.item.curriculum,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$emit('submitted');
      }
    }
  }, [_vm._v(" Pause Curriculum ")]) : _vm._e(), _vm.curriculumIsDeletable && _vm.$acl.check('couldBuildCurriculum') ? _c('CurriculumItemDeleteButton', {
    attrs: {
      "item": _vm.item.curriculum,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "deleted:item": function deletedItem($event) {
        return _vm.$emit('submitted');
      }
    }
  }, [_vm._v(" Delete Curriculum ")]) : _vm._e()] : _vm._e(), _c('b-dropdown-item', {
    attrs: {
      "to": "/client/".concat(_vm.item.id, "/curriculums")
    }
  }, [_vm._v(" Curriculum Histories ")])], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }