<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <WorkoutItemDetailButton
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      prefetch
    >
      Detail
    </WorkoutItemDetailButton>

    <b-dropdown-item
      v-if="showEditButton"
      :to="`/workout/${item.id}`"
    >
      Edit
    </b-dropdown-item>

    <WorkoutItemUpsertButton
      v-if="$acl.check('couldCopyWorkout')"
      :source="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @submitted="$emit('copied', $event)"
    >
      Copy
    </WorkoutItemUpsertButton>

    <WorkoutItemDeleteButton
      v-if="showDeleteButton"
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @deleted:item="$emit('deleted:item', $event)"
      @submitted="$emit('submitted', $event)"
    >
      Delete
    </WorkoutItemDeleteButton>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import WorkoutItemDetailButton from './WorkoutItemDetailButton'
import WorkoutItemUpsertButton from './WorkoutItemUpsertButton'
import WorkoutItemDeleteButton from './WorkoutItemDeleteButton'

export default {
  name: 'WorkoutItemActionsButton',

  components: {
    WorkoutItemDetailButton,
    WorkoutItemUpsertButton,
    WorkoutItemDeleteButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    showEditButton() {
      return (
        this.$acl.check('couldEditPublicWorkout') && !this.item.is_private ||
        this.$acl.check('couldEditOwnWorkout') && this.currentUser.id === this.item.created_by
      )
    },

    showDeleteButton() {
      return (
        this.$acl.check('couldDeletePublicWorkout') && !this.item.is_private ||
        this.$acl.check('couldDeleteOwnWorkout') && this.currentUser.id === this.item.created_by
      )
    }
  }
}
</script>