<template>
  <component
    :is="tag"
    v-bind="tagProps"
  >
    <slot :active-phase="activePhase">
      {{ activePhase ? `Phase ${activePhase.number}` : '-' }}
    </slot>
  </component>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CurriculumPhaseNumber',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    activePhase() {
      return _.find(this.item.phases, i => (
        this.$moment() >= this.$moment(i.start_date) && this.$moment() < this.$moment(i.end_date)
      ))
    }
  }
}
</script>