<template>
  <div>
    <b-row class="mb-4">
      <b-col>
        <CurriculumMealListTable
          ref="meal-table"
          :params="{
            filters: {
              phase_id: item.id,
              day_number: dayNumber
            },
            order_by: 'taken_at'
          }"
          :result.sync="mealResult"
          :curriculum="curriculum"
          :phase="item"
          :disabled="dayNumber !== 1"
          @update:result="$emit('update:result-meals', $event)"
        />
      </b-col>
    </b-row>

    <div
      v-if="dayNumber !== 1"
      class="my-3"
    >
      <b-button
        variant="warning"
        block
        :disabled="isReseting"
        @click="showResetModal()"
      >
        <b-spinner v-if="isReseting" small />Use Template Meals
      </b-button>
    </div>

    <b-row class="mb-4">
      <b-col>
        <CurriculumMealList
          ref="meal-list"
          :params="{
            filters: {
              phase_id: item.id,
              day_number: dayNumber
            },
            order_by: 'taken_at'
          }"
          :result.sync="mealResult"
          :supplement-result="supplementResult"
          :disabled="dayNumber !== 1"
          @submitted:supplement="i => $refs['supplement-list'].refresh()"
          @delete:supplement="i => $refs['supplement-list'].refresh()"
          @submitted:item="i => $refs['meal-table'].refresh()"
          @delete:item="i => $refs['meal-table'].refresh()"
        />
      </b-col>
    </b-row>

    <b-row
      v-show="false"
      class="mb-4"
    >
      <b-col>
        <b-card>
          <b-row
            class="mb-3"
            align-v="center"
          >
            <b-col class="font-size-16 font-weight-bold">
              Supplements
            </b-col>

            <b-col class="text-right">
              <CurriculumSupplementItemUpsertButton
                :item="{ phase_id: item.id, day_number: dayNumber }"
                class="p-0"
                tag="b-button"
                :tag-props="{
                  variant: 'link',
                  disabled: dayNumber !== 1
                }"
                @submitted="i => $refs['supplement-list'].refresh()"
              >
                + Add supplement
              </CurriculumSupplementItemUpsertButton>
            </b-col>
          </b-row>

          <CurriculumSupplementList
            ref="supplement-list"
            :params="{
              filters: {
                phase_id: item.id,
                day_number: dayNumber
              },
              order_by: 'taken_at'
            }"
            :disabled="dayNumber !== 1"
            :result.sync="supplementResult"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from 'lodash'
import CurriculumMealList from '@/components/curriculums/meals/CurriculumMealList'
import CurriculumMealListTable from '@/components/curriculums/meals/CurriculumMealListTable'
import CurriculumSupplementList from '@/components/curriculums/supplements/CurriculumSupplementList'
import CurriculumSupplementItemUpsertButton from '@/components/curriculums/supplements/buttons/CurriculumSupplementItemUpsertButton'

export default {
  name: 'CurriculumPhaseDayMeals',

  components: {
    CurriculumMealList,
    CurriculumSupplementList,
    CurriculumMealListTable,
    CurriculumSupplementItemUpsertButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    curriculum: {
      type: Object,
      required: true
    },

    dayNumber: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      mealResult: null,
      supplementResult: null,
      isReseting: false
    }
  },

  methods: {
    async resetTemplateMeals() {
      this.isReseting = true

      try {
        // Fetch Curriculum Meals 
        const templateMealResult = await this.fetchCurriculumMeals({ phase_id: this.item.id, day_number: 1 })
        // Fetch Curriculum Supplements
        const templateSupplementResult = await this.fetchCurriculumSupplements({ phase_id: this.item.id, day_number: 1 })

        // Delete Curriculum Meals
        for (let i = 0; i < this.mealResult.data.length; i++) {
          const item = this.mealResult.data[i]
          await this.deleteCurriculumMeal(item)
        }

        // Delete Curriculum Supplements
        for (let i = 0; i < this.supplementResult.data.length; i++) {
          const item = this.supplementResult.data[i]
          await this.deleteCurriculumSupplement(item)
        }

        // Create Curriculum Meals
        const mealsInput = this.getMealsInput(templateMealResult)

        for (const mealIndex in mealsInput) {
          const mealInput = mealsInput[mealIndex]
          const mealFoodsInput = mealInput.meal_foods
          _.omit(mealInput, 'mealFoods')
          const meal = await this.createCurriculumMeal(mealInput)

          for (const mealFoodIndex in mealFoodsInput) {
            const mealFoodInput = mealFoodsInput[mealFoodIndex]
            await this.createCurriculumMealFood({ ...mealFoodInput, meal_id: meal.id })
          }
        }

        // Create Curriculum Supplements
        for (const supplementIndex in templateSupplementResult.data) {
          const curriculumSupplement = templateSupplementResult.data[supplementIndex]

          await this.createCurriculumSupplement({
            ..._.pick(curriculumSupplement, [
              'taken_at',
              'amount'
            ]),

            supplement_id: curriculumSupplement.supplement.id,
            phase_id: this.item.id,
            day_number: this.dayNumber
          })
        }

      } catch(e) {
        window.console.log(e)
      }

      this.isReseting = false

      // Refresh meals
      this.$refs['meal-list'].refresh()
      this.$refs['supplement-list'].refresh()
    },

    showResetModal() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to reset all meals in this day?', {
        title: 'Please Confirm',
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true
      })
        .then(value => {
          if (value) {
            this.resetTemplateMeals()
          }
        })
    },

    async deleteCurriculumMeal(item) {
      const endpoint = `curriculum-meals/${item.id}`

      return this.$axios
        .delete(endpoint)

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    async deleteCurriculumSupplement(item) {
      const endpoint = `curriculum-supplements/${item.id}`

      return this.$axios
        .delete(endpoint)

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    async createCurriculumMeal(input) {
      const endpoint = `curriculum-meals`

      return this.$axios
        .post(endpoint, input)

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    async createCurriculumMealFood(input) {
      const endpoint = `curriculum-meal-foods`

      return this.$axios
        .post(endpoint, input)

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    async createCurriculumSupplement(input) {
      const endpoint = `curriculum-supplements`

      return this.$axios
        .post(endpoint, input)

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    async fetchCurriculumMeals(params) {
      const endpoint = `curriculum-meals`

      return this.$axios
        .get(endpoint, { params })

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    async fetchCurriculumSupplements(params) {
      const endpoint = `curriculum-supplements`

      return this.$axios
        .get(endpoint, { params })

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    getMealsInput(tempalteMealResult) {
      return _.map(tempalteMealResult.data, i => ({
        phase_id: this.item.id,
        day_number: this.dayNumber,

        ..._.pick(i, [
          'name',
          'type',
          'taken_at',
          'protein',
          'carbs',
          'fat'
        ]),

        meal_foods: _.map(i.meal_foods, i => ({
          food_id: i.food.id,
          amount: i.amount
        }))
      }))
    }
  }
}
</script>