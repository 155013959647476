<template>
  <b-row
    align-v="center"
    align-h="center"
    :style="{
      width: '250px'
    }"
  >
    <b-col>
      <TimeInput
        :value="value"
        disabled
        :style="{
          fontSize: '43px'
        }"
        @input="$emit('input', $event)"
      />
    </b-col>

    <b-col>
      <template v-if="state === 'started' || state === 'paused'">
        <b-button
          v-if="state === 'started'"
          variant="warning"
          class="py-0 font-size-12"
          block
          squared
          size="sm"
          :disabled="disabled"
          @click="pause"
        >
          Pause
        </b-button>

        <b-button
          v-else
          variant="success"
          class="py-0 font-size-12"
          block
          squared
          size="sm"
          :disabled="disabled"
          @click="start"
        >
          Resume
        </b-button>

        <b-button
          variant="danger"
          class="py-0 font-size-12"
          block
          squared
          size="sm"
          :disabled="disabled"
          @click="stop"
        >
          Stop
        </b-button>
      </template>

      <b-button
        v-else-if="value"
        class="font-size-14"
        block
        squared
        :disabled="disabled"
        :style="{
          paddingTop: '13px',
          paddingBottom: '13px'
        }"
        @click="reset"
      >
        Reset
      </b-button>

      <b-button
        v-else
        class="font-size-14"
        variant="success"
        block
        squared
        :disabled="disabled"
        :style="{
          paddingTop: '13px',
          paddingBottom: '13px'
        }"
        @click="start"
      >
        Start
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import TimeInput from '@/components/inputs/time-input'

export default {
  name: 'TimerInput',

  components: {
    TimeInput
  },

  props: {
    value: {
      type: Number,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      interval: null,
      state: null
    }
  },

  watch: {
    interval: {
      immediate: true,
      handler(newValue, oldValue) {
        if (oldValue && oldValue !== newValue) {
          clearInterval(oldValue)
        }
      }
    }
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  },

  methods: {
    start() {
      this.interval = setInterval(this.countup, 1000)
      this.state = 'started'
    },

    reset() {
      this.$emit('input', 0)
      this.interval = setInterval(this.countup, 1000)
      this.state = 'started'
    },

    stop() {
      this.interval = null
      this.state = 'stopped'
    },

    pause() {
      this.interval = null
      this.state = 'paused'
    },

    countup() {
      this.$emit('input', this.value + 1)
    }
  }
}
</script>