import _ from 'lodash'

export default {
  props: {
    result: {
      type: Object,
      default: null
    },

    params: {
      type: Object,
      default: null
    },

    prefetch: {
      type: Boolean,
      default: true
    },

    pagination: {
      type: Boolean,
      default: true
    },

    value: {
      type: [Number, String, Array],
      default: null
    },

    multipleSelect: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isFetching: false,
      isSubmitting: false,

      newResult: {
        data: null,
        total: null
      },

      newParams: {
        filters: {},
        page: 1,
        limit: 10,
        order_by: null,
        order_desc: null
      },

      newValue: null,

      input: null,
      inputChangedAt: null,
      inputSavedAt: null
    }
  },

  computed: {
    initialInput() {
      return this.getInput()
    },

    inputIsDirty() {
      return !_.isEqual(this.input, this.initialInput)
    },

    showPagination() {
      return this.pagination && this.newResult.total > this.newParams.limit
    },

    isFetched() {
      return !!this.newResult.data
    },

    selectedIds() {
      return this.multipleSelect ? (this.newValue || []) : this.newValue ? [this.newValue] : []
    }
  },

  created() {
    this.debounceSubmit = _.debounce(async function(submittedEvent) {
      if (this.inputIsDirty) {
        this.inputSavedAt = +new Date()
        await this.submit(submittedEvent)
      }
    }, _.isNil(this.debounceSubmitTime) ? 2000 : this.debounceSubmitTime)

    this.debounceFetchList = _.debounce(async function() {
      await this.fetchList()
    }, _.isNil(this.debounceFetchListTime) ? 2000 : this.debounceFetchListTime)
  },

  watch: {
    result: {
      immediate: true,
      deep: true,

      handler() {
        if (!_.isEqual(this.result, this.newResult)) {
          this.setNewResult()
        }
      }
    },

    params: {
      immediate: true,
      deep: true,

      handler() {
        if (!_.isEqual(this.params, this.newParams)) {
          this.setNewParams()
        }
      }
    },

    value: {
      immediate: true,
      deep: true,

      handler() {
        if (!_.isEqual(this.value, this.newValue)) {
          this.newValue = _.cloneDeep(this.value)
        }
      }
    },

    newParams: {
      immediate: true,
      deep: true,

      async handler(newValue, oldValue) {
        if (!_.isEqual(this.newParams, this.params)) {
          this.$emit('update:params', _.cloneDeep(this.newParams))
        }

        if (this.prefetch || oldValue) {
          this.refresh()
        }
      }
    },

    newResult: {
      immediate: true,
      deep: true,

      async handler() {
        if (this.newResult && !_.isEqual(this.newResult, this.result)) {
          this.$emit('update:result', _.cloneDeep(this.newResult))
        }

        if (this.newResult) {
          this.setInput()
        }
      }
    },

    newValue: {
      immediate: true,
      deep: true,

      async handler() {
        if (!_.isEqual(this.newValue, this.value)) {
          this.$emit('input', _.cloneDeep(this.newValue))
        }
      }
    },

    input: {
      immediate: true,
      deep: true,

      handler() {
        this.inputChangedAt = +new Date()

        if (this.autosave) {
          this.$nextTick(() => {
            if (this.inputIsDirty) {
              this.debounceSubmit(this.debounceSubmittedEventName)
            }
          })
        }
      }
    }
  },

  validations: {},

  methods: {
    getNewResult() {
      return {
        ...this.newResult,

        ..._.pick(this.result, [
          'data',
          'total'
        ])
      }
    },

    setNewResult() {
      const newResult = this.getNewResult()

      if (!_.isEqual(newResult, this.newResult)) {
        this.newResult = _.cloneDeep(newResult)
      }
    },

    getNewParams() {
      return {
        ...this.newParams,

        ..._.pick(this.params, [
          'filters',
          'page',
          'limit',
          'order_by',
          'order_desc'
        ])
      }
    },

    setNewParams() {
      const newParams = this.getNewParams()

      if (!_.isEqual(newParams, this.newParams)) {
        this.newParams = _.cloneDeep(newParams)
      }
    },

    getInput() {
      return null
    },

    setInput() {
      this.$v.$reset()
      this.input = _.cloneDeep(this.initialInput)
    },

    async submit(submittedEventName) {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      await this.bulkUpdate(submittedEventName)
    },

    async fetchList() {
      const endpoint = this.listEndpoint || this.baseEndpoint
      const limit = this.newParams.limit
      const page = this.newParams.page
      const filters = this.newParams.filters
      const order_by = this.newParams.order_by
      const order_desc = this.newParams.order_desc
      this.isFetching = true

      return this.$axios
        .get(endpoint, {
          parent: this,
          params: { limit, page, order_by, order_desc, ...filters }
        })

        .then(async ({ data }) => {
          this.isFetching = false
          this.newResult.data = data.data
          this.newResult.total = data.total

          if (this.afterFetch) {
            await this.afterFetch()
          }
        })

        .catch((e) => {
          this.isFetching = false
          throw e
        })
    },

    async bulkUpdate(submittedEventName) {
      const endpoint = this.bulkUpdateEndpoint || `${this.baseEndpoint}/bulk`
      this.isSubmitting = true

      return this.$axios
        .patch(endpoint, this.input)

        .then(async ({ data }) => {
          if (this.afterSubmit) {
            await this.afterSubmit(data)
          }

          this.isSubmitting = false

          if (submittedEventName) {
            this.$emit(submittedEventName, data)
          }
        })

        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    refresh() {
      return this.fetchList()
    },

    toogleItem(item) {
      if (this.multipleSelect) {
        const newValue = this.newValue || []

        if (newValue.includes(item.id)) {
          this.newValue = _.filter(newValue, i => i !== item.id)

        } else {
          this.newValue = [...newValue, item.id]
        }

      } else {
        if (this.newValue === item.id) {
          this.newValue = null

        } else {
          this.newValue = item.id
        }
      }
    }
  }
}