<template>
  <span>
    {{ title }}
  </span>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'SupplementDoseTypes',

  props: {
    ids: {
      type: Array,
      required: true
    },

    lower: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    title() {
      return _.chain(this.$store.getters['asset/getSupplementDoseTypesOptions'])
        .filter((i) => this.ids.includes(i.value))
        .map('text')
        .map(i => (this.lower ? i.toLowerCase() : i))
        .join(', ')
        .value()
    }
  }
}
</script>