<template>
  <b-overlay>
    <b-card class="bg-tab">
      <b-tabs
        active-nav-item-class="bg-tab text-primary"
        nav-class="border-0"
        justified
        no-fade
      >
        <b-tab
          v-for="day in days"
          :key="day.number"
          lazy
          title-link-class="rounded-0 border-0 font-size-16"
          :title="day.text"
        >
          <CurriculumPhaseDayMealsDetail
            :item="item"
            :day-number="day.number"
            :curriculum="curriculum"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </b-overlay>
</template>

<script>
import CurriculumPhaseDayMealsDetail from './CurriculumPhaseDayMealsDetail'

export default {
  name: 'CurriculumPhaseMealsDetail',

  components: {
    CurriculumPhaseDayMealsDetail
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    curriculum: {
      type: Object,
      required: true
    }
  },

  computed: {
    days() {
      return [
        { number: 1, text: 'Example 1' },
        { number: 2, text: 'Example 2' },
        { number: 3, text: 'Example 3' },
        { number: 4, text: 'Example 4' },
        { number: 5, text: 'Example 5' },
        { number: 6, text: 'Example 6' },
        { number: 7, text: 'Example 7' }
      ]
    }
  }
}
</script>