<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-alert
      v-if="!curriculumIsActive"
      show
      class="m-0 text-center"
      variant="danger"
    >
      You curriculum was not active on {{ $moment(date).format('LL') }}.
    </b-alert>

    <TrackerNutritionItemUpdate
      v-else-if="item"
      :item.sync="item"
      :water-recommended="stats ? stats.fluids.total : waterRecommended"
      :prefetch="false"
      @submitted="$emit('submitted', $event)"
    />

    <b-alert
      v-else-if="isFetching || isSubmitting"
      show
      class="m-0 text-center"
      variant="light"
    >
      Loading...
    </b-alert>

    <b-alert
      v-else
      show
      class="m-0 text-center"
      variant="success"
    >
      You have not used Nutritional Tracker on {{ $moment(date).format('LL') }}.

      <div>
        <b-button
          variant="link"
          @click="createItem"
        >
          Start Nutritional Tracker
        </b-button>
      </div>
    </b-alert>

    <b-row class="my-3">
      <b-col>
        <div class="px-3">
          <b-button
            variant="success"
            @click="date = prevDate"
          >
            Previous
          </b-button>
        </div>
      </b-col>

      <b-col class="text-right">
        <div class="px-3">
          <b-button
            v-if="$moment() > $moment(nextDate)"
            variant="success"
            @click="date = nextDate"
          >
            Next
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import TrackerNutritionItemUpdate from '@/components/trackers/nutritions/TrackerNutritionItemUpdate'

export default {
  name: 'TrackerNutrition',

  components: {
    TrackerNutritionItemUpdate
  },

  mixins: [
    crudListMixin
  ],

  props: {
    curriculum: {
      type: Object,
      default: null
    },

    waterRecommended: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'tracker-nutritions',
      stats: null
    }
  },

  computed: {
    item: {
      get() {
        return _.get(this.newResult.data, 0)
      },

      set(value) {
        _.set(this.newResult.data, 0, value)
      }
    },

    date: {
      get() {
        return this.newParams.filters.date
      },

      set(value) {
        this.newParams.filters.date = value
      }
    },

    prevDate() {
      return this.$moment(this.date).add(-1, 'day').format('YYYY-MM-DD')
    },

    nextDate() {
      return this.$moment(this.date).add(1, 'day').format('YYYY-MM-DD')
    },

    curriculumIsActive() {
      return _.chain(this.curriculum)
        .get('phases')
        .flatMap('weeks.items')
        .flatten()
        .includes(this.date)
        .value()
    }
  },

  watch: {
    item: {
      immediate: true,
      deep: true,

      handler() {
        if (this.curriculumIsActive) {
          this.fetchStats()
        }
      }
    }
  },

  methods: {
    createItem() {
      const endpoint = this.createEndpoint || this.baseEndpoint

      const input = {
        date: this.newParams.filters.date,
        user_id: this.newParams.filters.user_id
      }

      this.isSubmitting = true

      return this.$axios
        .post(endpoint, input)

        .then(async ({ data }) => {
          this.refresh()
          this.isSubmitting = false
          return data
        })

        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    fetchStats() {
      const endpoint = `curriculums/${this.curriculum.id}/stats`
      this.statsSsFetching = true

      return this.$axios
        .get(endpoint, {
          parent: this,
          params: {
            date: this.date
          }
        })
        .then(({ data }) => {
          this.statsSsFetching = false
          this.stats = data
        })
        .catch((e) => {
          this.statsSsFetching = false
          throw e
        })
    },
  }
}
</script>