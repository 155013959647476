import Vue from 'vue'
import { AclInstaller, AclCreate, AclRule } from 'vue-acl'
import router from '@/router/index'

Vue.use(AclInstaller)

export default new AclCreate({
    initial: 'public',
    notfound: {
        path: '/pages/404',
        forwardQueryParams: false,
    },
    router,
    acceptLocalRules: true,
    globalRules: {
        // role_0 - user without role
        // role_1 - Client
        // role_2 - Trainer
        // role_3 - Onwer
        // role_4 - Manager
        // role_5 - Sales Agent
        // role_6 - Admin
        isUnknown: new AclRule('role_0').generate(),
        isClient: new AclRule('role_1').generate(),
        isPaidClient: new AclRule('role_1').and('paid').generate(),
        isTrainer: new AclRule('role_2').generate(),
        isOwner: new AclRule('role_3').generate(),
        isManager: new AclRule('role_4').generate(),
        isSales: new AclRule('role_5').generate(),
        isLogged: new AclRule('role_0').or('role_1').or('role_2').or('role_3').or('role_4').or('role_5').or('admin').generate(),
        isAdmin: new AclRule('admin').generate(),
        isPublic: new AclRule('public').generate(),
        isStaff: new AclRule('role_2').or('role_3').or('role_4').or('role_5').generate(),
        // app
        couldUseApp: new AclRule('admin').or('role_2').or('role_3').or('role_4').or('role_5').or('role_1').and('paid').generate(),
        // onboard
        couldOnboard: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAnyCurriculumEssentials: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        // exercise
        couldAnyExercise: new AclRule('role_1').or('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldListExercise: new AclRule('role_1').or('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAddExercise: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldCopyExercise: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditPublicExercise: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditOwnExercise: new AclRule('role_2').generate(),
        couldDeletePublicExercise: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldDeleteOwnExercise: new AclRule('role_2').generate(),
        // workout
        couldAnyWorkout: new AclRule('role_1').or('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldListWorkout: new AclRule('role_1').or('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAddWorkout: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditWorkout: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditPublicWorkout: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditOwnWorkout: new AclRule('role_2').generate(),
        couldDeleteWorkout: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldCopyWorkout: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldFilterWorkoutsByPrivate: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        // forecast
        couldAnyForecast: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldListForecast: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAddForecast: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditForecast: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditPublicForecast: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditOwnForecast: new AclRule('role_2').generate(),
        couldDeletePublicForecast: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldDeleteOwnForecast: new AclRule('role_2').generate(),
        // program
        couldAnyProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldListProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAddProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        // supplement
        couldAnySupplement: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldListSupplement: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAddSupplement: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditSupplement: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditPublicSupplement: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditOwnSupplement: new AclRule('role_2').generate(),
        couldDeletePublicSupplement: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldDeleteOwnSupplement: new AclRule('role_2').generate(),
        // supplement program
        couldAnySupplementProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldListSupplementProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAddSupplementProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditSupplementProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditPublicSupplementProgram: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditOwnSupplementProgram: new AclRule('role_2').generate(),
        couldDeletePublicSupplementProgram: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldDeleteOwnSupplementProgram: new AclRule('role_2').generate(),
        // workout program
        couldAnyWorkoutProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldListWorkoutProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAddWorkoutProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditWorkoutProgram: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditPublicWorkoutProgram: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditOwnWorkoutProgram: new AclRule('role_2').generate(),
        couldDeletePublicWorkoutProgram: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldDeleteOwnWorkoutProgram: new AclRule('role_2').generate(),
        // user
        couldAddUser: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditUser: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditUserPassword: new AclRule('admin').generate(),
        // client
        couldAnyClient: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldListClient: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldAddClient: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditClient: new AclRule('role_2').or('role_3').or('role_4').or('admin').generate(),
        couldEditClientsPrimaryTrainer: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditClientsSecondaryTrainer: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditClientsPrimaryTrainerOnCreate: new AclRule('role_3').or('role_2').or('role_4').or('admin').generate(),
        couldEditClientsPrimaryTrainerIfIsPrimaryTrainer: new AclRule('role_2').generate(),
        couldEditClientsSecondaryTrainerOnCreate: new AclRule('role_3').or('role_2').or('role_4').or('admin').generate(),
        couldEditClientsSecondaryTrainerIfIsPrimaryTrainer: new AclRule('role_2').generate(),
        // trainer
        couldAddTrainer: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditTrainer: new AclRule('role_3').or('role_4').or('admin').generate(),
        // manager
        couldAddManager: new AclRule('role_3').or('admin').generate(),
        couldEditManager: new AclRule('role_3').or('admin').generate(),
        // sales agent
        couldAddSalesAgent: new AclRule('role_3').or('admin').generate(),
        couldEditSalesAgent: new AclRule('role_3').or('admin').generate(),
        // owner
        couldAddOwner: new AclRule('admin').generate(),
        couldEditOwner: new AclRule('admin').generate(),
        // gym
        couldListGym: new AclRule('role_2').or('role_3').or('role_4').or('role_5').or('admin').generate(),
        couldViewGym: new AclRule('role_2').or('role_3').or('role_4').or('role_5').or('admin').generate(),
        couldAddGym: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditGym: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldActionGym: new AclRule('role_3').or('role_4').or('admin').generate(),
        // staff
        couldListStaff: new AclRule('role_2').or('role_3').or('role_4').or('role_5').or('admin').generate(),
        couldAddStaff: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldEditStaff: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldActionClient: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldActionClientIsTrainer: new AclRule('role_2').or('admin').generate(),
        couldActionTrainer: new AclRule('role_3').or('role_4').or('admin').generate(),
        couldActionManager: new AclRule('role_3').or('admin').generate(),
        couldActionSales: new AclRule('role_3').or('admin').generate(),
        // company
        couldAnyCompany: new AclRule('admin').or('role_3').generate(),
        // curriculum
        couldBuildCurriculum: new AclRule('admin').or('role_2').or('role_3').or('role_4').generate()
    },
    // eslint-disable-next-line no-unused-vars
    middleware: async acl => {
        //await timeout(2000) // call your api
        //acl.change('public')
    }
})
