var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_vm.$acl.check('isClient') ? [!_vm.currentUser.curriculum ? _c('b-alert', {
    staticClass: "my-3",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You have no current curriculum ")]) : !_vm.curriculumIsActive ? _c('b-alert', {
    staticClass: "my-3",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Your curriculum is " + _vm._s(_vm.currentUser.curriculum.status) + " ")]) : !_vm.curriculumIsStarted ? _c('b-alert', {
    staticClass: "my-3",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Your curriculum is not started yet ")]) : _c('CurriculumItemDashboard', {
    attrs: {
      "item": {
        id: _vm.currentUser.curriculum.id
      }
    }
  })] : _vm.$acl.check('isTrainer') ? _c('b-alert', {
    staticClass: "my-3",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" Trainers's Dashboard ")]) : _c('b-alert', {
    staticClass: "my-3",
    attrs: {
      "show": "",
      "variant": "info"
    }
  }, [_vm._v(" User's Dashboard ")])], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }