<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Food"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.food_id)"
        :state="getFieldState($v.input.food_id)"
      >
        <FoodSelect
          v-model="input.food_id"
          :item="newItem.food"
          placeholder="Select a food or add new..."
          :state="getFieldState($v.input.food_id)"
        />
      </b-form-group>

      <b-form-group
        label="Amount"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.amount)"
        :state="getFieldState($v.input.amount)"
      >
        <b-form-input
          v-model="input.amount"
          placeholder="Amount"
          type="number"
          number
          step="0.01"
          :state="getFieldState($v.input.amount)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import FoodSelect from '@/components/nutritions/FoodSelect'

export default {
  name: 'TrackerMealFoodItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    FoodSelect
  },

  data() {
    return {
      baseEndpoint: 'tracker-meal-foods'
    }
  },

  validations() {
    return {
      input: {
        food_id: { required },
        amount: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        food_id: _.get(this.newItem, 'food.id', null),
        amount: _.get(this.newItem, 'amount', null)
      }

      if (!this.isCreated) {
        input.meal_id = _.get(this.newItem, 'meal_id', null)
      }

      return input
    }
  }
}
</script>
