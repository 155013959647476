<template>
  <b-overlay :show="isFetching">
    <b-card
      class="bg-tab"
    >
      <template v-if="currentPhaseMeals">
        <template v-if="showCopyButton">
          <b-alert
            show
            class="text-center"
            variant="warning"
          >
            <div class="mb-2">
              There are no meals in Phase {{ item.number }}.
            </div>

            <b-button
              v-if="prevPhaseHasMeals"
              variant="primary"
              :disabled="isCopying"
              @click="copyCurriculumMeals()"
            >
              <b-spinner v-if="isCopying" small />Copy Previous Phase
            </b-button>

            <b-button
              v-else
              variant="primary"
              :disabled="isCopying"
              @click="copyCurriculumMeals('typical')"
            >
              <b-spinner v-if="isCopying" small />Copy Client Meals
            </b-button>
          </b-alert>
        </template>

        <b-tabs
          v-else
          active-nav-item-class="bg-tab text-primary"
          nav-class="border-0"
          justified
          no-fade
        >
          <b-tab
            v-for="day in days"
            :key="day.number"
            lazy
            title-link-class="rounded-0 border-0 font-size-16"
            :title="day.text"
          >
            <CurriculumPhaseDayMeals
              :item="item"
              :day-number="day.number"
              :curriculum="curriculum"
              @update:result-meals="$event.data && !$event.data.length && currentPhaseHasMeals ? fetchCurrentPhaseMeals() : null"
            />
          </b-tab>
        </b-tabs>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import CurriculumPhaseDayMeals from './CurriculumPhaseDayMeals'

export default {
  name: 'CurriculumPhaseMeals',

  components: {
    CurriculumPhaseDayMeals
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    curriculum: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isFetching: false,
      isCopying: false,
      typicalMeals: null,
      currentPhaseMeals: null,
      prevPhaseMeals: null
    }
  },

  computed: {
    days() {
      return [
        { number: 1, text: 'Example 1 (Template)' },
        { number: 2, text: 'Example 2' },
        { number: 3, text: 'Example 3' },
        { number: 4, text: 'Example 4' },
        { number: 5, text: 'Example 5' },
        { number: 6, text: 'Example 6' },
        { number: 7, text: 'Example 7' }
      ]
    },

    prevPhase() {
      return _.find(this.curriculum.phases, i => i.number === this.item.number - 1)
    },

    currentPhaseHasMeals() {
      return !!_.get(this.currentPhaseMeals, 'total')
    },

    prevPhaseHasMeals() {
      return this.prevPhase && !!_.get(this.prevPhaseMeals, 'total')
    },

    clientHasTypicalMeals() {
      return !!_.get(this.typicalMeals, 'data.0')
    },

    showCopyButton() {
      return !this.currentPhaseHasMeals
    }
  },

  async mounted() {
    this.isFetching = true
    await this.fetchTypicalMeals()

    if (this.prevPhase) {
      await this.fetchPrevPhaseMeals()
    }

    await this.fetchCurrentPhaseMeals()

    this.isFetching = false
  },

  methods: {
    async fetchMeals(filters) {
      const endpoint = 'curriculum-meals'

      return this.$axios
        .get(endpoint, {
          parent: this,
          params: { ...filters }
        })

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    async fetchCurrentPhaseMeals() {
      this.currentPhaseMeals = await this.fetchMeals({ phase_id: this.item.id, limit: 200 })
    },

    async fetchPrevPhaseMeals() {
      this.prevPhaseMeals = await this.fetchMeals({ phase_id: this.prevPhase.id, limit: 200 })
    },

    async fetchTypicalMeals() {
      const endpoint = `users/${this.curriculum.client.id}/typical_meals`

      return this.$axios
        .get(endpoint)

        .then(({ data }) => {
          return this.typicalMeals = data
        })

        .catch((e) => {
          throw e
        })
    },

    async copyCurriculumMeals(mode) {
      this.isCopying = true

      try {
        const mealsInput = mode === 'typical' ? this.getMealsInputFromTypicalMeals() : this.getMealsInputFromPrevPhase()

        for (const mealIndex in mealsInput) {
          const mealInput = mealsInput[mealIndex]
          const mealFoodsInput = mealInput.meal_foods
          _.omit(mealInput, 'mealFoods')
          const meal = await this.createCurriculumMeal(mealInput)

          for (const mealFoodIndex in mealFoodsInput) {
            const mealFoodInput = mealFoodsInput[mealFoodIndex]
            await this.createCurriculumMealFood({ ...mealFoodInput, meal_id: meal.id })
          }
        }

      } catch(e) {
        window.console.log(e)
      }

      this.isCopying = false

      this.isFetching = true
      await this.fetchCurrentPhaseMeals()
      this.isFetching = false
    },

    async createCurriculumMeal(input) {
      const endpoint = `curriculum-meals`

      return this.$axios
        .post(endpoint, input)

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    async createCurriculumMealFood(input) {
      const endpoint = `curriculum-meal-foods`

      return this.$axios
        .post(endpoint, input)

        .then(({ data }) => {
          return data
        })

        .catch((e) => {
          throw e
        })
    },

    getMealsInputFromTypicalMeals() {
      const mealsInput = []
      const mealCount = Math.max(this.typicalMeals.data.length, 6)

      for (const dayIndex in this.days) {
        const day = this.days[dayIndex]
        let prevMealInput

        for (let mealIndex = 0; mealIndex < mealCount; mealIndex++) {
          const meal = this.typicalMeals.data[mealIndex] || {
            name: `Meal ${mealIndex + 1}`,
            type: 'main',
            taken_at: this.$moment(_.get(prevMealInput, 'taken_at', '8:00:00'), 'HH:mm:ss').add(1, 'hour').format('HH:mm:ss'),
          }

          const mealInput = {
            name: meal.name,
            type: meal.type,
            taken_at: meal.taken_at,
            phase_id: this.item.id,
            day_number: day.number,
            protein: null,
            carbs: null,
            fat: null
          }

          mealInput.meal_foods = _.map(meal.foods, i => ({
            food_id: i.food_id,
            amount: i.taken_amount
          }))

          mealsInput.push(mealInput)
          prevMealInput = mealInput
        } 
      }

      return mealsInput
    },

    getMealsInputFromPrevPhase() {
      return _.map(this.prevPhaseMeals.data, i => ({
        phase_id: this.item.id,

        ..._.pick(i, [
          'name',
          'type',
          'taken_at',
          'day_number',
          'protein',
          'carbs',
          'fat'
        ]),

        meal_foods: _.map(i.meal_foods, i => ({
          food_id: i.food.id,
          amount: i.amount
        }))
      }))
    }
  }
}
</script>