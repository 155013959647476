<template>
  <b-overlay
    :show="isFetching"
  >
    <TrackerWorkoutItemUpsert
      v-if="item"
      :key="item.id"
      class="p-3"
      :item="{ id: item.id}"
      @completed="$emit('submitted', $event); refresh()"
      @submitted="$emit('submitted', $event);"
    />

    <b-alert
      v-else-if="isFetching"
      show
      class="m-0 text-center"
      variant="light"
    >
      Loading...
    </b-alert>

    <b-alert
      v-else
      show
      class="m-0 text-center"
      variant="success"
    >
      You have no workouts to work.
    </b-alert>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import TrackerWorkoutItemUpsert from '@/components/trackers/workouts/TrackerWorkoutItemUpsert'

export default {
  name: 'CurriculumWorkoutTracker',

  mixins: [
    crudListMixin
  ],

  components: {
    TrackerWorkoutItemUpsert
  },

  props: {
    params: {
      type: Object,
      default: () => ({
        filters: {
          is_performed: false
        },

        order_by: 'start_time',
        order_desc: false
      })
    }
  },

  data() {
    return {
      baseEndpoint: 'tracker-workouts'
    }
  },

  computed: {
    item() {
      return _.get(this.newResult.data, 0)
    }
  },

  watch: {
    item: {
      immediate: true,
      deep: true,

      handler() {
        this.$emit('update:item', this.item)
      }
    }
  }
}
</script>