<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(start_time)="row">
        {{ row.item.start_time ? $moment(row.item.start_time).format('YYYY-MM-DD') : '-' }}
      </template>

      <template #cell(log_time)="row">
        {{ row.item.log_time ? $moment(row.item.log_time).format('YYYY-MM-DD') : '-' }}
      </template>

      <template #cell(total_time)="row">
        <TimeInput
          :value="row.item.total_time"
          disabled
        />
      </template>

      <template #cell(actions)="row">
        <TrackerWorkoutItemUpsertButton
          :item="{ id: row.item.id }"
          tag="b-button"
          :tag-props="{
            variant: 'link',
            size: 'sm'
          }"
          @submitted="refresh"
        >
          <b-icon-pencil />
        </TrackerWorkoutItemUpsertButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import TrackerWorkoutItemUpsertButton from './buttons/TrackerWorkoutItemUpsertButton'
import TimeInput from '@/components/inputs/time-input'

export default {
  name: 'TrackerWorkoutList',

  mixins: [
    crudListMixin
  ],

  components: {
    TrackerWorkoutItemUpsertButton,
    TimeInput
  },

  data() {
    return {
      baseEndpoint: 'tracker-workouts',

      fields: [
        { key: 'start_time', sortable: false, label: 'Date' },
        { key: 'log_time', sortable: false, label: 'Performed' },
        { key: 'total_time', sortable: false, label: 'Total time (mins)' },
        { key: 'workout.name', sortable: false, label: 'Workout' },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  }
}
</script>