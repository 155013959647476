var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [!_vm.curriculum ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You have no active curriculum ")]) : _vm.curriculum.status === 'paused' ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Your curriculum is paused ")]) : _c('div', [_vm._v(" Workouts Tracker ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }