var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "items": _vm.newResult.data,
      "fields": _vm.fields,
      "stacked": "sm",
      "sort-by": _vm.newParams.order_by,
      "sort-desc": _vm.newParams.order_desc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(row) {
        return [_c('b-avatar', {
          staticClass: "mr-2",
          attrs: {
            "src": row.item.profile_img_url
          }
        }), _vm._v(" " + _vm._s(row.item.first_name) + " " + _vm._s(row.item.last_name) + " ")];
      }
    }, {
      key: "cell(roles)",
      fn: function fn(row) {
        return _vm._l(row.item.role_ids, function (roleId, roleIndex) {
          return _c('b-badge', {
            key: roleIndex,
            staticClass: "mr-1",
            attrs: {
              "variant": "info"
            }
          }, [_c('Roles', {
            attrs: {
              "ids": [roleId]
            }
          })], 1);
        });
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_vm.getCouldActionItem(row.item) ? _c('UserItemActionButtons', {
          attrs: {
            "item": row.item
          },
          on: {
            "delete:item": _vm.refresh,
            "submitted": _vm.refresh
          }
        }, [_c('b-icon-three-dots')], 1) : _vm._e()];
      }
    }])
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }