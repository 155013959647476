var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('div', {
      key: item.id
    }, [_c('div', {
      staticClass: "bg-secondary text-light py-1 px-0"
    }, [_c('b-row', {
      attrs: {
        "align-v": "center",
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('TrackerExerciseGroupItemUpsertButton', {
      staticClass: "font-weight-bold text-white",
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": _vm.refresh
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")])], 1), _c('b-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "6"
      }
    }, [_c('TrackerExerciseItemUpsertButton', {
      staticClass: "mr-1 text-white",
      attrs: {
        "item": {
          tracker_exercise_group_id: item.id
        },
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": _vm.refresh
      }
    }, [_c('b-icon-plus-circle', {
      attrs: {
        "scale": "0.8"
      }
    })], 1), _c('TrackerExerciseGroupItemDeleteButton', {
      staticClass: "text-white",
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "delete:item": _vm.refresh
      }
    }, [_c('b-icon-trash', {
      attrs: {
        "scale": "0.8"
      }
    })], 1)], 1)], 1)], 1), _c('TrackerExerciseList', {
      attrs: {
        "params": {
          filters: {
            tracker_exercise_group_id: item.id
          }
        },
        "result": {
          data: item.tracker_exercises
        },
        "prefetch": false
      },
      on: {
        "update:result": function updateResult($event) {
          return _vm.onUpdateExercises(itemIndex, $event);
        }
      }
    })], 1);
  }), _c('div', {
    staticClass: "text-center m-2"
  }, [_c('TrackerExerciseGroupItemUpsertButton', {
    attrs: {
      "item": {
        tracker_workout_id: _vm.newParams.filters.tracker_workout_id
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      }
    },
    on: {
      "submitted": _vm.refresh
    }
  }, [_c('b-icon-plus-circle', {
    staticClass: "mr-1",
    attrs: {
      "scale": "0.8"
    }
  }), _vm._v(" Add exercise group ")], 1)], 1), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }