<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <b-dropdown-item
      :to="`/onboarding/${item.id}`"
    >
      Onboarding
    </b-dropdown-item>

    <b-dropdown-item
      :to="`/client/${item.id}/physical-conditions`"
    >
      Measurements
    </b-dropdown-item>

    <b-dropdown-item
      :to="`/client/${item.id}/photos`"
    >
      Photos
    </b-dropdown-item>
    
    <ClientItemTrainersUpdateButton
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @submitted="$emit('submitted')"
    >
      Trainers
    </ClientItemTrainersUpdateButton>

    <UserItemPasswordUpdateButton
      v-if="$acl.check('couldEditUserPassword')"
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @submitted="$emit('submitted')"
    >
      Reset Password
    </UserItemPasswordUpdateButton>

    <b-dropdown-divider />

    <b-dropdown-group>
      <template v-if="item.curriculum">
        <b-dropdown-item
          :to="`/curriculums/${item.curriculum.id}`"
        >
          {{ item.curriculum.status === 'designing' ? 'Build Curriculum' : 'Curriculum Assessment' }}
        </b-dropdown-item>

        <b-dropdown-item
          v-if="item.curriculum.status !== 'designing'"
          :to="`/curriculums/${item.curriculum.id}/logs`"
        >
          Curriculum Logs
        </b-dropdown-item>

        <CurriculumItemCompleteButton
          v-if="curriculumIsCompletable && !item.curriculum.parent_id && $acl.check('couldBuildCurriculum')"
          :item="item.curriculum"
          tag="b-dropdown-item-button"
          :tag-props="{
            buttonClass: 'font-size-14'
          }"
          @completed:item="$emit('submitted')"
        >
          Complete Curriculum
        </CurriculumItemCompleteButton>

        <CurriculumItemCancelButton
          v-if="curriculumIsCancelable && !item.curriculum.parent_id && $acl.check('couldBuildCurriculum')"
          :item="item.curriculum"
          tag="b-dropdown-item-button"
          :tag-props="{
            buttonClass: 'font-size-14'
          }"
          @canceled:item="$emit('submitted')"
        >
          Cancel Curriculum
        </CurriculumItemCancelButton>

        <CurriculumItemRestartButton
          v-if="curriculumIsRestartable && $acl.check('couldBuildCurriculum')"
          :item="item.curriculum"
          tag="b-dropdown-item-button"
          :tag-props="{
            buttonClass: 'font-size-14'
          }"
          @submitted="$emit('submitted')"
        >
          Restart Curriculum
        </CurriculumItemRestartButton>

        <CurriculumItemPauseButton
          v-if="curriculumIsPausable && $acl.check('couldBuildCurriculum')"
          v-show="false"
          :item="item.curriculum"
          tag="b-dropdown-item-button"
          :tag-props="{
            buttonClass: 'font-size-14'
          }"
          @submitted="$emit('submitted')"
        >
          Pause Curriculum
        </CurriculumItemPauseButton>

        <CurriculumItemDeleteButton
          v-if="curriculumIsDeletable && $acl.check('couldBuildCurriculum')"
          :item="item.curriculum"
          tag="b-dropdown-item-button"
          :tag-props="{
            buttonClass: 'font-size-14'
          }"
          @deleted:item="$emit('submitted')"
        >
          Delete Curriculum
        </CurriculumItemDeleteButton>
      </template>

      <b-dropdown-item
        :to="`/client/${item.id}/curriculums`"
      >
        Curriculum Histories
      </b-dropdown-item>
    </b-dropdown-group>
  </b-dropdown>
</template>

<script>
import _ from 'lodash'
import ClientItemTrainersUpdateButton from './ClientItemTrainersUpdateButton'
import CurriculumItemDeleteButton from '@/components/curriculums/buttons/CurriculumItemDeleteButton'
import CurriculumItemCancelButton from '@/components/curriculums/buttons/CurriculumItemCancelButton'
import CurriculumItemPauseButton from '@/components/curriculums/buttons/CurriculumItemPauseButton'
import CurriculumItemRestartButton from '@/components/curriculums/buttons/CurriculumItemRestartButton'
import CurriculumItemCompleteButton from '@/components/curriculums/buttons/CurriculumItemCompleteButton'
import UserItemPasswordUpdateButton from '@/components/users/buttons/UserItemPasswordUpdateButton'

export default {
  name: 'ClientItemActionsButton',

  components: {
    ClientItemTrainersUpdateButton,
    CurriculumItemDeleteButton,
    CurriculumItemCancelButton,
    CurriculumItemPauseButton,
    CurriculumItemRestartButton,
    CurriculumItemCompleteButton,
    UserItemPasswordUpdateButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  },

  computed: {
    curriculumRestartDate() {
      return _.chain(this.item.curriculum)
        .get('pauses.items')
        .find(i => this.$moment() < this.$moment(i.to).add(1, 'day'))
        .get('to')
        .value()
    },

    curriculumIsEditable() {
      return !['completed', 'canceled'].includes(this.item.curriculum?.status)
    },

    curriculumIsCancelable() {
      return !['completed', 'canceled', 'designing'].includes(this.item.curriculum?.status)
    },

    curriculumIsCompletable() {
      return !['completed', 'canceled', 'designing'].includes(this.item.curriculum?.status)
    },

    curriculumIsDeletable() {
      return ['designing'].includes(this.item.curriculum?.status)
    },

    curriculumIsRestartable() {
      return ['paused'].includes(this.item.curriculum?.status)
    },

    curriculumIsPausable() {
      return ['active'].includes(this.item.curriculum?.status) && !this.curriculumRestartDate
    }
  }
}
</script>