<template>
  <b-overlay
    :show="isFetching"
  >
    <b-alert
      v-if="(!newItem.cardio_sessions_per_week || !newItem.workouts_per_week) && !isFetching"
      show
      variant="warning"
    >
      <div class="mb-2">
        You have not set your workout and cardio preferences in Histroy tab.
      </div>

      <div>
        <b-button
          variant="primary"
          to="#step2"
        >
          Go to History
        </b-button>
      </div>
    </b-alert>

    <b-form
      v-else
      @submit.prevent="submit('submitted')"
    >      
      <div class="mb-4">
        <b-button
          class="mx-1 workout-event"
          squared
          @click="addEvent({ type: 'workout', duration: 1 })"
        >
          Add Workout
        </b-button>

        <b-button
          class="mx-1 cardio-event"
          squared
          @click="addEvent({ type: 'cardio', duration: 1 })"
        >
          Add Cardio
        </b-button>

        <b-button
          class="mx-1 work-event"
          squared
          @click="addEvent({ type: 'work', duration: 2 })"
        >
          Add Work
        </b-button>

        <b-button
          class="mx-1 sleep-event"
          squared
          @click="addEvent({ type: 'sleep', duration: 2 })"
        >
          Add Sleep
        </b-button>
      </div>

      <b-alert
        show
      >
        Please add at least {{ newItem.workouts_per_week }} workouts and {{ newItem.cardio_sessions_per_week }} cardio sessions. You set these preferences in History tab.
      </b-alert>

      <b-form-group>
        <ScheduleInput
          :events.sync="input.schedule"
          :events-selectable="false"
        >
          <template v-slot:event-actions="{ deleteEvent }">
            <b-button
              variant="link"
              class="py-1 px-0 text-white"
              size="sm"
              :style="{
                fontSize: '8px'
              }"
            >
              <b-icon
                class="no-drag"
                icon="trash-fill"
                @click="deleteEvent"
              />
            </b-button>
          </template>
        </ScheduleInput>
      </b-form-group>

      <b-alert
        :show="!$v.input.schedule.minCardiosLength || !$v.input.schedule.minWorkoutsLength"
        variant="danger"
      >
        <template v-if="!$v.input.schedule.minCardiosLength && !$v.input.schedule.minWorkoutsLength">
          You do not have anough workouts ({{ workoutEventCount }}/{{ newItem.workouts_per_week }}) and cardio ({{ cardioEventCount }}/{{ newItem.cardio_sessions_per_week }}) sessions to match you preferences.
        </template>

        <template v-else-if="!$v.input.schedule.minWorkoutsLength">
          You do not have anough workouts ({{ workoutEventCount }}/{{ newItem.workouts_per_week }}) sessions to match you preferences.
        </template>

        <template v-else-if="!$v.input.schedule.minCardiosLength">
          You do not have anough cardio ({{ cardioEventCount }}/{{ newItem.cardio_sessions_per_week }}) sessions to match you preferences.
        </template>
      </b-alert>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small /> Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixins from '@/mixins/crud-item'
import ScheduleInput from '@/components/schedule/ScheduleInput'
import { required } from "vuelidate/lib/validators"

const minEventsLength = (type, count) => (value) => {
  return _.filter(value, { type }).length >= count
}

export default {
  name: 'ClientScheduleUpdate',

  mixins: [
    crudItemMixins
  ],

  components: {
    ScheduleInput
  },

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  computed: {
    cardioEventCount() {
      return _.filter(this.input.schedule, { type: 'cardio' }).length
    },

    workoutEventCount() {
      return _.filter(this.input.schedule, { type: 'workout' }).length
    }
  },

  watch: {
    inputIsDirty: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$store.dispatch('layout/setDirtyComponent', this._uid)
        } else {
          this.$store.dispatch('layout/removeDirtyComponent', this._uid)
        }
      }
    }
  },

  validations() {
    return {
      input: {
        schedule: {
          required,
          minWorkoutsLength: minEventsLength('workout', this.newItem.workouts_per_week),
          minCardiosLength: minEventsLength('cardio', this.newItem.cardio_sessions_per_week)
        }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        schedule: this.newItem.schedule
      }

      return input
    },

    addEvent(event) {
      const duration = event.duration || 1
      const sortedSchedule = _.sortBy(this.input.schedule, 'start')

      if (!sortedSchedule || !sortedSchedule.length) {
        this.input.schedule = this.input.schedule || []
        this.input.schedule.push({ ...event, start: 0, duration })

      } else if (sortedSchedule[0].start >= duration) {
        this.input.schedule.push({ ...event, start: 0, duration })

      } else {
        _.each(sortedSchedule, (e, i) => {
          const nextEvent = sortedSchedule[i + 1]
          const nextEventStart = nextEvent ? nextEvent.start : 9999999
          if (e.start + e.duration + duration <= nextEventStart) {
            this.input.schedule.push({ ...event, start: e.start + e.duration, duration })
            return false
          }
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.workout-event {
  background-color: #02a499 !important;
}
.cardio-event {
  background-color: #626ed4 !important;
}
.work-event {
  background-color: #6c757d !important;
}
.sleep-event {
  background-color: #333547 !important;
}
</style>
