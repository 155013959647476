var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.supplements.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card supplements-table-card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row mb-md-2"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6"
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.supplementTypesOptionsWithEmptyValue
    },
    model: {
      value: _vm.supplements.filter.type,
      callback: function callback($$v) {
        _vm.$set(_vm.supplements.filter, "type", $$v);
      },
      expression: "supplements.filter.type"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-12 col-md-6"
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.supplementFormTypesOptionsWithEmptyValue
    },
    model: {
      value: _vm.supplements.filter.form,
      callback: function callback($$v) {
        _vm.$set(_vm.supplements.filter, "form", $$v);
      },
      expression: "supplements.filter.form"
    }
  })], 1)]), _c('div', {
    staticClass: "table-responsive mb-0"
  }, [_c('b-table-simple', {
    staticClass: "supplements-table",
    attrs: {
      "busy": _vm.supplements.isBusy
    }
  }, [_c('b-thead', [_c('div', [_c('b-tr', [_c('b-th', [_vm._v("Name")]), _c('b-th', [_vm._v("Type")]), _c('b-th', [_vm._v("Dose")]), _c('b-th', [_vm._v("Form")])], 1)], 1)]), _c('b-tbody', [_c('Draggable', {
    attrs: {
      "move": _vm.onMove,
      "disabled": _vm.editEntityMode,
      "group": {
        name: 'phase.supplements',
        pull: 'clone',
        put: false
      },
      "list": _vm.supplements.items
    }
  }, _vm._l(this.supplements.items, function (supplement) {
    return _c('b-tr', {
      key: supplement.id,
      attrs: {
        "data-id": supplement.id
      }
    }, [_c('b-td', [_vm._v(_vm._s(supplement.name))]), _c('b-td', [_vm._v(_vm._s(supplement.type_name))]), _c('b-td', [_vm._v(" " + _vm._s("".concat(supplement.dose, " ").concat(_vm.supplementDoseTypeName(supplement.dose_type))) + " ")]), _c('b-td', [_vm._v(_vm._s(_vm.supplementFormTypeName(supplement.form)))])], 1);
  }), 1)], 1)], 1)], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6"
  }), _c('div', {
    staticClass: "col-sm-12 col-md-6"
  }, [_c('div', {
    staticClass: "dataTables_paginate paging_simple_numbers float-right"
  }, [_c('ul', {
    staticClass: "pagination pagination-rounded mb-0 mt-4"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.supplements.totalRows,
      "per-page": _vm.supplements.perPage
    },
    model: {
      value: _vm.supplements.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.supplements, "currentPage", $$v);
      },
      expression: "supplements.currentPage"
    }
  })], 1)])])])])])])], 1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-collapse', {
    attrs: {
      "visible": !_vm.editEntityMode
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('h4', {
    staticClass: "mb-3 card-title"
  }, [_vm._v(" Supplement Program - " + _vm._s(_vm.form.name) + " ")])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "edit"
    },
    on: {
      "click": _vm.onEditSupplementProgramEntity
    }
  }, [_vm._v(" Edit ")])])])], 1), _c('dl', {
    staticClass: "row mt-3 mb-0"
  }, [_c('dt', {
    staticClass: "col-sm-5"
  }, [_vm._v("Gender")]), _c('dd', {
    staticClass: "col-sm-7"
  }, [_vm._v(" " + _vm._s(_vm.form.gender) + " ")]), _c('dt', {
    staticClass: "col-sm-5"
  }, [_vm._v("Goal Direction")]), _c('dd', {
    staticClass: "col-sm-7"
  }, [_c('GoalDirections', {
    attrs: {
      "ids": [_vm.form.goal_direction_id]
    }
  })], 1), _c('dt', {
    staticClass: "col-sm-5"
  }, [_vm._v("Fitness Level")]), _c('dd', {
    staticClass: "col-sm-7"
  }, [_c('Abilities', {
    attrs: {
      "ids": _vm.form.fitness_level_ids
    }
  })], 1), _c('dt', {
    staticClass: "col-sm-5"
  }, [_vm._v("Description")]), _c('dd', {
    staticClass: "col-sm-7"
  }, [_vm._v(" " + _vm._s(_vm.form.description) + " ")])])], 1)])]), _c('b-collapse', {
    attrs: {
      "visible": _vm.editEntityMode
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.formOverlay,
      "variant": "white",
      "opacity": "0.5",
      "blur": "2px",
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.onFormSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.name,
      expression: "form.name"
    }],
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.form.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.form, "name", $event.target.value);
      }
    }
  }), _vm.formSubmitted && _vm.$v.form.name.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.name.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.form.name.minLength ? _c('span', [_vm._v(" This value is 5 letters minimum length. ")]) : _vm._e(), !_vm.$v.form.name.maxLength ? _c('span', [_vm._v(" This value is 200 letters maximum length. ")]) : _vm._e()]) : _vm._e()]), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Gender",
      "label-for": "gender"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "pt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.gender.$error
    },
    attrs: {
      "id": "gender",
      "options": _vm.getSexOptions()
    },
    model: {
      value: _vm.form.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "gender", $$v);
      },
      expression: "form.gender"
    }
  }), _vm.formSubmitted && _vm.$v.form.gender.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.gender.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Goal Direction",
      "label-for": "goal_direction_id"
    }
  }, [_c('b-form-radio-group', {
    staticClass: "mt-2 mb-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.goal_direction_id.$error
    },
    attrs: {
      "id": "goal_direction_id",
      "options": _vm.getGoalDirectionsOptions()
    },
    model: {
      value: _vm.form.goal_direction_id,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "goal_direction_id", $$v);
      },
      expression: "form.goal_direction_id"
    }
  }), _vm.formSubmitted && _vm.$v.form.goal_direction_id.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.goal_direction_id.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Fitness Level(s)",
      "label-for": "ability-level"
    }
  }, [_c('b-form-checkbox-group', {
    staticClass: "mt-2",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.fitness_level_ids.$error
    },
    attrs: {
      "id": "ability-level",
      "name": "ability-level"
    }
  }, _vm._l(_vm.abilityLevels, function (level) {
    return _c('b-form-checkbox', {
      key: level.id,
      attrs: {
        "value": level.id
      },
      model: {
        value: _vm.form.fitness_level_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.form, "fitness_level_ids", $$v);
        },
        expression: "form.fitness_level_ids"
      }
    }, [_vm._v(" " + _vm._s(_vm.s(level.name).capitalize().value()) + " ")]);
  }), 1), _vm.formSubmitted && _vm.$v.form.fitness_level_ids.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.fitness_level_ids.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.formSubmitted && _vm.$v.form.description.$error
    },
    attrs: {
      "rows": "5",
      "placeholder": "Description"
    },
    model: {
      value: _vm.form.description,
      callback: function callback($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _vm.formSubmitted && _vm.$v.form.description.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.form.description.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.form.description.maxLength ? _c('span', [_vm._v(" This value is 200 letters maximum length. ")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "mt-3 bottom-button-container"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onFormCancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary m-l-5 ml-1",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])])])], 1)])])], 1)]), _c('b-collapse', {
    attrs: {
      "visible": !_vm.editEntityMode
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.phasesOverlay,
      "variant": "white",
      "opacity": "0.5",
      "blur": "2px",
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "phases-table"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "cell cell-phase-name phase-name col-lg-2"
  }), _c('div', {
    staticClass: "cell cell-supplement-name col-lg-3"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "cell cell-supplement-type col-lg-2"
  }, [_vm._v("Type")]), _c('div', {
    staticClass: "cell cell-supplement-dose col-lg-3"
  }, [_vm._v("Dose")]), _c('div', {
    staticClass: "cell cell-delete-supplement col-lg-1"
  }), _c('div', {
    staticClass: "cell cell-delete-phase col-lg-1"
  })]), _vm.form.phases.length > 0 ? [_c('Draggable', {
    attrs: {
      "group": "phases",
      "list": _vm.form.phases
    },
    on: {
      "update": _vm.onPhaseOrder
    }
  }, _vm._l(_vm.form.phases, function (phase, phaseIndex) {
    return _c('div', {
      key: 'phase-' + phaseIndex,
      staticClass: "phase-wrapper"
    }, [phaseIndex > 0 ? _c('div', {
      staticClass: "extra-row"
    }, [_c('div', {
      staticClass: "cell cell-phase-name phase-name col-lg-2"
    }), _c('div', {
      staticClass: "cell cell-supplement-name col-lg-3"
    }), _c('div', {
      staticClass: "cell cell-supplement-type col-lg-2"
    }), _c('div', {
      staticClass: "cell cell-supplement-dose col-lg-3"
    }), _c('div', {
      staticClass: "cell cell-delete-supplement col-lg-1"
    }), _c('div', {
      staticClass: "cell cell-delete-phase col-lg-1"
    })]) : _vm._e(), phase.supplements.length > 0 ? [_c('div', {
      staticClass: "group-supplements"
    }, [_c('div', {
      staticClass: "align-middle phase-name cell cell-phase-name col-lg-2"
    }, [_vm._v(" Phase " + _vm._s(phaseIndex + 1) + " ")]), _c('div', {
      staticClass: "supplement-rows col-lg-9"
    }, [_c('Draggable', {
      attrs: {
        "group": "phase.supplements",
        "data-supplement-drop-target": true,
        "data-phase-index": phaseIndex,
        "move": _vm.onSupplementMove,
        "list": phase.supplements
      },
      on: {
        "add": _vm.onSupplementAdd,
        "update": _vm.onSupplementOrder
      }
    }, _vm._l(phase.supplements, function (supplement, supplementIndex) {
      return _c('div', {
        key: 'supplement-' + supplementIndex,
        staticClass: "supplement-row row"
      }, [_c('div', {
        staticClass: "align-middle cell cell-supplement-name col-lg-4"
      }, [_vm._v(" " + _vm._s(supplement.name) + " ")]), _c('div', {
        staticClass: "align-middle cell cell-supplement-type col-lg-3"
      }, [_vm._v(" " + _vm._s(supplement.type_name) + " ")]), _c('div', {
        staticClass: "align-middle cell cell-supplement-dose col-lg-4"
      }, [_vm._v(" " + _vm._s("".concat(supplement.dose, " ").concat(_vm.supplementDoseTypeName(supplement.dose_type))) + " ")]), _c('div', {
        staticClass: "align-middle cell cell-delete-supplement col-lg-1"
      }, [_c('a', {
        attrs: {
          "title": "Delete supplement from phase"
        },
        on: {
          "click": function click($event) {
            return _vm.onSupplementDelete(phase, supplement);
          }
        }
      }, [_c('FaIcon', {
        key: 'supplement_delete_' + supplementIndex,
        attrs: {
          "icon": "far fa-trash-alt",
          "size": "17"
        }
      })], 1)])]);
    }), 0)], 1), _c('div', {
      staticClass: "cell cell-delete-phase col-lg-1"
    }, [_c('a', {
      attrs: {
        "title": "Delete Set"
      },
      on: {
        "click": function click($event) {
          return _vm.onPhaseDelete(phase);
        }
      }
    }, [_c('FaIcon', {
      key: 'phase_delete_' + phaseIndex,
      attrs: {
        "icon": "far fa-trash-alt",
        "size": "17"
      }
    })], 1)])])] : [_c('div', {
      staticClass: "group-supplements"
    }, [_c('div', {
      staticClass: "align-middle phase-name cell cell-phase-name col-lg-2"
    }, [_vm._v(" Phase " + _vm._s(phaseIndex + 1) + " ")]), _c('div', {
      staticClass: "supplement-rows col-lg-9"
    }, [_c('Draggable', {
      staticClass: "supplement-row",
      attrs: {
        "group": "phase.supplements",
        "data-supplement-drop-target": true,
        "data-phase-index": phaseIndex,
        "list": phase.supplements
      },
      on: {
        "add": _vm.onSupplementAdd
      }
    }, [_c('div', {
      staticClass: "text-center cell cell-add-supplement"
    }, [_vm._v(" + Add Supplement ")])])], 1), _c('div', {
      staticClass: "cell cell-delete-phase col-lg-1"
    }, [_c('a', {
      attrs: {
        "title": "Delete Phase"
      },
      on: {
        "click": function click($event) {
          return _vm.onPhaseDelete(phase);
        }
      }
    }, [_c('FaIcon', {
      key: 'phase_delete_' + phaseIndex,
      attrs: {
        "icon": "far fa-trash-alt",
        "size": "17"
      }
    })], 1)])])]], 2);
  }), 0)] : [_c('div', {
    staticClass: "phases-wrapper"
  }, [_c('div', {
    staticClass: "group-supplements"
  }, [_c('div', {
    staticClass: "align-middle phase-name cell cell-phase-name col-lg-2"
  }), _c('div', {
    staticClass: "supplement-rows col-lg-9"
  }, [_c('div', {
    staticClass: "supplement-row"
  }, [_c('div', {
    staticClass: "align-middle cell cell-no-phases"
  }, [_vm._v(" No phases yet ")])])]), _c('div', {
    staticClass: "cell cell-delete-phase col-lg-1"
  })])])]], 2), _c('div', {
    staticClass: "mb-3 mt-3 bottom-button-container"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onAddPhase
    }
  }, [_vm._v(" + Add Phase ")])])])])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }