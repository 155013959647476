var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.phases),
      "state": _vm.getFieldState(_vm.$v.input.phases)
    }
  }, [_c('CurriculumPhasesWaterInput', {
    attrs: {
      "curriculum": _vm.item
    },
    model: {
      value: _vm.input.phases,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phases", $$v);
      },
      expression: "input.phases"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.supplement_program_id),
      "state": _vm.getFieldState(_vm.$v.input.supplement_program_id)
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Suggested Programs"
    }
  }, [_c('b-card', {
    staticClass: "bg-white",
    attrs: {
      "no-body": ""
    }
  }, [_c('SupplementProgramSelect', {
    attrs: {
      "is-suggested": "",
      "params": {
        filters: {
          fitness_levels: [_vm.item.profile.fitness_level],
          goal_directions: [_vm.item.profile.goal_direction],
          genders: [_vm.item.profile.sex]
        }
      }
    },
    on: {
      "input": _vm.unsetSupplements
    },
    model: {
      value: _vm.input.supplement_program_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "supplement_program_id", $$v);
      },
      expression: "input.supplement_program_id"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Other Programs"
    }
  }, [_c('b-card', {
    staticClass: "bg-white",
    attrs: {
      "no-body": ""
    }
  }, [_c('SupplementProgramSelect', {
    attrs: {
      "params": {
        filters: {
          fitness_levels_exclude: [_vm.item.profile.fitness_level],
          goal_directions_exclude: [_vm.item.profile.goal_direction],
          genders_exclude: [_vm.item.profile.sex]
        }
      }
    },
    on: {
      "input": _vm.unsetSupplements
    },
    model: {
      value: _vm.input.supplement_program_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "supplement_program_id", $$v);
      },
      expression: "input.supplement_program_id"
    }
  })], 1)], 1)], 1), _vm.input.supplement_program_id ? [_vm.input.supplement_program_id !== _vm.newItem.supplement_program_id ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.isSubmitting,
      "block": ""
    },
    on: {
      "click": function click($event) {
        return _vm.submit();
      }
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Generate Supplements ")], 1) : _vm._l(_vm.newItem.phases, function (phase, phaseIndex) {
    return _c('CurriculumPhaseSupplementList', {
      key: phase.id,
      attrs: {
        "show-header": phaseIndex === 0,
        "phase": phase,
        "params": {
          filters: {
            phase_id: phase.id
          },
          limit: 100
        },
        "editable": ""
      }
    });
  })] : _vm._e(), _c('b-form-group', {
    staticClass: "text-right my-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }