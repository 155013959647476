<template>
  <div>
    <b-row class="mb-4">
      <b-col>
        <CurriculumMealListTable
          ref="meal-table"
          :params="{
            filters: {
              phase_id: item.id,
              day_number: dayNumber
            },
            order_by: 'taken_at'
          }"
          :result.sync="mealResult"
          :curriculum="curriculum"
          :phase="item"
          mode="values"
          disabled
          @update:result="$emit('update:result-meals', $event)"
        />
      </b-col>
    </b-row>

    <b-row class="mb-4">
      <b-col>
        <CurriculumMealList
          ref="meal-list"
          :params="{
            filters: {
              phase_id: item.id,
              day_number: dayNumber
            },
            order_by: 'taken_at'
          }"
          :result.sync="mealResult"
          :supplement-result="supplementResult"
          disabled
          foods-disabled
          supplements-disabled
        />
      </b-col>
    </b-row>

    <b-row
      v-show="false"
      class="mb-4"
    >
      <b-col>
        <b-card>
          <b-row
            class="mb-3"
            align-v="center"
          >
            <b-col class="font-size-16 font-weight-bold">
              Supplements
            </b-col>
          </b-row>

          <CurriculumSupplementList
            ref="supplement-list"
            :params="{
              filters: {
                phase_id: item.id,
                day_number: dayNumber
              },
              order_by: 'taken_at'
            }"
            disabled
            :result.sync="supplementResult"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CurriculumMealList from '@/components/curriculums/meals/CurriculumMealList'
import CurriculumMealListTable from '@/components/curriculums/meals/CurriculumMealListTable'
import CurriculumSupplementList from '@/components/curriculums/supplements/CurriculumSupplementList'

export default {
  name: 'CurriculumPhaseDayMealsDetail',

  components: {
    CurriculumMealList,
    CurriculumSupplementList,
    CurriculumMealListTable
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    curriculum: {
      type: Object,
      required: true
    },

    dayNumber: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      mealResult: null,
      supplementResult: null
    }
  }
}
</script>