<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <b-dropdown-item
      v-if="$acl.check('couldEditGym')"
      :to="`/gym/${item.id}`"
    >
      Edit
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'GymItemActionsButton',

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  }
}
</script>