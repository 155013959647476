var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [!_vm.curriculumIsActive ? _c('b-alert', {
    staticClass: "m-0 text-center",
    attrs: {
      "show": "",
      "variant": "danger"
    }
  }, [_vm._v(" You curriculum was not active on " + _vm._s(_vm.$moment(_vm.date).format('LL')) + ". ")]) : _vm.item ? _c('TrackerNutritionWaterUpdate', {
    attrs: {
      "item": _vm.item,
      "water-recommended": _vm.stats ? _vm.stats.fluids.total : _vm.waterRecommended,
      "prefetch": false
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.item = $event;
      },
      "submitted": function submitted($event) {
        return _vm.$emit('submitted', $event);
      }
    }
  }) : _vm.isFetching || _vm.isSubmitting ? _c('b-alert', {
    staticClass: "m-0 text-center",
    attrs: {
      "show": "",
      "variant": "light"
    }
  }, [_vm._v(" Loading... ")]) : _c('b-alert', {
    staticClass: "m-0 text-center",
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_vm._v(" You have not used Nutritional Tracker on " + _vm._s(_vm.$moment(_vm.date).format('LL')) + ". "), _c('div', [_c('b-button', {
    attrs: {
      "variant": "link"
    },
    on: {
      "click": _vm.createItem
    }
  }, [_vm._v(" + Add Water Tracker ")])], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }