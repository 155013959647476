var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  }), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.exercises.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card exercise-table-card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "row mb-md-2"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-4"
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.primaryMusclesOptionsWithEmptyValue
    },
    model: {
      value: _vm.exercises.filter.primary_muscle,
      callback: function callback($$v) {
        _vm.$set(_vm.exercises.filter, "primary_muscle", $$v);
      },
      expression: "exercises.filter.primary_muscle"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-12 col-md-4"
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.abilityLevelsOptionsWithEmptyValue
    },
    model: {
      value: _vm.exercises.filter.ability_level,
      callback: function callback($$v) {
        _vm.$set(_vm.exercises.filter, "ability_level", $$v);
      },
      expression: "exercises.filter.ability_level"
    }
  })], 1), _c('div', {
    staticClass: "col-sm-12 col-md-4"
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.workoutTypesOptionsWithEmptyValue
    },
    model: {
      value: _vm.exercises.filter.type,
      callback: function callback($$v) {
        _vm.$set(_vm.exercises.filter, "type", $$v);
      },
      expression: "exercises.filter.type"
    }
  })], 1)]), _c('div', {
    staticClass: "table-responsive mb-0"
  }, [_c('b-table-simple', {
    staticClass: "exercise-table",
    attrs: {
      "busy": _vm.exercises.isBusy
    },
    scopedSlots: _vm._u([{
      key: "cell(type)",
      fn: function fn(data) {
        return [_vm.getWorkoutTypeIcon(data.item.type) ? _c('div', {}, [_c('FaIcon', {
          key: data.item.id,
          attrs: {
            "icon": _vm.getWorkoutTypeIcon(data.item.type),
            "size": "14"
          }
        })], 1) : _vm._e(), !_vm.getWorkoutTypeIcon(data.item.type) ? _c('span', [_vm._v(" " + _vm._s(data.item.type) + " ")]) : _vm._e()];
      }
    }])
  }, [_c('b-thead', [_c('div', [_c('b-tr', [_c('b-th', [_vm._v("Name")]), _c('b-th', [_vm._v("Muscle")]), _c('b-th', [_vm._v("Type")]), _c('b-th', [_vm._v("Fitness Level")])], 1)], 1)]), _c('b-tbody', [_c('Draggable', {
    attrs: {
      "move": _vm.onMove,
      "disabled": _vm.editEntityMode,
      "group": {
        name: 'exercises',
        pull: 'clone',
        put: false
      },
      "list": _vm.exercises.items
    }
  }, _vm._l(this.exercises.items, function (exercise) {
    return _c('b-tr', {
      key: exercise.id,
      attrs: {
        "data-id": exercise.id
      }
    }, [_c('b-td', [_vm._v(_vm._s(exercise.name))]), _c('b-td', [_vm._v(_vm._s(_vm.getPrimaryMuscleNames(exercise)))]), _c('b-td', [_vm.getWorkoutTypeIcon(exercise.type) ? _c('div', {}, [_c('FaIcon', {
      key: exercise.id,
      attrs: {
        "icon": _vm.getWorkoutTypeIcon(exercise.type),
        "size": "14"
      }
    })], 1) : _vm._e(), !_vm.getWorkoutTypeIcon(exercise.type) ? _c('span', [_vm._v(" " + _vm._s(exercise.type) + " ")]) : _vm._e()]), _c('b-td', [_vm._v(" " + _vm._s(exercise.ability_level_names.join(', ')) + " ")])], 1);
  }), 1)], 1)], 1)], 1), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-sm-12 col-md-6"
  }), _c('div', {
    staticClass: "col-sm-12 col-md-6"
  }, [_c('div', {
    staticClass: "dataTables_paginate paging_simple_numbers float-right"
  }, [_c('ul', {
    staticClass: "pagination pagination-rounded mb-0"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.exercises.totalRows,
      "per-page": _vm.exercises.perPage
    },
    model: {
      value: _vm.exercises.currentPage,
      callback: function callback($$v) {
        _vm.$set(_vm.exercises, "currentPage", $$v);
      },
      expression: "exercises.currentPage"
    }
  })], 1)])])])])])])], 1), _c('div', {
    staticClass: "col-lg-6"
  }, [_c('b-collapse', {
    attrs: {
      "visible": !_vm.editEntityMode
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('h4', {
    staticClass: "mb-3 card-title"
  }, [_vm._v(" Workout - " + _vm._s(_vm.workoutForm.name) + " ")])]), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "edit"
    },
    on: {
      "click": _vm.onEditWorkoutEntity
    }
  }, [_vm._v(" Edit ")])])])], 1)], 1)])]), _c('b-collapse', {
    attrs: {
      "visible": _vm.editEntityMode
    }
  }, [_c('div', {
    staticClass: "card"
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.workoutOverlay,
      "variant": "white",
      "opacity": "0.5",
      "blur": "2px",
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('form', {
    attrs: {
      "action": "#"
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.workoutFormSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Name",
      "label-for": "name"
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.workoutForm.name,
      expression: "workoutForm.name"
    }],
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.workoutFormSubmit && _vm.$v.workoutForm.name.$error
    },
    attrs: {
      "type": "text",
      "placeholder": "Name"
    },
    domProps: {
      "value": _vm.workoutForm.name
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }

        _vm.$set(_vm.workoutForm, "name", $event.target.value);
      }
    }
  }), _vm.workoutFormSubmit && _vm.$v.workoutForm.name.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.workoutForm.name.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.workoutForm.name.minLength ? _c('span', [_vm._v(" This value is 5 letters minimum length. ")]) : _vm._e(), !_vm.$v.workoutForm.name.maxLength ? _c('span', [_vm._v(" This value is 200 letters maximum length. ")]) : _vm._e()]) : _vm._e()]), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Fitness Level(s)",
      "label-for": "ability-level"
    }
  }, [_c('b-form-checkbox-group', {
    staticClass: "mt-2",
    "class": {
      'is-invalid': _vm.workoutFormSubmit && _vm.$v.workoutForm.fitness_level_ids.$error
    },
    attrs: {
      "id": "ability-level",
      "name": "ability-level"
    }
  }, _vm._l(_vm.abilityLevels, function (level) {
    return _c('b-form-checkbox', {
      key: level.id,
      attrs: {
        "value": level.id
      },
      model: {
        value: _vm.workoutForm.fitness_level_ids,
        callback: function callback($$v) {
          _vm.$set(_vm.workoutForm, "fitness_level_ids", $$v);
        },
        expression: "workoutForm.fitness_level_ids"
      }
    }, [_vm._v(" " + _vm._s(_vm.s(level.name).capitalize().value()) + " ")]);
  }), 1), _vm.workoutFormSubmit && _vm.$v.workoutForm.fitness_level_ids.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.workoutForm.fitness_level_ids.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Description",
      "label-for": "description"
    }
  }, [_c('b-form-textarea', {
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.workoutFormSubmit && _vm.$v.workoutForm.description.$error
    },
    attrs: {
      "rows": "5",
      "placeholder": "Description"
    },
    model: {
      value: _vm.workoutForm.description,
      callback: function callback($$v) {
        _vm.$set(_vm.workoutForm, "description", $$v);
      },
      expression: "workoutForm.description"
    }
  }), _vm.workoutFormSubmit && _vm.$v.workoutForm.description.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.workoutForm.description.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.workoutForm.description.maxLength ? _c('span', [_vm._v(" This value is 200 letters maximum length. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label-cols": "9",
      "label-cols-sm": "3",
      "label": "Publish",
      "label-for": "is_private"
    }
  }, [_c('FormInputButtons', {
    attrs: {
      "tag": "b-button-group",
      "options": [{
        value: false,
        text: 'Public'
      }, {
        value: true,
        text: 'Private'
      }]
    },
    model: {
      value: _vm.workoutForm.is_private,
      callback: function callback($$v) {
        _vm.$set(_vm.workoutForm, "is_private", $$v);
      },
      expression: "workoutForm.is_private"
    }
  })], 1), _c('div', {
    staticClass: "mt-3 bottom-button-container"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('button', {
    staticClass: "btn btn-secondary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function click($event) {
        $event.stopPropagation();
        $event.preventDefault();
        return _vm.onFormCancel.apply(null, arguments);
      }
    }
  }, [_vm._v(" Cancel ")]), _c('button', {
    staticClass: "btn btn-primary m-l-5 ml-1",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])])])], 1)])])], 1)]), _c('b-collapse', {
    attrs: {
      "visible": !_vm.editEntityMode
    }
  }, [_c('b-overlay', {
    attrs: {
      "id": "overlay-background",
      "show": _vm.groupsOverlay,
      "variant": "white",
      "opacity": "0.5",
      "blur": "2px",
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "groups-table"
  }, [_c('div', {
    staticClass: "header"
  }, [_c('div', {
    staticClass: "cell cell-group-name group-name"
  }), _c('div', {
    staticClass: "cell cell-muscle"
  }, [_vm._v("Muscle")]), _c('div', {
    staticClass: "cell cell-name"
  }, [_vm._v("Exercise")]), _c('div', {
    staticClass: "cell cell-time"
  }, [_vm._v("Time per Set")]), _c('div', {
    staticClass: "cell cell-delete-exercise"
  }), _c('div', {
    staticClass: "cell cell-delete-set"
  })]), _vm.groups.length > 0 ? [_c('Draggable', {
    attrs: {
      "group": "groups",
      "list": _vm.groupsData
    },
    on: {
      "update": _vm.onGroupOrder
    }
  }, _vm._l(_vm.groupsData, function (groupData, groupIndex) {
    return _c('div', {
      key: groupData.group.id,
      staticClass: "group-wrapper"
    }, [groupIndex > 0 ? _c('div', {
      staticClass: "extra-row",
      attrs: {
        "data-group-id": groupData.group.id
      }
    }, [_c('div', {
      staticClass: "cell cell-group-name group-name"
    }), _c('div', {
      staticClass: "cell cell-muscle"
    }), _c('div', {
      staticClass: "cell cell-name"
    }), _c('div', {
      staticClass: "cell cell-time"
    }), _c('div', {
      staticClass: "cell cell-delete-exercise"
    }), _c('div', {
      staticClass: "cell cell-delete-set"
    })]) : _vm._e(), groupData.exercises.length > 0 ? [_c('div', {
      staticClass: "group-exercises"
    }, [_c('div', {
      staticClass: "align-middle group-name cell cell-group-name"
    }, [_vm._v(" " + _vm._s(groupData.group.name) + " ")]), _c('div', {
      staticClass: "exercise-rows"
    }, [_c('Draggable', {
      attrs: {
        "group": "exercises",
        "move": _vm.onExerciseMove,
        "data-id": groupData.group.id,
        "data-exercise-drop-target": true,
        "list": groupData.exercises
      },
      on: {
        "add": _vm.onExerciseAdd,
        "update": _vm.onExerciseOrder
      }
    }, _vm._l(groupData.exercises, function (exercise) {
      return _c('div', {
        key: exercise.exercise_groups_items_id,
        staticClass: "exercise-row"
      }, [_c('div', {
        staticClass: "align-middle cell cell-muscle"
      }, [_vm._v(" " + _vm._s(_vm.getPrimaryMuscleNames(exercise)) + " ")]), _c('div', {
        staticClass: "align-middle cell cell-name"
      }, [_vm._v(" " + _vm._s(exercise.name) + " ")]), _c('div', {
        staticClass: "align-middle cell cell-time"
      }, [_vm._v(" " + _vm._s(exercise.estimated_set_time) + " secs ")]), _c('div', {
        staticClass: "align-middle cell cell-delete-exercise"
      }, [_c('a', {
        attrs: {
          "title": "Delete exercise from set"
        },
        on: {
          "click": function click($event) {
            return _vm.onExerciseDelete(exercise.exercise_groups_items_id, groupData.group.id);
          }
        }
      }, [_c('FaIcon', {
        key: exercise.exercise_groups_items_id,
        attrs: {
          "icon": "far fa-trash-alt",
          "size": "12"
        }
      })], 1)])]);
    }), 0)], 1), _c('div', {
      staticClass: "cell cell-delete-set"
    }, [_c('a', {
      attrs: {
        "title": "Rename group"
      },
      on: {
        "click": function click($event) {
          return _vm.onGroupRename(groupData.group.id, groupData.group.name);
        }
      }
    }, [_c('FaIcon', {
      key: 'group_rename_' + groupData.group.id,
      attrs: {
        "icon": "fas fa-edit",
        "size": "14"
      }
    })], 1), _c('a', {
      attrs: {
        "title": "Delete Set"
      },
      on: {
        "click": function click($event) {
          return _vm.onGroupDelete(groupData.group.id);
        }
      }
    }, [_c('FaIcon', {
      key: 'group_delete_' + groupData.group.id,
      attrs: {
        "icon": "far fa-trash-alt",
        "size": "14"
      }
    })], 1)])])] : [_c('div', {
      staticClass: "group-exercises"
    }, [_c('div', {
      staticClass: "align-middle group-name cell cell-group-name"
    }, [_vm._v(" " + _vm._s(groupData.group.name) + " ")]), _c('div', {
      staticClass: "exercise-rows"
    }, [_c('div', {
      staticClass: "exercise-row"
    }, [_c('div', {
      staticClass: "text-center cell cell-add-exercise"
    }, [_c('Draggable', {
      attrs: {
        "group": "exercises",
        "data-id": groupData.group.id,
        "data-exercise-drop-target": true
      },
      on: {
        "add": _vm.onExerciseAdd
      }
    }, [_vm._v(" + Add exercise ")])], 1)])]), _c('div', {
      staticClass: "cell cell-delete-set"
    }, [_c('a', {
      attrs: {
        "title": "Rename group"
      },
      on: {
        "click": function click($event) {
          return _vm.onGroupRename(groupData.group.id, groupData.group.name);
        }
      }
    }, [_c('FaIcon', {
      key: 'group_rename_' + groupData.group.id,
      attrs: {
        "icon": "fas fa-edit",
        "size": "14"
      }
    })], 1), _c('a', {
      attrs: {
        "title": "Delete Set"
      },
      on: {
        "click": function click($event) {
          return _vm.onGroupDelete(groupData.group.id);
        }
      }
    }, [_c('FaIcon', {
      key: groupData.group.id,
      attrs: {
        "icon": "far fa-trash-alt",
        "size": "14"
      }
    })], 1)])])]], 2);
  }), 0), _c('div', {
    staticClass: "exercise-row"
  }, [_c('div', {
    staticClass: "align-middle group-name cell cell-group-name"
  }), _c('div', {
    staticClass: "align-middle cell cell-muscle"
  }, [_vm._v("Total")]), _c('div', {
    staticClass: "align-middle cell cell-name"
  }, [_vm._v(" " + _vm._s(_vm.exercises_total) + " exercises ")]), _c('div', {
    staticClass: "align-middle cell cell-time"
  }, [_vm._v(" " + _vm._s(_vm.exercises_total_time) + " secs ")]), _c('div', {
    staticClass: "align-middle cell cell-delete-exercise"
  }), _c('div', {
    staticClass: "cell cell-delete-set"
  })])] : [_c('div', {
    staticClass: "group-wrapper"
  }, [_c('div', {
    staticClass: "group-exercises"
  }, [_c('div', {
    staticClass: "align-middle group-name cell cell-group-name"
  }), _c('div', {
    staticClass: "exercise-rows"
  }, [_c('div', {
    staticClass: "exercise-row"
  }, [_c('div', {
    staticClass: "align-middle cell cell-no-groups"
  }, [_vm._v(" No sets yet ")])])]), _c('div', {
    staticClass: "cell cell-delete-set"
  })])])]], 2), _c('div', {
    staticClass: "mb-3 mt-3 bottom-button-container"
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.onAddSet
    }
  }, [_vm._v(" + Add Set ")]), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "to": "/workout/list",
      "variant": "success"
    }
  }, [_vm._v(" Finish ")])], 1)])])], 1)], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }