import _ from 'lodash'

export const state = {
    layoutType: 'vertical',
    loader: false,
    alerts: [],
    dirtyComponents: []
}

export const getters = {
    getLoader (state) {
        return state.loader
    },
    getAlerts (state) {
        return state.alerts
    },
    dirtyComponents(state) {
        return state.dirtyComponents
    }
}

export const mutations = {
    CHANGE_LAYOUT(state, layoutType) {
        state.layoutType = layoutType;
    },
    setLoader (state, value) {
        state.loader = !!value
    },
    addAlerts (state, alert) {
        const lastId = state.alerts.length !== 0 ? state.alerts[state.alerts.length - 1].id : false
        state.alerts.push({ ...alert, id: lastId ? lastId + 1 : 1 })
    },
    removeAlert (state, id) {
        const index = id ? state.alerts.findIndex(item => item.id === id) : 0
        state.alerts.splice(index, 1)
    },
    SET_DIRTY_COMPONENTS(state, value) {
        state.dirtyComponents = value
    }
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    changeLayoutType({ commit, state, rootState }, { layoutType }) {
        commit('CHANGE_LAYOUT', layoutType);
    },
    async setDirtyComponent({ commit, getters }, id) {
        if (!getters.dirtyComponents.includes(id)) {
            const newValue = [...getters.dirtyComponents, id]
            return await commit('SET_DIRTY_COMPONENTS', newValue)
        }
    },
    removeDirtyComponent({ commit, getters }, id) {
        const newValue = _.filter(getters.dirtyComponents, id)
        return commit('SET_DIRTY_COMPONENTS', newValue)
    },
    clearDirtyComponents({ commit }) {
        return commit('SET_DIRTY_COMPONENTS', [])
    }
}
