<template>
  <b-row align-h="between">
    <b-col>
      <!-- TAKEN AT -->
      <b-input-group>
        <b-form-datepicker
          v-model="newFilters.taken_at_from"
          size="sm"
          placeholder="From Date"
          reset-button
        />

        <b-form-datepicker
          v-model="newFilters.taken_at_to"
          size="sm"
          placeholder="To Date"
          reset-button
        />
      </b-input-group>
    </b-col>

    <b-col class="text-right">
      <b-form-group>
        <b-form-checkbox
          v-model="newFilters.is_favorite"
          switch
          size="md"
        >
          Favorite
        </b-form-checkbox>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ImageListFilters',

  props: {
    filters: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      newFilters: null
    }
  },

  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler() {
        if (!_.isEqual(this.filters, this.newFilters)) {
          this.newFilters = _.cloneDeep(this.filters)
        }
      }
    },

    newFilters: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.newFilters && !_.isEqual(this.newFilters, this.filters)) {
          this.$emit('update:filters', _.cloneDeep(this.newFilters))
        }
      }
    },
  }
}
</script>