var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', _vm._b({
    attrs: {
      "right": "",
      "text": "Actions"
    },
    scopedSlots: _vm._u([_vm.$slots["default"] ? {
      key: "button-content",
      fn: function fn() {
        return [_vm._t("default")];
      },
      proxy: true
    } : null], null, true)
  }, 'b-dropdown', _vm.dropdownProps, false), [_c('b-dropdown-item', {
    attrs: {
      "to": _vm.isEditable && _vm.$acl.check('couldBuildCurriculum') ? "/curriculums/".concat(_vm.item.id) : "/curriculums/".concat(_vm.item.id, "/view")
    }
  }, [_vm._v(" Details ")]), _vm.item.status !== 'designing' ? _c('b-dropdown-item', {
    attrs: {
      "to": "/curriculums/".concat(_vm.item.id, "/logs")
    }
  }, [_vm._v(" Logs ")]) : _vm._e(), _vm.isRebootable && _vm.isOwner ? _c('CurriculumItemRebootButton', {
    attrs: {
      "item": {
        id: _vm.item.id
      },
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "rebooted:item": function rebootedItem($event) {
        return _vm.$emit('rebooted:item', $event);
      }
    }
  }, [_vm._v(" Reboot ")]) : _vm._e(), _vm.isCompletable ? [!_vm.item.parent_id && _vm.$acl.check('couldBuildCurriculum') ? _c('CurriculumItemCompleteButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "completed:item": function completedItem($event) {
        return _vm.$emit('completed:item', $event);
      }
    }
  }, [_vm._v(" Complete ")]) : _vm._e(), _vm.item.parent_id && _vm.isOwner ? _c('CurriculumItemCompleteButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "completed:item": function completedItem($event) {
        return _vm.$emit('completed:item', $event);
      }
    }
  }, [_vm._v(" Complete ")]) : _vm._e()] : _vm._e(), _vm.isCancelable ? [!_vm.item.parent_id && _vm.$acl.check('couldBuildCurriculum') ? _c('CurriculumItemCancelButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "canceled:item": function canceledItem($event) {
        return _vm.$emit('canceled:item', $event);
      }
    }
  }, [_vm._v(" Cancel ")]) : _vm._e(), _vm.item.parent_id && _vm.isOwner ? _c('CurriculumItemCancelButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "canceled:item": function canceledItem($event) {
        return _vm.$emit('canceled:item', $event);
      }
    }
  }, [_vm._v(" Deactivate ")]) : _vm._e()] : _vm._e(), _vm.isDeletable && _vm.$acl.check('couldBuildCurriculum') ? _c('CurriculumItemDeleteButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-dropdown-item-button",
      "tag-props": {
        buttonClass: 'font-size-14'
      }
    },
    on: {
      "deleted:item": function deletedItem($event) {
        return _vm.$emit('deleted:item', $event);
      }
    }
  }, [_vm._v(" Delete ")]) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }