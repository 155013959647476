<template>
  <b-dropdown
    :text="title"
  >
    <b-dropdown-header>
      Your role is <Roles :ids="[currentRole.role_id]" />
    </b-dropdown-header>

    <!--
    <b-dropdown-item-button
      v-for="company in companies"
      :key="company.id"
      @click="onSelectCompany(company)"
    >
      {{ company.name }}
    </b-dropdown-item-button>

    <b-dropdown-divider />


    <b-dropdown-item-button
      @click="applyCompany"
    >
      Apply for a new Company
    </b-dropdown-item-button>
    -->

    <b-modal
      v-model="modalIsShown"
      title="Select role"
      hide-footer
    >
      <template v-if="selectedCompany">
        <b-button
          v-for="roleId in selectedCompany.roleIds"
          :key="roleId"
          :disabled="currentRole.role_id === roleId && currentRole.company_id === selectedCompany.id"
          block
          @click="onSelectRole(roleId, selectedCompany.id)"
        >
          <Roles :ids="[roleId]" />
        </b-button>
      </template>
    </b-modal>
  </b-dropdown>
</template>

<script>
import _ from 'lodash'
import Roles from '@/components/v1/asset/Roles'
import Swal from "sweetalert2"
import { mapGetters } from 'vuex'

export default {
  name: 'CompanySelect',

  components: {
    Roles
  },

  data() {
    return {
      selectedCompany: null
    }
  },

  computed: {
    ...mapGetters({
      currentRole: 'auth/currentRole'
    }),

    companies() {
      return _.map(this.$store.getters['auth/currentUserCompanies'], i => ({
        ...i,

        roleIds: _.chain(this.$store.getters['auth/currentUserRoles'])
          .filter({ company_id: i.id })
          .map(i => i.role_id)
          .value()
      }))
    },

    title() {
      return _.get(this.$store.getters['auth/currentCompany'], 'name', 'No company')
    },

    modalIsShown: {
      get() {
        return !!this.selectedCompany
      },

      set(value) {
        if (!value) {
          this.selectedCompany = null
        }
      }
    }
  },

  methods: {
    onSelectCompany(company) {
      if (company.roleIds.length === 1) {
        this.onSelectRole(company.roleIds[0], company.id)
      } else {
        this.selectedCompany = company
      }
    },

    onSelectRole(role_id, company_id) {
      this.showModal(role_id, company_id)
    },

    showModal(role_id, company_id) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to switch role?', {
        title: 'Please Confirm',
        okTitle: 'Switch',
        okVariant: 'warning',
        centered: true
      })
        .then(value => {
          if (value) {
            this.$store.commit('auth/SET_CURRENT_ROLE', { role_id, company_id })
            this.selectedCompany = null
            this.$router.push('/')
          }
        })
    },

    applyCompany() {
      var ipAPI = "https://api.ipify.org?format=json";
      Swal.queue([
        {
          title: "Apply for a new Company",
          confirmButtonText: "Apply Now",
          text: "",
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return fetch(ipAPI)
              .then((response) => response.json())
              .then((data) => {
                let message =
                  '<div class="apply-token" style="font-size:16px;">';
                message +=
                  '<div class="token-value" style="font-weight:bold;font-size:24px;">';
                message += data.ip;
                message += "</div>";
                message +=
                  "Please pass token to company manager<br>Token expires in 60 minutes";
                message += "</div>";
                Swal.insertQueueStep(message);
              })
              .catch(() => {
                Swal.insertQueueStep({
                  type: "error",
                  title: "Unable to get your public IP",
                });
              });
          },
        },
      ]);
    }
  }
}
</script>