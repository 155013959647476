var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', _vm._b({
    attrs: {
      "right": "",
      "text": "Actions"
    },
    scopedSlots: _vm._u([_vm.$slots["default"] ? {
      key: "button-content",
      fn: function fn() {
        return [_vm._t("default")];
      },
      proxy: true
    } : null], null, true)
  }, 'b-dropdown', _vm.dropdownProps, false), [_vm.$acl.check('couldEditGym') ? _c('b-dropdown-item', {
    attrs: {
      "to": "/gym/".concat(_vm.item.id)
    }
  }, [_vm._v(" Edit ")]) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }