<template>
  <div>
    <b-table-simple
      class="input-table mb-0"
      fixed
    >
      <b-thead>
        <b-tr>
          <b-th class="text-center bg-transparent border-0 w-100px" />
          <b-th>Exercise</b-th>
          <b-th>Muscle</b-th>
          <b-th>Time per Set</b-th>
        </b-tr>
      </b-thead>

      <b-tbody
        v-for="(exerciseGroup, exerciseGroupIndex) in value"
        :key="exerciseGroupIndex"
      >
        <b-tr v-if="!exerciseGroup.exercises || !exerciseGroup.exercises.length">
          <b-th class="text-center w-100px">
            {{ exerciseGroup.name }}
          </b-th>

          <b-td />
          <b-td />
          <b-td />
        </b-tr>
  
        <template v-else>
          <b-tr
            v-for="(exercise, exerciseIndex) in exerciseGroup.exercises"
            :key="exerciseIndex"
          >
            <b-th
              v-if="exerciseIndex === 0"
              class="text-center w-100px"
              :rowspan="exerciseGroup.exercises.length"
            >
              {{ exerciseGroup.name }}
            </b-th>

            <b-td>
              {{ exercise.name }}
            </b-td>

            <b-td>
              <Muscles :ids="exercise.muscle_ids" />
            </b-td>

            <b-td>
              {{ exercise.estimated_set_time }}
            </b-td>
          </b-tr>
        </template>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script>
import Muscles from '@/components/v1/asset/Muscles'
export default {
  name: 'WorkoutExerciseGroupsInput',

  components: {
    Muscles
  },

  props: {
    value: {
      type: Array,
      required: true
    }
  }
}
</script>


<style scoped lang="scss">
  .input-table {
    th {
      background: #BAD9D9;
      vertical-align: middle;
      border-bottom: none;
    }
    td {
      vertical-align: middle;
      border-bottom: none;
    }

    thead {
      th {
        background: #c0c5ed;

        &:first-child {
          background: #97CBCA;
          vertical-align: middle;
        }
      }
    }

    tbody {
      background: #FFFFFF;
    }
  }
</style>