var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0",
      "justified": "",
      "no-fade": ""
    }
  }, _vm._l(_vm.newItem.phases, function (phase) {
    return _c('b-tab', {
      key: phase.id,
      attrs: {
        "lazy": "",
        "title-link-class": "rounded-0 border-0",
        "title": "Phase ".concat(phase.number)
      }
    }, [_c('CurriculumPhaseMealsDetail', {
      attrs: {
        "item": phase,
        "curriculum": _vm.item
      }
    })], 1);
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }