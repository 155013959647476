<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-row>
      <b-col lg="4">
        <div class="image-field text-center">
          <div v-if="tmpImageUrl || imageUrl && !input.remove_image">
            <b-img
              :src="imageUrl"
              fluid
            />

            <div class="image-remove">
              <b-button
                variant="primary"
                @click="input.remove_image = true; input.image = null"
              >
                Remove Photo
              </b-button>
            </div>
          </div>

          <b-form-file
            v-else
            class="add-image mt-4"
            placeholder="+ Add Photo"
            drop-placeholder="Drop file here..."
            :state="getFieldState($v.input.image)"
            @change="input.image = $event.target.files[0]"
          />
        </div>
      </b-col>

      <b-col lg="8">
        <b-form
          @submit.prevent="submit('submitted')"
        >
          <!-- NAME -->
          <b-form-group
            label="Name"
            label-cols-sm="3"
            :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
            :state="getFieldState($v.input.name)"
          >
            <b-form-input
              v-model="input.name"
              :state="getFieldState($v.input.name)"
              placeholder="Enter Exercise Name"
            />
          </b-form-group>

          <template v-if="!source">
            <!-- MUSCLES -->
            <b-form-group
              label="Primary Muscle(s):"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.muscle_ids)"
              :state="getFieldState($v.input.muscle_ids)"
            >
              <b-form-checkbox-group>
                <b-form-checkbox
                  v-model="input.muscle_ids"
                  v-for="option in $store.getters['asset/getPrimaryMusclesOptions']"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <!-- SUBCATEGORY -->
            <b-form-group
              label="Subcategory"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.subcategory_ids)"
              :state="getFieldState($v.input.subcategory_ids)"
            >
              <b-form-checkbox-group>
                <div class="custom-control custom-control-inline custom-checkbox">
                  <input
                    id="subcategory-none"
                    :checked="input.subcategory_ids && !input.subcategory_ids.length"
                    :disabled="input.subcategory_ids && !input.subcategory_ids.length"
                    type="checkbox"
                    class="custom-control-input"
                    @input="input.subcategory_ids = []"
                  />

                  <label
                    for="subcategory-none"
                    class="custom-control-label"
                  >
                    None
                  </label>
                </div>

                <b-form-checkbox
                  v-model="input.subcategory_ids"
                  v-for="option in $store.getters['asset/getExerciseSubcategoriesOptions']"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <!-- POSITION -->
            <b-form-group
              label="Position"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.position_ids)"
              :state="getFieldState($v.input.position_ids)"
            >
              <b-form-checkbox-group>
                <div class="custom-control custom-control-inline custom-checkbox">
                  <input
                    id="position-none"
                    :checked="input.position_ids && !input.position_ids.length"
                    :disabled="input.position_ids && !input.position_ids.length"
                    type="checkbox"
                    class="custom-control-input"
                    @input="input.position_ids = []"
                  />

                  <label
                    for="position-none"
                    class="custom-control-label"
                  >
                    None
                  </label>
                </div>

                <b-form-checkbox
                  v-model="input.position_ids"
                  v-for="option in $store.getters['asset/getExercisePositionsOptions']"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <!-- BENCH POSITION -->
            <b-form-group
              label="Bench position"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.bench_position_ids)"
              :state="getFieldState($v.input.bench_position_ids)"
            >
              <b-form-checkbox-group>
                <div class="custom-control custom-control-inline custom-checkbox">
                  <input
                    id="bench-position-none"
                    :checked="input.bench_position_ids && !input.bench_position_ids.length"
                    :disabled="input.bench_position_ids && !input.bench_position_ids.length"
                    type="checkbox"
                    class="custom-control-input"
                    @input="input.bench_position_ids = []"
                  />

                  <label
                    for="bench-position-none"
                    class="custom-control-label"
                  >
                    None
                  </label>
                </div>

                <b-form-checkbox
                  v-model="input.bench_position_ids"
                  v-for="option in $store.getters['asset/getExerciseBenchPositionsOptions']"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <!-- BENCH POSITION -->
            <b-form-group
              label="Hand/Feet placement"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.hand_feet_placement_ids)"
              :state="getFieldState($v.input.hand_feet_placement_ids)"
            >
              <b-form-checkbox-group>
                <div class="custom-control custom-control-inline custom-checkbox">
                  <input
                    id="hand-feet-placement-none"
                    :checked="input.hand_feet_placement_ids && !input.hand_feet_placement_ids.length"
                    :disabled="input.hand_feet_placement_ids && !input.hand_feet_placement_ids.length"
                    type="checkbox"
                    class="custom-control-input"
                    @input="input.hand_feet_placement_ids = []"
                  />

                  <label
                    for="hand-feet-placement-none"
                    class="custom-control-label"
                  >
                    None
                  </label>
                </div>

                <b-form-checkbox
                  v-model="input.hand_feet_placement_ids"
                  v-for="option in $store.getters['asset/getExerciseHandFeetPlacementsOptions']"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <!-- FITNESS LEVELS -->
            <b-form-group
              label="Fitness Level(s):"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.fitness_level_ids)"
              :state="getFieldState($v.input.fitness_level_ids)"
            >
              <b-form-checkbox-group>
                <div class="custom-control custom-control-inline custom-checkbox">
                  <input
                    id="fitness-level-none"
                    :checked="input.fitness_level_ids && !input.fitness_level_ids.length"
                    :disabled="input.fitness_level_ids && !input.fitness_level_ids.length"
                    type="checkbox"
                    class="custom-control-input"
                    @input="input.fitness_level_ids = []"
                  />

                  <label
                    for="fitness-level-none"
                    class="custom-control-label"
                  >
                    None
                  </label>
                </div>

                <b-form-checkbox
                  v-model="input.fitness_level_ids"
                  v-for="option in $store.getters['asset/getAbilityLevelsOptions']"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <!-- TYPE -->
            <b-form-group
              label="Workout Type"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.type_ids)"
              :state="getFieldState($v.input.type_ids)"
            >
              <b-form-checkbox-group>
                <b-form-checkbox
                  v-model="input.type_ids"
                  v-for="option in $store.getters['asset/getWorkoutTypesOptions']"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.text }}
                </b-form-checkbox>
              </b-form-checkbox-group>
            </b-form-group>

            <!-- TYPE -->
            <b-form-group
              label="Estimated Set Time"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.estimated_set_time)"
              :state="getFieldState($v.input.estimated_set_time)"
            >
              <b-form-input
                v-model="input.estimated_set_time"
                type="number"
                number
                placeholder="Seconds to complete one set of ten repetitions"
                :state="getFieldState($v.input.estimated_set_time)"
              />
            </b-form-group>

            <!-- DESCRIPTION -->
            <b-form-group
              label="Description"
              label-cols-sm="3"
              :invalid-feedback="getFieldInvalidFeedback($v.input.description)"
              :state="getFieldState($v.input.description)"
            >
              <b-form-textarea
                v-model="input.description"
                :state="getFieldState($v.input.description)"
                placeholder="Enter Exercise Description"
              />
            </b-form-group>
          </template>

          <!-- SUBMIT -->
          <b-form-group class="text-right mt-4">
            <b-button
              class="mr-2"
              @click="$emit('cancel')"
            >
              Cancel
            </b-button>

            <b-button
              variant="primary"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />{{ actionName }}
            </b-button>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { requiredUnless } from "vuelidate/lib/validators"

const notNil = (value) => !_.isNil(value)

export default {
  name: 'ExerciseItemUpsert',

  mixins: [
    crudItemMixin
  ],

  props: {
    source: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'exercises',
      isExpanded: false
    }
  },

  computed: {
    actionName() {
      return this.isCreated ? 'Update' : this.source ? 'Copy' : 'Create'
    },

    tmpImageUrl() {
      return this.input && this.input.image ? URL.createObjectURL(this.input.image) : null
    },

    imageUrl() {
      return this.tmpImageUrl || _.get(this.newItem, 'image_url')
    }
  },

  validations() {
    return {
      input: {
        name: { requiredUnless: requiredUnless('input.source_id') },
        description: {},
        type_ids: { requiredUnless: requiredUnless('input.source_id') },
        position_ids: { required: notNil },
        bench_position_ids: { required: notNil },
        hand_feet_placement_ids: { required: notNil },
        subcategory_ids: { required: notNil },
        muscle_ids: { required: notNil },
        fitness_level_ids: { required: notNil },
        estimated_set_time: { required: notNil },
        source_id: {}
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        name: this.newItem.name,
        description: this.newItem.description,
        type_ids: _.get(this.newItem, 'type_ids'),
        subcategory_ids: _.get(this.newItem, 'subcategory_ids', null),
        muscle_ids: _.get(this.newItem, 'muscle_ids', null),
        fitness_level_ids: _.get(this.newItem, 'fitness_level_ids', null),
        estimated_set_time: _.get(this.newItem, 'estimated_set_time', 15),
        position_ids: _.get(this.newItem, 'position_ids', null),
        bench_position_ids: _.get(this.newItem, 'bench_position_ids', null),
        hand_feet_placement_ids: _.get(this.newItem, 'hand_feet_placement_ids', null),
        image: null,
        remove_image: false
      }

      input.subcategory_ids = _.size(input.subcategory_ids) ? input.subcategory_ids : null
      input.muscle_ids = _.size(input.muscle_ids) ? input.muscle_ids : null
      input.fitness_level_ids = _.size(input.fitness_level_ids) ? input.fitness_level_ids : null
      input.position_ids = _.size(input.position_ids) ? input.position_ids : null
      input.bench_position_ids = _.size(input.bench_position_ids) ? input.bench_position_ids : null
      input.hand_feet_placement_ids = _.size(input.hand_feet_placement_ids) ? input.hand_feet_placement_ids : null

      if (!this.isCreated && this.source) {
        input.source_id = this.source.id
        input.name = `${this.source.name} -- Copy`
        input.description = _.get(this.source, 'description')
        input.type_ids = _.get(this.source, 'type_ids')
        input.subcategory_ids = _.get(this.source, 'subcategory_ids')
        input.muscle_ids = _.get(this.source, 'muscle_ids')
        input.fitness_level_ids = this.source.fitness_level_ids
        input.estimated_set_time = this.source.estimated_set_time
        input.position_ids = _.get(this.newItem, 'position_ids')
        input.bench_position_ids = _.get(this.source, 'bench_position_ids')
        input.hand_feet_placement_ids = _.get(this.source, 'hand_feet_placement_ids')
      }

      return input
    },

    async afterSubmit(data) {
      if (this.input.image) {
        const formData = new FormData()
        formData.append('image', this.input.image)

        return this.$axios
          .patch(`${this.baseEndpoint}/${data.id}`, formData)

          .then(async ({ data }) => {  
            return data
          })

          .catch((e) => {
            throw e
          })
      }
    }
  }
}
</script>