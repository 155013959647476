<template>
  <b-overlay :show="isFetching">
    <b-form
      v-if="isFetched || !isCreated"
      @submit.prevent="submit('submitted')"
    >
      <!-- EXERCISE -->
      <b-form-group
        :invalid-feedback="getFieldInvalidFeedback($v.input.exercise_id)"
        :state="getFieldState($v.input.exercise_id)"
      >
        <ExerciseList
          v-model="input.exercise_id"
          :columns="['select', 'name', 'muscle_ids', 'type', 'fitness_level_ids']"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4 mb-0">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />{{ actionName }}
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { required } from "vuelidate/lib/validators"
import crudItemMixin from '@/mixins/crud-item'
import ExerciseList from '@/components/exercises/ExerciseList'

export default {
  name: 'TrackerExerciseItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    ExerciseList
  },

  data() {
    return {
      baseEndpoint: 'tracker-exercises'
    }
  },

  validations() {
    return {
      input: {
        exercise_id: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        exercise_id: _.get(this.newItem, 'exercise_id', null)
      }

      if (!this.isCreated) {
        input.tracker_exercise_group_id = _.get(this.newItem, 'tracker_exercise_group_id', null)
      }

      return input
    }
  }
}
</script>