<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import FaIcon from '@/components/widgets/fa-icon';
import Swal from 'sweetalert2';
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import Genders from '@/components/v1/asset/Genders'
import { mapGetters } from 'vuex'

/**
 * Advanced table component
 */
export default {
  name: 'SupplementListPage',
  page: {
    title: 'Supplements',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, FaIcon, Abilities, GoalDirections, Genders },
  data() {
    return {
      config: appConfig,
      title: 'Supplement Programs',
      totalRows: 0,
      currentPage: 1,
      perPage: appConfig.table.perPage,
      pageOptions: appConfig.table.pageOptions,
      isBusy: false,
      sortBy: 'name',
      sortDesc: false,
      afilter: '',
      fields: [
        { key: 'name', sortable: true, tdClass: 'align-middle' },
        {
          key: 'gender',
          sortable: true,
          tdClass: 'align-middle'
        },
        {
          key: 'goal_direction_id',
          sortable: true,
          label: 'Goal Direction',
          tdClass: 'align-middle'
        },
        {
          key: 'fitness_level_ids',
          sortable: false,
          label: 'Fitness Level',
          tdClass: 'align-middle'
        },
        {
          key: 'action_edit',
          sortable: false,
          tdClass: 'text-center align-middle',
          label: '',
        },
        {
          key: 'action_delete',
          sortable: false,
          tdClass: 'text-center align-middle',
          label: '',
        },
      ],
    };
  },
  watch: {},
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      currentCompany: 'auth/currentCompany',
      adminView: 'auth/adminView'
    }),
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  methods: {
    dataProvider() {
      const limit = this.perPage
      const page = this.currentPage
      const order_by = this.sortBy
      const order_desc = this.sortDesc
      // const genders = this.filter.gender ? [this.filter.gender] : null
      // const goal_directions = this.filter.goal_direction ? [this.filter.goal_direction] : null
      // const fitness_levels = this.filter.fitness_level ? [this.filter.fitness_level] : null
      return this.$axios
        .get(`/supplement-programs`, {
          params: {
            page,
            limit,
            order_by,
            order_desc
          },
          parent: this,
        })
        .then(({ data }) => {
          this.totalRows = data.total;
          return data.data;
        });
    },
    onEdit(id) {
      this.$router.push(`/supplement-program/${id}`);
    },
    async onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.value) {
          await this.$axios
            .delete(`supplement-programs/${id}`)
            .then(() => {
              Swal.fire('Deleted!', 'Supplement has been deleted.', 'success');
              this.triggerReload();
            })
            .catch(() => {
              Swal.fire(
                'Error Happened!',
                'Supplement is not deleted.',
                'error'
              );
              this.triggerReload();
            });
        }
      });
    },
    couldEdit(item) {
      return (
        this.$acl.check('couldEditPublicSupplementProgram') && !item.is_private ||
        this.$acl.check('couldEditOwnSupplementProgram') && this.currentUser.id === item.created_by
      )
    },
    couldDelete(item) {
      return (
        this.$acl.check('couldDeletePublicSupplementProgram') && !item.is_private ||
        this.$acl.check('couldDeleteOwnSupplementProgram') && this.currentUser.id === item.created_by
      )
    },
    triggerReload() {
      this.afilter = {
        value: !this.afilter.value,
      };
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="isBusy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="dataProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="afilter"
                >
                  <template #cell(fitness_level_ids)="data">
                    <Abilities :ids="data.item.fitness_level_ids" />
                  </template>
                  <template #cell(goal_direction_id)="data">
                    <GoalDirections :ids="[data.item.goal_direction_id]" />
                  </template>
                  <template #cell(gender)="data">
                    <Genders :ids="[data.item.gender]" />
                  </template>
                  <template #cell(action_edit)="data">
                    <b-button
                      variant="primary"
                      v-if="couldEdit(data.item)"
                      @click="onEdit(data.item.id)"
                    >
                      Edit
                    </b-button>
                  </template>
                  <template #cell(action_delete)="data">
                    <div>
                      <a
                        v-if="couldDelete(data.item)"
                        @click="onDelete(data.item.id)"
                      >
                        <FaIcon
                          icon="far fa-trash-alt"
                          :key="data.item.id"
                          size="17"
                        />
                      </a>
                    </div>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>
