<template>
  <Layout>
    <b-overlay
      :show="isBusy"
      :variant="config.overlay.variant"
      :opacity="config.overlay.opacity"
      :blur="config.overlay.blur"
      rounded="sm"
    >
      <template v-if="client">
        <b-row>
          <b-col cols="8">
            <PageHeader :title="title" />
          </b-col>

          <b-col
            cols="4"
            class="d-flex align-items-center justify-content-end"
          >
            <PhysicalConditionItemUpsertButton
              tag="b-button"
              :client.sync="client"
              :tag-props="{
                variant: 'primary'
              }"
              @update:item="refresh"
            >
              Add Measurements
            </PhysicalConditionItemUpsertButton>
          </b-col>
        </b-row>

        <b-card>
          <PhysicalConditionList
            ref="list"
            :client.sync="client"
            :params="{
              filters: {
                user_id: client.id
              },
              order_by: 'taken_at',
              order_desc: true
            }"
          />
        </b-card>
      </template>
    </b-overlay>
  </Layout>
</template>

<script>
import appConfig from '@/app.config'
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import PhysicalConditionList from '@/components/physical-conditions/PhysicalConditionList'
import PhysicalConditionItemUpsertButton from '@/components/physical-conditions/buttons/PhysicalConditionItemUpsertButton'

export default {
  name: 'PhysicalConditionsPage',

  components: {
    Layout,
    PageHeader,
    PhysicalConditionList,
    PhysicalConditionItemUpsertButton
  },

  page() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      isBusy: false,
      config: appConfig,
      client: null
    }
  },

  computed: {
    title() {
      return this.client ? `${this.client.first_name || this.client.email}'s Measurements` : null
    }
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchItem()
      }
    }
  },

  methods: {
    refresh() {
      return this.$refs.list.refresh()
    },

    fetchItem() {
      this.$axios
        .get(`users/${this.$route.params.id}`, {
          parent: this
        })
        .then(({ data }) => {
          this.client = data
        })
        .catch((e) => {
          throw e
        })
    }
  }
}
</script>