var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_vm.client ? [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "4"
    }
  }, [_c('PhysicalConditionItemUpsertButton', {
    attrs: {
      "tag": "b-button",
      "client": _vm.client,
      "tag-props": {
        variant: 'primary'
      }
    },
    on: {
      "update:client": function updateClient($event) {
        _vm.client = $event;
      },
      "update:item": _vm.refresh
    }
  }, [_vm._v(" Add Measurements ")])], 1)], 1), _c('b-card', [_c('PhysicalConditionList', {
    ref: "list",
    attrs: {
      "client": _vm.client,
      "params": {
        filters: {
          user_id: _vm.client.id
        },
        order_by: 'taken_at',
        order_desc: true
      }
    },
    on: {
      "update:client": function updateClient($event) {
        _vm.client = $event;
      }
    }
  })], 1)] : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }