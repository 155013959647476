var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "items": _vm.newResult.data,
      "fields": _vm.fields,
      "stacked": "sm",
      "fixed": "",
      "no-local-sorting": "",
      "sort-by": _vm.newParams.order_by,
      "sort-desc": _vm.newParams.order_desc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(created)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.created) + " ")];
      }
    }, {
      key: "cell(agreement)",
      fn: function fn(row) {
        return [_c('CompanyPaymentItemAgreement', {
          attrs: {
            "item": row.item,
            "tag": "b-button",
            "tag-props": {
              variant: 'success',
              size: 'sm'
            }
          }
        }, [_vm._v(" View Agreement ")])];
      }
    }, {
      key: "cell(receipt)",
      fn: function fn(row) {
        var _row$item$charges$dat;

        return [row.item.charges.data.length ? _c('b-button', {
          attrs: {
            "variant": "success",
            "size": "sm",
            "href": (_row$item$charges$dat = row.item.charges.data[0]) === null || _row$item$charges$dat === void 0 ? void 0 : _row$item$charges$dat.receipt_url,
            "target": "_blank"
          }
        }, [_vm._v(" View Receipt ")]) : [_vm._v(" No Receipt ")]];
      }
    }, {
      key: "cell(details)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" $" + _vm._s(row.item.amount / 100) + " ")]), row.item.company_app_subscription ? _c('div', [_vm._v(" " + _vm._s(row.item.company_app_subscription.company_app_plan.description) + " ")]) : row.item.company_product_purchase ? _c('div', [_vm._v(" " + _vm._s(row.item.company_product_purchase.quantity) + " x " + _vm._s(row.item.company_product_purchase.company_product.description) + " ")]) : row.item.curriculum_product_installment ? _c('div', [_vm._v(" " + _vm._s(row.item.curriculum_product_installment.curriculum_product.description) + " ")]) : _vm._e()];
      }
    }])
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }