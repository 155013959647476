<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-tabs
      active-nav-item-class="bg-tab text-primary"
      nav-class="border-0"
    >
      <template
        v-if="newResult.data.length"
        #tabs-end
      >
        <TrackerMealItemUpsertButton
          class="px-0"
          tag="b-nav-item"
          :tag-props="{
            role: 'link',
            linkClasses: 'border-0'
          }"
          role="presentation"
          :item="{ nutrition_id: newParams.filters.nutrition_id}"
          @submitted="$emit('submitted', $event)"
        >
          + Add
        </TrackerMealItemUpsertButton>
      </template>

      <template
        v-else
        #empty
      >
        <b-alert
          show
          class="text-center"
          variant="warning"
        >
          <TrackerMealItemUpsertButton
            class="px-0"
            tag="b-button"
            :tag-props="{
              variant: 'link'
            }"
            :item="{ nutrition_id: newParams.filters.nutrition_id}"
            @submitted="$emit('submitted', $event)"
          >
            + Add meal
          </TrackerMealItemUpsertButton>
        </b-alert>
      </template>

      <b-tab
        v-for="meal in newResult.data"
        :key="meal.id"
        class="p-2 bg-tab"
        title-link-class="rounded-0 border-0"
        :title="meal.name"
      >
        <b-row
          class="mb-2"
          align-v="center"
        >
          <b-col cols="6">
            <div class="d-flex">
              <TrackerMealItemUpsertButton
                :item="meal"
                :style="{
                  cursor: 'pointer'
                }"
                @submitted="$emit('submitted', $event)"
              >
                <b-form-timepicker
                  :value="meal.taken_at"
                  class="w-130px"
                  locale="en"
                  disabled
                />
              </TrackerMealItemUpsertButton>

              <div class="mr-2 d-flex align-items-center ml-2">
                <b-badge
                  class="p-1"
                  variant="success"
                >
                  <MealTypes
                    :ids="[meal.type]"
                  />
                </b-badge>
              </div>
            </div>
          </b-col>

          <b-col
            cols="6"
            class="text-right"
          >
            <TrackerMealItemUpsertButton
              :item="meal"
              tag="b-button"
              :tag-props="{
                variant: 'primary',
                squared: true,
                size: 'sm'
              }"
              @submitted="$emit('submitted', $event)"
            >
              <b-icon-pencil />
            </TrackerMealItemUpsertButton>

            <TrackerMealItemDeleteButton
              v-if="!meal.curriculum_meal"
              :item="meal"
              class="ml-2"
              tag="b-button"
              :tag-props="{
                variant: 'outline-danger',
                squared: true,
                size: 'sm'
              }"
              @delete:item="$emit('delete:item', $event)"
            >
              <b-icon-trash />
            </TrackerMealItemDeleteButton>
          </b-col>
        </b-row>

        <TrackerMealFoodList
          :meal="meal"
          :params="{
            filters: { meal_id: meal.id }
          }"
          :result="{
            data: meal.meal_foods
          }"
          :prefetch="false"
          @submitted:item="$emit('submitted:meal-food', $event)"
          @delete:item="$emit('delete:meal-food', $event)"
        />
      </b-tab>
    </b-tabs>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import MealTypes from '@/components/v1/asset/MealTypes'
import TrackerMealItemUpsertButton from './buttons/TrackerMealItemUpsertButton'
import TrackerMealItemDeleteButton from './buttons/TrackerMealItemDeleteButton'
import TrackerMealFoodList from './TrackerMealFoodList'

export default {
  name: 'TrackerMealList',

  mixins: [
    crudListMixin
  ],

  components: {
    MealTypes,
    TrackerMealItemUpsertButton,
    TrackerMealItemDeleteButton,
    TrackerMealFoodList
  },

  computed: {
    baseEndpoint() {
      return 'tracker-meals'
    }
  },

  methods: {
    calcMealFoodMacro(mealFood, type) {
      const mealFoodMacro = Math.round(mealFood.amount * (mealFood.food.serving_size || 1) * mealFood.food[type]) / 100
      const decimalPlaces = type === 'calories' ? 0 : 1
      return Math.round(mealFoodMacro * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
    },
    calcMealMacro(meal, type) {
      return _.chain(meal)
        .get('meal_foods')
        .map(i => this.calcMealFoodMacro(i, type))
        .sum()
        .round(2)
        .value()
    },

    calcMealCalories(meal) {
      return _.chain(['protein', 'carbs', 'fat'])
        .map(i => this.getCaloriesByGrams(this.calcMealMacro(meal, i), i))
        .sum()
        .round(2)
        .value()
    },

    getCaloriesByGrams(value, type) {
      const caloriesPerGram = type === 'fat' ? 9 : 4
      const calories = Math.round(parseFloat(value || '0') * caloriesPerGram * 100) / 100

      return calories
    }
  }
}
</script>