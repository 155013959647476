<template>
  <div class="overflow-hidden">
    <b-row class="min-vh-100">
      <b-col
        cols="12"
        lg="4"
      >
        <div class="py-5 px-4">
          <b-img
            class="mb-5"
            center
            :src="require('@/assets/images/logo.png')"
            width="175"
            fluid
          />

          <h1 class="font-size-18 text-center">
            Reset Your Password
          </h1>

          <PasswordResetConfirm
            class="mx-auto"
            :style="{
              maxWidth: '400px'
            }"
            @submitted="onSubmitted"
          />

          <b-button
            class="mt-1"
            to="/login"
            variant="link"
            block
          >
            Return to login
          </b-button>
        </div>
      </b-col>

      <b-col
        :style="{
          background: `url(${require('@/assets/images/bg.jpg')})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        }"
      />
    </b-row>
  </div>
</template>

<script>
import PasswordResetConfirm from '@/components/auth/PasswordResetConfirm'

export default {
  name: 'PasswordResetConfirmPage',

  components: {
    PasswordResetConfirm
  },

  methods: {
    async onSubmitted() {
      this.$router.push('/account')
    }
  }
}
</script>