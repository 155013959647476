var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('ScheduleInput', {
    attrs: {
      "events": _vm.events,
      "bg-events": _vm.daySchedule,
      "start-date": _vm.$moment(_vm.date).toDate(),
      "days-count": 1,
      "show-days": false,
      "paused-at": _vm.curriculum.paused_at ? _vm.$moment(_vm.curriculum.paused_at).toDate() : null,
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "event-actions",
      fn: function fn(_ref) {
        var event = _ref.event,
            toggleEventIsActive = _ref.toggleEventIsActive;
        return [_vm.curriculum.status === 'active' || event.is_performed ? [event.type === 'workout' ? _c('b-dropdown', {
          attrs: {
            "toggle-class": "p-0 text-white",
            "variant": "link",
            "size": "sm"
          },
          on: {
            "shown": function shown($event) {
              return toggleEventIsActive(true);
            },
            "hidden": function hidden($event) {
              return toggleEventIsActive(false);
            }
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('b-icon', {
                staticClass: "no-drag",
                attrs: {
                  "icon": "three-dots"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('TrackerWorkoutItemUpsertButton', {
          attrs: {
            "item": {
              id: event.id
            },
            "tag": "b-dropdown-item-button"
          },
          on: {
            "submitted": _vm.fetchEvents
          }
        }, [_vm._v(" Workout Tracker ")])], 1) : _vm._e(), event.type === 'cardio' ? _c('b-dropdown', {
          attrs: {
            "toggle-class": "p-0 text-white",
            "variant": "link",
            "size": "sm"
          },
          on: {
            "shown": function shown($event) {
              return toggleEventIsActive(true);
            },
            "hidden": function hidden($event) {
              return toggleEventIsActive(false);
            }
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('b-icon', {
                staticClass: "no-drag",
                attrs: {
                  "icon": "three-dots"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('TrackerCardioItemUpsertButton', {
          attrs: {
            "item": {
              id: event.id
            },
            "tag": "b-dropdown-item-button"
          },
          on: {
            "submitted": _vm.fetchEvents
          }
        }, [_vm._v(" Cardio Tracker ")])], 1) : _vm._e()] : _vm._e()];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }