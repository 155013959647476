var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching && !_vm.newResult.data
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "striped": "",
      "stacked": "sm"
    }
  }, [_c('b-tbody', _vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-tr', {
      key: item.id
    }, [_c('b-td', {
      staticClass: "w-30px align-middle text-center"
    }, [_c('span', {
      style: {
        cursor: 'move'
      }
    }, [_c('b-icon-grid3x2-gap')], 1)]), _c('b-td', {
      staticClass: "w-150px align-middle"
    }, [_c('ExerciseItemDetailButton', {
      staticClass: "text-decoration-underline-dotted",
      style: {
        cursor: 'pointer'
      },
      attrs: {
        "item": item.exercise
      }
    }, [_vm._v(" " + _vm._s(item.exercise.name) + " ")])], 1), _c('b-td', {
      staticClass: "align-middle"
    }, [_c('ExerciseSetsInput', {
      model: {
        value: _vm.input.items[itemIndex].sets,
        callback: function callback($$v) {
          _vm.$set(_vm.input.items[itemIndex], "sets", $$v);
        },
        expression: "input.items[itemIndex].sets"
      }
    })], 1), _c('b-td', {
      staticClass: "w-50px text-center align-middle"
    }, [_c('TrackerExerciseItemDeleteButton', {
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link',
          size: 'sm'
        }
      },
      on: {
        "delete:item": _vm.refresh
      }
    }, [_c('b-icon-trash')], 1)], 1)], 1);
  }), 1)], 1), _vm.isSubmitting || _vm.isFetching ? _c('div', {
    style: {
      position: 'absolute',
      top: '15px',
      right: '15px'
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1) : _vm._e(), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }