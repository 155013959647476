<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="popupIsShown = true"
  >
    <slot />

    <b-modal
      v-model="popupIsShown"
      content-class="rounded-0 border-0"
      size="lg"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-4 border-0"
      body-class="p-0"
      hide-footer
      title="Supplement Program"
    >
      <SupplementProgramItemDetail
        :item="item"
        :prefetch="prefetch"
        @update:item="$emit('update:item', $event)"
        @submitted="$emit('submitted', $event); popupIsShown = false"
      />
    </b-modal>
  </component>
</template>

<script>
import SupplementProgramItemDetail from '../SupplementProgramItemDetail'

export default {
  name: 'SupplementProgramItemButton',

  components: {
    SupplementProgramItemDetail
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    prefetch: {
      type: Boolean,
      default: false
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      popupIsShown: false
    }
  }
}
</script>

