<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-row class="mb-2">
      <b-col
        xs="12"
        md="3"
      >
        <b-input
          v-model="newParams.filters.search"
          placeholder="Search foods by name..."
        />
      </b-col>
    </b-row>

    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(actions)="row">
        <FoodItemUpsertButton
          :item="row.item"
          tag="b-button"
          class="p-0 mr-3"
          :tag-props="{
            variant: 'link',
            size: 'sm'
          }"
          @submitted="refresh"
        >
          <b-icon-gear />
        </FoodItemUpsertButton>

        <FoodItemDeleteButton
          :item="row.item"
          tag="b-button"
          class="p-0"
          :tag-props="{
            variant: 'link',
            size: 'sm'
          }"
          @deleted:item="refresh"
        >
          <b-icon-trash />
        </FoodItemDeleteButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import FoodItemUpsertButton from './buttons/FoodItemUpsertButton'
import FoodItemDeleteButton from './buttons/FoodItemDeleteButton'

export default {
  name: 'FoodList',

  mixins: [
    crudListMixin
  ],

  components: {
    FoodItemUpsertButton,
    FoodItemDeleteButton
  },

  data() {
    return {
      fields: [
        { key: 'name', sortable: true },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  },

  computed: {
    baseEndpoint() {
      return 'foods'
    }
  }
}
</script>