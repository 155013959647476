var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table-simple', {
    staticClass: "input-table mb-0",
    attrs: {
      "fixed": ""
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', {
    staticClass: "text-center bg-transparent border-0 w-100px"
  }), _c('b-th', [_vm._v("Exercise")]), _c('b-th', [_vm._v("Muscle")]), _c('b-th', [_vm._v("Time per Set")])], 1)], 1), _vm._l(_vm.value, function (exerciseGroup, exerciseGroupIndex) {
    return _c('b-tbody', {
      key: exerciseGroupIndex
    }, [!exerciseGroup.exercises || !exerciseGroup.exercises.length ? _c('b-tr', [_c('b-th', {
      staticClass: "text-center w-100px"
    }, [_vm._v(" " + _vm._s(exerciseGroup.name) + " ")]), _c('b-td'), _c('b-td'), _c('b-td')], 1) : _vm._l(exerciseGroup.exercises, function (exercise, exerciseIndex) {
      return _c('b-tr', {
        key: exerciseIndex
      }, [exerciseIndex === 0 ? _c('b-th', {
        staticClass: "text-center w-100px",
        attrs: {
          "rowspan": exerciseGroup.exercises.length
        }
      }, [_vm._v(" " + _vm._s(exerciseGroup.name) + " ")]) : _vm._e(), _c('b-td', [_vm._v(" " + _vm._s(exercise.name) + " ")]), _c('b-td', [_c('Muscles', {
        attrs: {
          "ids": exercise.muscle_ids
        }
      })], 1), _c('b-td', [_vm._v(" " + _vm._s(exercise.estimated_set_time) + " ")])], 1);
    })], 2);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }