<template>
  <b-overlay
    :show="isFetching"
  >
    <b-tabs
      active-nav-item-class="bg-tab text-primary"
      nav-class="border-0"
      justified
      no-fade
    >
      <b-tab
        v-for="phase in newItem.phases"
        :key="phase.id"
        lazy
        title-link-class="rounded-0 border-0"
        :title="`Phase ${phase.number}`"
      >
        <CurriculumPhaseScheduleDetail
          :item="phase"
          :curriculum="newItem"
        />
      </b-tab>
    </b-tabs>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import CurriculumPhaseScheduleDetail from './CurriculumPhaseScheduleDetail'

export default {
  name: 'CurriculumItemScheduleDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumPhaseScheduleDetail
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  }
}
</script>