<template>
  <div id="app">
    <Loader />
    <Alert />
    <RouterView :key="key" />
  </div>
</template>

<script>
import Loader from './components/loader'
import Alert from './components/alerts'

export default {
  name: "app",

  page() {
    return  {
      titleTemplate: (i) => i ? `${i} | ${this.appName}` : this.appName
    }
  },

  components: {
    Loader,
    Alert
  },

  beforeCreate () {
    this.$store.commit('layout/setLoader', false)
  },

  data() {
    return {
      key: + new Date()
    }
  },

  computed: {
    uid() {
      return this._uid
    },

    appName() {
      return process.env.VUE_APP_NAME
    },

    aclRoles() {
      return this.$store.getters['auth/aclRoles']
    }
  },

  watch: {
    aclRoles: {
      immediate: true,
      deep: true,
      handler() {
        // If there is current route - check permissions
        if (this.$route.name) {
          // If unpaid user
          if (this.aclRoles.includes('role_1') && !this.aclRoles.includes('paid')) {
            if (this.$router.currentRoute.meta.rule === 'couldUseApp') {
              window.console.log('redirect to account')
              this.$router.push({ name: 'account' })
              return
            }
          }

          // If no user
          if (this.aclRoles.includes('public')) {
            if (this.$router.currentRoute.meta.rule !== 'isPublic') {
              window.console.log('redirect to login')
              this.$acl.change(this.aclRoles)
              this.$router.push({ name: 'login' })
              return
            }
          }
        }

        this.$acl.change(this.aclRoles)
      }
    }
  }
};
</script>
