var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-card', {
    staticClass: "m-0",
    style: {
      overflow: 'scroll',
      width: '100%'
    }
  }, [_c('b-form', {
    staticClass: "image-background",
    style: {
      'background-image': 'url(' + require('@/assets/images/body/MusclesMale.png') + ')'
    },
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-row', [_c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Height",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.height),
      "state": _vm.getFieldState(_vm.$v.input.height)
    }
  }, [_c('HeightInput', {
    attrs: {
      "state": _vm.getFieldState(_vm.$v.input.height)
    },
    model: {
      value: _vm.input.height,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "height", $$v);
      },
      expression: "input.height"
    }
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "Weight",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.weight),
      "state": _vm.getFieldState(_vm.$v.input.weight)
    }
  }, [_c('b-input-group', {
    attrs: {
      "append": "lb"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.weight)
    },
    model: {
      value: _vm.input.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "weight", $$v);
      },
      expression: "input.weight"
    }
  })], 1)], 1)], 1)], 1), _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "main-content-measurements"
  }, [_c('div', [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "wrap-left-inputs"
  }, [_c('div', {
    staticClass: "wrap-form-body"
  }, [_c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Neck (inch)",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.neck)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.neck)
    },
    model: {
      value: _vm.input.neck,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "neck", $$v);
      },
      expression: "input.neck"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Subscapular (mms)",
      "label-class": "font-weight-bolder",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.subscapular)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.subscapular)
    },
    model: {
      value: _vm.input.subscapular,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "subscapular", $$v);
      },
      expression: "input.subscapular"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Chest (mms)",
      "label-class": "font-weight-bolder",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.chest)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.chest)
    },
    model: {
      value: _vm.input.chest,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "chest", $$v);
      },
      expression: "input.chest"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Right tricep (mms)",
      "label-class": "font-weight-bolder",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.tricep)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.tricep)
    },
    model: {
      value: _vm.input.tricep,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "tricep", $$v);
      },
      expression: "input.tricep"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Waist (inch)",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.waist)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.waist)
    },
    model: {
      value: _vm.input.waist,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "waist", $$v);
      },
      expression: "input.waist"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Suprailiac (mms)",
      "label-class": "font-weight-bolder",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.suprailiac)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.suprailiac)
    },
    model: {
      value: _vm.input.suprailiac,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "suprailiac", $$v);
      },
      expression: "input.suprailiac"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Thigh (mms)",
      "label-class": "font-weight-bolder",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.thigh)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.thigh)
    },
    model: {
      value: _vm.input.thigh,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "thigh", $$v);
      },
      expression: "input.thigh"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Left calf (inch)",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.calf_l)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.calf_l)
    },
    model: {
      value: _vm.input.calf_l,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "calf_l", $$v);
      },
      expression: "input.calf_l"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "row-reversed",
    attrs: {
      "label": "Right calf (inch)",
      "label-cols": "8",
      "label-align": "left",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.calf_r)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.calf_r)
    },
    model: {
      value: _vm.input.calf_r,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "calf_r", $$v);
      },
      expression: "input.calf_r"
    }
  })], 1)], 1)], 1)]), _c('div', {
    staticClass: "wrap-right-inputs"
  }, [_c('div', {
    staticClass: "wrap-form-body"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Chest (inch)",
      "label-cols": "8",
      "label-align": "right",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.chest_inches)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.chest_inches)
    },
    model: {
      value: _vm.input.chest_inches,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "chest_inches", $$v);
      },
      expression: "input.chest_inches"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Right bicep (inch)",
      "label-cols": "8",
      "label-align": "right",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.arm_r)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.arm_r)
    },
    model: {
      value: _vm.input.arm_r,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "arm_r", $$v);
      },
      expression: "input.arm_r"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Left bicep (inch)",
      "label-cols": "8",
      "label-align": "right",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.arm_l)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.arm_l)
    },
    model: {
      value: _vm.input.arm_l,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "arm_l", $$v);
      },
      expression: "input.arm_l"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Midaxillary (mms)",
      "label-class": "font-weight-bolder",
      "label-cols": "8",
      "label-align": "right",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.midaxillary)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.midaxillary)
    },
    model: {
      value: _vm.input.midaxillary,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "midaxillary", $$v);
      },
      expression: "input.midaxillary"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Hips (inch)",
      "label-cols": "8",
      "label-align": "right",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.hips)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.hips)
    },
    model: {
      value: _vm.input.hips,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "hips", $$v);
      },
      expression: "input.hips"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Abdominal (mms)",
      "label-class": "font-weight-bolder",
      "label-cols": "8",
      "label-align": "right",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.abdominal)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.abdominal)
    },
    model: {
      value: _vm.input.abdominal,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "abdominal", $$v);
      },
      expression: "input.abdominal"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Left thigh (inch)",
      "label-cols": "8",
      "label-align": "right",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.thigh_l)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.thigh_l)
    },
    model: {
      value: _vm.input.thigh_l,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "thigh_l", $$v);
      },
      expression: "input.thigh_l"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Right thigh (inch)",
      "label-cols": "8",
      "label-align": "right",
      "content-cols": "4",
      "state": _vm.getFieldState(_vm.$v.input.thigh_r)
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "type": "number",
      "step": "0.25",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.thigh_r)
    },
    model: {
      value: _vm.input.thigh_r,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "thigh_r", $$v);
      },
      expression: "input.thigh_r"
    }
  })], 1)], 1)], 1)])])]), _c('div', {
    staticClass: "wrap-bottom-texts"
  }, [_c('div', {
    staticClass: "empty-block"
  }), _c('div', {
    staticClass: "wrap-mass-fat-headers"
  }, [_c('div', {
    staticClass: "wrap-anfas-text"
  }, [_c('div', {
    staticClass: "local-header"
  }, [_vm._v(" Lean Mass ")]), _c('div', [_vm._v(" " + _vm._s(_vm.input.lean_mass) + " ")])]), _c('div', {
    staticClass: "wrap-profile-text"
  }, [_c('div', {
    staticClass: "local-header"
  }, [_vm._v(" Body Fat % ")]), _c('div', [_vm._v(" " + _vm._s(_vm.input.total_body_fat_p) + " ")])])]), _c('div', {
    staticClass: "wrap-footer-text-measurement"
  }, [_c('div', {
    staticClass: "font-weight-bolder"
  }, [_vm._v(" Caliper Measurement ")]), _c('div', [_vm._v(" Tape Measurement ")])])])])])], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    staticClass: "mr-2",
    attrs: {
      "disabled": _vm.isCalculating
    },
    on: {
      "click": _vm.calculateSubmit
    }
  }, [_vm.isCalculating ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Calculate ")], 1), _c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }