<template>
  <Layout>
    <b-overlay :show="isFetching">
      <template v-if="isFetched">
        <b-row>
          <b-col cols="5">
            <PageHeader :title="title" />
          </b-col>

          <b-col cols="7" class="d-flex align-items-center justify-content-end">
            <b-button-group>
              <b-button
                v-for="(step, stepIndex) in steps"
                :key="step.id"
                class="text-nowrap"
                :class="{
                  'active': stepIndex === currentStepIndex,
                  'completed': stepIndex < currentStepIndex
                }"
                :disabled="step.isDisabled"
                :to="`#${step.id}`"
              >
                {{ step.title }}
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col xl="3">
            <CurriculumItemProfileSummaryShort
              class="d-block d-xl-none"
              :item.sync="newItem"
            />

            <CurriculumItemProfileSummary
              class="d-none d-xl-block"
              :item.sync="newItem"
            />
          </b-col>

          <b-col xl="9">
            <b-card
              class="bg-transparent"
              body-class="py-0 px-0"
            >
              <CurriculumStatus
                v-if="['completed', 'canceled'].includes(newItem.status)"
                class="mr-1"
                :item="newItem"
              />

              <CurriculumItemPreviewUpdate
                v-else-if="currentStep.id === 'preview'"
                :key="$route.fullPath"
                :item.sync="newItem"
                :prefetch="false"
                @submitted="onNextStep"
              />

              <CurriculumItemWorkoutsUpdate
                v-else-if="currentStep.id === 'program'"
                :key="$route.fullPath"
                :item.sync="newItem"
                :prefetch="false"
                mode="program"
                @submitted="onNextStep"
              />

              <CurriculumItemWorkoutsUpdate
                v-else-if="currentStep.id === 'schedule'"
                :key="$route.fullPath"
                :item.sync="newItem"
                :prefetch="false"
                mode="data"
                @submitted="onNextStep"
              />

              <CurriculumItemCardioUpdate
                v-else-if="currentStep.id === 'cardio'"
                :key="$route.fullPath"
                :item.sync="newItem"
                :prefetch="false"
                mode="data"
                @submitted="onNextStep"
              />

              <CurriculumItemSupplementsUpdate
                v-else-if="currentStep.id === 'supplements'"
                :item.sync="newItem"
                :prefetch="false"
                @submitted="onNextStep"
              />

              <CurriculumItemNutritionUpdate
                v-else-if="currentStep.id === 'nutrition'"
                :item.sync="newItem"
                :prefetch="false"
                @submitted="onNextStep"
              />

              <CurriculumItemReview
                v-else-if="currentStep.id === 'review'"
                :item.sync="newItem"
                :prefetch="false"
                @submitted="onNextStep"
              />
            </b-card>
          </b-col>
        </b-row>
      </template>
    </b-overlay>
  </Layout>
</template>

<script>
import _ from 'lodash'
import crudItem from '@/mixins/crud-item'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import CurriculumItemProfileSummary from '@/components/curriculums/CurriculumItemProfileSummary'
import CurriculumItemProfileSummaryShort from '@/components/curriculums/CurriculumItemProfileSummaryShort'
import CurriculumStatus from '@/components/curriculums/CurriculumStatus'

import CurriculumItemPreviewUpdate from '@/components/curriculums/CurriculumItemPreviewUpdate'
import CurriculumItemWorkoutsUpdate from '@/components/curriculums/CurriculumItemWorkoutsUpdate'
import CurriculumItemSupplementsUpdate from '@/components/curriculums/CurriculumItemSupplementsUpdate'
import CurriculumItemNutritionUpdate from '@/components/curriculums/CurriculumItemNutritionUpdate'
import CurriculumItemCardioUpdate from '@/components/curriculums/CurriculumItemCardioUpdate'
import CurriculumItemReview from '@/components/curriculums/CurriculumItemReview'

export default {
  name: 'CurriculumBuildPage',

  mixins: [
    crudItem
  ],

  components: {
    Layout,
    PageHeader,
    CurriculumItemProfileSummary,
    CurriculumItemProfileSummaryShort,
    CurriculumItemPreviewUpdate,
    CurriculumItemWorkoutsUpdate,
    CurriculumItemSupplementsUpdate,
    CurriculumItemNutritionUpdate,
    CurriculumItemCardioUpdate,
    CurriculumItemReview,
    CurriculumStatus
  },

  page() {
    return {
      title: this.title
    }
  },

  props: {
    item: {
      type: Object,

      default: function() {
        const id = parseInt(this.$route.params.id)
        return { id }
      }
    },

    prefetch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  computed: {
    title() {
      const title = this.status === 'designing' ? 'Curriculum Build' : 'Curriculum Assessments'
      const subtitle = _.get(this.steps, `${this.currentStepIndex}.title`)
      return `${title} - ${subtitle}`
    },

    isStarted() {
      return (
        this.newItem.status !== 'designing' &&
        this.$moment().startOf('day') >= this.$moment(this.newItem.start_date)
      )
    },

    steps() {
      const steps = [
        { id: 'preview', title: 'Preview', isVisible: true },
        { id: 'program', title: 'Workout Program', isVisible: true },
        { id: 'schedule', title: 'Workout Schedule', isVisible: true },
        { id: 'cardio', title: 'Cardio', isVisible: true },
        { id: 'supplements', title: 'Supplements', isVisible: true },
        { id: 'nutrition', title: 'Nutrition', isVisible: true },
        { id: 'review', title: 'Review', isVisible: true },
      ]

      return _.filter(steps, { isVisible: true })
    },

    currentStepIndex: {
      get() {
        const index = _.findIndex(this.steps, { id: this.$route.hash.replace('#', '') })
        return index >= 0 ? index : 0
      },

      set(index) {
        const path = `/curriculums/${this.newItem.id}`
        const step = _.get(this.steps, `${index}.id`) || 'preview'
        this.$router.push({ path: path, hash: `#${step}` })
      }
    },
  
    currentStep() {
      return this.steps[this.currentStepIndex]
    }
  },

  methods: {
    onPrevStep() {
      this.currentStepIndex -= 1
    },

    onNextStep() {
      this.currentStepIndex += 1
    }
  },

  watch: {
    '$route.hash': {
      immediate: true,
      deep: true,
      handler() {
        this.refresh()
      }
    }
  }
};
</script>

<style scoped>
  .btn-group .btn.active {
    background: #626ed4 !important;
    /*border-color: #626ed4 !important;*/
  }
  .btn-group .btn.completed {
    background: #02a499 !important;
    /*border-color: #02a499 !important;*/
  }
  .client-summary-card {
    background: #d7dbf4;
  }
  
  .client-summary-card .card-header {
    background: inherit;
    text-align: center;
  }
</style>
