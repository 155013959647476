<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <WaterIntakesItemUpsertButton
      :item="item"
      prefetch
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-12'
      }"
      @update:item="$emit('update:item', $event)"
    >
      Edit
    </WaterIntakesItemUpsertButton>

    <WaterIntakesItemDeleteButton
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-12'
      }"
      @delete:item="$emit('delete:item', $event)"
    >
      Delete
    </WaterIntakesItemDeleteButton>
  </b-dropdown>
</template>

<script>
import WaterIntakesItemUpsertButton from './WaterIntakesItemUpsertButton'
import WaterIntakesItemDeleteButton from './WaterIntakesItemDeleteButton'

export default {
  name: 'WaterIntakesItemActionsButton',

  components: {
    WaterIntakesItemUpsertButton,
    WaterIntakesItemDeleteButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  }
}
</script>