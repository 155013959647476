var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "items": _vm.newResult.data,
      "fields": _vm.fields,
      "stacked": "sm",
      "sort-by": _vm.newParams.order_by,
      "sort-desc": _vm.newParams.order_desc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(start_time)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.start_time ? _vm.$moment(row.item.start_time).format('YYYY-MM-DD') : '-') + " ")];
      }
    }, {
      key: "cell(log_time)",
      fn: function fn(row) {
        return [_vm._v(" " + _vm._s(row.item.log_time ? _vm.$moment(row.item.log_time).format('YYYY-MM-DD') : '-') + " ")];
      }
    }, {
      key: "cell(hit_time)",
      fn: function fn(row) {
        return [_c('TimeInput', {
          attrs: {
            "value": row.item.hit_time,
            "disabled": ""
          }
        })];
      }
    }, {
      key: "cell(total_time)",
      fn: function fn(row) {
        return [_c('TimeInput', {
          attrs: {
            "value": row.item.total_time,
            "disabled": ""
          }
        })];
      }
    }, {
      key: "cell(low_intensity_time)",
      fn: function fn(row) {
        return [_c('TimeInput', {
          attrs: {
            "value": row.item.low_intensity_time,
            "disabled": ""
          }
        })];
      }
    }, {
      key: "cell(machine_id)",
      fn: function fn(row) {
        return [_c('CardioMachines', {
          attrs: {
            "ids": [row.item.machine_id || '-']
          }
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('TrackerCardioItemUpsertButton', {
          attrs: {
            "item": row.item,
            "tag": "b-button",
            "tag-props": {
              variant: 'link',
              size: 'sm'
            }
          },
          on: {
            "submitted": _vm.refresh
          }
        }, [_c('b-icon-pencil')], 1)];
      }
    }])
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }