var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', {
    staticClass: "client-summary-card",
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('b-avatar', {
          staticClass: "mb-3",
          attrs: {
            "src": _vm.item.client.profile_img_url,
            "size": "10rem"
          }
        }), _c('div', {
          staticClass: "client-summary-name"
        }, [_vm._v(" " + _vm._s(_vm.item.client.first_name) + " " + _vm._s(_vm.item.client.last_name) + " ")]), _c('CurriculumItemClientNotesUpdateButton', {
          staticClass: "mt-3",
          attrs: {
            "item": _vm.item,
            "tag": "b-button",
            "prefetch": "",
            "tag-props": {
              variant: 'primary',
              size: 'sm'
            }
          },
          on: {
            "update:item": function updateItem($event) {
              return _vm.$emit('update:item', $event);
            }
          }
        }, [_vm._v(" Client Notes ")]), _c('CurriculumStatus', {
          style: {
            position: 'absolute',
            top: '10px',
            right: '10px'
          },
          attrs: {
            "item": _vm.item
          }
        })];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "mb-2 text-uppercase"
  }, [_vm._v(" Client info ")]), _c('div', [_c('strong', [_vm._v("Age")]), _vm._v(": " + _vm._s(_vm.age) + " ")]), _c('div', [_c('strong', [_vm._v("Gender")]), _vm._v(": "), _c('Genders', {
    attrs: {
      "ids": [_vm.item.profile.sex]
    }
  })], 1), _c('div', [_c('strong', [_vm._v("Height")]), _vm._v(": " + _vm._s(_vm.item.physical_condition.height || '-'))]), _c('div', [_c('strong', [_vm._v("Current Weight")]), _vm._v(": " + _vm._s(_vm.item.physical_condition.weight || '-'))]), _c('div', [_c('strong', [_vm._v("Level")]), _vm._v(": "), _c('Abilities', {
    attrs: {
      "ids": [_vm.item.profile.fitness_level]
    }
  })], 1)]), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "mb-2 text-uppercase"
  }, [_vm._v(" Client goals ")]), _c('div', [_c('strong', [_vm._v("Primary Goal")]), _vm._v(": "), _c('GoalDirections', {
    attrs: {
      "ids": [_vm.item.profile.goal_direction]
    }
  })], 1), _c('div', [_c('strong', [_vm._v("Goal Weight")]), _vm._v(": " + _vm._s(_vm.item.profile.goal_weight || '-'))]), _c('div', [_c('strong', [_vm._v("Competition Weight")]), _vm._v(": " + _vm._s(_vm.item.profile.competition_weight || '-'))]), _c('div', [_c('strong', [_vm._v("Term")]), _vm._v(": " + _vm._s(_vm.item.term) + " weeks")]), _c('div', [_c('strong', [_vm._v("Major Working Points")]), _vm._v(": ")]), _vm._l(_vm.item.profile.working_points, function (working_point) {
    return _c('div', {
      key: 'working_point-' + working_point
    }, [_vm._v(" - "), _c('WorkingPoints', {
      attrs: {
        "ids": [working_point]
      }
    })], 1);
  }), _c('div', [_c('strong', [_vm._v("Notes")]), _vm._v(": " + _vm._s(_vm.item.profile.fitness_notes || '-'))])], 2), _vm.isStarted ? _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "mb-2 text-uppercase"
  }, [_vm._v(" Progress Notes ")]), _vm._l(_vm.item.phases, function (phase) {
    return _c('div', {
      key: phase.number
    }, [_c('div', [_c('strong', [_vm._v("Phase " + _vm._s(phase.number))])]), _c('div', {
      staticClass: "mb-2"
    }, [_vm.$moment().startOf('day') >= _vm.$moment(phase.start_date) ? _c('CurriculumPhaseStats', {
      attrs: {
        "item": phase
      }
    }) : _c('div', [_vm._v(" Not started ")])], 1)]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "mb-2 text-uppercase"
  }, [_vm._v(" History ")]), _c('div', [_c('strong', [_vm._v("History or currently experiencing")]), _vm._v(": ")]), _vm._l(_vm.item.profile.history1, function (history1_item) {
    return _c('div', {
      key: 'history1_item-' + history1_item
    }, [_vm._v(" - "), _c('History1', {
      attrs: {
        "ids": [history1_item]
      }
    })], 1);
  }), _vm.item.profile.history1_notes ? _c('div', [_vm._v(" " + _vm._s(_vm.item.profile.history1_notes) + " ")]) : _vm._e(), _c('div', [_c('strong', [_vm._v("History of pulls, strains, fractures, surgery, discomfort")]), _vm._v(": ")]), _vm._l(_vm.item.profile.history2, function (history2_item) {
    return _c('div', {
      key: 'history2_item-' + history2_item
    }, [_vm._v(" - "), _c('History2', {
      attrs: {
        "ids": [history2_item]
      }
    })], 1);
  }), _vm.item.profile.history2_notes ? _c('div', [_vm._v(" " + _vm._s(_vm.item.profile.history2_notes) + " ")]) : _vm._e(), _c('div', [_c('strong', [_vm._v("Current Medication")]), _vm._v(": " + _vm._s(_vm.item.profile.medication_notes || '-'))]), _c('div', [_c('strong', [_vm._v("Workout History")]), _vm._v(": " + _vm._s(_vm.item.profile.workout_history || '-'))])], 2), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "mb-2 text-uppercase"
  }, [_vm._v(" Dietary restrictions/preferences ")]), _c('div', [_c('strong', [_vm._v("Diets:")])]), _vm._l(_vm.item.profile.dietary_restrictions, function (dietary_restriction) {
    return _c('div', {
      key: 'dietary_restriction-' + dietary_restriction
    }, [_vm._v(" - "), _c('DietaryRestrictions', {
      attrs: {
        "ids": [dietary_restriction]
      }
    })], 1);
  }), _vm.item.profile.dietary_restriction_notes ? _c('div', [_vm._v(" " + _vm._s(_vm.item.profile.dietary_restriction_notes) + " ")]) : _vm._e(), _vm.item.profile.dietary_notes ? _c('div', [_vm._v(" " + _vm._s(_vm.item.profile.dietary_notes) + " ")]) : _vm._e()], 2), _c('div', {
    staticClass: "mb-4"
  }, [_c('div', {
    staticClass: "mb-2 text-uppercase"
  }, [_vm._v(" Contact info ")]), _vm.item.client.address_line1 ? _c('div', [_vm._v(_vm._s(_vm.item.client.address_line1))]) : _vm._e(), _vm.item.client.address_line2 ? _c('div', [_vm._v(_vm._s(_vm.item.client.address_line2))]) : _vm._e(), _c('div', [_vm._v(_vm._s(_vm.item.client.city) + ", " + _vm._s(_vm.item.client.state) + ", " + _vm._s(_vm.item.client.zip))]), _vm.item.client.email ? _c('div', [_vm._v(_vm._s(_vm.item.client.email))]) : _vm._e(), _vm.item.client.phone ? _c('div', [_vm._v(_vm._s(_vm.item.client.phone))]) : _vm._e(), _c('div', [_vm._v("Emergency Contact: " + _vm._s(_vm.item.client.emergency_phone ? "".concat(_vm.item.client.emergency_contact, ", ").concat(_vm.item.client.emergency_phone) : '-'))])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }