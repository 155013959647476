<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- FITNESS NOTES -->
      <b-form-group
        label="Fitness goals and general notes:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.fitness_notes)"
        :state="getFieldState($v.input.fitness_notes)"
      >
        <b-form-textarea
          v-model="input.fitness_notes"
          rows="5"
          :state="getFieldState($v.input.fitness_notes)"
        />
      </b-form-group>

      <!-- HISTORY 1 -->
      <b-form-group
        label="Do you have a history or currently experiencing (select all that apply):"
        :invalid-feedback="getFieldInvalidFeedback($v.input.history1)"
        :state="getFieldState($v.input.history1)"
      >
        <FormInputButtons
          v-model="input.history1"
          multiple
          button-class="mr-2 mb-1"
          :options="$store.getters['asset/getHistory1Options']"
        />
      </b-form-group>

      <!-- HISTORY 1 NOTES -->
      <b-form-group
        v-if="input.history1 && input.history1.length"
        label="If yes to any of the above, please provide details:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.history1_notes)"
        :state="getFieldState($v.input.history1_notes)"
      >
        <b-form-textarea
          v-model="input.history1_notes"
          rows="5"
          :state="getFieldState($v.input.history1_notes)"
        />
      </b-form-group>

      <!-- HISTORY 2 -->
      <b-form-group
        label="History of pulls, strains, fractures, surgery, discomfort (select all that apply):"
        :invalid-feedback="getFieldInvalidFeedback($v.input.history2)"
        :state="getFieldState($v.input.history2)"
      >
        <FormInputButtons
          v-model="input.history2"
          multiple
          button-class="mr-2 mb-1"
          :options="$store.getters['asset/getHistory2Options']"
        />
      </b-form-group>

      <!-- HISTORY 2 NOTES -->
      <b-form-group
        v-if="input.history2 && input.history2.length"
        label="Pulls, strains, fractures, surgery, and discomfort details:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.history2_notes)"
        :state="getFieldState($v.input.history2_notes)"
      >
        <b-form-textarea
          v-model="input.history2_notes"
          rows="5"
          :state="getFieldState($v.input.history2_notes)"
        />
      </b-form-group>

      <!-- MEDICATION NOTES -->
      <b-form-group
        label="Current medications (over-the-counter and prescribed, dosages, times administered, and experienced side effects):"
        :invalid-feedback="getFieldInvalidFeedback($v.input.medication_notes)"
        :state="getFieldState($v.input.medication_notes)"
      >
        <b-form-textarea
          v-model="input.medication_notes"
          rows="5"
          :state="getFieldState($v.input.medication_notes)"
        />
      </b-form-group>

      <!-- WORKING POINTS -->
      <b-form-group
        label="Major working points (select all that apply):"
        :invalid-feedback="getFieldInvalidFeedback($v.input.working_points)"
        :state="getFieldState($v.input.working_points)"
      >
        <FormInputButtons
          v-model="input.working_points"
          multiple
          button-class="mr-2 mb-1"
          :options="$store.getters['asset/getWorkingPointsOptions']"
        />
      </b-form-group>

      <!-- SOMATOTYPE -->
      <b-form-group
        label="What is your Somatotype?"
        :invalid-feedback="getFieldInvalidFeedback($v.input.somatotype)"
        :state="getFieldState($v.input.somatotype)"
      >
        <b-form-select
          v-model="input.somatotype"
          :options="$store.getters['asset/getSomatotypesOptions']"
          :state="getFieldState($v.input.somatotype)"
        />
      </b-form-group>

      <!-- FITNESS GOAL -->
      <b-form-group
        label="Fitness goal:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.goal_direction)"
        :state="getFieldState($v.input.goal_direction)"
      >
        <FormInputButtons
          v-model="input.goal_direction"
          tag="b-button-group"
          :options="$store.getters['asset/getGoalDirectionsOptions']"
        />
      </b-form-group>

      <!-- GOAL BODY MASS -->
      <b-form-group
        label="Goal Body Mass:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.goal_body_mass)"
        :state="getFieldState($v.input.goal_body_mass)"
      >
        <b-form-input
          v-model="input.goal_body_mass"
          type="number"
          number
          :state="getFieldState($v.input.goal_body_mass)"
        />
      </b-form-group>

      <!-- GOAL BODY FAT P -->
      <b-form-group
        label="Goal Body Fat (%):"
        :invalid-feedback="getFieldInvalidFeedback($v.input.goal_body_fat_p)"
        :state="getFieldState($v.input.goal_body_fat_p)"
      >
        <b-form-input
          v-model="input.goal_body_fat_p"
          type="number"
          number
          :state="getFieldState($v.input.goal_body_fat_p)"
        />
      </b-form-group>

      <!-- GOAL WEiGHT -->
      <b-form-group
        label="Goal Weight:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.goal_weight)"
        :state="getFieldState($v.input.goal_weight)"
      >
        <b-form-input
          v-model="input.goal_weight"
          type="number"
          number
          :state="getFieldState($v.input.goal_weight)"
        />
      </b-form-group>

      <!-- COMPETITION WEiGHT -->
      <b-form-group
        label="Competition Weight:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.competition_weight)"
        :state="getFieldState($v.input.competition_weight)"
      >
        <b-form-input
          v-model="input.competition_weight"
          type="number"
          number
          :state="getFieldState($v.input.competition_weight)"
        />
      </b-form-group>

      <!-- WORKOUT HISTORY -->
      <b-form-group
        label="Workout history:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.workout_history)"
        :state="getFieldState($v.input.workout_history)"
      >
        <b-form-textarea
          v-model="input.workout_history"
          rows="5"
          :state="getFieldState($v.input.workout_history)"
        />
      </b-form-group>

      <!-- FITNESS LEVEL -->
      <b-form-group
        label="Fitness level:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.fitness_level)"
        :state="getFieldState($v.input.fitness_level)"
      >
        <FormInputButtons
          v-model="input.fitness_level"
          tag="b-button-group"
          :options="$store.getters['asset/getAbilityLevelsOptions']"
        />
      </b-form-group>

      <!-- WORKOUTS PER WEEK -->
      <b-form-group
        label="Workouts per Week:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.workouts_per_week)"
        :state="getFieldState($v.input.workouts_per_week)"
      >
        <b-form-input
          v-model="input.workouts_per_week"
          type="number"
          number
          :state="getFieldState($v.input.workouts_per_week)"
        />
      </b-form-group>

      <!-- CARDIO SESSIONS PER WEEK -->
      <b-form-group
        label="Cardio Sessions per Week:"
        :invalid-feedback="getFieldInvalidFeedback($v.input.cardio_sessions_per_week)"
        :state="getFieldState($v.input.cardio_sessions_per_week)"
      >
        <b-form-input
          v-model="input.cardio_sessions_per_week"
          type="number"
          number
          :state="getFieldState($v.input.cardio_sessions_per_week)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small /> Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required, minLength, maxLength, numeric, between } from "vuelidate/lib/validators"
import FormInputButtons from '@/components/FormInputButtons'

export default {
  name: 'UserHistoryUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    FormInputButtons
  },

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  validations() {
    return {
      input: {
        fitness_notes: { required, minLength: minLength(5), maxLength: maxLength(255) },
        history1: {},
        history1_notes: { minLength: minLength(5), maxLength: maxLength(255) },
        history2: {},
        history2_notes: {minLength: minLength(5), maxLength: maxLength(255) },
        medication_notes: { minLength: minLength(5), maxLength: maxLength(255) },
        working_points: {},
        workout_history: { minLength: minLength(5), maxLength: maxLength(255) },
        goal_body_mass: { required },
        goal_body_fat_p: { required },
        goal_direction: { required },
        goal_weight: { required, numeric },
        competition_weight: { required, numeric },
        fitness_level: { required },
        workouts_per_week: { required, numeric, between: between(1, 21) },
        cardio_sessions_per_week: { required, numeric, between: between(1, 21) },
        somatotype: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        fitness_notes: this.newItem.fitness_notes,
        history1: this.newItem.history1,
        history1_notes: this.newItem.history1_notes,
        history2: this.newItem.history2,
        history2_notes: this.newItem.history2_notes,
        medication_notes: this.newItem.medication_notes,
        working_points: this.newItem.working_points,
        workout_history: this.newItem.workout_history,
        goal_body_mass: this.newItem.goal_body_mass,
        goal_body_fat_p: this.newItem.goal_body_fat_p,
        goal_direction: this.newItem.goal_direction,
        goal_weight: this.newItem.goal_weight,
        competition_weight: this.newItem.competition_weight,
        fitness_level: this.newItem.fitness_level,
        workouts_per_week: this.newItem.workouts_per_week,
        cardio_sessions_per_week: this.newItem.cardio_sessions_per_week,
        somatotype: this.newItem.somatotype
      }

      return input
    }
  }
}
</script>
