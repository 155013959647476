<template>
  <Layout>
    <b-row>
      <b-col cols="8">
        <PageHeader :title="title" />
      </b-col>

      <b-col
        cols="4"
        class="d-flex align-items-center justify-content-end"
      >
        <WorkoutItemUpsertButton
          v-if="$acl.check('couldAddWorkout')"
          tag="b-button"
          :tag-props="{
            variant: 'primary'
          }"
          @submitted="$router.push(`/workout/${$event.id}`)"
        >
          Add Workout
        </WorkoutItemUpsertButton>
      </b-col>
    </b-row>

    <b-card>
      <WorkoutList
        ref="list"
        :params="{
          filters: {
            muscle_ids: [],
            fitness_level_ids: [],
            is_private: null,
            is_favorite: null
          },
          order_desc: true
        }"
      />
    </b-card>
  </Layout>
</template>

<script>
import appConfig from '@/app.config'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import WorkoutList from '@/components/workouts/WorkoutList'
import WorkoutItemUpsertButton from '@/components/workouts/buttons/WorkoutItemUpsertButton'

export default {
  name: 'WorkoutsPage',

  components: {
    Layout,
    PageHeader,
    WorkoutList,
    WorkoutItemUpsertButton
  },

  page: {
    title: 'Workouts',
    meta: [{ name: 'description', content: appConfig.description }],
  },

  data() {
    return {
      config: appConfig
    }
  },

  computed: {
    title() {
      return 'Workouts'
    }
  },

  methods: {
    refresh() {
      this.$refs.list.refresh()
    }
  }
}
</script>