<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-row>
      <b-col
        lg="3"
        class="mb-2"
      >
        <b-input
          :value="newParams.filters.search"
          size="sm"
          placeholder="Search exercise..."
          @input="debounceSetFilterSearchParam"
        />
      </b-col>

      <b-col
        lg="3"
        class="mb-2"
      >
        <b-form-select
          v-model="filterFitnessLevelId"
          size="sm"
          :options="$store.getters['asset/getAbilityLevelsOptions']"
        >
          <template #first>
            <b-form-select-option :value="null">
              Fitness Level
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        lg="2"
        class="mb-2"
      >
        <b-form-select
          v-model="filterMuscleId"
          size="sm"
          :options="$store.getters['asset/getPrimaryMusclesOptions']"
        >
          <template #first>
            <b-form-select-option :value="null">
              Primary Muscle
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        lg="2"
        class="mb-2"
      >
        <b-form-select
          v-model="filterWorkoutType"
          size="sm"
          :options="$store.getters['asset/getWorkoutTypesOptions']"
        >
          <template #first>
            <b-form-select-option :value="null">
              Workout Type
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-col>

      <b-col
        lg="1"
        class="mb-2"
      >
        <b-form-checkbox
          class="py-1 d-inline-flex"
          v-model="filterIsFavorite"
          size="sm"
          switch
        >
          Favorite
        </b-form-checkbox>
      </b-col>  
    </b-row>

    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      fixed
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <!-- SELECT -->
      <template #cell(select)="row">
        <b-icon
          variant="primary"
          font-scale="1.3"
          :icon="selectedIds.includes(row.item.id) ? 'check-circle-fill' : 'circle'"
          :style="{
            cursor: 'pointer'
          }"
          @click="toogleItem(row.item)"
        />
      </template>

      <!-- IS FAVORITE -->
      <template #cell(is_favorite)="row">
        <ExerciseItemFavoriteButton
          :item="row.item"
          tag="b-icon"
          :tag-props="{
            variant: 'warning',
            icon: row.item.is_favorite ? 'star-fill' : 'star'
          }"
          :style="{
            cursor: 'pointer'
          }"
          @submitted="refresh"
        />
      </template>

      <!-- NAME -->
      <template #cell(name)="row">
        <ExerciseItemDetailButton
          class="text-decoration-underline-dotted"
          :item="row.item"
          prefetch
          :style="{
            cursor: 'pointer'
          }"
        >
          {{ row.item.name }}
        </ExerciseItemDetailButton>
      </template>

      <!-- TYPE -->
      <template #cell(type)="row">
        <WorkoutTypes :ids="row.item.type_ids" />
      </template>

      <!-- MUSCLES -->
      <template #cell(muscle_ids)="row">
        <Muscles :ids="row.item.muscle_ids || []" />
      </template>

      <!-- FITNESS LEVELS -->
      <template #cell(fitness_level_ids)="row">
        <Abilities :ids="row.item.fitness_level_ids || []" />
      </template>

      <!-- ACTIONS -->
      <template #cell(actions)="row">
        <ExerciseItemActionsButton
          :item="row.item"
          @deleted:item="refresh"
          @submitted="refresh"
          @copied="$router.push(`/exercise/${$event.id}`)"
        >
          <b-icon-three-dots />
        </ExerciseItemActionsButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import Muscles from '@/components/v1/asset/Muscles'
import Abilities from '@/components/v1/asset/Abilities'
import WorkoutTypes from '@/components/v1/asset/WorkoutTypes'
import ExerciseItemActionsButton from './buttons/ExerciseItemActionsButton'
import ExerciseItemFavoriteButton from './buttons/ExerciseItemFavoriteButton'
import ExerciseItemDetailButton from './buttons/ExerciseItemDetailButton'

export default {
  name: 'ExerciseList',

  mixins: [
    crudListMixin
  ],

  components: {
    Muscles,
    Abilities,
    WorkoutTypes,
    ExerciseItemActionsButton,
    ExerciseItemFavoriteButton,
    ExerciseItemDetailButton
  },

  props: {
    columns: {
      type: Array,

      default: () => ([
        'is_favorite',
        'name',
        'muscle_ids',
        'type',
        'fitness_level_ids',
        'actions'
      ])
    }
  },

  data() {
    return {
      baseEndpoint: 'exercises'
    }
  },

  computed: {
    fields() {
      const fields = [
        { key: 'select', sortable: false, label: '', thClass: 'w-50px' },
        { key: 'is_favorite', sortable: false, label: '', thClass: 'w-30px' },
        { key: 'name', sortable: false },
        { key: 'muscle_ids', sortable: false, label: 'Muscles' },
        { key: 'type', sortable: false, label: 'Workout Type' },
        { key: 'fitness_level_ids', sortable: false, label: 'Fitness Levels' },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'w-100px text-right' }
      ]

      return _.filter(fields, i => this.columns.includes(i.key))
    },

    filterMuscleId: {
      get() {
        return _.get(this.newParams.filters, 'muscle_ids.0', null)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            muscle_ids: value ? [value] : []
          },
          // Reset page to 1
          page: 1
        }
      }
    },

    filterFitnessLevelId: {
      get() {
        return _.get(this.newParams.filters, 'fitness_level_ids.0', null)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            fitness_level_ids: value ? [value] : []
          },
          // Reset page to 1
          page: 1
        }
      }
    },

    filterWorkoutType: {
      get() {
        return _.get(this.newParams.filters, 'type', null)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            type: value
          },
          // Reset page to 1
          page: 1
        }
      }
    },

    filterIsPrivate: {
      get() {
        return _.get(this.newParams.filters, 'is_private', false)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            is_private: value || null
          },
          // Reset page to 1
          page: 1
        }
      }
    },

    filterIsFavorite: {
      get() {
        return _.get(this.newParams.filters, 'is_favorite', false)
      },

      set(value) {
        this.newParams = {
          ...this.newParams,
          // Update filters
          filters: {
            ...this.newParams.filters,
            is_favorite: value || null
          },
          // Reset page to 1
          page: 1
        }
      }
    }
  },

  created() {
    this.debounceSetFilterSearchParam = _.debounce(async value => {
      this.newParams = {
        ...this.newParams,
        // Update filters
        filters: {
          ...this.newParams.filters,
          search: value
        },
        // Reset page to 1
        page: 1
      }
    }, 1000)
  }
}
</script>