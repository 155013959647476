var render = function () {
  var _vm$$v$input$food_id, _vm$$v$input$food_id$, _vm$newFood, _vm$newFood2, _vm$newFood3, _vm$newFood4, _vm$newFood5, _vm$newFood6, _vm$newFood7, _vm$newFood8, _vm$$v$input$amount, _vm$$v$input$amount$$, _vm$newFood9, _vm$newFood10, _vm$newFood11;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.food_id),
      "state": _vm.getFieldState(_vm.$v.input.food_id)
    },
    scopedSlots: _vm._u([(_vm$$v$input$food_id = _vm.$v.input.food_id) !== null && _vm$$v$input$food_id !== void 0 && (_vm$$v$input$food_id$ = _vm$$v$input$food_id.$params) !== null && _vm$$v$input$food_id$ !== void 0 && _vm$$v$input$food_id$.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Name "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('FoodSelect', {
    attrs: {
      "item": _vm.newFood,
      "placeholder": "Select a food or add new...",
      "state": _vm.getFieldState(_vm.$v.input.food_id)
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.newFood = $event;
      }
    },
    model: {
      value: _vm.input.food_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "food_id", $$v);
      },
      expression: "input.food_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Calories",
      "label-cols-sm": "4"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "value": (_vm$newFood = _vm.newFood) === null || _vm$newFood === void 0 ? void 0 : _vm$newFood.calories,
      "unit": "ounces",
      "unit-disabled": "",
      "decimal-places": 1,
      "input-props": {
        disabled: true
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Protein (gm)",
      "label-cols-sm": "4"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "value": (_vm$newFood2 = _vm.newFood) === null || _vm$newFood2 === void 0 ? void 0 : _vm$newFood2.protein,
      "unit": "ounces",
      "unit-disabled": "",
      "decimal-places": 1,
      "input-props": {
        disabled: true
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Carbs (gm)",
      "label-cols-sm": "4"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "value": (_vm$newFood3 = _vm.newFood) === null || _vm$newFood3 === void 0 ? void 0 : _vm$newFood3.carbs,
      "unit": "ounces",
      "unit-disabled": "",
      "decimal-places": 1,
      "input-props": {
        disabled: true
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Fat (gm)",
      "label-cols-sm": "4"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "value": (_vm$newFood4 = _vm.newFood) === null || _vm$newFood4 === void 0 ? void 0 : _vm$newFood4.fat,
      "unit": "ounces",
      "unit-disabled": "",
      "decimal-places": 1,
      "input-props": {
        disabled: true
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Sodium (mg)",
      "label-cols-sm": "4"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "value": (_vm$newFood5 = _vm.newFood) === null || _vm$newFood5 === void 0 ? void 0 : _vm$newFood5.sodium,
      "unit": "ounces",
      "unit-disabled": "",
      "decimal-places": 1,
      "input-props": {
        disabled: true
      }
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Serving units",
      "label-cols-sm": "4"
    }
  }, [_c('b-form-select', {
    attrs: {
      "value": (_vm$newFood6 = _vm.newFood) === null || _vm$newFood6 === void 0 ? void 0 : _vm$newFood6.serving_units,
      "options": _vm.$store.getters['asset/getFoodServingUnitsOptions'],
      "disabled": ""
    }
  })], 1), _vm.newFood && !['ounces', 'gramms'].includes((_vm$newFood7 = _vm.newFood) === null || _vm$newFood7 === void 0 ? void 0 : _vm$newFood7.serving_units) ? _c('b-form-group', {
    attrs: {
      "label": _vm.newFood.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit',
      "label-cols-sm": "4"
    }
  }, [_c('GramsInput', {
    attrs: {
      "value": (_vm$newFood8 = _vm.newFood) === null || _vm$newFood8 === void 0 ? void 0 : _vm$newFood8.serving_size,
      "input-props": {
        disabled: true
      },
      "decimal-places": 1,
      "unit": "ounces"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Amount",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.amount),
      "state": _vm.getFieldState(_vm.$v.input.amount)
    },
    scopedSlots: _vm._u([(_vm$$v$input$amount = _vm.$v.input.amount) !== null && _vm$$v$input$amount !== void 0 && (_vm$$v$input$amount$$ = _vm$$v$input$amount.$params) !== null && _vm$$v$input$amount$$ !== void 0 && _vm$$v$input$amount$$.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Amount "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [['grams', 'ounces', undefined].includes((_vm$newFood9 = _vm.newFood) === null || _vm$newFood9 === void 0 ? void 0 : _vm$newFood9.serving_units) ? _c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "placeholder": "Enter taken amount in ".concat(((_vm$newFood10 = _vm.newFood) === null || _vm$newFood10 === void 0 ? void 0 : _vm$newFood10.serving_units) || 'serving units'),
      "state": _vm.getFieldState(_vm.$v.input.amount)
    },
    model: {
      value: _vm.input.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "amount", $$v);
      },
      expression: "input.amount"
    }
  }) : _c('ServingUnitInput', {
    attrs: {
      "state": _vm.getFieldState(_vm.$v.input.amount),
      "unit-label": (_vm$newFood11 = _vm.newFood) === null || _vm$newFood11 === void 0 ? void 0 : _vm$newFood11.serving_units
    },
    model: {
      value: _vm.input.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "amount", $$v);
      },
      expression: "input.amount"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mt-2 mb-0"
  }, [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }