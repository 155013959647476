<template>
  <b-overlay :show="isFetching">
    <template v-if="isFetched">
      <b-card
        class="bg-transparent mt-3"
        no-body
      >
        <h4 class="mb-1 text-weight-bold">TODAY'S LOG</h4>

        <div class="font-size-20 mb-3">
          {{ $moment().format('M/D/Y') }}
        </div>

        <CurriculumItemStats
          :item="item"
          :date="$moment().startOf('day').format('YYYY-MM-DD')"
        />
      </b-card>

      <b-card>
        <h4 class="mb-3 text-secondary">Curriculum Notes</h4>

        <b-card
          bg-variant="light"
          class="mb-0"
        >
          <CurriculumItemClientNotesUpdate
            :item.sync="newItem"
            :prefetch="false"
          />
        </b-card>
      </b-card>

      <b-row>
        <b-col cols="6">
          <b-card>
            <b-row class="mb-3">
              <b-col>
                <h4 class="mb-3 text-secondary">Today's Schedule</h4>
              </b-col>

              <b-col class="text-right">
                <b-button
                  to="/schedule"
                  variant="primary"
                >
                  Full Schedule
                </b-button>
              </b-col>
            </b-row>

            <b-card
              bg-variant="light"
              class="mb-0"
            >
              <CurriculumItemScheduleDayDetail
                :date="$moment().toDate()"
                :curriculum="newItem"
              />
            </b-card>
          </b-card>
        </b-col>

        <b-col cols="6">
          <b-card>
            <h4 class="mb-3 text-secondary">Progress</h4>

            <b-card
              bg-variant="light"
              class="mb-0"
            >
              <b-tabs
                active-nav-item-class="bg-tab text-primary"
                nav-class="border-0"
              >
                <b-tab
                  class="p-2 bg-tab"
                  title-link-class="rounded-0 border-0"
                  :title="`Hide`"
                >
                  <b-alert
                    show
                    class="m-0"
                  >
                    Progress is hidden.
                  </b-alert>
                </b-tab>

                <b-tab
                  class="p-2 bg-tab"
                  title-link-class="rounded-0 border-0"
                  title="Weight"
                >
                  <PhysicalConditionListChart
                    mode="weight"
                    :params="{
                      filters: {
                        curriculum_id: newItem.id
                      },
                      order_by: 'taken_at'
                    }"
                    :curriculum='newItem'
                  />
                </b-tab>

                <b-tab
                  class="p-2 bg-tab"
                  title-link-class="rounded-0 border-0"
                  title="Body Mass %"
                >
                  <PhysicalConditionListChart
                    mode="total_body_fat_p"
                    :params="{
                      filters: {
                        curriculum_id: newItem.id
                      },
                      order_by: 'taken_at'
                    }"
                    :curriculum='newItem'
                  />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import CurriculumItemClientNotesUpdate from '@/components/curriculums/CurriculumItemClientNotesUpdate'
import CurriculumItemScheduleDayDetail from '@/components/curriculums/detail/CurriculumItemScheduleDayDetail'
import PhysicalConditionListChart from '@/components/physical-conditions/PhysicalConditionListChart'
import CurriculumItemStats from '@/components/curriculums/detail/CurriculumItemStats'

export default {
  name: 'CurriculumItemDashboard',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumItemClientNotesUpdate,
    CurriculumItemScheduleDayDetail,
    PhysicalConditionListChart,
    CurriculumItemStats
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  }
}
</script>
