<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <div
        v-if="isSubmitting"
        class="d-flex justify-content-center mb-3"
      >
        <b-spinner />
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'

export default {
  name: 'PasswordResetConfirm',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  validations() {
    return {
      input: {}
    }
  },

  watch: {
    'input.key': {
      immediate: true,
      handler() {
        if (this.input.key) {
          this.submit('submitted')
        }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        key: this.$route.params.key
      }

      return input
    },

    async submit(submittedEventName) {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      this.isSubmitting = true

      return this.$axios
        .post('auth/password-reset-confirm', this.input)
  
        .then(async ({ data }) => {
          this.isSubmitting = false
          await this.$store.dispatch('auth/logOut')
          await this.$store.dispatch('auth/setTokens', data)

          this.$store.commit("layout/addAlerts", {
            type: "success",
            text: 'You have successfully logged in'
          })

          this.setInput()

          if (submittedEventName) {
            this.$emit(submittedEventName, data)
          }
        })
  
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>
