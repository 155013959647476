<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Name"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.supplement_id)"
        :state="getFieldState($v.input.supplement_id)"
      >
        <SupplementSelect
          v-if="input.supplement_id"
          :value="input.supplement_id"
          :item="newItem.supplement"
          :show-create="false"
          placeholder="Select a supplement..."
          :state="getFieldState($v.input.supplement_id)"
          @update:item="onSelectSupplement"
        />
        <CurriculumPhaseSupplementSelect
          v-else
          :value="input.supplement_id"
          :params="{ filters: { phase_id: newItem.phase_id } }"
          placeholder="Select a supplement..."
          :state="getFieldState($v.input.supplement_id)"
          @update:item="onSelectCurriculumPhaseSupplement"
        />
      </b-form-group>

      <b-form-group
        label="Taken at"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.taken_at)"
        :state="getFieldState($v.input.taken_at)"
      >
        <b-form-timepicker
          v-model="input.taken_at"
          placeholder="Taken at"
          locale="en"
          :state="getFieldState($v.input.taken_at)"
        />
      </b-form-group>

      <b-form-group
        label="Amount"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.amount)"
        :state="getFieldState($v.input.amount)"
      >
        <b-form-input
          v-model="input.amount"
          type="number"
          number
          disabled
          placeholder="Taken amount in serving units"
          :state="getFieldState($v.input.amount)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import SupplementSelect from '@/components/supplements/SupplementSelect'
import CurriculumPhaseSupplementSelect from '@/components/curriculums/phase-supplements/CurriculumPhaseSupplementSelect'

export default {
  name: 'CurriculumSupplementItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    SupplementSelect,
    CurriculumPhaseSupplementSelect
  },

  data() {
    return {
      baseEndpoint: 'curriculum-supplements'
    }
  },

  validations() {
    return {
      input: {
        supplement_id: { required },
        amount: { required },
        taken_at: { required }
      }
    }
  },

  watch: {
    'input.supplement_id': {
      immediate: true,
      handler() {
        if (!this.input.supplement_id) {
          this.input.amount = null
        }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        amount: _.get(this.newItem, 'amount', null),
        supplement_id: _.get(this.newItem, 'supplement.id', null),
        taken_at: _.get(this.newItem, 'taken_at', null),
      }

      if (!this.isCreated) {
        input.phase_id = _.get(this.newItem, 'phase_id', null)
        input.day_number = _.get(this.newItem, 'day_number', null)
      }

      return input
    },

    onSelectCurriculumPhaseSupplement(curriculumPhaseSupplement) {
      if (curriculumPhaseSupplement) {
        this.input = {
          ...this.input,
          supplement_id: curriculumPhaseSupplement.supplement.id,
          amount: curriculumPhaseSupplement.dose,
        }
      }
    },

    onSelectSupplement(supplement) {
      if (!supplement) {
        console.log('onSelectSupplement', supplement)
        this.input = {
          ...this.input,
          supplement_id: null,
          amount: null,
        }
      }
    }
  }
}
</script>
