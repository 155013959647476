<template>
  <Layout>
    <b-overlay :show="isFetching">
      <b-row>
        <b-col cols="8">
          <PageHeader :title="title" />
        </b-col>
      </b-row>

      <b-card v-if="!isCreated || isFetched">
        <ExerciseItemUpsert
          :item.sync="newItem"
          @update:item="onUpdateItem"
          @submitted="$router.push('/exercise/list')"
          @cancel="$router.push('/exercise/list')"
        />
      </b-card>
    </b-overlay>
  </Layout>
</template>

<script>
import _ from 'lodash'
import crudItem from '@/mixins/crud-item'
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import ExerciseItemUpsert from '@/components/exercises/ExerciseItemUpsert'

export default {
  name: 'ExercisePage',

  mixins: [
    crudItem
  ],

  components: {
    Layout,
    PageHeader,
    ExerciseItemUpsert
  },

  props: {
    item: {
      type: Object,
      default: function() {
        return this.$route.params.id ? { id: _.toNumber(this.$route.params.id) } : {}
      }
    }
  },

  page() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      baseEndpoint: 'exercises'
    }
  },

  computed: {
    title() {
      return this.isCreated ? this.newItem.name || '' : 'Add exercise'
    }
  },

  methods: {
    onUpdateItem(newItem) {
      if (newItem.id !== _.toNumber(this.$route.params.id)) {
        this.$router.push(`/exercise/${newItem.id}`)
      }
    }
  }
}
</script>