<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      fixed
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(created_at)="row">
        {{ $moment(row.item.created_at).format('YYYY-MM-DD HH:mm') }}
      </template>

      <template #cell(status)="row">
        <CurriculumStatus
          :item="row.item"
          tag="b-badge"
          class="px-2 py-2"
        />
      </template>

      <template #cell(actions)="row">
        <CurriculumItemActionsButton
          :item="row.item"
          @deleted:item="refresh"
          @rebooted:item="refresh"
          @canceled:item="refresh"
          @completed:item="refresh"
        >
          <b-icon-three-dots />
        </CurriculumItemActionsButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>
<script>
import crudListMixin from '@/mixins/crud-list'
import CurriculumItemActionsButton from './buttons/CurriculumItemActionsButton'
import CurriculumStatus from './CurriculumStatus'

export default {
  name: 'CurriculumList',

  mixins: [
    crudListMixin
  ],

  components: {
    CurriculumItemActionsButton,
    CurriculumStatus
  },

  data() {
    return {
      baseEndpoint: 'curriculums',

      fields: [
        { key: 'created_at', sortable: false, label: 'Date' },
        { key: 'status', sortable: false },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  }
}
</script>