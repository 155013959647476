<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="popupIsShown = true"
  >
    <slot />

    <b-modal
      v-model="popupIsShown"
      content-class="rounded-0 border-0"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-4"
      body-class="p-0"
      hide-footer
      size="lg"
    >
      <template #modal-title>
        Meal Tracker {{ $moment(newParams.filters.date).format('YYYY-MM-DD') }}
      </template>

      <b-card
        class="m-0"
        body-class="p-0"
      >
        <TrackerNutrition
          :params.sync="newParams"
          :curriculum="curriculum"
          :water-recommended="waterRecommended"
          @submitted="$emit('submitted', $event); popupIsShown = false"
        />
      </b-card>
    </b-modal>
  </component>
</template>

<script>
import _ from 'lodash'
import TrackerNutrition from '../TrackerNutrition'

export default {
  name: 'TrackerNutritionButton',

  components: {
    TrackerNutrition
  },

  props: {
    params: {
      type: Object,
      default: () => ({})
    },

    curriculum: {
      type: Object,
      default: null
    },

    waterRecommended: {
      type: Number,
      default: null
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      popupIsShown: false,
      newParams: null
    }
  },

  watch: {
    params: {
      immediate: true,
      deep: true,

      handler() {
        this.newParams = _.cloneDeep(this.params)
      }
    },

    newParams: {
      immediate: true,
      deep: true,

      handler() {
        this.$emit('update:params', _.cloneDeep(this.newParams))
      }
    },

    popupIsShown: {
      immediate: true,
      
      handler(newValue, oldValue) {
        if (oldValue !== null) {
          if (newValue) {
            this.$emit('opened')
          } else {
            this.$emit('closed')
          }
        }
      }
    }
  }
}
</script>

