var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.name),
      "state": _vm.getFieldState(_vm.$v.input.name)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter meal name",
      "state": _vm.getFieldState(_vm.$v.input.name)
    },
    model: {
      value: _vm.input.name,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "name", $$v);
      },
      expression: "input.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Meal type",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.type),
      "state": _vm.getFieldState(_vm.$v.input.type)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getMealTypesOptions'],
      "state": _vm.getFieldState(_vm.$v.input.type)
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null,
            "disabled": ""
          }
        }, [_vm._v(" Select meal type ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.input.type,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "type", $$v);
      },
      expression: "input.type"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Taken at",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.taken_at),
      "state": _vm.getFieldState(_vm.$v.input.taken_at)
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "placeholder": "Taken at",
      "locale": "en",
      "state": _vm.getFieldState(_vm.$v.input.taken_at)
    },
    model: {
      value: _vm.input.taken_at,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "taken_at", $$v);
      },
      expression: "input.taken_at"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mt-2 mb-0"
  }, [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }