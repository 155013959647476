var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Start date",
      "label-class": "text-uppercase font-size-24",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.start_date),
      "state": _vm.getFieldState(_vm.$v.input.start_date)
    }
  }, [!_vm.isStarted ? _c('b-form-datepicker', {
    attrs: {
      "min": _vm.$moment().toDate(),
      "state": _vm.getFieldState(_vm.$v.input.start_date)
    },
    model: {
      value: _vm.input.start_date,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "start_date", $$v);
      },
      expression: "input.start_date"
    }
  }) : _c('b-form-datepicker', {
    attrs: {
      "value": _vm.newItem.start_date,
      "disabled": _vm.isStarted
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Projected fat % and activity level",
      "label-class": "text-uppercase font-size-24"
    }
  }, [_c('CurriculumPhasesAbilityInput', {
    attrs: {
      "curriculum": _vm.item,
      "mode": "values",
      "disabled": _vm.isStarted
    },
    model: {
      value: _vm.input.phases,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phases", $$v);
      },
      expression: "input.phases"
    }
  }), _c('CurriculumPhasesBodyFatInput', {
    attrs: {
      "curriculum": _vm.item,
      "show-header": false,
      "mode": "values",
      "disabled": _vm.isStarted
    },
    model: {
      value: _vm.input.phases,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phases", $$v);
      },
      expression: "input.phases"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Recommendations",
      "label-class": "text-uppercase font-size-24"
    }
  }, [_c('b-card', {
    staticClass: "mb-4 bg-transparent",
    attrs: {
      "no-body": ""
    }
  }, [_c('h4', {
    staticClass: "text-primary mb-3"
  }, [_vm._v(" Cardio ")]), _c('CurriculumPhasesCardioInput', {
    staticClass: "mb-2",
    attrs: {
      "mode": "suggestions",
      "curriculum": _vm.item
    }
  })], 1), _c('b-card', {
    staticClass: "mb-4 bg-transparent",
    attrs: {
      "no-body": ""
    }
  }, [_c('h4', {
    staticClass: "text-primary mb-3"
  }, [_vm._v(" Nutrients ")]), _c('CurriculumPhasesWaterInput', {
    attrs: {
      "mode": "suggestions",
      "curriculum": _vm.item
    }
  }), _c('CurriculumPhasesCaloriesInput', {
    attrs: {
      "mode": "suggestions",
      "curriculum": _vm.item,
      "show-header": false
    }
  })], 1), _c('b-card', {
    staticClass: "mb-5 bg-transparent",
    attrs: {
      "no-body": ""
    }
  }, [_c('h4', {
    staticClass: "text-primary mb-3"
  }, [_vm._v(" Workout Programs ")]), _c('WorkoutProgramSelect', {
    staticClass: "bg-white",
    attrs: {
      "is-suggested": "",
      "show-select": false,
      "params": {
        filters: {
          workouts_per_week: _vm.availableWorkoutsPerWeek,
          fitness_levels: [_vm.item.profile.fitness_level],
          goal_directions: [_vm.item.profile.goal_direction],
          genders: [_vm.item.profile.sex]
        }
      }
    }
  })], 1), _c('b-card', {
    staticClass: "mb-5 bg-transparent",
    attrs: {
      "no-body": ""
    }
  }, [_c('h4', {
    staticClass: "text-primary mb-3"
  }, [_vm._v(" Supplement Programs ")]), _c('SupplementProgramSelect', {
    staticClass: "bg-white",
    attrs: {
      "is-suggested": "",
      "show-select": false,
      "params": {
        filters: {
          fitness_levels: [_vm.item.profile.fitness_level],
          goal_directions: [_vm.item.profile.goal_direction],
          genders: [_vm.item.profile.sex]
        }
      }
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "PROJECTIONS",
      "label-class": "text-uppercase font-size-24"
    }
  }, [_c('CurriculumPhasesWeightInput', {
    staticClass: "mb-2",
    attrs: {
      "curriculum": _vm.item,
      "mode": "suggestions"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1), _vm.isSubmitting ? _c('div', {
    style: {
      position: 'absolute',
      top: 0,
      right: 0
    }
  }, [_c('b-spinner', {
    attrs: {
      "small": ""
    }
  })], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }