var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_vm.isSubmitting ? _c('div', {
    staticClass: "d-flex justify-content-center mb-3"
  }, [_c('b-spinner')], 1) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }