<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Name"
        label-cols="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.supplement_id)"
        :state="getFieldState($v.input.supplement_id)"
      >
        <SupplementSelect
          v-model="input.supplement_id"
          :item="newItem.supplement"
          :show-create="false"
          placeholder="Select a supplement..."
          :state="getFieldState($v.input.supplement_id)"
        />
      </b-form-group>

      <b-form-group
        label="Dose"
        label-cols="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.dose)"
        :state="getFieldState($v.input.dose)"
      >
        <b-form-input
          v-model="input.dose"
          type="number"
          number
          placeholder="Dose"
          :state="getFieldState($v.input.dose)"
        />
      </b-form-group>

      <b-form-group
        label="Doses per Day"
        label-cols="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.doses_per_day)"
        :state="getFieldState($v.input.doses_per_day)"
      >
        <b-form-input
          v-model="input.doses_per_day"
          type="number"
          number
          placeholder="Dosage per Day"
          :state="getFieldState($v.input.doses_per_day)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import SupplementSelect from '@/components/supplements/SupplementSelect'

export default {
  name: 'CurriculumPhaseSupplementItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    SupplementSelect
  },

  data() {
    return {
      baseEndpoint: 'curriculum-phase-supplements'
    }
  },

  validations() {
    return {
      input: {
        supplement_id: { required },
        dose: { required },
        doses_per_day: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        dose: _.get(this.newItem, 'dose', null),
        doses_per_day: _.get(this.newItem, 'doses_per_day', null),
        supplement_id: _.get(this.newItem, 'supplement.id', null),
      }

      if (!this.isCreated) {
        input.phase_id = _.get(this.newItem, 'phase_id', null);
      }

      return input;
    }
  }
}
</script>
