<template>
  <b-row no-gutters>
    <b-col class="pr-2">
      <b-input-group :append="unitLabel">
        <b-form-input
          v-model="units"
          type="number"
          number
          :state="state"
        />
      </b-input-group>
    </b-col>

    <b-col>
      <b-input-group append="fract">
        <b-form-select
          v-model="fractions"
          :options="fractionOptions"
          :state="state"
        />
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'ServingUnitInput',

  props: {
    value: {
      type: Number,
      default: null
    },

    state: {
      type: Boolean,
      default: null
    },

    unitLabel: {
      type: String,
      default: 'units'
    }
  },

  computed: {
    fractionOptions() {
      return [
        { value: 0, text: '0' },
        { value: 0.25, text: '¼' },
        { value: 0.33, text: '⅓' },
        { value: 0.50, text: '½' },
        { value: 0.75, text: '¾' }
      ]
    },

    units: {
      get() {
        const units = _.floor((this.value || 0))
        return units
      },

      set(units) {
        const newValue = (units || 0) + this.fractions
        this.$emit('input', newValue)
      }
    },

    fractions: {
      get() {
        const inches = Math.round(((this.value || 0) % 1) * 100) / 100
        return inches
      },

      set(fractions) {
        const newValue = this.units + (fractions || 0)
        this.$emit('input', newValue)
      }
    }
  }
}
</script>