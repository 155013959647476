<template>
  <b-row no-gutters>
    <b-col class="pr-2">
      <b-input-group append="ft">
        <b-form-select
          v-model="feet"
          :options="feetOptions"
          :state="state"
        />
      </b-input-group>
    </b-col>

    <b-col>
      <b-input-group append="in">
        <b-form-select
          v-model="inches"
          :options="inchesOptions"
          :state="state"
        />
      </b-input-group>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'HeightInput',

  props: {
    value: {
      type: Number,
      default: null
    },

    state: {
      type: Boolean,
      default: null
    }
  },

  computed: {
    feetOptions() {
      return [
        { value: 0, text: '0' },
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' }
      ]
    },

    inchesOptions() {
      return [
        { value: 1, text: '1' },
        { value: 2, text: '2' },
        { value: 3, text: '3' },
        { value: 4, text: '4' },
        { value: 5, text: '5' },
        { value: 6, text: '6' },
        { value: 7, text: '7' },
        { value: 8, text: '8' },
        { value: 9, text: '9' },
        { value: 10, text: '10' },
        { value: 11, text: '11' }
      ]
    },

    feet: {
      get() {
        const feet = _.floor((this.value || 0) / 12)
        return feet
      },

      set(feet) {
        const newValue = (feet || 0) * 12 + this.inches
        this.$emit('input', newValue)
      }
    },

    inches: {
      get() {
        const inches = (this.value || 0) % 12
        return inches
      },

      set(inches) {
        const newValue = this.feet * 12 + (Math.min(inches, 11) || 0)
        this.$emit('input', newValue)
      }
    }
  }
}
</script>