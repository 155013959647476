var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_vm.newParams.filters.phase_id ? _c('b-table-simple', {
    staticClass: "input-table mb-2",
    attrs: {
      "fixed": ""
    }
  }, [_vm.showHeader ? _c('b-thead', [_c('b-tr', [_c('b-th', {
    staticClass: "bg-transparent w-100px"
  }), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Name")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Type")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Rec. Dosage")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Actual Dosage")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Dosages/Day")]), _vm.editable ? _c('b-th', {
    staticClass: "text-right w-50px"
  }) : _vm._e()], 1)], 1) : _vm._e(), _c('b-tbody', [!_vm.input || !_vm.input.items || !_vm.input.items.length ? _c('b-tr', [_c('b-th', {
    staticClass: "text-center w-100px",
    attrs: {
      "rowspan": _vm.editable ? 2 : 1
    }
  }, [_vm._v(" Phase " + _vm._s(_vm.phase.number) + " ")]), _c('b-td', {
    staticClass: "text-center"
  }), _c('b-td', {
    staticClass: "text-center"
  }), _c('b-td', {
    staticClass: "text-center"
  }), _c('b-td', {
    staticClass: "text-center"
  }), _c('b-td', {
    staticClass: "text-center"
  })], 1) : _vm._l(_vm.input.items, function (item, itemIndex) {
    return _c('b-tr', {
      key: item.id
    }, [itemIndex === 0 ? _c('b-th', {
      staticClass: "text-center w-100px",
      attrs: {
        "rowspan": _vm.input.items.length + (_vm.editable ? 1 : 0)
      }
    }, [_vm._v(" Phase " + _vm._s(_vm.phase.number) + " ")]) : _vm._e(), _c('b-td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.getItemById(item.id).supplement.name) + " ")]), _c('b-td', {
      staticClass: "text-center"
    }, [_c('SupplementTypes', {
      attrs: {
        "ids": [_vm.getItemById(item.id).supplement.type]
      }
    })], 1), _c('b-td', {
      staticClass: "text-center"
    }, [_vm._v(" " + _vm._s(_vm.getItemById(item.id).supplement.dose) + " ")]), _c('b-td', {
      staticClass: "text-center"
    }, [_vm.editable ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "number": ""
      },
      model: {
        value: item.dose,
        callback: function callback($$v) {
          _vm.$set(item, "dose", $$v);
        },
        expression: "item.dose"
      }
    }) : [_vm._v(" " + _vm._s(item.dose) + " ")]], 2), _c('b-td', {
      staticClass: "text-center"
    }, [_vm.editable ? _c('b-form-input', {
      attrs: {
        "type": "number",
        "number": ""
      },
      model: {
        value: item.doses_per_day,
        callback: function callback($$v) {
          _vm.$set(item, "doses_per_day", $$v);
        },
        expression: "item.doses_per_day"
      }
    }) : [_vm._v(" " + _vm._s(item.doses_per_day) + " ")]], 2), _vm.editable ? _c('b-td', {
      staticClass: "text-right w-50px"
    }, [_c('CurriculumPhaseSupplementItemDeleteButton', {
      staticClass: "p-0",
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "delete:item": function deleteItem($event) {
          _vm.$emit('delete:item', $event);

          _vm.refresh();
        }
      }
    }, [_c('b-icon-trash', {
      attrs: {
        "scale": "0.8"
      }
    })], 1)], 1) : _vm._e()], 1);
  }), _vm.editable ? _c('b-tr', [_c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "colspan": "6"
    }
  }, [_c('b-row', {
    attrs: {
      "align": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-center"
  }, [_c('CurriculumPhaseSupplementItemUpsertButton', {
    staticClass: "p-0 mr-4",
    attrs: {
      "item": {
        phase_id: _vm.newParams.filters.phase_id
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.refresh();
      }
    }
  }, [_c('b-icon-plus-circle', {
    staticClass: "mr-1",
    attrs: {
      "scale": "0.8"
    }
  }), _vm._v(" Add supplement ")], 1)], 1)], 1)], 1)], 1) : _vm._e()], 2)], 1) : _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" CurriculumPhaseSupplementList working only with filters: phase_id ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }