<template>
  <Layout>
    <b-overlay
      :show="isBusy"
      :variant="config.overlay.variant"
      :opacity="config.overlay.opacity"
      :blur="config.overlay.blur"
      rounded="sm"
    >
      <template v-if="client">
        <b-row>
          <b-col cols="8">
            <PageHeader :title="title" />
          </b-col>

          <b-col
            cols="4"
            class="d-flex align-items-center justify-content-end"
          >
            <ImageItemUpsertButton
              tag="b-button"
              :client="client"
              :tag-props="{
                variant: 'primary'
              }"
              @update:item="refresh(`list-${$event.category}`)"
            >
              Add Photo
            </ImageItemUpsertButton>
          </b-col>
        </b-row>

        <ImageByCategoryList
          ref="list"
          :client="client"
        />
      </template>
    </b-overlay>
  </Layout>
</template>

<script>
import appConfig from '@/app.config'
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import ImageByCategoryList from '@/components/images/ImageByCategoryList'
import ImageItemUpsertButton from '@/components/images/buttons/ImageItemUpsertButton'

export default {
  name: 'ClientImagesPage',

  components: {
    Layout,
    PageHeader,
    ImageByCategoryList,
    ImageItemUpsertButton
  },

  page() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      isBusy: false,
      config: appConfig,
      client: null
    }
  },

  computed: {
    title() {
      return this.client ? `${this.client.first_name || this.client.email}'s Photos` : null
    }
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchItem()
      }
    }
  },

  methods: {
    refresh(refName) {
      this.$refs.list.refresh(refName)
      if (refName === 'list-front') {
        this.$refs.list.refresh('list-starting')
        this.$refs.list.refresh('list-now')
      }
    },

    fetchItem() {
      this.$axios
        .get(`users/${this.$route.params.id}`, {
          parent: this,
          params: {}
        })
        .then(({ data }) => {
          this.client = data
        })
        .catch((e) => {
          throw e
        })
    }
  }
}
</script>