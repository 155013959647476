<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Supplement"
        label-cols-sm="3"
        label-cols-lg="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.supplement_id)"
        :state="getFieldState($v.input.supplement_id)"
      >
        <SupplementSelect
          v-model="input.supplement_id"
          :item="newItem.supplement"
          placeholder="Select a supplement or add new..."
          :state="getFieldState($v.input.supplement_id)"
        />
      </b-form-group>

      <b-form-group
        label="Taken at"
        label-cols-sm="3"
        label-cols-lg="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.taken_at)"
        :state="getFieldState($v.input.taken_at)"
      >
        <b-form-timepicker
          v-model="input.taken_at"
          placeholder="Taken at"
          locale="en"
          :state="getFieldState($v.input.taken_at)"
        />
      </b-form-group>

      <b-form-group
        label="Amount"
        label-cols-sm="3"
        label-cols-lg="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.amount)"
        :state="getFieldState($v.input.amount)"
      >
        <b-form-input
          v-model="input.amount"
          placeholder="Amount"
          type="number"
          number
          step="0.01"
          :state="getFieldState($v.input.amount)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import SupplementSelect from '@/components/supplements/SupplementSelect'

export default {
  name: 'TrackerSupplementItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    SupplementSelect
  },

  data() {
    return {
      baseEndpoint: 'tracker-supplements'
    }
  },

  validations() {
    return {
      input: {
        supplement_id: { required },
        taken_at: { required },
        amount: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        supplement_id: _.get(this.newItem, 'supplement.id', null),
        taken_at: _.get(this.newItem, 'taken_at', null),
        amount: _.get(this.newItem, 'amount', null)
      }

      if (!this.isCreated) {
        input.nutrition_id = _.get(this.newItem, 'nutrition_id', null)
      }

      return input
    }
  }
}
</script>
