<template>
  <b-overlay :show="isFetching">
    <b-form
      v-if="isFetched"
      @submit.prevent="$refs.totalTimeTimer.stop(); submit('submitted')"
    >
      <b-row
        class="mb-3"
        align-v="center"
      >
        <b-col sm="6">
          <div class="font-size-16 font-weight-bold text-truncate">
            {{ newItem.workout.name }}
          </div>

          <div
            v-if="newItem.start_time"
            class="text-secondary text-truncate"
          >
            {{ $moment(newItem.start_time).format('lll') }} - <template v-if="newItem.curriculum_phase">
              Phase {{ newItem.curriculum_phase.number }}, Week {{ weekIndex + 1 }}
            </template>
          </div>

          <div
            v-if="rest"
            class="text-secondary text-truncate"
          >
            Rest between sets: {{ rest }} secs
          </div>
        </b-col>

        <b-col sm="6" class="d-flex justify-content-end">
          <b-form-group
            class="m-0"
            :invalid-feedback="getFieldInvalidFeedback($v.input.total_time)"
            :state="getFieldState($v.input.total_time)"
          >
            <TimeInput
              v-if="!$acl.check('isClient')"
              class="font-weight-bolder font-size-22"
              :value="input.total_time"
              disabled
            />

            <TimerInput
              v-show="$acl.check('isClient')"
              ref="totalTimeTimer"
              v-model="input.total_time"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <TrackerExerciseGroupList
        :params="{
          filters: {
            tracker_workout_id: newItem.id
          }
        }"
        :result="{
          data: newItem.tracker_exercise_groups
        }"
        :prefetch="false"
        @update:result="onUpdateExerciseGroupsResult($event)"
      />

      <b-form-group
        class="m-0"
        :invalid-feedback="getFieldInvalidFeedback($v.input.notes)"
        :state="getFieldState($v.input.notes)"
      >
        <NotesInput
          v-model="input.notes"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4 mb-0">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting && !isCompleting" small />{{ actionName }}
        </b-button>

        <b-button
          v-if="$acl.check('isClient') && !newItem.is_performed"
          class="ml-2"
          variant="success"
          :disabled="isCompleting || !!emptyExerciseSets.length"
          @click="complete"
        >
          <b-spinner v-if="isCompleting" small />Complete
        </b-button>
      </b-form-group>

      <div
        v-if="$acl.check('isClient') && !newItem.is_performed && emptyExerciseSets.length"
        class="mt-4"
      >
        <b-alert
          show
          variant="warning"
        >
          Please fill or remove sets to complete the workout.
        </b-alert>
      </div>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import TimerInput from '@/components/inputs/timer-input'
import TimeInput from '@/components/inputs/time-input'
import TrackerExerciseGroupList from './TrackerExerciseGroupList'
import NotesInput from '@/components/inputs/notes-input'

export default {
  name: 'TrackerWorkoutItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    TimerInput,
    TimeInput,
    TrackerExerciseGroupList,
    NotesInput
  },

  data() {
    return {
      baseEndpoint: 'tracker-workouts'
    }
  },

  computed: {
    phaseWeeks() {
      return _.get(this.newItem.curriculum_phase.weeks, 'items', [])
    },

    weekIndex() {
      return this.phaseWeeks ? (
        _.findIndex(this.phaseWeeks, i => (
          i.includes(this.$moment(this.newItem.start_time).format('YYYY-MM-DD'))
        ))
      ): null
    },

    weekDayIndex() {
      return this.phaseWeeks ? (
        _.findIndex(this.phaseWeeks[this.weekIndex], i => (
          i === this.$moment(this.newItem.start_time).format('YYYY-MM-DD')
        ))
      ) : null
    },

    isCompleting() {
      return this.isSubmitting && this.input.log_time && !this.newItem.log_time
    },

    emptyExerciseSets() {
      return _.chain(this.newItem.tracker_exercise_groups)
        .flatMap('tracker_exercises')
        .flatMap('sets')
        .flatMap('items')
        .filter(i => i && (!i.lbs || !i.reps))
        .value()
    },

    rest() {
      return _.get(
        this.newItem.curriculum_phase,
        `workout_program.phases.${this.newItem.curriculum_phase.number - 1}.weeks.${this.weekIndex}.rest`,
        null
      )
    }
  },

  validations() {
    return {
      input: {
        total_time: {},

        notes: {
          items: {
            $each: {
              text: {}
            }
          }
        }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        total_time: _.get(this.newItem, 'total_time', null),
        notes: _.get(this.newItem, 'notes') || { items: [] }
      }

      if (!this.isCreated) {
        input.user_id = _.get(this.newItem, 'user_id')
        input.curriculum_phase_id = _.get(this.newItem, 'curriculum_phase_id')
        input.workout_id = _.get(this.newItem, 'workout_id')
      }

      return input
    },

    complete() {
      this.input.log_time = this.$moment().format('YYYY-MM-DD HH:mm:ss')
      this.submit('completed')
    },

    onUpdateExerciseGroupsResult(exerciseGroupsResult) {
      if (
        exerciseGroupsResult &&
        !_.isEqual(this.newItem.tracker_exercise_groups, exerciseGroupsResult.data)
      ) {
        this.newItem.tracker_exercise_groups = exerciseGroupsResult.data
      }
    }
  }
}
</script>