<template>
  <div 
    v-if="vis"
    class="spiner-wrap"
  >
    <b-spinner label="Spinning" variant="primary" class="m-1"></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  computed: {
    vis () {
      return this.$store.getters['layout/getLoader']
    }
  }
}
</script>

<style scoped>
  .spiner-wrap {
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 1111;
  }
</style>