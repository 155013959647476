var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "fa-icon-component"
  }, [_c('i', {
    "class": _vm.internalIcon,
    style: _vm.style
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }