<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-table-simple
      class="mb-2 text-nowrap"
      fixed
      striped
      stacked="sm"
    >
      <b-thead>
        <b-tr variant="primary">
          <b-th class="w-150px" />
          <b-th>Foods</b-th>
          <b-th class="text-center">Calories</b-th>
          <b-th class="text-center">Protein</b-th>
          <b-th class="text-center">Carbs</b-th>
          <b-th class="text-center">Fats</b-th>
          <b-th class="text-center">Sodium</b-th>

          <b-th class="text-center w-50px" />
          <b-th class="text-center overflow-hidden w-150px">Supplements</b-th>
        </b-tr>
      </b-thead>

      <template v-if="newResult.data && newResult.data.length">
        <b-tbody
          v-for="meal in newResult.data"
          :key="meal.id"
        >
          <b-tr class="align-middle">
            <b-th
              variant="success"
              class="text-center align-middle position-relative overflow-hidden"
              :rowspan="meal.meal_foods.length ? meal.meal_foods.length + 3 : 4"
            >
              <CurriculumMealItemUpsertButton
                v-if="!disabled"
                :item="meal"
                class="p-0 font-weight-bold"
                tag="b-button"
                :tag-props="{
                  variant: 'link'
                }"
                @submitted="$emit('submitted:item', $event); refresh()"
              >
                {{ meal.name }}
              </CurriculumMealItemUpsertButton>

              <div v-else>
                {{ meal.name }}
              </div>

              <div>
                <b-badge>
                  <MealTypes :ids="[meal.type]" />
                </b-badge>
              </div>

              <div>{{ $moment(meal.taken_at, 'HH:mm:ss').format('hh:mm A') }}</div>

              <CurriculumMealItemDeleteButton
                v-if="!disabled"
                :item="meal"
                tag="b-button"
                :tag-props="{
                  variant: 'link'
                }"
                @delete:item="$emit('delete:item', $event); refresh()"
              >
                <b-icon-trash scale="0.8" />
              </CurriculumMealItemDeleteButton>
            </b-th>
          </b-tr>

          <template v-if="meal.meal_foods.length">
            <b-tr
              v-for="(mealFood, mealFoodIndex) in meal.meal_foods"
              :key="mealFood.id"
            >
              <b-td class="align-middle">
                <CurriculumMealFoodItemUpsertButton
                  v-if="!disabled"
                  :item="mealFood"
                  class="p-0 font-weight-bold"
                  tag="b-button"
                  :tag-props="{
                    variant: 'link'
                  }"
                  @submitted="$emit('submitted:food', $event); refresh()"
                >
                  {{ mealFood.food.name }}
                </CurriculumMealFoodItemUpsertButton>

                <div v-else>
                  {{ mealFood.food.name }}
                </div>

                <div>{{ mealFood.amount }} {{ mealFood.food.serving_units }}</div>
              </b-td>

              <b-td class="text-center align-middle">{{ calcMealFoodMacro(mealFood, 'calories') || '-' }}</b-td>
              <b-td class="text-center align-middle">{{ calcMealFoodMacro(mealFood, 'protein') || '-' }} gm</b-td>
              <b-td class="text-center align-middle">{{ calcMealFoodMacro(mealFood, 'carbs') || '-' }} gm</b-td>
              <b-td class="text-center align-middle">{{ calcMealFoodMacro(mealFood, 'fat') || '-' }} gm</b-td>
              <b-td class="text-center align-middle">{{ calcMealFoodMacro(mealFood, 'sodium') || '-' }} mg</b-td>

              <b-td class="text-center align-middle">
                <CurriculumMealFoodItemDeleteButton
                  v-if="!foodsDisabled"
                  :item="mealFood"
                  class="p-0"
                  tag="b-button"
                  :tag-props="{
                    variant: 'link'
                  }"
                  @delete:item="$emit('delete:food', $event); refresh()"
                >
                  <b-icon-trash scale="0.8" />
                </CurriculumMealFoodItemDeleteButton>
              </b-td>

              <b-td
                v-if="mealFoodIndex === 0"
                :rowspan="meal.meal_foods.length || 1"
                variant="secondary"
              >
                <CurriculumSupplementList
                  :key="meal.id"
                  :params="{
                    filters: {
                      phase_id: newParams.filters.phase_id,
                      day_number: newParams.filters.day_number,
                      taken_at: meal.taken_at
                    }
                  }"
                  :prefetch="false"
                  :result="{
                    data: getSupplementsByTakenAt(meal.taken_at)
                  }"
                  :show-time="false"
                  :disabled="supplementsDisabled"
                  @submitted:item="$emit('submitted:supplement', $event)"
                  @delete:item="$emit('delete:supplement', $event)"
                />
              </b-td>
            </b-tr>
          </template>

          <b-tr v-else>
            <b-th
              colspan="7"
              class="p-0 text-center"
            >
              <b-alert
                show
                variant="light"
              >
                There are no foods in this meal.
              </b-alert>
            </b-th>

            <b-td
              :rowspan="meal.meal_foods.length || 1"
              variant="secondary"
            >
              <CurriculumSupplementList
                :key="meal.id"
                :params="{
                  filters: {
                    phase_id: newParams.filters.phase_id,
                    day_number: newParams.filters.day_number,
                    taken_at: meal.taken_at
                  }
                }"
                :prefetch="false"
                :result="{
                  data: getSupplementsByTakenAt(meal.taken_at)
                }"
                :show-time="false"
                :disabled="supplementsDisabled"
                @submitted:item="$emit('submitted:supplement', $event)"
                @delete:item="$emit('delete:supplement', $event)"
              />
            </b-td>
          </b-tr>

          <b-tr>
            <b-td
              v-if="!foodsDisabled"
              class="align-middle"
              colspan="7"
            >
              <b-row align="between">
                <b-col class="text-center">
                  <CurriculumMealFoodItemUpsertButton
                    :item="{ meal_id: meal.id }"
                    class="p-0 mr-4"
                    tag="b-button"
                    :tag-props="{
                      variant: 'link'
                    }"
                    @submitted="$emit('submitted:food', $event); refresh()"
                  >
                    <b-icon-plus-circle class="mr-1" scale="0.8" /> Add food
                  </CurriculumMealFoodItemUpsertButton>
                </b-col>
              </b-row>
            </b-td>

            <b-td
              v-if="!supplementsDisabled"
              class="align-middle"
              variant="secondary"
              rowspan="1"
            >
              <CurriculumSupplementItemUpsertButton
                :item="{ phase_id: newParams.filters.phase_id, day_number: newParams.filters.day_number, taken_at: meal.taken_at }"
                class="px-0"
                tag="b-button"
                :tag-props="{
                  variant: 'link'
                }"
                @submitted="$emit('submitted:supplement', $event)"
              >
                <b-icon-plus-circle scale="0.8" /> Add supplement
              </CurriculumSupplementItemUpsertButton>
            </b-td>
          </b-tr>

          <b-tr variant="secondary">
            <b-td
              variant="primary"
              class="text-center font-weight-bolder"
            >
              Meal Totals
            </b-td>

            <b-td class="text-center font-weight-bolder" variant="secondary">{{ calcMealMacro(meal, 'calories') || '-' }}</b-td>
            <b-td class="text-center font-weight-bolder" variant="secondary">{{ calcMealMacro(meal, 'protein') || '-' }}</b-td>
            <b-td class="text-center font-weight-bolder" variant="secondary">{{ calcMealMacro(meal, 'carbs') || '-' }}</b-td>
            <b-td class="text-center font-weight-bolder" variant="secondary">{{ calcMealMacro(meal, 'fat') || '-' }}</b-td>
            <b-td class="text-center font-weight-bolder" variant="secondary">{{ calcMealMacro(meal, 'sodium') || '-' }}</b-td>
            <b-td colspan="2" />
          </b-tr>

          <b-tr variant="primary">
            <b-th
              class="py-2"
              colspan="9"
            />
          </b-tr>
        </b-tbody>
      </template>

      <b-tbody v-else>
        <b-tr>
          <b-td
            class="text-center p-0"
            colspan="9"
          >
            <b-alert
              v-if="isFetching"
              show
              class="m-0"
              variant="light"
            >
              Loading...
            </b-alert>

            <b-alert
              v-else
              show
              class="m-0"
              variant="warning"
            >
              There are no meals and foods.
            </b-alert>
          </b-td>
        </b-tr>
      </b-tbody>

      <b-tbody>
        <b-tr v-if="!disabled">
          <b-td
            class="text-center"
            colspan="9"
          >
            <CurriculumMealItemUpsertButton
              :item="{ phase_id: newParams.filters.phase_id, day_number: newParams.filters.day_number }"
              class="px-0"
              tag="b-button"
              :tag-props="{
                variant: 'link',
                disabled: disabled
              }"
              @submitted="$emit('submitted:item', $event); refresh()"
            >
              <b-icon-plus-circle class="mr-1" scale="0.8" /> Add meal
            </CurriculumMealItemUpsertButton>
          </b-td>
        </b-tr>

        <b-tr variant="secondary">
          <b-td
            variant="primary"
            colspan="2"
            class="text-center font-weight-bolder"
          >
            Daily Totals
          </b-td>

          <b-td class="text-center font-weight-bolder">{{ calcMealsMacro(newResult.data, 'calories') || '-' }}</b-td>
          <b-td class="text-center font-weight-bolder">{{ calcMealsMacro(newResult.data, 'protein') || '-' }}</b-td>
          <b-td class="text-center font-weight-bolder">{{ calcMealsMacro(newResult.data, 'carbs') || '-' }}</b-td>
          <b-td class="text-center font-weight-bolder">{{ calcMealsMacro(newResult.data, 'fat') || '-' }}</b-td>
          <b-td class="text-center font-weight-bolder">{{ calcMealsMacro(newResult.data, 'sodium') || '-' }}</b-td>
          <b-td colspan="2" />
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import CurriculumMealItemUpsertButton from './buttons/CurriculumMealItemUpsertButton'
import CurriculumMealItemDeleteButton from './buttons/CurriculumMealItemDeleteButton'
import CurriculumMealFoodItemUpsertButton from './buttons/CurriculumMealFoodItemUpsertButton'
import CurriculumMealFoodItemDeleteButton from './buttons/CurriculumMealFoodItemDeleteButton'
import CurriculumSupplementList from '@/components/curriculums/supplements/CurriculumSupplementList'
import CurriculumSupplementItemUpsertButton from '@/components/curriculums/supplements/buttons/CurriculumSupplementItemUpsertButton'
import MealTypes from '@/components/v1/asset/MealTypes'

export default {
  name: 'CurriculumMealList',

  mixins: [
    crudListMixin
  ],

  components: {
    CurriculumMealItemUpsertButton,
    CurriculumMealItemDeleteButton,
    CurriculumMealFoodItemUpsertButton,
    CurriculumMealFoodItemDeleteButton,
    CurriculumSupplementList,
    CurriculumSupplementItemUpsertButton,
    MealTypes
  },

  props: {
    supplementResult: {
      type: Object,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },

    foodsDisabled: {
      type: Boolean,
      default: false
    },

    supplementsDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    baseEndpoint() {
      return 'curriculum-meals'
    }
  },

  methods: {
    calcMealFoodMacro(mealFood, type) {
      const mealFoodMacro = Math.round(mealFood.amount * (mealFood.food.serving_size || 1) * mealFood.food[type]) / 100
      const decimalPlaces = type === 'calories' ? 0 : 1
      return Math.round(mealFoodMacro * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
    },

    calcMealMacro(meal, type) {
      return _.chain(meal)
        .get('meal_foods')
        .map(i => this.calcMealFoodMacro(i, type))
        .sum()
        .round(2)
        .value()
    },

    calcMealsMacro(meals, type) {
      return _.chain(meals)
        .map(i => this.calcMealMacro(i, type))
        .sum()
        .round(2)
        .value()
    },

    getSupplementsByTakenAt(taken_at) {
      return _.chain(this.supplementResult)
        .get('data')
        .filter({ taken_at })
        .value()
    }
  }
}
</script>