var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Start date",
      "label-class": "font-size-20 text-primary font-weight-bold"
    }
  }, [_vm.newItem.start_date ? _c('b-form-datepicker', {
    attrs: {
      "value": _vm.newItem.start_date,
      "disabled": ""
    }
  }) : _c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Start date has not been selected yet. ")])], 1), _c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Cardio",
      "label-class": "font-size-20 text-primary font-weight-bold"
    }
  }, [_c('CurriculumPhasesCardioInput', {
    staticClass: "mb-2",
    attrs: {
      "mode": "values",
      "disabled": "",
      "curriculum": _vm.newItem
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Nutrients",
      "label-class": "font-size-20 text-primary font-weight-bold"
    }
  }, [_c('CurriculumPhasesWaterInput', {
    staticClass: "mb-0",
    attrs: {
      "mode": "values",
      "disabled": "",
      "curriculum": _vm.item
    }
  }), _c('CurriculumPhasesCaloriesInput', {
    staticClass: "mb-0",
    attrs: {
      "mode": "values",
      "disabled": "",
      "show-header": false,
      "curriculum": _vm.item
    }
  })], 1), _c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Workout program",
      "label-class": "font-size-20 text-primary font-weight-bold"
    }
  }, [_vm.newItem.workout_program_id ? _c('b-table-simple', {
    staticClass: "bg-white",
    attrs: {
      "fixed": ""
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Program Name")]), _c('b-th', [_vm._v("Gender")]), _c('b-th', [_vm._v("Fitness Level")]), _c('b-th', [_vm._v("Goal Direction")]), _c('b-th', [_vm._v("Workouts per Week")])], 1)], 1), _c('b-tbody', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-td', [_c('WorkoutProgramItemButton', {
    staticClass: "text-decoration-underline-dotted",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "item": _vm.newItem.workout_program
    }
  }, [_vm._v(" " + _vm._s(_vm.newItem.workout_program.name) + " ")])], 1), _c('b-td', [_c('Genders', {
    attrs: {
      "ids": [_vm.newItem.workout_program.gender]
    }
  })], 1), _c('b-td', [_c('Abilities', {
    attrs: {
      "ids": _vm.newItem.workout_program.fitness_level_ids
    }
  })], 1), _c('b-td', [_c('GoalDirections', {
    attrs: {
      "ids": [_vm.newItem.workout_program.goal_direction_id]
    }
  })], 1), _c('b-td', [_vm._v(_vm._s(_vm.newItem.workout_program.workouts_per_week))])], 1)], 1)], 1) : _c('b-alert', {
    staticClass: "m-0",
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Workout program has not been selected yet. ")])], 1), _c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Supplement program",
      "label-class": "font-size-20 text-primary font-weight-bold"
    }
  }, [_vm.newItem.supplement_program_id ? _c('b-table-simple', {
    staticClass: "bg-white",
    attrs: {
      "fixed": ""
    }
  }, [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Program Name")]), _c('b-th', [_vm._v("Gender")]), _c('b-th', [_vm._v("Fitness Level")]), _c('b-th', [_vm._v("Goal Direction")])], 1)], 1), _c('b-tbody', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-td', [_vm._v(_vm._s(_vm.newItem.supplement_program.name))]), _c('b-td', [_c('Genders', {
    attrs: {
      "ids": [_vm.newItem.supplement_program.gender]
    }
  })], 1), _c('b-td', [_c('Abilities', {
    attrs: {
      "ids": _vm.newItem.supplement_program.fitness_level_ids
    }
  })], 1), _c('b-td', [_c('GoalDirections', {
    attrs: {
      "ids": [_vm.newItem.workout_program.goal_direction_id]
    }
  })], 1)], 1)], 1)], 1) : _c('b-alert', {
    staticClass: "m-0",
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Supplement program has not been selected yet. ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }