<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- NAME -->
      <b-form-group
        label="Name"
        label-cols="2"
        :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
        :state="getFieldState($v.input.name)"
      >
        <b-form-input
          v-model="input.name"
          :state="getFieldState($v.input.name)"
          placeholder="Enter Workout Name"
        />
      </b-form-group>

      <template v-if="!source">
        <!-- FITNESS LEVELS -->
        <b-form-group
          label="Fitness Level(s):"
          label-cols="2"
          :invalid-feedback="getFieldInvalidFeedback($v.input.fitness_level_ids)"
          :state="getFieldState($v.input.fitness_level_ids)"
        >
          <b-form-checkbox-group>
            <b-form-checkbox
              v-model="input.fitness_level_ids"
              v-for="option in $store.getters['asset/getAbilityLevelsOptions']"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>

        <!-- DESCRIPTION -->
        <b-form-group
          label="Description"
          label-cols="2"
          :invalid-feedback="getFieldInvalidFeedback($v.input.description)"
          :state="getFieldState($v.input.description)"
        >
          <b-form-textarea
            v-model="input.description"
            :state="getFieldState($v.input.description)"
            placeholder="Enter Workout Description"
          />
        </b-form-group>

        <!-- IS PRIVATE -->
        <b-form-group
          label="Publish"
          label-cols="2"
          :invalid-feedback="getFieldInvalidFeedback($v.input.is_private)"
          :state="getFieldState($v.input.is_private)"
        >
          <FormInputButtons
            v-model="input.is_private"
            tag="b-button-group"
            :options="[
              { value: false, text: 'Public' },
              { value: true, text: 'Private' }
            ]"
          />
        </b-form-group>
      </template>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          v-if="showCancel"
          class="mr-2"
          @click="$emit('cancel')"
        >
          Cancel
        </b-button>

        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />{{ actionName }}
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import FormInputButtons from '@/components/FormInputButtons'
import { requiredUnless } from "vuelidate/lib/validators"

export default {
  name: 'WorkoutItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    FormInputButtons
  },

  props: {
    source: {
      type: Object,
      default: null
    },

    showCancel: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      baseEndpoint: 'workouts',
      isExpanded: false
    }
  },

  computed: {
    actionName() {
      return this.isCreated ? 'Update' : this.source ? 'Copy' : 'Create'
    }
  },

  validations() {
    return {
      input: {
        name: { requiredUnless: requiredUnless('input.source_id') },
        description: { requiredUnless: requiredUnless('input.source_id') },
        fitness_level_ids: { requiredUnless: requiredUnless('input.source_id') },
        is_private: { requiredUnless: requiredUnless('input.source_id') },
        source_id: {}
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        name: this.newItem.name,
        description: this.newItem.description,
        fitness_level_ids: this.newItem.fitness_level_ids,
        is_private: this.newItem.is_private
      }

      if (!this.isCreated && this.source) {
        input.source_id = this.source.id
        input.name = `${this.source.name} -- Copy`
        input.description = this.source.description
        input.fitness_level_ids = this.source.fitness_level_ids
        input.is_private = this.source.is_private
      }

      return input
    }
  }
}
</script>