<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <NBCaliperItemUpsertButton
      :item="item"
      prefetch
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-12'
      }"
      @update:item="$emit('update:item', $event)"
    >
      Edit
    </NBCaliperItemUpsertButton>

    <NBCaliperItemDeleteButton
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-12'
      }"
      @delete:item="$emit('delete:item', $event)"
    >
      Delete
    </NBCaliperItemDeleteButton>
  </b-dropdown>
</template>

<script>
import NBCaliperItemUpsertButton from './NBCaliperItemUpsertButton'
import NBCaliperItemDeleteButton from './NBCaliperItemDeleteButton'

export default {
  name: 'NBCaliperItemActionsButton',

  components: {
    NBCaliperItemUpsertButton,
    NBCaliperItemDeleteButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  }
}
</script>