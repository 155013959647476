<script>
import appConfig from '@/app.config'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import GymUpsert from '@/components/gyms/gym-upsert'

export default {
  name: 'GymUpsertView',
  components: {
    Layout,
    PageHeader,
    GymUpsert
  },
  page: {
    title: 'Gym',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  data() {
    return {
      config: appConfig
    }
  },
  computed: {
    gymId() {
      return this.$route.params.id ? parseInt(this.$route.params.id) : null
    },
    title() {
      if (!this.canEdit) return 'View Gym'
      return this.gymId ? 'Edit Gym' : 'Add Gym'
    },
    canEdit() {
      if (this.itemId) return this.$acl.check('couldEditGym')
      return this.$acl.check('couldAddGym')
    }
  }
}
</script>

<template>
  <Layout>
    <b-row>
      <b-col>
        <PageHeader :title="title" />
      </b-col>
    </b-row>

    <b-card>
      <GymUpsert :item-id="gymId"/>
    </b-card>
  </Layout>
</template>
