<script>
import _ from 'lodash'
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Toggle from '@/components/widgets/toggle';
import FaIcon from '@/components/widgets/fa-icon';
import Switches from 'vue-switches';
import { assetComputed, authComputed } from '@/state/helpers';
import Swal from 'sweetalert2';
import WorkoutProgramItemCopyButton from '@/components/workout-programs/buttons/WorkoutProgramItemCopyButton'
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import Genders from '@/components/v1/asset/Genders'

/**
 * Advanced table component
 */
export default {
  name: 'WorkoutPrograms',
  page: {
    title: 'Workout Programs',
    meta: [{ name: 'description', content: appConfig.description }],
  },

  components: {
    Layout,
    PageHeader,
    Toggle,
    Switches,
    FaIcon,
    Abilities,
    GoalDirections,
    Genders,
    WorkoutProgramItemCopyButton
  },

  data() {
    return {
      config: appConfig,
      title: 'Workout Programs',
      totalRows: 0,
      currentPage: 1,
      perPage: appConfig.table.perPage,
      pageOptions: appConfig.table.pageOptions,
      afilter: '',
      searchQuery: '',
      filter: {
        fitness_level: '',
        gender: '',
        goal_direction: '',
        is_private: false,
        is_favorite: false,
        search: '',
      },
      isBusy: false,
      filterOn: [],
      sortBy: 'id',
      sortDesc: false,
      fields: [
        { key: 'is_favorite', label: '', tdClass: 'align-middle' },
        { key: 'name', label: 'Program Name', tdClass: 'align-middle' },
        {
          key: 'fitness_level_ids',
          sortable: false,
          label: 'Fitness Level',
          tdClass: 'align-middle'
        },
        { key: 'gender', sortable: false, tdClass: 'align-middle' },
        {
          key: 'goal_direction_id',
          sortable: false,
          label: 'Goal Direction',
          tdClass: 'align-middle'
        },
        {
          key: 'workouts_per_week',
          sortable: false,
          label: 'Workouts per Week',
          tdClass: 'align-middle'
        },
        // { key: 'published', sortable: false, tdClass: 'align-middle' }, // TODO: hide for now
        {
          key: 'action_edit',
          sortable: false,
          tdClass: 'text-center align-middle',
          label: '',
        },
        {
          key: 'action_copy',
          sortable: false,
          tdClass: 'text-center align-middle',
          label: '',
        },
        {
          key: 'action_delete',
          sortable: false,
          tdClass: 'text-center align-middle',
          label: '',
        },
      ],
    };
  },
  watch: {
    'filter.fitness_level'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.gender'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.goal_direction'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.is_favorite'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.is_private'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.search'() {
      this.currentPage = 1;
      this.triggerReload();
    },
  },
  computed: {
    ...assetComputed,
    ...authComputed,
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.debounceSetFilterSearchParam = _.debounce(async value => {
      this.filter.search = value
    }, 1000)
  },
  methods: {
    dataProvider() {
      const limit = this.perPage;
      const page = this.currentPage;
      const order_by = this.sortBy || 'name'
      const order_desc = this.sortDesc
      const filters = {}

      if (this.filter.goal_direction) {
        filters.goal_directions = [this.filter.goal_direction]
      }

      if (this.filter.gender) {
        filters.genders = [this.filter.gender]
      }

      if (this.filter.fitness_level) {
        filters.fitness_levels = [this.filter.fitness_level]
      }

      if (this.filter.is_favorite) {
        filters.is_favorite = true
      }

      if (this.filter.is_private) {
        filters.is_private = true
      }

      if (this.filter.search) {
        filters.search = this.filter.search
      }

      return this.$axios
        .get(`/workout-programs`, {
          params: { limit, page, order_by, order_desc, ...filters },
          parent: this,
        })
        .then(({ data }) => {
          this.totalRows = data.total;
          return data.data;
        });
    },
    // eslint-disable-next-line no-unused-vars
    onPublishedToggle(id, published) {
      this.$store.commit('layout/addAlerts', {
        type: 'warning',
        text: 'Nothing happens here yet',
      });
      this.triggerReload();
    },
    triggerReload() {
      this.afilter = {
        value: !this.afilter.value,
      };
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      filteredItems;
    },
    onEdit(id) {
      this.$router.push(`/workout-programs/${id}`);
    },
    onCopy() {
      this.$store.commit('layout/addAlerts', {
        type: 'warning',
        text: 'Nothing happens here yet',
      });
      //this.$router.push(`/workouts/${id}`);
    },
    onFavoriteToggle(id, is_favorite) {
      if (is_favorite) {
        this.$axios
          .patch(`workout-programs/${id}/unfavorite`)
          .then(() => this.triggerReload())
          .catch(() => this.triggerReload());
      } else {
        this.$axios
          .patch(`workout-programs/${id}/favorite`)
          .then(() => this.triggerReload())
          .catch(() => this.triggerReload());
      }
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.value) {
          this.$axios
            .delete(`workout-programs/${id}`)
            .then(() => {
              Swal.fire('Deleted!', 'Program template has been deleted.', 'success');
              this.triggerReload();
            })
            .catch((e) => {
              const message = _.get(e, 'response.data.message', 'Forecast is not deleted.')
              Swal.fire('Error Happened!', message, 'error')
              this.triggerReload();
            });
        }
      });
    },
    couldEdit(item) {
      return (
        this.$acl.check('couldEditPublicWorkoutProgram') && !item.is_private ||
        this.$acl.check('couldEditOwnWorkoutProgram') && this.currentUser.id === item.user_id
      )
    },
    couldCopy() {
      return true;
    },
    couldDelete(item) {
      return (
        this.$acl.check('couldDeletePublicWorkoutProgram') && !item.is_private ||
        this.$acl.check('couldDeleteOwnWorkoutProgram') && this.currentUser.id === item.user_id
      )
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="isBusy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <div class="row mb-md-2">
                <!-- Filters -->
                <div class="col-sm-12 col-md-3">
                  <b-input
                    :value="filter.search"
                    size="sm"
                    placeholder="Search workout program..."
                    @input="debounceSetFilterSearchParam"
                  />
                </div>
                <div class="col-sm-12 col-md-2">
                  <b-form-select
                    v-model="filter.fitness_level"
                    size="sm"
                    :options="abilityLevelsOptionsWithEmptyValue"
                  ></b-form-select>
                </div>
                <div class="col-sm-12 col-md-2">
                  <b-form-select
                    v-model="filter.gender"
                    size="sm"
                    :options="sexOptionsWithEmptyValue"
                  ></b-form-select>
                </div>
                <div class="col-sm-12 col-md-2">
                  <b-form-select
                    v-model="filter.goal_direction"
                    size="sm"
                    :options="goalDirectionsOptionsWithEmptyValue"
                  ></b-form-select>
                </div>
                <div class="col-sm-12 col-md-3">
                  <b-form-checkbox
                    class="py-1 d-inline-flex mr-4"
                    v-model="filter.is_private"
                    size="sm"
                    switch
                  >
                    Private
                  </b-form-checkbox>
                  <b-form-checkbox
                    class="py-1 d-inline-flex"
                    v-model="filter.is_favorite"
                    size="sm"
                    switch
                  >
                    Favorite
                  </b-form-checkbox>
                </div>
              </div>
              <!-- Table -->
              <div class="row">
                <div class="table-responsive mb-0">
                  <b-table
                    :items="dataProvider"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="afilter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                  >
                    <template #cell(is_favorite)="data">
                      <Toggle
                        :key="Math.random()"
                        :status="!!data.item.is_favorite"
                        @change="
                          onFavoriteToggle(data.item.id, data.item.is_favorite)
                        "
                        :data-id="data.item.id"
                      />
                    </template>
                    <template #cell(fitness_level_ids)="data">
                      <Abilities :ids="data.item.fitness_level_ids || []" />
                    </template>
                    <template #cell(goal_direction_id)="data">
                      <GoalDirections :ids="[data.item.goal_direction_id]" />
                    </template>
                    <template #cell(gender)="data">
                      <Genders :ids="[data.item.gender]" />
                    </template>
                    <template #cell(published)="data">
                      <div>
                        <switches
                          emit-on-mount=""
                          :key="data.item.id"
                          v-model="data.item.published"
                          type-bold="true"
                          :color="data.item.published ? 'success' : 'default'"
                          class="ml-2 mb-0"
                          @input="
                            onPublishedToggle(data.item.id, data.item.published)
                          "
                        ></switches>
                      </div>
                    </template>
                    <template #cell(action_edit)="data">
                      <b-button
                        v-if="couldEdit(data.item)"
                        variant="primary"
                        size="sm"
                        :to="`/workout-programs/${data.item.id}`"
                      >
                        Edit
                      </b-button>

                      <b-button
                        v-else
                        variant="link"
                        size="sm"
                        :to="`/workout-programs/${data.item.id}`"
                      >
                        Detail
                      </b-button>
                    </template>

                    <template #cell(action_copy)="data">
                      <WorkoutProgramItemCopyButton
                        :source="data.item"
                        tag="b-button"
                        :tag-props="{
                          size: 'sm',
                          variant: 'primary'
                        }"
                        @copied="$router.push(`/workout-programs/${$event.id}`)"
                      >
                        Copy
                      </WorkoutProgramItemCopyButton>
                    </template>

                    <template #cell(action_delete)="data">
                      <a
                        v-if="couldDelete(data.item)"
                        @click="onDelete(data.item.id)"
                      >
                        <FaIcon
                          icon="far fa-trash-alt"
                          :key="data.item.id"
                          size="17"
                        />
                      </a>
                    </template>
                  </b-table>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
  .vue-switcher {
    top: 8px !important;
  }
</style>
