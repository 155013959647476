<template>
  <b-overlay
    :show="isFetching"
  >
    <template v-if="isFetched">
      <b-row
        class="p-3"
      >
        <b-col lg="4">
          <b-img
            v-if="newItem.image_url"
            :src="newItem.image_url"
            fluid
            alt="Responsive image"
          />

          <b-alert
            v-else
            class="text-center"
            show
            variant="warning"
          >
            No image
          </b-alert>
        </b-col>

        <b-col
          class="pl-0"
          lg="8"
        >
          <b-table-simple
            class="input-table mb-0"
            fixed
          >
            <b-tbody>
              <b-tr>
                <b-th class="w-150px">Name</b-th>
                <b-td>{{ newItem.name }}</b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Primary Muscle(s)</b-th>

                <b-td>
                  <Muscles :ids="newItem.muscle_ids || []" />
                </b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Subcategory</b-th>

                <b-td>
                  <ExerciseSubcategories :ids="newItem.subcategory_ids || []" />
                </b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Position</b-th>

                <b-td>
                  <ExercisePositions :ids="newItem.position_ids || []" />
                </b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Bench position</b-th>

                <b-td>
                  <ExerciseBenchPositions :ids="newItem.bench_position_ids || []" />
                </b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Hand/Feet placements</b-th>

                <b-td>
                  <ExerciseHandFeetPlacements :ids="newItem.hand_feet_placement_ids || []" />
                </b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Fitness Level(s)</b-th>

                <b-td>
                  <Abilities :ids="newItem.fitness_level_ids || []" />
                </b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Workout Type</b-th>

                <b-td>
                  <WorkoutTypes :ids="newItem.type_ids || []" />
                </b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Estimated Set Time</b-th>

                <b-td>{{ newItem.estimated_set_time }}s</b-td>
              </b-tr>

              <b-tr>
                <b-th class="w-100px">Description</b-th>

                <b-td>{{ newItem.description }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </template>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import Abilities from '@/components/v1/asset/Abilities'
import Muscles from '@/components/v1/asset/Muscles'
import WorkoutTypes from '@/components/v1/asset/WorkoutTypes'
import ExerciseSubcategories from '@/components/v1/asset/ExerciseSubcategories'
import ExercisePositions from '@/components/v1/asset/ExercisePositions'
import ExerciseBenchPositions from '@/components/v1/asset/ExerciseBenchPositions'
import ExerciseHandFeetPlacements from '@/components/v1/asset/ExerciseHandFeetPlacements'
import * as image from '@/assets/images/add_exercise.png'

export default {
  name: 'ExerciseItemDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    Abilities,
    Muscles,
    WorkoutTypes,
    ExerciseSubcategories,
    ExercisePositions,
    ExerciseBenchPositions,
    ExerciseHandFeetPlacements
  },

  data() {
    return {
      baseEndpoint: 'exercises',
      image
    }
  }
}
</script>

<style scoped lang="scss">
  .input-table {
    th {
      background: #BAD9D9;
      vertical-align: middle;
      border-bottom: none;
    }
    td {
      vertical-align: middle;
      border-bottom: none;
    }

    thead {
      th {
        background: #c0c5ed;

        &:first-child {
          background: #97CBCA;
          vertical-align: middle;
        }
      }
    }

    tbody {
      background: #FFFFFF;
    }
  }
</style>