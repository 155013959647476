<template>
  <b-input-group 
    v-if="value"
    class="pb-0"
  >
    <b-form-input
      :value="serializer(value)"
      readonly
      :state="state"
      :size="size"
    />

    <template v-if="!disabled" #append>
      <b-button
        :size="size"
        @click="$emit('input', null)"
      >
        <b-icon icon="x" />
      </b-button>
    </template>
  </b-input-group>

  <b-form-input
    v-else-if="disabled"
    readonly
    :placeholder="placeholder"
    :state="state"
    :size="size"
  />

  <vue-bootstrap-typeahead
    v-else
    ref="typeahead"
    v-model="query"
    :placeholder="placeholder"
    :data="newResult.data || []"
    :serializer="serializer"
    :min-matching-chars="0"
    do-not-filter
    :state="state"
    :size="size"
    @hit="onInput"
    @focus="debounceFetchList()"
    @blur="onBlur"
  >
    <template #append>
      <slot name="append" />
    </template>
  </vue-bootstrap-typeahead>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import VueBootstrapTypeahead from '@/components/typeahead/VueBootstrapTypeahead'

export default {
  name: 'ItemSelect',

  mixins: [
    crudListMixin
  ],

  components: {
    VueBootstrapTypeahead
  },

  props: {
    value: {
      type: Object,
      default: null
    },

    item: {
      type: Object,
      default: null
    },

    listEndpoint: {
      type: String,
      required: true
    },

    serializer: {
      type: Function,
      default: (i) => i.name
    },

    placeholder: {
      type: String,
      default: 'Select an item...'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    prefetch: {
      type: Boolean,
      defualt: false
    },

    state: {
      type: Boolean,
      default: null
    },

    size: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      query: null,
      debounceFetchListTime: 500
    }
  },

  watch: {
    query: {
      immediate: true,

      handler() {
        this.newParams.filters.search = this.query

        if (this.debounceFetchList) {
          this.debounceFetchList()
        }
      }
    }
  },

  methods: {
    onInput(value) {
      this.$emit('input', value)
      this.$refs.typeahead.inputValue = ''
      this.query = ''
    },

    onBlur() {
      this.$refs.typeahead.handleInput(this.value || '')
    }
  }
}
</script>