<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <b-dropdown-item
      :to="`/users/${item.id}/profile`"
    >
      Profile
    </b-dropdown-item>

    <b-dropdown-item
      v-if="isTrainer"
      :to="`/client/list?trainers=${item.id}`"
    >
      Clients
    </b-dropdown-item>

    <UserItemPasswordUpdateButton
      v-if="showResetPasswordButton"
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @submitted="$emit('submitted')"
    >
      Reset Password
    </UserItemPasswordUpdateButton>
  </b-dropdown>
</template>

<script>
import UserItemPasswordUpdateButton from './UserItemPasswordUpdateButton'

export default {
  name: 'UserItemActionsButton',

  components: {
    UserItemPasswordUpdateButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  },

  computed: {
    isTrainer() {
      return this.item.role_ids.includes(2)
    },

    showResetPasswordButton() {
      return this.$acl.check('couldEditUserPassword')
    }
  }
}
</script>