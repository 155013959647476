<template>
  <Layout>
    <b-row class="mb-3">
      <b-col lg="8">
        <PageHeader :title="title" />
      </b-col>

      <b-col
        lg="4"
        class="d-flex align-items-center justify-content-end"
      >
        <ExerciseItemUpsertButton
          v-if="$acl.check('couldAddExercise')"
          tag="b-button"
          :tag-props="{
            variant: 'primary'
          }"
          @submitted="refresh"
        >
          Add Exercise
        </ExerciseItemUpsertButton>
      </b-col>
    </b-row>

    <b-card>
      <ExerciseList
        ref="list"
        :params="{
          filters: {
            type: null,
            muscle_ids: [],
            fitness_level_ids: [],
            is_private: null,
            is_favorite: null
          },
          order_desc: true
        }"
      />
    </b-card>
  </Layout>
</template>

<script>
import appConfig from '@/app.config'
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import ExerciseList from '@/components/exercises/ExerciseList'
import ExerciseItemUpsertButton from '@/components/exercises/buttons/ExerciseItemUpsertButton'

export default {
  name: 'ExercisesPage',

  components: {
    Layout,
    PageHeader,
    ExerciseList,
    ExerciseItemUpsertButton
  },

  page: {
    title: 'Exercises',
    meta: [{ name: 'description', content: appConfig.description }],
  },

  data() {
    return {
      config: appConfig
    }
  },

  computed: {
    title() {
      return 'Exercises'
    }
  },

  methods: {
    refresh() {
      this.$refs.list.refresh()
    }
  }
}
</script>