<script>
import _ from 'lodash';
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Toggle from '@/components/widgets/toggle';
import FaIcon from '@/components/widgets/fa-icon';
import Switches from 'vue-switches';
import { assetComputed, authComputed } from '@/state/helpers';
import Swal from 'sweetalert2';
import Abilities from '@/components/v1/asset/Abilities';
import GoalDirections from '@/components/v1/asset/GoalDirections';

/**
 * Advanced table component
 */
export default {
  name: 'ForecastListPage',
  page: {
    title: 'Forecasts',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  components: { Layout, PageHeader, Toggle, Switches, FaIcon, Abilities, GoalDirections },
  data() {
    return {
      config: appConfig,
      title: 'Forecasts',
      totalRows: 0,
      currentPage: 1,
      perPage: appConfig.table.perPage,
      pageOptions: appConfig.table.pageOptions,
      afilter: '',
      searchQuery: '',
      isBusy: false,
      filter: {
        primary_muscle: '',
        ability_level: '',
        type: '',
        favorite: false,
        search: '',
      },
      filterOn: [],
      sortBy: 'name',
      sortDesc: false,
      fields: [
        { key: 'name', sortable: false, label: 'Forecast Name', tdClass: 'align-middle' },
        { key: 'fitness_level_ids', sortable: false, label: 'Fitness Level', tdClass: 'align-middle' },
        { key: 'gender', sortable: false, tdClass: 'align-middle' },
        { key: 'goal_direction', sortable: false, label: 'Goal direction', tdClass: 'align-middle' },
        // { key: 'published', sortable: false },
        {
          key: 'action_edit',
          sortable: false,
          tdClass: 'text-center',
          label: '',
        },
        /*{
          key: 'action_copy',
          sortable: false,
          tdClass: 'text-center',
          label: '',
        },*/
        {
          key: 'action_delete',
          sortable: false,
          tdClass: 'text-center align-middle',
          label: '',
        },
      ],
    };
  },
  watch: {
    'filter.primary_muscle'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.ability_level'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.type'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.favorite'() {
      this.currentPage = 1;
      this.triggerReload();
    },
    'filter.search'() {
      this.currentPage = 1;
      this.triggerReload();
    },
  },
  computed: {
    ...assetComputed,
    ...authComputed,
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },
  },
  created() {
    this.debounceSetFilterSearchParam = _.debounce(async value => {
      this.filter.search = value
    }, 1000)
  },
  methods: {
    dataProvider() {
      const limit = this.perPage;
      const page = this.currentPage;
      const search = this.filter.search;
      const params = {
        limit,
        page,
        search
      }

      return this.$axios
        .get(`/forecast`, { parent: this, params })
        .then(({ data }) => {
          this.totalRows = data.total;
          return data.data;
        });
    },
    // eslint-disable-next-line no-unused-vars
    onPublishedToggle(id, published) {
      this.$store.commit('layout/addAlerts', {
        type: 'warning',
        text: 'Nothing happens here yet',
      });
      this.triggerReload();
    },
    triggerReload() {
      this.afilter = {
        value: !this.afilter.value,
      };
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      filteredItems;
    },
    onEdit(id) {
      this.$router.push(`/forecast/${id}`);
    },
    onCopy(id) {
      id;
      this.$store.commit('layout/addAlerts', {
        type: 'warning',
        text: 'Nothing happens here yet',
      });
      //this.$router.push(`/workouts/${id}`);
    },
    onDelete(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      }).then(async (result) => {
        if (result.value) {
          this.$axios
            .delete(`forecast/${id}`)
            .then(() => {
              Swal.fire('Deleted!', 'Forecast has been deleted.', 'success');
              this.triggerReload();
            })
            .catch(({ response }) => {
              let msg = 'Forecast is not deleted.';
              let err = response.data.error;
              if (err.code === '23503') {
                msg += ' Because it was added to one or several Program templates.' +
                       ' You should remove this forecast from all Program templates' +
                       ' to be able to delete it.'
              }
              Swal.fire('Error Happened!', msg, 'error');
              this.triggerReload();
            });
        }
      });
    },
    couldEdit(item) {
      return (
        this.$acl.check('couldEditPublicForecast') && !item.is_private ||
        this.$acl.check('couldEditOwnForecast') && this.currentUser.id === item.created_by
      )
    },
    couldCopy(item) {
      item;
      return true;
    },
    couldDelete(item) {
      return (
        this.$acl.check('couldDeletePublicForecast') && !item.is_private ||
        this.$acl.check('couldDeleteOwnForecast') && this.currentUser.id === item.created_by
      )
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />

    <div class="row">
      <div class="col-12">
        <b-overlay
          :show="isBusy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">Forecasts</h4>
              <p class="card-title-desc"></p>

              <b-row>
                <b-col
                  lg="3"
                  class="mb-2"
                >
                  <b-input
                    :value="filter.search"
                    size="sm"
                    placeholder="Search forecast..."
                    @input="debounceSetFilterSearchParam"
                  />
                </b-col>
              </b-row>

              <!-- Table -->
              <div class="table-responsive mb-0">
                <b-table
                  :items="dataProvider"
                  :fields="fields"
                  responsive="sm"
                  :per-page="perPage"
                  :current-page="currentPage"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="afilter"
                  :filter-included-fields="filterOn"
                  @filtered="onFiltered"
                >
                  <template #cell(favorite)="data">
                    <Toggle
                      :key="Math.random()"
                      :status="!!data.item.is_favorite"
                      @change="
                        onFavoriteToggle(data.item.id, data.item.is_favorite)
                      "
                      :data-id="data.item.id"
                    />
                  </template>
                  <template #cell(fitness_level_ids)="data">
                    <Abilities :ids="data.item.fitness_level_ids || []" />
                  </template>
                  <template #cell(goal_direction)="data">
                    <GoalDirections :ids="[data.item.goal_direction]" />
                  </template>
                  <template #cell(published)="data">
                    <div>
                      <switches
                        emit-on-mount=""
                        :key="data.item.id"
                        v-model="data.item.published"
                        type-bold="true"
                        :color="data.item.published ? 'success' : 'default'"
                        class="ml-2 mb-0"
                        @input="
                          onPublishedToggle(data.item.id, data.item.published)
                        "
                      ></switches>
                    </div>
                  </template>
                  <template #cell(action_edit)="data">
                    <b-button
                      variant="primary"
                      v-if="couldEdit(data.item)"
                      @click="onEdit(data.item.id)"
                      >Edit</b-button
                    >
                  </template>
                  <!--<template #cell(action_copy)="data">
                    <b-button
                      variant="success"
                      v-if="couldCopy(data.item)"
                      @click="onCopy(data.item.id)"
                      >Copy</b-button
                    >
                  </template>-->
                  <template #cell(action_delete)="data">
                    <a
                      v-if="couldDelete(data.item)"
                      @click="onDelete(data.item.id)"
                    >
                      <FaIcon
                        icon="far fa-trash-alt"
                        :key="data.item.id"
                        size="17"
                    /></a>
                  </template>
                </b-table>
              </div>
              <div class="row">
                <div class="col-sm-12 col-md-6"></div>
                <div class="col-sm-12 col-md-6">
                  <div
                    class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <!-- pagination -->
                      <b-pagination
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
  .vue-switcher {
    top: 8px !important;
  }
</style>
