var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "accountbg",
    style: {
      background: 'url(' + require('@/assets/images/bg.jpg') + ')',
      'background-size': 'cover',
      'background-position': 'center'
    }
  }), _c('div', {
    staticClass: "wrapper-page account-page-full",
    staticStyle: {
      "width": "100%",
      "max-width": "420px"
    }
  }, [_c('div', {
    staticClass: "card shadow-none"
  }, [_c('div', {
    staticClass: "card-block"
  }, [_c('div', {
    staticClass: "account-box"
  }, [_c('div', {
    staticClass: "card-box shadow-none p-4"
  }, [_c('div', {
    staticClass: "p-2"
  }, [_c('div', {
    staticClass: "text-center mt-4"
  }, [_c('router-link', {
    attrs: {
      "tag": "a",
      "to": "/"
    }
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo.png"),
      "height": "175",
      "alt": "logo"
    }
  })])], 1), _c('h4', {
    staticClass: "font-size-18 mt-5 text-center"
  }, [_vm._v("Welcome Back !")]), _c('p', {
    staticClass: "text-muted text-center"
  }, [_vm._v(" Sign in to continue to USA Elite. ")]), _c('form', {
    staticClass: "mt-4",
    on: {
      "submit": function submit(e) {
        e.preventDefault();
      }
    }
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "username"
    }
  }, [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.email,
      expression: "email"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "text",
      "id": "email",
      "placeholder": "Enter email"
    },
    domProps: {
      "value": _vm.email
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.email = $event.target.value;
      }
    }
  }), _vm.errors.email && _vm.touch.password ? _c('p', {
    staticClass: "input-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.email) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group"
  }, [_c('label', {
    attrs: {
      "for": "userpassword"
    }
  }, [_vm._v("Password")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.password,
      expression: "password"
    }],
    staticClass: "form-control",
    attrs: {
      "type": "password",
      "id": "userpassword",
      "placeholder": "Enter password"
    },
    domProps: {
      "value": _vm.password
    },
    on: {
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.password = $event.target.value;
      }
    }
  }), _vm.errors.password && _vm.touch.password ? _c('p', {
    staticClass: "input-error"
  }, [_vm._v(" " + _vm._s(_vm.errors.password) + " ")]) : _vm._e()]), _c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col",
    staticStyle: {
      "display": "flex",
      "align-items": "center"
    }
  }, [_c('div', {
    staticClass: "custom-control custom-checkbox"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.remember,
      expression: "remember"
    }],
    staticClass: "custom-control-input",
    attrs: {
      "type": "checkbox",
      "id": "customControlInline"
    },
    domProps: {
      "checked": Array.isArray(_vm.remember) ? _vm._i(_vm.remember, null) > -1 : _vm.remember
    },
    on: {
      "change": function change($event) {
        var $$a = _vm.remember,
            $$el = $event.target,
            $$c = $$el.checked ? true : false;

        if (Array.isArray($$a)) {
          var $$v = null,
              $$i = _vm._i($$a, $$v);

          if ($$el.checked) {
            $$i < 0 && (_vm.remember = $$a.concat([$$v]));
          } else {
            $$i > -1 && (_vm.remember = $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.remember = $$c;
        }
      }
    }
  }), _c('label', {
    staticClass: "custom-control-label",
    attrs: {
      "for": "customControlInline"
    }
  }, [_vm._v("Remember me")])])]), _c('div', {
    staticClass: "col text-right"
  }, [_c('button', {
    staticClass: "btn btn-primary w-md",
    attrs: {
      "disabled": _vm.send
    },
    on: {
      "click": _vm.tryToLogIn
    }
  }, [_vm._v(" Log In ")])])]), _c('div', {
    staticClass: "form-group mt-2 mb-0 row"
  }, [_c('div', {
    staticClass: "col-12 mt-3"
  }, [_c('router-link', {
    attrs: {
      "tag": "a",
      "to": "/password-reset"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-lock"
  }), _vm._v(" Forgot your password? ")])], 1)])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }