<template>
  <Layout>
    <b-overlay
      :show="isFetching"
    >
      <b-row>
        <b-col cols="5">
          <PageHeader :title="title" />
        </b-col>

        <b-col cols="7" class="d-flex align-items-center justify-content-end">
          <b-button-group>
            <b-button
              v-for="(step, stepIndex) in steps"
              :key="step.id"
              :class="{
                'active': stepIndex === currentStepIndex,
                'completed': stepIndex < currentStepIndex
              }"
              :disabled="step.isDisabled"
              :to="`#${step.id}`"
            >
              {{ step.title }}
            </b-button>
          </b-button-group>
        </b-col>
      </b-row>

      <b-row
        v-if="isFetched"
        no-gutters
      >
        <b-col lg="3">
          <CurriculumItemProfileSummary
            :item.sync="newItem"
          />
        </b-col>

        <b-col lg="9">
          <b-card
            class="bg-transparent"
            body-class="py-0"
          >
            <CurriculumItemPreviewDetail
              v-if="currentStep.id === 'preview'"
              :key="$route.fullPath"
              :item.sync="newItem"
              :prefetch="false"
            />

            <CurriculumItemCardioDetail
              v-if="currentStep.id === 'cardio'"
              :key="$route.fullPath"
              :item.sync="newItem"
              :prefetch="false"
            />

            <CurriculumItemSupplementsDetail
              v-if="currentStep.id === 'supplements'"
              :key="$route.fullPath"
              :item.sync="newItem"
              :prefetch="false"
            />

            <CurriculumItemNutritionDetail
              v-if="currentStep.id === 'nutrition'"
              :key="$route.fullPath"
              :item.sync="newItem"
              :prefetch="false"
            />

            <CurriculumItemScheduleDetail
              v-if="currentStep.id === 'schedule'"
              :key="$route.fullPath"
              :item.sync="newItem"
              :prefetch="false"
            />
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </Layout>
</template>

<script>
import _ from 'lodash'
import Layout from '../../layouts/main'
import crudItem from '@/mixins/crud-item'
import PageHeader from '@/components/page-header'
import CurriculumItemProfileSummary from '@/components/curriculums/CurriculumItemProfileSummary'
import CurriculumItemPreviewDetail from '@/components/curriculums/detail/CurriculumItemPreviewDetail'
import CurriculumItemCardioDetail from '@/components/curriculums/detail/CurriculumItemCardioDetail'
import CurriculumItemSupplementsDetail from '@/components/curriculums/detail/CurriculumItemSupplementsDetail'
import CurriculumItemNutritionDetail from '@/components/curriculums/detail/CurriculumItemNutritionDetail'
import CurriculumItemScheduleDetail from '@/components/curriculums/detail/CurriculumItemScheduleDetail'

export default {
  name: 'CurriculumViewPage',

  mixins: [
    crudItem
  ],

  components: {
    Layout,
    PageHeader,
    CurriculumItemPreviewDetail,
    CurriculumItemProfileSummary,
    CurriculumItemCardioDetail,
    CurriculumItemSupplementsDetail,
    CurriculumItemNutritionDetail,
    CurriculumItemScheduleDetail
  },

  props: {
    item: {
      type: Object,
      default: function() {
        return { id: _.toNumber(this.$route.params.id) }
      }
    }
  },

  page() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  computed: {
    title() {
      return 'Curriculum'
    },

    steps() {
      const steps = [
        { id: 'preview', title: 'Preview', isVisible: true },
        { id: 'cardio', title: 'Cardio', isVisible: true },
        { id: 'supplements', title: 'Supplements', isVisible: true },
        { id: 'nutrition', title: 'Nutrition', isVisible: true },
        { id: 'schedule', title: 'Schedule', isVisible: true }
      ]

      return _.filter(steps, { isVisible: true })
    },

    currentStepIndex: {
      get() {
        const index = _.findIndex(this.steps, { id: this.$route.hash.replace('#', '') })
        return index >= 0 ? index : 0
      },

      set(index) {
        const path = `/curriculums/${this.item.id}/view`
        const step = _.get(this.steps, `${index}.id`) || 'preview'
        this.$router.push({ path: path, hash: `#${step}` })
      }
    },

    currentStep() {
      return this.steps[this.currentStepIndex]
    }
  },

  watch: {
    '$route.hash': {
      immediate: true,
      deep: true,
      handler() {
        this.refresh()
      }
    }
  }
}
</script>

<style scoped>
  .btn-group .btn.active {
    background: #626ed4 !important;
    /*border-color: #626ed4 !important;*/
  }
  .btn-group .btn.completed {
    background: #02a499 !important;
    /*border-color: #02a499 !important;*/
  }
  .client-summary-card {
    background: #d7dbf4;
  }
  
  .client-summary-card .card-header {
    background: inherit;
    text-align: center;
  }
</style>
