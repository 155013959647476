var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Supplement",
      "label-cols-sm": "3",
      "label-cols-lg": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.supplement_id),
      "state": _vm.getFieldState(_vm.$v.input.supplement_id)
    }
  }, [_c('SupplementSelect', {
    attrs: {
      "item": _vm.newItem.supplement,
      "placeholder": "Select a supplement or add new...",
      "state": _vm.getFieldState(_vm.$v.input.supplement_id)
    },
    model: {
      value: _vm.input.supplement_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "supplement_id", $$v);
      },
      expression: "input.supplement_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Taken at",
      "label-cols-sm": "3",
      "label-cols-lg": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.taken_at),
      "state": _vm.getFieldState(_vm.$v.input.taken_at)
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "placeholder": "Taken at",
      "locale": "en",
      "state": _vm.getFieldState(_vm.$v.input.taken_at)
    },
    model: {
      value: _vm.input.taken_at,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "taken_at", $$v);
      },
      expression: "input.taken_at"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Amount",
      "label-cols-sm": "3",
      "label-cols-lg": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.amount),
      "state": _vm.getFieldState(_vm.$v.input.amount)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Amount",
      "type": "number",
      "number": "",
      "step": "0.01",
      "state": _vm.getFieldState(_vm.$v.input.amount)
    },
    model: {
      value: _vm.input.amount,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "amount", $$v);
      },
      expression: "input.amount"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mt-2 mb-0"
  }, [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }