var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [!_vm.newResult.data && _vm.isBusy ? _c('div', [_vm._v(" Loading... ")]) : !_vm.newResult.data ? _c('div', [_vm._v(" Not loaded ")]) : !_vm.newResult.data.length ? _c('div', [_vm._v(" No results ")]) : _c('b-row', _vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-col', {
      key: itemIndex,
      attrs: {
        "cols": parseInt(12 / _vm.cols)
      }
    }, [_c('b-row', {
      attrs: {
        "align-v": "center"
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "8"
      }
    }, [_c('b-badge', {
      staticClass: "p-0",
      attrs: {
        "variant": "light"
      }
    }, [_vm._v(" " + _vm._s(_vm.$moment(item.taken_at).format('l')) + " ")]), _c('b-badge', {
      attrs: {
        "variant": "light"
      }
    }, [_c('ImageCategories', {
      attrs: {
        "ids": [item.category]
      }
    })], 1)], 1), _c('b-col', {
      staticClass: "text-right",
      attrs: {
        "cols": "4"
      }
    }, [_c('ImageItemActionsButton', {
      attrs: {
        "item": item,
        "client": _vm.client,
        "dropdown-props": {
          toggleClass: 'bg-transparent text-primary p-0 border-0'
        }
      },
      on: {
        "update:item": function updateItem($event) {
          _vm.refresh();

          _vm.$emit('update:item', $event);
        },
        "delete:item": function deleteItem($event) {
          _vm.refresh();

          _vm.$emit('delete:item', $event);
        }
      }
    }, [_c('b-icon-three-dots')], 1)], 1)], 1), _c('div', {
      staticClass: "position-relative"
    }, [_c('b-img', {
      staticClass: "bg-light object-fit-cover w-100",
      style: {
        height: _vm.cols === 1 ? '300px' : '175px'
      },
      attrs: {
        "src": item.url
      }
    }), _c('div', {
      staticClass: "favorite-button p-2"
    }, [_c('ImageItemFavoriteButton', {
      staticClass: "font-size-20",
      style: {
        cursor: 'pointer'
      },
      attrs: {
        "tag": "b-icon-heart-fill",
        "tag-props": {
          variant: item.is_favorite ? 'danger' : 'white'
        },
        "item": item
      },
      on: {
        "update:item": function updateItem($event) {
          _vm.refresh();

          _vm.$emit('update:item', $event);
        }
      }
    })], 1)], 1)], 1);
  }), 1), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }