var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "pr-2"
  }, [_c('b-input-group', {
    attrs: {
      "append": "ft"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.feetOptions,
      "state": _vm.state
    },
    model: {
      value: _vm.feet,
      callback: function callback($$v) {
        _vm.feet = $$v;
      },
      expression: "feet"
    }
  })], 1)], 1), _c('b-col', [_c('b-input-group', {
    attrs: {
      "append": "in"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.inchesOptions,
      "state": _vm.state
    },
    model: {
      value: _vm.inches,
      callback: function callback($$v) {
        _vm.inches = $$v;
      },
      expression: "inches"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }