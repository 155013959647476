<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      fixed
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <!-- CREATED -->
      <template #cell(created)="row">
        {{ row.item.created }}
      </template>

      <!-- AGREEMENT -->
      <template #cell(agreement)="row">
        <CompanyPaymentItemAgreement
          :item="row.item"
          tag="b-button"
          :tag-props="{
            variant: 'success',
            size: 'sm'
          }"
        >
          View Agreement
        </CompanyPaymentItemAgreement>
      </template>

      <!-- RECEIPT -->
      <template #cell(receipt)="row">
        <b-button
          v-if="row.item.charges.data.length"
          variant="success"
          size="sm"
          :href="row.item.charges.data[0]?.receipt_url"
          target="_blank"
        >
          View Receipt
        </b-button>

        <template v-else>
          No Receipt
        </template>
      </template>

      <!-- DETAILS -->
      <template #cell(details)="row">
        <div class="font-weight-bolder">
          ${{ row.item.amount / 100 }}
        </div>

        <div v-if="row.item.company_app_subscription">
          {{ row.item.company_app_subscription.company_app_plan.description }}
        </div>

        <div v-else-if="row.item.company_product_purchase">
          {{ row.item.company_product_purchase.quantity }} x {{ row.item.company_product_purchase.company_product.description }}
        </div>

        <div v-else-if="row.item.curriculum_product_installment">
          {{ row.item.curriculum_product_installment.curriculum_product.description }}
        </div>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'
import crudListMixin from '@/mixins/crud-list'
import CompanyPaymentItemAgreement from './CompanyPaymentItemAgreement'

export default {
  name: 'CompanyPaymentList',

  mixins: [
    crudListMixin
  ],

  components: {
    CompanyPaymentItemAgreement
  },

  props: {
    columns: {
      type: Array,

      default: () => ([
        'created',
        'agreement',
        'details',
        'receipt'
      ])
    }
  },

  data() {
    return {
      baseEndpoint: 'company-payments'
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    fields() {
      const fields = [
        { key: 'created', sortable: true, label: 'Agreement Date' },
        { key: 'agreement', sortable: false, label: 'Agreement' },
        { key: 'receipt', sortable: false, label: 'Receipt' },
        { key: 'details', sortable: false, label: 'Details' }
      ]

      return _.filter(fields, i => this.columns.includes(i.key))
    }
  }
}
</script>