<template>
  <component
    :is="tag"
    :href="agreementUrl"
    target="_blank"
    v-bind="tagProps"
  >
    <slot />
  </component>
</template>

<script>
export default {
  name: 'CompanyPaymentItemAgreement',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'b-button'
    },

    tagProps: {
      type: Object,
      default: () => ({
        size: 'sm'
      })
    }
  },

  computed: {
    agreementUrl() {
      let agreementUrl

      if (this.item.company_app_subscription) {
        agreementUrl = this.item.company_app_subscription.company_app_plan.agreement_url
      } else if (this.item.company_product_purchase) {
        agreementUrl = this.item.company_product_purchase.company_product.agreement_url
      } else if (this.item.curriculum_product_installment) {
        agreementUrl = this.item.curriculum_product_installment.curriculum_product.agreement_url
      }

      return agreementUrl
    }
  }
}
</script>