var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [!_vm.mode || _vm.mode === 'program' ? _c('b-form-group', {
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.workout_program_id),
      "state": _vm.getFieldState(_vm.$v.input.workout_program_id)
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Suggested Programs"
    }
  }, [_c('b-card', {
    staticClass: "bg-white",
    attrs: {
      "no-body": ""
    }
  }, [_c('WorkoutProgramSelect', {
    attrs: {
      "is-suggested": "",
      "params": {
        filters: {
          workouts_per_week: _vm.availableWorkoutsPerWeek,
          fitness_levels: [_vm.item.profile.fitness_level],
          goal_directions: [_vm.item.profile.goal_direction],
          genders: [_vm.item.profile.sex]
        }
      }
    },
    on: {
      "input": _vm.unsetWorkouts
    },
    model: {
      value: _vm.input.workout_program_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "workout_program_id", $$v);
      },
      expression: "input.workout_program_id"
    }
  })], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Other Programs"
    }
  }, [_c('b-card', {
    staticClass: "bg-white",
    attrs: {
      "no-body": ""
    }
  }, [_c('WorkoutProgramSelect', {
    attrs: {
      "params": {
        filters: {
          workouts_per_week_exclude: _vm.availableWorkoutsPerWeek,
          fitness_levels_exclude: [_vm.item.profile.fitness_level],
          goal_directions_exclude: [_vm.item.profile.goal_direction],
          genders_exclude: [_vm.item.profile.sex]
        }
      }
    },
    on: {
      "input": _vm.unsetWorkouts
    },
    model: {
      value: _vm.input.workout_program_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "workout_program_id", $$v);
      },
      expression: "input.workout_program_id"
    }
  })], 1)], 1)], 1) : _vm._e(), !_vm.mode || _vm.mode === 'data' ? [_c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0",
      "justified": "",
      "no-fade": ""
    }
  }, _vm._l(_vm.newItem.phases, function (phase) {
    return _c('b-tab', {
      key: phase.id,
      attrs: {
        "lazy": "",
        "title-link-class": "rounded-0 border-0",
        "title": "Phase ".concat(phase.number)
      }
    }, [_c('CurriculumPhaseWorkouts', {
      attrs: {
        "item": phase,
        "curriculum": _vm.item
      }
    })], 1);
  }), 1)] : _vm._e(), _c('b-form-group', {
    staticClass: "text-right my-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }