<template>
  <b-overlay
    :show="isBusy"
    :variant="config.overlay.variant"
    :opacity="config.overlay.opacity"
    :blur="config.overlay.blur"
    rounded="sm"
  >
    <b-table
      class="table-select mb-0"
      :class="{
        'is-suggested': isSuggested
      }"
      :items="fetchList"
      :fields="fields"
      responsive="sm"
      :per-page="newParams.limit"
      :current-page="newParams.page"
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
      @row-clicked="selectedIds = [$event.id]"
    >
      <!-- SELECTED -->
      <template #cell(selected)="row">
        <b-icon
          variant="primary"
          font-scale="1.3"
          :icon="selectedIds.includes(row.item.id) ? 'check-circle-fill' : 'circle'"
        />
      </template>

      <template #cell(name)="data">
        <WorkoutProgramPopupButton
          :id="data.item.id"
          class="text-decoration-underline-dotted"
        >
          {{ data.item.name }}
        </WorkoutProgramPopupButton>
      </template>

      <template #cell(fitness_level_ids)="data">
        <Abilities :ids="data.item.fitness_level_ids" />
      </template>

      <template #cell(goal_direction_id)="data">
        <GoalDirections :ids="[data.item.goal_direction_id]" />
      </template>

      <template #cell(gender)="data">
        <Genders :ids="[data.item.gender]" />
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>
<script>
import _ from 'lodash'
import appConfig from '@/app.config'
import WorkoutProgramPopupButton from '@/components/buttons/workout-program-popup-button'
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import Genders from '@/components/v1/asset/Genders'

export default {
  name: 'WorkoutProgramSelect',

  components: {
    WorkoutProgramPopupButton,
    Abilities,
    GoalDirections,
    Genders
  },

  props: {
    value: {
      type: Number,
      default: null
    },

    isSuggested: {
      type: Boolean,
      default: false
    },

    showSelect: {
      type: Boolean,
      default: true
    },

    result: {
      type: Object,
      default: null
    },

    params: {
      type: Object,
      default: null
    },

    pagination: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      isBusy: false,
      config: appConfig,
      newResult: {
        data: null,
        total: null
      },
      newParams: {
        filters: {},
        page: 1,
        limit: 10,
        order_by: null,
        order_desc: null
      },

      fields: [
        ...(this.showSelect ? [
          { key: 'selected', sortable: false, label: '', tdClass: 'text-center' }
        ] : []),
        { key: 'name', sortable: false, label: 'Program Name' },
        { key: 'gender', sortable: false, label: 'Gender' },
        { key: 'fitness_level_ids', sortable: false, label: 'Fitness Level' },
        { key: 'goal_direction_id', sortable: false, label: 'Goal Direction' },
        { key: 'workouts_per_week', sortable: false, label: 'Max. # of Workouts per Week' },
      ]
    }
  },

  computed: {
    showPagination() {
      return this.pagination && this.newResult.total > this.newParams.limit
    },

    selectedIds: {
      get() {
        return this.value ? [this.value] : [];
      },
      
      set(values) {
        const value = values.length ? values[0] : null
        this.$emit('input', value)
      }
    }
  },

  watch: {
    result: {
      immediate: true,
      deep: true,
      handler() {
        this.setNewResult()
      }
    },

    params: {
      immediate: true,
      deep: true,
      handler() {
        if (!_.isEqual(this.params, this.newParams)) {
          this.setNewParams()

          if (this.prefetch) {
            this.fetchList()
          }
        }
      }
    },

    newParams: {
      immediate: true,
      deep: true,
      async handler() {
        this.fetchList()
      }
    },

    newResult: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.newResult && !_.isEqual(this.newResult, this.result)) {
          this.$emit('update:result', _.cloneDeep(this.newResult))
        }
      }
    }
  },

  methods: {
    setNewResult() {
      const newResult = _.cloneDeep({
        ...this.newResult,
        ..._.pick(this.result, [
          'data',
          'total'
        ])
      })
      if (!_.isEqual(newResult, this.newResult)) {
        this.newResult = newResult
      }
    },

    setNewParams() {
      const newParams = _.cloneDeep({
        ...this.newParams,
        ..._.pick(this.params, [
          'filters',
          'page',
          'limit',
          'order_by',
          'order_desc'
        ])
      })
      if (!_.isEqual(newParams, this.newParams)) {
        this.newParams = newParams
      }
    },

    async fetchList() {
      const limit = this.newParams.limit
      const page = this.newParams.page
      const filters = this.newParams.filters
      const order_by = this.newParams.order_by
      const order_desc = this.newParams.order_desc

      return this.$axios
        .get(`/workout-programs`, {
          parent: this,
          params: { limit, page, order_by, order_desc, ...filters }
        })
        .then(({ data }) => {
          this.newResult.data = data.data
          this.newResult.total = data.total
          return data.data
        })
    },

    refresh() {
      this.fetchList()
    }
  }
}
</script>

<style scoped>
  .table-select >>> table tbody tr {
    cursor: pointer;
  }
  .table-select.is-suggested >>> table tbody tr {
    background: #d7dbf4;
  }
  .table-select.is-suggested >>> table tbody tr:nth-child(odd) {
    background: #c0c5ed;
  }
</style>