var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.newItems, function (newItem, newItemIndex) {
    return _c('div', {
      key: newItemIndex
    }, [!newItem && _vm.isFetching ? _c('b-input-group', {
      scopedSlots: _vm._u([{
        key: "append",
        fn: function fn() {
          return [_c('b-button', {
            attrs: {
              "disabled": ""
            }
          }, [_c('b-spinner', {
            attrs: {
              "small": ""
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('b-form-input', {
      attrs: {
        "value": "Loading...",
        "readonly": "",
        "state": _vm.state
      }
    })], 1) : !newItem ? _c('b-input-group', {
      scopedSlots: _vm._u([{
        key: "append",
        fn: function fn() {
          return [_c('b-button', {
            on: {
              "click": function click($event) {
                return _vm.removeValues([_vm.newValues[newItemIndex]]);
              }
            }
          }, [_c('b-icon', {
            attrs: {
              "icon": "x"
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('b-form-input', {
      attrs: {
        "value": _vm.newValues[newItemIndex],
        "readonly": "",
        "state": _vm.state
      }
    })], 1) : _c('ItemSelect', {
      "class": {
        'pt-2': newItemIndex >= 1
      },
      attrs: {
        "value": newItem,
        "list-endpoint": _vm.listEndpoint,
        "params": _vm.params,
        "disabled": _vm.disabled,
        "serializer": function serializer(i) {
          return "".concat(i.name);
        }
      },
      on: {
        "input": function input($event) {
          !$event ? _vm.removeValues([newItem.id]) : null;
        }
      }
    })], 1);
  }), _vm.showMore ? _c('ItemSelect', {
    "class": {
      'pt-2': _vm.newItems.length > 0
    },
    attrs: {
      "list-endpoint": _vm.listEndpoint,
      "placeholder": _vm.placeholder,
      "params": _vm.params,
      "serializer": function serializer(i) {
        return "".concat(i.name);
      },
      "disabled": _vm.disabled,
      "state": _vm.state
    },
    on: {
      "input": function input($event) {
        return _vm.addValue($event);
      }
    },
    scopedSlots: _vm._u([_vm.showCreate ? {
      key: "append",
      fn: function fn() {
        return [_c('SupplementItemUpsertButton', {
          attrs: {
            "tag": "b-button",
            "item": _vm.itemToCreate,
            "tag-props": {
              variant: 'primary'
            },
            "show-roles": false
          },
          on: {
            "update:item": function updateItem($event) {
              return _vm.addValue($event);
            }
          }
        }, [_vm._v(" Add new ")])];
      },
      proxy: true
    } : null], null, true)
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }