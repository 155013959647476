<template>
  <div>
    <div
      v-for="(newItem, newItemIndex) in newItems"
      :key="newItemIndex"
    >
      <b-input-group 
        v-if="!newItem && isFetching"
      >
        <b-form-input
          value="Loading..."
          readonly
          :state="state"
        />

        <template #append>
          <b-button disabled>
            <b-spinner small />
          </b-button>
        </template>
      </b-input-group>

      <b-input-group 
        v-else-if="!newItem"
      >
        <b-form-input
          :value="newValues[newItemIndex]"
          readonly
          :state="state"
        />

        <template #append>
          <b-button
            @click="removeValues([newValues[newItemIndex]])"
          >
            <b-icon icon="x" />
          </b-button>
        </template>
      </b-input-group>

      <ItemSelect
        v-else
        :class="{
          'pt-2': newItemIndex >= 1
        }"
        :value="newItem"
        :list-endpoint="listEndpoint"
        :params="params"
        :disabled="disabled"
        :serializer="i => `${i.supplement.name}`"
        @input="!$event ? removeValues([newItem.id]) : null"
      />
    </div>

    <ItemSelect
      v-if="showMore"
      :class="{
        'pt-2': newItems.length > 0
      }"
      :list-endpoint="listEndpoint"
      :placeholder="placeholder"
      :params="params"
      :serializer="i => `${i.supplement.name}`"
      :disabled="disabled"
      :state="state"
      @input="addValue($event)"
    />
  </div>
</template>

<script>
import _ from 'lodash'
import ItemSelect from '@/components/ItemSelect'

export default {
  name: 'CurriculumPhaseSupplementSelect',

  components: {
    ItemSelect
  },

  props: {
    value: {
      type: [Number, Array],
      default: null
    },

    multiple: {
      type: Boolean,
      default: false
    },

    multipleLimit: {
      type: Number,
      default: 0
    },

    item: {
      type: Object,
      default: null
    },

    items: {
      type: Array,
      default: null
    },

    params: {
      type: Object,
      default: () => ({})
    },

    placeholder: {
      type: String,
      default: 'Select a supplement...'
    },

    disabled: {
      type: Boolean,
      default: false
    },

    listEndpoint: {
      type: String,
      default: 'curriculum-phase-supplements'
    },

    state: {
      type: Boolean,
      default: null
    }
  },

  data() {
    return {
      fetchedItems: [],
      selectedItems: []
    }
  },

  computed: {
    allItems() {
      return _.chain([this.item, ...this.items || [], ...this.fetchedItems, ...this.selectedItems])
        .filter(i => i)
        .value()
    },

    newValues() {
      let newValues

      if (this.multiple) {
        newValues = this.value || []
      } else {
        newValues = this.value ? [this.value] : []
      }
      return newValues
    },

    newItems() {
      return _.map(this.newValues, id => _.find(this.allItems, { id }))
    },

    idsToFetch() {
      return _.filter(this.newValues, id => !_.find(this.allItems, { id }))
    },

    showMore() {
      let showAdd
      
      if (this.multiple) {
        showAdd = !this.multipleLimit || this.multipleLimit > this.newValues.length
      } else {
        showAdd = !this.value
      } 

      if (this.disabled && this.newValues.length) {
        showAdd = false
      }

      return showAdd
    }
  },

  watch: {
    value: {
      immediate: true,
      deep: true,

      handler() {
        if (this.idsToFetch.length) {
          this.fetchItems()
        }
      }
    }
  },

  methods: {
    removeValues(ids) {
      const newValues = _.filter(this.newValues, i => !ids.includes(i))
      const newItems = _.map(newValues, id => _.find(this.allItems, { id }))

      if (this.multiple) {
        this.$emit('input', newValues)
        this.$emit('update:items', newItems)

      } else {
        this.$emit('input', _.get(newValues, '0', null))
        this.$emit('update:item', _.get(newItems, '0'))
      }
    },

    addValue(item) {
      this.selectedItems.push(item)
      const newValues = _.uniq([...this.newValues, item.id])
      const newItems = _.map(newValues, id => _.find(this.allItems, { id }))

      if (this.multiple) {
        this.$emit('input', newValues)
        this.$emit('update:items', newItems)

      } else {
        this.$emit('input', _.get(newValues, '0', null))
        this.$emit('update:item', _.get(newItems, '0'))
      }
    },

    async fetchItems() {
      const ids = this.idsToFetch
      const filters = this.params.filters
      this.isFetching = true

      await this.$axios
        .get(this.listEndpoint, {
          params: { 
            ...filters,
            ids
          }
        })
        .then(({ data }) => {
          this.isFetching = false
          this.fetchedItems = data.data


        })
        .catch((e) => {
          this.isFetching = false
          throw e
        })
    }
  } 
}
</script>