<template>
  <b-overlay
    :show="isFetching"
  >
    <div
      v-if="isCreated && !isFetched"
      class="text-center"
    >
      {{ isFetching ? 'Loading...' : 'Item does not exist or you have no permissions to access it.' }}
    </div>

    <b-form
      v-else
      @submit.prevent="submit('submitted')"
    >
      <b-alert
        v-if="isCreated && ownersIsEditable && !input.owner_ids.length"
        show
        variant="warning"
      >
        The company has no owners, please add one or more owners. 
      </b-alert>

      <b-form-group
        label="Company name"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
        :state="getFieldState($v.input.name)"
      >
        <b-form-input
          v-model="input.name"
          placeholder="Enter company name"
          :state="getFieldState($v.input.name)"
        />
      </b-form-group>

      <b-form-group
        label="Email"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.email)"
        :state="getFieldState($v.input.email)"
      >
        <b-form-input
          v-model="input.email"
          placeholder="Enter company email"
          :state="getFieldState($v.input.email)"
        />
      </b-form-group>

      <b-form-group
        label="Phone"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.phone)"
        :state="getFieldState($v.input.phone)"
      >
        <b-form-input
          v-model="input.phone"
          placeholder="Enter company phone"
          :state="getFieldState($v.input.phone)"
        />
      </b-form-group>

      <b-form-group
        label="Address"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.address_line1)"
        :state="getFieldState($v.input.address_line1)"
      >
        <b-form-input
          v-model="input.address_line1"
          placeholder="Enter company address"
          :state="getFieldState($v.input.address_line1)"
        />
      </b-form-group>

      <b-form-group
        label="Address line 2"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.address_line2)"
        :state="getFieldState($v.input.address_line2)"
      >
        <b-form-input
          v-model="input.address_line2"
          placeholder="Enter company address line 2"
          :state="getFieldState($v.input.address_line2)"
        />
      </b-form-group>

      <b-form-group
        label="City"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.city)"
        :state="getFieldState($v.input.city)"
      >
        <b-form-input
          v-model="input.city"
          placeholder="Enter company city"
          :state="getFieldState($v.input.city)"
        />
      </b-form-group>

      <b-form-group
        label="State"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.state)"
        :state="getFieldState($v.input.state)"
      >
        <b-form-input
          v-model="input.state"
          placeholder="Enter company state"
          :state="getFieldState($v.input.state)"
        />
      </b-form-group>

      <b-form-group
        label="Zip"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.zip)"
        :state="getFieldState($v.input.zip)"
      >
        <b-form-input
          v-model="input.zip"
          placeholder="Enter company zip"
          :state="getFieldState($v.input.zip)"
        />
      </b-form-group>

      <b-form-group
        label="Status"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.status)"
        :state="getFieldState($v.input.status)"
      >
        <b-form-select
          v-if="statusIsEditable"
          v-model="input.status"
          :options="[
            { value: 1, text: 'Active' },
            { value: 0, text: 'Inactive' }
          ]"
          :state="getFieldState($v.input.status)"
        />

        <b-form-select
          v-else
          :value="newItem.status"
          disabled
          :options="[
            { value: 1, text: 'Active' },
            { value: 0, text: 'Inactive' }
          ]"
        />
      </b-form-group>

      <b-form-group
        label="Stripe secret key"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
      >
        <b-alert
          v-if="newItem.has_stripe_secret_key"
          show
          variant="success"
        >
          Company has stripe secret key.
        </b-alert>

        <b-alert
          v-else
          show
          variant="warning"
        >
          Company has no stripe secret key.
        </b-alert>
      </b-form-group>

      <b-form-group
        label="Stripe signing secret"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
      >
        <b-alert
          v-if="newItem.has_stripe_signing_secret"
          show
          variant="success"
        >
          Company has stripe signing secret.
        </b-alert>

        <b-alert
          v-else
          show
          variant="warning"
        >
          Company has no stripe signing secret.
        </b-alert>
      </b-form-group>

      <b-form-group
        label="Owners"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.owner_ids)"
        :state="getFieldState($v.input.owner_ids)"
      >
        <UserSelect
          v-if="ownersIsEditable"
          v-model="input.owner_ids"
          list-endpoint="admin/users"
          :items="newItem.owners"
          :show-create="isCreated"
          :item-to-create="isCreated ? { company_id: newItem.id, role_ids: [3] } : null"
          :company-to-create="isCreated ? newItem : null"
          multiple
          placeholder="Select an owner..."
        />

        <UserSelect
          v-else
          list-endpoint="admin/users"
          :value="newItem.owners ? newItem.owners.map(i => i.id) : null"
          :items="newItem.owners"
          disabled
          multiple
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right my-4">
        <b-button
          variant="success"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required, email, maxLength, minLength } from "vuelidate/lib/validators"
import UserSelect from '@/components/users/UserSelect'
import _ from 'lodash'

export default {
  name: 'CompanyItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    UserSelect
  },

  computed: {
    baseEndpoint() {
      return this.$acl.check('isAdmin') ? 'admin/companies' : 'companies'
    },

    ownersIsEditable() {
      return this.$acl.check('isAdmin')
    },

    statusIsEditable() {
      return this.$acl.check('isAdmin')
    }
  },

  validations() {
    const input = {
      name: { required, maxLength: maxLength(80) },
      email: { required, email },
      phone: { required, maxLength: maxLength(80) },
      city: { required, maxLength: maxLength(80) },
      state: { required, maxLength: maxLength(80) },
      zip: { required },
      address_line1: { required, maxLength: maxLength(80) },
      address_line2: { maxLength: maxLength(80) }
    }

    if (this.ownersIsEditable && this.isCreated) {
      input.owner_ids = { required, minLength: minLength(1) }      
    }

    if (this.statusIsEditable) {
      input.status = { required }
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        name: this.newItem.name,
        email: this.newItem.email,
        phone: this.newItem.phone,
        city: this.newItem.city,
        state: this.newItem.state,
        zip: this.newItem.zip,
        address_line1: this.newItem.address_line1,
        address_line2: this.newItem.address_line2
      }

      if (this.ownersIsEditable) {
        input.owner_ids = _.map(this.newItem.owners, 'id')
      }

      if (this.statusIsEditable) {
        input.status = this.newItem.status
      }

      return input
    },

    getSubmitSuccessMessage(newItem, oldItem) {
      const action = oldItem.id ? 'updated' : 'created'

      return `Company "${newItem.name}" has been ${action}`
    }
  }
}
</script>