var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phase number",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.phase_number),
      "state": _vm.getFieldState(_vm.$v.input.phase_number)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [1, 2, 3, 4],
      "disabled": _vm.isCreated,
      "state": _vm.getFieldState(_vm.$v.input.phase_number)
    },
    model: {
      value: _vm.input.phase_number,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phase_number", $$v);
      },
      expression: "input.phase_number"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Goal direction",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.goal_direction_id),
      "state": _vm.getFieldState(_vm.$v.input.goal_direction_id)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getGoalDirectionsOptions'],
      "disabled": _vm.isCreated,
      "state": _vm.getFieldState(_vm.$v.input.goal_direction_id)
    },
    model: {
      value: _vm.input.goal_direction_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "goal_direction_id", $$v);
      },
      expression: "input.goal_direction_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Gender",
      "label-cols-sm": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.gender),
      "state": _vm.getFieldState(_vm.$v.input.gender)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getSexOptions'],
      "disabled": _vm.isCreated,
      "state": _vm.getFieldState(_vm.$v.input.gender)
    },
    model: {
      value: _vm.input.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "gender", $$v);
      },
      expression: "input.gender"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Calories ratio",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.calories_ratio),
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio)
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "fixed": "",
      "striped": "",
      "stacked": "sm"
    }
  }, [_c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "w-120px"
  }), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Carbs")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Fats")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Protein")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v("Totals")])], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', {
    staticClass: "align-middle font-weight-bolder",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Multiplier (cal) ")]), _c('b-td', {
    staticClass: "text-center align-middle"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.calories_ratio.carbs.multiplier),
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.carbs.multiplier)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center hide-arrows",
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.0001",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.carbs.multiplier)
    },
    model: {
      value: _vm.input.calories_ratio.carbs.multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.input.calories_ratio.carbs, "multiplier", $$v);
      },
      expression: "input.calories_ratio.carbs.multiplier"
    }
  })], 1)], 1), _c('b-td', {
    staticClass: "text-center align-middle"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.calories_ratio.fats.multiplier),
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.fats.multiplier)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center hide-arrows",
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.0001",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.fats.multiplier)
    },
    model: {
      value: _vm.input.calories_ratio.fats.multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.input.calories_ratio.fats, "multiplier", $$v);
      },
      expression: "input.calories_ratio.fats.multiplier"
    }
  })], 1)], 1), _c('b-td', {
    staticClass: "text-center align-middle"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.calories_ratio.protein.multiplier),
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.protein.multiplier)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center hide-arrows",
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.0001",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.protein.multiplier)
    },
    model: {
      value: _vm.input.calories_ratio.protein.multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.input.calories_ratio.protein, "multiplier", $$v);
      },
      expression: "input.calories_ratio.protein.multiplier"
    }
  })], 1)], 1), _c('b-td', {
    staticClass: "text-center align-middle"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.caloriesRatioTotals.multiplier),
      "state": _vm.getFieldState(_vm.$v.caloriesRatioTotals.multiplier)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center",
    attrs: {
      "value": _vm.caloriesRatioTotals.multiplier,
      "disabled": "",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.caloriesRatioTotals.multiplier)
    }
  })], 1)], 1)], 1), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle font-weight-bolder",
    attrs: {
      "variant": "success"
    }
  }, [_vm._v(" Multiplier (gm) ")]), _c('b-td', {
    staticClass: "text-center align-middle"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.calories_ratio.carbs.grams_multiplier),
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.carbs.grams_multiplier)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center hide-arrows",
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.0001",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.carbs.grams_multiplier)
    },
    model: {
      value: _vm.input.calories_ratio.carbs.grams_multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.input.calories_ratio.carbs, "grams_multiplier", $$v);
      },
      expression: "input.calories_ratio.carbs.grams_multiplier"
    }
  })], 1)], 1), _c('b-td', {
    staticClass: "text-center align-middle"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.calories_ratio.fats.grams_multiplier),
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.fats.grams_multiplier)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center hide-arrows",
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.0001",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.fats.grams_multiplier)
    },
    model: {
      value: _vm.input.calories_ratio.fats.grams_multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.input.calories_ratio.fats, "grams_multiplier", $$v);
      },
      expression: "input.calories_ratio.fats.grams_multiplier"
    }
  })], 1)], 1), _c('b-td', {
    staticClass: "text-center align-middle"
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.calories_ratio.protein.grams_multiplier),
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.protein.grams_multiplier)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center hide-arrows",
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.0001",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.input.calories_ratio.protein.grams_multiplier)
    },
    model: {
      value: _vm.input.calories_ratio.protein.grams_multiplier,
      callback: function callback($$v) {
        _vm.$set(_vm.input.calories_ratio.protein, "grams_multiplier", $$v);
      },
      expression: "input.calories_ratio.protein.grams_multiplier"
    }
  })], 1)], 1), _c('b-td', {
    staticClass: "text-center align-middle"
  })], 1)], 1)], 1)], 1), _c('b-form-group', {
    attrs: {
      "label": "Meals ratio",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.meals_ratio),
      "state": _vm.getFieldState(_vm.$v.input.meals_ratio)
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "fixed": "",
      "striped": "",
      "stacked": "sm"
    }
  }, [_c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "w-70px"
  }), _c('b-th', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v("Carbs")]), _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("7/d")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("6/d")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("5/d")])], 1)], 1), _c('b-th', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v("Fats")]), _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("7/d")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("6/d")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("5/d")])], 1)], 1), _c('b-th', {
    staticClass: "text-center"
  }, [_c('div', {
    staticClass: "mb-2"
  }, [_vm._v("Protein")]), _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_vm._v("7/d")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("6/d")]), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_vm._v("5/d")])], 1)], 1)], 1)], 1), _c('b-tbody', [_vm._l(_vm.input.meals_ratio, function (meal, mealIndex) {
    return _c('b-tr', {
      key: mealIndex
    }, [_c('b-td', {
      staticClass: "align-middle font-weight-bolder",
      attrs: {
        "variant": "success"
      }
    }, [_vm._v(" Meal " + _vm._s(mealIndex + 1) + " ")]), _c('b-td', {
      staticClass: "text-center"
    }, [_c('b-row', {
      attrs: {
        "align-v": "center",
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('b-form-input', {
      staticClass: "text-center hide-arrows",
      attrs: {
        "type": "number",
        "number": "",
        "step": "0.0001",
        "size": "sm",
        "state": _vm.getFieldState(_vm.$v.input.meals_ratio.$each[mealIndex].carbs)
      },
      model: {
        value: meal.carbs,
        callback: function callback($$v) {
          _vm.$set(meal, "carbs", $$v);
        },
        expression: "meal.carbs"
      }
    })], 1), _c('b-col', {
      attrs: {
        "cols": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.sixMealsRatio[mealIndex] ? Math.round(_vm.sixMealsRatio[mealIndex].carbs * 100) / 100 : '-') + " ")]), _c('b-col', {
      attrs: {
        "cols": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.fiveMealsRatio[mealIndex] ? Math.round(_vm.fiveMealsRatio[mealIndex].carbs * 100) / 100 : '-') + " ")])], 1)], 1), _c('b-td', {
      staticClass: "text-center"
    }, [_c('b-row', {
      attrs: {
        "align-v": "center",
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('b-form-input', {
      staticClass: "text-center hide-arrows",
      attrs: {
        "type": "number",
        "number": "",
        "step": "0.0001",
        "size": "sm",
        "state": _vm.getFieldState(_vm.$v.input.meals_ratio.$each[mealIndex].fats)
      },
      model: {
        value: meal.fats,
        callback: function callback($$v) {
          _vm.$set(meal, "fats", $$v);
        },
        expression: "meal.fats"
      }
    })], 1), _c('b-col', {
      attrs: {
        "cols": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.sixMealsRatio[mealIndex] ? Math.round(_vm.sixMealsRatio[mealIndex].fats * 100) / 100 : '-') + " ")]), _c('b-col', {
      attrs: {
        "cols": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.fiveMealsRatio[mealIndex] ? Math.round(_vm.fiveMealsRatio[mealIndex].fats * 100) / 100 : '-') + " ")])], 1)], 1), _c('b-td', {
      staticClass: "text-center"
    }, [_c('b-row', {
      attrs: {
        "align-v": "center",
        "no-gutters": ""
      }
    }, [_c('b-col', {
      attrs: {
        "cols": "6"
      }
    }, [_c('b-form-input', {
      staticClass: "text-center hide-arrows",
      attrs: {
        "type": "number",
        "number": "",
        "step": "0.0001",
        "size": "sm",
        "state": _vm.getFieldState(_vm.$v.input.meals_ratio.$each[mealIndex].protein)
      },
      model: {
        value: meal.protein,
        callback: function callback($$v) {
          _vm.$set(meal, "protein", $$v);
        },
        expression: "meal.protein"
      }
    })], 1), _c('b-col', {
      attrs: {
        "cols": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.sixMealsRatio[mealIndex] ? Math.round(_vm.sixMealsRatio[mealIndex].protein * 100) / 100 : '-') + " ")]), _c('b-col', {
      attrs: {
        "cols": "3"
      }
    }, [_vm._v(" " + _vm._s(_vm.fiveMealsRatio[mealIndex] ? Math.round(_vm.fiveMealsRatio[mealIndex].protein * 100) / 100 : '-') + " ")])], 1)], 1)], 1);
  }), _c('b-tr', {
    attrs: {
      "variant": "secondary"
    }
  }, [_c('b-td', {
    staticClass: "align-middle font-weight-bolder"
  }, [_vm._v(" Totals ")]), _c('b-th', {
    staticClass: "text-center align-middle"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.mealsRatioTotals.carbs),
      "state": _vm.getFieldState(_vm.$v.mealsRatioTotals.carbs)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center",
    attrs: {
      "value": _vm.mealsRatioTotals.carbs,
      "disabled": "",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.mealsRatioTotals.carbs)
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  })], 1)], 1), _c('b-th', {
    staticClass: "text-center align-middle"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.mealsRatioTotals.fats),
      "state": _vm.getFieldState(_vm.$v.mealsRatioTotals.fats)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center",
    attrs: {
      "value": _vm.mealsRatioTotals.fats,
      "disabled": "",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.mealsRatioTotals.fats)
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  })], 1)], 1), _c('b-th', {
    staticClass: "text-center align-middle"
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.mealsRatioTotals.protein),
      "state": _vm.getFieldState(_vm.$v.mealsRatioTotals.protein)
    }
  }, [_c('b-form-input', {
    staticClass: "text-center",
    attrs: {
      "value": _vm.mealsRatioTotals.protein,
      "disabled": "",
      "size": "sm",
      "state": _vm.getFieldState(_vm.$v.mealsRatioTotals.protein)
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  })], 1)], 1)], 1)], 2)], 1)], 1), _c('b-form-group', {
    staticClass: "mt-2 mb-0"
  }, [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }