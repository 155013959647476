<template>
  <autocomplete
    :value="value"
    :placeholder="placeholder"
    :options="options"
    :serializer="i => i"
    @input="$emit('input', $event)"
  />
</template>

<script>
import Autocomplete from './autocomplete'

export default {
  name: 'TimezoneSelect',

  components: {
    Autocomplete
  },

  props: {
    value: {
      type: String,
      default: null
    },

    placeholder: {
      type: String,
      default: 'Type to select...'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    options() {
      return this.$moment.tz.names()
    }
  }
}
</script>