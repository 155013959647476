<template>
  <b-overlay
    :show="isFetching"
  >
    <b-card class="mb-0">
      <b-form
        @submit.prevent="submit('submitted')"
      >
        <b-form-group>
          <div class="text-center font-size-24 font-weight-bolder">
            {{ input.water || 0 }}<template v-if="waterRecommended"> / {{ waterRecommended }}</template>
          </div>

          <div class="text-center font-weight-bolder">Ounces</div>

          <div class="text-center">
            <b-button-group>
              <b-button
                variant="list"
                @click="input.water += 1"
              >
                <b-icon-plus-circle />
              </b-button>

              <b-button
                variant="list"
                @click="input.water ? input.water -= 1 : null"
              >
                <b-icon-dash-circle />
              </b-button>
            </b-button-group>
          </div>
        </b-form-group>

        <!-- SUBMIT -->
        <b-form-group class="text-right mb-0">
          <b-button
            variant="success"
            type="submit"
            :disabled="isSubmitting"
          >
            <b-spinner v-if="isSubmitting" small />Log Water
          </b-button>
        </b-form-group>
      </b-form>
    </b-card>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'

export default {
  name: 'TrackerNutritionWaterUpdate',

  mixins: [
    crudItemMixin
  ],

  props: {
    waterRecommended: {
      type: Number,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'tracker-nutritions'
    }
  },

  methods: {
    getInput() {
      const input = {
        water: _.get(this.newItem, 'water', null)
      }

      return input
    }
  }
}
</script>