<script>
import appConfig from '@/app.config'
import { email, required, minLength, maxLength } from 'vuelidate/lib/validators'
import { companyComputed } from '@/state/helpers';

export default {
  name: 'GymUpsert',
  props: {
    itemId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...companyComputed,
    newItem() {
      return {
        company_id: this.currentCompany.id,
        status: 1,
        work_hours: [
          {
            day: 0,
            time_from: '00:00:00',
            time_to: '23:59:59'
          }
        ]
      }
    },
    canEdit() {
      if (this.itemId) return this.$acl.check('couldEditGym')
      return this.$acl.check('couldAddGym')
    }
  },
  data() {
    return {
      isLoading: false,
      isSaving: false,
      config: appConfig,
      item: {}
    }
  },
  validations() {
    return {
      item: {
        name: { required, maxLength: maxLength(255) },
        description: { minLength: minLength(5), maxLength: maxLength(255) },
        email: { required, email },
        phone: { required },
        city: { required },
        state: { required },
        zip: { required },
        address_line1: { required },
        status: { required },
        work_hours: { required }
      }
    }
  },
  methods: {
    saveItem() {
      this.$v.item.$touch()

      if (this.$v.item.$error) return

      this.isSaving = true

      if (this.item.id) {
        this.$axios
          .put(`/gym/${this.item.id}`, this.item, { parent: this })
          .then(() => this.$router.push('/gym/list'))
          .finally(() => this.isSaving = false)
      } else {
        this.$axios
          .post(`/gym`, this.item, { parent: this })
          .then(() => this.$router.push('/gym/list'))
          .finally(() => this.isSaving = false)
      }
    },
    fetchItem() {
      if (!this.itemId) return this.newItem

      this.isLoading = true

      return this.$axios
        .get(`/gym/${this.itemId}`, { parent: this })
        .then(({ data }) => data)
        .finally(() => this.isLoading = false)
    },
    statusOptions() {
      return [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ]
    },
    dayOptions() {
      return [
        { value: 0, text: 'Always' },
        { value: 1, text: 'MO' },
        { value: 2, text: 'TU' },
        { value: 3, text: 'WE' },
        { value: 4, text: 'TH' },
        { value: 5, text: 'FR' },
        { value: 6, text: 'SA' },
        { value: 7, text: 'SU' }
      ]
    },
    dayText(value) {
      return this.dayOptions().find((day) => day.value === value).text
    },
    addWorkHours() {
      this.item.work_hours.push({ day: 0, time_from: '00:00:00', time_to: '23:59:00' })
    },
    removeWorkHours(whIndex) {
      this.item.work_hours.splice(whIndex, 1)
    },
    setWorkHoursDay(wh, value) {
      wh.day = value
    },
    fieldState(value) {
      return value && value.$dirty ? !value.$invalid : null
    },
    fieldError(value) {
      if (!value) return ''
      if (!value.required) return 'This value is required.'
      if (!value.email) return 'Provide valid email.'
      if (!value.minLength) return 'Minimum 5 letters.'
      if (!value.maxLength) return 'Maximum 255 letters.'
      return ''
    }
  },
  watch: {
    itemId: {
      immediate: true,
      async handler() {
        this.item = await this.fetchItem()
      }
    }
  }
}
</script>

<template>
  <b-overlay
    :show="isLoading"
    :variant="config.overlay.variant"
    :opacity="config.overlay.opacity"
    :blur="config.overlay.blur"
    rounded="sm"
  >
    <b-form>
      <b-form-group
        label="Name"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.name)"
        :state="fieldState($v.item.name)"
      >
        <b-form-input
          v-model="item.name"
          placeholder="Enter gym name"
          :state="fieldState($v.item.name)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="Description"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.description)"
        :state="fieldState($v.item.description)"
      >
        <b-form-textarea
          v-model="item.description"
          placeholder="Enter gym description"
          :state="fieldState($v.item.description)"
          :disabled="!canEdit"
          rows="3"
          no-resize
        />
      </b-form-group>
      <b-form-group
        label="Email"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.email)"
        :state="fieldState($v.item.email)"
      >
        <b-form-input
          v-model="item.email"
          placeholder="Enter gym email"
          :state="fieldState($v.item.email)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="Phone"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.phone)"
        :state="fieldState($v.item.phone)"
      >
        <b-form-input
          v-model="item.phone"
          placeholder="Enter gym phone"
          :state="fieldState($v.item.phone)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="Address"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.address_line1)"
        :state="fieldState($v.item.address_line1)"
      >
        <b-form-input
          v-model="item.address_line1"
          placeholder="Enter gym address"
          :state="fieldState($v.item.address_line1)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="Address line 2"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.address_line2)"
        :state="fieldState($v.item.address_line2)"
      >
        <b-form-input
          v-model="item.address_line2"
          placeholder="Enter gym address line 2"
          :state="fieldState($v.item.address_line2)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="City"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.city)"
        :state="fieldState($v.item.city)"
      >
        <b-form-input
          v-model="item.city"
          placeholder="Enter gym city"
          :state="fieldState($v.item.city)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="State"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.state)"
        :state="fieldState($v.item.state)"
      >
        <b-form-input
          v-model="item.state"
          placeholder="Enter gym state"
          :state="fieldState($v.item.state)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="Zip"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.zip)"
        :state="fieldState($v.item.zip)"
      >
        <b-form-input
          v-model="item.zip"
          placeholder="Enter gym zip"
          :state="fieldState($v.item.zip)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="Status"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.status)"
        :state="fieldState($v.item.status)"
      >
        <b-form-select
          v-model="item.status"
          :options="statusOptions()"
          :state="fieldState($v.item.status)"
          :disabled="!canEdit"
        />
      </b-form-group>
      <b-form-group
        label="Work hours"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="6"
        content-cols-lg="5"
        :invalid-feedback="fieldError($v.item.work_hours)"
        :state="fieldState($v.item.work_hours)"
        :class="{ 'mb-0': !canEdit }"
      >
        <b-input-group
          v-for="(wh, whIndex) in item.work_hours"
          :key="whIndex"
          :class="{ 'mb-2': whIndex + 1 < item.work_hours.length }"
        >
          <template #prepend>
            <b-dropdown
              variant="outline-primary"
              :disabled="!canEdit"
            >
              <template v-slot:button-content>
                {{ dayText(wh.day) }}
                <i class="mdi mdi-chevron-down"></i>
              </template>
              <b-dropdown-item
                v-for="(day, dayIndex) in dayOptions()"
                :key="dayIndex"
                @click="setWorkHoursDay(wh, day.value)"
              >
                {{ day.text }}
              </b-dropdown-item>
            </b-dropdown>
          </template>
          <b-form-timepicker
            v-model="wh.time_from"
            :state="fieldState($v.item.work_hours)"
            :disabled="!canEdit"
          />
          <b-form-timepicker
            v-model="wh.time_to"
            :state="fieldState($v.item.work_hours)"
            :disabled="!canEdit"
          />
          <template #append v-if="canEdit">
            <b-button
              variant="danger"
              @click="removeWorkHours(whIndex)"
            >
              <b-icon icon="trash"/>
            </b-button>
          </template>
        </b-input-group>
        <b-link
          class="d-flex align-items-center mt-2"
          v-if="item.work_hours && item.work_hours.length < 7 && canEdit"
          href="#"
          @click="addWorkHours()"
        >
          <b-icon icon="plus"/> Add more
        </b-link>
        <span
          class="d-flex align-items-center mt-2"
          v-if="(!item.work_hours || item.work_hours.length < 1) && !canEdit"
        >
          -
        </span>
      </b-form-group>
      <b-form-group class="mb-0" v-if="canEdit">
        <b-button variant="primary" @click="saveItem()">
          <i class="fa fa-cog fa-spin" v-if="isSaving"></i> Save
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<style scoped>
  .input-group-prepend >>> .dropdown {
    min-width: 70px;
  }
</style>
