var render = function () {
  var _vm$$v$selectedMealFo, _vm$$v$selectedMealFo2, _vm$$v$selectedMealFo3, _vm$$v$selectedMealFo4, _vm$$v$selectedMealFo5, _vm$$v$selectedMealFo6, _vm$$v$selectedMealFo7, _vm$$v$selectedMealFo8, _vm$$v$selectedMealFo9, _vm$$v$selectedMealFo10, _vm$$v$selectedMealFo11, _vm$$v$selectedMealFo12, _vm$$v$selectedMealFo13, _vm$$v$selectedMealFo14, _vm$selectedMealFood, _vm$selectedMealFood2, _vm$selectedMealFood3, _vm$$v$selectedMealSu, _vm$$v$selectedMealSu2, _vm$$v$selectedMealSu3, _vm$$v$selectedMealSu4, _vm$selectedMealSuppl, _vm$selectedMealSuppl2, _vm$selectedMealSuppl3;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "row mb-3"
  }, [_c('div', {
    staticClass: "col"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v("Client Meal Log")])]), _c('div', {
    staticClass: "col text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.showMealModal();
      }
    }
  }, [_c('i', {
    staticClass: "fa fa-plus"
  }), _vm._v(" Add meal ")])], 1)]), !_vm.typical_meals.length ? _c('div', {
    staticClass: "mb-3"
  }, [_vm._v(" No meals added yet. ")]) : _vm._e(), _vm.typical_meals.length ? _c('div', [_c('div', {
    staticClass: "row no-gutters"
  }, [_c('div', {
    staticClass: "col-lg-2 cell"
  }, [_vm._v(" ")]), _c('div', {
    staticClass: "col row no-gutters"
  }, [_c('div', {
    staticClass: "col-lg-4 cell cell-header"
  }, [_vm._v("Item")]), _c('div', {
    staticClass: "col-lg-2 cell cell-header"
  }, [_vm._v("Serving Size")]), _c('div', {
    staticClass: "col cell cell-header"
  }, [_vm._v("Calories")]), _c('div', {
    staticClass: "col cell cell-header"
  }, [_vm._v("Protein")]), _c('div', {
    staticClass: "col cell cell-header"
  }, [_vm._v("Carbs")]), _c('div', {
    staticClass: "col cell cell-header"
  }, [_vm._v("Fats")]), _c('div', {
    staticClass: "col cell cell-header"
  }, [_vm._v("Sodium")]), _c('div', {
    staticClass: "col cell cell-header"
  }, [_vm._v(" ")])]), _c('div', {
    staticClass: "col-lg-1 cell cell-header"
  }, [_vm._v(" ")])]), _vm._l(_vm.typical_meals, function (m) {
    return _c('div', {
      key: m.id,
      staticClass: "row row-meal mb-1 no-gutters"
    }, [_c('div', {
      staticClass: "col-lg-2 cell col-header"
    }, [_c('div', [_c('p', {
      staticClass: "font-weight-bold mb-0"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.showMealModal(m);
        }
      }
    }, [_vm._v(" " + _vm._s(m.name) + " "), m.type === 'pre-workout' ? _c('b-badge', {
      staticClass: "align-middle"
    }, [_vm._v(" Pre Workout ")]) : _vm._e(), m.type === 'post-workout' ? _c('b-badge', {
      staticClass: "align-middle"
    }, [_vm._v(" Post Workout ")]) : _vm._e()], 1)]), _vm._v(" " + _vm._s(_vm.moment(m.taken_at, 'HH:mm:ss').format('hh:mm A')) + " ")])]), _c('div', {
      staticClass: "col d-flex flex-column"
    }, [_vm._l(m.foods, function (f) {
      return _c('div', {
        key: f.id,
        staticClass: "row no-gutters row-food"
      }, [_c('div', {
        staticClass: "col-lg-4 cell cell-food-name"
      }, [_c('a', {
        attrs: {
          "href": "#"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.showMealItemModal(f, m);
          }
        }
      }, [_vm._v(_vm._s(f.name))])]), _c('div', {
        staticClass: "col-lg-2 cell cell-base"
      }, [_vm._v(" " + _vm._s(f.taken_amount) + " " + _vm._s(f.serving_units) + " ")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v(" " + _vm._s(_vm.calcCalories(f) || '-') + " ")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v(" " + _vm._s(_vm.calcProtein(f) || '-') + " gm ")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v(" " + _vm._s(_vm.calcCarbs(f) || '-') + " gm ")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v(" " + _vm._s(_vm.calcFat(f) || '-') + " gm ")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v(" " + _vm._s(_vm.calcSodium(f) || '-') + " mg ")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_c('a', {
        attrs: {
          "href": "#"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.deleteMealFood(f);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-trash-alt"
      })])])]);
    }), _vm._l(m.supplements, function (s) {
      return _c('div', {
        key: s.id,
        staticClass: "row no-gutters row-food"
      }, [_c('div', {
        staticClass: "col-lg-4 cell cell-food-name"
      }, [_c('a', {
        attrs: {
          "href": "#"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.showMealItemModal(s, m);
          }
        }
      }, [_vm._v(_vm._s(s.name))])]), _c('div', {
        staticClass: "col-lg-2 cell cell-base"
      }, [_vm._v(" " + _vm._s(s.taken_amount) + " " + _vm._s(s.serving_units) + " ")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v("-")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v("-")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v("-")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v("-")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_vm._v("-")]), _c('div', {
        staticClass: "col cell cell-base"
      }, [_c('a', {
        attrs: {
          "href": "#"
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.deleteMealSupplement(s, m);
          }
        }
      }, [_c('i', {
        staticClass: "fa fa-trash-alt"
      })])])]);
    }), _c('div', {
      staticClass: "row no-gutters row-food h-100"
    }, [_c('div', {
      staticClass: "col-lg-4 cell cell-food-button"
    }, [_c('b-button', {
      attrs: {
        "variant": "primary",
        "size": "sm"
      },
      on: {
        "click": function click($event) {
          return _vm.showMealItemModal(null, m);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-plus"
    }), _vm._v(" Add item ")])], 1), _c('div', {
      staticClass: "col cell cell-base"
    })])], 2), _c('div', {
      staticClass: "col-lg-1 cell cell-base d-flex justify-content-center align-items-center"
    }, [_c('a', {
      attrs: {
        "href": "#"
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.deleteMeal(m);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash-alt fa-lg"
    })])])]);
  }), _c('div', {
    staticClass: "row no-gutters mb-3"
  }, [_c('div', {
    staticClass: "col-lg-2 cell"
  }, [_vm._v(" ")]), _c('div', {
    staticClass: "col row row-totals no-gutters"
  }, [_c('div', {
    staticClass: "col-lg-4 cell cell-footer"
  }, [_vm._v("Daily Totals")]), _c('div', {
    staticClass: "col-lg-2 cell cell-footer"
  }, [_vm._v(" ")]), _c('div', {
    staticClass: "col cell cell-footer"
  }, [_vm._v(_vm._s(_vm.totalCalories()))]), _c('div', {
    staticClass: "col cell cell-footer"
  }, [_vm._v(_vm._s(_vm.totalProtein()))]), _c('div', {
    staticClass: "col cell cell-footer"
  }, [_vm._v(_vm._s(_vm.totalCarbs()))]), _c('div', {
    staticClass: "col cell cell-footer"
  }, [_vm._v(_vm._s(_vm.totalFat()))]), _c('div', {
    staticClass: "col cell cell-footer"
  }, [_vm._v(_vm._s(_vm.totalSodium()))]), _c('div', {
    staticClass: "col cell cell-footer"
  }, [_vm._v(" ")])]), _c('div', {
    staticClass: "col-lg-1 cell cell-footer"
  }, [_vm._v(" ")])])], 2) : _vm._e(), _vm.client ? _c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Diet selector (select all that apply):",
      "label-for": "dietary_restrictions"
    }
  }, _vm._l(_vm.dietaryOptions(), function (item) {
    return _c('b-button', {
      key: item.value,
      staticClass: "mr-2 mb-1",
      attrs: {
        "pressed": _vm.client.dietary_restrictions.indexOf(item.value) !== -1,
        "variant": _vm.client.dietary_restrictions.indexOf(item.value) !== -1 ? 'primary' : ''
      },
      on: {
        "click": function click($event) {
          return _vm.toggleDietaryRestriction(item.value);
        }
      }
    }, [_vm._v(" " + _vm._s(item.text) + " ")]);
  }), 1), _vm.client.dietary_restrictions.length ? _c('b-form-group', {
    attrs: {
      "label": "If dietary restriction applies, please provide more detail:",
      "label-for": "dietary_restriction_notes"
    }
  }, [_c('b-form-textarea', {
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.$v.client.dietary_restriction_notes.$error
    },
    attrs: {
      "rows": "5"
    },
    model: {
      value: _vm.client.dietary_restriction_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.client, "dietary_restriction_notes", $$v);
      },
      expression: "client.dietary_restriction_notes"
    }
  }), _vm.$v.client.dietary_restriction_notes.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.client.dietary_restriction_notes.minLength ? _c('span', [_vm._v(" Minimum 5 letters. ")]) : _vm._e(), !_vm.$v.client.dietary_restriction_notes.maxLength ? _c('span', [_vm._v(" Maximum 255 letters. ")]) : _vm._e()]) : _vm._e()], 1) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Notes:",
      "label-for": "dietary_notes"
    }
  }, [_c('b-form-textarea', {
    staticClass: "form-control",
    "class": {
      'is-invalid': _vm.$v.client.dietary_notes.$error
    },
    attrs: {
      "rows": "5"
    },
    model: {
      value: _vm.client.dietary_notes,
      callback: function callback($$v) {
        _vm.$set(_vm.client, "dietary_notes", $$v);
      },
      expression: "client.dietary_notes"
    }
  }), _vm.$v.client.dietary_notes.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.client.dietary_notes.minLength ? _c('span', [_vm._v(" Minimum 5 letters. ")]) : _vm._e(), !_vm.$v.client.dietary_notes.maxLength ? _c('span', [_vm._v(" Maximum 255 letters. ")]) : _vm._e()]) : _vm._e()], 1)], 1) : _vm._e(), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.prevStep
    }
  }, [_vm._v("Cancel")]), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.nextStep
    }
  }, [_vm._v("Submit")])], 1)])], 1), _c('b-modal', {
    attrs: {
      "id": "meal-modal",
      "content-class": "rounded-0 border-0",
      "header-bg-variant": "dark",
      "header-text-variant": "white",
      "header-class": "rounded-0 p-3"
    },
    on: {
      "hidden": function hidden($event) {
        return _vm.hideMealModal();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.selectedMeal.id ? 'Edit' : 'Add') + " Meal ")];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.hideMealModal();
            }
          }
        }, [_vm._v(" Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.saveMeal();
            }
          }
        }, [_vm.isSaving ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _vm._v(" Save ")], 1)];
      },
      proxy: true
    }])
  }, [_c('form', [_c('b-form-group', {
    attrs: {
      "label": "Meal name",
      "label-for": "name",
      "label-cols": "3"
    }
  }, [_c('b-form-input', {
    "class": {
      'is-invalid': _vm.$v.selectedMeal.name.$error
    },
    attrs: {
      "placeholder": "Enter meal name"
    },
    model: {
      value: _vm.selectedMeal.name,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMeal, "name", $$v);
      },
      expression: "selectedMeal.name"
    }
  }), _vm.$v.selectedMeal.name.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMeal.name.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Meal type",
      "label-for": "type",
      "label-cols": "3"
    }
  }, [_c('b-form-select', {
    "class": {
      'is-invalid': _vm.$v.selectedMeal.type.$error
    },
    attrs: {
      "options": _vm.mealTypeOptions()
    },
    model: {
      value: _vm.selectedMeal.type,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMeal, "type", $$v);
      },
      expression: "selectedMeal.type"
    }
  }), _vm.$v.selectedMeal.type.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMeal.type.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Taken at",
      "label-for": "taken_at",
      "label-cols": "3"
    }
  }, [_c('b-form-input', {
    "class": {
      'is-invalid': _vm.$v.selectedMeal.taken_at.$error
    },
    attrs: {
      "type": "time"
    },
    model: {
      value: _vm.selectedMeal.taken_at,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMeal, "taken_at", $$v);
      },
      expression: "selectedMeal.taken_at"
    }
  }), _vm.$v.selectedMeal.taken_at.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMeal.taken_at.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1)], 1)]), _c('b-modal', {
    attrs: {
      "id": "meal-food-modal",
      "content-class": "rounded-0 border-0",
      "header-bg-variant": "dark",
      "header-text-variant": "white",
      "header-class": "rounded-0 p-3"
    },
    on: {
      "hidden": function hidden($event) {
        return _vm.hideMealItemModal();
      }
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.selectedMealFood.id || _vm.selectedMealSupplement.id ? 'Edit' : 'Add') + " item ")];
      },
      proxy: true
    }, {
      key: "modal-footer",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": function click($event) {
              return _vm.hideMealItemModal();
            }
          }
        }, [_vm._v(" Cancel ")]), _c('b-button', {
          attrs: {
            "variant": "primary"
          },
          on: {
            "click": function click($event) {
              return _vm.saveMealFood();
            }
          }
        }, [_vm.isSaving ? _c('b-spinner', {
          attrs: {
            "small": ""
          }
        }) : _vm._e(), _vm._v(" Save ")], 1)];
      },
      proxy: true
    }])
  }, [_c('b-tabs', {
    attrs: {
      "pills": ""
    }
  }, [_c('b-tab', {
    attrs: {
      "title": "Food",
      "active": _vm.mealItemType === 'food',
      "disabled": !!_vm.selectedMealSupplement.id
    },
    on: {
      "click": function click($event) {
        _vm.mealItemType = 'food';
      }
    }
  }, [_c('form', {
    staticClass: "mt-3"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Food",
      "label-for": "food_search",
      "label-cols": "3"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Food "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [!_vm.selectedFood ? _c('vue-bootstrap-typeahead', {
    attrs: {
      "data": _vm.foods,
      "serializer": function serializer(f) {
        return "".concat(f.name, " (").concat(_vm.getServingUnit(f.serving_units), ")");
      },
      "placeholder": "Type food name...",
      "inputClass": (_vm$$v$selectedMealFo = _vm.$v.selectedMealFood.name) !== null && _vm$$v$selectedMealFo !== void 0 && _vm$$v$selectedMealFo.$error ? 'is-invalid' : ''
    },
    on: {
      "hit": function hit($event) {
        return _vm.setSelectedFood($event);
      }
    },
    model: {
      value: _vm.selectedFoodSearch,
      callback: function callback($$v) {
        _vm.selectedFoodSearch = $$v;
      },
      expression: "selectedFoodSearch"
    }
  }) : _vm._e(), _vm.selectedFood ? _c('b-input-group', {
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_c('b-input-group-text', [_c('a', {
          attrs: {
            "href": "#"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.resetFood();
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-times"
        })])])];
      },
      proxy: true
    }], null, false, 4185801667)
  }, [_c('b-form-input', {
    attrs: {
      "value": "".concat(_vm.selectedMealFood.name, " (").concat(_vm.getServingUnit(_vm.selectedMealFood.serving_units), ")"),
      "disabled": ""
    }
  })], 1) : _vm._e(), _c('b-form-input', {
    staticClass: "input-hidden",
    "class": {
      'is-invalid': (_vm$$v$selectedMealFo2 = _vm.$v.selectedMealFood.name) === null || _vm$$v$selectedMealFo2 === void 0 ? void 0 : _vm$$v$selectedMealFo2.$error
    },
    model: {
      value: _vm.selectedMealFood.name,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "name", $$v);
      },
      expression: "selectedMealFood.name"
    }
  }), (_vm$$v$selectedMealFo3 = _vm.$v.selectedMealFood.name) !== null && _vm$$v$selectedMealFo3 !== void 0 && _vm$$v$selectedMealFo3.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.name.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Calories",
      "label-for": "calories",
      "label-cols": "3"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        placeholder: 'Enter Calories',
        disabled: !!_vm.selectedFood
      },
      "unit": "ounces",
      "decimal-places": 0,
      "unit-disabled": ""
    },
    model: {
      value: _vm.selectedMealFood.calories,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "calories", $$v);
      },
      expression: "selectedMealFood.calories"
    }
  }), (_vm$$v$selectedMealFo4 = _vm.$v.selectedMealFood.calories) !== null && _vm$$v$selectedMealFo4 !== void 0 && _vm$$v$selectedMealFo4.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.calories.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealFood.calories.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Protein (gm)",
      "label-for": "protein",
      "label-cols": "3"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        placeholder: 'Enter Grams',
        disabled: !!_vm.selectedFood
      },
      "unit": "ounces",
      "decimal-places": 1,
      "unit-disabled": ""
    },
    model: {
      value: _vm.selectedMealFood.protein,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "protein", $$v);
      },
      expression: "selectedMealFood.protein"
    }
  }), (_vm$$v$selectedMealFo5 = _vm.$v.selectedMealFood.protein) !== null && _vm$$v$selectedMealFo5 !== void 0 && _vm$$v$selectedMealFo5.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.protein.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealFood.protein.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Carbs (gm)",
      "label-for": "carbs",
      "label-cols": "3"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        placeholder: 'Enter Grams',
        disabled: !!_vm.selectedFood
      },
      "unit": "ounces",
      "decimal-places": 1,
      "unit-disabled": ""
    },
    model: {
      value: _vm.selectedMealFood.carbs,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "carbs", $$v);
      },
      expression: "selectedMealFood.carbs"
    }
  }), (_vm$$v$selectedMealFo6 = _vm.$v.selectedMealFood.carbs) !== null && _vm$$v$selectedMealFo6 !== void 0 && _vm$$v$selectedMealFo6.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.carbs.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealFood.carbs.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Fat (gm)",
      "label-for": "fat",
      "label-cols": "3"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        placeholder: 'Enter Grams',
        disabled: !!_vm.selectedFood
      },
      "unit": "ounces",
      "decimal-places": 1,
      "unit-disabled": ""
    },
    model: {
      value: _vm.selectedMealFood.fat,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "fat", $$v);
      },
      expression: "selectedMealFood.fat"
    }
  }), (_vm$$v$selectedMealFo7 = _vm.$v.selectedMealFood.fat) !== null && _vm$$v$selectedMealFo7 !== void 0 && _vm$$v$selectedMealFo7.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.fat.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealFood.fat.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Sodium (mg)",
      "label-for": "sodium",
      "label-cols": "3"
    }
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        placeholder: 'Enter Milligrams',
        disabled: !!_vm.selectedFood
      },
      "unit": "ounces",
      "decimal-places": 1,
      "unit-disabled": ""
    },
    model: {
      value: _vm.selectedMealFood.sodium,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "sodium", $$v);
      },
      expression: "selectedMealFood.sodium"
    }
  }), (_vm$$v$selectedMealFo8 = _vm.$v.selectedMealFood.sodium) !== null && _vm$$v$selectedMealFo8 !== void 0 && _vm$$v$selectedMealFo8.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.sodium.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealFood.sodium.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Serving units",
      "label-for": "serving_units",
      "label-cols": "3"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Serving units "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c('b-form-select', {
    "class": {
      'is-invalid': (_vm$$v$selectedMealFo9 = _vm.$v.selectedMealFood.serving_units) === null || _vm$$v$selectedMealFo9 === void 0 ? void 0 : _vm$$v$selectedMealFo9.$error
    },
    attrs: {
      "options": _vm.servingUnitOptions,
      "disabled": !!_vm.selectedFood
    },
    on: {
      "change": function change($event) {
        return _vm.setServingSize(_vm.selectedMealFood);
      }
    },
    model: {
      value: _vm.selectedMealFood.serving_units,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "serving_units", $$v);
      },
      expression: "selectedMealFood.serving_units"
    }
  }), (_vm$$v$selectedMealFo10 = _vm.$v.selectedMealFood.serving_units) !== null && _vm$$v$selectedMealFo10 !== void 0 && _vm$$v$selectedMealFo10.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.serving_units.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _vm.servingUnitsRequiresSize(_vm.selectedMealFood.serving_units) ? _c('b-form-group', {
    attrs: {
      "label": _vm.selectedMealFood.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit',
      "label-for": "serving_size",
      "label-cols": "3"
    },
    scopedSlots: _vm._u([(_vm$$v$selectedMealFo11 = _vm.$v.selectedMealFood.serving_size) !== null && _vm$$v$selectedMealFo11 !== void 0 && (_vm$$v$selectedMealFo12 = _vm$$v$selectedMealFo11.$params) !== null && _vm$$v$selectedMealFo12 !== void 0 && _vm$$v$selectedMealFo12.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.selectedMealFood.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit') + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('GramsInput', {
    attrs: {
      "input-props": {
        disabled: !!_vm.selectedFood,
        placeholder: _vm.selectedMealFood.serving_units === 'cups' ? 'Enter ounces per 1 Cup' : 'Enter ounces per 1 Unit',
        "class": {
          'is-invalid': (_vm$$v$selectedMealFo13 = _vm.$v.selectedMealFood.serving_size) === null || _vm$$v$selectedMealFo13 === void 0 ? void 0 : _vm$$v$selectedMealFo13.$error
        }
      },
      "decimal-places": 1,
      "unit": "ounces"
    },
    model: {
      value: _vm.selectedMealFood.serving_size,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "serving_size", $$v);
      },
      expression: "selectedMealFood.serving_size"
    }
  }), (_vm$$v$selectedMealFo14 = _vm.$v.selectedMealFood.serving_size) !== null && _vm$$v$selectedMealFo14 !== void 0 && _vm$$v$selectedMealFo14.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.serving_size.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealFood.serving_size.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1) : _vm._e(), _c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Taken amount",
      "label-for": "taken_amount",
      "label-cols": "3"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Taken amount "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [['grams', 'ounces', undefined, null].includes((_vm$selectedMealFood = _vm.selectedMealFood) === null || _vm$selectedMealFood === void 0 ? void 0 : _vm$selectedMealFood.serving_units) ? _c('b-form-input', {
    "class": {
      'is-invalid': _vm.$v.selectedMealFood.taken_amount.$error
    },
    attrs: {
      "type": "number",
      "number": "",
      "placeholder": "Enter taken amount in ".concat(((_vm$selectedMealFood2 = _vm.selectedMealFood) === null || _vm$selectedMealFood2 === void 0 ? void 0 : _vm$selectedMealFood2.serving_units) || 'serving units')
    },
    model: {
      value: _vm.selectedMealFood.taken_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "taken_amount", $$v);
      },
      expression: "selectedMealFood.taken_amount"
    }
  }) : _c('ServingUnitInput', {
    attrs: {
      "unit-label": (_vm$selectedMealFood3 = _vm.selectedMealFood) === null || _vm$selectedMealFood3 === void 0 ? void 0 : _vm$selectedMealFood3.serving_units
    },
    model: {
      value: _vm.selectedMealFood.taken_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealFood, "taken_amount", $$v);
      },
      expression: "selectedMealFood.taken_amount"
    }
  }), _vm.$v.selectedMealFood.taken_amount.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealFood.taken_amount.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealFood.taken_amount.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1)], 1)]), _c('b-tab', {
    attrs: {
      "title": "Supplement",
      "active": _vm.mealItemType === 'supplement',
      "disabled": !!_vm.selectedMealFood.id
    },
    on: {
      "click": function click($event) {
        _vm.mealItemType = 'supplement';
      }
    }
  }, [_c('form', {
    staticClass: "mt-3"
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-for": "name",
      "label-cols": "3"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Name "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c('b-form-input', {
    "class": {
      'is-invalid': _vm.$v.selectedMealSupplement.name.$error
    },
    attrs: {
      "placeholder": "Enter supplement name"
    },
    model: {
      value: _vm.selectedMealSupplement.name,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealSupplement, "name", $$v);
      },
      expression: "selectedMealSupplement.name"
    }
  }), _vm.$v.selectedMealSupplement.name.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealSupplement.name.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
    attrs: {
      "label": "Serving units",
      "label-for": "serving_units",
      "label-cols": "3"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Serving units "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [_c('b-form-select', {
    "class": {
      'is-invalid': _vm.$v.selectedMealSupplement.serving_units.$error
    },
    attrs: {
      "options": _vm.servingUnitOptions
    },
    on: {
      "change": function change($event) {
        return _vm.setServingSize(_vm.selectedMealSupplement);
      }
    },
    model: {
      value: _vm.selectedMealSupplement.serving_units,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealSupplement, "serving_units", $$v);
      },
      expression: "selectedMealSupplement.serving_units"
    }
  }), _vm.$v.selectedMealSupplement.serving_units.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealSupplement.serving_units.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e()]) : _vm._e()], 1), _vm.servingUnitsRequiresSize(_vm.selectedMealSupplement.serving_units) ? _c('b-form-group', {
    attrs: {
      "label": _vm.selectedMealSupplement.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit',
      "label-for": "serving_size",
      "label-cols": "3"
    },
    scopedSlots: _vm._u([(_vm$$v$selectedMealSu = _vm.$v.selectedMealSupplement.serving_size) !== null && _vm$$v$selectedMealSu !== void 0 && (_vm$$v$selectedMealSu2 = _vm$$v$selectedMealSu.$params) !== null && _vm$$v$selectedMealSu2 !== void 0 && _vm$$v$selectedMealSu2.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.selectedMealSupplement.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit') + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('GramsInput', {
    attrs: {
      "input-props": {
        placeholder: _vm.selectedMealSupplement.serving_units === 'cups' ? 'Enter ounces per 1 Cup' : 'Enter ounces per 1 Unit',
        "class": {
          'is-invalid': (_vm$$v$selectedMealSu3 = _vm.$v.selectedMealSupplement.serving_size) === null || _vm$$v$selectedMealSu3 === void 0 ? void 0 : _vm$$v$selectedMealSu3.$error
        }
      },
      "decimal-places": 1,
      "unit": "ounces"
    },
    model: {
      value: _vm.selectedMealSupplement.serving_size,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealSupplement, "serving_size", $$v);
      },
      expression: "selectedMealSupplement.serving_size"
    }
  }), (_vm$$v$selectedMealSu4 = _vm.$v.selectedMealSupplement.serving_size) !== null && _vm$$v$selectedMealSu4 !== void 0 && _vm$$v$selectedMealSu4.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealSupplement.serving_size.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealSupplement.serving_size.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1) : _vm._e(), _c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label": "Taken amount",
      "label-for": "taken_amount",
      "label-cols": "3"
    },
    scopedSlots: _vm._u([{
      key: "label",
      fn: function fn() {
        return [_vm._v(" Taken amount "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    }])
  }, [['grams', 'ounces', undefined, null].includes((_vm$selectedMealSuppl = _vm.selectedMealSupplement) === null || _vm$selectedMealSuppl === void 0 ? void 0 : _vm$selectedMealSuppl.serving_units) ? _c('b-form-input', {
    "class": {
      'is-invalid': _vm.$v.selectedMealSupplement.taken_amount.$error
    },
    attrs: {
      "type": "number",
      "number": "",
      "placeholder": "Enter taken amount in ".concat(((_vm$selectedMealSuppl2 = _vm.selectedMealSupplement) === null || _vm$selectedMealSuppl2 === void 0 ? void 0 : _vm$selectedMealSuppl2.serving_units) || 'serving units')
    },
    model: {
      value: _vm.selectedMealSupplement.taken_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealSupplement, "taken_amount", $$v);
      },
      expression: "selectedMealSupplement.taken_amount"
    }
  }) : _c('ServingUnitInput', {
    attrs: {
      "unit-label": (_vm$selectedMealSuppl3 = _vm.selectedMealSupplement) === null || _vm$selectedMealSuppl3 === void 0 ? void 0 : _vm$selectedMealSuppl3.serving_units
    },
    model: {
      value: _vm.selectedMealSupplement.taken_amount,
      callback: function callback($$v) {
        _vm.$set(_vm.selectedMealSupplement, "taken_amount", $$v);
      },
      expression: "selectedMealSupplement.taken_amount"
    }
  }), _vm.$v.selectedMealSupplement.taken_amount.$error ? _c('div', {
    staticClass: "invalid-feedback"
  }, [!_vm.$v.selectedMealSupplement.taken_amount.required ? _c('span', [_vm._v(" This value is required. ")]) : _vm._e(), !_vm.$v.selectedMealSupplement.taken_amount.decimal ? _c('span', [_vm._v(" This value should be numeric. ")]) : _vm._e()]) : _vm._e()], 1)], 1)])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }