var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0",
      "justified": "",
      "no-fade": ""
    }
  }, _vm._l(_vm.value, function (phase, phaseIndex) {
    return _c('b-tab', {
      key: phase.id,
      attrs: {
        "title-link-class": "rounded-0 border-0",
        "title": "Phase ".concat(phaseIndex + 1)
      }
    }, [_c('b-card', {
      staticClass: "my-0",
      attrs: {
        "no-body": ""
      }
    }, [_c('b-table-simple', {
      staticClass: "m-0 input-table",
      attrs: {
        "fixed": ""
      }
    }, [_c('b-thead', [_c('b-tr', [_c('b-th', [_vm._v("Name")]), _c('b-th', [_vm._v("Type")]), _c('b-th', [_vm._v("Recommended Dosage")])], 1)], 1), _c('b-tbody', _vm._l(phase.supplements, function (supplement, supplementIndex) {
      return _c('b-tr', {
        key: supplementIndex,
        on: {
          "click": function click($event) {
            return _vm.$emit('select-supplement', supplement);
          }
        }
      }, [_c('b-td', {
        staticClass: "w-20"
      }, [_vm._v(" " + _vm._s(supplement.name) + " ")]), _c('b-td', {
        staticClass: "w-20"
      }, [_c('SupplementTypes', {
        attrs: {
          "ids": supplement.type ? [supplement.type] : []
        }
      })], 1), _c('b-td', {
        staticClass: "w-33-33"
      }, [_vm._v(" " + _vm._s(supplement.dose) + " "), _c('SupplementDoseTypes', {
        attrs: {
          "ids": supplement.dose_type ? [supplement.dose_type] : []
        }
      })], 1)], 1);
    }), 1)], 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }