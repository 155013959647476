<template>
  <b-overlay :show="isFetching">
    <b-form
      v-if="isFetched"
      @submit.prevent="submit('submitted')"
    >
      <div class="mb-3">
        <span class="font-weight-bold font-size-20 align-middle">Billing</span>
      </div>

      <b-form-group
        label="Status"
        label-cols-sm="3"
        class="align-middle"
      >
        <div class="py-1">
          <BillingSubscriptionStatus
            :item="subscription || { status: 'unpaid' }"
            tag="b-badge"
            class="align-middle text-uppercase px-2 py-2 mr-2"
          />

          <BillingSubscriptionCancel
            v-if="subscription && subscription.cancel_date"
            :item="subscription"
            tag="b-badge"
            class="align-middle text-uppercase px-2 py-2"
          />
        </div>
      </b-form-group>

      <template v-if="subscription">
        <b-form-group
          label="Your Plan"
          label-cols-sm="3"
        >
          <b-input-group>
            <b-form-input
              :value="`${subscriptionPlan.name} - $${pricePerInterval * subscription.interval_count} per ${subscription.interval_count} ${subscription.interval}`"
              disabled
            />

            <b-input-group-append>
              <CompanyAppPlanItemCheckoutButton
                :item="subscriptionPlan"
                :params="{ billing_type: 'mothly' }"
                tag="b-button"
                :tag-props="{
                  variant: 'primary'
                }"
              >
                Update
              </CompanyAppPlanItemCheckoutButton>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>

        <b-form-group
          v-if="subscription.cancel_date"
          label="Cancel date"
          label-cols-sm="3"
        >
          <b-form-datepicker
            :value="subscription.cancel_date"
            disabled
          />
        </b-form-group>

        <b-form-group
          v-else-if="subscription.end_date"
          label="Next bill date"
          label-cols-sm="3"
        >
          <b-form-datepicker
            :value="subscription.end_date"
            disabled
          />
        </b-form-group>
      </template>

      <template v-else-if="companyAppPlan">
        <b-form-group
          label="Agreement"
          label-cols="3"
          clas=""
        >
          <div class="d-flex h-100 align-items-center">
            <b-form-checkbox
              v-model="agreementsCheck"
            >
              Check to Accept <a
                :href="companyAppPlan.agreement_url"
                target="_blank"
              >Agreement</a>
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group
          label="Choose a plan"
          label-cols="3"
        >
          <CompanyAppPlanItemCheckoutButton
            class="mr-3"
            :item="companyAppPlan"
            :params="{ billing_type: 'monthly' }"
            tag="b-button"
            :tag-props="{
              variant: 'primary',
              disabled: !agreementsCheck
            }"
          >
            ${{ companyAppPlan.price_per_month }} per month
          </CompanyAppPlanItemCheckoutButton>

          <CompanyAppPlanItemCheckoutButton
            variant="primary"
            :item="companyAppPlan"
            :params="{ billing_type: 'annual' }"
            tag="b-button"
            :tag-props="{
              variant: 'primary',
              disabled: !agreementsCheck
            }"
          >
            ${{ companyAppPlan.price_per_month * 12 }} per year
          </CompanyAppPlanItemCheckoutButton>
        </b-form-group>
      </template>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import BillingSubscriptionStatus from './BillingSubscriptionStatus'
import BillingSubscriptionCancel from './BillingSubscriptionCancel'
import CompanyAppPlanItemCheckoutButton from '@/components/companies/store/buttons/CompanyAppPlanItemCheckoutButton'

export default {
  name: 'BillingDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    CompanyAppPlanItemCheckoutButton,
    BillingSubscriptionStatus,
    BillingSubscriptionCancel
  },

  data() {
    return {
      fetchEndpoint: 'users/me',
      companyAppPlanResult: {
        data: [],
        total: 0
      },
      agreementsCheck: false
    }
  },

  computed: {
    companyAppPlan() {
      return _.get(this.companyAppPlanResult, 'data.0')
    },

    subscription() {
      return _.get(this.newItem, 'company_app_subscription')
    },

    subscriptionPlan() {
      return _.get(this.subscription, 'company_app_plan')
    },

    pricePerInterval() {
      let pricePerInterval = this.subscriptionPlan.price_per_month

      if (this.subscription.interval === 'year') {
        pricePerInterval = this.subscriptionPlan.price_per_month * 12
      }

      return pricePerInterval
    }
  },

  created() {
    this.fetchCompanyAppPlans()
  },

  methods: {
    fetchCompanyAppPlans() {
      const limit = 10
      const page = 1

      return this.$axios
        .get(`/company-app-plans`, {
          parent: this,
          params: { limit, page }
        })
        .then(({ data }) => {
          this.companyAppPlanResult.data = data.data
          this.companyAppPlanResult.total = data.total
        })
    }
  }
}
</script>
