var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.formSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Weight From",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.weight_from)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.weight_from)
    },
    model: {
      value: _vm.input.weight_from,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "weight_from", $$v);
      },
      expression: "input.weight_from"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Weight To",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.weight_to)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.weight_to)
    },
    model: {
      value: _vm.input.weight_to,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "weight_to", $$v);
      },
      expression: "input.weight_to"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Weeks Count",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.weeks_count)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [16, 32],
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.weeks_count)
    },
    model: {
      value: _vm.input.weeks_count,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "weeks_count", $$v);
      },
      expression: "input.weeks_count"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "NB Caliper",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.nb_caliper)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.nb_caliper)
    },
    model: {
      value: _vm.input.nb_caliper,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "nb_caliper", $$v);
      },
      expression: "input.nb_caliper"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }