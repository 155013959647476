<template>
  <Layout>
    <div class="row">
      <div class="col-lg-5">
        <PageHeader :title="title" />
      </div>
      <div class="col-lg-7 d-flex align-items-center justify-content-end">
        <b-button-group>
          <b-button
            :class="getNavigationClass(1)"
            :disabled="getNavigationDisabled(1)"
            to="#step1"
            @click="onStep(1)"
          >
            General Info
          </b-button>
          <b-button
            :class="getNavigationClass(2)"
            :disabled="getNavigationDisabled(2)"
            to="#step2"
          >
            History
          </b-button>
          <b-button
            :class="getNavigationClass(3)"
            :disabled="getNavigationDisabled(3)"
            to="#step3"
          >
            Nutrition
          </b-button>
          <b-button
            :class="getNavigationClass(4)"
            :disabled="getNavigationDisabled(4)"
            to="#step4"
          >
            Schedule
          </b-button>
          <b-button
            :class="getNavigationClass(5)"
            :disabled="getNavigationDisabled(5)"
            to="#step5"
          >
            Measurements
          </b-button>
          <b-button
            :class="getNavigationClass(6)"
            :disabled="getNavigationDisabled(6)"
            to="#step6"
          >
            Finish
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <UserProfileUpsert
              v-if="activeStep === 1"
              :item.sync="item"
              :show-roles="false"
              prefetch
              @submitted="onSubmitted"
            />

            <ClientHistoryUpsert
              v-if="item.id && activeStep === 2"
              :item.sync="item"
              prefetch
              @submitted="onSubmitted"
            />
  
            <Nutrition
              v-if="item.id && activeStep === 3"
              :client="item"
              :step="activeStep"
            />

            <ClientScheduleUpdate
              v-if="item.id && activeStep === 4"
              :item.sync="item"
              prefetch
              @submitted="onSubmitted"
            />

            <ClientItemUpsertPhysicalCondition
              v-if="item.id && activeStep === 5"
              :item.sync="item"
              @submitted="onSubmitted"
            />

            <ClientProfileUptodateUpsert
              v-if="item.id && activeStep === 6"
              :item.sync="item"
              @submitted="$router.push('/client/list')"
            />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main';
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import { mapGetters } from 'vuex'

import UserProfileUpsert from '@/components/users/UserProfileUpsert';
import ClientHistoryUpsert from '@/components/clients/ClientHistoryUpsert';
import ClientScheduleUpdate from '@/components/clients/ClientScheduleUpdate';
import Nutrition from './step/nutrition'
import ClientProfileUptodateUpsert from '@/components/clients/ClientProfileUptodateUpsert'
import ClientItemUpsertPhysicalCondition from '@/components/clients/ClientItemUpsertPhysicalCondition'

/**
 * Form Advanced component
 */
export default {
  name: 'OnboardingPage',

  components: {
    Layout,
    PageHeader,
    UserProfileUpsert,
    ClientHistoryUpsert,
    Nutrition,
    ClientItemUpsertPhysicalCondition,
    ClientScheduleUpdate,
    ClientProfileUptodateUpsert
  },

  page: {
    title: 'Onboarding',
    meta: [{ name: 'description', content: appConfig.description }],
  },

  data() {
    return {
      isBusy: false,
      config: appConfig,
      activeStep: null,
      item: {}
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      currentRole: 'auth/currentRole'
    }),

    title() {
      switch (this.activeStep) {
        default:
        case 1:
          return 'Onboarding - General Info';
        case 2:
          return 'Onboarding - History';
        case 3:
          return 'Onboarding - Nutrition';
        case 4:
          return 'Onboarding - Schedule';
        case 5:
          return 'Onboarding - Measurements';
        case 6:
          return 'Onboarding - Finish';
      }
    },

    allowedStep() {
      return this.$route.params.id ? 7 : 1
    }
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.setStep()
      }
    }
  },

  methods: {
    onStep(step) {
      let path = '/onboarding'

      if (this.item.id) {
        path += `/${this.item.id}`
      }

      this.$router.push({ path: path, hash: `#step${step}` });
    },

    onPrevStep() {
      this.onStep(this.activeStep - 1)
    },

    onNextStep() {
      this.onStep(this.activeStep + 1)
    },

    onSubmitted() {
      this.$nextTick(() => this.onNextStep())
    },

    getStepFromRoute() {
      let step;
      switch (this.$route.hash) {
        default:
        case '#step1':
          step = 1;
          break;
        case '#step2':
          step = 2;
          break;
        case '#step3':
          step = 3;
          break;
        case '#step4':
          step = 4;
          break;
        case '#step5':
          step = 5;
          break;
        case '#step6':
          step = 6;
          break;
      }
      return step;
    },

    getNavigationClass(step) {
      let classes = [];

      if (step === this.activeStep) {
        classes.push('active');
      }

      if (step < this.activeStep) {
        classes.push('completed');
      }

      return classes.join(' ');
    },

    getNavigationDisabled(step) {
      if (step > this.allowedStep) {
        return true;
      }
    },

    setStep() {
      this.activeStep = this.getStepFromRoute()

      const id = this.$route.params.id ? parseInt(this.$route.params.id) : null

      if (this.activeStep > this.allowedStep) {
        this.$router.push({ path: '/onboarding' })

      } else if (id && id !== this.item.id) {
        this.item = { id }
  
      } else if (!id) {
        this.item = {
          role_ids: [1],
          primary_trainer: this.currentRole.role_id === 2 ? this.currentUser : null 
        }
      }
    }
  }
}
</script>

<style scoped>
  .btn-group .btn.active {
    background: #626ed4 !important;
    /*border-color: #626ed4 !important;*/
  }
  .btn-group .btn.completed {
    background: #02a499 !important;
    /*border-color: #02a499 !important;*/
  }
  .card {
    background: #ececf1;
  }
</style>
