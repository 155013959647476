<template>
  <Layout>
    <b-overlay :show="isFetching">
      <b-row>
        <b-col cols="8">
          <PageHeader :title="title" />
        </b-col>
      </b-row>

      <b-card v-if="isFetched">
        <ClientStrengthGains
          :params="{
            filters: {
              user_id: item.id,
              exercise_id: null,
              date_from: $moment().subtract(90, 'd').format('YYYY-MM-DD'),
              date_to: $moment().format('YYYY-MM-DD')
            }
          }"
          :prefetch="false"
        />
      </b-card>
    </b-overlay>
  </Layout>
</template>

<script>
import crudItem from '@/mixins/crud-item'
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import { mapGetters } from 'vuex'
import ClientStrengthGains from '@/components/clients/ClientStrengthGains'

export default {
  name: 'ClientStrengthGainsPage',

  mixins: [
    crudItem
  ],

  components: {
    Layout,
    PageHeader,
    ClientStrengthGains
  },

  props: {
    item: {
      type: Object,

      default: function() {
        const currentUser = this.$store.getters['auth/currentUser']
        const id = this.$route.params.id
        return currentUser.id === id || !id ? { id } : currentUser
      }
    }
  },

  page() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    title() {
      return `${this.item.first_name || 'Client'}'s Strength Gains`
    }
  }
}
</script>