<template>
  <b-row class="schedule-bg position-absolute w-100">
    <b-col
      v-for="(day, dayIndex) in days"
      :key="day"
      :class="dayStatuses[dayIndex]"
    >
      <div
        v-if="showDays"
        class="schedule-bg-day text-center font-size-16"
      >
        <template>
          <div class="label-day">
            {{ dates[dayIndex] ? $moment(dates[dayIndex]).format('ddd Do MMM') : day }}
          </div>

          <div
            v-if="datesWeekNumbers[dayIndex]"
            class="label-week"
          >
            <div v-if="phaseWeeks[datesWeekNumbers[dayIndex] - 1][0] === dates[dayIndex]">
              Week {{ datesWeekNumbers[dayIndex] }}
            </div>
          </div>
        </template>
      </div>

      <div class="position-relative">
        <div class="day-background"/>

        <div
          v-for="(hour, hourIndex) in hours"
          :key="hourIndex"
          class="schedule-bg-hour"
          :style="{
            height: hourIndex < hours.length - 1 ? '40px' : '0'
          }"
          @click="$emit('hour-click', { start: hourIndex + dayIndex * 24 })"
        >
          <div class="schedule-bg-hour-title text-right">
            {{ hour }}
          </div>

          <template v-if="hourIndex < hours.length - 1">
            <div class="tick-15" />
            <div class="tick-30" />
            <div class="tick-45" />
          </template>
        </div>

        <template>
          <div
            v-for="(event, eventIndex) in eventsByDay[dayIndex]"
            :key="`${dayIndex}-${eventIndex}`"
            class="schedule-bg-event"
            :style="{
              top: `${(event.start - (24 * dayIndex)) * 40}px`,
              height: `${event.duration * 40}px`,
              cursor: 'default'
            }"
            @click="$emit('bg-event-click', event)"
          >
            <ScheduleEvent
              :event="event"
              :removable="false"
              :selectable="false"
              :style="{
                padding: 0
              }"
            />
          </div>
        </template>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import _ from 'lodash'
import ScheduleEvent from './ScheduleEvent'

export default {
  name: 'ScheduleBackground',

  components: {
    ScheduleEvent
  },

  props: {
    events: {
      type: Array,
      default: null
    },

    startDate: {
      type: Date,
      default: null
    },

    pausedAt: {
      type: Date,
      default: null
    },

    phase: {
      type: Object,
      default: null
    },

    daysCount: {
      type: Number,
      default: 7
    },

    showDays: {
      type: Boolean,
      defualt: true
    }
  },

  computed: {
    days() {
      const days = [
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday'
      ]

      return days.slice(0, this.daysCount)
    },

    hours() {
      return [
        '12 am',
        '1 am',
        '2 am',
        '3 am',
        '4 am',
        '5 am',
        '6 am',
        '7 am',
        '8 am',
        '9 am',
        '10 am',
        '11 am',
        '12 pm',
        '1 pm',
        '2 pm',
        '3 pm',
        '4 pm',
        '5 pm',
        '6 pm',
        '7 pm',
        '8 pm',
        '9 pm',
        '10 pm',
        '11 pm',
        '12 am'
      ]
    },

    dates() {
      return _.map(this.days, (day, i)  => (
        this.startDate ? this.$moment(this.startDate).add(i, 'day').format('YYYY-MM-DD') : null
      ))
    },

    todayDate() {
      return this.$moment().format('YYYY-MM-DD')
    },

    phaseWeeks() {
      return _.get(this.phase, 'weeks.items')
    },

    datesWeekNumbers() {
      return _.map(this.dates, i => (_.findIndex(this.phaseWeeks, w => w.includes(i)) + 1) || null)
    },

    dayStatuses() {
      return _.map(this.days, (day, i) => {
        const date = this.dates[i]
        const weekNumber = this.datesWeekNumbers[i]
        let statuses = []

        if (date === this.todayDate) {
          statuses.push('is-today')
        }

        if (this.pausedAt && this.$moment(this.pausedAt) < this.$moment(date)) {
          statuses.push(`phase-paused-day`)

        } else if (weekNumber) {
          statuses.push(`phase-week`)
          statuses.push(`phase-week-${this.datesWeekNumbers[i]}`)

        } else if (
          this.phase &&
          this.$moment(date) >= this.$moment(this.phase.start_date) &&
          this.$moment(date) < this.$moment(this.phase.end_date).add(1, 'day')
        ) {
          statuses.push(`phase-paused-day`)

        } else {
          statuses.push(`phase-inactive-day`)
        }

        return statuses
      })
    },

    eventsByDay() {
      return [
        _.filter(this.events, (i) => i.start >= 0 && i.start < 24),
        _.filter(this.events, (i) => i.start >= 24 && i.start < 48),
        _.filter(this.events, (i) => i.start >= 48 && i.start < 72),
        _.filter(this.events, (i) => i.start >= 72 && i.start < 96),
        _.filter(this.events, (i) => i.start >= 96 && i.start < 120),
        _.filter(this.events, (i) => i.start >= 120 && i.start < 144),
        _.filter(this.events, (i) => i.start >= 144 && i.start < 168)
      ]
    }
  }
}
</script>

<style lang="scss">
.schedule-bg {
  height: 950px;
  font-size: 12px;
  margin-top: -1px;
  top: 1px;

  .schedule-bg-day {
    height: 59px;
    overflow: hidden;
  }

  .schedule-bg-hour {
    border-top: 1px solid #CCCCCC;
    margin: 0 0 0 50px;
    z-index: 8;
    position: relative;

    .schedule-bg-hour-title {
      transform: translate(-100%, -50%);
      width: 45px;
      padding-right: 5px;
    }

    .tick-15 {
      position: absolute;
      border-top: 1px solid #CCCCCC;
      width: 10px;
      top: calc(40px / 4 * 1 - 2px);
      left: 0;
    }

    .tick-30 {
      position: absolute;
      border-top: 1px solid #CCCCCC;
      width: 10px;
      top: calc(40px / 4 * 2 - 2px);
      left: 0;
    }

    .tick-45 {
      position: absolute;
      border-top: 1px solid #CCCCCC;
      width: 10px;
      top: calc(40px / 4 * 3 - 2px);
      left: 0;
    }
  }

  .schedule-bg-event {
    opacity: 0.2;
    position: absolute;
    margin: 0 0 0 50px;
    width: calc(100% - 50px);
    z-index: 9;
  }

  .day-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }

  .label-day {
    color: #626ed4;
  }

  .label-week {
    color: #FFFFFF;
    height: 18px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 4px 0;
  }

  .is-today {
    .label-day {
      color: #000;
      font-weight: bold;
    }
  }

  .phase-paused-day {
    .label-day {
      color: #9E3333;
    }

    .label-week {
      display: none;
    }
  }

  .phase-inactive-day {
    .label-day {
      color: #999999;
    }

    .schedule-bg-event {
      display: none;
    }

    .label-week {
      display: none;
    }
  }

  .phase-week-1 {
    .label-week {
      background: #a3aae9
    }
  }

  .phase-week-2 {
    .label-week {
      background: #626ed4
    }
  }

  .phase-week-3 {
    .label-week {
      background: #a3aae9
    }
  }

  .phase-week-4 {
    .label-week {
      background: #626ed4
    }
  }

  .phase-week-5 {
    .label-week {
      background: #a3aae9
    }
  }

  .phase-week-6 {
    .label-week {
      background: #626ed4
    }
  }
}
</style>