<script>
import Layout from "../../layouts/main";
import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import { assetComputed, companyComputed } from '@/state/helpers';
import {
  required,
  minLength,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators';
import FaIcon from '@/components/widgets/fa-icon';
import Swal from 'sweetalert2';
import _ from 'lodash';
import s from 'underscore.string';

let counter = 1;

/**
 * Form Advanced component
 */
export default {
  name: 'ForecastAddPage',
  components: { Layout, PageHeader, FaIcon },
  page: {
    title: 'Add Exercise',
    meta: [{ name: 'description', content: appConfig.description }],
  },
  computed: {
    ...assetComputed,
    ...companyComputed,
    repOptions() {
      return Array(100).fill().map((_, i) => {
        return { value: i + 1, text: i + 1 };
      });
    },
    restOptions() {
      return Array(301).fill().map((_, i) => {
        return { value: i, text: i };
      });
    },
    title() {
      return this.isEdit ? 'Edit Forecast' : 'Forecast Creator';
    },
    isEdit() {
      return this.$route.name === 'forecast-edit';
    },
    isCopy() {
      return this.$route.name === 'forecast-copy';
    },
  },
  data() {
    return this.initialData();
  },
  validations: {
    form: {
      name: { required, minLength: minLength(5), maxLength: maxLength(255) },
      gender: { required },
      goal_direction: { required },
      fitness_level: { required },
      term: { required, numeric },
      description: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(1000),
      },
    },
  },
  methods: {
    s: s,
    initialData() {
      return {
        config: appConfig,
        // execrise id in edit
        id: null,
        formSubmitted: false,
        phases: [],
        form: {
          name: '',
          gender: '',
          goal_direction: null,
          fitness_level: [],
          term: null,
          description: '',
          style: 'style',
          rest: 'rest',
          sequence: 'sequence',
          company_id: null,
          fitness_level_name: [],
          goal_direction_name: '',
          phases: [],
        },
        editEntityMode: true,
        // when go to the next step on Forecast save - use this flag in order not to reload form data
        keepData: false,
        // index of week currently edited
        edit_index: null,
        edit_week: null,
        // allowed phases amount
        max_phases: 4,
        // busy flags
        phases_busy: false,
        forecast_busy: false,
        // edit mode restore data
        formClone: [],
        remember: {
          rest: 0,
          reps: 4,
          rep_1: 0,
          rep_2: 0,
          rep_3: 0,
          rep_4: 0,
          rep_5: 0,
          rep_6: 0,
          rep_7: 0,
          rep_8: 0,
        },
        defaultWeek: {
          rest: 30,
          reps: 4,
          rep_1: 10,
          rep_2: 8,
          rep_3: 8,
          rep_4: 8,
          rep_5: 8,
          rep_6: 8,
          rep_7: 8,
          rep_8: 8,
        },
        phasesWeeksTemplates: {
          16: [3, 4, 5, 4],
          32: [6, 8, 10, 8],
        }
      };
    },
    onFormSubmit() {
      this.formSubmitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$error) {
        return;
      }

      this.form.company_id = this.currentCompany ?
                             this.currentCompany.id :
                             null;

      this.form.phases = this.generatePhasesAndWeeksAccordingToTerm();

      this.forecast_busy = true;
      this.phases_busy = true;
      if (this.isEdit) {
        this.$axios
          .put(`/forecast/${this.id}`, this.form)
          .then(async ({ data }) => {
            this.editEntityMode = false;
            this.formClone = data.data;
            await this.loadForecast();
            this.phases_busy = false;
          })
          .catch(() => {
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Could not save forecast',
            });
          })
          .finally(() => {
            this.forecast_busy = false;
          });
      } else {
        this.$axios
          .post('/forecast', this.form)
          .then(async ({ data }) => {
            this.editEntityMode = false;
            this.formClone = data.data;
            this.keepData = true;
            await this.$router.push(`/forecast/${data.data.id}`);
            await this.loadForecast();
            this.phases_busy = false;
          })
          .catch(() => {
            this.$store.commit('layout/addAlerts', {
              type: 'warning',
              text: 'Could not save forecast',
            });
          })
          .finally(() => {
            this.forecast_busy = false;
          });
      }
    },
    resetCounter() {
      counter = 1;
    },
    incCounter() {
      counter++;
    },
    getCounter() {
      return counter;
    },
    async onStartEdit(index, week) {
      await this.applyEdit();
      this.edit_index = index;
      this.edit_week = week;
      this.remember.rest = week.rest;
      this.remember.reps = week.reps;
      this.remember.rep_1 = week.rep_1;
      this.remember.rep_2 = week.rep_2;
      this.remember.rep_3 = week.rep_3;
      this.remember.rep_4 = week.rep_4;
      this.remember.rep_5 = week.rep_5;
      this.remember.rep_6 = week.rep_6;
      this.remember.rep_7 = week.rep_7;
      this.remember.rep_8 = week.rep_8;
    },
    needSave() {
      return (
        this.edit_week.rest !== this.remember.rest ||
        this.edit_week.reps !== this.remember.reps ||
        this.edit_week.rep_1 !== this.remember.rep_1 ||
        this.edit_week.rep_2 !== this.remember.rep_2 ||
        this.edit_week.rep_3 !== this.remember.rep_3 ||
        this.edit_week.rep_4 !== this.remember.rep_4 ||
        this.edit_week.rep_5 !== this.remember.rep_5 ||
        this.edit_week.rep_6 !== this.remember.rep_6 ||
        this.edit_week.rep_7 !== this.remember.rep_7 ||
        this.edit_week.rep_8 !== this.remember.rep_8
      );
    },
    async applyEdit() {
      if (!this.edit_week) {
        return;
      }
      if (this.needSave()) {
        this.phases_busy = true;
        await this.updateWeek(this.edit_week);
        this.phases_busy = false;
      }
      this.edit_index = null;
      this.edit_week = null;
    },
    async onApplyEdit() {
      return this.applyEdit();
    },
    onAddSet(week) {
      if (week.reps < 8) {
        week.reps++;
      }
      week[`rep_${week.reps}`] = week[`rep_${week.reps - 1}`];
      // do this to trigger selects loop rendering
      week.rest--;
      week.rest++;
    },
    onRemoveSet(week) {
      if (week.reps > 1) {
        week.reps--;
      }
      week[`rep_${week.reps + 1}`] = 0;
      // do this to trigger selects loop rendering
      week.rest--;
      week.rest++;
    },
    onCancelEdit(week) {
      week.rest = this.remember.rest;
      week.reps = this.remember.reps;
      week.rep_1 = this.remember.rep_1;
      week.rep_2 = this.remember.rep_2;
      week.rep_3 = this.remember.rep_3;
      week.rep_4 = this.remember.rep_4;
      week.rep_4 = this.remember.rep_4;
      week.rep_4 = this.remember.rep_4;
      week.rep_4 = this.remember.rep_4;
      week.rep_4 = this.remember.rep_4;
      this.edit_index = null;
      this.edit_week = null;
    },
    async onAddWeek(weeks, phase_id) {
      if (weeks.length > 0) {
        const last_week = _.cloneDeep(weeks[weeks.length - 1]);

        this.phases_busy = true;

        await this.addWeek(phase_id, last_week);
        await this.loadForecast();

        this.phases_busy = false;
      } else {
        this.phases_busy = true;

        await this.addWeek(phase_id, this.defaultWeek);
        await this.loadForecast();

        this.phases_busy = false;
      }
    },
    async addWeek(phase_id, week) {
      return this.$axios
        .post('forecast/phase/week', {
          forecast_phase_id: phase_id,
          rest: week.rest,
          reps: week.reps,
          rep_1: week.rep_1,
          rep_2: week.rep_2,
          rep_3: week.rep_3,
          rep_4: week.rep_4,
          rep_5: week.rep_5,
          rep_6: week.rep_6,
          rep_7: week.rep_7,
          rep_8: week.rep_8,
        });
    },
    async updateWeek(week) {
      return this.$axios
        .put(`forecast/phase/week/${week.id}`, {
          id: week.id,
          rest: week.rest,
          reps: week.reps,
          rep_1: week.rep_1,
          rep_2: week.rep_2,
          rep_3: week.rep_3,
          rep_4: week.rep_4,
          rep_5: week.rep_5,
          rep_6: week.rep_6,
          rep_7: week.rep_7,
          rep_8: week.rep_8,
        });
    },
    onRemoveWeek(week_id, phase_id) {
      const params = {
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#34c38f',
        cancelButtonColor: '#f46a6a',
        confirmButtonText: 'Yes, delete it!',
      };
      if (!this.canRemoveWeek(week_id, phase_id)) {
        params.title = 'Operation not allowed';
        params.text = 'This item cannot be removed';
        params.showCancelButton = false;
        params.confirmButtonText = 'Close';
        params.confirmButtonColor = '#556ee6';
      }
      Swal.fire(params).then(async (result) => {
        if (result.value) {
          this.phases_busy = true;

          await this.removeWeek(week_id, phase_id);

          // current phase
          const phase = this.phases.filter((phase) => phase.id === phase_id)[0];
          // week left in phase
          const weeks = phase.weeks.filter((week) => week.id !== week_id);

          if (weeks.length === 0) {
            await this.removePhase(phase_id);
          }
          await this.loadForecast();

          this.phases_busy = false;
        }
      });
    },
    async removeWeek(weekId, phaseId) {
      if (!this.canRemoveWeek(weekId, phaseId)) {
        return;
      }
      return this.$axios
        .delete(`forecast/phase/week/${weekId}`, {
          params: { id: weekId },
        });
    },
    canRemoveWeek(weekId, phaseId) {
      const template = this.phasesWeeksTemplates[this.form.term];
      if (!template) return false;

      const phaseIndx = _.findIndex(this.phases, { id: phaseId });
      if (phaseIndx < 0) return false;

      const weekIndx = _.findIndex(this.phases[phaseIndx].weeks, { id: weekId });
      if (weekIndx < 0) return false;

      // Phase has no weeks min length requirement and can be deleted
      const weeksMinLength = template[phaseIndx];
      if (!weeksMinLength) return true;

      // We can delete week if number of weeks more than weeks min length requirement
      return weeksMinLength < (weekIndx + 1);
    },
    async addPhaseAndWeek() {
      this.phases_busy = true;

      const phase_id = await this.addPhase();

      if (phase_id) {
        await this.addWeek(phase_id, this.defaultWeek);
      }

      await this.loadForecast();

      this.phases_busy = false;
    },
    generatePhasesAndWeeksAccordingToTerm() {
      const phasesWeeksTemplate = this.phasesWeeksTemplates[this.form.term];
      const phases = _.cloneDeep(this.phases) || [];

      if (!phasesWeeksTemplate) return phases;

      let phasesCount = Math.max(phasesWeeksTemplate.length, phases.length);
      for (let i = 0; i < phasesCount; i++) {
        let phase = phases[i];
        let weeksCount = phasesWeeksTemplate[i];

        // Add missing phases/weeks
        if (phase) {
          phase.weeks = phase.weeks || [];
          weeksCount = Math.max(weeksCount, phase.weeks.length);
          for (let j = 0; j < weeksCount; j++) {
            if (!phase.weeks[j]) {
              phase.weeks.push(this.defaultWeek);
            }
          }
        } else {
          phase = { weeks: [] };
          for (let j = 0; j < weeksCount; j++) {
            phase.weeks.push(this.defaultWeek);
          }
          phases.push(phase);
        }
      }
      return phases;
    },
    async addPhase(forecastId) {
      forecastId = forecastId || this.id;
      return this.$axios
        .post('forecast/phase', { forecast_id: forecastId })
        .then(async (response) => {
          return response.data.data.id;
        })
        .catch(() => {
          return false;
        });
    },
    async removePhase(phase_id) {
      return this.$axios
        .delete(`forecast/phase/${phase_id}`, {
          params: { id: phase_id },
        });
    },
    getSexOptions() {
      return this.$store.getters['asset/getSexOptions'];
    },
    getGoalDirectionsOptions() {
      return this.$store.getters['asset/getGoalDirectionsOptions'];
    },
    getFitnessLevelsOptions() {
      return this.$store.getters['asset/getAbilityLevelsOptions'];
    },
    getTermOptions() {
      return [
        { value: '16', text: '16 weeks' },
        { value: '32', text: '32 weeks' },
      ];
    },
    onStartEditEntity() {
      this.editEntityMode = true;
    },
    async loadForecast() {
      this.id = this.$route.params.id;

      if (this.id) {
        this.$axios.get(`/forecast/${this.id}`).then((response) => {
          if (this.isCopy) {
            response.data.data.name += ' - Copy';
          }
          this.form = response.data.data;
          this.formClone = _.cloneDeep(this.form);

          this.phases = response.data.data.phases;
        });
      }
    },
    getSetsString(week) {
      let s = [];
      //const reps = this.getReps(week);
      const reps = week.reps;
      for (let i = 1; i <= reps; i++) {
        s.push(week[`rep_${i}`]);
      }
      return s.join(', ');
    },
    onFormCancel() {
      if (this.isEdit) {
        this.form = _.cloneDeep(this.formClone);
      } else {
        this.form = [];
      }
    },
  },
  mounted() {
    this.loadForecast();
  },
  watch: {
    // watch for URL update to start over
    '$route.path'() {
      if (!this.keepData) {
        Object.assign(this.$data, this.initialData());
      }
      this.keepData = false;

      this.id = this.$route.params.id;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div
        class="animate-width-change"
        :class="!editEntityMode ? 'col-lg-8' : 'col-lg-4'"
      >
        <b-overlay
          :show="phases_busy"
          :variant="config.overlay.variant"
          :opacity="config.overlay.opacity"
          :blur="config.overlay.blur"
          rounded="sm"
        >
          <b-tabs class="phases-tabs">
            <!-- This tabs content will always be mounted -->
            <b-tab title="All Phases">
              <div class="card">
                <div class="card-body">
                  <b-table-simple>
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center">Week</b-th>
                        <b-th class="text-center">Rest</b-th>
                        <b-th>Rep. Sequence</b-th>
                        <b-th></b-th>
                      </b-tr>
                    </b-thead>
                    {{ resetCounter() }}
                    <b-tbody v-for="phase in phases" :key="phase.id">
                      <b-tr
                        v-for="week in phase.weeks"
                        :key="phase.id + '_' + week.id"
                      >
                        <b-td class="text-center">{{ getCounter() }}</b-td>
                        {{ incCounter() }}
                        <b-td class="text-center">{{ week.rest }}</b-td>
                        <b-td>{{ getSetsString(week) }}</b-td>
                        <b-td> </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
            </b-tab>

            <!-- This tabs content will not be mounted until the tab is shown -->
            <!-- and will be un-mounted when hidden -->
            <b-tab
              v-for="(phase, phase_index) in phases"
              :key="phase.id"
              :title="'Phase ' + (phase_index + 1)"
              lazy
            >
              <div class="card">
                <div class="card-body">
                  <b-table-simple>
                    <b-thead>
                      <b-tr>
                        <b-th class="text-center">Week</b-th>
                        <b-th class="text-center">Rest</b-th>
                        <b-th>Rep. Sequence</b-th>
                        <b-th></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr
                        v-for="(week, week_index) in phase.weeks"
                        :key="phase.id + '_' + week.id"
                      >
                        <b-td class="text-center align-middle">
                          {{ week_index + 1 }}
                        </b-td>
                        <b-td class="text-center align-middle">
                          <span v-if="edit_index !== phase.id + '_' + week.id">
                            {{ week.rest }}
                          </span>
                          <span v-if="edit_index === phase.id + '_' + week.id">
                            <b-select
                              :options="restOptions"
                              size="sm"
                              class="rest-select"
                              v-model="week.rest"
                            />
                          </span>
                        </b-td>
                        <b-td>
                          <span v-if="edit_index !== phase.id + '_' + week.id">
                            {{ getSetsString(week) }}
                          </span>
                          <span v-if="edit_index === phase.id + '_' + week.id">
                            <b-select
                              v-for="rep in week.reps"
                              :key="rep + '_' + week.reps"
                              :options="repOptions"
                              size="sm"
                              class="rep-select"
                              v-model="week[`rep_${rep}`]"
                            />
                          </span>
                        </b-td>
                        <b-td class="align-middle">
                          <span
                            class="no-wrap"
                            v-if="edit_index !== phase.id + '_' + week.id"
                          >
                            <!--
                            <a
                              class="float-right ml-1"
                              :class="{
                                'disabled': !canRemoveWeek(week.id, phase.id)
                              }"
                              title="Remove"
                              :key="'remove_' + phase.id + '_' + week.id"
                              @click="onRemoveWeek(week.id, phase.id)"
                            >
                              <FaIcon icon="far fa-trash-alt" size="18"/>
                            </a>
                            -->
                            <a
                              class="float-right"
                              title="Edit"
                              :key="'edit_' + phase.id + '_' + week.id"
                              @click="
                                onStartEdit(phase.id + '_' + week.id, week)
                              "
                            >
                              <FaIcon
                                icon="far fa-edit"
                                size="18"
                                color="#626ed4"
                              />
                            </a>
                          </span>
                          <span
                            class="no-wrap"
                            v-if="edit_index === phase.id + '_' + week.id"
                          >
                            <a
                              class="float-right ml-1"
                              :key="'cancel_' + phase.id + '_' + week.id"
                              title="Cancel"
                              @click="onCancelEdit(week)"
                            >
                              <FaIcon
                                icon="far fa-times-circle"
                                size="18"
                                color="#ec4561"
                              />
                            </a>
                            <a
                              class="float-right ml-1"
                              :key="'apply_' + phase.id + '_' + week.id"
                              title="Apply"
                              @click="onApplyEdit(week)"
                            >
                              <FaIcon
                                icon="fas fa-check-circle"
                                size="18"
                                color="#02a499"
                              />
                            </a>
                            <a
                              class="float-right ml-1"
                              :key="'add_set_' + phase.id + '_' + week.id"
                              title="Add Set"
                              @click="onAddSet(week)"
                            >
                              <FaIcon
                                icon="fas fa-plus-circle"
                                size="18"
                                color="#6c757d"
                              />
                            </a>
                            <a
                              class="float-right ml-1"
                              :key="'remove_set_' + phase.id + '_' + week.id"
                              title="Remove Set"
                              @click="onRemoveSet(week)"
                            >
                              <FaIcon
                                icon="fas fa-minus-circle"
                                size="18"
                                color="#6c757d"
                              />
                            </a>
                          </span>
                        </b-td>
                      </b-tr>
                      <!--
                      <b-tr>
                        <b-td colspan="4" class="text-center">
                          <a
                            title="Add Week"
                            :key="'add_week_' + phase.id"
                            @click="onAddWeek(phase.weeks, phase.id)"
                          >
                            <FaIcon
                              icon="fas fa-plus-circle"
                              size="30"
                              color="#626ed4"
                            />
                          </a>
                        </b-td>
                      </b-tr>
                      -->
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
            </b-tab>
            <b-tab
              v-if="phases.length < max_phases && isEdit"
              title="+ New Phase"
              :key="phases.length"
            >
              <div class="card">
                <div class="card-body">
                  <b-table-simple>
                    <b-thead>
                      <b-tr>
                        <b-th>Week</b-th>
                        <b-th>Rest</b-th>
                        <b-th>Rep. Sequence</b-th>
                        <b-th></b-th>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <b-tr>
                        <b-td colspan="4" class="text-center">
                          <a
                            title="Add Week"
                            @click="addPhaseAndWeek()"
                          >
                            <FaIcon
                              icon="fas fa-plus-circle"
                              size="30"
                              color="#626ed4"
                            />
                          </a>
                        </b-td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </b-overlay>
      </div>
      <!-- end col -->
      <div
        class="animate-width-change"
        :class="editEntityMode ? 'col-lg-8' : 'col-lg-4'"
      >
        <div class="card">
          <div class="card-body">
            <div v-show="!editEntityMode">
              <b-row>
                <b-col cols="8">
                  <h4 class="mb-3 card-title">Forecast - {{ form.name }}</h4>
                </b-col>
                <b-col cols="4">
                  <div class="float-right">
                    <button
                      type="edit"
                      @click="onStartEditEntity"
                      class="btn btn-secondary"
                    >
                      Edit
                    </button>
                  </div>
                </b-col>
              </b-row>
              <dl class="row mt-3 mb-0">
                <dt class="col-sm-5">Gender</dt>
                <dd class="col-sm-7">
                  {{ form.gender }}
                </dd>
                <dt class="col-sm-5">Goal Direction</dt>
                <dd class="col-sm-7">
                  {{ form.goal_direction_name }}
                </dd>
                <dt class="col-sm-5">Fitness Level</dt>
                <dd class="col-sm-7">
                  {{ form.fitness_level_name.join(', ') }}
                </dd>
                <dt class="col-sm-5">Description</dt>
                <dd class="col-sm-7">
                  {{ form.description }}
                </dd>
              </dl>
            </div>
            <div v-show="editEntityMode">
              <b-overlay
                :show="phases_busy"
                :variant="config.overlay.variant"
                :opacity="config.overlay.opacity"
                :blur="config.overlay.blur"
                rounded="sm"
              >
                <form action="#" @submit.prevent="onFormSubmit">
                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Forecast Name"
                    label-for="name"
                  >
                    <input
                      v-model="form.name"
                      type="text"
                      class="form-control"
                      placeholder="Forecast Name"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.name.$error,
                      }"
                    />
                    <div
                      v-if="formSubmitted && $v.form.name.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.name.required">
                        This value is required.
                      </span>
                      <span v-if="!$v.form.name.minLength">
                        This value is 5 letters minimum length.
                      </span>
                      <span v-if="!$v.form.name.maxLength">
                        This value is 255 letters maximum length.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Gender"
                    label-for="type"
                  >
                    <b-form-radio-group
                      id="gender"
                      v-model="form.gender"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.gender.$error,
                      }"
                      class="mt-2"
                      :options="getSexOptions()"
                    >
                    </b-form-radio-group>

                    <div
                      v-if="formSubmitted && $v.form.gender.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.gender.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Goal Direction"
                    label-for="goal_direction"
                  >
                    <b-form-radio-group
                      id="goal_direction"
                      v-model="form.goal_direction"
                      class="mb-1 mt-2"
                      :class="{
                        'is-invalid': formSubmitted &&
                                      $v.form.goal_direction.$error,
                      }"
                      :options="getGoalDirectionsOptions()"
                    >
                    </b-form-radio-group>

                    <div
                      v-if="formSubmitted && $v.form.goal_direction.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.goal_direction.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Fitness Level(s)"
                    label-for="ability-level"
                  >
                    <b-form-checkbox-group
                      id="ability-level"
                      name="ability-level"
                      :class="{
                        'is-invalid': formSubmitted &&
                                      $v.form.fitness_level.$error,
                      }"
                      class="mt-2 mb-2"
                    >
                      <b-form-checkbox
                        v-model="form.fitness_level"
                        v-for="level in abilityLevels"
                        :key="level.id"
                        :value="level.id"
                      >
                        {{ s(level.name).capitalize().value() }}
                      </b-form-checkbox>
                    </b-form-checkbox-group>

                    <div
                      v-if="formSubmitted && $v.form.fitness_level.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.fitness_level.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Term"
                    label-for="term"
                  >
                    <b-form-radio-group
                      id="term"
                      v-model="form.term"
                      :class="{
                        'is-invalid': formSubmitted && $v.form.term.$error,
                      }"
                      class="mt-2"
                      :options="getTermOptions()"
                    >
                    </b-form-radio-group>

                    <div
                      v-if="formSubmitted && $v.form.term.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.term.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    label-cols="9"
                    label-cols-sm="3"
                    label="Description"
                    label-for="description"
                  >
                    <b-form-textarea
                      v-model="form.description"
                      type="textarea"
                      rows="5"
                      class="form-control"
                      placeholder="Description"
                      :class="{
                        'is-invalid': onFormSubmit &&
                                      $v.form.description.$error,
                      }"
                    />
                    <div
                      v-if="onFormSubmit && $v.form.description.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.form.description.required">
                        This value is required.
                      </span>
                    </div>
                  </b-form-group>

                  <div class="form-group mb-0">
                    <div class="float-right">
                      <button
                        type="reset"
                        class="btn btn-secondary"
                        @click.stop.prevent="onFormCancel"
                      >
                        Cancel
                      </button>
                      <button type="submit" class="btn btn-primary m-l-5 ml-1">
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </b-overlay>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
  .phases-tabs .tab-pane {
    background: #e0e0ea;
  }
  .phases-tabs >>> .nav-tabs .nav-link.active {
    background: #e0e0ea;
    border-bottom: 1px solid #e0e0ea;
  }
  .phases-tabs .tab-pane .card {
    background: none;
  }
  .phases-tabs .tab-pane .card .card-body .table {
    background: #fff;
    margin-bottom: 0;
  }
  .phases-tabs .tab-pane .card .card-body .table th,
  .phases-tabs .tab-pane .card .card-body .table td {
    padding: 12px 6px;
  }
  .rep-select {
    max-width: 60px;
    width: 25%;
  }
  .rest-select {
    max-width: 60px;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .animate-width-change {
    transition: all 400ms ease;
  }
  .disabled, .disabled:hover {
    opacity: .5;
  }
</style>
