var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.supplement_id),
      "state": _vm.getFieldState(_vm.$v.input.supplement_id)
    }
  }, [_c('SupplementSelect', {
    attrs: {
      "item": _vm.newItem.supplement,
      "show-create": false,
      "placeholder": "Select a supplement...",
      "state": _vm.getFieldState(_vm.$v.input.supplement_id)
    },
    model: {
      value: _vm.input.supplement_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "supplement_id", $$v);
      },
      expression: "input.supplement_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Dose",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.dose),
      "state": _vm.getFieldState(_vm.$v.input.dose)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "placeholder": "Dose",
      "state": _vm.getFieldState(_vm.$v.input.dose)
    },
    model: {
      value: _vm.input.dose,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "dose", $$v);
      },
      expression: "input.dose"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Doses per Day",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.doses_per_day),
      "state": _vm.getFieldState(_vm.$v.input.doses_per_day)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "placeholder": "Dosage per Day",
      "state": _vm.getFieldState(_vm.$v.input.doses_per_day)
    },
    model: {
      value: _vm.input.doses_per_day,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "doses_per_day", $$v);
      },
      expression: "input.doses_per_day"
    }
  })], 1), _c('b-form-group', {
    staticClass: "mt-2 mb-0"
  }, [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }