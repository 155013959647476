<template>
  <b-overlay
    :show="isBusy"
    :variant="config.overlay.variant"
    :opacity="config.overlay.opacity"
    :blur="config.overlay.blur"
    rounded="sm"
  >
    <b-form
      @submit.prevent="formSubmit"
    >
      <b-form-group
        label="Phase Number"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.phase_number)"
      >
        <b-form-select
          v-model="input.phase_number"
          :options="[1, 2, 3, 4]"
          :state="getFieldState($v.input.phase_number)"
        />
      </b-form-group>

      <b-form-group
        label="Gender"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.gender)"
      >
        <b-form-select
          v-model="input.gender"
          :options="$store.getters['asset/getSexOptions']"
          :state="getFieldState($v.input.gender)"
        />
      </b-form-group>

      <b-form-group
        label="Goal Direction"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.goal_direction_id)"
      >
        <b-form-select
          v-model="input.goal_direction_id"
          :options="$store.getters['asset/getGoalDirectionsOptions']"
          :state="getFieldState($v.input.goal_direction_id)"
        />
      </b-form-group>

      <b-form-group
        label="Weight"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.weight)"
      >
        <b-form-input
          v-model="input.weight"
          type="number"
          number
          :state="getFieldState($v.input.weight)"
        />
      </b-form-group>

      <b-form-group
        label="Weight Loss Ratio"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.weight_loss_ratio)"
      >
        <b-form-input
          v-model="input.weight_loss_ratio"
          type="number"
          number
          step="0.00000000001"
          :state="getFieldState($v.input.weight_loss_ratio)"
        />
      </b-form-group>

      <b-form-group
        label="Increment"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.increment)"
      >
        <b-form-input
          v-model="input.increment"
          type="number"
          number
          step="0.00000000001"
          :state="getFieldState($v.input.increment)"
        />
      </b-form-group>

      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small /> Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import appConfig from '@/app.config'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'WeightLossItemUpsert',

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    prefetch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      config: appConfig,
      isBusy: false,
      isSubmitting: false,
      newItem: {},
      input: {}
    }
  },

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        if (!_.isEqual(this.item, this.newItem)) {
          this.newItem = _.cloneDeep(this.item)

          this.$v.$reset()

          if (this.prefetch) {
            this.fetchItem()
          }
        }
      }
    },

    newItem: {
      immediate: true,
      deep: true,
      handler() {
        if (this.newItem && !_.isEqual(this.newItem, this.item)) {
          this.$emit('update:item', _.cloneDeep(this.newItem))
        }

        if (this.newItem) {
          this.setInput()
        }
      }
    }
  },

  validations() {
    return {
      input: {
        phase_number: { required },
        weight: { required },
        weight_loss_ratio: { required },
        increment: { required },
        gender: { required },
        goal_direction_id: { required }
      }
    }
  },

  methods: {
    setInput() {
      this.input = {
        id: this.newItem.id,
        phase_number: this.newItem.phase_number,
        weight: this.newItem.weight,
        weight_loss_ratio: this.newItem.weight_loss_ratio,
        increment: this.newItem.increment,
        gender: this.newItem.gender,
        goal_direction_id: this.newItem.goal_direction_id
      } 
    },

    getFieldState(vuelidateParam) {
      return vuelidateParam && vuelidateParam.$dirty ? !vuelidateParam.$invalid : null
    },

    formSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return
      }

      if (this.input.id) {
        this.updateItem()
      } else {
        this.createItem()
      }
    },

    fetchItem() {
      this.$axios
        .get(`admin/weight-loss/${this.newItem.id}`, {
          parent: this
        })
        .then(({ data }) => {
          this.newItem = data
        })
        .catch((e) => {
          throw e
        })
    },

    updateItem() {
      this.isSubmitting = true

      this.$axios
        .patch(`admin/weight-loss/${this.input.id}`, this.input)
        .then(({ data }) => {
          this.newItem = data
          this.isSubmitting = false
          this.$emit('submitted', this.newItem)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    createItem() {
      this.isSubmitting = true

      this.$axios
        .post('admin/weight-loss', this.input)
        .then(({ data }) => {
          this.newItem = data
          this.isSubmitting = false
          this.$emit('submitted', this.newItem)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>