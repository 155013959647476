import { render, staticRenderFns } from "./workout-program-popup-button.vue?vue&type=template&id=11545e9e&"
import script from "./workout-program-popup-button.vue?vue&type=script&lang=js&"
export * from "./workout-program-popup-button.vue?vue&type=script&lang=js&"
import style0 from "./workout-program-popup-button.vue?vue&type=style&index=0&lang=scss&scope=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports