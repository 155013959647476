var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-card', [_c('ImageListFilters', {
    attrs: {
      "filters": _vm.params.filters
    },
    on: {
      "update:filters": function updateFilters($event) {
        return _vm.$set(_vm.params, "filters", $event);
      }
    }
  })], 1), _c('b-row', [_c('b-col', [_c('b-card', [_c('h3', [_vm._v("Starting Image")]), _c('ImageList', {
    ref: "list-starting",
    attrs: {
      "client": _vm.client,
      "params": Object.assign({}, _vm.params, {
        filters: Object.assign({}, _vm.params.filters, {
          categories: ['front'],
          is_favorite: null
        }),
        sort_desc: false,
        limit: 1
      }),
      "cols": 1,
      "pagination": false
    },
    on: {
      "delete:item": function deleteItem($event) {
        return _vm.refreshExcept("list-starting");
      },
      "update:item": function updateItem($event) {
        return _vm.refreshExcept("list-starting");
      }
    }
  })], 1)], 1), _c('b-col', [_c('b-card', [_c('h3', [_vm._v("Now")]), _c('ImageList', {
    ref: "list-now",
    attrs: {
      "client": _vm.client,
      "params": Object.assign({}, _vm.params, {
        filters: Object.assign({}, _vm.params.filters, {
          categories: ['front'],
          is_favorite: null
        }),
        sort_desc: true,
        limit: 1
      }),
      "cols": 1,
      "pagination": false
    },
    on: {
      "update:item": function updateItem($event) {
        return _vm.refreshExcept("list-now");
      },
      "delete:item": function deleteItem($event) {
        return _vm.refreshExcept("list-now");
      }
    }
  })], 1)], 1)], 1), _vm._l(_vm.$store.getters['asset/getImageCategoriesOptions'], function (category) {
    return _c('b-card', {
      key: category.value
    }, [_c('h3', [_vm._v(_vm._s(category.text))]), _c('ImageList', {
      ref: "list-".concat(category.value),
      refInFor: true,
      attrs: {
        "client": _vm.client,
        "params": Object.assign({}, _vm.params, {
          filters: Object.assign({}, _vm.params.filters, {
            categories: [category.value]
          })
        })
      },
      on: {
        "update:item": function updateItem($event) {
          return _vm.refreshExcept("list-".concat(category.value));
        },
        "delete:item": function deleteItem($event) {
          return _vm.refreshExcept("list-".concat(category.value));
        }
      }
    })], 1);
  }), _c('b-card', [_c('h3', [_vm._v("Other")]), _c('ImageList', {
    ref: "list-other",
    attrs: {
      "client": _vm.client,
      "params": Object.assign({}, _vm.params, {
        filters: Object.assign({}, _vm.params.filters, {
          categories: ['other']
        })
      })
    },
    on: {
      "update:item": function updateItem($event) {
        return _vm.refreshExcept("list-other");
      },
      "delete:item": function deleteItem($event) {
        return _vm.refreshExcept("list-other");
      }
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }