<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-row>
        <b-col
          cols="12"
          lg="3"
        >
          <!-- LOG TIME -->
          <b-form-group
            label="Date"
            label-class="font-size-12"
            label-cols="5"
            content-cols="7"
            label-cols-lg="3"
            content-cols-lg="9"
            :invalid-feedback="getFieldInvalidFeedback($v.input.log_time)"
            :state="getFieldState($v.input.log_time)"
          >
            <b-form-input
              v-model="input.log_time"
              type="date"
              :state="getFieldState($v.input.log_time)"
            />
          </b-form-group>

          <!-- Session mins -->
          <b-form-group
            label="Session"
            label-class="font-size-12"
            label-cols="5"
            content-cols="7"
            label-cols-lg="3"
            content-cols-lg="9"
          >
            <b-input-group
              append="mins"
            >
              <b-form-input
                :value="newItem.session_mins"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="4"
        >
          <!-- MACHINE -->
          <b-form-group
            label="Machine Type"
            label-class="font-size-12"
            label-cols="5"
            content-cols="7"
            label-cols-lg="4"
            content-cols-lg="8"
            :invalid-feedback="getFieldInvalidFeedback($v.input.machine_id)"
            :state="getFieldState($v.input.machine_id)"
          >
            <b-form-select
              v-model="input.machine_id"
              :options="$store.getters['asset/getCardioMachinesOptions']"
              :state="getFieldState($v.input.machine_id)"
            />
          </b-form-group>

          <!-- NUMBER OF INTERVALS -->
          <b-form-group
            label="# of Intervals"
            label-class="font-size-12"
            label-cols="5"
            content-cols="7"
            label-cols-lg="4"
            content-cols-lg="8"
            :invalid-feedback="getFieldInvalidFeedback($v.input.num_intervals)"
            :state="getFieldState($v.input.num_intervals)"
          >
            <b-form-select
              v-model="input.num_intervals"
              :options="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20]"
              :state="getFieldState($v.input.num_intervals)"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          lg="5"
        >
          <!-- HIT TIME -->
          <b-form-group
            label="HIT Time"
            label-class="font-size-12"
            label-cols="5"
            content-cols="7"
            label-cols-lg="5"
            content-cols-lg="7"
            :invalid-feedback="getFieldInvalidFeedback($v.input.hit_time)"
            :state="getFieldState($v.input.hit_time)"
          >
            <TimeInput
              v-model="input.hit_time"
            />
          </b-form-group>

          <!-- LOW INTENSITY TIME -->
          <b-form-group
            label="Low Intensity Time"
            label-class="font-size-12"
            label-cols="5"
            content-cols="7"
            label-cols-lg="5"
            content-cols-lg="7"
            :invalid-feedback="getFieldInvalidFeedback($v.input.low_intensity_time)"
            :state="getFieldState($v.input.low_intensity_time)"
          >
            <TimeInput
              v-model="input.low_intensity_time"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-2 mb-0">
        <b-button
          variant="success"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import { required } from "vuelidate/lib/validators"
import crudItemMixin from '@/mixins/crud-item'
import TimeInput from '@/components/inputs/time-input'

export default {
  name: 'TrackerCardioItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    TimeInput
  },

  data() {
    return {
      baseEndpoint: 'tracker-cardios'
    }
  },

  validations() {
    return {
      input: {
        log_time: { required },
        machine_id: { required },
        num_intervals: { required },
        hit_time: { required },
        low_intensity_time: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        log_time: this.$moment(this.newItem.log_time || this.$moment()).format('YYYY-MM-DD'),
        machine_id: _.get(this.newItem, 'machine_id'),
        num_intervals: _.get(this.newItem, 'num_intervals'),
        hit_time: _.get(this.newItem, 'hit_time'),
        low_intensity_time: _.get(this.newItem, 'low_intensity_time'),
      }

      if (!this.isCreated) {
        input.user_id = _.get(this.newItem, 'user_id')
        input.curriculum_phase_id = _.get(this.newItem, 'curriculum_phase_id')
      }

      return input
    }
  }
}
</script>
