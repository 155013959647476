var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', {
    attrs: {
      "id": "page-topbar"
    }
  }, [_c('div', {
    staticClass: "navbar-header"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "navbar-brand-box"
  }, [_c('router-link', {
    staticClass: "logo logo-dark",
    attrs: {
      "tag": "a",
      "to": "/"
    }
  }, [_c('span', {
    staticClass: "logo-sm"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo_gold.png"),
      "alt": "",
      "height": "22"
    }
  })]), _c('span', {
    staticClass: "logo-lg"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo_gold.png"),
      "alt": "",
      "height": "110"
    }
  })])]), _c('router-link', {
    staticClass: "logo logo-light",
    attrs: {
      "tag": "a",
      "to": "/"
    }
  }, [_c('span', {
    staticClass: "logo-sm"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo_gold.png"),
      "alt": "",
      "height": "22"
    }
  })]), _c('span', {
    staticClass: "logo-lg"
  }, [_c('img', {
    attrs: {
      "src": require("@/assets/images/logo_gold.png"),
      "alt": "",
      "height": "110"
    }
  })])])], 1), _c('button', {
    staticClass: "btn btn-sm px-3 font-size-24 header-item",
    attrs: {
      "type": "button",
      "id": "vertical-menu-btn"
    },
    on: {
      "click": function click($event) {
        return _vm.toggleMenu();
      }
    }
  }, [_c('i', {
    staticClass: "mdi mdi-menu"
  })]), _c('div', {
    staticClass: "d-none d-sm-block"
  }, [_c('CompanySelect', {
    staticClass: "pt-3 d-inline-block"
  })], 1), _vm.$store.getters['auth/isAdmin'] ? _c('div', {
    staticClass: "d-none d-sm-block ml-1"
  }, [_c('AdminViewCheckbox', {
    staticClass: "pt-3 d-inline-block"
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-dropdown', {
    staticClass: "d-inline-block",
    attrs: {
      "right": "",
      "toggle-class": "header-item",
      "variant": "white"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_c('b-avatar', {
          attrs: {
            "src": _vm.currentUser.profile_img_url,
            "text": _vm.currentUserAvatarText,
            "badge": "".concat(_vm.currentUser.id)
          }
        })];
      },
      proxy: true
    }])
  }, [_vm.$store.getters['auth/loggedIn'] ? _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": "/profile"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-account-circle font-size-17 align-middle mr-1"
  }), _vm._v(" Profile ")]) : _vm._e(), _c('router-link', {
    staticClass: "dropdown-item",
    attrs: {
      "to": "/account"
    }
  }, [_c('i', {
    staticClass: "mdi mdi-settings font-size-17 align-middle mr-1"
  }), _vm._v(" Account ")]), _c('div', {
    staticClass: "dropdown-divider"
  }), _c('a', {
    staticClass: "dropdown-item text-danger",
    attrs: {
      "href": "/"
    },
    on: {
      "click": function click(e) {
        e.preventDefault();

        _vm.logout();
      }
    }
  }, [_c('i', {
    staticClass: "bx bx-power-off font-size-17 align-middle mr-1 text-danger"
  }), _vm._v(" Logout ")])], 1)], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }