<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table-simple
      class="mb-0"
      fixed
      striped
      stacked="sm"
    >
      <b-thead>
        <b-tr variant="primary">
          <b-th class="w-120px" />
          <b-th class="text-center">Calories</b-th>
          <b-th class="text-center">Protein</b-th>
          <b-th class="text-center">Carbs</b-th>
          <b-th class="text-center">Fats</b-th>
          <b-th class="w-50px" />
        </b-tr>
      </b-thead>

      <b-tbody>
        <b-tr
          v-for="mealFood in newResult.data"
          :key="mealFood.id"
        >
          <b-th
            variant="success"
            class="font-weight-bold text-center align-middle"
          >
            <TrackerMealFoodItemUpsertButton
              :item="mealFood"
              class="p-0 font-weight-bolder"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @submitted="$emit('submitted:item', $event)"
            >
              {{ mealFood.food.name }}
            </TrackerMealFoodItemUpsertButton>

            <div>
              {{ mealFood.amount }} {{ mealFood.food.serving_units }}
            </div>
          </b-th>

          <b-td
            class="text-center align-middle"
            stacked-heading="Calories"
          >
            {{ calcMealFoodMacro(mealFood, 'calories') }}
          </b-td>

          <b-td
            class="text-center align-middle"
            stacked-heading="Protein"
          >
            {{ calcMealFoodMacro(mealFood, 'protein') }}
          </b-td>

          <b-td
            class="text-center align-middle"
            stacked-heading="Carbs"
          >
            {{ calcMealFoodMacro(mealFood, 'carbs') }}
          </b-td>

          <b-td
            class="text-center align-middle"
            stacked-heading="Fats"
          >
            {{ calcMealFoodMacro(mealFood, 'fat') }}
          </b-td>

          <b-td class="text-center align-middle">
            <TrackerMealFoodItemDeleteButton
              :item="mealFood"
              class="font-size-12"
              tag="b-button"
              :tag-props="{
                variant: 'link'
              }"
              @delete:item="$emit('delete:item', $event)"
            >
              <b-icon-trash />
            </TrackerMealFoodItemDeleteButton>
          </b-td>
        </b-tr>

        <b-tr>
          <b-td
            class="align-middle"
            colspan="6"
          >
            <b-row align="between">
              <b-col class="text-center">
                <TrackerMealFoodItemUpsertButton
                  :item="{ meal_id: newParams.filters.meal_id }"
                  class="p-0 mr-4"
                  tag="b-button"
                  :tag-props="{ variant: 'link' }"
                  @submitted="$emit('submitted:item', $event)"
                >
                  <b-icon-plus-circle class="mr-1" scale="0.8" /> Add food
                </TrackerMealFoodItemUpsertButton>
              </b-col>
            </b-row>
          </b-td>
        </b-tr>

        <b-tr
          v-if="macroRecommended"
          variant="secondary"
        >
          <b-th class="text-right font-weight-bolder">
            Recommended
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Calories"
          >
            {{ macroRecommended.calories }}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Protein"
          >
            {{ macroRecommended.protein }}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Carbs"
          >
            {{ macroRecommended.carbs }}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Fats"
          >
            {{ macroRecommended.fat }}
          </b-th>

          <b-th />
        </b-tr>

        <b-tr variant="secondary">
          <b-th class="text-right font-weight-bolder">
            Total
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Calories"
          >
            {{ macroTotal.calories }}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Protein"
          >
            {{ macroTotal.protein }}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Carbs"
          >
            {{ macroTotal.carbs }}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Fats"
          >
            {{ macroTotal.fat }}
          </b-th>

          <b-th />
        </b-tr>

        <b-tr
          v-if="meal.curriculum_meal"
          variant="secondary"
        >
          <b-th class="text-right font-weight-bolder">
            +/-
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Calories"
          >
            {{ macroDifference.calories}}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Protein"
          >
            {{ macroDifference.protein }}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Carbs"
          >
            {{ macroDifference.carbs }}
          </b-th>

          <b-th
            class="text-center font-weight-bolder"
            stacked-heading="Fats"
          >
            {{ macroDifference.fat }}
          </b-th>

          <b-th />
        </b-tr>
      </b-tbody>
    </b-table-simple>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import TrackerMealFoodItemUpsertButton from './buttons/TrackerMealFoodItemUpsertButton'
import TrackerMealFoodItemDeleteButton from './buttons/TrackerMealFoodItemDeleteButton'

export default {
  name: 'TrackerMealList',

  mixins: [
    crudListMixin
  ],

  components: {
    TrackerMealFoodItemUpsertButton,
    TrackerMealFoodItemDeleteButton
  },

  props: {
    meal: {
      type: Object,
      required: true
    }
  },

  computed: {
    baseEndpoint() {
      return 'tracker-meal-foods'
    },

    macroTotal() {
      return {
        calories: this.calcMealFoodsMacro(this.newResult.data, 'calories'),
        protein: this.calcMealFoodsMacro(this.newResult.data, 'protein'),
        carbs: this.calcMealFoodsMacro(this.newResult.data, 'carbs'),
        fat: this.calcMealFoodsMacro(this.newResult.data, 'fat')
      }
    },

    macroRecommended() {
      return this.meal.curriculum_meal ? {
        calories: this.calcMealCalories(this.meal.curriculum_meal),
        protein: this.meal.curriculum_meal.protein || 0,
        carbs: this.meal.curriculum_meal.carbs || 0,
        fat: this.meal.curriculum_meal.fat || 0
      } : null
    },

    macroDifference() {
      return this.macroRecommended ? {
        calories: Math.round((this.macroTotal.calories - this.macroRecommended.calories) * 100) / 100,
        protein: Math.round((this.macroTotal.protein - this.macroRecommended.protein) * 100) / 100,
        carbs: Math.round((this.macroTotal.carbs - this.macroRecommended.carbs) * 100) / 100,
        fat: Math.round((this.macroTotal.fat - this.macroRecommended.fat) * 100) / 100,
      } : null
    }
  },

  methods: {
    calcMealFoodMacro(mealFood, type) {
      const mealFoodMacro = Math.round(mealFood.amount * (mealFood.food.serving_size || 1) * mealFood.food[type]) / 100
      const decimalPlaces = type === 'calories' ? 0 : 1
      return Math.round(mealFoodMacro * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces)
    },

    calcMealFoodsMacro(mealFoods, type) {
      return _.chain(mealFoods)
        .map(i => this.calcMealFoodMacro(i, type))
        .sum()
        .round(2)
        .value()
    },

    calcMealCalories(meal) {
      return _.chain(['protein', 'carbs', 'fat'])
        .map(i => this.getCaloriesByGrams(meal[i], i))
        .sum()
        .round(2)
        .value()
    },

    getCaloriesByGrams(value, type) {
      const caloriesPerGram = type === 'fat' ? 9 : 4
      const calories = Math.round(parseFloat(value || '0') * caloriesPerGram * 100) / 100

      return calories
    }
  }
}
</script>