<template>
  <Layout>
    <b-overlay
      :show="isBusy"
      :variant="config.overlay.variant"
      :opacity="config.overlay.opacity"
      :blur="config.overlay.blur"
      rounded="sm"
    >
      <template v-if="client">
        <b-row>
          <b-col cols="8">
            <PageHeader :title="title" />
          </b-col>

          <b-col
            cols="4"
            class="d-flex align-items-center justify-content-end"
          />
        </b-row>

        <b-card>
          <CurriculumList
            ref="list"
            :params="{
              filters: {
                client_id: client.id
              }
            }"
          />
        </b-card>
      </template>
    </b-overlay>
  </Layout>
</template>

<script>
import appConfig from '@/app.config'
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import CurriculumList from '@/components/curriculums/CurriculumList'

export default {
  name: 'ClientCurriculumPage',

  components: {
    Layout,
    PageHeader,
    CurriculumList
  },

  page() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      isBusy: false,
      config: appConfig,
      client: null
    }
  },

  computed: {
    title() {
      return this.client ? `${this.client.first_name || this.client.email}'s Curriculum Histories` : null
    }
  },

  watch: {
    $route: {
      immediate: true,
      deep: true,
      handler() {
        this.fetchItem()
      }
    }
  },

  methods: {
    refresh() {
      return this.$refs.list.refresh()
    },

    fetchItem() {
      this.$axios
        .get(`users/${this.$route.params.id}`, {
          parent: this
        })
        .then(({ data }) => {
          this.client = data
        })
        .catch((e) => {
          throw e
        })
    }
  }
}
</script>