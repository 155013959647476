var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1)], 1), !_vm.isCreated || _vm.isFetched ? _c('b-row', [_c('b-col', [_c('b-card', {
    attrs: {
      "body-class": "table-primary p-0"
    }
  }, [_c('ExerciseListSelect', {
    staticClass: "p-3",
    attrs: {
      "draggable-group-name": "exerciseGroupItems",
      "clone": function clone(exercise) {
        return {
          exercise: exercise
        };
      }
    }
  })], 1)], 1), _c('b-col', [_c('b-card', [_c('b-collapse', {
    attrs: {
      "visible": _vm.mode === '#exercises'
    }
  }, [_c('b-row', {
    staticClass: "pb-3"
  }, [_c('b-col', {
    staticClass: "font-weight-bold font-size-18",
    attrs: {
      "cols": "8"
    }
  }, [_vm._v(" Workout - " + _vm._s(_vm.newItem.name) + " ")]), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        _vm.mode = '';
      }
    }
  }, [_vm._v(" Edit ")])], 1)], 1)], 1), _c('b-collapse', {
    attrs: {
      "visible": _vm.mode !== '#exercises'
    }
  }, [_c('WorkoutItemUpsert', {
    attrs: {
      "item": _vm.newItem,
      "show-cancel": _vm.isCreated
    },
    on: {
      "update:item": [function ($event) {
        _vm.newItem = $event;
      }, _vm.onUpdateItem],
      "submitted": function submitted($event) {
        _vm.mode = '#exercises';
      },
      "cancel": function cancel($event) {
        _vm.mode = '#exercises';
      }
    }
  })], 1), _vm.isCreated ? _c('b-collapse', {
    attrs: {
      "visible": _vm.mode === '#exercises'
    }
  }, [_c('ExerciseGroupList', {
    attrs: {
      "params": {
        filters: {
          workout_id: _vm.newItem.id
        }
      }
    }
  }), _c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "to": "/workout/list",
      "variant": "success"
    }
  }, [_vm._v(" Finish ")])], 1)], 1) : _vm._e()], 1)], 1)], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }