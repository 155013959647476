<template>
  <b-overlay :show="isFetching">
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- CARDIO DATA -->
      <b-form-group
        :invalid-feedback="getFieldInvalidFeedback($v.input.phases)"
        :state="getFieldState($v.input.phases)"
      >
        <CurriculumPhasesCardioInput
          v-model="input.phases"
          :curriculum="item"
        />
      </b-form-group>

      <template v-if="!mode || mode === 'data'">
        <b-button
          v-if="inputIsDirty"
          variant="primary"
          :disabled="isSubmitting"
          block
          @click="submit()"
        >
          <b-spinner v-if="isSubmitting" small />Generate Cardio Schedule
        </b-button>

        <b-tabs
          v-else
          active-nav-item-class="bg-tab text-primary"
          nav-class="border-0"
          justified
          no-fade
        >
          <b-tab
            v-for="phase in newItem.phases"
            :key="phase.id"
            lazy
            title-link-class="rounded-0 border-0"
            :title="`Phase ${phase.number}`"
          >
            <CurriculumPhaseCardios
              :item="phase"
              :curriculum="item"
            />
          </b-tab>
        </b-tabs>
      </template>

      <!-- SUBMIT -->
      <b-form-group class="text-right my-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import CurriculumPhasesCardioInput from './inputs/CurriculumPhasesCardioInput'
import CurriculumPhaseCardios from './CurriculumPhaseCardios'

export default {
  name: 'CurriculumItemCardioUpdate',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumPhasesCardioInput,
    CurriculumPhaseCardios
  },

  props: {
    mode: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  },

  validations() {
    return {
      input: {
        phases: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        phases: _.map(this.newItem.phases, phase => ({
          id: phase.id,
          number: phase.number,
          cardio_morning: phase.cardio_morning,
          cardio_evening: phase.cardio_evening,
          cardio_mins_per_session: phase.cardio_mins_per_session
        }))
      }

      return input
    }
  }
}
</script>