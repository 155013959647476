<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Email"
        :invalid-feedback="getFieldInvalidFeedback($v.input.email)"
        :state="getFieldState($v.input.email)"
      >
        <b-form-input
          v-model="input.email"
          :state="getFieldState($v.input.email)"
          size="lg"
          placeholder="Enter Your Email"
        />
      </b-form-group>

      <b-form-group
        label="Password"
        :invalid-feedback="getFieldInvalidFeedback($v.input.password)"
        :state="getFieldState($v.input.password)"
      >
        <b-form-input
          v-model="input.password"
          type="password"
          placeholder="Enter Your Password"
          size="lg"
          :state="getFieldState($v.input.password)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-2 mb-0">
        <b-button
          type="submit"
          variant="primary"
          size="lg"
          block
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Login
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import { required, email } from "vuelidate/lib/validators"
import crudItemMixin from '@/mixins/crud-item'

export default {
  name: 'Login',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  validations() {
    return {
      input: {
        email: { required, email },
        password: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        email: null,
        password: null
      }

      return input
    },

    async submit(submittedEventName) {
      this.$v.$touch()

      if (this.$v.$error) {
        return
      }

      this.isSubmitting = true

      return this.$axios
        .post('auth/login', this.input)
  
        .then(async ({ data }) => {
          await this.$store.dispatch('auth/setTokens', data)
          this.isSubmitting = false

          this.$store.commit("layout/addAlerts", {
            type: "success",
            text: 'You have successfully logged in'
          })

          this.setInput()

          if (submittedEventName) {
            this.$emit(submittedEventName, data)
          }
        })
  
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>
