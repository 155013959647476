<template>
  <b-overlay :show="isFetching || isSubmitting">
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      no-local-sorting
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(gender)="row">
        <Genders :ids="[row.item.gender]" />
      </template>

      <template #cell(goal_direction_id)="row">
        <GoalDirections :ids="[row.item.goal_direction_id]" />
      </template>

      <template #cell(fitness_level_id)="row">
        <Abilities :ids="[row.item.fitness_level_id]" />
      </template>

      <template #cell(actions)="row">
        <WorkoutsPerWeekItemUpsertButton
          :item="row.item"
          tag="b-button"
          class="p-0 mr-3"
          :tag-props="{
            variant: 'link',
            size: 'sm'
          }"
          @submitted="refresh"
        >
          <b-icon-gear />
        </WorkoutsPerWeekItemUpsertButton>

        <WorkoutsPerWeekItemDeleteButton
          :item="row.item"
          tag="b-button"
          class="p-0"
          :tag-props="{
            variant: 'link',
            size: 'sm'
          }"
          @delete:item="refresh"
        >
          <b-icon-trash />
        </WorkoutsPerWeekItemDeleteButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import WorkoutsPerWeekItemUpsertButton from './buttons/WorkoutsPerWeekItemUpsertButton'
import WorkoutsPerWeekItemDeleteButton from './buttons/WorkoutsPerWeekItemDeleteButton'
import Genders from '@/components/v1/asset/Genders'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import Abilities from '@/components/v1/asset/Abilities'

export default {
  name: 'WorkoutsPerWeekList',

  mixins: [
    crudListMixin
  ],

  components: {
    WorkoutsPerWeekItemUpsertButton,
    WorkoutsPerWeekItemDeleteButton,
    Genders,
    GoalDirections,
    Abilities
  },

  data() {
    return {
      fields: [
        { key: 'phase_number', sortable: false },
        { key: 'gender', sortable: false },
        { key: 'goal_direction_id', sortable: false, label: 'Goal Direction' },
        { key: 'fitness_level_id', sortable: false, label: 'Fitness Level' },
        { key: 'number', sortable: false },
        { key: 'morning_number', sortable: false },
        { key: 'evening_number', sortable: false },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  },

  computed: {
    baseEndpoint() {
      return 'admin/workouts-per-week'
    }
  }
}
</script>