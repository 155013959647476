var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "size": "sm",
      "variant": _vm.showValues ? 'primary' : 'outline-primary'
    },
    on: {
      "click": function click($event) {
        _vm.showValues = !_vm.showValues;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.showValues ? 'Hide numbers' : 'Show numbers') + " ")])], 1), _c('v-chart', {
    style: {
      height: '400px'
    },
    attrs: {
      "option": _vm.option,
      "autoresize": ""
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }