<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-form
      @submit.prevent="submit"
    >
      <!-- Quantity -->
      <b-form-group
        label="Quantity"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.quantity)"
        :state="getFieldState($v.input.quantity)"
      >
        <b-form-select
          v-model="input.quantity"
          size="lg"
          :options="[1, 2, 3, 4, 5]"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          variant="success"
          type="submit"
          block
          size="lg"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small /> ${{ item.price * input.quantity }} | Checkout
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'CompanyProductItemCheckout',

  mixins: [
    crudItemMixin
  ],

  props: {
    quantity: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      baseEndpoint: 'company-products'
    }
  },

  validations() {
    const input = {
      quantity: { required }
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        quantity: this.quantity
      }

      return input
    },

    submit() {
      const endpoint = `${this.baseEndpoint}/${this.newItem.id}/checkout`
      this.isSubmitting = true
      
      return this.$axios
        .post(endpoint, this.input)
  
        .then(async ({ data }) => {
          window.location.href = data.url

          setTimeout(() => {
            this.isSubmitting = false
          }, 2000)
        })
  
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>