var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching,
      "rounded": "sm"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-cols": "2",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.name),
      "state": _vm.getFieldState(_vm.$v.input.name)
    }
  }, [_c('b-form-input', {
    attrs: {
      "state": _vm.getFieldState(_vm.$v.input.name),
      "placeholder": "Enter Workout Program Name"
    },
    model: {
      value: _vm.input.name,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "name", $$v);
      },
      expression: "input.name"
    }
  })], 1), _vm.isExpanded ? _c('b-form-group', {
    attrs: {
      "label": "Description",
      "label-cols": "2",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.description),
      "state": _vm.getFieldState(_vm.$v.input.description)
    }
  }, [_c('b-form-textarea', {
    attrs: {
      "state": _vm.getFieldState(_vm.$v.input.description),
      "placeholder": "Enter Workout Program Description"
    },
    model: {
      value: _vm.input.description,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "description", $$v);
      },
      expression: "input.description"
    }
  })], 1) : _vm._e(), _c('b-button', {
    staticClass: "px-0",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function click($event) {
        _vm.isExpanded = !_vm.isExpanded;
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.isExpanded ? 'Less' : 'More') + " ")]), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(_vm._s(_vm.actionName) + " ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }