var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._b({
    tag: "component"
  }, 'component', _vm.tagProps, false), _vm._l(_vm.options, function (option) {
    return _c('b-button', {
      key: option.value,
      staticClass: "my-1",
      "class": _vm.buttonClass,
      attrs: {
        "size": "sm",
        "disabled": _vm.disabledValues.includes(option.value),
        "pressed": _vm.isActive(option.value),
        "variant": _vm.isActive(option.value) ? 'primary' : ''
      },
      on: {
        "click": function click($event) {
          return _vm.toggleItem(option.value);
        }
      }
    }, [_vm._v(" " + _vm._s(option.text) + " ")]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }