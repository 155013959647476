var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isCreated && !_vm.isFetched ? _c('div', {
    staticClass: "text-center"
  }, [_vm._v(" " + _vm._s(_vm.isFetching ? 'Loading...' : 'Item does not exist or you have no permissions to access it.') + " ")]) : _c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_vm.isCreated && _vm.ownersIsEditable && !_vm.input.owner_ids.length ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" The company has no owners, please add one or more owners. ")]) : _vm._e(), _c('b-form-group', {
    attrs: {
      "label": "Company name",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.name),
      "state": _vm.getFieldState(_vm.$v.input.name)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter company name",
      "state": _vm.getFieldState(_vm.$v.input.name)
    },
    model: {
      value: _vm.input.name,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "name", $$v);
      },
      expression: "input.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Email",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.email),
      "state": _vm.getFieldState(_vm.$v.input.email)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter company email",
      "state": _vm.getFieldState(_vm.$v.input.email)
    },
    model: {
      value: _vm.input.email,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "email", $$v);
      },
      expression: "input.email"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Phone",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.phone),
      "state": _vm.getFieldState(_vm.$v.input.phone)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter company phone",
      "state": _vm.getFieldState(_vm.$v.input.phone)
    },
    model: {
      value: _vm.input.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phone", $$v);
      },
      expression: "input.phone"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Address",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.address_line1),
      "state": _vm.getFieldState(_vm.$v.input.address_line1)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter company address",
      "state": _vm.getFieldState(_vm.$v.input.address_line1)
    },
    model: {
      value: _vm.input.address_line1,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "address_line1", $$v);
      },
      expression: "input.address_line1"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Address line 2",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.address_line2),
      "state": _vm.getFieldState(_vm.$v.input.address_line2)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter company address line 2",
      "state": _vm.getFieldState(_vm.$v.input.address_line2)
    },
    model: {
      value: _vm.input.address_line2,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "address_line2", $$v);
      },
      expression: "input.address_line2"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "City",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.city),
      "state": _vm.getFieldState(_vm.$v.input.city)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter company city",
      "state": _vm.getFieldState(_vm.$v.input.city)
    },
    model: {
      value: _vm.input.city,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "city", $$v);
      },
      expression: "input.city"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "State",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.state),
      "state": _vm.getFieldState(_vm.$v.input.state)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter company state",
      "state": _vm.getFieldState(_vm.$v.input.state)
    },
    model: {
      value: _vm.input.state,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "state", $$v);
      },
      expression: "input.state"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Zip",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.zip),
      "state": _vm.getFieldState(_vm.$v.input.zip)
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter company zip",
      "state": _vm.getFieldState(_vm.$v.input.zip)
    },
    model: {
      value: _vm.input.zip,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "zip", $$v);
      },
      expression: "input.zip"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Status",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.status),
      "state": _vm.getFieldState(_vm.$v.input.status)
    }
  }, [_vm.statusIsEditable ? _c('b-form-select', {
    attrs: {
      "options": [{
        value: 1,
        text: 'Active'
      }, {
        value: 0,
        text: 'Inactive'
      }],
      "state": _vm.getFieldState(_vm.$v.input.status)
    },
    model: {
      value: _vm.input.status,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "status", $$v);
      },
      expression: "input.status"
    }
  }) : _c('b-form-select', {
    attrs: {
      "value": _vm.newItem.status,
      "disabled": "",
      "options": [{
        value: 1,
        text: 'Active'
      }, {
        value: 0,
        text: 'Inactive'
      }]
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Stripe secret key",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10"
    }
  }, [_vm.newItem.has_stripe_secret_key ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_vm._v(" Company has stripe secret key. ")]) : _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Company has no stripe secret key. ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Stripe signing secret",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10"
    }
  }, [_vm.newItem.has_stripe_signing_secret ? _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "success"
    }
  }, [_vm._v(" Company has stripe signing secret. ")]) : _c('b-alert', {
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" Company has no stripe signing secret. ")])], 1), _c('b-form-group', {
    attrs: {
      "label": "Owners",
      "label-cols-sm": "3",
      "label-cols-lg": "2",
      "content-cols-sm": "9",
      "content-cols-lg": "10",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.owner_ids),
      "state": _vm.getFieldState(_vm.$v.input.owner_ids)
    }
  }, [_vm.ownersIsEditable ? _c('UserSelect', {
    attrs: {
      "list-endpoint": "admin/users",
      "items": _vm.newItem.owners,
      "show-create": _vm.isCreated,
      "item-to-create": _vm.isCreated ? {
        company_id: _vm.newItem.id,
        role_ids: [3]
      } : null,
      "company-to-create": _vm.isCreated ? _vm.newItem : null,
      "multiple": "",
      "placeholder": "Select an owner..."
    },
    model: {
      value: _vm.input.owner_ids,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "owner_ids", $$v);
      },
      expression: "input.owner_ids"
    }
  }) : _c('UserSelect', {
    attrs: {
      "list-endpoint": "admin/users",
      "value": _vm.newItem.owners ? _vm.newItem.owners.map(function (i) {
        return i.id;
      }) : null,
      "items": _vm.newItem.owners,
      "disabled": "",
      "multiple": ""
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right my-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }