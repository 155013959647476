var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Session Mins"
    }
  }, [_c('b-card', {
    staticClass: "bg-white",
    attrs: {
      "no-body": ""
    }
  }, [_c('b-input', {
    attrs: {
      "type": "number",
      "number": ""
    },
    model: {
      value: _vm.input.session_mins,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "session_mins", $$v);
      },
      expression: "input.session_mins"
    }
  })], 1)], 1), _c('b-form-group', {
    staticClass: "mt-2 mb-0"
  }, [_c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    staticClass: "text-right"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }