<script>
/**
 * Toggle component
 */
export default {
  props: {
    status: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.currentStatus = this.status;
  },
  data() {
    return {
      currentStatus: false,
    };
  },
  methods: {
    toggle() {
      this.currentStatus = !this.currentStatus;
      this.$emit('change');
    },
  },
};
</script>

<template>
  <div class="star-toggle toggle text-warning">
    <a @click="toggle">
      <i
        class="typcn"
        :class="
          currentStatus ? 'typcn-star-full-outline' : 'typcn-star-outline'
        "
      ></i>
    </a>
  </div>
</template>

<style scoped>
.toggle.star-toggle a {
  font-size: 24px;
  line-height: 20px;
  cursor: pointer;
}
</style>
