var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table-simple', {
    staticClass: "font-size-12",
    attrs: {
      "fixed": "",
      "striped": ""
    }
  }, [_c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-td', {
    staticClass: "w-70px"
  }), _c('b-td', {
    staticClass: "p-0"
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "p-2",
    style: {
      width: 'calc((100% - 130px) / 2)',
      maxWidth: 'calc((100% - 130px) / 2)'
    }
  }, [_vm._v(" Muscle ")]), _c('b-col', {
    staticClass: "p-2",
    style: {
      width: 'calc((100% - 130px) / 2)',
      maxWidth: 'calc((100% - 130px) / 2)'
    }
  }, [_vm._v(" Exercise ")]), _c('b-col', {
    staticClass: "p-2",
    style: {
      width: '100px',
      maxWidth: '100px'
    }
  }, [_vm._v(" Time per Set ")]), _c('b-col', {
    style: {
      width: '30px',
      maxWidth: '30px'
    }
  })], 1)], 1), _c('b-td', {
    staticClass: "w-30px"
  })], 1)], 1), _c('Draggable', {
    attrs: {
      "tag": "b-tbody",
      "group": "exerciseGroups"
    },
    on: {
      "update": function update($event) {
        return _vm.bulkExerciseGroupUpdate(_vm.input);
      }
    },
    model: {
      value: _vm.input,
      callback: function callback($$v) {
        _vm.input = $$v;
      },
      expression: "input"
    }
  }, _vm._l(_vm.input, function (exerciseGroup) {
    return _c('b-tr', {
      key: exerciseGroup.id,
      style: {
        cursor: 'pointer'
      }
    }, [_c('b-td', {
      staticClass: "text-center align-middle py-0",
      attrs: {
        "variant": "success"
      }
    }, [_c('ExerciseGroupItemUpsertButton', {
      staticClass: "p-0 font-weight-bold font-size-12 w-100 text-truncate",
      attrs: {
        "item": exerciseGroup,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": function submitted($event) {
          return _vm.refresh();
        }
      }
    }, [_vm._v(" " + _vm._s(exerciseGroup.name) + " ")])], 1), _c('b-td', {
      staticClass: "p-0"
    }, [_c('b-table-simple', {
      staticClass: "m-0",
      attrs: {
        "fixed": "",
        "striped": "",
        "stacked": "sm"
      }
    }, [exerciseGroup.exercise_group_items.length ? _c('Draggable', {
      staticClass: "exercise-group-items",
      attrs: {
        "tag": "b-tbody",
        "group": "exerciseGroupItems",
        "move": _vm.onExerciseGroupItemMove,
        "data-id": exerciseGroup.id
      },
      on: {
        "add": _vm.createExerciseGroupItem,
        "update": function update($event) {
          return _vm.bulkExerciseGroupItemUpdate(exerciseGroup.exercise_group_items);
        }
      },
      model: {
        value: exerciseGroup.exercise_group_items,
        callback: function callback($$v) {
          _vm.$set(exerciseGroup, "exercise_group_items", $$v);
        },
        expression: "exerciseGroup.exercise_group_items"
      }
    }, _vm._l(exerciseGroup.exercise_group_items, function (exerciseGroupItem) {
      return _c('b-tr', {
        key: exerciseGroupItem.id,
        style: {
          cursor: 'pointer'
        }
      }, [_c('b-td', {
        staticClass: "align-middle p-2",
        style: {
          width: 'calc((100% - 130px) / 2)'
        }
      }, [_c('Muscles', {
        staticClass: "text-truncate d-block",
        attrs: {
          "ids": exerciseGroupItem.exercise.muscle_ids || []
        }
      })], 1), _c('b-td', {
        staticClass: "align-middle p-2",
        style: {
          width: 'calc((100% - 130px) / 2)'
        }
      }, [_c('div', {
        staticClass: "text-truncate"
      }, [_vm._v(" " + _vm._s(exerciseGroupItem.exercise.name) + " ")])]), _c('b-td', {
        staticClass: "align-middle w-100px p-2"
      }, [_vm._v(" " + _vm._s(exerciseGroupItem.exercise.estimated_set_time) + " secs ")]), _c('b-td', {
        staticClass: "align-middle text-center p-0 w-30px"
      }, [_c('ExerciseGroupItemItemDeleteButton', {
        staticClass: "p-0",
        attrs: {
          "item": exerciseGroupItem,
          "tag": "b-button",
          "tag-props": {
            variant: 'link',
            size: 'sm'
          }
        },
        on: {
          "deleted:item": function deletedItem($event) {
            return _vm.refresh();
          }
        }
      }, [_c('b-icon-trash', {
        attrs: {
          "scale": "0.8"
        }
      })], 1)], 1)], 1);
    }), 1) : _c('Draggable', {
      staticClass: "exercise-group-items",
      attrs: {
        "tag": "b-tbody",
        "group": "exerciseGroupItems",
        "data-id": exerciseGroup.id
      },
      on: {
        "add": _vm.createExerciseGroupItem
      }
    }, [_c('b-tr', [_c('b-td', {
      staticClass: "align-middle text-center",
      attrs: {
        "colspan": "4"
      }
    }, [_vm._v(" Move exercise here... ")])], 1)], 1)], 1)], 1), _c('b-td', {
      staticClass: "text-center align-middle p-0 w-30-px",
      attrs: {
        "variant": "success"
      }
    }, [_c('ExerciseGroupItemDeleteButton', {
      staticClass: "p-0",
      attrs: {
        "item": exerciseGroup,
        "tag": "b-button",
        "tag-props": {
          "class": 'p-0',
          variant: 'link',
          size: 'sm'
        }
      },
      on: {
        "deleted:item": function deletedItem($event) {
          return _vm.refresh();
        }
      }
    }, [_c('b-icon-trash', {
      attrs: {
        "scale": "0.8"
      }
    })], 1)], 1)], 1);
  }), 1), _c('b-tbody', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-td'), _c('b-td', {
    staticClass: "p-0"
  }, [_c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "p-2",
    style: {
      width: 'calc((100% - 130px) / 2)',
      maxWidth: 'calc((100% - 130px) / 2)'
    }
  }, [_vm._v(" Total ")]), _c('b-col', {
    staticClass: "p-2",
    style: {
      width: 'calc((100% - 130px) / 2)',
      maxWidth: 'calc((100% - 130px) / 2)'
    }
  }, [_vm._v(" " + _vm._s(_vm.totalExerciseCount) + " exercises ")]), _c('b-col', {
    staticClass: "p-2",
    style: {
      width: '100px',
      maxWidth: '100px'
    }
  }, [_vm._v(" " + _vm._s(_vm.totalEstimatedSetTime) + " secs ")]), _c('b-col', {
    style: {
      width: '30px',
      maxWidth: '30px'
    }
  })], 1)], 1), _c('b-td')], 1)], 1), _c('b-tbody', [_c('b-tr', [_c('b-td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "3"
    }
  }, [_c('ExerciseGroupItemUpsertButton', {
    staticClass: "p-0",
    attrs: {
      "item": {
        workout_id: _vm.newParams.filters.workout_id
      },
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.refresh();
      }
    }
  }, [_c('b-icon-plus-circle', {
    staticClass: "mr-1",
    attrs: {
      "scale": "0.8"
    }
  }), _vm._v(" Add set ")], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }