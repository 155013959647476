<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- NAME -->
      <b-form-group
        label="Name"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
        :state="getFieldState($v.input.name)"
      >
        <template v-if="$v.input.name?.$params?.required" #label>
          Name <span class="text-danger">*</span>
        </template>

        <b-form-input
          v-model="input.name"
          :state="getFieldState($v.input.name)"
          placeholder="Enter Food Name"
        />
      </b-form-group>

      <!-- CALORIES -->
      <b-form-group
        label="Calories"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.calories)"
        :state="getFieldState($v.input.calories)"
      >
        <template v-if="$v.input.calories?.$params?.required" #label>
          Calories <span class="text-danger">*</span>
        </template>

        <PerHundredGramsInput
          v-model="input.calories"
          :input-props="{
            state: getFieldState($v.input.calories),
            placeholder: 'Enter Calories'
          }"
          unit="ounces"
          :decimal-places="0"
          unit-disabled
        />
      </b-form-group>

      <!-- CALORIES -->
      <b-form-group
        label="Protein (gm)"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.protein)"
        :state="getFieldState($v.input.protein)"
      >
        <template v-if="$v.input.protein?.$params?.required" #label>
          Protein <span class="text-danger">*</span>
        </template>

        <PerHundredGramsInput
          v-model="input.protein"
          :input-props="{
            state: getFieldState($v.input.protein),
            placeholder: 'Enter Grams'
          }"
          :decimal-places="1"
          unit="ounces"
          unit-disabled
        />
      </b-form-group>

      <!-- CARBS -->
      <b-form-group
        label="Carbs (gm)"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.carbs)"
        :state="getFieldState($v.input.carbs)"
      >
        <template v-if="$v.input.carbs?.$params?.required" #label>
          Carbs <span class="text-danger">*</span>
        </template>

        <PerHundredGramsInput
          v-model="input.carbs"
          :input-props="{
            state: getFieldState($v.input.carbs),
            placeholder: 'Enter Grams'
          }"
          unit="ounces"
          :decimal-places="1"
          unit-disabled
        />
      </b-form-group>

      <!-- CARBS -->
      <b-form-group
        label="Fat (gm)"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.fat)"
        :state="getFieldState($v.input.fat)"
      >
        <template v-if="$v.input.fat?.$params?.required" #label>
          Fat <span class="text-danger">*</span>
        </template>

        <PerHundredGramsInput
          v-model="input.fat"
          :input-props="{
            state: getFieldState($v.input.fat),
            placeholder: 'Enter Grams'
          }"
          :decimal-places="1"
          unit="ounces"
          unit-disabled
        />
      </b-form-group>

      <!-- SODIUM -->
      <b-form-group
        label="Sodium (mg)"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.sodium)"
        :state="getFieldState($v.input.sodium)"
      >
        <template v-if="$v.input.sodium?.$params?.required" #label>
          Sodium <span class="text-danger">*</span>
        </template>

        <PerHundredGramsInput
          v-model="input.sodium"
          :input-props="{
            state: getFieldState($v.input.sodium),
            placeholder: 'Enter Milligrams'
          }"
          :decimal-places="1"
          unit="ounces"
          unit-disabled
        />
      </b-form-group>

      <!-- SERVING UNITS -->
      <b-form-group
        label="Serving units"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.serving_units)"
        :state="getFieldState($v.input.serving_units)"
      >
        <template v-if="$v.input.serving_units?.$params?.required" #label>
          Serving units <span class="text-danger">*</span>
        </template>

        <b-form-select
          v-model="input.serving_units"
          :options="servingUnitsOptions"
          :state="getFieldState($v.input.serving_units)"
          :disabled="servingUnitsIsDisabled"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              Select units 
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <!-- SERVING SIZE -->
      <b-form-group
        v-if="servingSizeIsEditable"
        :label="input.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit'"
        label-cols-sm="4"
        :invalid-feedback="getFieldInvalidFeedback($v.input.serving_size)"
        :state="getFieldState($v.input.serving_size)"
      >
        <template v-if="$v.input.serving_size?.$params?.required" #label>
          {{ input.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit' }} <span class="text-danger">*</span>
        </template>

        <GramsInput
          v-model="input.serving_size"
          :input-props="{
            state: getFieldState($v.input.serving_size),
            placeholder: input.serving_units === 'cups' ? 'Enter ounces per 1 Cup' : 'Enter ounces per 1 Unit'
          }"
          :decimal-places="1"
          unit="ounces"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4 mb-0">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />{{ actionName }}
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import PerHundredGramsInput from '@/components/inputs/per-hundred-grams-input'
import GramsInput from '@/components/inputs/grams-input'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"
import { mapGetters } from 'vuex'

export default {
  name: 'FoodItemUpsert',

  mixins: [
    crudItemMixin
  ],

  components: {
    PerHundredGramsInput,
    GramsInput
  },

  data() {
    return {
      baseEndpoint: 'foods'
    }
  },

  computed: {
    ...mapGetters({
      adminView: 'auth/adminView'
    }),

    actionName() {
      return this.isCreated ? 'Update' : this.source ? 'Copy' : 'Create'
    },

    servingUnitsOptions() {
      const servingUnitsOptions = this.$store.getters['asset/getFoodServingUnitsOptions']

      if (!this.isCreated) {
        return servingUnitsOptions.filter(i => ['ounces', 'cups'].includes(i.value))
      }

      return servingUnitsOptions
    },

    servingSizeIsEditable() {
      return ['cups', 'scoops', 'tablets'].includes(this.input?.serving_units)
    },

    servingUnitsIsDisabled() {
      return this.isCreated
    }
  },

  watch: {
    'input.serving_units': {
      immediate: true,

      handler(value) {
        if (value === 'gramm') {
          this.input.serving_size = 1

        } else if (value === 'ounces') {
          this.input.serving_size = 28.3495

        } else {
          this.input.serving_size = null
        }
      }
    }
  },

  validations() {
    const input = {
      name: { required }
    }

    if (
      this.servingSizeIsEditable && (
        this.input.calories ||
        this.input.protein ||
        this.input.carbs ||
        this.input.fat ||
        this.input.sodium
      )
    ) {
      input.serving_size = { required }
    }

    if (this.adminView) {
      input.calories = { required }
      input.protein = { required }
      input.carbs = { required }
      input.fat = { required }
      input.serving_units = { required }
      input.sodium = { required }
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        name: _.get(this.newItem, 'name', null),
        calories: _.get(this.newItem, 'calories', null),
        protein: _.get(this.newItem, 'protein', null),
        carbs: _.get(this.newItem, 'carbs', null),
        fat: _.get(this.newItem, 'fat', null),
        sodium: _.get(this.newItem, 'sodium', null),
        serving_units: _.get(this.newItem, 'serving_units', 'ounces'),
        serving_size: _.get(this.newItem, 'serving_size', null)
      }

      return input
    }
  }
}
</script>