var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "fixed": "",
      "striped": ""
    }
  }, [_c('b-thead', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.showHeader,
      expression: "showHeader"
    }]
  }, [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "w-130px bg-transparent"
  }), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-th', {
      key: phase.number,
      staticClass: "text-center",
      attrs: {
        "colspan": "2"
      }
    }, [_vm._v(" Phase " + _vm._s(phase.number) + " ")]);
  })], 2)], 1), _c('b-tbody', [_c('b-tr', [_c('b-th', {
    staticClass: "w-130px",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "py-1 text-nowrap text-center"
  }, [_vm._v(" Calories ")])]), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-td', {
      key: phase.number,
      staticClass: "text-center align-middle bg-white w-100",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("...")] : _vm.suggestedValue[phase.number - 1].nutrients.calories.total ? [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].nutrients.calories.total) + " per day ")] : [_vm._v("-")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [_vm.phasesMacros[phase.number - 1].calories.total ? [_vm._v(" " + _vm._s(_vm.phasesMacros[phase.number - 1].calories.total) + " per day ")] : [_vm._v("-")]], 2) : _vm._e()], 1)], 1);
  })], 2), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle font-weight-bold text-center py-1",
    attrs: {
      "colspan": "9",
      "variant": "info"
    }
  }, [_vm._v(" Protein ")])], 1), _c('b-tr', [_c('b-td', {
    staticClass: "w-130px",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "py-1 text-nowrap text-center"
  }, [_vm._v(" Calories ")])]), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-td', {
      key: phase.number,
      staticClass: "text-center align-middle bg-white w-100",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("...")] : _vm.suggestedValue[phase.number - 1].nutrients.calories.protein ? [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].nutrients.calories.protein) + " per day ")] : [_vm._v("-")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [_vm.phasesMacros[phase.number - 1].calories.protein ? [_vm._v(" " + _vm._s(_vm.phasesMacros[phase.number - 1].calories.protein) + " per day ")] : [_vm._v("-")]], 2) : _vm._e()], 1)], 1);
  })], 2), _c('b-tr', [_c('b-td', {
    staticClass: "w-130px",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "py-1 text-nowrap text-center"
  }, [_vm._v(" Grams ")])]), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-td', {
      key: phase.number,
      staticClass: "text-center align-middle bg-white w-100",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("...")] : _vm.suggestedValue[phase.number - 1].nutrients.grams.protein ? [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].nutrients.grams.protein) + " per day ")] : [_vm._v("-")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [_vm.phasesMacros[phase.number - 1].grams.protein ? [_vm._v(" " + _vm._s(_vm.phasesMacros[phase.number - 1].grams.protein) + " per day ")] : [_vm._v("-")]], 2) : _vm._e()], 1)], 1);
  })], 2), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle font-weight-bold text-center py-1",
    attrs: {
      "colspan": "9",
      "variant": "info"
    }
  }, [_vm._v(" Carbs ")])], 1), _c('b-tr', [_c('b-td', {
    staticClass: "w-130px",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "py-1 text-nowrap text-center"
  }, [_vm._v(" Calories ")])]), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-td', {
      key: phase.number,
      staticClass: "text-center align-middle bg-white w-100",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("...")] : _vm.suggestedValue[phase.number - 1].nutrients.calories.carbs ? [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].nutrients.calories.carbs) + " per day ")] : [_vm._v("-")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [_vm.phasesMacros[phase.number - 1].calories.carbs ? [_vm._v(" " + _vm._s(_vm.phasesMacros[phase.number - 1].calories.carbs) + " per day ")] : [_vm._v("-")]], 2) : _vm._e()], 1)], 1);
  })], 2), _c('b-tr', [_c('b-td', {
    staticClass: "w-130px",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "py-1 text-nowrap text-center"
  }, [_vm._v(" Grams ")])]), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-td', {
      key: phase.number,
      staticClass: "text-center align-middle bg-white w-100",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("...")] : _vm.suggestedValue[phase.number - 1].nutrients.grams.carbs ? [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].nutrients.grams.carbs) + " per day ")] : [_vm._v("-")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [_vm.phasesMacros[phase.number - 1].grams.carbs ? [_vm._v(" " + _vm._s(_vm.phasesMacros[phase.number - 1].grams.carbs) + " per day ")] : [_vm._v("-")]], 2) : _vm._e()], 1)], 1);
  })], 2), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle font-weight-bold text-center py-1",
    attrs: {
      "colspan": "9",
      "variant": "info"
    }
  }, [_vm._v(" Fat ")])], 1), _c('b-tr', [_c('b-td', {
    staticClass: "w-130px",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "py-1 text-nowrap text-center"
  }, [_vm._v(" Calories ")])]), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-td', {
      key: phase.number,
      staticClass: "text-center align-middle bg-white w-100",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("...")] : _vm.suggestedValue[phase.number - 1].nutrients.calories.fat ? [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].nutrients.calories.fat) + " per day ")] : [_vm._v("-")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [_vm.phasesMacros[phase.number - 1].calories.fat ? [_vm._v(" " + _vm._s(_vm.phasesMacros[phase.number - 1].calories.fat) + " per day ")] : [_vm._v("-")]], 2) : _vm._e()], 1)], 1);
  })], 2), _c('b-tr', [_c('b-td', {
    staticClass: "w-130px",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', {
    staticClass: "py-1 text-nowrap text-center"
  }, [_vm._v(" Grams ")])]), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-td', {
      key: phase.number,
      staticClass: "text-center align-middle bg-white w-100",
      attrs: {
        "colspan": "2"
      }
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("...")] : _vm.suggestedValue[phase.number - 1].nutrients.grams.fat ? [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].nutrients.grams.fat) + " per day ")] : [_vm._v("-")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [_vm.phasesMacros[phase.number - 1].grams.fat ? [_vm._v(" " + _vm._s(_vm.phasesMacros[phase.number - 1].grams.fat) + " per day ")] : [_vm._v("-")]], 2) : _vm._e()], 1)], 1);
  })], 2)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }