<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="popupIsShown = true"
  >
    <slot />

    <b-modal
      v-model="popupIsShown"
      content-class="rounded-0 border-0"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-4"
      body-class="p-0"
      hide-footer
    >
      <template #modal-title>
        {{ title }}
      </template>

      <b-card class="m-0">
        <CurriculumProductItemUpsert
          :item="item"
          :prefetch="prefetch"
          @update:item="$emit('update:item', $event)"
          @submitted="$emit('submitted', $event); popupIsShown = false"
          @cancel="$emit('cancel'); popupIsShown = false"
        />
      </b-card>
    </b-modal>
  </component>
</template>

<script>
import CurriculumProductItemUpsert from '../CurriculumProductItemUpsert'

export default {
  name: 'CurriculumProductItemUpsertButton',

  components: {
    CurriculumProductItemUpsert
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    prefetch: {
      type: Boolean,
      default: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      popupIsShown: false
    }
  },

  computed: {
    title() {
      const title = this.item.id ? 'Edit Curriculum Product' : 'Create Curriculum Product'
      return title
    }
  }
}
</script>

