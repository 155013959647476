var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _vm.$acl.check('couldAddGym') ? _c('b-col', {
    staticClass: "d-flex align-items-center justify-content-end",
    attrs: {
      "cols": "4"
    }
  }, [_c('b-button', {
    attrs: {
      "to": "/gym/add",
      "variant": "primary"
    }
  }, [_vm._v(" Add Gym ")])], 1) : _vm._e()], 1), _c('b-card', [_c('GymList', {
    ref: "list",
    attrs: {
      "params": {
        filters: {
          company_ids: _vm.companies
        },
        order_desc: true
      }
    },
    on: {
      "update:params": function updateParams($event) {
        _vm.companies = $event.filters.company_ids;
      }
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }