var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form-group', {
    staticClass: "mb-4",
    attrs: {
      "label": "Cardio",
      "label-class": "font-size-20 text-primary font-weight-bold"
    }
  }, [_c('CurriculumPhasesCardioInput', {
    staticClass: "mb-2",
    attrs: {
      "mode": "values",
      "disabled": "",
      "curriculum": _vm.newItem
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }