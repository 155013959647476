import axios from '@/plugins/axios-instance'
import store from '@/state/store'
import _ from 'lodash';

export const state = {
    userCompanies: sessionStorage.getItem('userCompanies'),
    currentCompany: sessionStorage.getItem('currentCompany'),
}

export const getters = {
    getUserCompanies(state) {
        return state.userCompanies
    },
    getCurrentCompany(state) {
        return state.currentCompany
    }
}

export const mutations = {
    SET_CURRENT_COMPANY(state, value) {
        state.currentCompany = value;
        // saveState('company.currentCompany', value)
    },
    SET_USER_COMPANIES(state, value) {
        state.userCompanies.push(value)
        // saveState('company.userCompanies', state.userCompanies)
    },
    CLEAR_USER_COMPANIES(state,) {
        state.userCompanies = []
        // saveState('company.userCompanies', [])
    },
}

export const actions = {
    // eslint-disable-next-line no-unused-vars
    setCurrentCompany({ commit, state, rootState }, { company }) {
        commit('SET_CURRENT_COMPANY', company);
    },
    // eslint-disable-next-line no-unused-vars
    setUserCompanies({ commit, state, rootState }, { companies }) {
        commit('SET_USER_COMPANIES', companies);
    },
    clearData({ commit }) {
        commit('SET_CURRENT_COMPANY', false);
        commit('CLEAR_USER_COMPANIES');
    },
    // eslint-disable-next-line no-unused-vars
    async fetchCompanyData({ commit, state, dispatch }, { roles }) {
        if (!roles) {
            return;
        }

        commit('CLEAR_USER_COMPANIES');

        const companyIds = _.uniq(roles.map((r) => r.company_id));
        for (const companyId of companyIds) {
            await axios.get(`company/${companyId}`)
                .then(async (response) => {
                    await commit('SET_USER_COMPANIES', response.data);

                    if (state.userCompanies.length === 1) {
                        await commit('SET_CURRENT_COMPANY', response.data);
                    }
                }).catch((error) => {
                    window.console.log(error);

                    store.commit("layout/addAlerts", {
                        type: "warning",
                        text: 'Could not load company data',
                    });
                });
        }
    }
}

// ===
// Private helpers
// ===
/*
function saveState(key, state) {
    window.localStorage.setItem(key, JSON.stringify(state))
}
*/
