var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.value ? _c('b-input-group', {
    staticClass: "pb-0",
    scopedSlots: _vm._u([!_vm.disabled ? {
      key: "append",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "size": _vm.size
          },
          on: {
            "click": function click($event) {
              return _vm.$emit('input', null);
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "x"
          }
        })], 1)];
      },
      proxy: true
    } : null], null, true)
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.serializer(_vm.value),
      "readonly": "",
      "state": _vm.state,
      "size": _vm.size
    }
  })], 1) : _vm.disabled ? _c('b-form-input', {
    attrs: {
      "readonly": "",
      "placeholder": _vm.placeholder,
      "state": _vm.state,
      "size": _vm.size
    }
  }) : _c('vue-bootstrap-typeahead', {
    ref: "typeahead",
    attrs: {
      "placeholder": _vm.placeholder,
      "data": _vm.newResult.data || [],
      "serializer": _vm.serializer,
      "min-matching-chars": 0,
      "do-not-filter": "",
      "state": _vm.state,
      "size": _vm.size
    },
    on: {
      "hit": _vm.onInput,
      "focus": function focus($event) {
        return _vm.debounceFetchList();
      },
      "blur": _vm.onBlur
    },
    scopedSlots: _vm._u([{
      key: "append",
      fn: function fn() {
        return [_vm._t("append")];
      },
      proxy: true
    }], null, true),
    model: {
      value: _vm.query,
      callback: function callback($$v) {
        _vm.query = $$v;
      },
      expression: "query"
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }