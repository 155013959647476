<template>
  <b-overlay
    :show="isFetching"
  >
    <TrackerCardioItemUpsert
      v-if="item"
      class="p-3"
      :item="item"
      @submitted="$emit('submitted', $event); refresh()"
    />

    <b-alert
      v-else-if="isFetching"
      show
      class="m-0 text-center"
      variant="light"
    >
      Loading...
    </b-alert>

    <b-alert
      v-else
      show
      class="m-0 text-center"
      variant="success"
    >
      You have no cardio to work.
    </b-alert>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudListMixin from '@/mixins/crud-list'
import TrackerCardioItemUpsert from '@/components/trackers/cardios/TrackerCardioItemUpsert'

export default {
  name: 'CurriculumCardioTracker',

  mixins: [
    crudListMixin
  ],

  components: {
    TrackerCardioItemUpsert
  },

  props: {
    params: {
      type: Object,
      default: () => ({
        filters: {
          is_performed: false
        },

        order_by: 'start_time',
        order_desc: false
      })
    }
  },

  data() {
    return {
      baseEndpoint: 'tracker-cardios'
    }
  },

  computed: {
    item() {
      return _.get(this.newResult.data, 0)
    }
  }
}
</script>