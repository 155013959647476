var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    staticClass: "schedule-bg position-absolute w-100"
  }, _vm._l(_vm.days, function (day, dayIndex) {
    return _c('b-col', {
      key: day,
      "class": _vm.dayStatuses[dayIndex]
    }, [_vm.showDays ? _c('div', {
      staticClass: "schedule-bg-day text-center font-size-16"
    }, [[_c('div', {
      staticClass: "label-day"
    }, [_vm._v(" " + _vm._s(_vm.dates[dayIndex] ? _vm.$moment(_vm.dates[dayIndex]).format('ddd Do MMM') : day) + " ")]), _vm.datesWeekNumbers[dayIndex] ? _c('div', {
      staticClass: "label-week"
    }, [_vm.phaseWeeks[_vm.datesWeekNumbers[dayIndex] - 1][0] === _vm.dates[dayIndex] ? _c('div', [_vm._v(" Week " + _vm._s(_vm.datesWeekNumbers[dayIndex]) + " ")]) : _vm._e()]) : _vm._e()]], 2) : _vm._e(), _c('div', {
      staticClass: "position-relative"
    }, [_c('div', {
      staticClass: "day-background"
    }), _vm._l(_vm.hours, function (hour, hourIndex) {
      return _c('div', {
        key: hourIndex,
        staticClass: "schedule-bg-hour",
        style: {
          height: hourIndex < _vm.hours.length - 1 ? '40px' : '0'
        },
        on: {
          "click": function click($event) {
            return _vm.$emit('hour-click', {
              start: hourIndex + dayIndex * 24
            });
          }
        }
      }, [_c('div', {
        staticClass: "schedule-bg-hour-title text-right"
      }, [_vm._v(" " + _vm._s(hour) + " ")]), hourIndex < _vm.hours.length - 1 ? [_c('div', {
        staticClass: "tick-15"
      }), _c('div', {
        staticClass: "tick-30"
      }), _c('div', {
        staticClass: "tick-45"
      })] : _vm._e()], 2);
    }), _vm._l(_vm.eventsByDay[dayIndex], function (event, eventIndex) {
      return _c('div', {
        key: "".concat(dayIndex, "-").concat(eventIndex),
        staticClass: "schedule-bg-event",
        style: {
          top: "".concat((event.start - 24 * dayIndex) * 40, "px"),
          height: "".concat(event.duration * 40, "px"),
          cursor: 'default'
        },
        on: {
          "click": function click($event) {
            return _vm.$emit('bg-event-click', event);
          }
        }
      }, [_c('ScheduleEvent', {
        style: {
          padding: 0
        },
        attrs: {
          "event": event,
          "removable": false,
          "selectable": false
        }
      })], 1);
    })], 2)]);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }