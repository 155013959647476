var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    style: {
      overflow: 'scroll'
    },
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.newResult.data && _vm.newResult.data.length ? _c('b-table-simple', {
    staticClass: "mb-2 text-nowrap",
    style: {
      minWidth: '900px'
    },
    attrs: {
      "fixed": "",
      "striped": "",
      "stacked": "sm"
    }
  }, [_c('b-thead', [_c('b-tr', {
    staticClass: "text-center font-size-12"
  }, [_c('b-th', {
    staticClass: "w-70px align-middle",
    attrs: {
      "variant": "primary"
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e()], 1), _vm._l(_vm.newResult.data, function (item) {
    return _c('b-th', {
      key: item.id,
      staticClass: "px-1",
      attrs: {
        "variant": "primary"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(item.name) + " ")]), _vm.showSuggestions && _vm.showValues ? _c('b-row', {
      staticClass: "mt-2 text-nowrap",
      attrs: {
        "no-gutters": ""
      }
    }, [_c('b-col', {
      staticClass: "text-truncate"
    }, [_vm._v("Rec")]), _c('b-col', {
      staticClass: "text-truncate"
    }, [_vm._v("Actual")])], 1) : _vm._e()], 1);
  }), _c('b-th', {
    staticClass: "w-150px",
    attrs: {
      "variant": "success"
    }
  }, [_c('div', [_vm._v("Totals")]), _vm.showSuggestions && _vm.showValues ? _c('b-row', {
    staticClass: "mt-2 text-nowrap",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "text-truncate"
  }, [_vm._v("Rec")]), _c('b-col', {
    staticClass: "text-truncate"
  }, [_vm._v("Actual")])], 1) : _vm._e()], 1)], 2), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle font-weight-bold text-center py-1",
    attrs: {
      "colspan": (_vm.newResult.data || []).length + 2,
      "variant": "info"
    }
  }, [_vm._v(" Protein ")])], 1), _c('b-tr', {
    staticClass: "text-center font-size-12"
  }, [_c('b-td', {
    staticClass: "align-middle font-weight-bold"
  }, [_vm._v(" Grams ")]), _vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-td', {
      key: item.id,
      staticClass: "px-1 align-middle"
    }, [_c('b-row', {
      staticClass: "text-nowrap",
      attrs: {
        "no-gutters": "",
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.suggestedMeals[itemIndex] ? _vm.suggestedMeals[itemIndex].grams.protein : '-'))])]) : _vm._e(), _vm.showValues ? _c('b-col', [!_vm.disabled ? _c('b-form-input', {
      staticClass: "hide-arrows text-center px-0",
      attrs: {
        "type": "number",
        "size": "sm",
        "step": "0.1",
        "number": "",
        "disabled": _vm.disabled
      },
      on: {
        "update": function update($event) {
          _vm.input.items[itemIndex].protein = $event !== '' ? $event : null;
        }
      },
      model: {
        value: _vm.input.items[itemIndex].protein,
        callback: function callback($$v) {
          _vm.$set(_vm.input.items[itemIndex], "protein", $$v);
        },
        expression: "input.items[itemIndex].protein"
      }
    }) : _c('div', {
      staticClass: "py-2"
    }, [_vm._v(" " + _vm._s(item.protein || '-') + " ")])], 1) : _vm._e()], 1)], 1);
  }), _c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "variant": "success"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_vm.showSuggestions ? _c('b-col', [_vm._v(" " + _vm._s(_vm.suggestedMacros ? _vm.suggestedMacros.grams.protein : '-') + " ")]) : _vm._e(), _vm.showValues ? _c('b-col', [_vm._v(" " + _vm._s(_vm.input ? _vm.calcMealsInputMacro('protein') : '-') + " ")]) : _vm._e()], 1)], 1)], 2), _c('b-tr', {
    staticClass: "text-center font-size-12"
  }, [_c('b-td', {
    staticClass: "align-middle font-weight-bold",
    attrs: {
      "variant": "secondary"
    }
  }, [_vm._v(" Calories ")]), _vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-td', {
      key: item.id,
      staticClass: "px-1",
      attrs: {
        "variant": "secondary"
      }
    }, [_c('b-row', {
      staticClass: "text-nowrap",
      attrs: {
        "no-gutters": ""
      }
    }, [_vm.showSuggestions ? _c('b-col', [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.suggestedMeals[itemIndex] ? _vm.suggestedMeals[itemIndex].calories.protein : '-'))])]) : _vm._e(), _vm.showValues ? _c('b-col', {
      staticClass: "font-weight-bolder"
    }, [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.input.items[itemIndex] ? _vm.getCaloriesByGrams(_vm.input.items[itemIndex].protein, 'protein') : '-'))])]) : _vm._e()], 1)], 1);
  }), _c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "variant": "success"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_vm.showSuggestions ? _c('b-col', [_vm._v(" " + _vm._s(_vm.suggestedMacros ? _vm.suggestedMacros.calories.protein : '-') + " ")]) : _vm._e(), _vm.showValues ? _c('b-col', [_vm._v(" " + _vm._s(_vm.input ? _vm.getCaloriesByGrams(_vm.calcMealsInputMacro('protein'), 'protein') : '-') + " ")]) : _vm._e()], 1)], 1)], 2), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle font-weight-bold text-center py-1",
    attrs: {
      "colspan": (_vm.newResult.data || []).length + 2,
      "variant": "info"
    }
  }, [_vm._v(" Carbs ")])], 1), _c('b-tr', {
    staticClass: "text-center font-size-12"
  }, [_c('b-td', {
    staticClass: "align-middle font-weight-bold"
  }, [_vm._v(" Grams ")]), _vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-td', {
      key: item.id,
      staticClass: "align-middle px-1"
    }, [_c('b-row', {
      staticClass: "text-nowrap",
      attrs: {
        "no-gutters": "",
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.suggestedMeals[itemIndex] ? _vm.suggestedMeals[itemIndex].grams.carbs : '-'))])]) : _vm._e(), _vm.showValues ? _c('b-col', [!_vm.disabled ? _c('b-form-input', {
      staticClass: "hide-arrows text-center px-0",
      attrs: {
        "type": "number",
        "size": "sm",
        "step": "0.1",
        "number": "",
        "disabled": _vm.disabled
      },
      on: {
        "update": function update($event) {
          _vm.input.items[itemIndex].carbs = $event !== '' ? $event : null;
        }
      },
      model: {
        value: _vm.input.items[itemIndex].carbs,
        callback: function callback($$v) {
          _vm.$set(_vm.input.items[itemIndex], "carbs", $$v);
        },
        expression: "input.items[itemIndex].carbs"
      }
    }) : _c('div', {
      staticClass: "py-2"
    }, [_vm._v(" " + _vm._s(item.carbs || '-') + " ")])], 1) : _vm._e()], 1)], 1);
  }), _c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "variant": "success"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_vm.showSuggestions ? _c('b-col', [_vm._v(" " + _vm._s(_vm.suggestedMacros ? _vm.suggestedMacros.grams.carbs : '-') + " ")]) : _vm._e(), _vm.showValues ? _c('b-col', [_vm._v(" " + _vm._s(_vm.input ? _vm.calcMealsInputMacro('carbs') : '-') + " ")]) : _vm._e()], 1)], 1)], 2), _c('b-tr', {
    staticClass: "text-center font-size-12"
  }, [_c('b-td', {
    staticClass: "align-middle font-weight-bold",
    attrs: {
      "variant": "secondary"
    }
  }, [_vm._v(" Calories ")]), _vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-td', {
      key: item.id,
      staticClass: "align-middle px-1",
      attrs: {
        "variant": "secondary"
      }
    }, [_c('b-row', {
      staticClass: "text-nowrap",
      attrs: {
        "no-gutters": ""
      }
    }, [_vm.showSuggestions ? _c('b-col', [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.suggestedMeals[itemIndex] ? _vm.suggestedMeals[itemIndex].calories.carbs : '-'))])]) : _vm._e(), _vm.showValues ? _c('b-col', {
      staticClass: "font-weight-bolder"
    }, [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.input.items[itemIndex] ? _vm.getCaloriesByGrams(_vm.input.items[itemIndex].carbs, 'carbs') : '-'))])]) : _vm._e()], 1)], 1);
  }), _c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "variant": "success"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_vm.showSuggestions ? _c('b-col', [_vm._v(" " + _vm._s(_vm.suggestedMacros ? _vm.suggestedMacros.calories.carbs : '-') + " ")]) : _vm._e(), _vm.showValues ? _c('b-col', [_vm._v(" " + _vm._s(_vm.input ? _vm.getCaloriesByGrams(_vm.calcMealsInputMacro('carbs'), 'carbs') : '-') + " ")]) : _vm._e()], 1)], 1)], 2), _c('b-tr', [_c('b-td', {
    staticClass: "align-middle font-weight-bold text-center py-1",
    attrs: {
      "colspan": (_vm.newResult.data || []).length + 2,
      "variant": "info"
    }
  }, [_vm._v(" Fats ")])], 1), _c('b-tr', {
    staticClass: "text-center font-size-12"
  }, [_c('b-td', {
    staticClass: "align-middle font-weight-bold"
  }, [_vm._v(" Grams ")]), _vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-td', {
      key: item.id,
      staticClass: "align-middle px-1"
    }, [_c('b-row', {
      staticClass: "text-nowrap",
      attrs: {
        "no-gutters": "",
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.suggestedMeals[itemIndex] ? _vm.suggestedMeals[itemIndex].grams.fat : '-'))])]) : _vm._e(), _vm.showValues ? _c('b-col', [!_vm.disabled ? _c('b-form-input', {
      staticClass: "hide-arrows text-center px-0",
      attrs: {
        "type": "number",
        "size": "sm",
        "step": "0.1",
        "number": "",
        "disabled": _vm.disabled
      },
      on: {
        "update": function update($event) {
          _vm.input.items[itemIndex].fat = $event !== '' ? $event : null;
        }
      },
      model: {
        value: _vm.input.items[itemIndex].fat,
        callback: function callback($$v) {
          _vm.$set(_vm.input.items[itemIndex], "fat", $$v);
        },
        expression: "input.items[itemIndex].fat"
      }
    }) : _c('div', {
      staticClass: "py-2"
    }, [_vm._v(" " + _vm._s(item.fat || '-') + " ")])], 1) : _vm._e()], 1)], 1);
  }), _c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "variant": "success"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_vm.showSuggestions ? _c('b-col', [_vm._v(" " + _vm._s(_vm.suggestedMacros ? _vm.suggestedMacros.grams.fat : '-') + " ")]) : _vm._e(), _vm.showValues ? _c('b-col', [_vm._v(" " + _vm._s(_vm.input ? _vm.calcMealsInputMacro('fat') : '-') + " ")]) : _vm._e()], 1)], 1)], 2), _c('b-tr', {
    staticClass: "text-center font-size-12"
  }, [_c('b-td', {
    staticClass: "align-middle font-weight-bold",
    attrs: {
      "variant": "secondary"
    }
  }, [_vm._v(" Calories ")]), _vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-td', {
      key: item.id,
      staticClass: "px-1",
      attrs: {
        "variant": "secondary"
      }
    }, [_c('b-row', {
      staticClass: "text-nowrap",
      attrs: {
        "no-gutters": "",
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.suggestedMeals[itemIndex] ? _vm.suggestedMeals[itemIndex].calories.fat : '-'))])]) : _vm._e(), _vm.showValues ? _c('b-col', {
      staticClass: "font-weight-bolder"
    }, [_c('div', {
      staticClass: "py-2"
    }, [_vm._v(_vm._s(_vm.input.items[itemIndex] ? _vm.getCaloriesByGrams(_vm.input.items[itemIndex].fat, 'fat') : '-'))])]) : _vm._e()], 1)], 1);
  }), _c('b-td', {
    staticClass: "align-middle",
    attrs: {
      "variant": "success"
    }
  }, [_c('b-row', {
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_vm.showSuggestions ? _c('b-col', [_vm._v(" " + _vm._s(_vm.suggestedMacros ? _vm.suggestedMacros.calories.fat : '-') + " ")]) : _vm._e(), _vm.showValues ? _c('b-col', [_vm._v(" " + _vm._s(_vm.input ? _vm.getCaloriesByGrams(_vm.calcMealsInputMacro('fat'), 'fat') : '-') + " ")]) : _vm._e()], 1)], 1)], 2), _c('b-tr', [_c('b-td', {
    attrs: {
      "colspan": (_vm.newResult.data || []).length + 1
    }
  }), _c('b-td', {
    attrs: {
      "variant": "success"
    }
  }, [_c('b-row', {
    staticClass: "font-weight-bolder text-center",
    attrs: {
      "align-v": "center",
      "no-gutters": ""
    }
  }, [_c('b-col', [_vm._v(" Cal/Day ")]), _c('b-col', [_vm._v(" " + _vm._s(_vm.calcMealsInputCalories()) + " ")])], 1)], 1)], 1)], 1)], 1) : _vm.isFetching ? _c('div', [_c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "secondary"
    }
  }, [_vm._v(" Loading... ")])], 1) : _c('div', [_c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" There are no meals. ")])], 1), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }