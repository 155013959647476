var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown', {
    attrs: {
      "text": _vm.title
    }
  }, [_c('b-dropdown-header', [_vm._v(" Your role is "), _c('Roles', {
    attrs: {
      "ids": [_vm.currentRole.role_id]
    }
  })], 1), _c('b-modal', {
    attrs: {
      "title": "Select role",
      "hide-footer": ""
    },
    model: {
      value: _vm.modalIsShown,
      callback: function callback($$v) {
        _vm.modalIsShown = $$v;
      },
      expression: "modalIsShown"
    }
  }, [_vm.selectedCompany ? _vm._l(_vm.selectedCompany.roleIds, function (roleId) {
    return _c('b-button', {
      key: roleId,
      attrs: {
        "disabled": _vm.currentRole.role_id === roleId && _vm.currentRole.company_id === _vm.selectedCompany.id,
        "block": ""
      },
      on: {
        "click": function click($event) {
          return _vm.onSelectRole(roleId, _vm.selectedCompany.id);
        }
      }
    }, [_c('Roles', {
      attrs: {
        "ids": [roleId]
      }
    })], 1);
  }) : _vm._e()], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }