<template>
  <component
    :is="tag"
    :variant="variant"
    v-bind="tagProps"
  >
    <template v-if="item.cancel_date">
      Cancels {{ $moment(item.cancel_date).fromNow() }}
    </template>
  </component>
</template>

<script>
export default {
  name: 'BillingSubscriptionCancel',

  props: {
    item: {
      type: Object,
      default: null
    },

    tag: {
      type: String,
      default: 'b-button'
    },

    tagProps: {
      type: Object,
      default: () => ({
        size: 'sm'
      })
    }
  },

  computed: {
    variant() {
      return 'warning'
    }
  }
}
</script>