var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [!_vm.item.start_date ? _c('div', [_c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" You should set curriculum's start date first on "), _c('router-link', {
    attrs: {
      "to": {
        name: 'curriculums-build',
        hash: '#preview'
      }
    }
  }, [_vm._v(" Preview ")]), _vm._v(". ")], 1)], 1) : _c('div', {
    staticClass: "text-right mt-4"
  }, [_c('div', {
    staticClass: "d-inline-flex"
  }, [!_vm.newItem.designed_at ? _c('CurriculumItemActivateButton', {
    attrs: {
      "item": _vm.item,
      "tag": "b-button",
      "tag-props": {
        variant: 'primary'
      }
    },
    on: {
      "submitted": function submitted($event) {
        return _vm.$router.push('/client/list');
      }
    }
  }, [_vm._v(" Activate ")]) : _vm.newItem.status === 'active' ? _c('CurriculumPhaseNumber', {
    staticClass: "ml-3",
    attrs: {
      "tag": "b-button",
      "tag-props": {
        variant: 'link'
      },
      "item": _vm.item,
      "disabled": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var activePhase = _ref.activePhase;
        return [_vm._v(" Phase " + _vm._s(activePhase.number) + " Active ")];
      }
    }])
  }) : _c('CurriculumStatus', {
    staticClass: "ml-3",
    attrs: {
      "item": _vm.newItem,
      "tag": "b-button",
      "tag-props": {
        variant: 'success'
      },
      "disabled": ""
    }
  })], 1), _vm.newItem.designed_at && _vm.unreleasedPhase ? _c('div', {
    staticClass: "d-inline-flex ml-1"
  }, [_c('CurriculumPhaseItemReleaseButton', {
    staticClass: "ml-2",
    attrs: {
      "item": _vm.unreleasedPhase,
      "tag": "b-button",
      "tag-props": {
        variant: 'primary'
      },
      "disabled": !_vm.unreleasedPhase.start_date || !_vm.releaseEnabled
    },
    on: {
      "submitted": _vm.refresh
    }
  }, [_vm._v(" Release Phase " + _vm._s(_vm.unreleasedPhase.number) + " ")])], 1) : _vm._e()])]);
}
var staticRenderFns = []

export { render, staticRenderFns }