var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-table-simple', {
    staticClass: "mb-0",
    attrs: {
      "fixed": ""
    }
  }, [_c('b-thead', [_c('b-tr', {
    attrs: {
      "variant": "primary"
    }
  }, [_c('b-th', {
    staticClass: "text-center bg-transparent w-130px"
  }), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v(" AM Cardio ")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v(" PM Cardio ")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v(" Totals ")]), _c('b-th', {
    staticClass: "text-center"
  }, [_vm._v(" Minutes Per Session ")])], 1)], 1), _vm._l(_vm.curriculum.phases, function (phase) {
    return _c('b-tbody', {
      key: phase.number
    }, [_c('b-tr', [_c('b-th', {
      staticClass: "text-center w-130px",
      attrs: {
        "variant": "success"
      }
    }, [_c('div', {
      staticClass: "py-1"
    }, [_vm._v(" Phase " + _vm._s(phase.number) + " ")])]), _c('b-td', {
      staticClass: "bg-white"
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("-")] : [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].cardio_morning) + " ")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [!_vm.disabled && _vm.newValue ? _c('b-form-input', {
      staticClass: "text-center hide-arrows",
      attrs: {
        "type": "number",
        "step": "1",
        "min": "0",
        "number": "",
        "size": "sm"
      },
      model: {
        value: _vm.newValue[phase.number - 1].cardio_morning,
        callback: function callback($$v) {
          _vm.$set(_vm.newValue[phase.number - 1], "cardio_morning", $$v);
        },
        expression: "newValue[phase.number - 1].cardio_morning"
      }
    }) : [_vm.newValue ? [_vm._v(" " + _vm._s(_vm.newValue[phase.number - 1].cardio_morning) + " ")] : [_vm._v(" " + _vm._s(phase.cardio_morning) + " ")]]], 2) : _vm._e()], 1)], 1), _c('b-td', {
      staticClass: "bg-white"
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("-")] : [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].cardio_evening) + " ")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [!_vm.disabled && _vm.newValue ? _c('b-form-input', {
      staticClass: "text-center hide-arrows",
      attrs: {
        "type": "number",
        "step": "1",
        "min": "0",
        "number": "",
        "size": "sm"
      },
      model: {
        value: _vm.newValue[phase.number - 1].cardio_evening,
        callback: function callback($$v) {
          _vm.$set(_vm.newValue[phase.number - 1], "cardio_evening", $$v);
        },
        expression: "newValue[phase.number - 1].cardio_evening"
      }
    }) : [_vm.newValue ? [_vm._v(" " + _vm._s(_vm.newValue[phase.number - 1].cardio_evening) + " ")] : [_vm._v(" " + _vm._s(phase.cardio_evening) + " ")]]], 2) : _vm._e()], 1)], 1), _c('b-td', {
      staticClass: "bg-white"
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("-")] : [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].cardio_morning + _vm.suggestedValue[phase.number - 1].cardio_evening) + " ")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [!_vm.disabled && _vm.newValue ? _c('b-form-input', {
      staticClass: "text-center hide-arrows",
      attrs: {
        "value": _vm.newValue[phase.number - 1].cardio_morning + _vm.newValue[phase.number - 1].cardio_evening,
        "disabled": "",
        "type": "number",
        "step": "1",
        "min": "0",
        "number": "",
        "size": "sm"
      }
    }) : [_vm.newValue ? [_vm._v(" " + _vm._s(_vm.newValue[phase.number - 1].cardio_morning + _vm.newValue[phase.number - 1].cardio_evening) + " ")] : [_vm._v(" " + _vm._s(phase.cardio_morning + phase.cardio_evening) + " ")]]], 2) : _vm._e()], 1)], 1), _c('b-td', {
      staticClass: "bg-white"
    }, [_c('b-row', {
      staticClass: "text-center",
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showSuggestions ? _c('b-col', [!_vm.suggestedValue[phase.number - 1] ? [_vm._v("-")] : [_vm._v(" " + _vm._s(_vm.suggestedValue[phase.number - 1].cardio_mins_per_session) + " ")]], 2) : _vm._e(), _vm.showValues ? _c('b-col', [!_vm.disabled && _vm.newValue ? _c('b-form-input', {
      staticClass: "text-center hide-arrows",
      attrs: {
        "type": "number",
        "step": "1",
        "min": "0",
        "number": "",
        "size": "sm"
      },
      model: {
        value: _vm.newValue[phase.number - 1].cardio_mins_per_session,
        callback: function callback($$v) {
          _vm.$set(_vm.newValue[phase.number - 1], "cardio_mins_per_session", $$v);
        },
        expression: "newValue[phase.number - 1].cardio_mins_per_session"
      }
    }) : [_vm.newValue ? [_vm._v(" " + _vm._s(_vm.newValue[phase.number - 1].cardio_mins_per_session) + " ")] : [_vm._v(" " + _vm._s(phase.cardio_mins_per_session) + " ")]]], 2) : _vm._e()], 1)], 1)], 1)], 1);
  })], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }