var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._b({
    tag: "component",
    attrs: {
      "variant": _vm.variant
    }
  }, 'component', _vm.tagProps, false), [_vm.item.product_installment && !_vm.item.product_installment.is_paid ? [_vm._v(" Unpaid ")] : _vm.restartDate ? [_vm._v(" Restart " + _vm._s(_vm.$moment(_vm.restartDate).fromNow()) + " ")] : [_vm.item.parent_id ? [_vm._v("Reboot ")] : _vm._e(), _vm._v(_vm._s(_vm.title) + " ")]], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }