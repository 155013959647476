<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- SESSION MINS -->
      <b-form-group label="Session Mins">
        <b-card
          class="bg-white"
          no-body
        >
          <b-input
            v-model="input.session_mins"
            type="number"
            number
          />
        </b-card>
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'CurriculumTrackerCardioItemUpsert',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'tracker-cardios'
    }
  },

  validations() {
    return {
      input: {
        session_mins: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        session_mins: this.newItem.session_mins
      }

      if (!this.isCreated) {
        input.user_id = this.newItem.user_id
        input.curriculum_phase_id = this.newItem.curriculum_phase_id
        input.start_time = this.newItem.start_time
        input.end_time = this.newItem.end_time
      }

      return input
    }
  }
}
</script>
