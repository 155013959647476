<template>
  <b-overlay
    :show="isFetching"
  >
    <!-- CARDIO -->
    <b-form-group
      class="mb-4"
      label="Cardio"
      label-class="font-size-20 text-primary font-weight-bold"
    >
      <CurriculumPhasesCardioInput
        class="mb-2"
        mode="values"
        disabled
        :curriculum="newItem"
      />
    </b-form-group>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import CurriculumPhasesCardioInput from '@/components/curriculums/inputs/CurriculumPhasesCardioInput'

export default {
  name: 'CurriculumItemCardioDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    CurriculumPhasesCardioInput
  },

  data() {
    return {
      baseEndpoint: 'curriculums'
    }
  }
}
</script>