var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('b-row', [_c('b-col', [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1)], 1), _c('b-card', [_c('GymUpsert', {
    attrs: {
      "item-id": _vm.gymId
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }