var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "schedule-event-wrapper w-100 h-100 d-flex",
    "class": {
      'is-active': _vm.isActive
    }
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex justify-content-center align-items-center text-white",
    "class": {
      'workout-event': _vm.event.type === 'workout',
      'cardio-event': _vm.event.type === 'cardio',
      'work-event': _vm.event.type === 'work',
      'sleep-event': _vm.event.type === 'sleep',
      'is-completed': _vm.event.is_performed,
      'is-missed': !_vm.event.is_performed && _vm.isMissed
    }
  }, [_vm.event.type === 'workout' ? _c('b-img', {
    attrs: {
      "height": 20,
      "src": "https://image.flaticon.com/icons/png/128/206/206305.png"
    }
  }) : _vm.event.type === 'cardio' ? _c('b-img', {
    attrs: {
      "height": 20,
      "src": "https://image.flaticon.com/icons/png/128/763/763965.png"
    }
  }) : _vm.event.type === 'sleep' ? _c('b-img', {
    attrs: {
      "height": 20,
      "src": "https://image.flaticon.com/icons/png/512/632/632339.png"
    }
  }) : _vm.event.type === 'work' ? _c('b-img', {
    attrs: {
      "height": 20,
      "src": "https://image.flaticon.com/icons/png/512/1063/1063299.png"
    }
  }) : [_vm._v(" " + _vm._s(_vm.title) + " ")], _c('div', {
    staticClass: "actions-buttons"
  }, [_vm._t("actions", null, {
    "event": _vm.event,
    "toggleEventIsActive": _vm.toggleEventIsActive,
    "deleteEvent": _vm.deleteEvent
  })], 2)], 2)]);
}
var staticRenderFns = []

export { render, staticRenderFns }