var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [!_vm.searchDisabled ? _c('b-row', [_c('b-col', {
    staticClass: "mb-2"
  }, [_c('b-input', {
    attrs: {
      "value": _vm.newParams.filters.search,
      "size": "sm",
      "placeholder": "Search workout..."
    },
    on: {
      "input": _vm.debounceSetFilterSearchParam
    }
  })], 1)], 1) : _vm._e(), _c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "items": _vm.newResult.data,
      "fields": _vm.fields,
      "stacked": "sm",
      "no-local-sorting": "",
      "sort-by": _vm.newParams.order_by,
      "sort-desc": _vm.newParams.order_desc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "row-clicked": function rowClicked($event) {
        !_vm.disabled ? _vm.selectedIds = [$event.id] : null;
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(selected)",
      fn: function fn(row) {
        return [_c('b-icon', {
          style: {
            opacity: _vm.disabled ? '0.6' : null
          },
          attrs: {
            "variant": "primary",
            "font-scale": "1.3",
            "icon": _vm.selectedIds.includes(row.item.id) ? 'check-circle-fill' : 'circle'
          }
        })];
      }
    }, {
      key: "cell(is_favorite)",
      fn: function fn(row) {
        return [_c('b-icon', {
          attrs: {
            "variant": "warning",
            "icon": row.item.is_favorite ? 'star-fill' : 'star'
          }
        })];
      }
    }, {
      key: "cell(muscle_ids)",
      fn: function fn(row) {
        return [_c('Muscles', {
          attrs: {
            "ids": row.item.muscle_ids || []
          }
        })];
      }
    }, {
      key: "cell(fitness_level_ids)",
      fn: function fn(row) {
        return [_c('Abilities', {
          attrs: {
            "ids": row.item.fitness_level_ids || []
          }
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('WorkoutItemActionsButton', {
          attrs: {
            "item": row.item
          },
          on: {
            "delete:item": _vm.refresh,
            "submitted": _vm.refresh
          }
        }, [_c('b-icon-three-dots')], 1)];
      }
    }])
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }