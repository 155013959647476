var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_vm.isFetched ? [_c('b-row', {
    staticClass: "p-3"
  }, [_c('b-col', {
    attrs: {
      "lg": "4"
    }
  }, [_vm.newItem.image_url ? _c('b-img', {
    attrs: {
      "src": _vm.newItem.image_url,
      "fluid": "",
      "alt": "Responsive image"
    }
  }) : _c('b-alert', {
    staticClass: "text-center",
    attrs: {
      "show": "",
      "variant": "warning"
    }
  }, [_vm._v(" No image ")])], 1), _c('b-col', {
    staticClass: "pl-0",
    attrs: {
      "lg": "8"
    }
  }, [_c('b-table-simple', {
    staticClass: "input-table mb-0",
    attrs: {
      "fixed": ""
    }
  }, [_c('b-tbody', [_c('b-tr', [_c('b-th', {
    staticClass: "w-150px"
  }, [_vm._v("Name")]), _c('b-td', [_vm._v(_vm._s(_vm.newItem.name))])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Primary Muscle(s)")]), _c('b-td', [_c('Muscles', {
    attrs: {
      "ids": _vm.newItem.muscle_ids || []
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Subcategory")]), _c('b-td', [_c('ExerciseSubcategories', {
    attrs: {
      "ids": _vm.newItem.subcategory_ids || []
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Position")]), _c('b-td', [_c('ExercisePositions', {
    attrs: {
      "ids": _vm.newItem.position_ids || []
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Bench position")]), _c('b-td', [_c('ExerciseBenchPositions', {
    attrs: {
      "ids": _vm.newItem.bench_position_ids || []
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Hand/Feet placements")]), _c('b-td', [_c('ExerciseHandFeetPlacements', {
    attrs: {
      "ids": _vm.newItem.hand_feet_placement_ids || []
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Fitness Level(s)")]), _c('b-td', [_c('Abilities', {
    attrs: {
      "ids": _vm.newItem.fitness_level_ids || []
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Workout Type")]), _c('b-td', [_c('WorkoutTypes', {
    attrs: {
      "ids": _vm.newItem.type_ids || []
    }
  })], 1)], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Estimated Set Time")]), _c('b-td', [_vm._v(_vm._s(_vm.newItem.estimated_set_time) + "s")])], 1), _c('b-tr', [_c('b-th', {
    staticClass: "w-100px"
  }, [_vm._v("Description")]), _c('b-td', [_vm._v(_vm._s(_vm.newItem.description))])], 1)], 1)], 1)], 1)], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }