<template>
  <component
    :is="tag"
    :class="{
      'text-danger': isDanger
    }"
    v-bind="tagProps"
  >
    {{ title }}
  </component>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'CurriculumPhaseDaysRemaining',

  props: {
    item: {
      type: Object,
      required: true
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    activePhase() {
      if (this.item.status === 'paused') {
        return _.find(this.item.phases, i => (
          this.$moment(this.item.paused_at) >= this.$moment(i.start_date) &&
          this.$moment(this.item.paused_at) < this.$moment(i.start_date).add(1, 'day')
        ))
      }

      return _.find(this.item.phases, i => (
        this.$moment() >= this.$moment(i.start_date) && this.$moment() < this.$moment(i.end_date)
      ))
    },

    restartDate() {
      return _.chain(this.item)
        .get('pauses.items')
        .find(i => this.$moment() < this.$moment(i.to).add(1, 'day'))
        .get('to')
        .value()
    },

    lastDate() {
      if (this.item.status === 'paused') {
        return this.$moment(this.item.paused_at).format('YYYY-MM-DD')
      }

      if (this.restartDate) {
        return this.$moment(this.restartDate).format('YYYY-MM-DD')
      }

      return this.$moment().format('YYYY-MM-DD')
    },

    daysRemaining() {
      return this.activePhase ? Math.floor(
        (this.$moment(this.activePhase.end_date) - this.$moment(this.lastDate)) / (1000 * 60 * 60 * 24)
      ) : null
    },

    title() {
      return _.isNumber(this.daysRemaining) ? `${this.daysRemaining} days` : '-'
    },

    isDanger() {
      return _.isNumber(this.daysRemaining) ? this.daysRemaining <= 7 : false
    }
  }
}
</script>