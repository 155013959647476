var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Layout', [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-5"
  }, [_c('PageHeader', {
    attrs: {
      "title": _vm.title
    }
  })], 1), _c('div', {
    staticClass: "col-lg-7 d-flex align-items-center justify-content-end"
  }, [_c('b-button-group', [_c('b-button', {
    "class": _vm.getNavigationClass(1),
    attrs: {
      "disabled": _vm.getNavigationDisabled(1),
      "to": "#step1"
    },
    on: {
      "click": function click($event) {
        return _vm.onStep(1);
      }
    }
  }, [_vm._v(" General Info ")]), _c('b-button', {
    "class": _vm.getNavigationClass(2),
    attrs: {
      "disabled": _vm.getNavigationDisabled(2),
      "to": "#step2"
    }
  }, [_vm._v(" History ")]), _c('b-button', {
    "class": _vm.getNavigationClass(3),
    attrs: {
      "disabled": _vm.getNavigationDisabled(3),
      "to": "#step3"
    }
  }, [_vm._v(" Nutrition ")]), _c('b-button', {
    "class": _vm.getNavigationClass(4),
    attrs: {
      "disabled": _vm.getNavigationDisabled(4),
      "to": "#step4"
    }
  }, [_vm._v(" Schedule ")]), _c('b-button', {
    "class": _vm.getNavigationClass(5),
    attrs: {
      "disabled": _vm.getNavigationDisabled(5),
      "to": "#step5"
    }
  }, [_vm._v(" Measurements ")]), _c('b-button', {
    "class": _vm.getNavigationClass(6),
    attrs: {
      "disabled": _vm.getNavigationDisabled(6),
      "to": "#step6"
    }
  }, [_vm._v(" Finish ")])], 1)], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_vm.activeStep === 1 ? _c('UserProfileUpsert', {
    attrs: {
      "item": _vm.item,
      "show-roles": false,
      "prefetch": ""
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.item = $event;
      },
      "submitted": _vm.onSubmitted
    }
  }) : _vm._e(), _vm.item.id && _vm.activeStep === 2 ? _c('ClientHistoryUpsert', {
    attrs: {
      "item": _vm.item,
      "prefetch": ""
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.item = $event;
      },
      "submitted": _vm.onSubmitted
    }
  }) : _vm._e(), _vm.item.id && _vm.activeStep === 3 ? _c('Nutrition', {
    attrs: {
      "client": _vm.item,
      "step": _vm.activeStep
    }
  }) : _vm._e(), _vm.item.id && _vm.activeStep === 4 ? _c('ClientScheduleUpdate', {
    attrs: {
      "item": _vm.item,
      "prefetch": ""
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.item = $event;
      },
      "submitted": _vm.onSubmitted
    }
  }) : _vm._e(), _vm.item.id && _vm.activeStep === 5 ? _c('ClientItemUpsertPhysicalCondition', {
    attrs: {
      "item": _vm.item
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.item = $event;
      },
      "submitted": _vm.onSubmitted
    }
  }) : _vm._e(), _vm.item.id && _vm.activeStep === 6 ? _c('ClientProfileUptodateUpsert', {
    attrs: {
      "item": _vm.item
    },
    on: {
      "update:item": function updateItem($event) {
        _vm.item = $event;
      },
      "submitted": function submitted($event) {
        return _vm.$router.push('/client/list');
      }
    }
  }) : _vm._e()], 1)])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }