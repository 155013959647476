var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "3"
    }
  }, [_c('b-input', {
    attrs: {
      "value": _vm.newParams.filters.search,
      "size": "sm",
      "placeholder": "Search exercise..."
    },
    on: {
      "input": _vm.debounceSetFilterSearchParam
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "3"
    }
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.$store.getters['asset/getAbilityLevelsOptions']
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v(" Fitness Level ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterFitnessLevelId,
      callback: function callback($$v) {
        _vm.filterFitnessLevelId = $$v;
      },
      expression: "filterFitnessLevelId"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.$store.getters['asset/getPrimaryMusclesOptions']
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v(" Primary Muscle ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterMuscleId,
      callback: function callback($$v) {
        _vm.filterMuscleId = $$v;
      },
      expression: "filterMuscleId"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "2"
    }
  }, [_c('b-form-select', {
    attrs: {
      "size": "sm",
      "options": _vm.$store.getters['asset/getWorkoutTypesOptions']
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null
          }
        }, [_vm._v(" Workout Type ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.filterWorkoutType,
      callback: function callback($$v) {
        _vm.filterWorkoutType = $$v;
      },
      expression: "filterWorkoutType"
    }
  })], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "lg": "1"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "py-1 d-inline-flex",
    attrs: {
      "size": "sm",
      "switch": ""
    },
    model: {
      value: _vm.filterIsFavorite,
      callback: function callback($$v) {
        _vm.filterIsFavorite = $$v;
      },
      expression: "filterIsFavorite"
    }
  }, [_vm._v(" Favorite ")])], 1)], 1), _c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "items": _vm.newResult.data,
      "fields": _vm.fields,
      "stacked": "sm",
      "fixed": "",
      "no-local-sorting": "",
      "sort-by": _vm.newParams.order_by,
      "sort-desc": _vm.newParams.order_desc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(select)",
      fn: function fn(row) {
        return [_c('b-icon', {
          style: {
            cursor: 'pointer'
          },
          attrs: {
            "variant": "primary",
            "font-scale": "1.3",
            "icon": _vm.selectedIds.includes(row.item.id) ? 'check-circle-fill' : 'circle'
          },
          on: {
            "click": function click($event) {
              return _vm.toogleItem(row.item);
            }
          }
        })];
      }
    }, {
      key: "cell(is_favorite)",
      fn: function fn(row) {
        return [_c('ExerciseItemFavoriteButton', {
          style: {
            cursor: 'pointer'
          },
          attrs: {
            "item": row.item,
            "tag": "b-icon",
            "tag-props": {
              variant: 'warning',
              icon: row.item.is_favorite ? 'star-fill' : 'star'
            }
          },
          on: {
            "submitted": _vm.refresh
          }
        })];
      }
    }, {
      key: "cell(name)",
      fn: function fn(row) {
        return [_c('ExerciseItemDetailButton', {
          staticClass: "text-decoration-underline-dotted",
          style: {
            cursor: 'pointer'
          },
          attrs: {
            "item": row.item,
            "prefetch": ""
          }
        }, [_vm._v(" " + _vm._s(row.item.name) + " ")])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(row) {
        return [_c('WorkoutTypes', {
          attrs: {
            "ids": row.item.type_ids
          }
        })];
      }
    }, {
      key: "cell(muscle_ids)",
      fn: function fn(row) {
        return [_c('Muscles', {
          attrs: {
            "ids": row.item.muscle_ids || []
          }
        })];
      }
    }, {
      key: "cell(fitness_level_ids)",
      fn: function fn(row) {
        return [_c('Abilities', {
          attrs: {
            "ids": row.item.fitness_level_ids || []
          }
        })];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_c('ExerciseItemActionsButton', {
          attrs: {
            "item": row.item
          },
          on: {
            "deleted:item": _vm.refresh,
            "submitted": _vm.refresh,
            "copied": function copied($event) {
              return _vm.$router.push("/exercise/".concat($event.id));
            }
          }
        }, [_c('b-icon-three-dots')], 1)];
      }
    }])
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }