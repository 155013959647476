<template>
  <div>
    <b-button
      variant="text"
      @click="showModal()"
    >
      <b-icon
        variant="primary"
        :icon="adminView ? 'check-circle-fill' : 'circle'"
        class="mr-1"
      />

      Admin
    </b-button>
  </div>
</template>

<script>
export default {
  name: 'AdminViewScheckbox',

  computed: {
    adminView: {
      get() {
        return this.$store.getters['auth/adminView']
      },

      set(value) {
        this.$store.dispatch('auth/setAdminView', value)
      }
    }
  },

  methods: {
    showModal() {
      this.$bvModal.msgBoxConfirm('Are you sure you want to switch admin view?', {
        title: 'Please Confirm',
        okTitle: 'Switch',
        okVariant: 'warning',
        centered: true
      })
        .then(value => {
          if (value) {
            this.adminView = !this.adminView
            this.$router.push('/')
          }
        })
    }
  }
}
</script>