var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', {
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    staticClass: "pr-2"
  }, [_c('b-input-group', {
    attrs: {
      "append": _vm.unitLabel
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.state
    },
    model: {
      value: _vm.units,
      callback: function callback($$v) {
        _vm.units = $$v;
      },
      expression: "units"
    }
  })], 1)], 1), _c('b-col', [_c('b-input-group', {
    attrs: {
      "append": "fract"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.fractionOptions,
      "state": _vm.state
    },
    model: {
      value: _vm.fractions,
      callback: function callback($$v) {
        _vm.fractions = $$v;
      },
      expression: "fractions"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }