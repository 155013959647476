<template>
  <b-overlay
    :show="isFetching"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Name"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
        :state="getFieldState($v.input.name)"
      >
        <b-form-input
          v-model="input.name"
          placeholder="Enter meal name"
          :state="getFieldState($v.input.name)"
        />
      </b-form-group>

      <b-form-group
        label="Meal type"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.type)"
        :state="getFieldState($v.input.type)"
      >
        <b-form-select
          v-model="input.type"
          :options="$store.getters['asset/getMealTypesOptions']"
          :state="getFieldState($v.input.type)"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              Select meal type 
            </b-form-select-option>
          </template>
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Taken at"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.taken_at)"
        :state="getFieldState($v.input.taken_at)"
      >
        <b-form-timepicker
          v-model="input.taken_at"
          placeholder="Taken at"
          locale="en"
          :state="getFieldState($v.input.taken_at)"
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0">
        <b-row
          align-h="between"
        >
          <b-col class="text-right">
            <b-button
              variant="success"
              type="submit"
              :disabled="isSubmitting"
            >
              <b-spinner v-if="isSubmitting" small />Submit
            </b-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'CurriculumMealItemUpsert',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'curriculum-meals'
    }
  },

  validations() {
    return {
      input: {
        name: { required },
        type: { required },
        taken_at: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        name: _.get(this.newItem, 'name', null),
        type: _.get(this.newItem, 'type', null),
        taken_at: _.get(this.newItem, 'taken_at', null),
        bulk_mode: true
      }

      if (!this.isCreated) {
        input.phase_id = _.get(this.newItem, 'phase_id', null)
        input.day_number = _.get(this.newItem, 'day_number', null)
      }

      return input
    }
  }
}
</script>
