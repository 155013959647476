<template>
  <Layout>
    <b-row>
      <b-col cols="12">
        <PageHeader :title="title" />
      </b-col>
    </b-row>

    <template v-if="$acl.check('isClient')">
      <b-row>
        <b-col md="6">
          <b-card>
            <BillingDetail
              :item="currentUser"
              :prefetch="false"
            />
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card>
            <PasswordChange
              :item="currentUser"
              :prefetch="false"
            />
          </b-card>
        </b-col>
      </b-row>

      <h2 class="font-size-18 mb-3">Purchases</h2>

      <b-row>
        <b-col>
          <b-card>
            <CompanyPaymentList />
          </b-card>
        </b-col>
      </b-row>
    </template>

    <b-row v-else>
      <b-col>
        <b-card>
          <PasswordChange
            :item="currentUser"
            :prefetch="false"
          />
        </b-card>
      </b-col>
    </b-row>
  </Layout>
</template>

<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import PasswordChange from '@/components/account/PasswordChange'
import BillingDetail from '@/components/account/BillingDetail'
import CompanyPaymentList from '@/components/companies/payments/CompanyPaymentList'

export default {
  name: 'AccountPage',

  components: {
    Layout,
    PageHeader,
    PasswordChange,
    BillingDetail,
    CompanyPaymentList
  },

  page() {
    return {
      title: this.title
    }
  },

  computed: {
    title() {
      return 'Account Settings'
    },

    currentUser() {
      return this.$store.getters['auth/currentUser']
    }    
  }
}
</script>