<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <!-- NAME -->
      <b-form-group
        label="Name"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.name)"
        :state="getFieldState($v.input.name)"
      >
        <b-form-input
          v-model="input.name"
          :state="getFieldState($v.input.name)"
          placeholder="Enter Name"
        />
      </b-form-group>

      <!-- DESCRIPTION -->
      <b-form-group
        label="Description"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.description)"
        :state="getFieldState($v.input.description)"
      >
        <b-form-input
          v-model="input.description"
          :state="getFieldState($v.input.description)"
          placeholder="Enter Description"
        />
      </b-form-group>

      <!-- PRICE PER WEEK -->
      <b-form-group
        label="Price per Week"
        label-cols-sm="3"
        :invalid-feedback="getFieldInvalidFeedback($v.input.price_per_week)"
        :state="getFieldState($v.input.price_per_week)"
      >
        <b-form-input
          v-model="input.price_per_week"
          :state="getFieldState($v.input.price_per_week)"
          type="number"
          number
          placeholder="Enter Price per Week"
        />
      </b-form-group>

      <!-- Stripe Product ID -->
      <b-form-group
        label="Stripe Product"
        label-cols-sm="3"
      >
        <b-form-input
          v-model="newItem.stripe_product_id"
          disabled
        />
      </b-form-group>

      <!-- GOAL DIRECTION -->
      <b-form-group
        label="Goal direction"
        label-cols-sm="3"
      >
        <b-form-select
          :value="newItem.goal_direction_id"
          :options="$store.getters['asset/getGoalDirectionsOptions']"
          disabled
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right mt-4">
        <b-button
          class="mr-2"
          @click="$emit('cancel')"
        >
          Cancel
        </b-button>

        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />{{ actionName }}
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required, maxLength } from "vuelidate/lib/validators"

export default {
  name: 'CurriculumProductItemUpsert',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'curriculum-products'
    }
  },

  validations() {
    const input = {
      name: { required, maxLength: maxLength(80) },
      description: { required, maxLength: maxLength(300) },
      price_per_week: { required }
    }

    return { input }
  },

  methods: {
    getInput() {
      const input = {
        name: _.get(this.newItem, 'name'),
        description: _.get(this.newItem, 'description'),
        price_per_week: _.get(this.newItem, 'price_per_week')
      }

      return input
    }
  }
}
</script>