<template>
  <b-overlay
    :show="isFetching || isSubmitting"
  >
    <b-table
      ref="table"
      class="table-select mb-0"
      :items="newResult.data"
      :fields="fields"
      stacked="sm"
      :sort-by.sync="newParams.order_by"
      :sort-desc.sync="newParams.order_desc"
    >
      <template #cell(start_time)="row">
        {{ row.item.start_time ? $moment(row.item.start_time).format('YYYY-MM-DD') : '-' }}
      </template>

      <template #cell(log_time)="row">
        {{ row.item.log_time ? $moment(row.item.log_time).format('YYYY-MM-DD') : '-' }}
      </template>

      <template #cell(hit_time)="row">
        <TimeInput
          :value="row.item.hit_time"
          disabled
        />
      </template>

      <template #cell(total_time)="row">
        <TimeInput
          :value="row.item.total_time"
          disabled
        />
      </template>

      <template #cell(low_intensity_time)="row">
        <TimeInput
          :value="row.item.low_intensity_time"
          disabled
        />
      </template>

      <template #cell(machine_id)="row">
        <CardioMachines :ids="[row.item.machine_id || '-']" />
      </template>

      <template #cell(actions)="row">
        <TrackerCardioItemUpsertButton
          :item="row.item"
          tag="b-button"
          :tag-props="{
            variant: 'link',
            size: 'sm'
          }"
          @submitted="refresh"
        >
          <b-icon-pencil />
        </TrackerCardioItemUpsertButton>
      </template>
    </b-table>

    <b-pagination
      v-if="showPagination"
      class="mt-4"
      v-model="newParams.page"
      pills
      align="center"
      :total-rows="newResult.total"
      :per-page="newParams.limit"
    />
  </b-overlay>
</template>

<script>
import crudListMixin from '@/mixins/crud-list'
import TrackerCardioItemUpsertButton from './buttons/TrackerCardioItemUpsertButton'
import TimeInput from '@/components/inputs/time-input'
import CardioMachines from '@/components/v1/asset/CardioMachines'

export default {
  name: 'TrackerCardioList',

  mixins: [
    crudListMixin
  ],

  components: {
    TrackerCardioItemUpsertButton,
    TimeInput,
    CardioMachines
  },

  data() {
    return {
      baseEndpoint: 'tracker-cardios',

      fields: [
        { key: 'start_time', sortable: false, label: 'Date' },
        { key: 'log_time', sortable: false, label: 'Performed' },
        { key: 'machine_id', sortable: false, label: 'Machine Type' },
        { key: 'num_intervals', sortable: false, label: '# of Intervals' },
        { key: 'hit_time', sortable: false, label: 'Total HIT Time' },
        { key: 'low_intensity_time', sortable: false },
        { key: 'total_time', sortable: false, label: 'Total time' },
        { key: 'actions', sortable: false, tdClass: 'text-right', thClass: 'text-right' }
      ]
    }
  }
}
</script>