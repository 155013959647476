<template>
  <b-overlay :show="isFetching">
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Phase Number"
        label-cols="3"
      >
        <b-form-select
          :value="newItem.phase_number"
          :options="[1, 2, 3, 4]"
          :disabled="isCreated"
        />
      </b-form-group>

      <!-- Goal direction -->
      <b-form-group
        label="Goal direction"
        label-cols-sm="3"
      >
        <b-form-select
          :value="newItem.goal_direction"
          :options="$store.getters['asset/getGoalDirectionsOptions']"
          :disabled="isCreated"
        />
      </b-form-group>

      <!-- Fitness level -->
      <b-form-group
        label="Fitness level"
        label-cols-sm="3"
      >
        <b-form-select
          :value="newItem.fitness_level"
          :options="$store.getters['asset/getAbilityLevelsOptions']"
          :disabled="isCreated"
        />
      </b-form-group>

      <b-form-group
        label="Multiplier"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.multiplier)"
      >
        <b-form-input
          v-model="input.multiplier"
          type="number"
          number
          step="0.01"
          :state="getFieldState($v.input.multiplier)"
        />
      </b-form-group>

      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small /> Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'NBCaliperRatioItemUpsert',

  mixins: [
    crudItemMixin
  ],

  computed: {
    baseEndpoint() {
      return 'admin/nb-caliper-ratio'
    }
  },

  validations() {
    return {
      input: {
        multiplier: { required }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        multiplier: _.get(this.newItem, 'multiplier', null)
      }

      return input
    }
  }
}
</script>