<template>
  <component
    :is="tag"
    v-bind="tagProps"
    @click="popupIsShown = true"
  >
    <slot />

    <b-modal
      v-model="popupIsShown"
      content-class="rounded-0 border-0"
      header-bg-variant="dark"
      header-text-variant="white"
      header-class="rounded-0 p-4"
      hide-footer
    >
      <template #modal-title>
        NB Caliper
      </template>

      <NBCaliperItemUpsert
        :item="item"
        :prefetch="prefetch"
        @update:item="$emit('update:item', $event)"
        @submitted="popupIsShown = false"
      />
    </b-modal>
  </component>
</template>

<script>
import appConfig from '@/app.config'
import NBCaliperItemUpsert from '../NBCaliperItemUpsert'

export default {
  name: 'NBCaliperItemUpsertButton',

  components: {
    NBCaliperItemUpsert
  },

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    prefetch: {
      type: Boolean,
      default: false
    },

    tag: {
      type: String,
      default: 'span'
    },

    tagProps: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      config: appConfig,
      isBusy: false,
      popupIsShown: false
    }
  }
}
</script>
