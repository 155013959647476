var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isBusy,
      "variant": _vm.config.overlay.variant,
      "opacity": _vm.config.overlay.opacity,
      "blur": _vm.config.overlay.blur,
      "rounded": "sm"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.formSubmit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Phase Number",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.phase_number)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": [1, 2, 3, 4],
      "state": _vm.getFieldState(_vm.$v.input.phase_number)
    },
    model: {
      value: _vm.input.phase_number,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "phase_number", $$v);
      },
      expression: "input.phase_number"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Gender",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.gender)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getSexOptions'],
      "state": _vm.getFieldState(_vm.$v.input.gender)
    },
    model: {
      value: _vm.input.gender,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "gender", $$v);
      },
      expression: "input.gender"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Goal Direction",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.goal_direction_id)
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.$store.getters['asset/getGoalDirectionsOptions'],
      "state": _vm.getFieldState(_vm.$v.input.goal_direction_id)
    },
    model: {
      value: _vm.input.goal_direction_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "goal_direction_id", $$v);
      },
      expression: "input.goal_direction_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Weight",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.weight)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "state": _vm.getFieldState(_vm.$v.input.weight)
    },
    model: {
      value: _vm.input.weight,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "weight", $$v);
      },
      expression: "input.weight"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Weight Loss Ratio",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.weight_loss_ratio)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.00000000001",
      "state": _vm.getFieldState(_vm.$v.input.weight_loss_ratio)
    },
    model: {
      value: _vm.input.weight_loss_ratio,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "weight_loss_ratio", $$v);
      },
      expression: "input.weight_loss_ratio"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Increment",
      "label-cols": "3",
      "invalid-feedback": "This value is required.",
      "state": _vm.getFieldState(_vm.$v.input.increment)
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "number",
      "number": "",
      "step": "0.00000000001",
      "state": _vm.getFieldState(_vm.$v.input.increment)
    },
    model: {
      value: _vm.input.increment,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "increment", $$v);
      },
      expression: "input.increment"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }