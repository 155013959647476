<template>
  <b-overlay :show="isFetching">
    <template v-if="isFetched">
      <b-card
        bg-variant="dark"
        text-variant="white"
        class="rounded-0 pt-4 m-0"
        body-class="pt-5 mt-5"
      >
        <div class="font-size-24 font-weight-bold">
          {{ newItem.name }}
        </div>

        {{ newItem.description }}
      </b-card>

      <b-card
        class="py-3 m-0"
        bg-variant="light"
      >
        <b-row>
          <b-col>
            <span class="font-weight-bold">
              Fitness Level
            </span>: <Abilities :ids="newItem.fitness_level_ids" />
          </b-col>

          <b-col>
            <span class="font-weight-bold">
              Gender
            </span>: <Genders :ids="[newItem.gender]" />
          </b-col>

          <b-col>
            <span class="font-weight-bold">
              Goal Direction
            </span>: <GoalDirections :ids="[newItem.goal_direction_id]" />
          </b-col>
        </b-row>
      </b-card>

      <b-card class="m-0">
        <h4 class="mb-4">
          {{ weeksCount }} Week Program - {{ phasesCount }} Phases
        </h4>

        <WorkoutProgramPhasesInput
          :value="newItem.phases"
        />
      </b-card>
    </template>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import WorkoutProgramPhasesInput from '@/components/inputs/workout-program-phases-input'
import Abilities from '@/components/v1/asset/Abilities'
import GoalDirections from '@/components/v1/asset/GoalDirections'
import Genders from '@/components/v1/asset/Genders'

export default {
  name: 'WorkoutProgramItemDetail',

  mixins: [
    crudItemMixin
  ],

  components: {
    WorkoutProgramPhasesInput,
    Abilities,
    GoalDirections,
    Genders
  },

  data() {
    return {
      baseEndpoint: 'workout-programs'
    }
  },

  computed: {
    phasesCount() {
      return _.chain(this.newItem)
        .get('phases')
        .size()
        .value()
    },

    weeksCount() {
      return _.chain(this.newItem)
        .get('phases')
        .flatMap('weeks')
        .size()
        .value()
    }
  },
}
</script>