<template>
  <b-overlay
    :show="isBusy"
    :variant="config.overlay.variant"
    :opacity="config.overlay.opacity"
    :blur="config.overlay.blur"
    rounded="sm"
  >
    <b-form
      @submit.prevent="formSubmit"
    >
      <b-form-group
        label="Gender"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.gender)"
      >
        <b-form-select
          v-model="input.gender"
          :options="$store.getters['asset/getSexOptions']"
          :state="getFieldState($v.input.gender)"
        />
      </b-form-group>

      <b-form-group
        label="Lean Factor"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.lean_factor)"
      >
        <b-form-input
          v-model="input.lean_factor"
          type="number"
          number
          :state="getFieldState($v.input.lean_factor)"
        />
      </b-form-group>

      <b-form-group
        label="Body Fat From"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.body_fat_from)"
      >
        <b-form-input
          v-model="input.body_fat_from"
          type="number"
          number
          :state="getFieldState($v.input.body_fat_from)"
        />
      </b-form-group>

      <b-form-group
        label="Body Fat To"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.body_fat_to)"
      >
        <b-form-input
          v-model="input.body_fat_to"
          type="number"
          number
          :state="getFieldState($v.input.body_fat_to)"
        />
      </b-form-group>

      <b-form-group
        label="Multiplier"
        label-cols="3"
        invalid-feedback="This value is required."
        :state="getFieldState($v.input.multiplier)"
      >
        <b-form-input
          v-model="input.multiplier"
          type="number"
          number
          step="0.01"
          :state="getFieldState($v.input.multiplier)"
        />
      </b-form-group>

      <b-form-group class="text-right mt-4">
        <b-button
          variant="primary"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small /> Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import appConfig from '@/app.config'
import { required } from "vuelidate/lib/validators"

export default {
  name: 'LeanFactorItemUpsert',

  props: {
    item: {
      type: Object,
      default: () => ({})
    },

    prefetch: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      config: appConfig,
      isBusy: false,
      isSubmitting: false,
      newItem: {},
      input: {}
    }
  },

  watch: {
    item: {
      immediate: true,
      deep: true,
      handler() {
        if (!_.isEqual(this.item, this.newItem)) {
          this.newItem = _.cloneDeep(this.item)

          this.$v.$reset()

          if (this.prefetch) {
            this.fetchItem()
          }
        }
      }
    },

    newItem: {
      immediate: true,
      deep: true,
      handler() {
        if (this.newItem && !_.isEqual(this.newItem, this.item)) {
          this.$emit('update:item', _.cloneDeep(this.newItem))
        }

        if (this.newItem) {
          this.setInput()
        }
      }
    }
  },

  validations() {
    return {
      input: {
        gender: { required },
        lean_factor: { required },
        body_fat_from: { required },
        body_fat_to: { required },
        multiplier: { required }
      }
    }
  },

  methods: {
    setInput() {
      this.input = {
        id: this.newItem.id,
        gender: this.newItem.gender,
        lean_factor: this.newItem.lean_factor,
        body_fat_from: this.newItem.body_fat_from,
        body_fat_to: this.newItem.body_fat_to,
        multiplier: this.newItem.multiplier
      } 
    },

    getFieldState(vuelidateParam) {
      return vuelidateParam && vuelidateParam.$dirty ? !vuelidateParam.$invalid : null
    },

    formSubmit() {
      this.$v.$touch();

      if (this.$v.$error) {
        return
      }

      if (this.input.id) {
        this.updateItem()
      } else {
        this.createItem()
      }
    },

    fetchItem() {
      this.$axios
        .get(`admin/lean-factor/${this.newItem.id}`, {
          parent: this
        })
        .then(({ data }) => {
          this.newItem = data
        })
        .catch((e) => {
          throw e
        })
    },

    updateItem() {
      this.isSubmitting = true

      this.$axios
        .patch(`admin/lean-factor/${this.input.id}`, this.input)
        .then(({ data }) => {
          this.newItem = data
          this.isSubmitting = false
          this.$emit('submitted', this.newItem)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    },

    createItem() {
      this.isSubmitting = true

      this.$axios
        .post('admin/lean-factor', this.input)
        .then(({ data }) => {
          this.newItem = data
          this.isSubmitting = false
          this.$emit('submitted', this.newItem)
        })
        .catch((e) => {
          this.isSubmitting = false
          throw e
        })
    }
  }
}
</script>