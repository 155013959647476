var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_vm._l(_vm.newResult.data, function (item, itemIndex) {
    return _c('b-row', {
      key: item.id,
      "class": {
        'mt-3': itemIndex !== 0
      },
      attrs: {
        "align-v": "center"
      }
    }, [_vm.showTime ? _c('b-col', {
      style: {
        maxWidth: '90px'
      }
    }, [_c('b-badge', {
      staticClass: "p-2",
      attrs: {
        "variant": "success"
      }
    }, [_vm._v(" " + _vm._s(_vm.$moment(item.taken_at, 'HH:mm:ss').format('hh:mm A')) + " ")])], 1) : _vm._e(), _c('b-col', [!_vm.disabled ? _c('CurriculumSupplementItemUpsertButton', {
      staticClass: "font-weight-bold p-0",
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "submitted": function submitted($event) {
          _vm.$emit('submitted:item', $event);

          _vm.refresh();
        }
      }
    }, [_vm._v(" " + _vm._s(item.supplement.name) + " ")]) : _c('div', [_vm._v(" " + _vm._s(item.supplement.name) + " ")]), _c('div', [_vm._v(_vm._s(item.amount) + " "), _c('SupplementDoseTypes', {
      attrs: {
        "ids": [item.supplement.dose_type],
        "lower": ""
      }
    })], 1)], 1), !_vm.disabled ? _c('b-col', {
      staticClass: "text-right w-50px"
    }, [_c('CurriculumSupplementItemDeleteButton', {
      staticClass: "p-0",
      attrs: {
        "item": item,
        "tag": "b-button",
        "tag-props": {
          variant: 'link'
        }
      },
      on: {
        "delete:item": function deleteItem($event) {
          _vm.$emit('delete:item', $event);

          _vm.refresh();
        }
      }
    }, [_c('b-icon-trash', {
      attrs: {
        "scale": "0.8"
      }
    })], 1)], 1) : _vm._e()], 1);
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }