<template>
  <b-tabs
    active-nav-item-class="bg-tab text-primary"
    nav-class="border-0"
    justified
  >
    <b-tab
      v-for="(phase, phaseIndex) in value"
      :key="phase.id"
      title-link-class="rounded-0 border-0"
      :title="`Phase ${phaseIndex + 1}`"
    >
      <b-card class="my-0" no-body>
        <b-table-simple
          v-for="(week, weekIndex) in phase.weeks"
          :key="week.id"
          class="m-0 input-table"
        >
          <b-thead>
            <b-tr>
              <b-th
                colspan="4"
              >
                Week {{ weekIndex + 1 }}
              </b-th>
            </b-tr>
          </b-thead>

          <b-tbody>
            <b-tr
              v-for="(workout, workoutIndex) in week.workouts"
              :key="workoutIndex"
              @click="$emit('select-workout', workout)"
            >
              <b-td>
                {{ workoutIndex }}
              </b-td>

              <b-td class="w-20">
                {{ workout.name }}
              </b-td>

              <b-td class="w-33-33">
                <Muscles :ids="workout.muscle_ids" />
              </b-td>

              <b-td class="w-33-33">
                <Abilities :ids="workout.fitness_level_ids" />
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-card>
    </b-tab>
  </b-tabs>
</template>

<script>
import Muscles from '@/components/v1/asset/Muscles'
import Abilities from '@/components/v1/asset/Abilities'

export default {
  name: 'WorkoutProgramPhasesInput',

  components: {
    Muscles,
    Abilities
  },

  props: {
    value: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.input-table {
  thead {
    th {
      background: #BAD9D9;
    }
  }

  tbody {
    tr {
      background: #DADADE;

      &:nth-child(odd) {
        background: #ECECF1;
      } 
    }
  }
}
</style>