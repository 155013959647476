var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [!_vm.isCreated || _vm.isFetched ? [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Primary trainer",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.primary_trainer_id),
      "state": _vm.getFieldState(_vm.$v.input.primary_trainer_id)
    }
  }, [_vm.primaryTrainerIsEditable ? _c('UserSelect', {
    attrs: {
      "item": _vm.newItem.primary_trainer,
      "show-create": false,
      "params": {
        filters: {
          role_ids: [2]
        }
      },
      "placeholder": "Type a primary trainer name"
    },
    model: {
      value: _vm.input.primary_trainer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "primary_trainer_id", $$v);
      },
      expression: "input.primary_trainer_id"
    }
  }) : _c('UserSelect', {
    attrs: {
      "value": _vm.newItem.primary_trainer ? _vm.newItem.primary_trainer.id : null,
      "item": _vm.newItem.primary_trainer,
      "disabled": "",
      "params": {
        filters: {
          role_ids: [2]
        }
      },
      "placeholder": "No primary trainer"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Secondary trainer",
      "label-cols": "3",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.secondary_trainer_id),
      "state": _vm.getFieldState(_vm.$v.input.secondary_trainer_id)
    }
  }, [_vm.secondaryTrainerIsEditable ? _c('UserSelect', {
    attrs: {
      "item": _vm.newItem.secondary_trainer,
      "show-create": false,
      "params": {
        filters: {
          role_ids: [2]
        }
      },
      "placeholder": "Type a secondary trainer name"
    },
    model: {
      value: _vm.input.secondary_trainer_id,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "secondary_trainer_id", $$v);
      },
      expression: "input.secondary_trainer_id"
    }
  }) : _c('UserSelect', {
    attrs: {
      "value": _vm.newItem.secondary_trainer ? _vm.newItem.secondary_trainer.id : null,
      "item": _vm.newItem.secondary_trainer,
      "disabled": "",
      "params": {
        filters: {
          role_ids: [2]
        }
      },
      "placeholder": "No secondary trainer"
    }
  })], 1), _c('b-form-group', {
    staticClass: "text-right mt-4"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(" Submit ")], 1)], 1)], 1)] : _vm._e()], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }