<template>
  <Layout>
    <b-overlay :show="isFetching">
      <b-row>
        <b-col cols="8">
          <PageHeader :title="title" />
        </b-col>
      </b-row>

      <b-row v-if="!isCreated || isFetched">
        <b-col>
          <b-card body-class="table-primary p-0">
            <ExerciseListSelect
              class="p-3"
              draggable-group-name="exerciseGroupItems"
              :clone="exercise => ({ exercise })"
            />
          </b-card>
        </b-col>

        <b-col>
          <b-card>
            <b-collapse :visible="mode === '#exercises'">
              <b-row class="pb-3">
                <b-col
                  class="font-weight-bold font-size-18"
                  cols="8"
                >
                  Workout - {{ newItem.name }}
                </b-col>

                <b-col
                  class="text-right"
                  cols="4"
                >
                  <b-button
                    variant="primary"
                    @click="mode = ''"
                  >
                    Edit
                  </b-button>
                </b-col>
              </b-row>
            </b-collapse>

            <b-collapse :visible="mode !== '#exercises'">
              <WorkoutItemUpsert
                :item.sync="newItem"
                :show-cancel="isCreated"
                @update:item="onUpdateItem"
                @submitted="mode = '#exercises'"
                @cancel="mode = '#exercises'"
              />
            </b-collapse>

            <b-collapse
              v-if="isCreated"
              :visible="mode === '#exercises'"
            >
              <ExerciseGroupList
                :params="{
                  filters: {
                    workout_id: newItem.id
                  }
                }"
              />

              <div class="text-right">
                <b-button
                  class="ml-1"
                  to="/workout/list"
                  variant="success"
                >
                  Finish
                </b-button>
              </div>
            </b-collapse>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </Layout>
</template>

<script>
import _ from 'lodash'
import crudItem from '@/mixins/crud-item'
import Layout from '@/router/layouts/main'
import PageHeader from '@/components/page-header'
import WorkoutItemUpsert from '@/components/workouts/WorkoutItemUpsert'
import ExerciseListSelect from '@/components/exercises/ExerciseListSelect'
import ExerciseGroupList from '@/components/workouts/exercise-groups/ExerciseGroupList'

export default {
  name: 'WorkoutPage',

  mixins: [
    crudItem
  ],

  components: {
    Layout,
    PageHeader,
    WorkoutItemUpsert,
    ExerciseListSelect,
    ExerciseGroupList
  },

  page() {
    return {
      title: this.title
    }
  },

  data() {
    return {
      baseEndpoint: 'workouts'
    }
  },

  computed: {
    title() {
      return this.isCreated ? this.newItem.name || '' : 'Add workout'
    },

    mode: {
      get() {
        return !this.isCreated ? null : this.$route.hash
      },

      set(hash) {
        if (this.isCreated) {
          this.$router.push({ ...this.$route, hash })
        }
      }
    }
  },

  watch: {
    '$route.params.id': {
      immediate: true,

      handler() {
        const id = this.$route.params.id ? parseInt(this.$route.params.id) : null

        if (this.newItem.id !== id) {
          this.newItem = id? { id } : {}
          this.refresh()
        }
      }
    }
  },

  methods: {
    onUpdateItem(newItem) {
      if (newItem.id !== _.toNumber(this.$route.params.id)) {
        this.$router.push(`/workout/${newItem.id}`)
      }
    }
  }
}
</script>