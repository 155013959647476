var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c(_vm.tag, _vm._b({
    tag: "component",
    on: {
      "click": function click($event) {
        _vm.popupIsShown = true;
      }
    }
  }, 'component', _vm.tagProps, false), [_vm._t("default"), _c('b-modal', {
    attrs: {
      "content-class": "rounded-0 border-0",
      "header-bg-variant": "dark",
      "header-text-variant": "white",
      "header-class": "rounded-0 p-4",
      "hide-footer": ""
    },
    scopedSlots: _vm._u([{
      key: "modal-title",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.title) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.popupIsShown,
      callback: function callback($$v) {
        _vm.popupIsShown = $$v;
      },
      expression: "popupIsShown"
    }
  }, [_c('CurriculumItemRestart', {
    attrs: {
      "item": _vm.item
    },
    on: {
      "update:item": function updateItem($event) {
        return _vm.$emit('update:item', $event);
      },
      "submitted": function submitted($event) {
        _vm.$emit('submitted', $event);

        _vm.popupIsShown = false;
      }
    }
  })], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }