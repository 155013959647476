var render = function () {
  var _vm$$v$input$name, _vm$$v$input$name$$pa, _vm$$v$input$calories, _vm$$v$input$calories2, _vm$$v$input$protein, _vm$$v$input$protein$, _vm$$v$input$carbs, _vm$$v$input$carbs$$p, _vm$$v$input$fat, _vm$$v$input$fat$$par, _vm$$v$input$sodium, _vm$$v$input$sodium$$, _vm$$v$input$serving_, _vm$$v$input$serving_2, _vm$$v$input$serving_3, _vm$$v$input$serving_4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching,
      "rounded": "sm"
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.name),
      "state": _vm.getFieldState(_vm.$v.input.name)
    },
    scopedSlots: _vm._u([(_vm$$v$input$name = _vm.$v.input.name) !== null && _vm$$v$input$name !== void 0 && (_vm$$v$input$name$$pa = _vm$$v$input$name.$params) !== null && _vm$$v$input$name$$pa !== void 0 && _vm$$v$input$name$$pa.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Name "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('b-form-input', {
    attrs: {
      "state": _vm.getFieldState(_vm.$v.input.name),
      "placeholder": "Enter Food Name"
    },
    model: {
      value: _vm.input.name,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "name", $$v);
      },
      expression: "input.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Calories",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.calories),
      "state": _vm.getFieldState(_vm.$v.input.calories)
    },
    scopedSlots: _vm._u([(_vm$$v$input$calories = _vm.$v.input.calories) !== null && _vm$$v$input$calories !== void 0 && (_vm$$v$input$calories2 = _vm$$v$input$calories.$params) !== null && _vm$$v$input$calories2 !== void 0 && _vm$$v$input$calories2.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Calories "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        state: _vm.getFieldState(_vm.$v.input.calories),
        placeholder: 'Enter Calories'
      },
      "unit": "ounces",
      "decimal-places": 0,
      "unit-disabled": ""
    },
    model: {
      value: _vm.input.calories,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "calories", $$v);
      },
      expression: "input.calories"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Protein (gm)",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.protein),
      "state": _vm.getFieldState(_vm.$v.input.protein)
    },
    scopedSlots: _vm._u([(_vm$$v$input$protein = _vm.$v.input.protein) !== null && _vm$$v$input$protein !== void 0 && (_vm$$v$input$protein$ = _vm$$v$input$protein.$params) !== null && _vm$$v$input$protein$ !== void 0 && _vm$$v$input$protein$.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Protein "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        state: _vm.getFieldState(_vm.$v.input.protein),
        placeholder: 'Enter Grams'
      },
      "decimal-places": 1,
      "unit": "ounces",
      "unit-disabled": ""
    },
    model: {
      value: _vm.input.protein,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "protein", $$v);
      },
      expression: "input.protein"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Carbs (gm)",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.carbs),
      "state": _vm.getFieldState(_vm.$v.input.carbs)
    },
    scopedSlots: _vm._u([(_vm$$v$input$carbs = _vm.$v.input.carbs) !== null && _vm$$v$input$carbs !== void 0 && (_vm$$v$input$carbs$$p = _vm$$v$input$carbs.$params) !== null && _vm$$v$input$carbs$$p !== void 0 && _vm$$v$input$carbs$$p.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Carbs "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        state: _vm.getFieldState(_vm.$v.input.carbs),
        placeholder: 'Enter Grams'
      },
      "unit": "ounces",
      "decimal-places": 1,
      "unit-disabled": ""
    },
    model: {
      value: _vm.input.carbs,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "carbs", $$v);
      },
      expression: "input.carbs"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Fat (gm)",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.fat),
      "state": _vm.getFieldState(_vm.$v.input.fat)
    },
    scopedSlots: _vm._u([(_vm$$v$input$fat = _vm.$v.input.fat) !== null && _vm$$v$input$fat !== void 0 && (_vm$$v$input$fat$$par = _vm$$v$input$fat.$params) !== null && _vm$$v$input$fat$$par !== void 0 && _vm$$v$input$fat$$par.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Fat "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        state: _vm.getFieldState(_vm.$v.input.fat),
        placeholder: 'Enter Grams'
      },
      "decimal-places": 1,
      "unit": "ounces",
      "unit-disabled": ""
    },
    model: {
      value: _vm.input.fat,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "fat", $$v);
      },
      expression: "input.fat"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Sodium (mg)",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.sodium),
      "state": _vm.getFieldState(_vm.$v.input.sodium)
    },
    scopedSlots: _vm._u([(_vm$$v$input$sodium = _vm.$v.input.sodium) !== null && _vm$$v$input$sodium !== void 0 && (_vm$$v$input$sodium$$ = _vm$$v$input$sodium.$params) !== null && _vm$$v$input$sodium$$ !== void 0 && _vm$$v$input$sodium$$.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Sodium "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('PerHundredGramsInput', {
    attrs: {
      "input-props": {
        state: _vm.getFieldState(_vm.$v.input.sodium),
        placeholder: 'Enter Milligrams'
      },
      "decimal-places": 1,
      "unit": "ounces",
      "unit-disabled": ""
    },
    model: {
      value: _vm.input.sodium,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "sodium", $$v);
      },
      expression: "input.sodium"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Serving units",
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.serving_units),
      "state": _vm.getFieldState(_vm.$v.input.serving_units)
    },
    scopedSlots: _vm._u([(_vm$$v$input$serving_ = _vm.$v.input.serving_units) !== null && _vm$$v$input$serving_ !== void 0 && (_vm$$v$input$serving_2 = _vm$$v$input$serving_.$params) !== null && _vm$$v$input$serving_2 !== void 0 && _vm$$v$input$serving_2.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" Serving units "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.servingUnitsOptions,
      "state": _vm.getFieldState(_vm.$v.input.serving_units),
      "disabled": _vm.servingUnitsIsDisabled
    },
    scopedSlots: _vm._u([{
      key: "first",
      fn: function fn() {
        return [_c('b-form-select-option', {
          attrs: {
            "value": null,
            "disabled": ""
          }
        }, [_vm._v(" Select units ")])];
      },
      proxy: true
    }]),
    model: {
      value: _vm.input.serving_units,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "serving_units", $$v);
      },
      expression: "input.serving_units"
    }
  })], 1), _vm.servingSizeIsEditable ? _c('b-form-group', {
    attrs: {
      "label": _vm.input.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit',
      "label-cols-sm": "4",
      "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.serving_size),
      "state": _vm.getFieldState(_vm.$v.input.serving_size)
    },
    scopedSlots: _vm._u([(_vm$$v$input$serving_3 = _vm.$v.input.serving_size) !== null && _vm$$v$input$serving_3 !== void 0 && (_vm$$v$input$serving_4 = _vm$$v$input$serving_3.$params) !== null && _vm$$v$input$serving_4 !== void 0 && _vm$$v$input$serving_4.required ? {
      key: "label",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.input.serving_units === 'cups' ? 'Ounces per Cup' : 'Ounces per Unit') + " "), _c('span', {
          staticClass: "text-danger"
        }, [_vm._v("*")])];
      },
      proxy: true
    } : null], null, true)
  }, [_c('GramsInput', {
    attrs: {
      "input-props": {
        state: _vm.getFieldState(_vm.$v.input.serving_size),
        placeholder: _vm.input.serving_units === 'cups' ? 'Enter ounces per 1 Cup' : 'Enter ounces per 1 Unit'
      },
      "decimal-places": 1,
      "unit": "ounces"
    },
    model: {
      value: _vm.input.serving_size,
      callback: function callback($$v) {
        _vm.$set(_vm.input, "serving_size", $$v);
      },
      expression: "input.serving_size"
    }
  })], 1) : _vm._e(), _c('b-form-group', {
    staticClass: "text-right mt-4 mb-0"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v(_vm._s(_vm.actionName) + " ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }