<template>
  <b-overlay :show="isFetching">
    <b-form
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Password"
      >
        <b-form-group
          :invalid-feedback="getFieldInvalidFeedback($v.input.password1)"
          :state="getFieldState($v.input.password1)"
        >
          <b-form-input
            v-model.trim="$v.input.password1.$model"
            type="password"
            placeholder="New password"
            squared
            :state="getFieldState($v.input.password1)"
          />
        </b-form-group>

        <b-form-group
          v-if="input.password1"
          :invalid-feedback="getFieldInvalidFeedback($v.input.password2)"
          :state="getFieldState($v.input.password2)"
        >
          <b-form-input
            v-model="$v.input.password2.$model"
            type="password"
            placeholder="Confirm new password"
            squared
            :state="getFieldState($v.input.password2)"
          />
        </b-form-group>
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="mt-2 mb-0 text-right">
        <b-button
          variant="success"
          type="submit"
          :disabled="isSubmitting"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import crudItemMixin from '@/mixins/crud-item'
import { required, sameAs } from "vuelidate/lib/validators"
import { strongPassword } from '@/plugins/validation'

export default {
  name: 'UserItemPasswordUpdate',

  mixins: [
    crudItemMixin
  ],

  data() {
    return {
      baseEndpoint: 'users'
    }
  },

  validations() {
    return {
      input: {
        password1: { required, strongPassword },
        password2: { required, sameAs: sameAs(nestedModel => nestedModel.password1)}
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        password1: null,
        password2: null
      }

      return input
    },

    prepareInput() {
      const input = {
        password: this.input.password1
      }

      return input
    }
  }
}
</script>