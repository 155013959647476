<template>
  <b-overlay
    :show="isFetching"
    rounded="sm"
  >
    <div
      v-if="!isFetched"
      class="text-center"
    >
      {{ isFetching ? 'Loading...' : 'You can not view this item.' }}
    </div>

    <b-form
      v-else
      @submit.prevent="submit('submitted')"
    >
      <b-form-group
        label="Owners"
        label-cols-sm="3"
        label-cols-lg="2"
        content-cols-sm="9"
        content-cols-lg="10"
        :invalid-feedback="getFieldInvalidFeedback($v.input.owner_ids)"
        :state="getFieldState($v.input.owner_ids)"
      >
        <UserSelect
          v-if="ownersIsEditable"
          v-model="input.owner_ids"
          list-endpoint="admin/users"
          :items="newItem.owners"
          :show-create="isCreated"
          :item-to-create="isCreated ? { company_id: newItem.id, role_ids: [3] } : null"
          :company-to-create="isCreated ? newItem : null"
          multiple
          placeholder="Select an owner..."
        />

        <UserSelect
          v-else
          list-endpoint="admin/users"
          :value="newItem.owners ? newItem.owners.map(i => i.id) : null"
          :items="newItem.owners"
          disabled
          multiple
        />
      </b-form-group>

      <!-- SUBMIT -->
      <b-form-group class="text-right my-4">
        <b-button
          variant="success"
          type="submit"
          :disabled="isSubmitting || !inputIsDirty"
        >
          <b-spinner v-if="isSubmitting" small />Submit
        </b-button>
      </b-form-group>
    </b-form>
  </b-overlay>
</template>

<script>
import _ from 'lodash'
import crudItemMixin from '@/mixins/crud-item'
import { required, minLength } from "vuelidate/lib/validators"
import UserSelect from '@/components/users/UserSelect'

export default {
  name: 'CompanyItemOwnersUpdate',

  mixins: [
    crudItemMixin
  ],

  components: {
    UserSelect
  },

  computed: {
    baseEndpoint() {
      return this.$acl.check('isAdmin') ? 'admin/companies' : 'companies'
    },

    ownersIsEditable() {
      return this.$acl.check('isAdmin')
    }
  },

  validations() {
    return {
      input: {
        owner_ids: { required, minLength: minLength(1) }
      }
    }
  },

  methods: {
    getInput() {
      const input = {
        owner_ids: _.map(this.newItem.owners, 'id')
      }

      return input
    },

    getSubmitSuccessMessage() {
      return `Company owners have been updated`
    }
  }
}
</script>