<template>
  <b-dropdown
    right
    text="Actions"
    v-bind="dropdownProps"
  >
    <template v-if="$slots.default" #button-content>
      <slot />
    </template>

    <ExerciseItemDetailButton
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      prefetch
    >
      Detail
    </ExerciseItemDetailButton>

    <b-dropdown-item
      v-if="showEditButton"
      :to="`/exercise/${item.id}`"
    >
      Edit
    </b-dropdown-item>

    <ExerciseItemDeleteButton
      v-if="showDeleteButton"
      :item="item"
      tag="b-dropdown-item-button"
      :tag-props="{
        buttonClass: 'font-size-14'
      }"
      @deleted:item="$emit('deleted:item', $event)"
      @submitted="$emit('submitted', $event)"
    >
      Delete
    </ExerciseItemDeleteButton>
  </b-dropdown>
</template>

<script>
import { mapGetters } from 'vuex'
import ExerciseItemDetailButton from './ExerciseItemDetailButton'
import ExerciseItemDeleteButton from './ExerciseItemDeleteButton'

export default {
  name: 'ExerciseItemActionsButton',

  components: {
    ExerciseItemDetailButton,
    ExerciseItemDeleteButton
  },

  props: {
    item: {
      type: Object,
      required: true
    },

    dropdownProps: {
      type: Object,
      default: () => ({
        size: 'sm',
        toggleClass: 'bg-transparent text-primary'
      })
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    }),

    showEditButton() {
      return (
        this.$acl.check('couldEditPublicExercise') && !this.item.is_private ||
        this.$acl.check('couldEditOwnExercise') && this.currentUser.id === this.item.created_by
      )
    },

    showDeleteButton() {
      return (
        this.$acl.check('couldDeletePublicExercise') && !this.item.is_private ||
        this.$acl.check('couldDeleteOwnExercise') && this.currentUser.id === this.item.created_by
      )
    }
  }
}
</script>