var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching || _vm.isSubmitting
    }
  }, [_c('b-table', {
    ref: "table",
    staticClass: "table-select mb-0",
    attrs: {
      "items": _vm.newResult.data,
      "fields": _vm.fields,
      "stacked": "sm",
      "fixed": "",
      "no-local-sorting": "",
      "sort-by": _vm.newParams.order_by,
      "sort-desc": _vm.newParams.order_desc
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sort-by": function updateSortBy($event) {
        return _vm.$set(_vm.newParams, "order_by", $event);
      },
      "update:sortDesc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      },
      "update:sort-desc": function updateSortDesc($event) {
        return _vm.$set(_vm.newParams, "order_desc", $event);
      }
    },
    scopedSlots: _vm._u([{
      key: "cell(name)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "font-weight-bold font-size-16 mb-1"
        }, [row.item.type === 'reboot' ? _c('b-badge', {
          staticClass: "text-uppercase"
        }, [_vm._v(" " + _vm._s(row.item.type) + " ")]) : _vm._e(), _vm._v(" " + _vm._s(row.item.name) + " ")], 1), _c('div', [_vm._v(" " + _vm._s(row.item.description) + " ")])];
      }
    }, {
      key: "cell(goal_direction_id)",
      fn: function fn(row) {
        return [_c('GoalDirections', {
          attrs: {
            "ids": [row.item.goal_direction_id]
          }
        })];
      }
    }, {
      key: "cell(price_per_week)",
      fn: function fn(row) {
        return [_c('div', [_vm._v(" $" + _vm._s(row.item.price_per_week) + " per week ")])];
      }
    }, {
      key: "cell(agreement_url)",
      fn: function fn(row) {
        return [_vm.$acl.check('isOwner') ? _c('b-button', {
          attrs: {
            "variant": "success",
            "size": "sm",
            "href": row.item.agreement_url,
            "target": "_blank"
          }
        }, [_vm._v(" View Agreement ")]) : _vm.$acl.check('isClient') && _vm.currentUser.goal_direction === row.item.goal_direction_id && !_vm.currentUser.curriculum ? _c('b-form-checkbox', {
          model: {
            value: _vm.agreementsCheck[row.item.id],
            callback: function callback($$v) {
              _vm.$set(_vm.agreementsCheck, row.item.id, $$v);
            },
            expression: "agreementsCheck[row.item.id]"
          }
        }, [_vm._v(" Check to Accept "), _c('a', {
          attrs: {
            "href": row.item.agreement_url,
            "target": "_blank"
          }
        }, [_vm._v("Agreement")])]) : _vm._e()];
      }
    }, {
      key: "cell(actions)",
      fn: function fn(row) {
        return [_vm.$acl.check('isOwner') ? _c('CurriculumProductItemUpsertButton', {
          style: {
            cursor: 'pointer'
          },
          attrs: {
            "item": row.item
          },
          on: {
            "update:item": _vm.refresh
          }
        }, [_c('b-icon-gear')], 1) : _vm.$acl.check('isClient') && _vm.currentUser.goal_direction === row.item.goal_direction_id && !_vm.currentUser.curriculum ? _c('CurriculumProductItemCheckoutButton', {
          attrs: {
            "item": row.item,
            "tag": "b-button",
            "tag-props": {
              size: 'sm',
              variant: 'success'
            },
            "disabled": !_vm.agreementsCheck[row.item.id]
          },
          on: {
            "update:item": _vm.refresh
          }
        }, [_c('b-icon-cart'), _vm._v(" Buy ")], 1) : _vm._e()];
      }
    }])
  }), _vm.showPagination ? _c('b-pagination', {
    staticClass: "mt-4",
    attrs: {
      "pills": "",
      "align": "center",
      "total-rows": _vm.newResult.total,
      "per-page": _vm.newParams.limit
    },
    model: {
      value: _vm.newParams.page,
      callback: function callback($$v) {
        _vm.$set(_vm.newParams, "page", $$v);
      },
      expression: "newParams.page"
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }