var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isFetching
    }
  }, [_c('b-form', {
    on: {
      "submit": function submit($event) {
        $event.preventDefault();
        return _vm.submit('submitted');
      }
    }
  }, [_c('b-tabs', {
    attrs: {
      "active-nav-item-class": "bg-tab text-primary",
      "nav-class": "border-0"
    },
    model: {
      value: _vm.activeInputPhaseIndex,
      callback: function callback($$v) {
        _vm.activeInputPhaseIndex = $$v;
      },
      expression: "activeInputPhaseIndex"
    }
  }, _vm._l(_vm.input.phases, function (phase, phaseIndex) {
    return _c('b-tab', {
      key: phase.id,
      staticClass: "p-2 bg-tab",
      attrs: {
        "title-link-class": "rounded-0 border-0",
        "title": "Phase ".concat(_vm.getPhaseNumber(phase.id))
      }
    }, [_c('b-alert', {
      staticClass: "my-0",
      attrs: {
        "show": !phase.client_notes.length,
        "variant": "light"
      }
    }, [_vm._v(" There are no notes yet. ")]), _vm._l(phase.client_notes, function (note, noteIndex) {
      return _c('b-form-group', {
        key: noteIndex,
        staticClass: "my-2",
        attrs: {
          "invalid-feedback": _vm.getFieldInvalidFeedback(_vm.$v.input.phases.$each[phaseIndex].client_notes.$each[noteIndex]),
          "state": _vm.getFieldState(_vm.$v.input.phases.$each[phaseIndex].client_notes.$each[noteIndex])
        }
      }, [_c('b-collapse', {
        attrs: {
          "visible": noteIndex !== _vm.activeInputNoteIndex
        }
      }, [_c('b-alert', {
        staticClass: "mb-0",
        attrs: {
          "show": "",
          "dismissing": ""
        }
      }, [_c('div', [_vm._v(" " + _vm._s(note.text) + " ")]), _c('div', {
        staticClass: "text-right"
      }, [_c('b-button', {
        attrs: {
          "variant": "link",
          "size": "sm"
        },
        on: {
          "click": function click($event) {
            _vm.activeInputNoteIndex = noteIndex;
          }
        }
      }, [_vm._v(" Edit ")]), _c('b-button', {
        attrs: {
          "variant": "link",
          "size": "sm"
        },
        on: {
          "click": function click($event) {
            return _vm.showDeleteConfirmationModal(noteIndex);
          }
        }
      }, [_vm._v(" Delete ")])], 1)])], 1), _c('b-collapse', {
        attrs: {
          "visible": noteIndex === _vm.activeInputNoteIndex
        }
      }, [_c('b-form-textarea', {
        attrs: {
          "rows": "6",
          "max-rows": "6"
        },
        model: {
          value: note.text,
          callback: function callback($$v) {
            _vm.$set(note, "text", $$v);
          },
          expression: "note.text"
        }
      }), _c('div', {
        staticClass: "text-right"
      }, [_c('b-button', {
        attrs: {
          "variant": "link",
          "size": "sm"
        },
        on: {
          "click": function click($event) {
            _vm.activeInputNoteIndex = null;
          }
        }
      }, [_vm._v(" Close ")])], 1)], 1)], 1);
    })], 2);
  }), 1), _c('b-form-group', {
    staticClass: "text-right ml-2 mr-2 mt-2"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "disabled": _vm.isSubmitting
    },
    on: {
      "click": _vm.addNote
    }
  }, [_vm._v(" New Note ")]), _c('b-button', {
    staticClass: "ml-1",
    attrs: {
      "variant": "primary",
      "type": "submit",
      "disabled": _vm.isSubmitting
    }
  }, [_vm.isSubmitting ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _vm._v("Submit ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }